import React, { memo, useEffect, useState } from 'react';
import moment from 'moment/moment';
import ReactSelect from 'react-select';
import { Eye, EyeSlash, PhoneCall } from 'phosphor-react';
import CheckRole from '../../../../utils/RoleComponent';
import ClientSalesUpdatePopup from '../ClientPopups/ClientSalesUpdatePopup';
import ClientAcquisitionStatus from '../ClientAcquisitionStatus/ClientAcquisitionStatus';
import classNames from 'classnames/bind';
import styles from '../client-page.module.scss';
import { usePermission } from '../../../../utils/usePermission';
import { notify } from '../../../../utils/notify';
import { getCurrentCallPhone, getCurrentAltCallPhone } from '../../../../helpers/clientPage';
import { useParams } from 'react-router-dom';
import { Client } from 'models/Clients/Clients';
import { ListValue } from 'models/ListValue';
import { PURPLE } from '../../../../constants/colors.js';
import { getPrefixes, initCall } from 'api/voip/';
import { useSelector } from 'react-redux';
import { RootState } from 'store';

const cx = classNames.bind(styles);

type Props = {
  clientData: Client;
  updateComponent: () => void;
};

const PersonalInfo = memo<Props>(({ clientData, updateComponent }) => {
  const VOIP_PREFIXES = useSelector((state: RootState) => state.userInfo.VOIP_PREFIXES);
  const VOIP_STATUS = useSelector((state: RootState) => state.userInfo.VOIP_STATUS);
  const sip_account = useSelector((state: RootState) => state.userInfo?.userInfo?.sip_account);

  const { permissionGiven: PermissionPersonalInfo } = usePermission(
    'admin.client.view-personal-info',
  );
  const { permissionGiven: PermissionViewPhone } = usePermission('admin.client.view-phone');
  const { permissionGiven: PermissionViewEmail } = usePermission('admin.client.view-email');
  const { permissionGiven: PermissionAssociateClientToOperator } =
    usePermission('admin.client.team');

  const [prefix, setPrefix] = useState('');
  const [prefixes, setPrefixes] = useState<ListValue[]>([]);
  const [showPhone, setShowPhone] = useState(false);
  const [showAltPhone, setShowAltPhone] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showAltEmail, setShowAltEmail] = useState(false);
  const [missClickCall, setMissClickCall] = useState(false);

  const params = useParams();

  const call = (alt) => {
    const callPhone = alt ? getCurrentAltCallPhone(clientData) : getCurrentCallPhone(clientData);
    if (sip_account) {
      notify({
        type: 'info',
        message: 'Wait for connection...',
        timeOut: 2000,
      });

      initCall(prefix, callPhone)
        .then((res) => console.log('Connect...'))
        .catch((error) => {
          notify({
            type: 'error',
            message: error || 'Connection error',
            timeOut: 3000,
          });
        });
    } else {
      notify({
        type: 'info',
        message: 'You cannot make calls. Add a SIP account in the operator settings',
        timeOut: 7000,
      });
    }
  };

  const ClickToCall = (alt) => {
    if (!missClickCall) {
      call(alt);
      setMissClickCall(true);
      setTimeout(() => setMissClickCall(false), 3000);
    }
  };

  const salesBtn = (): React.ReactElement => (
    <div
      style={{ border: `1px solid ${clientData?.color_status || PURPLE}` }}
      className={cx(
        'acquisitionStatus__block',
        clientData?.assigned_status_types?.hasOwnProperty('sales') ? 'active' : '',
      )}
    >
      <div className={cx('acquisitionStatus__block-col')}>
        <div className={cx('acquisitionStatus__action')}>Sales</div>
        <div
          style={{ color: `${clientData?.color_status || PURPLE}` }}
          className={cx('acquisitionStatus__action-status')}
        >
          {clientData?.status_name || 'NONE'}

          {/*{clientData?.assigned_status_types?.sales?.translate*/}
          {/*  ? clientData?.assigned_status_types?.sales?.translate*/}
          {/*  : 'NONE'}*/}
        </div>
      </div>
      <div className={cx('acquisitionStatus__block-col')}>
        <div className={cx('acquisitionStatus__info')}>
          <div className={cx('acquisitionStatus__info-row')}>
            <b>
              {clientData?.sales?.operator?.full_name
                ? clientData?.sales?.operator?.full_name
                : '---'}
            </b>
          </div>
          <div className={cx('acquisitionStatus__info-row')}>
            <b>Desk:</b>{' '}
            {clientData?.sales?.team?.desk?.name ? clientData?.sales?.team?.desk?.name : '---'}
          </div>
          <div className={cx('acquisitionStatus__info-row')}>
            <b>Team:</b> {clientData?.sales?.team?.name ? clientData?.sales?.team?.name : '---'}
          </div>
        </div>
      </div>
    </div>
  );

  const retentionBtn = () => (
    <div
      style={{ border: `1px solid ${clientData?.color_status || PURPLE}` }}
      className={cx(
        'acquisitionStatus__block',
        clientData?.assigned_status_types?.hasOwnProperty('retention') ? 'active' : 'NONE',
      )}
    >
      <div className={cx('acquisitionStatus__block-col')}>
        <div className={cx('acquisitionStatus__action')}>Retention</div>
        <div
          style={{ color: `${clientData?.color_status || PURPLE}` }}
          className={cx('acquisitionStatus__action-status')}
        >
          {clientData?.status_name || 'NONE'}

          {/*{clientData?.assigned_status_types?.retention?.translate*/}
          {/*  ? clientData?.assigned_status_types?.retention?.translate*/}
          {/*  : 'NONE'}*/}
        </div>
      </div>
      <div className={cx('acquisitionStatus__block-col')}>
        <div className={cx('acquisitionStatus__info')}>
          <div className={cx('acquisitionStatus__info-row')}>
            <b>
              {clientData?.retention?.operator?.full_name
                ? clientData?.retention?.operator?.full_name
                : '---'}
            </b>
          </div>
          <div className={cx('acquisitionStatus__info-row')}>
            <b>Desk:</b>{' '}
            {clientData?.retention?.team?.desk?.name
              ? clientData?.retention?.team?.desk?.name
              : '---'}
          </div>
          <div className={cx('acquisitionStatus__info-row')}>
            <b>Team:</b>{' '}
            {clientData?.retention?.team?.name ? clientData?.retention?.team?.name : '---'}
          </div>
        </div>
      </div>
    </div>
  );

  useEffect(() => {
    getPrefixes()
      .then((data) => {
        const result = data.data.map((item) => {
          return {
            value: item.prefix.toString(),
            label: `${item.prefix.toString()} ${item.country}`,
          };
        });
        setPrefixes(result);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div className={cx('personalInfo')}>
      {PermissionPersonalInfo ? (
        <div className={cx('personalInfo__col')}>
          <div className={cx('personalInfo__col-title')}>Personal information</div>
          <div className={cx('personalInfo__col-content')}>
            <div className={cx('personalInfo__row')}>
              <div className={cx('personalInfo__row-title')}>Date of birth:</div>
              <div className={cx('personalInfo__row-content')}>
                {clientData?.user_profile?.birthday
                  ? moment(clientData?.user_profile?.birthday * 1000).format('DD.MM.YYYY')
                  : '---'}
              </div>
            </div>
            <div className={cx('personalInfo__row')}>
              <div className={cx('personalInfo__row-title')}>Gender:</div>
              <div className={cx('personalInfo__row-content')}>
                {clientData?.user_profile?.gender ? clientData?.user_profile?.gender : '---'}
              </div>
            </div>
            {VOIP_STATUS && (
              <div className={cx('personalInfo__row', 'prefix')}>
                <div className={cx('personalInfo__row-title')}>Prefix:</div>
                <div className={cx('personalInfo__row-content')}>
                  {VOIP_PREFIXES ? (
                    <>
                      <ReactSelect
                        styles={{
                          container: (styles) => ({
                            ...styles,
                            width: '145px',
                            border: '1px solid #c2c2c2',
                            borderRadius: '4px',
                            outline: 'none',
                            boxShadow: 'none!important',
                            '&:hover': {
                              borderColor: 'var(--main-color)!important',
                            },
                            '&:active': {
                              borderColor: 'var(--main-color)!important',
                            },
                            '&:focus': {
                              borderColor: 'var(--main-color)!important',
                            },
                          }),
                          control: (styles) => ({
                            ...styles,
                            border: 'none',
                            padding: '0 8px',
                            minHeight: 'unset',
                            '&:hover': {
                              borderColor: 'var(--main-color)!important',
                              boxShadow: 'none!important',
                            },
                            '&:active': {
                              borderColor: 'var(--main-color)!important',
                              boxShadow: 'none!important',
                            },
                            '&:focus': {
                              borderColor: 'var(--main-color)!important',
                              boxShadow: 'none!important',
                            },
                          }),
                          valueContainer: (styles) => ({
                            ...styles,
                            padding: '0',
                          }),
                          menuList: (styles) => ({
                            ...styles,
                            padding: '0',
                            borderRadius: '4px',
                            width: '100%',
                          }),
                          option: (styles, { isSelected }) => ({
                            ...styles,
                            backgroundColor: isSelected ? 'var(--main-color)' : '#ffffff',
                            '&:hover': {
                              backgroundColor: 'var(--purple-100)',
                              color: '#000000',
                            },
                          }),
                          singleValue: (styles) => ({
                            ...styles,
                            padding: '0',
                            lineHeight: 'normal',
                          }),
                          indicatorSeparator: (styles) => ({
                            ...styles,
                            display: 'none',
                            padding: '0',
                          }),
                          dropdownIndicator: (styles) => ({
                            ...styles,
                            padding: '0',
                          }),
                          clearIndicator: (styles) => ({
                            ...styles,
                            padding: '0',
                          }),
                        }}
                        options={prefixes}
                        defaultValue={prefixes[0]}
                        onChange={(value) => {
                          setPrefix(value.value.toString());
                        }}
                      />
                    </>
                  ) : (
                    <>
                      <input
                        className={styles.prefixInput}
                        value={prefix}
                        onChange={(e) => setPrefix(e.target.value)}
                      />
                    </>
                  )}
                </div>
              </div>
            )}
            <div className={cx('personalInfo__row')}>
              <div className={cx('personalInfo__row-title')}>Phone:</div>
              <div className={cx('personalInfo__row-content')}>
                {PermissionViewPhone
                  ? showPhone
                    ? clientData && clientData.phone
                      ? clientData.phone
                      : '---'
                    : '***********'
                  : null}
              </div>
              <div className={cx('phone')}>
                {PermissionViewPhone && clientData?.phone && VOIP_STATUS ? (
                  <span className={cx('phone-call')} onClick={() => ClickToCall(false)}>
                    <PhoneCall color="#fff" />
                  </span>
                ) : null}
                <CheckRole permissionType="admin.client.view-phone" roleType={['Super Admin']}>
                  <span className={cx('showContent')} onClick={() => setShowPhone(!showPhone)}>
                    {!showPhone ? <Eye size={17} /> : <EyeSlash size={17} />}
                  </span>
                </CheckRole>
              </div>
            </div>

            <div className={cx('personalInfo__row')}>
              <div className={cx('personalInfo__row-title')}>Alternative Phone:</div>
              <div className={cx('personalInfo__row-content')}>
                {PermissionViewPhone
                  ? showAltPhone
                    ? clientData && clientData.user_profile.alt_phone
                      ? clientData.user_profile.alt_phone
                      : '---'
                    : '***********'
                  : null}
              </div>
              <div className={cx('phone')}>
                {PermissionViewPhone && clientData?.user_profile?.alt_phone && VOIP_STATUS ? (
                  <span className={cx('phone-call')} onClick={() => ClickToCall(true)}>
                    <PhoneCall color="#fff" />
                  </span>
                ) : null}
                <CheckRole permissionType="admin.client.view-phone" roleType={['Super Admin']}>
                  <span
                    className={cx('showContent')}
                    onClick={() => setShowAltPhone(!showAltPhone)}
                  >
                    {!showAltPhone ? <Eye size={17} /> : <EyeSlash size={17} />}
                  </span>
                </CheckRole>
              </div>
            </div>

            <div className={cx('personalInfo__row')}>
              <div className={cx('personalInfo__row-title')}>E-mail:</div>
              <div className={cx('personalInfo__row-content')}>
                {PermissionViewEmail
                  ? showEmail
                    ? clientData && clientData.email
                      ? clientData.email
                      : '---'
                    : '***********'
                  : null}
              </div>
              <CheckRole permissionType="admin.client.view-email" roleType={undefined}>
                <span className={cx('showContent')} onClick={() => setShowEmail(!showEmail)}>
                  {!showEmail ? <Eye size={17} /> : <EyeSlash size={17} />}
                </span>
              </CheckRole>
            </div>

            <div className={cx('personalInfo__row')}>
              <div className={cx('personalInfo__row-title')}>Alternative E-mail:</div>
              <div className={cx('personalInfo__row-content')}>
                {PermissionViewEmail
                  ? showAltEmail
                    ? clientData && clientData.user_profile.alt_email
                      ? clientData.user_profile.alt_email
                      : '---'
                    : '***********'
                  : null}
              </div>
              <CheckRole permissionType="admin.client.view-email" roleType={undefined}>
                <span className={cx('showContent')} onClick={() => setShowAltEmail(!showAltEmail)}>
                  {!showAltEmail ? <Eye size={17} /> : <EyeSlash size={17} />}
                </span>
              </CheckRole>
            </div>
            <div className={cx('personalInfo__row')}>
              <div className={cx('personalInfo__row-title')}>Country:</div>
              <div className={cx('personalInfo__row-content')}>
                {clientData?.country?.name ? clientData?.country?.name : '---'}
              </div>
            </div>
            <div className={cx('personalInfo__row')}>
              <div className={cx('personalInfo__row-title')}>Language:</div>
              <div className={cx('personalInfo__row-content')}>
                {clientData?.language?.name ? clientData?.language?.name : '---'}
              </div>
            </div>
            <div className={cx('personalInfo__row')}>
              <div className={cx('personalInfo__row-title')}>Additional info:</div>
              <div className={cx('personalInfo__row-content')}>
                {clientData?.user_profile?.additional_info
                  ? clientData?.user_profile?.additional_info
                  : '---'}
              </div>
            </div>
          </div>
        </div>
      ) : null}
      <div className={cx('personalInfo__col', 'acquisitionStatus')}>
        <div className={cx('personalInfo__col-title')}>Acquisition status</div>
        <div className={cx('personalInfo__col-content')}>
          {clientData.hasOwnProperty('sales') ? (
            <ClientSalesUpdatePopup
              actionType="sales"
              updateComponent={updateComponent}
              userId={params.id}
              triggerBtn={salesBtn()}
              disabled={!PermissionAssociateClientToOperator}
            />
          ) : null}
          {clientData.hasOwnProperty('retention') ? (
            <ClientSalesUpdatePopup
              actionType="retention"
              updateComponent={updateComponent}
              userId={params.id}
              triggerBtn={retentionBtn()}
              disabled={!PermissionAssociateClientToOperator}
            />
          ) : null}
          {clientData?.old_values?.retention ? (
            <ClientAcquisitionStatus
              oldValues={clientData?.old_values?.retention}
              status={clientData?.old_values}
              type={'retention'}
            />
          ) : null}
          {clientData?.old_values?.sales ? (
            <ClientAcquisitionStatus
              oldValues={clientData?.old_values?.sales}
              status={clientData?.old_values}
              type={'sales'}
            />
          ) : null}
        </div>
      </div>
      <div className={cx('personalInfo__col', 'lastIp')}>
        <div className={cx('personalInfo__col-title')}>last 10 ip's</div>
        <div className={cx('personalInfo__col-content')}>
          <ul className={cx('lastIp__list')}>
            {clientData && clientData.visits
              ? clientData.visits.map((el, index) => (
                  <li key={index} className={cx('lastIp__list-item')}>
                    {el?.country ? (
                      <div className={cx('flag-icon')}>
                        <img
                          src={
                            el?.country?.flag && el?.country?.flag
                            // : `${process.env.REACT_APP_API_URL}/images/flags/UA.png`
                          }
                        />
                      </div>
                    ) : (
                      <div style={{ width: 25 }} />
                    )}
                    <div className={cx('ip-value')}>{el?.ip}</div>
                  </li>
                ))
              : null}
          </ul>
        </div>
      </div>
      <div className={cx('personalInfo__col', 'pinnedNotes')}>
        <div className={cx('personalInfo__col-title')}>Pinned notes</div>
        <div className={cx('personalInfo__col-content')}>
          {clientData && clientData.notes_pinned
            ? clientData.notes_pinned
                .sort((a, b) => (a.create_at < b.create_at ? 1 : -1))
                .map((note) => {
                  return (
                    <div key={note?.id} className={cx('pinnedNote')}>
                      <div className={cx('pinnedNote__title')}>{note?.subject}</div>
                      <div className={cx('pinnedNote__operator')}>{`by ${
                        note?.operator?.first_name ? note?.operator?.first_name : ''
                      } ${note?.operator?.last_name ? note?.operator?.last_name : ''}`}</div>
                      <div
                        className={cx('pinnedNote__create', 'uuid')}
                        // onClick={() => copyToBuffer(note?.to)}
                      >
                        {moment(note?.create_at * 1000).format('DD.MM.YYYY')}{' '}
                        {moment(note?.create_at * 1000).format('HH:mm:ss')}
                        {/*to {getShortUuid(note.to)}*/}
                      </div>
                      <div className={cx('pinnedNote__body')}>{note?.body}</div>
                    </div>
                  );
                })
            : null}
        </div>
      </div>
    </div>
  );
});

export default PersonalInfo;
