import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
	selectChatUserId, selectDialogParticipants,
	selectMessagesError,
	selectThread,
	selectUserStatuses,
	setMessagesError,
	setThreadId
} from "../../../../store/slice/chat/chatSlice";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { ChatMessageForm } from "../../components/ChatMessageForm/ChatMessageForm";
import { getMessages } from "../../../../store/slice/chat/thunks";
import { MessagesContainer } from "./MessagesContainer";
import { handleReadThread } from "../../helpers/readThread";
import { ChatDialogHeader } from "../../components/ChatDialogHeader/ChatDialogHeader";
import { ChatDialogSearch } from "../../components/ChatDialogSearch/ChatDialogSearch";
import { JokerDropdown } from "../../../../components/ui/JokerDropdown/JokerDropdown";
import { ChatDropdownButton } from "../../components/ChatDropdownButton/ChatDropdownButton";
import { GroupRenameOption } from "../../components/menuOptions/GroupRenameOption";
import { GroupMembersOption } from "../../components/menuOptions/GroupMembersOption";
import { ViewProfileOption } from "../../components/menuOptions/ViewProfileOption";
import { UnreadTypeRoutes } from "../../../../models/Chat";
import { MuteNotificationsOption } from "../../components/menuOptions/MuteNotificationsOption";
import { DeleteContactOption } from "../../components/menuOptions/DeleteContactOption";
import { GroupParticipantsModal } from "../../components/modals/GroupParticipantsModal/GroupParticipantsModal";
import { RenameGroupModal } from "../../components/modals/RenameGroupModal/RenameGroupModal";
import './ChatDialog.scss';

export const ChatDialog = () => {
	const {id} = useParams();
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const messagesError = useAppSelector(selectMessagesError);
	const thread = useAppSelector(selectThread);
	const userStatuses = useAppSelector(selectUserStatuses);
	const userId = useAppSelector(selectChatUserId);
	const participants = useAppSelector(selectDialogParticipants);
	const profileId = thread?.resources?.recipient?.base?.out_user_id;

	useEffect(() => {
		dispatch(getMessages(id));
	}, [id]);

	useEffect(() => {
		if (messagesError) {
			navigate('/chat');
			setThreadId(null);
		}
		return () => {
			dispatch(setMessagesError(false));
		}
	}, [messagesError]);

	return (
		<div className="chat-dialog-page" onClick={() => handleReadThread(thread)}>
			{thread ? (
				<ChatDialogHeader
					name={thread.name}
					type={thread.resources?.recipient?.base?.type}
					status={userStatuses[thread.resources?.recipient?.provider_id]}
					isGroup={thread.group}
				>
					<ChatDialogSearch/>
					<JokerDropdown button={<ChatDropdownButton/>} position='bottom-left'>
						{participants[userId]?.admin && !thread.is_support ? <GroupRenameOption/> : null}
						{thread.group ? (
							<GroupMembersOption threadId={thread.id} title={!!thread.is_support ? 'Supervisors' : 'Group members'}/>
						) : null}
						{(!thread.group && profileId) ? (
							<ViewProfileOption url={`/${UnreadTypeRoutes[thread?.resources?.recipient?.base?.type]}/${profileId}`}/>
						) : null}
						<MuteNotificationsOption threadId={thread.id}/>
						<DeleteContactOption contact={thread}/>
					</JokerDropdown>

					<GroupParticipantsModal/>
					<RenameGroupModal/>
				</ChatDialogHeader>
			) : null}
			<MessagesContainer/>
			<ChatMessageForm/>
		</div>
	)
}