import cn from "classnames";
import { MessageTypesVerbose } from "../../../../models/Chat";
import { mapSystemMessageIcons } from "../../constants";
import { ChatDate } from "../ChatDate/ChatDate";
import { unEscapeHtml } from "../../helpers/unscapeHtml";
import { MutableRefObject } from "react";

type ChatSystemMessageProps = {
    name: string;
    date: string;
    type: MessageTypesVerbose;
    message: string;
    foundMessageRef: MutableRefObject<HTMLDivElement> | null;
};

export const ChatSystemMessage = ({ date, type, name, message, foundMessageRef }: ChatSystemMessageProps) => {
    return (
        <div className={cn("chat-system-message", { found: !!foundMessageRef })} ref={foundMessageRef}>
            <div className="system-message-body">
                <ChatDate date={date} dateFormat='distance' />
                {mapSystemMessageIcons[type] || ''}&nbsp;
                <span className="system-message-owner">{name}&nbsp;</span>
                <span className="system-message-text">{unEscapeHtml(message)}</span>
            </div>
        </div>
    )
}