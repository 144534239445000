import React from 'react';
import moment from 'moment/moment';
import { getTimeAgo } from 'utils/getTimeAgo.js';
import classNames from 'classnames/bind';
import styles from '../operator-page.module.scss';
import { HierarchyOperator } from 'models/Hierarchy/HierarchyData';
import { ManagementOperator } from 'models/Management/Operators/Operator';

const cx = classNames.bind(styles);

type Props = {
  operatorData: ManagementOperator;
}

const Registered = (props: Props) => {
  const { operatorData } = props;
  
  return (
    <>
      <div className={cx('client-info__col-title')}>Registered</div>
      <div className={cx('client-info__col-content')}>
        <div className={cx('strong')}>
          {operatorData && operatorData.created_at
            ? getTimeAgo(operatorData.created_at)
            : '-------'}
        </div>
        <div>
          on{' '}
          {operatorData && operatorData.created_at
            ? moment(operatorData.created_at * 1000).format('DD.MM.YYYY HH:mm')
            : '---'}
        </div>
      </div>
    </>
  );
};

export default Registered;
