import classNames from 'classnames/bind'
import React, { FC, memo, useEffect, useState} from 'react'
import { TABLE_CLIENTS_COLUMNS_NAMES } from '../../../../constants/table'
import { makeTableData } from '../../../../helpers/table.helper'
import Table from '../Table'
import styles from './clients-table.module.scss'
import Client from './cells/Client'
import LastActivity from './cells/LastActivity'
import Country from './cells/Country'
import Balance from './cells/Balance'
import Deposit from './cells/Deposit'
import Affiliate from './cells/Affiliate'
import Type from './cells/Type'
import Sales from './cells/Sales'
import Retention from './cells/Retention'
import Registration from './cells/Registration'
import LastNote from './cells/LastNote'
import Status from './cells/Status'
import { IClientsTableProps } from "../../../../models/components/Table/ClientsTable/ClientsTable.ts";
import useTableColumnOrder from 'utils/useTableColumnOrder.ts'
import Button from 'components/ui/Button/Button.tsx'
import Toggle from 'react-toggle';
import { usePermission } from 'utils/usePermission.tsx'
import { useAppSelector } from 'store/index.ts'
import { getDemoAccountsAllowed, getEnableWebTrader } from 'constants/selectors.ts'
import { disableGlobalDemoAccounts, enableGlobalDemoAccounts } from 'api/clients/index.ts'

const cx = classNames.bind(styles)

const ClientsTable: FC<IClientsTableProps> = ({
                                                data,
                                                perPage,
                                                showLoader,
                                                modifyDataHandler,
                                                onRowSelect,
                                                manualSortHandler,
                                                colSortCheck,
                                              }) => {

  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_CLIENTS_COLUMNS_NAMES", TABLE_CLIENTS_COLUMNS_NAMES);
  // const columns = React.useMemo(() => TABLE_CLIENTS_COLUMNS_NAMES, [])

  const tableData = React.useMemo(() => makeTableData(data, data.length), [data])
  
  const wtEnabled = useAppSelector(getEnableWebTrader);
  const initialDemoAccountsAllowed = useAppSelector(getDemoAccountsAllowed);
  
  const [demoAccountsAllowed, setDemoAccountsAllowed] = useState(initialDemoAccountsAllowed);

  useEffect(() => {
    setDemoAccountsAllowed(initialDemoAccountsAllowed)
  }, [initialDemoAccountsAllowed])

  const prepearedTableData = React.useMemo(() => tableData.map((item, pos) => {
    const {
      id,
      name,
      uuid,
      warning,
      activity,
      last_activity,
      country,
      balance,
      deposits,
      deposits_last,
      referral,
      source,
      affiliate,
      sales,
      retention,
      registration,
      note_last,
      deposits_first,
      status,
      language,
      type,
      updated_at,
      color_status,
      user_profile,
    } = item

    return {
      client: (
        <Client
          user_profile={user_profile}
          uuid={uuid}
          id={id}
        />
      ),
      last_activity: (
        <LastActivity
          activity={activity}
          last_activity={last_activity} />
      ),
      country: (
        <Country
          country={country}
          language={language} />
      ),
      balance: (
        <Balance balance={balance} />
      ),
      deposit: (
        <Deposit
          deposits={deposits}
          deposits_first={deposits_first}
          deposits_last={deposits_last} />
      ),
      affiliate_refferer: (
        <Affiliate
          affiliate={affiliate}
          referral={referral}
          source={source} />
      ),
      type: (
        <Type type={type} />
      ),
      sales: (
        <Sales
          color_status={color_status}
          sales={sales}
          status={status}
          type={type} />
      ),
      retention: (
        <Retention
          color_status={color_status}
          retention={retention}
          status={status}
          type={type} />
      ),
      registration: (
        <Registration registration={registration} />
      ),
      last_note: (
        <LastNote
          user_profile={user_profile}
          note_last={note_last} />
      ),
      status: (
        <Status user_profile={user_profile} />
      ),
    }
  }), [data])

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [data])

  const checkSortEnable = (colId): boolean => {
    switch (colId) {
      case 'client':
      // case 'last_activity': TODO: NO BACKEND REALIZATION;
      case 'country':
      case 'balance':
      case 'deposit':
      case 'registration':
      case 'last_note':
        return true
      default:
        return false
    }
  }
  
  const { permissionGiven: PERMISSION_DEMOACCOUNTS_EDIT } = usePermission('admin.trading-account.edit-demo')

  return (
    <>
      <div style={{ 
        display: 'flex', 
        flexDirection: 'row', 
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '12px'
      }}>
        <Button 
          buttonStyle={{
            marginLeft: 0,
          }}
          buttonText='Reset column order'
          onClick={setDefaultColumnsOrder}
        />
        {PERMISSION_DEMOACCOUNTS_EDIT && wtEnabled && !showLoader && (
          <div style={{ 
            display: 'flex', 
            flexDirection: 'row',
            gap: 8
          }}>
            <p>Allow all clients to create demo accounts</p>
            <Toggle 
              icons={false} 
              checked={demoAccountsAllowed} 
              onChange={(e) => {
                if (e.target.checked) {
                  enableGlobalDemoAccounts();
                } else {
                  disableGlobalDemoAccounts();
                }
                setDemoAccountsAllowed(e.target.checked);
              }} 
            />
          </div>
        )}
      </div>
      <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      style={{ height: `calc(100vh - 250px)` }}
      className={cx('clients-table')}
      manualSortHandler={manualSortHandler}
      colSortCheck={colSortCheck || checkSortEnable}
      saveColumnOrder={saveColumnOrder}
      isSelected
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#f9f1ff' : '#ffffff'}`,
          },
        }
      }}
    />
    </>

  )
}

export default memo(ClientsTable)
