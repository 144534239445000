import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { useFieldArray, useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './style-popup.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
import FormInput from 'components/ui/FormInput';
import { notify } from 'utils/notify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import JokerSelectWithSearch from 'components/ui/JokerSelectWithSearch/JokerSelectWithSearch';
import Select from '../../../../components/ui/Select/Select';
import { Trash } from 'phosphor-react';
import { createDynamicStatus, getLanguagesList, getStatusTypes } from '../../../../api/settingsLK';
import Toggle from 'react-toggle';
import { useFilteredList } from '../../../../utils/filteredList';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: (val: string) => void;
  permissionsList?: any;
  actionType: string;
  ruleData?: any;
  departmentsList?: any;
}

const CreateStatusPopup = (props: Props) => {
  const {
    triggerBtn,
    updateComponent,
    permissionsList,
    actionType,
    ruleData,
    departmentsList,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const [active, setActive] = useState(true);
  const contentToScrol = useRef(null);
  const dynamicHeightContent = useRef(null);
  const [statusTypeList, setStatusTypeList] = useState<ListValue[]>([]);
  const [languageList, setLanguageList] = useState<ListValue[]>([]);
  const setDefaultValues = () => {
    return {
      key: '',
      status_type_id: '',
      value: '',
      department_ids: undefined,
    };
  };
  const fetchStatusTypeList = () => {
    getStatusTypes().then((res) => {
      if (res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.id,
          label: opt.key,
        }));
        setStatusTypeList([...opt]);
      }
    });
  };
  const fetchLanguageList = () => {
    getLanguagesList().then((res) => {
      if (res.status === 200) {
        const opt = res.data.map((opt) => ({
          value: opt.code,
          label: opt.name,
        }));
        setLanguageList([...opt]);
      }
    });
  };
  const onOpen = () => {
    fetchStatusTypeList();
    fetchLanguageList();
  };
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'translates',
  });

  useEffect(() => {
    reset(setDefaultValues());
  }, [ruleData, permissionsList, departmentsList]);

  const onSubmit = (close, data) => {
    const sendData = {
      key: data.key,
      active: Number(active),
      status_type_id: getValue(data['status_type_id']),
      translate: {
        en: data.value
      },
    };

    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      addTemplateHandler(sendData, close);
    }
  };
  const getLastIndex = () => {
    return fields.length - 1;
  };

  const addTemplateHandler = (sendData, close) => {
    setIsLoading(true);

    createDynamicStatus(sendData)
      .then((res) => {
        if (res.status === 201) {
          notify({
            type: 'success',
            message: 'Status created successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent('sales');
          reset(setDefaultValues());
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };
  const showDelete = (ind) => {
    if (ind === 0 && fields.length < 2) {
      return false;
    } else return true;
  };
  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };
  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'New status'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')} ref={contentToScrol}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container ref={dynamicHeightContent}>
                      <Tabs className={cx('tabs')}>
                        <TabPanel>
                          <Container>
                            <Row>
                              <Col md={12}>
                                <FormInput
                                  rules={{
                                    required: 'Template key field is required',
                                  }}
                                  label={'Key'}
                                  id="key"
                                  control={control}
                                  name="key"
                                  placeholder={'Key'}
                                  errors={errors.key}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <JokerSelectWithSearch
                                  isRequired={true}
                                  label="Status Type"
                                  control={control}
                                  id="status_type_id"
                                  name="status_type_id"
                                  placeholder={'Search'}
                                  rightaligned={true}
                                  options={statusTypeList}
                                  errors={errors.department_ids}
                                  rules={{
                                    required: 'Status Type field is required',
                                  }}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <div className={cx('filters-toggle')}>
                                <label className={cx('search-clients__toggle')}>
                                  <span className={cx('label')}>Active</span>
                                  <Toggle
                                    name={'active'}
                                    defaultChecked={active}
                                    icons={false}
                                    onChange={() => {
                                      setActive(!active);
                                      // changePermission(currItemList, item);
                                    }}
                                  />
                                </label>
                              </div>
                            </Row>
                            <Row className={cx('mt-15')}>
                              <Col md={12}>
                                <FormInput
                                  rules={{
                                    required: 'Value field is required',
                                  }}
                                  label="Value"
                                  id={`value`}
                                  control={control}
                                  type={'text'}
                                  name={`value`}
                                  placeholder="Value"
                                />
                              </Col>
                            </Row>
                          </Container>
                          <div className={cx('content-controls')}>
                            <Container>
                              <Row>
                                <Col md={12}>
                                  <div className={cx('controls__buttons')}>
                                    <Button
                                      buttonText="Cancel"
                                      buttonType="outline"
                                      size="big"
                                      type="button"
                                      onClick={closeModal.bind(undefined, close)}
                                    />
                                    <LoaderButton
                                      buttonType="primary"
                                      size="big"
                                      showSpinner={isLoading}
                                      // disabled={isLoading || !isDirty}
                                      buttonText={'Create status'}
                                      type="submit"
                                      onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </Container>
                          </div>
                        </TabPanel>
                        <TabPanel></TabPanel>
                      </Tabs>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default CreateStatusPopup;
