import { useEffect } from 'react';

export const useOnClickOutside = (ref, handler, active: boolean = true): void => {
  useEffect(() => {
    if (!active) return;
    const listener = (event): void => {
      const refs = Array.isArray(ref) ? ref : [ref];

      let contains: boolean = false;

      refs.forEach((r): void => {
        if (!r.current || r.current.contains(event.target)) {
          contains = true;
          return;
        }
      });
      event.stopPropagation();
      if (!contains) handler(event);
    };

    document.addEventListener('mousedown', listener);
    document.addEventListener('touchstart', listener);

    return () => {
      if (!active) return;
      document.removeEventListener('mousedown', listener);
      document.removeEventListener('touchstart', listener);
    };
  }, [ref, handler, active]);
};
