import React, { useEffect, useMemo, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './style.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate.tsx';
import Button from 'components/ui/Button';
import { notify } from 'utils/notify.tsx';
import { getDetailTransaction } from '../../../../api/deposit';
import { getShortUuid } from '../../../../utils/getShortUuid';
import { copyToBuffer } from '../../../../utils/copyToBuffer';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import {
  getRejectedStatusesListMethods,
  getTransactionsTypeMethods,
  updateTransactionWithdrawal,
} from '../../../../api/payments';
import Select from '../../../../components/ui/Select/Select.tsx';
import { checkPermission, usePermission } from '../../../../utils/usePermission.tsx';
import { useSelector } from 'react-redux';
import { getEnableMT } from '../../../../constants/selectors.ts';
import { Payment } from 'models/Payments/Payment.ts';
import { ListValue } from 'models/ListValue.ts';
import { PaymentTransactionDetails } from 'models/Payments/TransactionDetails.ts';
import { ExternalId } from '../ExternalId/ExternalId.tsx';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  rule: Payment;
  gateway: string;
};

export type TransactionDetailFormValues = {
  operatorIds: ListValue | string;
  time: number;
  method: ListValue;
  rejection_reason: ListValue;
  reference_id: string | null;
};

const permissionMap = {
  transfer_in: {
    Crypto: 'admin.transfer.approve.crypto',
    Bonus: 'admin.transfer.approve.bonus',
    Credit: 'admin.transfer.approve.credit',
    credit_card: 'admin.transfer.approve.credit_card',
    Wire: 'admin.transfer.approve.wire',
    internal_transfer: 'admin.transfer.approve.internal',
  },
  transfer_out: {
    Crypto: 'admin.transfer.approve.crypto',
    Bonus: 'admin.transfer.approve.bonus',
    Credit: 'admin.transfer.approve.credit',
    credit_card: 'admin.transfer.approve.credit_card',
    Wire: 'admin.transfer.approve.wire',
    internal_transfer: 'admin.transfer.approve.internal',
  },
  deposit: {
    Crypto: 'admin.deposit.approve.crypto',
    Bonus: 'admin.deposit.approve.bonus',
    Credit: 'admin.deposit.approve.credit',
    credit_card: 'admin.deposit.approve.credit_card',
    Wire: 'admin.deposit.approve.wire',
    internal_transfer: 'admin.deposit.approve.internal',
  },
  withdraw: {
    Crypto: 'admin.withdraw.approve.crypto',
    Bonus: 'admin.withdraw.approve.bonus',
    Credit: 'admin.withdraw.approve.credit',
    credit_card: 'admin.withdraw.approve.credit_card',
    Wire: 'admin.withdraw.approve.wire',
    internal_transfer: 'admin.withdraw.approve.internal',
    credit_card_ca: 'admin.withdraw.approve.credit_card_ca',
  },
};

const TransactionDetailPopup = (props: Props) => {
  const { triggerBtn, updateComponent, rule, gateway } = props;

  const { permissionGiven: PermissionShowDepositBalance } = usePermission('admin.deposit.balance');
  const { permissionGiven: PermissionShowDepositBalanceInfo } = usePermission(
    'admin.deposit.balance_info',
  );
  const { permissionGiven: PermissionRejectTransaction } = usePermission(
    'admin.deposit.transaction.withdrawal-moderate',
  );
  const { permissionGiven: PermissionApproveTransaction } = usePermission(
    'admin.deposit.transfer-funds',
  );

  const [isLoading, setIsLoading] = useState(false);
  const [details, setDetails] = useState<PaymentTransactionDetails>();
  const [saveMethod, setSaveMethod] = useState(false);
  const [saveReason, setSaveReason] = useState(false);
  const [paymentsMethodList, setPaymentMethodList] = useState<ListValue[]>([]);
  const [reasonList, setReasonList] = useState<ListValue[]>([]);
  const ENABLE_MT = useSelector(getEnableMT);

  const checkAllPermissionsOfType = (type: string) => {
    const map: Record<string, boolean> = {};

    Object.entries(permissionMap[type]).forEach((entry) => {
      map[entry[0]] = checkPermission(permissionMap[type][entry[0]]);
    });

    return map;
  };

  const allUniquePermissions = useMemo(
    () => (rule?.type ? checkAllPermissionsOfType(rule?.type) : {}),
    [rule],
  );
  const anyUniqueEnabled = useMemo(
    () => Object.values(allUniquePermissions).find((x) => x),
    [allUniquePermissions],
  );

  const checkIfMethodIsAvailable = (method: string) => {
    if (anyUniqueEnabled) {
      return allUniquePermissions[method];
    } else {
      return PermissionApproveTransaction;
    }
  };

  const filterPaymentMethodsByPermissions = (options: ListValue[]): ListValue[] => {
    const result = options.filter((opt) => {
      return checkIfMethodIsAvailable(opt.value.toString());
    });
    return result;
  };

  const getDefaultValue = (data?) => {
    if (data) {
      setSaveMethod(true);
      const method = paymentsMethodList.find((opt) => opt.value == data.method);
      return {
        operatorIds: { value: data?.agent?.id, label: data?.agent?.full_name },
        time: data?.created_at * 1000,
        method: checkIfMethodIsAvailable(data?.method)
          ? { value: data?.method, label: data?.method }
          : null,
        rejection_reason: null,
        reference_id: data?.reference_id,
      };
    }
    return {
      operatorIds: '',
    };
  };
  const {
    handleSubmit,
    reset,
    watch,
    setValue,
    getValues,
    control,
    formState: { errors },
  } = useForm<TransactionDetailFormValues>({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const selectedMethod = watch('method');
  const closeModal = (closeModal) => {
    closeModal();
    reset(getDefaultValue());
    setSaveMethod(false);
    setSaveReason(false);
  };
  const onChangeMethod = (close, status) => {
    const formData =
      status === 'completed'
        ? {
            status: status,
            method: getValues('method').value,
            reference_id: getValues('reference_id'),
          }
        : {
            status: status,
            reject_status: getValues('rejection_reason').value,
          };
    updateTransactionWithdrawal(rule?.id, formData)
      .then((res) => {
        if (res.status === 200) {
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const fetchDetails = () => {
    getDetailTransaction(rule.id, rule.user_id).then((res) => {
      if (res.status === 200) {
        setDetails(res.data.data);
      }
    });
  };

  const fetchPaymentMethodList = () => {
    getTransactionsTypeMethods().then((res) => {
      if (res.status === 200) {
        let opt = res.data.withdraw?.map((item) => {
          return { label: item, value: item };
        });
        setPaymentMethodList(filterPaymentMethodsByPermissions(opt));
      }
    });
  };
  const fetchRejectedStatusesList = () => {
    getRejectedStatusesListMethods().then((res) => {
      if (res.status === 200) {
        let opt = res.data.data.activeStatuses?.map((item) => {
          return { label: item.translate, value: item.id };
        });
        setReasonList(opt);
      }
    });
  };

  const onOpen = () => {
    fetchDetails();
    fetchPaymentMethodList();
    fetchRejectedStatusesList();
  };

  useEffect(() => {
    reset(getDefaultValue(details));
  }, [paymentsMethodList, details]);

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        nested
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            bigSizeMedia={true}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Transaction details'}
            rightContent={
              <div className={cx('content-form', 'popupForm')}>
                <div className={cx('content-controls')}>
                  <Container>
                    <Row>
                      <Col md={3} className={cx('withBorderRight', 'col')}>
                        <p className={cx('title')}>Client</p>
                        <p className={cx('bolder_text')}>
                          {`${rule?.user?.user_profile?.first_name} ${rule?.user?.user_profile?.last_name}`}
                        </p>
                        <span className="uuid" onClick={() => copyToBuffer(rule?.user?.uuid)}>
                          <p className={cx('text')}>{getShortUuid(rule?.user?.uuid)}</p>
                        </span>
                      </Col>
                      {PermissionShowDepositBalanceInfo ? (
                        <Col md={3} className={cx('withBorderRight', 'col')}>
                          <p className={cx('title')}>
                            Balance {ENABLE_MT ? details?.trading_account_id : null}
                          </p>

                          {PermissionShowDepositBalance ? (
                            <p className={cx('bolder_text')}>
                              {details?.trading_account?.amount_type}{' '}
                              {details?.trading_account?.balance}
                            </p>
                          ) : null}
                          <p className={cx('small_text')}>
                            Credit: {details?.trading_account?.amount_type}{' '}
                            {details?.trading_account?.credit}
                          </p>
                        </Col>
                      ) : null}
                      {ENABLE_MT ? (
                        <Col md={3} className={cx('withBorderRight', 'col')}>
                          <p className={cx('title')}>Transaction</p>
                          <span className="uuid" onClick={() => copyToBuffer(rule?.transaction_id)}>
                            <p className={cx('text')}>{rule?.transaction_id}</p>
                          </span>
                        </Col>
                      ) : null}
                      <Col md={3}>
                        <p className={cx('title')}>Payment type</p>
                        <span className={cx(`${rule?.type}`, 'text_light')}>
                          {rule?.type ? rule?.type : '---'}
                        </span>
                      </Col>
                    </Row>
                    <Row className={cx('wrapperCenterBlock')}>
                      <Col md={3} className={cx('withBorderRight', 'col')}>
                        <p className={cx('title')}>DATE & TIME</p>
                        <div>
                          <span className={cx('text')}>
                            {moment.unix(details?.created_at).format('DD.MM.YYYY')}{' '}
                            {moment.unix(details?.created_at).format('HH:mm')}
                          </span>
                        </div>
                      </Col>
                      <Col md={3} className={cx('withBorderRight', 'col')}>
                        <ExternalId
                          id={details?.reference_id}
                          className={cx('title')}
                          name="reference_id"
                          canEdit={rule?.status === 'pending'}
                          control={control}
                          reset={reset}
                          setValue={setValue}
                          getValues={getValues}
                        />
                      </Col>
                      <Col md={3} className={cx('withBorderRight', 'col')}>
                        <p className={cx('title')}>Status</p>
                        <div className={cx('client__status')}>
                          <span className={cx(rule?.status)}>{rule?.status}</span>
                          <span>
                            on{' '}
                            {moment
                              .unix(rule?.status_created_date)
                              .format('DD.MM.YYYY  - HH:mm:ss')}{' '}
                          </span>
                        </div>
                      </Col>
                      <Col md={3}>
                        {details?.assigned_status_types?.reject_status && (
                          <div>
                            <span className={cx('title')}>Reason</span>{' '}
                            <span className={cx('text')}>
                              {details?.assigned_status_types?.reject_status?.translate}
                            </span>
                          </div>
                        )}
                      </Col>
                    </Row>
                    <Row className={cx('blueBlock')}>
                      <Col sm={6} className={cx('leftBlock')}>
                        <p className={cx('title')}>Amount</p>
                        <p className={cx('bigText')}>
                          {rule?.amount} {rule?.amount_type}
                        </p>
                      </Col>
                      <Col sm={6}>
                        <p className={cx('title')}>Payment Method</p>
                        <p className={cx('bigText')}>{rule?.method}</p>
                      </Col>
                    </Row>
                    {rule?.status === 'pending' && !gateway && (
                      <Row>
                        <Col md={6}>
                          {PermissionRejectTransaction && (
                            <Select
                              name="rejection_reason"
                              placeholder="Choose rejection reason"
                              label="Change rejection reason"
                              control={control}
                              errors={errors.rejection_reason}
                              options={reasonList}
                              handleChange={() => {
                                setSaveReason(true);
                              }}
                              rules={{
                                required: true,
                              }}
                            />
                          )}
                          {paymentsMethodList.length === 0 && (
                            <p className={cx('permission_error')}>
                              You don't have permission to approve this transaction
                            </p>
                          )}
                          {rule?.status === 'pending' && paymentsMethodList.length > 0 && (
                            <div className={cx('wrapperBtn-end')}>
                              {PermissionRejectTransaction ? (
                                <Button
                                  disabled={!saveReason}
                                  buttonText="Reject"
                                  buttonType="outline"
                                  size="small"
                                  type="button"
                                  onClick={(event) => {
                                    event.currentTarget.disabled = true;
                                    onChangeMethod(close, 'canceled');
                                  }}
                                />
                              ) : null}
                            </div>
                          )}
                        </Col>
                        {!(rule?.type == 'deposit' && rule?.method == 'credit_card_ca') && (
                          <Col md={6}>
                            {paymentsMethodList.length > 0 && (
                              <Select
                                name="method"
                                placeholder="Choose payment method"
                                label="Change payment method"
                                control={control}
                                errors={errors.method}
                                options={paymentsMethodList}
                                handleChange={() => {
                                  setSaveMethod(true);
                                }}
                              />
                            )}
                            {paymentsMethodList.length === 0 && (
                              <p className={cx('permission_error')}>
                                You don't have permission to approve this transaction
                              </p>
                            )}
                            {rule?.status === 'pending' && paymentsMethodList.length > 0 && (
                              <div className={cx('wrapperBtn-end')}>
                                <Button
                                  disabled={!saveMethod}
                                  buttonText="Approve"
                                  buttonType="outline"
                                  size="small"
                                  type="button"
                                  onClick={(event) => {
                                    event.currentTarget.disabled = true;
                                    onChangeMethod(close, 'completed');
                                  }}
                                />
                              </div>
                            )}
                          </Col>
                        )}
                      </Row>
                    )}

                    <Row>
                      <Col md={12}>
                        <div className={cx('controls__buttons')}>
                          <Button
                            buttonText="OK"
                            buttonType="outline"
                            size="big"
                            type="button"
                            onClick={closeModal.bind(undefined, close)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default TransactionDetailPopup;
