import React from 'react';
import classNames from 'classnames/bind';

import styles from './error-page.module.scss';
import Page from '../Page/Page';
import { Link } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useAppSelector } from 'store';
import { getLogo } from 'constants/selectors';

const cx = classNames.bind(styles);

type Props = { isPrivateRoute?: boolean };

const ErrorPage = (props: Props) => {
  const { isPrivateRoute } = props;

  const logo = useAppSelector(getLogo)

  return (
    <Page>
      <div className={cx('error-page')}>
        {!isPrivateRoute && (
          <img className={cx('error-page__logo')} src={logo} />
        )}
        <div className={cx('error-page__img')}></div>
        <div className={cx('error-page__description')}>
          <span className={cx('error-page__title')}>
            <FormattedMessage id="errorPage.title" defaultMessage="Page not found" />
          </span>
          <span className={cx('error-page__text')}>
            <FormattedMessage
              id="errorPage.text"
              defaultMessage="The page you are looking for does not exist, it may have been moved or deleted altogether. Please go to the main page by clicking the button below."
            />
          </span>
        </div>
        <div className={cx('error-page__link')}>
          {!isPrivateRoute ? (
            <>
              <Link to="/" className={cx('link-text')}>
                <FormattedMessage id="errorPage.login" defaultMessage="Log in" />
              </Link>
            </>
          ) : (
            <Link to="/search-clients" className={cx('link-text')}>
              <FormattedMessage id="errorPage.home" defaultMessage="Home" />
            </Link>
          )}
        </div>
      </div>
    </Page>
  );
};

export default ErrorPage;
