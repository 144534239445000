import React from 'react';
import classNames from 'classnames/bind';
import styles from './tooltip.module.scss';
import ReactTooltip from 'react-tooltip';
import { ITooltip } from "../../../models/components/Tooltip/Tooltip.ts";

const cx = classNames.bind(styles);

const Tooltip = ({ params }: ITooltip) => {

  const id: string = params.dataFor + (Math.random() * 100000000).toFixed().toString();
  const icon: JSX.Element = params['icon'] ? (
    params['icon']
  ) : (
    <svg className={cx('tooltip__icon')} width="18" height="18">
      <use href="#ic_question"></use>
    </svg>
  );

  return (
    <div className={cx('tooltip')}>
      <span data-tip data-for={id} data-event="click">
        {icon}
      </span>
      <ReactTooltip
        className={cx('tooltip__before')}
        id={id}
        globalEventOff={params.dataEvent ?? 'click'}
        delayHide={params.delayHide ?? 1000}
      >
        {params.tooltipText}
      </ReactTooltip>
    </div>
  );
};

export default Tooltip;
