import { Alert } from "../../../../models/Ticker";
import { isFuture, isWithinInterval } from "date-fns";

export const getAlertMessageType = (item: Alert) => {
    if (!item.status) {
        return 'past';
    }

    const isActive = isWithinInterval(Date.now(), { start: item.start_date, end: item.end_date });
    if (isActive) {
        return 'active'
    }

    const isScheduled = isFuture(item.start_date);
    if (isScheduled) {
        return 'scheduled';
    }

    return 'past'
};