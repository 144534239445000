import { useEffect, useRef, useState } from "react";
import { getFillList } from "./helpers";
import { Alert } from "../../../models/Ticker";
import './Ticker.scss';

export const Ticker = ({ alerts, color, speed = 1 }) => {
    const time = 100 / (speed + 1);

    const wrapElement = useRef(null);
    const messageElement = useRef(null);

    const [moveLeft, setMoveLeft] = useState(0);
    const [showAlerts, setShowList] = useState<Alert[]>(alerts);
    const [realTime, setRealTime] = useState(time);

    useEffect(() => {
        const containerWidth = Math.floor(wrapElement.current.offsetWidth);
        const areaWidth = Math.floor(messageElement.current.scrollWidth);

        if (areaWidth > containerWidth) {
            const copyTimes = Math.max(2, Math.ceil((containerWidth * 2) / areaWidth));
            const newMoveLeft = areaWidth * Math.floor(copyTimes / 2);
            const newRealTime = time * parseFloat((newMoveLeft / containerWidth).toFixed(2));

            setShowList(getFillList(alerts, copyTimes));
            setMoveLeft(newMoveLeft);
            setRealTime(newRealTime);
        }
    }, [alerts.length]);

    const animation = { animationDuration: realTime + 's', transform: `translateX(-${moveLeft}px)` };

    return (
        <div className='ticker' style={{ background: color }}>
            <div className="ticker-message-wrap" ref={wrapElement}>
                <div ref={messageElement} className="ticker-message" style={animation}>
                    {showAlerts.map((item, i) => <span key={item.id + i}>{item.message}</span>)}
                </div>
            </div>
        </div>
    )
}
