import styles from './style.module.scss';
import classNames from 'classnames/bind';
import { X } from 'phosphor-react';
import React, { FC, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import { IMultiInput } from "../../../models/components/JokerMultiInput/JokerMultiInput.ts";

const cx = classNames.bind(styles);
const JokerMultiInput: FC<IMultiInput> = (props) => {

  const { label, placeholder, list, checkValidMultiInput, err, errorText = '', required } = props;
  const [val, setVal] = useState<string>('');
  const [currList, setCurrList] = useState<string[]>(list);
  const { field } = useController(props);

  useEffect((): void => {
    setCurrList(list);
  }, [list]);

  const onKeyDown = (e): void => {
    if (e.code === 'Enter') {
      const newArr: string[] = [...currList, val];
      field.onChange(newArr);
      e.preventDefault();
      setCurrList(newArr);
      checkValidMultiInput(newArr);
      setVal('');
    }
  };
  const addVal = (val): void => {
    setVal(val);
  };
  const removeItem = (curr): void => {
    const arr: string[] = currList.filter((item: string) => {
      if (item !== curr) return item;
    });
    checkValidMultiInput(arr);
    field.onChange([...arr]);
    setCurrList(arr);
  };
  return (
    <div className={styles.mainWrapper}>
      <label className={styles.label}>{label}{required && <span style={{ color: 'red' }}> *</span>}</label>
      <div className={styles.wrapperInput}>
        {currList &&
          currList.map((item: string, ind: number) => {
            return (
              <div key={ind} className={styles.wrapperList}>
                <span>{item}</span>
                <button
                  onClick={(): void => {
                    removeItem(item);
                  }}
                  type={'button'}
                  className={styles.btnDelete}
                >
                  <X size={10} />
                </button>
              </div>
            );
          })}
        <input
          value={val}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            addVal(e.target.value);
          }}
          className={styles.inputStyle}
          placeholder={placeholder}
          onKeyDown={onKeyDown}
        />
      </div>
      {err?.length > 0 && (
        <p className={cx('errorName')}>
          {errorText}
          {err.map((item: string, ind: number) => {
            return (
              <span className={cx('errorText')}>
                {item} {ind !== err.length - 1 && ','}
              </span>
            );
          })}{' '}
        </p>
      )}
    </div>
  );
};
export default JokerMultiInput;
