"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = Svg;

var _react = _interopRequireDefault(require("react"));

function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

function _extends() {
  _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  return _extends.apply(this, arguments);
}

function Svg(p) {
  return _react["default"].createElement("svg", _extends({
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    focusable: "false",
    role: "presentation"
  }, p));
}