export const clearEmptyFilter = (data) => {
  const filtered = {};

  for (const key of Object.keys(data)) {
    if (data[key]) {
      filtered[key] = data[key];
    }
  }

  return filtered;
};
