import { APIGetAllCryptoWalletsResponse, APIGetAllCryptoTransactionsResponse, APIGetCryptoWalletResponse, APIGetCryptoTransactionResponse, APIGetAllCryptoMethods } from 'models/API/CustomerService/Crypto';
import API from '../../api';

export const getCryptoWalletsAll = (params) => API.get<APIGetAllCryptoWalletsResponse>(`/api/v1/crypto-payment/all${params}`);

// method unused
export const getCryptoWallets = (params) => API.get(`/api/v1/crypto-payment${params}`);

export const getWallet = (id) => API.get<APIGetCryptoWalletResponse>(`/api/v1/crypto-payment/${id}`);

// response unused
export const addWallet = (data) => API.post(`/api/v1/crypto-payment`, data);

// response unused
export const deleteWallet = (id) => API.delete(`/api/v1/crypto-payment/${id}`);

// response unused
export const restoreWallet = (id) => API.post(`/api/v1/crypto-payment/${id}/restore`);

// response unused
export const setWalletToUsers = (data) => API.post(`/api/v1/crypto-payment/set-wallet-users`, data);

// response unused
export const updateWalletToUser = (id, data) =>
  API.put(`/api/v1/crypto-payment/wallet-to-user/${id}`, data);

export const getCryptoTransactions = (params) =>
  API.get<APIGetAllCryptoTransactionsResponse>(`/api/v1/crypto-payment/crypto-transaction${params}`);

export const getCryptoTransaction = (id) =>
  API.get<APIGetCryptoTransactionResponse>(`/api/v1/crypto-payment/crypto-transaction/${id}`);

// response unused
export const changeWebHookStatus = (id, params) =>
  API.put(`/api/v1/crypto-payment/crypto-transaction-status/${id}`, params);

// response unused
export const changeCryptoTransactionStatus = (id, params) =>
  API.put(`/api/v1/crypto-payment/crypto-transaction/${id}`, params);

// method unused
export const getCryptoRate = (coinName) => API.get(`api.coincap.io/v2/rates/asdfsaf`);

export const getSystemDataMethod = () => API.get<APIGetAllCryptoMethods>(`/api/v1/dictionary/system`);
