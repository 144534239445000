import classNames from 'classnames/bind';
import React from 'react';

import { CirclePicker } from 'react-color';
import { useController } from 'react-hook-form';

import styles from './circlePicker.module.scss';
import { DEFAULT_COLORS } from "../../../constants/colors.js";

const cx = classNames.bind(styles);

const CircleColorsPicker = (props) => {
  const { field } = useController(props);
  const handleChange = (value): void => {
    field.onChange(value.hex);
  };

  return (
    <div className={cx('circle-picker')}>
      <CirclePicker
        color={props?.selected_color}
        colors={DEFAULT_COLORS}
        onChange={handleChange}
      />
    </div>
  );
};
export default CircleColorsPicker;
