import React, { FC, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { useController } from 'react-hook-form';
import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { CalendarBlank } from 'phosphor-react';
import { useOnClickOutside } from '../../../utils/useClickOutside';
import styles from './calendarPicker.module.scss';
import './customStyle.scss';
import moment from 'moment';
import Tooltip from '../Tooltip/Tooltip';
import { endOfDay } from 'date-fns';
import { ICalendar } from "../../../models/components/CalendarPicker/CalendarPicker";

const cx = classNames.bind(styles);

const CalendarPicker: FC<ICalendar> = (props) => {
  const { field } = useController(props);
  const [showDatePicker, setShowDatePicker] = useState<boolean>(false);
  const contentRef = useRef(null);

  const handleSelect = (date): void => {
    const dateSelect: number = Number(moment(endOfDay(date)).format('X')) * 1000;
    field.onChange(dateSelect);
  };

  useOnClickOutside(contentRef, (): void => {
    setShowDatePicker(false);
  });

  return (
    <div className={cx('date-range')}>
      <div className={cx('label-wrap')}>
        {props.label && (
          <label htmlFor={props.id} className={props.errors ? cx('label', 'error') : cx('label')}>
            {props.label}
            {props.rules && props.rules.required ? <span className={cx('required')}>*</span> : ''}
          </label>
        )}
        {/*{props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}*/}
      </div>
      <div className={cx('date-input')}>
        <input
          className={cx('date-input__input')}
          type='text'
          placeholder={props.placeholder || ''}
          value={field.value ? moment(field.value).format('DD.MM.YYYY') : null}
          onFocus={() => setShowDatePicker(true)}
          disabled={props.disabled}
        />
        <span className={cx('icon')}>
          <CalendarBlank size={24} />
        </span>
      </div>
      {showDatePicker && (
        <div className={cx('date-range')} ref={contentRef}>
          <Calendar onChange={(e) => handleSelect(e)} {...props} />
        </div>
      )}
    </div>
  );
};

export default CalendarPicker;
