import classNames                     from 'classnames/bind';
import React, { useEffect, useState } from 'react';

import { Row }               from 'react-bootstrap';
import 'react-simple-tree-menu/dist/main.css';
import { getBrandMethod }    from '../../api/hierarchy/index';
import Page                  from '../Page/index.js';
import { DashboardLayout }   from '../UserView/DashboardLayout';

import styles                    from './hierarchy-page.module.scss';
import HierarchyComponentOffices from './HierarchyComponentOffices';
import { HierarchyRoot } from 'models/Hierarchy/HierarchyData.js';

const cx = classNames.bind(styles);
const HierarchyPage = (props) => {

  const [data, setData] = useState<HierarchyRoot>();

  const title = 'Hierarchy';
  useEffect(() => {
    document.title = title;
  }, [title]);
  useEffect(() => {
    getBrandMethod().then((res) => {
      if (res.status === 200) {
        setData({ brand: res.data.data });
      }
    })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <Row>
              <div className={cx('header')}>
                <div className={cx('search-result')}>
                  <span>Hierarchy</span>
                  <span></span>
                </div>
              </div>
            </Row>
          </div>
          <div className={cx('container__block')}>
            <div>
              {data?.brand?.map((item) => {
                return <HierarchyComponentOffices data={item} />;
              })}
            </div>
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default HierarchyPage;
