export const getTablePageParams = (options, perPageCount, tableLinks) => {
  if (!options) {
    return '';
  }

  const { page, navigate, perPage } = options;

  let params = `per_page=${perPage || perPageCount}`;

  if (page) {
    params = `${params}&page=${page}`;
  } else if (navigate && tableLinks) {
    let toPage = tableLinks[navigate].split('?').reverse()[0];

    const splited = toPage.split('&');
    const finalToPage = splited.filter((item) => !item.includes('query')).join('&');

    params = `${finalToPage}&${params}`;
  }

  return params;
};