import API from '../api';
import { IPostDataRefresh } from "../../utils/useCheckAutoRefresh.tsx";

export const uploadDataAutoRefresh = (data: IPostDataRefresh) => (
    API.post(`/api/v1/operator/update-page`, data)
)

export const getDataAutoRefresh = () => (
  API.get(`/api/v1/operator/update-page`)
)
