import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { useController } from 'react-hook-form';

import styles from './checkbox.module.scss';

const cx = classNames.bind(styles);

const CheckBoxComponent = (props) => {
  const { field } = useController(props);
  const { label, setOpen } = props;
  const [checked, setCheck] = useState<boolean>(field.value);

  const onChange = (value): void => {
    field.onChange(value);
    setCheck(!field.value)
    setOpen && setOpen(!field.value)
  };

  return (
    <>
      <div className={cx('input-wrap')}>
        <input
          {...field}
          id={props.id}
          className={props.errors ? cx('input', 'error') : cx('input')}
          type={props.type}
          placeholder={props.placeholder}
          checked={field.value}
          onChange={onChange}
        />
        <div className={cx('label-wrap', props.labelNoClickable ? 'no-click' : '')}>
          {!props.labelNoClickable && (
            <label
              htmlFor={props.id}
              className={props.errors ? cx('input__label', 'error') : cx('input__label')}
            >
              {label}
              {props.rules && props.rules.required ? <span className={cx('required')}>*</span> : ''}
            </label>
          )}
          {props.labelNoClickable && (
            <>
              <i
                className={cx(
                  'checkbox-icon',
                  checked ? 'icon-checked' : '',
                  props.errors ? 'error' : '',
                )}
                onClick={() => {
                  setCheck(!checked);
                }}
              ></i>
              <div
                className={
                  props.errors ? cx('input__label-no-click', 'error') : cx('input__label-no-click')
                }
              >
                {label}
                {props.rules && props.rules.required ? (
                  <span className={cx('required')}>*</span>
                ) : (
                  ''
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CheckBoxComponent;
