import cn from 'classnames';
import { ReactNode } from 'react';
import { CommonInputProps } from '../';

import './InputField.scss';

type Props = Omit<CommonInputProps, 'rules' | 'name' | 'error'> & {
  error?: string | undefined;
  className?: string;
  children: ReactNode;
};

export const InputField = ({id, label, error, className, tooltip, children}: Props) => {
  const LabelTag = id ? 'label' : 'span';
  const LabelAttrs = {
    className: 'label-title',
    ...(id ? {htmlFor: id} : {}),
  };

  return (
    <div className={cn('input-field', className)}>
      <div className="input-field-label">
        {label ? <LabelTag {...LabelAttrs}>{label}</LabelTag> : null}
      </div>
      <div className="input-wrap">{children}</div>
      {error ? <p className="error-message">{error}</p> : null}
    </div>
  );
};
