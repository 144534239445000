import classNames                                      from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import { Row }                                from 'react-bootstrap';

import { useForm }                          from 'react-hook-form';
import { useParams }                        from 'react-router-dom';
import { usePermission } from '../../utils/usePermission';
import styles                               from './page-style.module.scss';
import TradingAccFilter                     from './TradingAccFilter';
import { getTablePageParams } from '../../utils/getTablePageParams';
import { findTradingAccMethod, getTradingAccStatuses } from "../../api/tradingAcc";
import TradingAccTable from "../../components/ui/Table/TradingAccTable";
import { DashboardLayout } from "../UserView/DashboardLayout";
import Page from "../Page";
import TablePagination from "../../components/ui/TablePagination";
import { notify } from "../../utils/notify";
import { TableLinks, TableMeta } from 'models/Table';
import { ClientTradingAccountFilter } from 'models/Clients/TradingAccounts/Filter';
import { ClientTradingAccount } from 'models/Clients/TradingAccounts/Account';
import { ListValue } from 'models/ListValue';
import { useAppDispatch } from "../../store";
import { toggleScrollToTop } from "../../store/slice/tableSlice.ts";
import { useCheckAutoRefresh } from "../../utils/useCheckAutoRefresh.tsx";

const cx = classNames.bind(styles);

const TradingAccPage = () => {
  const { permissionGiven: PermissionShowListTradingAccounts } = usePermission('admin.trading-account.index');

  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState(true);
  const [tradingAccList, setTradingAccList] = useState<ClientTradingAccount[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<ClientTradingAccountFilter>();
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const [taStatusesOpt, setTaStatusesOpt] = useState<ListValue[]>([]);
  // const [taTypesOpt, setTaTypesOpt] = useState([]);

  const componentMounted = useRef(false);

  const { id } = useParams();
  const checkAutoRefresh = useCheckAutoRefresh('Page Trading accounts')

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  document.title = 'Trading Accounts';
  const fetchTAStatusesOpt = () => {
    getTradingAccStatuses()
      .then((res) => {
        if (res.status === 200) {
          setTaStatusesOpt(
            res.data.data.map((item) => ({
              value: item,
              label: item,
            })),
          );
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };
  // const fetchTATypesOpt = () => {
  //   getTradingAccTypes()
  //     .then((res) => {
  //       if (res.status === 200) {
  //         setTaTypesOpt(
  //           res.data.data.map((item) => ({
  //             value: item,
  //             label: item,
  //           })),
  //         );
  //       }
  //     })
  //     .catch((error) => {
  //       if (error && error.response.data !== null) {
  //         notify({
  //           type: 'error',
  //           message: error.response,
  //           timeOut: 3000,
  //         });
  //       }
  //     });
  // };

  const fetchTradingAccList = (options?, data?) => {
    setIsListLoading(true);
    findTradingAccMethod(getTablePageParams(options, perPageCount, tableLinks), data)
      .then((res) => {
        if (res.status === 200) {
          setTradingAccList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setIsListLoading(false);
        }
      })
      .catch((error) => {
        if (error && error?.response?.data !== null) {
          setIsListLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect(() => {
    if (PermissionShowListTradingAccounts) {
      componentMounted.current = true;
      fetchTradingAccList();
    }

    return () => {
      componentMounted.current = false;
    };
  }, [PermissionShowListTradingAccounts]);

  const getSortFiltersForPageNavigation = () => {
    if (filters) {
      return {
        ...filters,
      };
    }
  };
  const getTradingAccList = (options, data) => {
    setIsListLoading(true);

    let postData;

    findTradingAccMethod(getTablePageParams(options, perPageCount, tableLinks), data || postData || filters)
      .then((res) => {
        if (componentMounted.current) {
          setTradingAccList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  useEffect(() => {
    fetchTAStatusesOpt();
    // fetchTATypesOpt();
  }, []);

  return (
    <>
      <DashboardLayout>
        <Page>
          <div className={cx('container')}>
            <div className={cx('container__header')}>
              <Row>
                <div className={cx('header', selectedRows?.length ? 'padding' : '')}>
                  <div className={cx('search-result')}>
                    <span>
                      <strong>{tableMeta?.total}</strong> Trading accounts
                    </span>
                    <span></span>
                  </div>
                  {/* <div className={cx('header-controls')}>
                      <button className={cx('hide-btn')} onClick={() => setExpanded(!expanded)}>
                        {expanded && <CaretDoubleUp size={22} color="rgba(0, 0, 0, 0.1)" />}
                        {!expanded && <CaretDoubleDown size={22} color="rgba(0, 0, 0, 0.1)" />}
                      </button>
                    </div> */}
                </div>
              </Row>
            </div>
            <div className={cx('container__filters-block', !expanded && 'hide')}>
              <TradingAccFilter
                taStatusesOpt={taStatusesOpt}
                // taTypesOpt={taTypesOpt}
                onSubmit={(data) => {
                  setFilters(data);
                  getTradingAccList({ navigate: 'first', perPage: data?.filter?.limit }, data);
                }}
                reloadFilters={isListLoading}
                queryMessage={queryResponseMessage}
              />
            </div>
            {PermissionShowListTradingAccounts ?
              <div className={cx('container__block')}>
                <TradingAccTable
                  data={tradingAccList}
                  perPage={perPageCount}
                  showLoader={isListLoading}
                  onRowSelect={setSelectedRows}
                  userId={id}
                />
                {tradingAccList?.length ? (
                  <TablePagination
                    goToFirstPage={() => {
                      fetchTradingAccList({ navigate: 'first' }, getSortFiltersForPageNavigation());
                      dispatch(toggleScrollToTop())
                    }}
                    goToPrevPage={() => {
                      fetchTradingAccList({ navigate: 'prev' }, getSortFiltersForPageNavigation());
                      dispatch(toggleScrollToTop())
                    }}
                    goToNextPage={() => {
                      fetchTradingAccList({ navigate: 'next' }, getSortFiltersForPageNavigation());
                      dispatch(toggleScrollToTop())
                    }}
                    goToLastPage={() => {
                      fetchTradingAccList({ navigate: 'last' }, getSortFiltersForPageNavigation());
                      dispatch(toggleScrollToTop())
                    }}
                    // hidePageSelect={true}
                    currentPage={tableMeta?.current_page}
                    pagesLength={tableMeta?.last_page}
                    perPageChange={(value) => {
                      setPerPageCount(value);
                      fetchTradingAccList({ perPage: value }, getSortFiltersForPageNavigation());
                    }}
                    goToSelectedPage={(page) => {
                      fetchTradingAccList({ page }, getSortFiltersForPageNavigation());
                    }}
                    isFirstPageDisable={() => tableMeta?.current_page === 1}
                    isPrevPageDisable={() => !tableLinks?.prev}
                    isNextPageDisable={() => !tableLinks?.next}
                    isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
                  />
                ) : (
                  ''
                )}
              </div>
              : null
            }
          </div>
        </Page>
      </DashboardLayout>
    </>
  );
};

export default TradingAccPage;
