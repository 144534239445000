import React from 'react';
import classNames from 'classnames/bind';

import styles from './formTextarea.module.scss';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';

const cx = classNames.bind(styles);

const FormTextarea = (props) => {
  const { field } = useController(props);
  return (
    <div className={cx('textarea-container')}>
      <div className={cx('label-wrap')} style={props.labelStyle}>
        {props.label && (
          <label htmlFor={props.id} className={props.errors ? cx('label', 'error') : cx('label')}>
            {props.label}
            {props.rules && props.rules.required ? <span className={cx('required')}>*</span> : ''}
          </label>
        )}
        <div className={cx('icon-container')}>
          {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
        </div>
      </div>
      <div className={props.errors ? cx('textarea-wrap', 'error') : cx('textarea-wrap')}>
        <textarea
          {...field}
          {...props}
          className={props.errors ? cx('textarea', 'error') : cx('textarea')}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onFocus={props.onFocus}
          value={props.value || (field.value === null ? '' : field.value)}
        />
        {props.errors && <p className={cx('errorName')}>{props.errors.message}</p>}
      </div>
    </div>
  );
};

export default FormTextarea;
