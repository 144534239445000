import React, { useState } from "react";
import Button from "../../../../../components/ui/Button";
import { closeJokerModal, selectModalProps } from "../../../../../store/slice/modalSlice";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { deleteAlert, updateAlertStatus } from "../../../../../store/slice/tickerSlice";
import { ModalTemplate } from "../../../../../components/ui/Modal/components/JokerModalTemplate/JokerModalTemplate";
import './DeleteMessageModal.scss';

export const SYSTEM_MESSAGE_DELETE_MODAL_KEY = 'SYSTEM_MESSAGE_DELETE_MODAL_KEY';

export const DeleteMessageModal = () => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const modalProps = useAppSelector(selectModalProps);
    const isArchive = modalProps?.archive;

    const handleCloseModal = () => dispatch(closeJokerModal());

    const handleConfirm = async () => {
        setIsLoading(true);

        if (isArchive) {
            await dispatch(updateAlertStatus({ id: modalProps?.message?.id, status: false }));
        } else {
            await dispatch(deleteAlert(modalProps?.message));
        }

        setIsLoading(false);
        dispatch(closeJokerModal());
    }

    return (
        <ModalTemplate
            id={SYSTEM_MESSAGE_DELETE_MODAL_KEY}
            title={isArchive ? 'Archive message' : 'Delete message'}
            onClose={handleCloseModal}
            size='md'
        >
            <div className='system-message-delete-modal'>
                <div className="system-message-delete-title">
                    <div>{`Are you sure, you want to ${isArchive ? 'archive' : 'delete'} the message?`}</div>
                    {isArchive ? (
                        <div>The message will be moved to the 'Past' tab. You'll be able to republish it later.</div>
                    ) : (
                        <div>Once deleted, it will no longer be possible to restore it.</div>
                    )}
                </div>
                <div className="modal-actions">
                    <Button
                        buttonText='Cancel'
                        buttonType="outline"
                        size="big"
                        type="button"
                        onClick={handleCloseModal}
                    />
                    <Button
                        className={isArchive ? '' : 'delete-message'}
                        buttonType="delete"
                        size="big"
                        disabled={isLoading}
                        buttonText={isArchive ? 'Archive' : 'Delete'}
                        onClick={handleConfirm}
                    />
                </div>
            </div>
        </ModalTemplate>
    )
};
