import getUTCtime from 'utils/getUTCTime';
import API from '../api';

export const saveNote = (data) => API.post('/api/v1/users/create-note', data);

export const getUserNotesList = (data, id) => {
  const params = {
    from:
      data && data.created_at
        ? `${'&date_from=' + getUTCtime('startDay', data.created_at.from)}`
        : '',
    to: data && data.created_at ? `${'&date_to=' + getUTCtime('endDay', data.created_at.to)}` : '',
    department_id: data && data.department_id ? `${'&department_id=' + data.department_id.id}` : '',
  };
  return API.get(`/api/v1/notes?user_id=${id}${params.department_id}${params.from}${params.to}`);
};

export const searchNotesListAll = (params, data) =>
  API.post(`/api/v1/search/user/notes/all${params}`, data);

export const searchNotesList = (params, data) => API.post(`/api/v1/search/notes${params}`, data);

export const fetchPinnedNotesList = (id, params) =>
  API.get(`/api/v1/leads/${id}/notes`, { params });

export const updateNote = (data, id) => API.put(`/api/v1/notes/${id}`, data);
export const deleteNote = (id) => API.delete(`/api/v1/notes/${id}`);

export const saveLeadNote = (data) => API.post('/api/v1/leads/create-note', data);
