import classNames from 'classnames/bind';
import Button from 'components/ui/Button/Button';
import PopupTemplate from 'components/ui/PopupTemplate';
import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import Popup from 'reactjs-popup';
import { setStyleListMethod } from '../../../../api/settingsLK/index';
import LoaderButton from '../../../../components/ui/LoaderButton/index.js';
import { COLORS } from '../../../../constants/colors.js';
import { notify } from '../../../../utils/notify.tsx';
import styles from './reset-colors-popup.module.scss';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
};

const ResetColorsPopup = (props: Props) => {
  const { triggerBtn, updateComponent } = props;

  const [isLoading, setIsLoading] = useState(false);

  const closeModal = (closeModal) => {
    closeModal();
  };

  const onSubmit = async (close) => {
    try {
      setIsLoading(true);
      const sendData = {
        body_background_color: COLORS.BODY_BACKGROUND_COLOR,
        border_background_color: COLORS.BORDER_BACKGROUND_COLOR,
        disabled_text_color: COLORS.DISABLED_TEXT_COLOR,
        header_background_color: COLORS.HEADER_BACKGROUND_COLOR,
        main_color: COLORS.MAIN_COLOR,
        main_color_hover: COLORS.MAIN_COLOR_HOVER,
        main_light_color: COLORS.MAIN_LIGHT_COLOR,
        primary_text_color: COLORS.PRIMARY_TEXT_COLOR,
        secondary_text_color: COLORS.SECONDARY_TEXT_COLOR,
        sidebar_background_color: COLORS.SIDEBAR_BACKGROUND_COLOR,
        // currency_color: COLORS.CURRENCY_COLOR,
        currency_color: '#fff',
      };
      const res = await setStyleListMethod(sendData);
      if (res) {
        notify({
          type: 'success',
          message: 'Style changed successfully',
          timeOut: 3000,
        });
        setIsLoading(false);
        closeModal(close);
        updateComponent();
      }
    } catch (error) {
      notify({
        type: 'error',
        message: error.response,
        timeOut: 3000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Reset all colors to default'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form')}>
                  <Container>
                    <div className={cx('content__title')}>
                      <span>All selected and saved colors will be reset to default colors.</span>
                    </div>
                  </Container>
                  <div className={cx('content-controls')}>
                    <div className={cx('controls__buttons')}>
                      <Button
                        buttonText="Close"
                        buttonType="outline"
                        size="big"
                        onClick={closeModal.bind(undefined, close)}
                      />
                      <LoaderButton
                        buttonType="primary"
                        size="big"
                        showSpinner={isLoading}
                        disabled={isLoading}
                        buttonText={'Reset colors'}
                        type="submit"
                        onClick={onSubmit.bind(undefined, close)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default ResetColorsPopup;
