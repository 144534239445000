import classNames           from 'classnames/bind';
import React, { FC, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { logout }           from '../../store/slice/authSlice.ts';
import Page                 from '../Page/Page';
import { DashboardLayout }  from '../UserView/DashboardLayout';

import styles from './not-permission-page.module.scss';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from "../../store";
import { getLogo } from 'constants/selectors.ts';

const cx = classNames.bind(styles);

interface IPrivateRoute {
  isPrivateRoute: boolean,
  children?: React.ReactNode
}

const NotPermissionPage: FC = ({ isPrivateRoute }: IPrivateRoute) => {

  const dispatch = useAppDispatch();
  const navigate = useNavigate()

  useEffect((): void => {
    const token: string = localStorage.getItem('token');
    if (!token) {
      navigate('/');
    }
  }, [])

  const logo = useAppSelector(getLogo)

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('error-page')}>
          {!isPrivateRoute && (
            <img className={cx('error-page__logo')} src={logo} />
          )}
          <div className={cx('error-page__description')}>
          <span className={cx('error-page__title')}>
            No access
          </span>
          <span className={cx('error-page__text')}>
            You have no access to this page
          </span>
          </div>
          <div className={cx('error-page__link')}>
            <>
              <div className={cx('link-text')} onClick={() => dispatch(logout())}>
                Log out
              </div>
            </>
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default NotPermissionPage;
