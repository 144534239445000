import { getCryptoTransactions }              from 'api/customer-service/crypto-payments';
import classNames                             from 'classnames/bind';
import TablePagination                        from 'components/ui/TablePagination/TablePagination';
import Page                                   from 'pages/Page/Page';
import { DashboardLayout }                    from 'pages/UserView/DashboardLayout';
import { useEffect, useRef, useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import { usePermission }   from '../../../utils/usePermission.tsx';

import styles                    from './crypto-transactions-page.module.scss';
import CryptoTransactionsFilters from './CryptoTransactionsFilters/CryptoTransactionsFilters';
import CryptoTransactionsTable from '../../../components/ui/Table/CryptoTransactionsTable/index.js';
import { CryptoTransaction } from 'models/CustomerService/CryptoTransaction.js';
import { CryptoTransactionFilter } from 'models/CustomerService/CryptoTransactionFilter.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { useAppDispatch } from "../../../store";
import { toggleScrollToTop } from "../../../store/slice/tableSlice.ts";
import { useCheckAutoRefresh } from "../../../utils/useCheckAutoRefresh.tsx";

const cx = classNames.bind(styles);

const CryptoTransactionsPage = () => {

  const { permissionGiven: PermissionViewCryptoTransactions } = usePermission('admin.crypto_transactions.index');

  const dispatch = useAppDispatch();

  const [transactionList, setTransactionList] = useState<CryptoTransaction[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [filters, setFilters] = useState<CryptoTransactionFilter>();
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(15);
  const checkAutoRefresh = useCheckAutoRefresh('Page Crypto transactions')

  const title = 'Crypto transactions';

  const componentMounted = useRef(false);

  useEffect(() => {
    if (PermissionViewCryptoTransactions) {
      componentMounted.current = true;
      getCryptoTransactionsList();
    }
    return () => {
      componentMounted.current = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PermissionViewCryptoTransactions]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const getCryptoTransactionsList = (options?, filter?) => {
    setIsLoading(true);

    getCryptoTransactions(getTablePageParams(options, filter))
      .then((res) => {
        if (componentMounted.current) {
          setTransactionList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getTablePageParams = (pageParams, filter) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    const params = `${toPage}&${perPageParams}`;

    if (filter) {
      let withFilters = `?${params}`;

      if (filter['status']) {
        withFilters = `${withFilters}&status=${filter['status']}`;
      }

      if (filter['query'] !== ''){
        withFilters = `${withFilters}&query=${filter['query']}`;
      }

      if (filter['searchCategory'] && filter['searchCategory'] !== ''){
        withFilters = `${withFilters}&searchCategory=${filter['searchCategory']}`;
      }

      return withFilters;
    }

    // if (filter) {
    //   return `?${params}&status=${filter['status']}&filter_user=${filter['filter_user']}`;
    // }

    return `?${params}`;
  };

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>
                  <strong>{tableMeta?.total || 0}</strong> Crypto Transactions
                </span>
                <span></span>
              </div>
              <div className={cx('header-controls')}></div>
            </div>
          </div>
          <div className={cx('container__filters-block')}>
            <CryptoTransactionsFilters
              onSubmit={(data) => {
                if (!filters && !data) {
                  return;
                }
                if (filters && !data) {
                  getCryptoTransactionsList({ navigate: 'first' });
                  setFilters(null);
                } else {
                  setFilters(data);
                  getCryptoTransactionsList({ navigate: 'first' }, data);
                }
              }}
              reloadFilters={isLoading}
              queryMessage={queryResponseMessage}
                          />
          </div>
          <div className={cx('container__block')}>
            {
              PermissionViewCryptoTransactions ?
                <CryptoTransactionsTable
                  data={transactionList}
                  perPage={perPageCount}
                  showLoader={isLoading}
                  modifyDataHandler={(callback) =>
                    getCryptoTransactionsList(
                      {
                        page: tableMeta?.current_page || 1,
                      },
                      filters,
                    )
                  }
                  updateData={(pos, status) => {
                    transactionList[pos].status = status;
                    setTransactionList([...transactionList]);
                  }}
                /> : null
            }
            {transactionList?.length ? (
              <TablePagination
                goToFirstPage={() => {
                  getCryptoTransactionsList({ navigate: 'first' }, filters);
                  dispatch(toggleScrollToTop())
                }}
                goToPrevPage={() => {
                  getCryptoTransactionsList({ navigate: 'prev' }, filters);
                  dispatch(toggleScrollToTop())
                }}
                goToNextPage={() => {
                  getCryptoTransactionsList({ navigate: 'next' }, filters);
                  dispatch(toggleScrollToTop())
                }}
                goToLastPage={() => {
                  getCryptoTransactionsList({ navigate: 'last' }, filters);
                  dispatch(toggleScrollToTop())
                }}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getCryptoTransactionsList({ perPage: value }, filters);
                }}
                goToSelectedPage={(page) => {
                  getCryptoTransactionsList({ page }, filters);
                }}
                hidePageSelect={false}
                isFirstPageDisable={() => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default CryptoTransactionsPage;
