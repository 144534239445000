import classNames from 'classnames/bind';
import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import styles from './lead-upload-popup.module.scss';
import PopupTemplate from 'components/ui/PopupTemplate';
import { Col, Container, Row } from 'react-bootstrap';
import FileUploadComponent from 'components/ui/FileUploadComponent/FileUploadComponent';
import { FILE_MAX_SIZE, FILE_TYPES_LEADS } from 'constants/file-upload';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  onCloseModal?: () => void;
  updateComponent: () => void;
}

const LeadUploadPopup = (props: Props) => {
  const { triggerBtn, onCloseModal, updateComponent } = props;

  const closeModal = (closeModal) => {
    closeModal();
    onCloseModal();
  };

  const getLeadTemplateUr = () => {
    const base64csv = `bmFtZSxzdXJuYW1lLHBob25lLG1vYmlsZSxlbWFpbCxjb3VudHJ5LGNpdHksYmlydGhEYXRlLGdlbmRlcixsYW5ndWFnZSxzb3VyY2UsYWZmaWxpYXRlLHNhbGVzQWdlbnQKTmFtZS0xLExhc3QtbmFtZSwrNzkwOTg4ODY2Njc3LCs3OTA5ODg4NjY2NzcsZW1haWwxMjIyQGVtYWlsLmNvbSxVUyxDaXR5LTEsMjAyMi0wMy0yOCxtYWxlLGVuLG51bGwsbnVsbCxkMzcwOGViMS1iZjkwLTQzMjctOTkwMC1lMTk3MDYyYmY3NzgK`;
    const base64csvFileAsBlob = new Blob([atob(base64csv)], {
      type: 'text/csv;base64;charset=utf-8',
    });
    return window.URL.createObjectURL(base64csvFileAsBlob);
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Leads upload'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'form-lead__content')}>
                  <Container>
                    <Row>
                      <Col md={12}>
                        <div className={cx('content__title')}>
                          <span>Upload a CSV file to import new Leads</span>
                          <span>
                            Sample CSV:{' '}
                            <a href={getLeadTemplateUr()} download={'leads-template.csv'}>
                              leads-upload.csv
                            </a>
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <FileUploadComponent
                          type="lead_document"
                          id="uploader"
                          isMultiple={false}
                          maxSize={FILE_MAX_SIZE}
                          types={FILE_TYPES_LEADS}
                          onSuccess={() => {
                            closeModal(close);
                          }}
                          updateComponent={updateComponent}
                        />
                      </Col>
                    </Row>
                  </Container>
                  {/*<div className={cx('content-controls')}>*/}
                  {/*  <div className={cx('controls__buttons')}>*/}
                  {/*    <Button*/}
                  {/*      buttonText="Cancel"*/}
                  {/*      buttonType="outline"*/}
                  {/*      size="big"*/}
                  {/*      type="cancel"*/}
                  {/*      onClick={closeModal.bind(undefined, close)}*/}
                  {/*    />*/}
                  {/*  </div>*/}
                  {/*</div>*/}
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default LeadUploadPopup;
