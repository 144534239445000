import classNames from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import React, { useEffect, useRef, useState } from 'react';
import { findDesksMethod, getDeskTypeMethod } from '../../../api/managements/desks/index';
import Button from '../../../components/ui/Button/index.js';
import DesksTable from '../../../components/ui/Table/DesksTable/index.js';
import TablePagination from '../../../components/ui/TablePagination/index.js';
import { firstLetterUppercase } from '../../../utils/firstLetterUppercase.js';
import { usePermission } from '../../../utils/usePermission.tsx';
import Page from '../../Page/index.js';
import { DashboardLayout } from '../../UserView/DashboardLayout';

import styles from './desks-page.module.scss';
import CreateOrEditDesksPopup from './DesksPopups/CreateOrEditDesksPopup';
import DesksFilter from './Filter/index.js';
import { notify } from '../../../utils/notify.tsx';
import { getTablePageParams } from '../../../utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { HierarchyDesk } from 'models/Hierarchy/HierarchyData.js';
import { ManagementDesksFilter } from 'models/Management/Desks/Filter.js';
import { ListValue } from 'models/ListValue';
import { toggleScrollToTop } from "../../../store/slice/tableSlice.ts";
import { useAppDispatch } from "../../../store";

const cx = classNames.bind(styles);

const DesksPage = () => {

  const { permissionGiven: PermissionListDesk } = usePermission('admin.desk.index');
  const { permissionGiven: PermissionCreate } = usePermission('admin.desk.create');

  const dispatch = useAppDispatch();
  const [desks, setDesks] = useState<HierarchyDesk[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [deskTypeList, setDeskTypeList] = useState<ListValue[]>([]);
  const [filters, setFilters] = useState<ManagementDesksFilter>(null);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const componentMounted = useRef(false);

  document.title = 'Desks';

  useEffect(() => {
    if (PermissionListDesk) {
      componentMounted.current = true;
      getDesks();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [PermissionListDesk]);

  useEffect(() => {
    getDeskTypeList();
  }, [])

  const getDeskTypeList = () => {
    getDeskTypeMethod().then((res) => {
      if (res.status === 200) {
        let list = res.data.desk_types.map((item) => {
          return { value: item, label: firstLetterUppercase(item) };
        });
        setDeskTypeList(list);
      }
    })
      .catch((e) => {
        console.log(e.message);
      });
  };

  const getDesks = (options?, data?) => {
    setIsListLoading(true);

    let postData;

    findDesksMethod(getTablePageParams(options, perPageCount, tableLinks), data || postData || filters)
      .then((res) => {
        if (componentMounted.current) {
          setDesks(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>
                  <strong>{tableMeta?.total || 0}</strong> Desks
                </span>
                <span></span>
              </div>
              <div className={cx('header-controls')}>
                {
                  PermissionCreate ?
                    <CreateOrEditDesksPopup
                      deskTypeList={deskTypeList}
                      updateComponent={() => getDesks()}
                      actionType={SALES_RULES_ACTION_TYPE.CREATE}
                      triggerBtn={
                        <Button buttonText={'+ Add desk'} onClick={() => null} buttonType={'outline'} />
                      }
                    /> : null
                }
              </div>
            </div>
          </div>
          <div className={cx('container__filters-block')}>
            <DesksFilter
              deskTypeList={deskTypeList}
              onSubmit={(data) => {
                setFilters(data);
                getDesks({ navigate: 'first', perPage: data?.filter?.limit }, data);
              }}
              reloadFilters={isListLoading}
              queryMessage={queryResponseMessage}
                          />
          </div>
          <div className={cx('container__block')}>
            {
              PermissionListDesk ?
                <DesksTable
                  data={desks}
                  deskTypeList={deskTypeList}
                  perPage={desks?.length}
                  showLoader={isListLoading}
                  modifyDataHandler={(callback) => getDesks()}
                /> : null
            }
            {desks?.length ? (
              <TablePagination
                goToFirstPage={() => {
                  getDesks({ navigate: 'first' });
                  dispatch(toggleScrollToTop())
                }}
                goToPrevPage={() => {
                  getDesks({ navigate: 'prev' });
                  dispatch(toggleScrollToTop())
                }}
                goToNextPage={() => {
                  getDesks({ navigate: 'next' });
                  dispatch(toggleScrollToTop())
                }}
                goToLastPage={() => {
                  getDesks({ navigate: 'last' });
                  dispatch(toggleScrollToTop())
                }}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getDesks({ perPage: value });
                }}
                goToSelectedPage={(page) => {
                  getDesks({ page });
                }}
                // hidePageSelect={true}
                isFirstPageDisable={() => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default DesksPage;
