const OfficeIcon = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 33.75C1.25 33.0596 1.80964 32.5 2.5 32.5H37.5C38.1904 32.5 38.75 33.0596 38.75 33.75C38.75 34.4404 38.1904 35 37.5 35H2.5C1.80964 35 1.25 34.4404 1.25 33.75Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.48223 4.48223C4.95107 4.01339 5.58696 3.75 6.25 3.75H21.25C21.913 3.75 22.5489 4.01339 23.0178 4.48223C23.4866 4.95108 23.75 5.58696 23.75 6.25V33.75C23.75 34.4404 23.1904 35 22.5 35C21.8096 35 21.25 34.4404 21.25 33.75L21.25 6.25H6.25L6.25 33.75C6.25 34.4404 5.69036 35 5 35C4.30964 35 3.75 34.4404 3.75 33.75V6.25C3.75 5.58696 4.01339 4.95107 4.48223 4.48223Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.25 15C21.25 14.3096 21.8096 13.75 22.5 13.75H33.75C34.413 13.75 35.0489 14.0134 35.5178 14.4822C35.9866 14.9511 36.25 15.587 36.25 16.25V33.75C36.25 34.4404 35.6904 35 35 35C34.3096 35 33.75 34.4404 33.75 33.75L33.75 16.25L22.5 16.25C21.8096 16.25 21.25 15.6904 21.25 15Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 11.25C8.75 10.5596 9.30964 10 10 10H15C15.6904 10 16.25 10.5596 16.25 11.25C16.25 11.9404 15.6904 12.5 15 12.5H10C9.30964 12.5 8.75 11.9404 8.75 11.25Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.25 21.25C11.25 20.5596 11.8096 20 12.5 20H17.5C18.1904 20 18.75 20.5596 18.75 21.25C18.75 21.9404 18.1904 22.5 17.5 22.5H12.5C11.8096 22.5 11.25 21.9404 11.25 21.25Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 27.5C8.75 26.8096 9.30964 26.25 10 26.25H15C15.6904 26.25 16.25 26.8096 16.25 27.5C16.25 28.1904 15.6904 28.75 15 28.75H10C9.30964 28.75 8.75 28.1904 8.75 27.5Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.25 27.5C26.25 26.8096 26.8096 26.25 27.5 26.25H30C30.6904 26.25 31.25 26.8096 31.25 27.5C31.25 28.1904 30.6904 28.75 30 28.75H27.5C26.8096 28.75 26.25 28.1904 26.25 27.5Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.25 21.25C26.25 20.5596 26.8096 20 27.5 20H30C30.6904 20 31.25 20.5596 31.25 21.25C31.25 21.9404 30.6904 22.5 30 22.5H27.5C26.8096 22.5 26.25 21.9404 26.25 21.25Z"
        fill="#722ED1"
      />
    </svg>
  );
};

export default OfficeIcon;
