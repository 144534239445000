import React, { useState } from 'react';
import classNames from 'classnames/bind';

import styles from './formInput.module.scss';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';
import ShuffleIcon from '../../../assets/Svg/ShuffleIcon';
const randomize = require('randomatic');
const cx = classNames.bind(styles);

const FormInputAutoPassword = (props) => {
  const generate = (): void => {
    let Low = randomize('a', 1);
    let Upp = randomize('A', 1);
    let Num = randomize('0', 1);
    let Spec = randomize('!', 1);
    let rand = randomize('*', 4);
    let pass = Upp + Low + Num + Spec + rand;
    props.generatePassword(pass);
    field.onChange(pass);
  };
  const [focus, setFocus] = useState<boolean>(false);

  const { field } = useController(props);
  return (
    <>
      <div className={cx('label-wrap')} style={props.labelStyle}>
        {props.label && (
          <label htmlFor={props.id} className={props.errors ? cx('label', 'error') : cx('label')}>
            {props.label}
            {props.rules && props.rules.required ? <span className={cx('required')}>*</span> : ''}
          </label>
        )}
        <div className={cx('icon-container')}>
          {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
        </div>
      </div>
      <div
        style={focus ? { borderColor: 'var(--main-color)' } : { backgroundColor: 'transperent' }}
        className={props.errors ? cx('input-wrap', 'error') : cx('input-wrap')}
      >
        <button onClick={generate} type={'button'} className={cx('generate-btn')}>
          <ShuffleIcon />
        </button>
        <input
          {...field}
          {...props}
          className={props.errors ? cx('input', 'error') : cx('input')}
          type={props.type}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={props.value || (field.value === null ? '' : field.value)}
        />
      </div>
      {props.errors && <p className={cx('errorName')}>{props.errors.message}</p>}
    </>
  );
};

export default FormInputAutoPassword;
