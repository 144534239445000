import { useEffect, useState } from "react";
import { useAppSelector } from "../../../../store";
import { selectChatThreads } from "../../../../store/slice/chat/chatSlice";
import { ThreadTypes } from "../../../../models/Chat";

//TODO: CHAT change to api search + store
export const useThreadsFilter = () => {
    const threads = useAppSelector(selectChatThreads);
    const [type, setType] = useState<ThreadTypes>(+sessionStorage.getItem('chat-tab') || ThreadTypes.internal);
    const [search, setSearch] = useState('');
    const [filteredThreads, setFilteredThreads] = useState([]);

    const query = search.toLowerCase().trim();
    const filterBySearch = query.length >= 2;

    useEffect(() => {
        sessionStorage.removeItem('chat-tab');
    }, []);

    useEffect(() => {
        const filtered = threads.filter((item) => {
            if (filterBySearch && query) {
                return item.name.toLowerCase().includes(search) && item.is_support === type;
            }

            return item.is_support === type;
        });
        setFilteredThreads(filtered);
    }, [type, search, threads]);

    return {
        threads: filteredThreads,
        type,
        setType,
        search,
        setSearch
    }
}