import classNames from 'classnames/bind';
import { MagnifyingGlass } from 'phosphor-react';
import React, { FC } from 'react';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';
import styles from './search.module.scss';
import { ISearchProps } from '../../../models/components/Search/Search.ts';

const cx = classNames.bind(styles);

type Props = {
  containerStyle?: React.CSSProperties;
} & React.PropsWithChildren<ISearchProps>;

const Search: FC<Props> = (props) => {
  const { field } = useController({
    name: props.name,
    control: props.control,
  });

  return (
    <div className={cx('search-input')} style={props.containerStyle}>
      <div className={cx('label-wrap')}>
        {props.label && (
          <label htmlFor={props.id} className={cx('label')}>
            {props.label}
          </label>
        )}
        {props.tooltip && props.tooltip.show && (
          <div className={cx('icon-container')}>
            <Tooltip params={props.tooltip} />
          </div>
        )}
      </div>
      <div className={cx('search-input__wrap')}>
        <input
          {...field}
          // {...props}
          name={props.name}
          className={cx('search-input__input')}
          type="text"
          id={props.id}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onFocus={props.onFocus}
          value={field.value ?? ''}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.key === 'Enter') {
              props?.onSearch();
            }
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            field.onChange(e);
            if (props.handleChange) {
              props.handleChange(e.target.value);
            }
          }}
        />
        <span className={cx('search-input__input-icon')} onClick={props?.onSearch}>
          <MagnifyingGlass size={20} />
        </span>
      </div>
      {props.descr && (
        <div className={cx('description-wrap')}>
          <span className={cx('description')}>{props.descr}</span>
        </div>
      )}
    </div>
  );
};

export default Search;
