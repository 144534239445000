import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../components/ui/CrmSearch/CrmSearch";
import {
  CrmSearchCategorySelect,
  defaultQueryCategoryOption
} from "../../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import useDynamicSearchPlaceholder from "../../../../utils/useDynamicSearchPlaceholder";
import { useSearchCategoriesOptions } from "../../../../utils/useGetSearchCategoriesOptions";
import styles from './transactions-filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import Button from 'components/ui/Button/Button';
import { isFormChange } from '../../../../utils/isFormChange';
import { CryptoTransactionFilter } from 'models/CustomerService/CryptoTransactionFilter';

const cx = classNames.bind(styles);

const status = [
  {
    value: 'accepted',
    label: 'Accepted',
  },
  {
    value: 'rejected',
    label: 'Rejected',
  },
  // {
  //   value: 'internal',
  //   label: 'Internal',
  // },
  {
    value: 'new',
    label: 'New',
  },
];

type Props = {
  onSubmit: (data: CryptoTransactionFilter) => void;
  reloadFilters: boolean;
  queryMessage: string,
}

const CryptoTransactionsFilters = (props: Props) => {
  const { onSubmit, reloadFilters, queryMessage} = props;

  const componentMounted = useRef(true);
  const cryptoTransactionsSearchOptions = useSearchCategoriesOptions('cryptoTransaction');

  const getDefaultValue = () => {
    return {
      query: '',
      query_category: defaultQueryCategoryOption,
      status: undefined,
    };
  };
  const { handleSubmit, reset, watch, setValue, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { isDirty, isValid, dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, cryptoTransactionsSearchOptions);

  const submit = (data) => {
    const filterData = prepareFiltersData(data);

    onSubmit(filterData);
  };

  const resetForm = () => {
    onSubmit(null);
    reset(getDefaultValue());
  };

  const prepareFiltersData = (data: any) => {
    const filterdata = {
      query: data['query'],
      status: data['status']?.value,
    }

    if (data['query_category']) {
      filterdata['searchCategory'] = data['query_category']?.value;
    }

    return filterdata;
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className={cx('filters')}>
        <Row>
          <Col md={12}>
            <ComplexSearchBar message={queryMessage}>
              <>
                <CrmSearchCategorySelect
                  id='query_category'
                  name='query_category'
                  control={control}
                  options={cryptoTransactionsSearchOptions}
                  onSelectChange={() => {
                      const { query } = getValues();
                    if (query) submit(getValues());
                  }}
                />
                <CrmSearch
                  id='query'
                  name='query'
                  control={control}
                  placeholder={dynamicSearchPlaceholder}
                  onSearch={() => submit(getValues())}
                  handleChange={(value: string) => {
                    if (value?.length === 0) submit(getValues());
                  }}
                />
              </>
            </ComplexSearchBar>
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <JokerSelect
              label="Status"
              control={control}
              id="status"
              name="status"
              options={status}
            />
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => resetForm()}
              disabled={!isFormChange(dirtyFields)}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CryptoTransactionsFilters;
