import React, { useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './wallet-filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';
import Button from 'components/ui/Button/Button';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { isFormChange } from '../../../../utils/isFormChange';
import { CryptoWithdrawFilter } from 'models/CustomerService/CryptoWithdrawFilter';

const cx = classNames.bind(styles);

const StatusList = [
  { value: 'new', label: 'New' },
  { value: 'accepted', label: 'Accepted' },
  { value: 'rejected', label: 'Rejected' },
];

type Props = {
  onSubmit: (data: CryptoWithdrawFilter) => void;
  reloadFilters: boolean;
}

const CryptoPaymentsFilters = (props: Props) => {
  const { onSubmit, reloadFilters } = props;

  const componentMounted = useRef(true);

  const getDefaultValue = () => {
    return {
      status: null,
    };
  };
  const { handleSubmit, reset, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const submit = (data) => {
    onSubmit({
      status: data?.status?.value,
    });
  };

  const resetForm = () => {
    onSubmit(null);
    reset(getDefaultValue());
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className={cx('filters')}>
        <Row>
          <Col md={3}>
            <JokerSelect
              label="Status"
              control={control}
              id="status"
              name="status"
              options={StatusList}
            />
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => resetForm()}
              disabled={!isFormChange(dirtyFields)}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CryptoPaymentsFilters;
