import Popup from 'reactjs-popup';
import PopupTemplate from '../../../../components/ui/PopupTemplate';
import classNames from 'classnames';
import styles from './styles.module.scss';
import Button from '../../../../components/ui/Button/Button';
import FormInput from 'components/ui/FormInput/FormInput';
import { useForm } from 'react-hook-form';
import { setAffiliationData } from 'api/Source';
import { FormattedMessage } from 'react-intl';

const AfilateEditPopup = ({
  trigger,
  affilate,
  source,
  referral,
  user_id,
  setSource,
  setReferral,
}) => {
  const cx = classNames.bind(styles);
  const {
    handleSubmit,
    control,
    setValue,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      affilate: affilate,
      source: source,
      referral: referral,
    },
  });

  const onSubmit = (close, data): void => {
    setAffiliationData(user_id, data).then((r) => {
      setSource(r.data.data.source);
      setReferral(r.data.data.referral);
      setValue('source', r.data.data.source);
      setValue('referral', r.data.data.referral);
    });
    close();
  };

  const onError = (close, errors) => {
    console.log(errors);
  };

  const closeModal = () => {
    clearErrors();
    setValue('referral', referral);
    setValue('source', source);
  };

  return (
    <Popup modal trigger={trigger} closeOnEscape repositionOnResize lockScroll closeOnDocumentClick onClose={closeModal}>
      {(close) => (
        <form
          onSubmit={handleSubmit(onSubmit.bind(undefined, close), onError.bind(undefined, close))}
        >
          <PopupTemplate
            dismissModal={() => {
              closeModal();
              close();
            }}
            headerTitle="Set source and referral"
            rightContent={
              <div className={cx('document-types__content')}>
                <div className={cx('document-types__name')}>
                  <FormInput name="affilate" label="Affilate" control={control} disabled />
                  <FormInput
                    name="source"
                    label="Source"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 20,
                        message: (
                          <FormattedMessage
                            id="form.error.maxLengthPassword"
                            defaultMessage="Maximum length is 20 characters"
                          />
                        ),
                      },
                    }}
                    errors={errors.source}
                  />
                  <FormInput
                    name="referral"
                    label="Referral"
                    control={control}
                    rules={{
                      maxLength: {
                        value: 20,
                        message: (
                          <FormattedMessage
                            id="form.error.maxLengthPassword"
                            defaultMessage="Maximum length is 20 characters"
                          />
                        ),
                      },
                    }}
                    errors={errors.referral}
                  />
                </div>
                <div style={{ display: 'flex' }}>
                  <Button
                    type="submit"
                    buttonText="Save Change"
                    size="big"
                    disabled={!!Object.entries(errors).length}
                    onClick={(e): void => {
                      handleSubmit(onSubmit, onError);
                    }}
                  />

                  <Button
                    type="button"
                    buttonText="Cancel"
                    buttonType="secondary"
                    size="big"
                    onClick={(): void => {
                      closeModal();
                      close();
                    }}
                  />
                </div>
              </div>
            }
          />
        </form>
      )}
    </Popup>
  );
};

export default AfilateEditPopup;
