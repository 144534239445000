import React from 'react';
import styles from './style.module.scss';
import { CaretDown, CaretRight } from 'phosphor-react';
import { getDynamicStatusesList } from '../../../api/settingsLK';
import {
  IItemListDynamicStatusMenu,
  IUpdateSubItems
} from "../../../models/components/DynamicStatusMenu/DynamicStatusMenu";

const DynamicStatusMenu = ({ itemList, setActiveRole, activeRole, clearTable, setItemList }) => {
  const getStatusList = (item) => {
    return getDynamicStatusesList(item.name)
      .then((res) => res.data.data)
      .catch(console.log)
  };

  const closeSubItems = (arr: IItemListDynamicStatusMenu[], curr): void => {
    const newList: IItemListDynamicStatusMenu[] = arr.map((item: IItemListDynamicStatusMenu): IItemListDynamicStatusMenu => {
      if (item === curr) {
        return {
          ...item,
          show: false,
        };
      }
      return item;
    });
    setItemList(newList);
  };
  const updateSubItems = async (arr: IUpdateSubItems[], curr): Promise<void> => {
    const listRoles: IUpdateSubItems[] = await getStatusList(curr);

    const newList: IUpdateSubItems[] = arr.map((item: IUpdateSubItems): IUpdateSubItems => {
      if (item === curr) {
        return {
          ...item,
          subItems: [...listRoles],
          show: true,
        };
      }
      return {
        ...item,
        show: false,
      };
    });

    setItemList(newList);
    clearTable();
  };

  return (
    <div className={styles.wrapperMenu}>
      {itemList?.map((item) => {
        return (
          <div key={item.id} className={styles.wrapperCollapse}>
            <button
              className={styles.btnItem}
              key={item.id}
              onClick={(): void => {
                item.show ? closeSubItems(itemList, item) : updateSubItems(itemList, item);
              }}
            >
              <p className={styles.title}>{item.name}</p>
              {item.show ? (
                <CaretDown size={24} color={'#c2c2c2'} />
              ) : (
                <CaretRight size={24} color={'#c2c2c2'} />
              )}
            </button>
            {item.show && (
              <div>
                {item?.subItems.map((sub) => {
                  return (
                    <button
                      key={sub.id}
                      className={
                        activeRole === sub
                          ? styles.btnSubItem + ' ' + styles.active
                          : styles.btnSubItem
                      }
                      onClick={(): void => {
                        setActiveRole(sub);
                      }}
                    >
                      &#8226; {sub.key}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default DynamicStatusMenu;
