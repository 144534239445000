import React from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';

import {
  TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES
} from 'constants/table';
import { copyToBuffer } from '../../../../utils/copyToBuffer';
import { getShortUuid } from '../../../../utils/getShortUuid';
import { value } from 'lodash/seq';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import Button from 'components/ui/Button/Button';

const cx = classNames.bind(styles);

const SalesRulesOperatorTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  colSortCheck,
}) => {
  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES", TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES);
  
  // const columns = React.useMemo(() => TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, name, uuid, type, source, priority } = item;
    const getSourceArray = () => {
      const parsed = JSON.parse(source);
      const keys: string[] = Object.keys(parsed);
      const array = [];

      for (const key of keys) {
        array.push(parsed[key]);
      }

      return array;
    };

    const sourceArray = source ? getSourceArray() : [];
    const sourceStr = sourceArray.length > 1 ? sourceArray.join(', ') : sourceArray;
    return {
      // id: (
      //   <div className={cx('callback-table_th')}>
      //     <span>{id}</span>
      //   </div>
      // ),
      name: (
        <div className={cx('callback-table_th')}>
          <span>
            <b>{name}</b>
          </span>
          <span
            className={styles.rule_id}
            onClick={(): void => {
              copyToBuffer(uuid);
            }}
          >
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      priority: (
        <div className={cx('callback-table_th')}>
          <span>{priority}</span>
        </div>
      ),
      source: (
        <div className={cx('callback-table_th')}>
          {sourceArray.length ? <b>{sourceArray.length} Sources </b> : ''}
          {sourceArray.length ? (
            <span className={styles.rule_source}>{sourceStr}</span>
          ) : (
            <span className={cx('rule__source-empty')}>&mdash;</span>
          )}
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck}
      className={cx('callback-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
    />
    </>
  );
};

export default SalesRulesOperatorTable;
