import { FunctionComponent } from 'react';
import { components, DropdownIndicatorProps } from 'react-select';

export const DropdownIndicator: FunctionComponent<DropdownIndicatorProps> = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" fill="currentColor" viewBox="0 0 256 256">
        <rect width="256" height="256" fill="none"></rect>
        <polyline
          points="208 96 128 176 48 96"
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="16"
        ></polyline>
      </svg>
    </components.DropdownIndicator>
  );
};
