import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Link, useParams } from 'react-router-dom';

import styles from './style.module.scss';
import Page from '../../../Page/Page';
import { DashboardLayout } from '../../../UserView/DashboardLayout';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import { Col, Row } from 'react-bootstrap';
import QRCode from 'react-qr-code';
import { getStatusForCrypt } from '../../../../utils/getStatusForCrypt';
import CryptoRequestForm from './CryptoRequestForm';
import { getCurrentRequestMethod } from '../../../../api/customer-service/request';
import Button from '../../../../components/ui/Button';
import { CryptoWithdrawWalletData } from 'models/CustomerService/CryptoWithdraw';

const cx = classNames.bind(styles);

const CryptoRequestPage = () => {
  const params = useParams();
  const [walletData, setWalletData] = useState<CryptoWithdrawWalletData>();
  const [isLoading, setIsLoading] = useState(true);
  const title = 'Request - ' + walletData?.wallet_address;
  const statusRequest = getStatusForCrypt(walletData);
  const componentMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const fetchWalletInfo = (params) => {
    setIsLoading(true);

    getCurrentRequestMethod(params.id)
      .then((res) => {
        setWalletData(res.data.data);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    fetchWalletInfo(params);
  }, [params]);
  const drawUserBlock = (data, taData) => {
    const user_profile = data?.user_profile;
    const uuid = data?.uuid
    const trading_account = taData?.trading_account;

    return (
      <div className={cx('container__block')}>
        <div className={cx('personalInfo')}>
          <div className={cx('personalInfo__col')}>
            <div className={cx('personalInfo__col-title')}>User information</div>
            <div className={cx('personalInfo__col-content')}>
              {user_profile?.first_name && (
                <div>
                  <span className={cx('strong')}>First name:&nbsp;</span>
                  {user_profile?.first_name}
                </div>
              )}
              {user_profile?.last_name && (
                <div>
                  <span className={cx('strong')}>Last name:&nbsp;</span>
                  {user_profile && user_profile.last_name !== null ? user_profile.last_name : ''}
                </div>
              )}
              {uuid && (
                <div>
                  <span className={cx('strong')}>UUID:&nbsp;</span>
                  <span
                    className={cx('wallet__identificator')}
                    onClick={() => {
                      copyToBuffer(uuid);
                    }}
                  >
                    {getShortUuid(uuid)}
                  </span>
                </div>
              )}
              {trading_account && (
                <div>
                  <span className={cx('strong')}>Balance:&nbsp;</span>
                  {trading_account?.balance}
                </div>
              )}
              {trading_account && (
                <div>
                  <span className={cx('strong')}>Credit:&nbsp;</span>
                  {trading_account?.credit}
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ background: 'white', padding: '16px' }}>
          <QRCode value={walletData.wallet_address} size={150} />
        </div>
      </div>
    );
  };
  const drawRequestInfo = (walletData) => {
    return (
      <div className={cx('container__block')}>
        <div className={cx('personalInfo')}>
          <div className={cx('personalInfo__col')}>
            <div className={cx('personalInfo__col-title')}>Request information</div>
            <div className={cx('personalInfo__col-content')}>
              {walletData.wallet_address && (
                <div>
                  <span className={cx('strong')}>Wallet address:&nbsp;</span>
                  <span
                    className={cx('wallet__identificator')}
                    onClick={() => {
                      copyToBuffer(walletData.wallet_address);
                    }}
                  >
                    {walletData.wallet_address}
                  </span>
                </div>
              )}

              <div className={cx('transaction__pinned')}>
                <span className={cx('strong')}>Status:&nbsp;</span>
                <span className={cx(statusRequest)}>{statusRequest?.toUpperCase()}</span>
              </div>

              {walletData.crypto_type && (
                <div>
                  <span className={cx('strong')}>Crypto type:&nbsp;</span>
                  {walletData.crypto_type}
                </div>
              )}

              {walletData.amount_usd && (
                <div>
                  <span className={cx('strong')}>Amount USD:&nbsp;</span>
                  {walletData.amount_usd} $
                </div>
              )}
              {/*{walletData.request_amount && (*/}
              {/*  <div>*/}
              {/*    <span className={cx('strong')}>Request amount:&nbsp;</span>*/}
              {/*    {walletData.request_amount}*/}
              {/*  </div>*/}
              {/*)}*/}
              {walletData.accept_amount && (
                <div>
                  <span className={cx('strong')}>Accept amount:&nbsp;</span>
                  {walletData.accept_amount}
                </div>
              )}
              {/*{walletData.request_exchange_rate && (*/}
              {/*  <div>*/}
              {/*    <span className={cx('strong')}>Request exchange rate:&nbsp;</span>*/}
              {/*    {walletData.request_exchange_rate}*/}
              {/*  </div>*/}
              {/*)}*/}
              {walletData.accept_exchange_rate && (
                <div>
                  <span className={cx('strong')}>Accept exchange rate:&nbsp;</span>
                  {walletData.accept_exchange_rate}
                </div>
              )}

              {walletData.created_at && (
                <div>
                  <span className={cx('strong')}>Created at:&nbsp;</span>
                  {moment.unix(walletData.created_at).format('DD.MM.YYYY HH:mm')}
                </div>
              )}

              {walletData?.accept_time && (
                <div>
                  <span className={cx('strong')}>Accept at:&nbsp;</span>
                  {moment.unix(walletData.accept_time).format('DD.MM.YYYY HH:mm')}
                </div>
              )}
              {walletData?.deleted_at && (
                <div>
                  <span className={cx('strong')}>Delete at:&nbsp;</span>
                  {moment.unix(walletData.deleted_at).format('DD.MM.YYYY HH:mm')}
                </div>
              )}
              {walletData.deposit_transaction && (
                <>
                  {walletData.deposit_transaction?.id && (
                    <div>
                      <span className={cx('strong')}>Transaction ID:&nbsp;</span>
                      <span>{walletData.deposit_transaction?.id}</span>
                    </div>
                  )}
                  {walletData.deposit_transaction?.type && (
                    <div>
                      <span className={cx('strong')}>Type transactions:&nbsp;</span>
                      <span>{walletData.deposit_transaction?.type}</span>
                    </div>
                  )}
                  {walletData.deposit_transaction?.method && (
                    <div>
                      <span className={cx('strong')}>Method:&nbsp;</span>
                      <span>{walletData.deposit_transaction?.method}</span>
                    </div>
                  )}
                  {walletData.deposit_transaction?.trading_account_id && (
                    <div>
                      <span className={cx('strong')}>Trading Account ID:&nbsp;</span>
                      <span>{walletData.deposit_transaction?.trading_account_id}</span>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <DashboardLayout>
      {!isLoading && (
        <Page>
          <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
            <div className={cx('container')}>
              <div className={cx('container__header')}>
                <div className={cx('wallet')}>
                  <div className={cx('wallet__type')}>
                    <div className={cx('wallet__item')}>
                      <div className={cx('wallet__icon')}>
                        <i className={cx(`${walletData?.crypto_type?.toLowerCase()}`)}></i>
                      </div>
                      <div className={cx('wallet__desc')}>
                        <span className={cx('mb-10')}>{walletData?.wallet_address}</span>
                        <span className={cx(statusRequest)}>{statusRequest.toUpperCase()}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={cx('action')}>
                  <Link to={`/crypto-output`} className={cx('logo')}>
                    <Button
                      buttonType="outline"
                      buttonText="To requests list"
                      onClick={() => null}
                    />
                  </Link>
                </div>
              </div>
              <Row>
                <Col md={6}>{drawRequestInfo(walletData)}</Col>
                <Col md={6}>
                  {drawUserBlock(
                    walletData?.deposit_transaction?.user,
                    walletData?.deposit_transaction,
                  )}
                </Col>
              </Row>
              {statusRequest === 'new' && (
                <Row>
                  <Col md={12}>
                    <CryptoRequestForm
                      updateComponent={() => fetchWalletInfo(params)}
                      formData={walletData}
                    />
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </Page>
      )}
    </DashboardLayout>
  );
};

export default CryptoRequestPage;
