import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './table-pagination.module.scss';
import { SHOW_ITEMS } from '../../../constants/pagination';

const cx = classNames.bind(styles);

interface ITablePagination {
  perPage?: number;
  perPageChange: (perPage: number) => void;
  goToSelectedPage: (page: number) => void;
  goToFirstPage: () => void;
  goToPrevPage: () => void;
  goToNextPage: () => void;
  goToLastPage: () => void;
  currentPage: number;
  pagesLength: number;
  isFirstPageDisable: () => boolean;
  isPrevPageDisable: () => boolean;
  isNextPageDisable: () => boolean;
  isLastPageDisable: () => boolean;
  isRareCase?: boolean;
  hidePageSelect?: boolean;
}

const TablePagination: FC<ITablePagination> = (props) => {
  const {
    perPage,
    perPageChange,
    goToSelectedPage,
    goToFirstPage,
    goToPrevPage,
    goToNextPage,
    goToLastPage,
    currentPage,
    pagesLength,
    isFirstPageDisable,
    isPrevPageDisable,
    isNextPageDisable,
    isLastPageDisable,
    isRareCase,
    hidePageSelect,
  } = props;

  const listItems = (items: number[]) => {
    return items.map((perPage: number) => (
      <option key={perPage} value={perPage}>
        Show {perPage}
      </option>
    ))
  }

  return (
    <div className={cx('pagination')}>
      <button onClick={goToFirstPage} disabled={isFirstPageDisable()}>
        {'<<'}
      </button>
      {' '}
      <button onClick={goToPrevPage} disabled={isPrevPageDisable()}>
        {'<'}
      </button>
      {' '}
      <button onClick={goToNextPage} disabled={isNextPageDisable()}>
        {'>'}
      </button>
      {' '}
      <button onClick={goToLastPage} disabled={isLastPageDisable()}>
        {'>>'}
      </button>
      {' '}
      <span>
        Page{' '}
        <strong>
          {currentPage} of {pagesLength}
        </strong>{' '}
      </span>
      <span>
        &nbsp; Go to page:{' '}
        <input
          type='number'
          max={pagesLength}
          defaultValue={currentPage}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            let page: number = e.target.value ? Number(e.target.value) : 0;

            if (page > pagesLength) {
              e.target.value = pagesLength.toString();
              page = pagesLength;
            } else if (page < 0) {
              e.target.value = '1';
              page = 1;
            }

            goToSelectedPage(page);
          }}
          style={{ width: '100px' }}
        />
      </span>{' '}
      {!hidePageSelect ? (
        <select
          value={perPage}
          onChange={(e: React.ChangeEvent<HTMLSelectElement>): void => {
            perPageChange(Number(e.target.value));
          }}
        >
          {isRareCase ? listItems(SHOW_ITEMS.RARE_CASE) : listItems(SHOW_ITEMS.ALL)}
        </select>
      ) : (
        ''
      )}
    </div>
  );
};

export default TablePagination;
