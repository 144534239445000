import { getCountryList }                     from 'api/country';
import classNames                             from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE }            from 'constants/sales-rules.const';
import React, { useEffect, useRef, useState } from 'react';

import { getDictionaryMethod }              from '../../../api/managements/operators/index';
import { findPartnersMethod }               from '../../../api/managements/partners/index';
import Button                               from '../../../components/ui/Button/index';
import PartnersTable                        from '../../../components/ui/Table/PartnersTable/index.js';
import TablePagination                      from '../../../components/ui/TablePagination/index.js';
import { firstLetterUppercase }             from '../../../utils/firstLetterUppercase.js';
import { usePermission } from '../../../utils/usePermission.tsx';
import Page                                 from '../../Page/index.js';
import { DashboardLayout }                  from '../../UserView/DashboardLayout';
import PartnersFilter                       from './Filter/index.js';

import styles              from './partners-page.module.scss';
import CreatePartnersPopup from './PartnersPopups/CreatePartnersPopup';
import { getTablePageParams } from '../../../utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { ListValue } from 'models/ListValue';
import { ManagementPartner } from 'models/Management/Partners/Partner.js';
import { ManagementPartnersFilter } from 'models/Management/Partners/Filter.js';
import { CountryListValue } from 'models/Countries.js';
import { toggleScrollToTop } from "../../../store/slice/tableSlice.ts";
import { useAppDispatch } from "../../../store";

const cx = classNames.bind(styles);

const PartnersPage = () => {

  const { permissionGiven: PermissionListPartners } = usePermission('admin.partner.index');
  const { permissionGiven: PermissionCreate } = usePermission('admin.partner.create');

  const dispatch = useAppDispatch();
  const [partners, setPartners] = useState<ManagementPartner[]>([]);

  const [isListLoading, setIsListLoading] = useState(true);
  const [statusList, setStatusList] = useState<ListValue[]>([]);
  const [countryOpt, setCountryOpt] = useState<CountryListValue[]>([]);
  const [filters, setFilters] = useState<ManagementPartnersFilter>(null);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  // const [sort, setSort] = useState();

  const componentMounted = useRef(false);
  document.title = 'Affiliates';
  const SORTED_FIELD_NAME = {
    partner: 'first_name',
    external_id: 'external_id',
    country: 'country_name',
    created_at: 'created_at',
    status: 'status',
  };
  useEffect(() => {
    if (PermissionListPartners) {
      componentMounted.current = true;
      getPartners();
    }
    return () => {
      componentMounted.current = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PermissionListPartners]);

  useEffect(() => {
    fetchCountryList();
    fetchDictionaryList();
  }, [])

  const fetchCountryList = () => {
    getCountryList().then((res) => {
      const opt = res.data.data.map((opt) => ({
        value: opt.id,
        label: opt.name,
        iso_3166_2: opt.iso_3166_2,
      }));
      setCountryOpt([{ value: 0, label: 'Other' }, ...opt]);
    });
  };
  const fetchDictionaryList = () => {
    getDictionaryMethod().then((res) => {
      const list1 = res.data.operator_statuses.map((opt) => ({
        value: opt,
        label: firstLetterUppercase(opt),
      }));
      setStatusList([...list1]);
    });
  };

  const getPartners = (options?, data?) => {
    setIsListLoading(true);

    let postData;

    findPartnersMethod(getTablePageParams(options, perPageCount, tableLinks), data || postData || filters)
      .then((res) => {
        if (componentMounted.current) {
          setPartners(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  const sortColumn = (col) => {
    const sorted = col.isSorted;
    const desc = col.isSortedDesc;
    const sortedCountryAndAffiliate = SORTED_FIELD_NAME[col.id] === SORTED_FIELD_NAME.partner || SORTED_FIELD_NAME[col.id] === SORTED_FIELD_NAME.country

    if (!sorted) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: sortedCountryAndAffiliate ? 'asc' : 'desc',
      };
      // setSort(sort);
      getPartners(null, {
        ...filters,
        sort,
      });
      col.toggleSortBy(true);
    }

    if (sorted && desc) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: sortedCountryAndAffiliate ? 'desc' : 'asc',
      };
      // setSort(sort);
      getPartners(null, {
        ...filters,
        sort,
      });
      col.toggleSortBy(false);
    }

    if (sorted && !desc) {
      const data = {
        ...filters,
      };
      // setSort(null);
      getPartners(null, data);
      col.clearSortBy();
    }
  }

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>
                  <strong>{tableMeta?.total || 0}</strong> Affiliates found
                </span>
                <span></span>
              </div>
              <div className={cx('header-controls')}>
                {
                  PermissionCreate ?
                    <CreatePartnersPopup
                      countryList={countryOpt}
                      updateComponent={() => getPartners()}
                      actionType={SALES_RULES_ACTION_TYPE.CREATE}
                      triggerBtn={
                        <Button
                          buttonText={'+ New affiliate'}
                          onClick={() => null}
                          buttonType={'outline'}
                        />
                      }
                    /> : null
                }
              </div>
            </div>
          </div>
          <div className={cx('container__filters-block')}>
            <PartnersFilter
              statusList={statusList}
              countryList={countryOpt}
              onSubmit={(data) => {
                setFilters(data);
                getPartners({ navigate: 'first', perPage: data?.filter?.limit }, data);
              }}
              reloadFilters={isListLoading}
              queryMessage={queryResponseMessage}
                          />
          </div>
          <div className={cx('container__block')}>
            {
              PermissionListPartners ?
                <PartnersTable
                  manualSortHandler={sortColumn}
                  data={partners}
                  perPage={partners?.length}
                  showLoader={isListLoading}
                  modifyDataHandler={(callback) => getPartners()}
                /> : null
            }
            {partners?.length ? (
              <TablePagination
                goToFirstPage={() => {
                  getPartners({ navigate: 'first' });
                  dispatch(toggleScrollToTop())
                }}
                goToPrevPage={() => {
                  getPartners({ navigate: 'prev' });
                  dispatch(toggleScrollToTop())
                }}
                goToNextPage={() => {
                  getPartners({ navigate: 'next' });
                  dispatch(toggleScrollToTop())
                }}
                goToLastPage={() => {
                  getPartners({ navigate: 'last' });
                  dispatch(toggleScrollToTop())
                }}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getPartners({ perPage: value });
                }}
                goToSelectedPage={(page) => {
                  getPartners({ page });
                }}
                // hidePageSelect={true}
                isFirstPageDisable={() => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default PartnersPage;
