import { ReactNode } from 'react';
import { CommonInputProps } from "./type";

import './DefaultInputField.scss';

type Props = Omit<CommonInputProps, 'rules' | 'name' | 'error'> & {
  error?: string | undefined;
  className?: string;
  children: ReactNode;
};

export const DefaultInputField = ({ id, label, error, className, tooltip, children }: Props) => {
  const LabelTag = id ? 'label' : 'span';
  const LabelAttrs = {
    className: 'label-title',
    ...(id ? { htmlFor: id } : {}),
  };

  return (
    <div className="input-field">
      <div className="input-field-label">
        {label ? <LabelTag {...LabelAttrs}>{label}</LabelTag> : null}
      </div>
      {/*TODO: Add tooltip here if needed*/}
      <div className="input-wrap">{children}</div>
      {error ? <p className="error-message">{error}</p> : null}
    </div>
  );
};