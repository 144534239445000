import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import styles from './bulk-actions-popup.module.scss';
import Button from 'components/ui/Button/Button';
import { useForm } from 'react-hook-form';
import PopupTemplate from 'components/ui/PopupTemplate';
import LoaderButton from 'components/ui/LoaderButton';
import { firstLetterUppercase } from 'utils/firstLetterUppercase';
import { Col, Container, Row } from 'react-bootstrap';
import JokerSelect from 'components/ui/JokerSelect';
import { deskList, getDeskList } from 'api/desk';
import { teamList } from 'api/team';
import { bulkActionClientToTeam, clientDynamicStatusesByKey } from 'api/clients';
import { operatorsList, operatorsListForBulkActions } from 'api/operators';
import JokerMultiSelect from 'components/ui/JokerMultiSelect';
import SelectComponent from 'components/ui/Select/Select';
import {
  SALES_STATUS_SELECT_OPTIONS,
  USER_SELECT_FILTER_DEFAULT_VALUE,
} from 'constants/clients/filters.const';
import PaginateSelect from '../../../../../components/ui/PaginateSelect';
import {
  fetchDesks,
  fetchDesksForType,
  fetchOperatorsForBulkActions,
  fetchOperatorsForBulkActionsByDesk,
  fetchTeamsForDesk,
} from '../../../../../utils/managment/fetchData';
import { notify } from '../../../../../utils/notify';
import NotificationManager from 'react-notifications/lib/NotificationManager';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  trigger: React.ReactElement;
  bulkActionsData: number[];
  bulkActionsType: string;
  onCloseModal?: () => any;
  onSuccess?: () => any;
  selectedClients: number[];
}

const RetentionBulkActionsPopup = (props: Props) => {
  const {
    trigger,
    bulkActionsData,
    bulkActionsType,
    onCloseModal,
    onSuccess,
    selectedClients,
  } = props;

  const [lastOpsFetch, setLastOpsFetch] = useState<
    { desk: string | number; team: string | number; res: ListValue[] }
  >({ desk: -1, team: -1, res: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [isSelect, setIsSelect] = useState(false);
  const [desk, setDesk] = useState<ListValue>();
  const [teams, setTeams] = useState<ListValue>();
  const [operators, setOperators] = useState<ListValue[]>([]);
  const [dynamicFilter, setDynamicFilter] = useState(null);
  const [defaultValue, setDefaultValue] = useState(null);
  const role = JSON.parse(localStorage.getItem('role'))[0];
  const currentLang = localStorage.getItem('lang');
  const user_id = localStorage.getItem('user_id');
  const componentMounted = useRef(true);


  const setDefaultValues = () => {
    return {
      desk_id: null,
      team_id: USER_SELECT_FILTER_DEFAULT_VALUE[0],
      admin_user_ids: null,
      status: null,
    };
  };
  const {
    handleSubmit,
    control,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });

  const closeModal = (closeModal) => {
    setDesk(null);
    setIsSelect(false);
    setTeams(null);
    reset(setDefaultValues());
    closeModal();
    onCloseModal?.();
  };

  const onSubmit = async (close, data) => {
    setIsLoading(true);

    const ids = getValue(data['admin_user_ids']);

    const postData = {
      bulk_type: 'retention',
      desk_id: getValue(data['desk_id']),
      team_id: getValue(data['team_id']),
      admin_user_ids: ids.length ? ids : [ids],
      statusId: getValue(data['status']),
      user_ids: bulkActionsData,
    };

    bulkActionClientToTeam(clearEmptyField(postData))
      .then((res) => {
        if (res.status === 200 || res.status === 202) {
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
        setIsSelect(false);
      });
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);
  const onOpen = () => {
    fetchClientDynamicStatuses();
  };

  const fetchClientDynamicStatuses = () => {
    clientDynamicStatusesByKey('retention').then((res) => {
      if (componentMounted.current) {
        const filter = res.data.data;

        setDynamicFilter({
          value: filter.key,
          label: filter.translate,
          options: filter.activeStatuses.map((item) => ({
            value: item.id,
            label: item.translate,
          })),
        });
      }
    });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyField = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };
  const setDefaultValuesTeam = () => {
    return {
      bulk_type: 'sales',
      desk_id: getValues('desk_id'),
      team_id: '',
      admin_user_ids: getValues('admin_user_ids'),
      statusId: getValues('status'),
      user_ids: bulkActionsData,
    };
  };
  const setDefaultValuesOperator = () => {
    return {
      bulk_type: 'sales',
      desk_id: getValues('desk_id'),
      team_id: getValues('team_id'),
      admin_user_ids: '',
      statusId: getValues('status'),
      user_ids: bulkActionsData,
    };
  };
  const updateTeamWithDesk = (desk) => {
    setDesk(desk);
    reset(setDefaultValuesTeam() as any);
  };
  const updateOperatorWithTeam = (team) => {
    setTeams(team);
    reset(setDefaultValuesOperator());
  };

  const fetchCurrentDeskFromManager = (desk) => {
    getDeskList(`per_page=50&page=1`, '', { filter: { types: [desk] } })
      .then((res) => {
        const opt = res.data.data.map((opt) => ({
          value: opt.id,
          label: opt.name,
        }));
        opt.length > 1 ? setDefaultValue(opt?.[1]) : setDefaultValue(opt?.[0]);
      });
  };

  const isRetentionManager = role === 'Head of Retention' || role === 'Retention Team Leader' || role === 'Retention Agent';

  useEffect(() => {
    if (isRetentionManager) {
      fetchCurrentDeskFromManager('retention');
    }
  }, [role]);

  useEffect(() => {
    setTeams(undefined)
    setValue('admin_user_ids', null);
    if (desk?.value && selectedClients.length > 1) {
      fetchOps({ deskID: desk.value });
    }
  }, [desk])

  useEffect(() => {
    setValue('admin_user_ids', null);
    if (teams?.value && selectedClients.length > 1) {
      fetchOps({ teamID: teams.value })
    }
  }, [teams])

  type FetchOperatorsProps = {
    deskID?: string | number;
    teamID?: string | number;
    query?: string;
  }

  const fetchOps = async ({ deskID, teamID, query }: FetchOperatorsProps) => {
    let ops: { data: ListValue[] } = { data: [] };

    if (deskID === lastOpsFetch.desk && teamID === lastOpsFetch.team) {
      ops.data = lastOpsFetch.res;
    } else {
      if (teamID) {
        ops = await fetchOperatorsForBulkActions('retention', teamID)
      } else if (deskID) {
        ops = await fetchOperatorsForBulkActionsByDesk('retention', deskID);
      }
      setLastOpsFetch({ desk: deskID, team: teamID, res: ops.data })
    }
    let result = ops.data;
    if (query) {
      result = ops.data.filter(op => op.label.toUpperCase().includes(query.toUpperCase()))
    }
    setOperators(result);
    return { data: result }
  }

  return (
    <>
      <Popup
        modal
        trigger={trigger}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              <div className={cx('popup-title')}>
                <span>{`Bulk Actions - ${firstLetterUppercase(
                  bulkActionsType,
                  currentLang,
                )}`}</span>
                <span>{bulkActionsData.length} Clients selected</span>
              </div>
            }
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-title')}></div>
                <div className={cx('content-form')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12}>
                          <PaginateSelect
                            isRequired={true}
                            isMulti={false}
                            label='Retention Desks'
                            control={control}
                            def={defaultValue}
                            id='desk_id'
                            name='desk_id'
                            onChange={(page, search) =>
                              fetchDesksForType(page, search, 'retention')
                            }
                            errors={errors.desk_id}
                            rules={{
                              required: 'Desk field is required',
                            }}
                            onSelectChange={() => {
                              updateTeamWithDesk(getValues('desk_id'));
                              setIsSelect(true);
                            }}
                          />
                        </Col>
                        {isSelect ?
                          <>
                            <Col md={12}>
                              <PaginateSelect
                                isRequired={false}
                                isMulti={false}
                                label='Teams'
                                control={control}
                                id='team_id'
                                name='team_id'
                                onChange={(page, search, desk) => {
                                  return fetchTeamsForDesk(page, search, desk?.value);
                                }}
                                mainVal={desk}
                                // errors={errors.team_id}
                                // rules={{
                                //   required: 'Team field is required',
                                // }}
                                onSelectChange={() => {
                                  updateOperatorWithTeam(getValues('team_id'));
                                }}
                                key={JSON.stringify(desk)}
                              />
                            </Col>
                            <Col md={12}>
                              { selectedClients.length > 1 ?
                                <JokerMultiSelect
                                  control={control}
                                  label={'Retention representative'}
                                  id="admin_user_ids"
                                  name="admin_user_ids"
                                  options={operators}
                                  maxSelected={selectedClients.length}
                                  isRequired={true}
                                  hideHeading={true}
                                  errors={errors.admin_user_ids}
                                  rules={{
                                    required: 'Retention representative field is required',
                                  }}
                                /> :
                                <PaginateSelect
                                  isRequired={true}
                                  isMulti={false}
                                  label='Retention representative'
                                  control={control}
                                  id='admin_user_ids'
                                  name='admin_user_ids'
                                  onChange={(page, search) => {
                                    return fetchOps({ deskID: desk?.value, teamID: teams?.value, query: search })
                                  }}
                                  errors={errors.admin_user_ids}
                                  rules={{
                                    required: 'Retention representative field is required',
                                  }}
                                  key={JSON.stringify(desk) + JSON.stringify(teams)}
                                />
                              }
                            </Col>
                            <Col md={12}>
                              {dynamicFilter && (
                                <SelectComponent
                                  label={dynamicFilter.label}
                                  control={control}
                                  id='retention-status'
                                  name='status'
                                  options={dynamicFilter.options}
                                  // rules={{ required: ' Field is required' }}
                                  // errors={errors.status}
                                />
                              )}
                            </Col>
                          </>
                          : null
                        }
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText='Cancel'
                          buttonType='outline'
                          size='big'
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <LoaderButton
                          buttonType='primary'
                          size='big'
                          showSpinner={isLoading}
                          type='submit'
                          onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                          buttonText={'Apply changes'}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default RetentionBulkActionsPopup;
