import React, { useState, useEffect } from 'react';
import classNames from 'classnames/bind';
import styles from './table.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import ReactTooltip from 'react-tooltip';

import { TABLE_FILES_COLUMNS_NAMES } from 'constants/table';
import moment from 'moment';
import { CheckCircle, Pencil, Power, Trash, DownloadSimple } from 'phosphor-react';
import EditCallbackPopup from 'pages/Clients/Client/ClientTabs/Callbacks/EditCallbackPopup';
import { removeCallbackItem } from 'api/callbaks';
import { notify } from 'utils/notify';
import { copyToBuffer } from '../../../../utils/copyToBuffer';
import { getShortUuid } from '../../../../utils/getShortUuid';
import EditFilesPopup from 'pages/Clients/Client/ClientTabs/Files/EditFilesPopup';
import { fetchDownloadFileRequest } from 'api/documents';
import { usePermission } from '../../../../utils/usePermission';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import Button from 'components/ui/Button/Button';

const cx = classNames.bind(styles);

const FilesTable = ({
  data,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  colSortCheck,
  userId,
  deleteAction,
  changeStatusAction,
  updateComponent,
  typesOpt,
}) => {
  const { permissionGiven: PermissionDownloadDocument } = usePermission('admin.document.download');
  const { permissionGiven: PermissionDeleteDocument } = usePermission('admin.document.delete');
  const { permissionGiven: PermissionUpdateDocument } = usePermission('admin.document.update');

    const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_FILES_COLUMNS_NAMES", TABLE_FILES_COLUMNS_NAMES);
  
  // const columns = React.useMemo(() => TABLE_FILES_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, original_name, time, created_at, status, kyc_type, kyc_category } = item;

    return {
      name: (
        <div className={cx('files-table_th')}>
          <span>{original_name}</span>
        </div>
      ),
      category: <span>{kyc_category?.translate}</span>,
      type: <span>{kyc_type?.translate}</span>,
      status: (
        <div className={cx('files-table_th')}>
          <span className={cx(status)}>{status}</span>
        </div>
      ),
      created_at: (
        <div className={cx('files-table_th')}>
          <div className={cx('client__registration')}>
            <span>{moment(created_at * 1000).format('DD.MM.YYYY HH:mm')}</span>
          </div>
        </div>
      ),
      action: (
        <div className={cx('action', 'files-table_th')}>
          {PermissionUpdateDocument ?
            <EditFilesPopup
              editData={item}
              updateComponent={() => updateComponent()}
              // userId={id}
              triggerBtn={<Pencil size={20} />}
              // onCloseModal={() => console.log('a')}
              typesOpt={typesOpt}
            />
            : null
          }
          {PermissionDownloadDocument ?
            <DownloadSimple
              size={20}
              onClick={() => fetchDownloadFileRequest(item.id, item.user_id, original_name)}
            />
            : null
          }
          {PermissionDeleteDocument ?
            <Trash size={20} onClick={() => deleteAction(item.id, item.user_id)} />
            : null
          }
          <ReactTooltip id="disabled" />
          <ReactTooltip id="success" />
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck}
      className={cx('files-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
    />
    </>
  );
};

export default FilesTable;
