import classNames                     from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm }             from 'react-hook-form';
import { usePermission }       from '../../../../utils/usePermission.tsx';
import styles                  from './../lead-page.module.scss';
import CalendarPicker from '../../../../components/ui/CalendarPicker/CalendarPicker';
import { notify } from '../../../../utils/notify.tsx';
import { updateLeadPersonalInfo } from '../../../../api/leads/index';
import Button from '../../../../components/ui/Button/index.js';
import FormInput from '../../../../components/ui/FormInput/index.js';
import Select from '../../../../components/ui/Select/index.js';
import { Lead } from 'models/Leads/Lead';
import PaginateSelect from "../../../../components/ui/PaginateSelect";
import { fetchLanguagesPaginate } from "../../../../utils/managment/fetchData.js";

const cx = classNames.bind(styles);

const genderListArr = [
  { id: 'female', name: 'Female' },
  { id: 'male', name: 'Male' },
];

type Props = {
  formData: Lead;
  updateComponent: () => void;
}

const LeadPersonalInfoForm = (props: Props) => {
  const { formData, updateComponent } = props;

  const { permissionGiven: PermissionSUpdatePersonalInfo } = usePermission('admin.lead.update-personal-info');

  const [isLoading, setIsLoading] = useState(true);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      ...data,
      gender: data && data.gender ? data.gender.id : null,
      birthday: data && data.birthday ? data.birthday / 1000 : null,
      language_id: data?.language_id?.value || formData?.language?.id || 40,
    };

    updateLeadPersonalInfo(formData.id, sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Personal info updated successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      const gender = genderListArr.find((item) => item.id === formData.gender);
      setIsLoading(false);
      reset({
        first_name: formData.name,
        last_name: formData.surname,
        gender: gender,
        birthday: formData.birthDate * 1000,
        language_id: {
          value: formData.language.id,
          label: formData.language.name,
        }
      });
    }
  }, [formData]);

  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      <Container className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <Row className={cx('form__line')}>
          <Col md={12}>
            <div className={cx('tab-panel__content-box-title')}>
              Personal information
              {
                PermissionSUpdatePersonalInfo ?
                  <>
                    {isDirty && <Button buttonText='Save changes' buttonType='outline' />}
                  </> : null
              }
            </div>
          </Col>
        </Row>
        <Row className={cx('form__line')}>
          <Col md={6} lg={4} xs={12}>
            <FormInput
              name='first_name'
              type='text'
              label='First name'
              control={control}
              errors={errors.first_name}
            />
          </Col>
          <Col md={6} lg={4} xs={12}>
            <FormInput
              name='last_name'
              type='text'
              label='Last name'
              control={control}
              errors={errors.last_name}
            />
          </Col>
          <Col md={6} lg={4} xs={12}>
            <Select
              name='gender'
              label='Gender'
              control={control}
              getOptionValue={(opt) => opt.id}
              getOptionLabel={(opt) => opt.name}
              options={genderListArr}
            />
          </Col>
          <Col md={6} lg={4} xs={12} className={cx('calendar__picker-col')}>
            <CalendarPicker
              name='birthday'
              placeholder='Date of birth'
              control={control}
              label='Date of birth'
            />
          </Col>
          <Col md={6} lg={4} xs={12} className={cx('calendar__picker-col')}>
            <PaginateSelect
              isSearchable={true}
              isMulti={false}
              placeholder={'Any'}
              label='Languages'
              control={control}
              id='language_id'
              name='language_id'
              onePage={true}
              onChange={(page, search) => fetchLanguagesPaginate(page, search)}
            />
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default LeadPersonalInfoForm;
