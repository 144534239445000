import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './сallbacksTable.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';

import { TABLE_ALL_CALLBACKS_COLUMNS_NAMES } from 'constants/table';
import moment from 'moment';

import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import {
  CallbacksTableAdminProps
} from "../../../../models/components/Table/CallbacksTableAdmin/CallbacksTableAdmin.ts";
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';
import { Link } from 'react-router-dom';

const cx = classNames.bind(styles);

const CallbacksTableAdmin: FC<CallbacksTableAdminProps> = ({
                                                        data,
                                                        perPage,
                                                        showLoader,
                                                        onRowSelect,
                                                        colSortCheck
                                                      }) => {
  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_ALL_CALLBACKS_COLUMNS_NAMES", TABLE_ALL_CALLBACKS_COLUMNS_NAMES);

  // const columns = React.useMemo(() => TABLE_ALL_CALLBACKS_COLUMNS_NAMES, []);

  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, operator, client, time, created_at, updated_at, status, reminders } = item;

    return {
      operator: (
        <div className={cx('callback-table_td')}>
          <span>{operator?.full_name || '---'}</span>
          <span className="uuid" onClick={() => copyToBuffer(operator?.uuid)}>
            {getShortUuid(operator?.uuid) || '---'}
          </span>
        </div>
      ),
      client: (
        <div className={cx('callback-table_td')}>
          <Link
            target={'_blank'}
            to={`/client/${client?.id ?? 0}`}
            className={cx('callbacks-table_td-link')}
            style={{ display: 'block' }}
          >
            <span>
              {`${client?.user_profile?.first_name
                  ? client?.user_profile?.first_name
                  : ''
              } ${client?.user_profile?.last_name
                  ? client?.user_profile?.last_name
                  : ''
              }`}
            </span>
          </Link>
          <span
            className="uuid"
            onClick={() => copyToBuffer(client?.uuid)}
          >
            {getShortUuid(client?.uuid) || '---'}
          </span>
        </div>
      ),
      time: (
        <div className={cx('callback-table_td')}>
          <span>{moment(time * 1000).format('DD.MM.YYYY') || '---'}</span>
          <span>{moment(time * 1000).format('HH:mm:ss') || '---'}</span>
        </div>
      ),
      created_at: (
        <div className={cx('callback-table_td')}>
          <span>{moment(created_at * 1000).format('DD.MM.YYYY')}</span>
          <span>{moment(created_at * 1000).format('HH:mm:ss')}</span>
        </div>
      ),
      updated_at: (
        <div className={cx('callback-table_td')}>
          <span>{moment(updated_at * 1000).format('DD.MM.YYYY')}</span>
          <span>{moment(updated_at * 1000).format('HH:mm:ss')}</span>
        </div>
      ),
      status: (
        <div className={cx('callback-table_td')}>
          <span className={cx(status)}>{status || '---'}</span>
        </div>
      ),
      reminders: (
        <div className={cx('callback-table_td')}>
            {reminders.length ? (
                <>
                    <span>{moment(reminders[0] * 1000).format('DD.MM.YYYY')}</span>
                    {reminders.map(item => <span>{moment(item * 1000).format('HH:mm')}</span>)}
                </>
            ) : null}
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck}
      className={cx('callback-table')}
      isSelected={false}
      saveColumnOrder={saveColumnOrder}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
    />
    </>
  );
};

export default CallbacksTableAdmin;
