import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../components/ui/CrmSearch/CrmSearch";
import styles from './teams-filter.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { ArrowsClockwise, Info } from 'phosphor-react';
import { useForm, useWatch } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';

import Button from 'components/ui/Button/Button';
import PaginateSelect from '../../../../components/ui/PaginateSelect/PaginateSelect';
import { fetchDesks, fetchOffices } from '../../../../utils/managment/fetchData';
import { ManagementTeamFilter } from 'models/Management/Teams/Filter';

const cx = classNames.bind(styles);

type Props = {
  onSubmit: (data: ManagementTeamFilter) => void;
  reloadFilters: boolean;
  queryMessage: string,
}

type FormValues = {
  name: string;
  query: string;
  office_ids: any[];
  desk_ids: any[] | string;
}

const TeamsFilter = (props: Props) => {
  const { onSubmit, reloadFilters, queryMessage} = props;

  const [office, setOffice] = useState(null);
  const componentMounted = useRef(true);
  const getDefaultValue = () => {
    return {
      name: '',
      query: '',
      office_ids: [],
      desk_ids: [],
    };
  };
  const { handleSubmit, reset, watch, setValue, getValues, control, formState } = useForm<FormValues>({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });

  const { isDirty, isValid, dirtyFields } = formState;
  const updateDeskWithOffice = (office) => {
    setOffice(office);
    reset(setDefaultValuesDesk());
  };
  const setDefaultValuesDesk = () => {
    return {
      name: getValues('name'),
      office_ids: getValues('office_ids'),
      desk_ids: '',
    };
  };
  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        office_ids: getValue(data['office_ids']) ? [getValue(data['office_ids'])] : '',
        desk_ids: getValue(data['desk_ids']) ? [getValue(data['desk_ids'])] : '',
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset(getDefaultValue());
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const isFormChange = () => {
    if (getValues('office_ids')) {
      return true;
    }
    return Object.keys(dirtyFields).length;
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={cx('filters')}
      >
        <Row>
          <Col md={12}>
            <ComplexSearchBar searchOnly={true} message={queryMessage}>
              <CrmSearch
                id='query'
                name='query'
                control={control}
                placeholder='Search by Team Name'
                onSearch={() => submit(getValues())}
                handleChange={(value: string) => {
                  if (value?.length === 0) submit(getValues());
                }}
              />
            </ComplexSearchBar>
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <PaginateSelect
              isMulti={false}
              label="Office"
              control={control}
              id="office_ids"
              name="office_ids"
              onChange={(page, search) => fetchOffices(page, search)}
              onSelectChange={() => {
                updateDeskWithOffice(getValues('office_ids'));
              }}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <PaginateSelect
              isDisabled={getValues('office_ids') ? false : true}
              isMulti={false}
              label="Desk"
              control={control}
              id="desk_ids"
              name="desk_ids"
              onChange={(page, search, office) => fetchDesks(page, search, office?.value)}
              mainVal={office}
              key={JSON.stringify(office)}
            />
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => resetForm()}
              disabled={!isFormChange()}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange()}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default TeamsFilter;
