import React from "react";
import { format } from "date-fns";

export const SystemMessageDate = ({ date }) => {
    return (
        <div className="system-messages-table-date">
            <div><strong>{format(date, 'yyyy-MM-dd')}</strong></div>
            <div>{format(date, 'HH:mm')}</div>
        </div>
    )
}