import { APIManagementGetOfficesResponse } from 'models/API/Management/Office';
import API from '../../api';
export const findOfficesMethod = (params, data) =>
  API.post<APIManagementGetOfficesResponse>(`/api/v1/search/offices?${params}`, data);
// response unused
export const addOfficeMethod = (data) => API.post(`/api/v1/offices`, data);
// response unused
export const updateOfficeMethod = (id, data) => API.put(`/api/v1/offices/${id}`, data);
// response unused
export const deleteOfficeMethod = (id) => API.delete(`/api/v1/offices/${id}`);
// method unused
export const getTotalOfficeListMethod = () => API.get(`/api/v1/offices?per_page=10&page=1`);
// method unused
export const getOfficeListMethod = (params) => API.get(`/api/v1/offices?${params}`);
