import classNames from 'classnames/bind';
import { CaretDown, CaretUp } from 'phosphor-react';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { useExpanded, usePagination, useRowSelect, useSortBy, useTable } from 'react-table';
import Preloader from '../Preloader/Preloader';
import styles from './table.module.scss';
import { IIndeterminateCheckboxProps, ITable } from '../../../models/components/Table/Table.ts';
import { getScrollToTop } from '../../../constants/selectors.ts';
import { useAppSelector } from '../../../store';
import useDragAndDrop from 'utils/useDragAndDrop.tsx';

const cx = classNames.bind(styles);

const IndeterminateCheckbox: FC<IIndeterminateCheckboxProps> = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef: any = ref || defaultRef;
    // console.log(rest)

    React.useEffect((): void => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return (
      <>
        <div className={cx('input-wrap')}>
          {/*<input type="checkbox" id={rest.checkbox_id} ref={resolvedRef} {...rest} />*/}
          <input type="checkbox" id={rest.checkbox_id} ref={resolvedRef} {...rest} />
          <div className={cx('label-wrap')}>
            <>
              <i
                ref={resolvedRef}
                className={cx('checkbox-icon', rest.checked ? 'icon-checked' : '')}
                onClick={(): void => {}}
              ></i>
            </>
          </div>
        </div>
        {/* <label htmlFor={rest.checkbox_id} className={cx('table-checkbox__label')}> */}

        {/* <i className={cx('checkbox-icon')}></i> */}
        {/* </label> */}
      </>
    );
  },
);

const Table: FC<ITable> = ({
  columns,
  data,
  getCellProps,
  perPage,
  showLoader,
  className,
  manualSortHandler,
  colSortCheck,
  onRowSelect,
  isSelected,
  isExpanded,
  renderRowSubComponent,
  style,
  saveColumnOrder,
}) => {
  const scrollToTop = useAppSelector(getScrollToTop);

  const preparedColumns = useMemo(
    () =>
      columns.map((c) => {
        return {
          ...c,
          id: c.accessor,
        };
      }),
    [columns],
  );

  const draggableTool = useDragAndDrop({
    columns: preparedColumns,
    saveColumnOrder,
  });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    selectedFlatRows,
    page,
    setSortBy,
    visibleColumns,
    state: { pageIndex, pageSize, selectedRowIds, sortBy, expanded },
  } = useTable(
    {
      columns: draggableTool.draggableColumns,
      data,
      autoResetHiddenColumns: false,
      initialState: {
        pageSize: perPage || 10,
        data: data || [],
        manualPagination: true,
      },
      manualSortBy: true,

      stateReducer: (newState, action, prev, instance): void => {
        if (perPage && newState.pageSize !== perPage) {
          newState.pageSize = perPage;
        }
      },
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    (hooks): void => {
      isSelected &&
        hooks.visibleColumns.push((columns) => [
          {
            id: 'selection',
            Header: ({ getToggleAllPageRowsSelectedProps }) => (
              <div className={cx('table-checkbox')} draggable={false} id={'selection'}>
                <IndeterminateCheckbox
                  {...getToggleAllPageRowsSelectedProps()}
                  checkbox_id={'main-row'}
                />
              </div>
            ),
            Cell: ({ row }) => (
              <div className={cx('table-checkbox')}>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} checkbox_id={row.id} />
              </div>
            ),
          },
          ...columns,
        ]);
    },
  );

  const contentToScroll = useRef(null);

  useEffect((): void => {
    contentToScroll.current.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [scrollToTop]);

  useEffect((): void => {
    if (onRowSelect) {
      onRowSelect(selectedFlatRows);
    }
  }, [selectedFlatRows]);

  if (!draggableTool.draggableColumns.length) return <div ref={contentToScroll} />;

  return (
    <>
      <div className={cx('table-container', className)} ref={contentToScroll} style={style}>
        <table {...getTableProps()}>
          <thead>
          {!!draggableTool.draggableColumns.length && headerGroups.map((headerGroup) => {
            return (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => {
                  const dragProps = column.id !== "selection" ? draggableTool.draggableElementProps : {}
                  return (
                    <th
                      {...column.getHeaderProps(
                        colSortCheck?.(column.id) ? column.getSortByToggleProps() : undefined,
                      )}
                      className={cx(
                        colSortCheck?.(column.id) ? 'sort-col' : '',
                        column.isSorted ? 'selected' : '',
                        column.id === draggableTool.dragOver && column.id !== draggableTool.dragOn ? 
                        draggableTool.relativeDragOverPosition === "prev" ? "dragover-prev" : "dragover-next" 
                        : '',
                        column.id === draggableTool.dragOn ? 'drag-on' : '',
                      )}
                      onClick={colSortCheck?.(column.id) ? () => manualSortHandler(column) : null}
                      style={(i === 0 && isSelected) ? {} : { width: column.width }}
                      {...dragProps}
                      onLoad={(e) => {console.log(e, this)}}
                      dragover={(column.id === draggableTool.dragOver).toString()}
                      id={column.id}
                      key={column.id}
                    >
                      {column.render('Header')}
                      <span className={cx('sort-btn')}>
                      <span
                        className={cx(column.isSorted && !column.isSortedDesc ? 'selected' : '')}
                      >
                        {!column.isSorted || (column.isSorted && !column.isSortedDesc) ? (
                          <CaretUp size={8} />
                        ) : (
                          ''
                        )}
                      </span>
                      <span
                        className={cx(column.isSorted && column.isSortedDesc ? 'selected' : '')}
                      >
                        {!column.isSorted || (column.isSorted && column.isSortedDesc) ? (
                          <CaretDown size={8} />
                        ) : (
                          ''
                        )}
                      </span>
                    </span>
                    </th>
                  )
                })}
              </tr>
            )
          })}
          </thead>

          <tbody {...getTableBodyProps()} className={!page?.length ? cx('empty') : ''}>
            {!page.length && !showLoader ? (
              <tr>
                <td colSpan={headerGroups?.headers?.length}>
                  <span className={cx('empty-msg')}>
                    <FormattedMessage id="table.noItems" defaultMessage="No items" />
                  </span>
                </td>
              </tr>
            ) : null}
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <React.Fragment key={i}>
                  <tr {...row.getRowProps()} className={showLoader ? cx('blur') : ''}>
                    {row.cells.map((cell) => {
                      // TODO: COLORED SORT COL;
                      // const st = cell.column.isSorted
                      //   ? { ...cell.column.style, background: '#ede2f6' }
                      //   : cell.column.style;

                      return (
                        <td
                          {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                            getCellProps(cell, cx),
                          ])}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                  {row.isExpanded ? (
                    <tr>
                      <td colSpan={visibleColumns.length}>{renderRowSubComponent({ row })}</td>
                    </tr>
                  ) : null}
                </React.Fragment>
              );
            })}

            {showLoader ? (
              <tr>
                <td colSpan={headerGroups?.headers?.length}>
                  <div className={cx('preloader')}>
                    <div className={cx('preloader__inner')}>
                      <Preloader />
                    </div>
                  </div>
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Table;
