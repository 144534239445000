export const checkPerimissionForShowData = (permissionType, roleType) => {
    const permissions = JSON.parse(localStorage.getItem('permissions')).some(
      (element) => element.name === permissionType,
    );
    const roles = JSON.parse(localStorage.getItem('role'));

    const checkSuperAdminRole = roles.indexOf('Super Admin') > -1;
    const checkRole = roles.indexOf(roleType) > -1;

    if (permissions === true || checkSuperAdminRole === true || checkRole === 'Super Admin') {
      return true;
    }
};