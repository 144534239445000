import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './style-popup.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
import { notify } from 'utils/notify';
import { CirclePicker } from 'react-color';
import { changeColorDynamicStatus, deleteDynamicStatus } from '../../../../api/settingsLK';
import { DynamicStatus } from 'models/SettingsLK/DynamicStatuses/DynamicStatusesPage';
import { DEFAULT_COLORS_FOR_DYNAMIC_STATUSES } from "../../../../constants/colors.js";
import { useWindowWidth } from "@react-hook/window-size";
import { MOBILE_SMALL_SIZE_WIDTH } from "../../../../constants/mobile.js";

const cx = classNames.bind(styles);

type Props = {
  rule: DynamicStatus;
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
}

const ChangeColorPopup = (props: Props) => {
  const { triggerBtn, updateComponent, rule } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [color, setColor] = useState(rule.color_status || '#722ed1')
  const width: number = useWindowWidth();
  const isMobile: boolean = width < MOBILE_SMALL_SIZE_WIDTH;

  const closeModal = (closeModal) => {
    closeModal();
  };

  const handleChange = (color) => {
    setColor(color.hex)
  }

  const onSubmit = (close) => {
    setIsLoading(true);

    const postData = {
      status_id: rule.id,
      color_status: color,
    }

    changeColorDynamicStatus(postData)
      .then(() => {
        updateComponent();
        closeModal(close);
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={() => closeModal(close)}
            headerTitle={'Change color'}
            rightContent={
              <div className={cx('content-form', 'popupForm')}>
                <div className={cx('content-controls')}>
                  <Container>
                    <Row>
                      <Col
                        className={'mt-2'}
                        md={12}
                      >
                        <CirclePicker
                          width={isMobile ? '200px' : '350px'}
                          color={color}
                          colors={DEFAULT_COLORS_FOR_DYNAMIC_STATUSES}
                          onChange={handleChange}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className={cx('controls__buttons')}>
                          <Button
                            buttonText="Cancel"
                            buttonType="outline"
                            size="big"
                            type="button"
                            onClick={() => closeModal(close)}
                          />
                          <LoaderButton
                            buttonType="primary"
                            size="big"
                            showSpinner={isLoading}
                            disabled={isLoading}
                            buttonText={'Change color'}
                            onClick={() => onSubmit(close)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default ChangeColorPopup;
