import { updateFilterFavorite }       from 'api/filters';
import classNames                     from 'classnames/bind';
import { SELECT_DEFAULT_STYLES }      from 'constants/select';
import { Star }                       from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { useController }              from 'react-hook-form';
import Select, { components }         from 'react-select';

import styles from './filter-set-select.module.scss';
import {
  IPrepeareOptions,
  IValueFilterSetSelect,
} from "../../../models/components/FilterSetSelect/FilterSetSelect";

const cx = classNames.bind(styles);

const customStyles = {
  ...SELECT_DEFAULT_STYLES,
  control: (provided, state) => ({
    ...provided,
    padding: '0px 13px',
    boxShadow: '1px solid #262626',
    border: state.isFocused ? '1px solid #262626' : '1px solid var(--light-grey)',
    '&:hover': {
      border: state.isFocused ? '1px solid #262626 !important' : '1px solid var(--light-grey)',
    },
    backgroundColor: '#ffffff',
    margin: '10px',
    //border: '1px solid var(--light-grey)',
    position: 'relative',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: '150px',
    borderTop: '1px solid #EBEBEB',
  }),
  container: (provided) => ({
    ...provided,
    border: '1px solid var(--light-grey)',
    backgroundColor: '#f5f5f5',
    width: 330,
  }),
  menu: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    marginTop: 0,
    boxShadow: 'unset',
    border: '1px solid var(--light-grey)',
    overflow: 'hidden',
    borderRadius: 0,
  }),
  option: (provided, state) => {
    return {
      ...provided,
      padding: '10px 12px',
      fontSize: '13px',
      lineHeight: '20px',
      fontWeight: 400,
      fontFamily: 'Inter',
      backgroundColor: state.isDisabled
        ? undefined
        : state.isSelected
          ? 'var(--list-selected-option-color)'
          : state.isFocused
            ? 'var(--list-hover-option-color)'
            : undefined,
      ':hover': {
        backgroundColor: 'var(--list-hover-option-color)',
      },
      ':active': {
        backgroundColor: !state.isDisabled ? (state.isSelected ? '#f9f1ff' : '#f9f1ff') : undefined,
      },
      color: '#262626',
    };
  },
  groupHeading: (provided, state) => {
    return {
      ...provided,
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '8px',
      color: 'var(--purple)',
      fontFamily: 'Inter',
      fontSize: '12px',
      paddingRight: '10px',
    };
  },
  group: (provided, state) => {
    return {
      ...provided,
    };
  },
};

const Svg = (p) => (
  <svg width='18' height='18' viewBox='0 0 24 24' focusable='false' role='presentation' {...p} />
);

const FilterSetSelectPayments = (props) => {
  const { onChange, label, placeholder, ...other } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [value, setValue] = useState<IValueFilterSetSelect>(null);
  const { field } = useController(props);

  const prepeareOptions = () =>
    other.options.map((item: IPrepeareOptions): IValueFilterSetSelect => ({
      label: item.name || 'No name',
      value: item.id,
      filter: { ...item.filter, query: item.query, sort: item.sort },
      is_favorite: item.is_favorite,
    }));

  const toggleOpen = (): void => {
    setIsOpen(!isOpen);
  };

  const onSelectChange = (value): void => {
    toggleOpen();
    setValue(value);
    field.onChange(value);
    other.onSelectChange?.(value);
  };

  useEffect((): void => {
    if (!field.value) {
      setValue(null);
    }
  }, [field.value]);

  const updateFavoriteValue = (props): void => {
    const isFavorite: 0 | 1 = props.data?.is_favorite === 0 ? 1 : 0;

    updateFilterFavorite(props.value, { is_favorite: isFavorite }).then((res): void => {
      other.updateList();
    });
  };

  const Blanket = (props) => (
    <div
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1,
      }}
      {...props}
    />
  );

  const Menu = (props) => {
    return (
      <div
        style={{
          marginTop: '10px',
          position: 'absolute',
          zIndex: 12,
        }}
        {...props}
      />
    );
  };

  const Dropdown = ({ children, isOpen, target, onClose }) => (
    <>
      <div style={{ position: 'relative'}}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
      </div>
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </>
  );

  const DropdownIndicator = () => (
    <div
      style={{
        color: 'var(--primary-text-color)',
        height: '100%',
        width: '22px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Svg>
        <path
          d='M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'
          fill='currentColor'
          fillRule='evenodd'
        />
      </Svg>
    </div>
  );

  const GroupHeading = (props) => (
    <div>
      <components.GroupHeading {...props}>
        <span>{props.children}</span>
        <span className={cx('favorite-title')}>
          <Star size={11} />
          Favourite
        </span>
      </components.GroupHeading>
    </div>
  );

  const SelectedValue = ({ data }) => (
    <div className={cx('selected-value')}>
      <span className={cx('selected-value__status', data.is_favorite ? 'favorite' : '')}>
        <Star size={11} />
      </span>
      <span className={cx('selected-value__label')}>{data.label}</span>
    </div>
  );

  const Option = (props) => {
    return (
      <div
        className={cx(
          'option',
          props.isSelected ? 'selected' : props.isFocused ? 'focused' : undefined,
        )}
      >
        <span className={cx('option__status', props.data?.is_favorite ? 'favorite' : '')}>
          <Star
            size={11}
            onClick={(e: React.MouseEvent<SVGSVGElement>): void => {
              e.preventDefault();
              updateFavoriteValue(props);
            }}
          />
        </span>
        <components.Option {...props}>
          <div>
            <span className={cx('account__label')} ref={props.innerRef} {...props.innerProps}>
              {props.children}
            </span>
          </div>
        </components.Option>
      </div>
    );
  };

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={toggleOpen}
      target={
        <div onClick={toggleOpen} style={{ display: 'flex', alignItems: 'center' }}>
          <span className={cx('custome-control__label')}>{value ? value.label : label}</span>
          {/*<div className={cx('custome-control')}>*/}
          {/*  {value ? (*/}
          {/*    <SelectedValue data={value} />*/}
          {/*  ) : (*/}
          {/*    <span className={cx('placeholder')}>{placeholder}</span>*/}
          {/*  )}*/}
          {/*  {isOpen ? <CaretUp size={18} /> : <CaretDown size={18} />}*/}
          {/*</div>*/}
        </div>
      }
    >
      <Select
        {...field}
        styles={customStyles}
        autoFocus
        backspaceRemovesValue={false}
        components={{ DropdownIndicator, GroupHeading, Option, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={onSelectChange}
        options={[
          {
            label: 'VIEW',
            options: prepeareOptions(),
          },
        ]}
        placeholder='Search...'
        tabSelectsValue={false}
        value={value}
      />
    </Dropdown>
  );
};

export default FilterSetSelectPayments;
