import classNames from 'classnames/bind';
import React, { FC } from 'react';

import styles from './buttonReset.module.scss';
import { IButtonReset } from "../../../models/components/Button/Button";

const cx = classNames.bind(styles);

const ButtonReset: FC<IButtonReset> = (props): React.JSX.Element => {
  const {
    buttonText,
    size,
    buttonType,
    icon,
    classes,
    buttonStyle,
    onClick,
    disabled,
    variant,
    type = 'submit',
    ...other
  } = props;

  if (variant === 'link') {
    return (
      <div
        className={cx('link')}
        onClick={onClick}
        {...other}
      >
        <span className={cx('btn__text')}>{buttonText}</span>
      </div>
    );
  }

  return (
    <button
      style={buttonStyle}
      className={cx('btn', buttonType, size, buttonStyle)}
      onClick={onClick}
      disabled={disabled}
      type={type}
      {...other}
    >
      {buttonText && <span className={cx('btn__text')}>{buttonText}</span>}
      {icon ? <span className={cx('btn__icon')}>{icon}</span> : ''}
    </button>
  );
};

export default ButtonReset;
