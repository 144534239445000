import { updateLeadContacts }         from 'api/leads';
import classNames                     from 'classnames/bind';
import Button                         from 'components/ui/Button';
import FormInput                      from 'components/ui/FormInput';
import React, { useEffect, useState } from 'react';

import { Col, Container, Row }         from 'react-bootstrap';
import { useForm }                     from 'react-hook-form';
import { checkPerimissionForShowData } from 'utils/checkPermissionForShowData';
import { notify }                      from 'utils/notify';
import { VALID_EMAIL }                 from '../../../../constants/validators.js';
import { usePermission }               from '../../../../utils/usePermission.tsx';
import styles                          from './../lead-page.module.scss';
import { Lead } from 'models/Leads/Lead';

const cx = classNames.bind(styles);

type Props = {
  formData: Lead;
  updateComponent: () => void;
}

const LeadContactForm = (props: Props) => {
  const { formData, updateComponent } = props;

  const [isLoading, setIsLoading] = useState(true);

  const { permissionGiven: PermissionUpdate } = usePermission('admin.lead.update-contacts');
  const { permissionGiven: PermissionPhone } = usePermission('admin.lead.show-phone');
  const { permissionGiven: PermissionEmail } = usePermission('admin.lead.show-email');
  const { permissionGiven: PermissionMobile } = usePermission('admin.lead.show-mobile');

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      ...data,
    };
    updateLeadContacts(formData.id, sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      setIsLoading(false);
      reset({
        phone: checkPerimissionForShowData('admin.client.view-phone', ['Super Admin'])
          ? formData.phone
          : '********',
        mobile: checkPerimissionForShowData('admin.client.view-phone', ['Super Admin'])
          ? formData.mobile
          : '********',
        email: checkPerimissionForShowData('admin.client.view-email', ['Super Admin'])
          ? formData.email
          : '********',
      });
    }
  }, [formData]);

  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      <Container className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <Row className={cx('form__line')}>
          <Col md={12}>
            <div className={cx('tab-panel__content-box-title')}>
              Contacts
              {
                PermissionUpdate ?
                  <>
                    {isDirty && <Button buttonText='Save changes' buttonType='outline' />}
                  </> : null
              }
            </div>
          </Col>
        </Row>
        <Row className={cx('form__line')}>
          <Col md={4}>
            <div className={cx('form__line', 'd-flex')}>
              <div className={cx('form__line-input-wrap')}>
                {
                  PermissionPhone ?
                    <FormInput
                      rules={{
                        validate: {
                          correctPhone: (value) =>
                            /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
                              value) || !value || value.trim().length === 0 ||
                            'Enter a different phone format.',
                        },
                      }}
                      errors={errors.phone}
                      name='phone'
                      type='text'
                      maxLength='20'
                      label='Phone'
                      placeholder='Phone'
                      control={control}
                    /> : null
                }
              </div>
              {/* <Button buttonText="Verify" /> */}
            </div>
          </Col>
          <Col md={4}>
            {
              PermissionMobile ?
                <FormInput
                  rules={{
                    validate: {
                      correctPhone: (value) =>
                        /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
                          value) || !value || value.trim().length === 0 ||
                        'Enter a different phone format.',
                    },
                  }}
                  errors={errors.mobile}
                  name='mobile'
                  maxLength='20'
                  type='text'
                  label='Mobile'
                  placeholder='Mobile'
                  control={control}
                /> : null
            }
          </Col>
          <Col md={4}>
            <div className={cx('form__line', 'd-flex')}>
              <div className={cx('form__line-input-wrap')}>
                {
                  PermissionEmail ?
                    <FormInput
                      rules={{
                        validate: VALID_EMAIL,
                      }}
                      errors={errors.email}
                      name='email'
                      type='email'
                      label='E-mail'
                      placeholder='E-mail'
                      control={control}
                    /> : null
                }
              </div>
              {/* <Button buttonText="Verify" /> */}
            </div>
          </Col>
        </Row>
      </Container>
    </form>
  );
};

export default LeadContactForm;
