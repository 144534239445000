import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './tableStyle.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import { TABLE_NOTIFICATIONS_COLUMNS_NAMES } from 'constants/table';
import moment from 'moment';
import { getShortUuid } from 'utils/getShortUuid';
import { copyToBuffer } from 'utils/copyToBuffer';
import { Link } from 'react-router-dom';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import Button from 'components/ui/Button/Button';
import { mapNotificationType } from "../../../../constants/notifications";

const cx = classNames.bind(styles);

const NotificationsTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  colSortCheck,
  markAsReadHandle,
  markAsReadDisableBtn,
}) => {
  const { columns, saveColumnOrder, setDefaultColumnsOrder } = useTableColumnOrder(
    'TABLE_NOTIFICATIONS_COLUMNS_NAMES',
    TABLE_NOTIFICATIONS_COLUMNS_NAMES,
  );
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, type, uuid, details, agent, client, created_at, priority, is_read } = item;

    return {
      type: (
        <div className={cx('notifications-table_td')}>
          <span className={cx(!is_read ? 'unread' : '')}>
            {uuid && (
              <>
                {/* <span>{type}</span> */}
                {mapNotificationType[type] || type}
                <span className="uuid" onClick={() => copyToBuffer(uuid)}>
                  {getShortUuid(uuid)}
                </span>
              </>
            )}
          </span>
        </div>
      ),
      details: (
        <div className={cx('notifications-table_td')}>
          {<span className={cx(!is_read ? 'unread' : '')}>{details}</span>}
        </div>
      ),
      agent: (
        <div className={cx('notifications-table_td')}>
          <span className={cx(!is_read ? 'unread' : '')}>
            {agent && (
              <>
                <span>{agent.full_name}</span>
                <span className="uuid" onClick={() => copyToBuffer(agent.uuid)}>
                  {getShortUuid(agent.uuid)}
                </span>
              </>
            )}
          </span>
        </div>
      ),
      client: (
        <div className={cx('notifications-table_td')}>
          <span className={cx(!is_read ? 'unread' : '')}>
            {client && (
              <>
                <Link
                  target={'_blank'}
                  to={`/client/${client?.id ?? 0}`}
                  className={cx('notifications-table_td-link')}
                >
                  <span>
                    {client?.user_profile?.first_name + ' ' + client?.user_profile?.last_name}
                  </span>
                </Link>
                <span className="uuid" onClick={() => copyToBuffer(client.uuid)}>
                  {getShortUuid(client.uuid)}
                </span>
              </>
            )}
          </span>
        </div>
      ),
      created_at: (
        <div className={cx('notifications-table_td')}>
          <span className={cx(!is_read ? 'unread' : '')}>
            <span>{created_at && moment(created_at * 1000).format('DD.MM.YYYY')}</span>
            <span>{created_at && moment(created_at * 1000).format('HH:mm:ss')}</span>
          </span>
        </div>
      ),
      priority: (
        <div
          className={cx('notifications-table_td')}
        >
          <span className={cx(!is_read ? 'unread' : '')}>
            <div
              className={cx(!is_read ? 'unreadColor' : '')}
              style={{
                width: '100%',
                height: 44,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <span className={cx(priority)}>{priority}</span>
            </div>
          </span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
      <div className={cx('button_container')}>
        <Button
          buttonStyle={{
            marginBottom: '12px',
            marginLeft: 0,
          }}
          buttonText="Reset column order"
          onClick={setDefaultColumnsOrder}
        />
        <Button
          buttonStyle={{
            marginBottom: '12px',
            marginLeft: 0,
          }}
          buttonText="Mark as read"
          onClick={markAsReadHandle}
          disabled={markAsReadDisableBtn}
        />
      </div>
      <Table
        saveColumnOrder={saveColumnOrder}
        columns={columns}
        data={tableDataMeMo}
        perPage={perPage}
        showLoader={showLoader}
        onRowSelect={onRowSelect}
        colSortCheck={colSortCheck}
        className={cx('notifications-table')}
        isSelected={true}
        getCellProps={(cellInfo, cx) => {
          const { row, value, column } = cellInfo;
          return {
            style: {
              backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
            },
          };
        }}
      />
    </>
  );
};

export default NotificationsTable;
