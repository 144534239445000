import React from 'react';
import classNames from 'classnames/bind';
import { Pencil, Trash } from 'phosphor-react';

import styles from './../../lead-page.module.scss';
import moment from 'moment';
import EditNotePopup from './EditNotePopup';
import { deleteNote } from 'api/notes';
import { notify } from 'utils/notify';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import { usePermission } from '../../../../../utils/usePermission';
import { LeadNote } from 'models/Leads/LeadNotes';

const cx = classNames.bind(styles);

type Props = {
  noteItem: LeadNote;
  updateNotesList: () => void;
}

const NotesItem = (props: Props) => {
  const { noteItem, updateNotesList } = props;

  const { permissionGiven: PermissionNotesDelete } = usePermission('admin.notes.delete');

  const removeNote = () => {
    deleteNote(noteItem.id)
      .then(() => {
        notify({
          type: 'success',
          message: 'Notes deleted successfully',
          timeOut: 3000,
        });
        updateNotesList();
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };

  return (
    <div className={cx('notes-list__item')}>
      <div className={cx('notes-list__header')}>
        <div className={cx('notes-list__item-info')}>
          <div className={cx('notes-list__item-author')}>
            {`${noteItem.operator.first_name} ${
              noteItem && noteItem.operator && noteItem.operator.last_name
                ? noteItem.operator.last_name
                : ''
            }`}{' '}
            {noteItem && noteItem.is_pinned ? <span className={cx('pinned')}>Pinned</span> : null}
          </div>
          <div className={cx('notes-list__item-ident')}>
            {noteItem && noteItem.operator.uuid ? (
              <span
                className='copuToBuffer'
                onClick={() => {
                  copyToBuffer(noteItem.operator.uuid);
                }}
              >
                {getShortUuid(noteItem.operator.uuid)}
              </span>
            ) : (
              '---'
            )}
          </div>
          <div className={cx('notes-list__item-date')}>
            {moment(noteItem.create_at * 1000).format('DD.MM.YYYY HH:mm:ss')} to{' '}
            <span
              className='copuToBuffer'
              onClick={() => {
                copyToBuffer(noteItem.to);
              }}
            >
              {getShortUuid(noteItem.to)}
            </span>
          </div>
        </div>
        <div className={cx('notes-list__item-actinBtn')}>
          <EditNotePopup
            updateComponent={() => updateNotesList()}
            noteData={noteItem}
            triggerBtn={<Pencil size={20} onClick={null} />}
          />

          {PermissionNotesDelete ?
            <Trash size={20} onClick={() => removeNote()} />
            : null
          }
        </div>
      </div>
      <div className={cx('notes-list__item-note')}>
        <div className={cx('subject')}>{noteItem.subject}</div>
        <div className={cx('body')}>{noteItem.body}</div>
      </div>
    </div>
  );
};
export default NotesItem;
