import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import { TABLE_DYNAMIC_STATUS_TYPES } from 'constants/table';
import { EyedropperSample, NotePencil, Trash } from 'phosphor-react';
import moment from 'moment';
import DeleteDynamicStatusesPopup from '../../../../pages/SettingsLk/dynamicStatuses/popups/DeleteDynamicStatusesPopup';
import EditStatusPopup from '../../../../pages/SettingsLk/dynamicStatuses/popups/EditStatusPopup';
import ChangeColorPopup from "../../../../pages/SettingsLk/dynamicStatuses/popups/ChangeColorPopup.tsx";
import { DynamicStatus } from "../../../../models/SettingsLK/DynamicStatuses/DynamicStatusesPage.ts";
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';

const cx = classNames.bind(styles);

interface IDynamicStatusesTableProps {
  permission: boolean;
  permissionChangeColor?: boolean;
  modifyDataHandler: () => Promise<void>;
  data: DynamicStatus[];
  type: string;
}

const DynamicStatusesTable: FC<IDynamicStatusesTableProps> = ({
                                                                data,
                                                                modifyDataHandler,
                                                                type,
                                                                permission,
                                                                permissionChangeColor
                                                              }) => {

  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_DYNAMIC_STATUS_TYPES", TABLE_DYNAMIC_STATUS_TYPES);

  // const columns = React.useMemo(() => TABLE_DYNAMIC_STATUS_TYPES, []);
  const tableData = React.useMemo(
    () => makeTableData(data, data.length),
    [data, permissionChangeColor],
  );
  const prepearedTableData = tableData.map((item, pos) => {
    const { id, key, translate, created_at, color_status, updated_at, active } = item;

    return {
      key: (
        <div className={cx('rule__name')}>
          <span>{key}</span>
        </div>
      ),
      translate: (
        <div className={cx('rule__name')}>
          <span>{translate}</span>
        </div>
      ),
      type: (
        <div className={cx('rule__name')}>
          <span>{type}</span>
        </div>
      ),
      active: (
        <div className={cx('rule__name')}>
          <span>{active ? 'true' : 'false'}</span>
        </div>
      ),
      created_at: (
        <div className={cx('rule__name')}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      updated_at: (
        <div className={cx('rule__name')}>
          <span>{moment.unix(updated_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(updated_at).format('HH:mm')}</span>
        </div>
      ),
      change_color: (
        <div className={cx('rule__color')}>
          <div
            style={{ backgroundColor: color_status || '#722ed1' }}
            className={cx('rule__color-circle')}
          >
          </div>
          {permissionChangeColor ?
            <ChangeColorPopup
              rule={item}
              triggerBtn={
                <span className={cx('rule__color-button')}>
              <EyedropperSample size={20} />
            </span>
              }
              updateComponent={modifyDataHandler}
            /> : null
          }
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          <div>
            <DeleteDynamicStatusesPopup
              rule={item}
              triggerBtn={<Trash size={20} />}
              updateComponent={modifyDataHandler}
            />
          </div>
          <div>
            {permission ?
              <EditStatusPopup
                dataItem={item}
                triggerBtn={<NotePencil size={20} />}
                updateComponent={modifyDataHandler}
              />
              : null
            }
          </div>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={1000000}
      // showLoader={showLoader}
      // onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
    />
    </>
  );
};

export default DynamicStatusesTable;
