import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../components/ui/CrmSearch/CrmSearch";
import {
  CrmSearchCategorySelect,
  defaultQueryCategoryOption
} from "../../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import useDynamicSearchPlaceholder from "../../../../utils/useDynamicSearchPlaceholder";
import { useSearchCategoriesOptions } from "../../../../utils/useGetSearchCategoriesOptions";
import styles from './desks-filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';

import Button from 'components/ui/Button/Button';
import JokerSelect from '../../../../components/ui/JokerSelect';
import { FormattedMessage } from 'react-intl';
import PaginateSelect from '../../../../components/ui/PaginateSelect/PaginateSelect';
import { fetchOffices } from '../../../../utils/managment/fetchData';
import { isFormChange } from '../../../../utils/isFormChange';
import { getValue } from '../../../../utils/getValue';
import { clearEmptyFilter } from '../../../../utils/clearEmptyFilter';
import { ManagementDesksFilter } from 'models/Management/Desks/Filter';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  onSubmit: (data: ManagementDesksFilter) => void;
  reloadFilters: boolean;
  deskTypeList: ListValue[];
  queryMessage: string,
}

const DesksFilter = (props: Props) => {
  const { onSubmit, reloadFilters, deskTypeList, queryMessage} = props;
  const desksSearchOptions = useSearchCategoriesOptions('desk');

  const componentMounted = useRef(false);
  const getDefaultValue = () => {
    return {
      query: '',
      query_category: defaultQueryCategoryOption,
      office_ids: [],
    };
  };
  const { handleSubmit, reset, getValues, control, formState, watch } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, desksSearchOptions);

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        office_ids: getValue(data['office_ids']) ? [getValue(data['office_ids'])] : '',
        types: getValue(data['types']) ? [getValue(data['types'])] : '',
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    if (data['query_category']) {
      postData['searchCategory'] = data['query_category']?.value;
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset(getDefaultValue());
  };

  useEffect(() => {
    componentMounted.current = true;

    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={cx('filters')}
      >
        <Row>
          <Col md={12}>
            <ComplexSearchBar message={queryMessage}>
              <>
                <CrmSearchCategorySelect
                  id='query_category'
                  name='query_category'
                  control={control}
                  options={desksSearchOptions}
                  onSelectChange={() => {
                      const { query } = getValues();
                    if (query) submit(getValues());
                  }}
                />
                <CrmSearch
                  id='query'
                  name='query'
                  control={control}
                  placeholder={dynamicSearchPlaceholder}
                  onSearch={() => submit(getValues())}
                  handleChange={(value: string) => {
                      if (value?.length === 0) submit(getValues());
                  }}
                />
              </>
            </ComplexSearchBar>
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <PaginateSelect
              isMulti={false}
              label="Office"
              control={control}
              id="office_ids"
              name="office_ids"
              onChange={(page, search) => fetchOffices(page, search)}
            />
          </Col>

          {deskTypeList && (
            <Col xl={3} lg={4} md={6}>
              <JokerSelect
                label={
                  <FormattedMessage id="management.desks.filter3" defaultMessage="Desk Type" />
                }
                control={control}
                id="types"
                name="types"
                options={deskTypeList}
              />
            </Col>
          )}
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => resetForm()}
              disabled={!isFormChange(dirtyFields)}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default DesksFilter;
