import cn from 'classnames';
import './CheckboxInputDecor.scss';

type CheckboxSize = 'lg' | 'md' | 'sm';
type Props = {
  checked: boolean;
  error?: boolean;
  className?: string;
  size?: CheckboxSize | undefined;
  onClick?: () => void;
};

export const CheckboxInputDecor = ({ checked, onClick, size = 'md', error, className }: Props) => {
  return (
    <div className={cn('checkbox-input-decor', className, size, { checked, error })} onClick={onClick}>
      <div className="checkbox-input-value"></div>
    </div>
  );
};
