import React, { useEffect, useMemo, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { useFieldArray, useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './styles-popup.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
import FormInput from 'components/ui/FormInput';
import { notify } from 'utils/notify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import JokerSelectWithSearch from 'components/ui/JokerSelectWithSearch/JokerSelectWithSearch';
import {
  createTemplateMethod,
  getDepartmentsListMethod,
  getPermissionsListMethod,
  getTotalDepartmentsListMethod,
} from '../../../api/templateRoles';
import JokerMultiSelect from 'components/ui/JokerMultiSelect';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  actionType: string;
}

const CreateTemplatesPopup = (props: Props) => {
  const { triggerBtn, updateComponent, actionType } = props;

  const [isLoading, setIsLoading] = useState(false);
  const contentToScrol = useRef(null);
  const permissionsSelectRef = useRef(null);
  const [departmentsList, setDepartmentsList] = useState<ListValue[]>([]);
  const [permissionsList, setPermissionsList] = useState<ListValue[]>([]);

  const [permissionsActiveList, setPermissionsActiveList] = useState<ListValue[]>([]);
  const [permissionsAvailableList, setPermissionsAvailableList] = useState<ListValue[]>([]);

  const permissionsActiveIdsSet = useMemo(() => new Set(permissionsActiveList.map(v => v.value)), [permissionsActiveList]);
  const permissionsAvailableIdsSet = useMemo(() => new Set(permissionsAvailableList.map(v => v.value)), [permissionsAvailableList]);

  const setDefaultValues = () => {
    return {
      name: undefined,
      department_ids: undefined,
      permissions_ids: null,
      permissions_active_ids: null,
    };
  };
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };

  useEffect(() => {
    reset(setDefaultValues());
  }, [permissionsList, departmentsList]);

  const onSubmit = (close, data) => {
    const sendData = {
      name: data.name,
      selected_permissions: getValue(data['permissions_active_ids']),
      available_permissions: getValue(data['permissions_ids']),
      department_id: getValue(data['department_ids']),
    };

    if (actionType === SALES_RULES_ACTION_TYPE.CREATE) {
      addTemplateHandler(sendData, close);
    }
  };

  const addTemplateHandler = (sendData, close) => {
    setIsLoading(true);

    createTemplateMethod(sendData)
      .then((res) => {
        if (res.status === 201) {
          notify({
            type: 'success',
            message: 'Template created successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };
  const fetchPermissionsList = () => {
    getPermissionsListMethod().then((res) => {
      if (res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.id,
          label: opt.description,
        }));
        setPermissionsList([...opt]);
      }
    });
  };
  const fetchDepartmentsList = () => {
    getTotalDepartmentsListMethod().then((res) => {
      if (res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.id,
          label: opt.name,
        }));
        setDepartmentsList([...opt]);
      }
    });
  };
  const onOpen = () => {
    fetchDepartmentsList();
    fetchPermissionsList();
  };
  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={
              actionType === SALES_RULES_ACTION_TYPE.CREATE ? 'New template' : 'Edit template'
            }
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')} ref={contentToScrol}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Tabs className={cx('tabs')}>
                      <TabPanel>
                        <Container>
                          <Row>
                            <Col md={12}>
                              <FormInput
                                rules={{
                                  required: 'Template name field is required',
                                }}
                                label={'Template name'}
                                id="template_name"
                                control={control}
                                name="name"
                                placeholder={'Template name'}
                                errors={errors.name}
                              />
                            </Col>
                          </Row>
                          <Row>
                            <Col md={12}>
                              <JokerSelectWithSearch
                                isRequired={true}
                                label="Department"
                                control={control}
                                id="department"
                                name="department_ids"
                                placeholder={'Search'}
                                rightaligned={true}
                                options={departmentsList}
                                errors={errors.department_ids}
                                rules={{
                                  required: 'Department field is required',
                                }}
                              />
                            </Col>
                          </Row>
                          <Row ref={permissionsSelectRef}>
                            <Col md={6}>
                              <div className={cx('tabs-panel')}>
                                <JokerMultiSelect
                                  label="Permissions active"
                                  control={control}
                                  id="permissions_active"
                                  name="permissions_active_ids"
                                  onMenuOpen={() => {
                                    setTimeout(
                                      () =>
                                        contentToScrol.current.scrollTo({
                                          top: permissionsSelectRef.current.offsetTop - 25,
                                          left: 0,
                                          behavior: 'smooth',
                                        }),
                                      10,
                                    );
                                  }}
                                  placeholder={'--Select one or multiple options--'}
                                  rightAligned={true}
                                  options={permissionsList.filter(perm => !permissionsAvailableIdsSet.has(perm.value))}
                                  onChange={setPermissionsActiveList}
                                />
                              </div>
                            </Col>
                            <Col md={6}>
                              <div className={cx('tabs-panel', 'pad-top-0')}>
                                <JokerMultiSelect
                                  label="Permissions available"
                                  control={control}
                                  id="permissions"
                                  name="permissions_ids"
                                  onMenuOpen={() => {
                                    setTimeout(
                                      () =>
                                        contentToScrol.current.scrollTo({
                                          top: permissionsSelectRef.current.offsetTop - 25,
                                          left: 0,
                                          behavior: 'smooth',
                                        }),
                                      10,
                                    );
                                  }}
                                  placeholder={'--Select one or multiple options--'}
                                  rightAligned={true}
                                  options={permissionsList.filter(perm => !permissionsActiveIdsSet.has(perm.value))}
                                  onChange={setPermissionsAvailableList}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Container>
                        <div className={cx('content-controls')}>
                          <Container>
                            <Row>
                              <Col md={12}>
                                <div className={cx('controls__buttons')}>
                                  <Button
                                    buttonText="Cancel"
                                    buttonType="outline"
                                    size="big"
                                    type="button"
                                    onClick={closeModal.bind(undefined, close)}
                                  />
                                  <LoaderButton
                                    buttonType="primary"
                                    size="big"
                                    showSpinner={isLoading}
                                    // disabled={isLoading || !isDirty}
                                    buttonText={'Create template'}
                                    type="submit"
                                    onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </TabPanel>
                      <TabPanel></TabPanel>
                    </Tabs>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default CreateTemplatesPopup;
