import { APICreateCallbackResponse, APIEditCallbackResponse, APIFindCallbacksResponse } from 'models/API/Callbacks/Callbacks';
import API from '../api';

export const findAllCallbacksMethod = (params, data) =>
  API.post<APIFindCallbacksResponse>(`/api/v1/search/callbacks?${params}`, data);

export const findCallbacksMethod = (params, data, id) =>
  API.post<APIFindCallbacksResponse>(`/api/v1/search/callbacks/users/${id}?${params}`, data);

export const createCallbacks = (data) => API.post<APICreateCallbackResponse>('/api/v1/callbacks', data);

export const updateCallbacks = (data, id) => API.put<APIEditCallbackResponse>(`/api/v1/callbacks/${id}`, data);

// response (message) unused
export const removeCallbackItem = (id) => API.delete(`/api/v1/callbacks/${id}`);

// response (message) unused
export const disabledCallbackItem = (id, status) =>
  API.put(`/api/v1/callbacks/${id}/status-change`, { status: status });

export const getUserCalbecksList = (id) => API.get<APIFindCallbacksResponse>(`/api/v1/callbacks/user/${id}`);

export const getUserRemindersList = (id) => API.get(`/api/v1/callbacks/my`);
