import { getLogo } from 'constants/selectors';
import { Link, useLocation } from 'react-router-dom';
import { useAppSelector } from 'store';
import cn from 'classnames';
import styles from './logo.module.scss';

type Props = {
  onClick: () => void;
};

export const Logo = ({ onClick }: Props) => {
  const logo = useAppSelector(getLogo);
  const location = useLocation();

  return (
    <Link
      to="/search-clients"
      className={cn(styles.logo)}
      onClick={() => {
        location.pathname !== '/search-clients' && onClick();
      }}
    >
      <img src={logo} alt="logo" />
    </Link>
  );
};
