import { createApi } from "@reduxjs/toolkit/query/react";
import axiosBaseQuery from "../../api/axiosBaseQuery";

export const searchCategoriesQuery = createApi({
  reducerPath: "searchCategories",
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    getSearchCategories: builder.query<Record<string, Record<string, string>>, void>({
      query: () => '/api/v1/search/search-categories'
    })
  })
});

export const { useGetSearchCategoriesQuery, endpoints } = searchCategoriesQuery;