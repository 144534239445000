import { FunctionComponent } from 'react';
import {CaretDown, MagnifyingGlass} from "phosphor-react";
import { DropdownIndicatorProps, components } from 'react-select';

export const DropdownIndicator: FunctionComponent<DropdownIndicatorProps> = (props) => {
    return (
        <components.DropdownIndicator {...props}>
            <MagnifyingGlass size={20} color='#262626FF'/>
        </components.DropdownIndicator>
    );
};
