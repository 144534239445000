import { getMTStatusInfo } from "../api/MT_ERROR";
import { notify } from "./notify";
import { store } from '../store';

export const checkMtStatus = () => {
  const state = store.getState();
  
  if (state.userInfo.ENABLE_WEB_TRADER) {
    return;
  }

  getMTStatusInfo()
  .then(res => {
    const { connect, message } = res.data;
    if (!connect) {
      notify({
        type: 'error',
        message: `${message}`,
        timeOut: 5000,
      })
    }
  })
}