import { JokerPermissionKey, JokerPermissionObject, PERMISSION_DENIED_URL } from 'constants/permissions';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

export const checkPermission = (name: JokerPermissionKey) => {
  const role = JSON.parse(localStorage.getItem('role'))[0];

  if (role === 'Super Admin') {
    return true;
  }

  const permissions: JokerPermissionObject[] = localStorage.getItem('permissions')
    ? JSON.parse(localStorage.getItem('permissions'))
    : [];

  const permission = permissions.find((p) => p.name === name);
  return !!permission;
}

export const usePermission = (name: JokerPermissionKey, redirectToErrorPage?: boolean) => {
  const [enabled, setEnabled] = useState<boolean>(false);

  const navigate = useNavigate();

  const checkPermissionInHook = () => {
    const role = JSON.parse(localStorage.getItem('role'))[0];
    const active = checkPermission(name);

    if (!active && redirectToErrorPage && role !== 'Super Admin') {
      navigate(PERMISSION_DENIED_URL);
    }

    setEnabled(active);
  };

  useEffect(() => {
    checkPermissionInHook();
  }, [name]);

  return { permissionGiven: enabled };
};
