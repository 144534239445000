import { memo, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../client-page.module.scss';
import { Client } from 'models/Clients/Clients';
import AfilateEditPopup from '../AfilateEditPopup';
import { usePermission } from 'utils/usePermission';
import { notify } from 'utils/notify';
const cx = classNames.bind(styles);

type Props = {
  clientData: Client;
};

const Affiliate = memo<Props>(({ clientData }) => {
  const { permissionGiven: PermissionChangeAffiliationData } = usePermission(
    'admin.client.change-affiliation-data',
  );
  const [source, setSource] = useState(clientData.source);
  const [referral, setReferral] = useState(clientData.referral);

  const affBox = (
    <div style={{ height: '100%', cursor: 'pointer' }}>
      <div className={cx('client-info__col-title')}>
        Affiliate 
      </div>
      <div className={cx('client-info__col-content')}>
        <div>
          <b>
            {`${clientData?.affiliate?.first_name || ''} ${clientData?.affiliate?.last_name || ''}`}
          </b>
        </div>
        <div className={cx('word-break-all')}>Source: {source ? source : '---'}</div>
        <div className={cx('word-break-all')}>Referral: {referral ? referral : '---'}</div>
      </div>
    </div>
  );

  return (
    <>
    {PermissionChangeAffiliationData?
      <AfilateEditPopup
        user_id={clientData.id}
        affilate={`${clientData?.affiliate?.first_name || ''} ${
          clientData?.affiliate?.last_name || ''
        }`}
        source={source || ''}
        setSource={setSource}
        referral={referral || ''}
        setReferral={setReferral}
        trigger={affBox}
      />
      :
      <div onClick={()=>{
        notify({
          type: 'warning',
          message: `You don't have permission to edit these settings`,
          timeOut: 3000,
        });
      }}>
        {affBox}
      </div>
    }
    </>
  );
});

export default Affiliate;
