import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './select.module.scss';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';
import ReactSelect from 'react-select';
import './select.scss';
import { IOptions, ISelectProps } from "../../../models/components/Select/Select.ts";

const cx = classNames.bind(styles);
const customStyles = {
  control: (styles) => ({
    ...styles,
    border: 'none',
    minHeight: '41px',
    // maxHeight: '41px',
    '&:hover': {
      borderColor: 'var(--main-color)!important',
      boxShadow: '0 0 0 1px var(--main-color)!important',
    },
    '&:active': {
      borderColor: 'var(--main-color)!important',
      boxShadow: '0 0 0 1px var(--main-color)!important',
    },
    '&:focus': {
      borderColor: 'var(--main-color)!important',
      boxShadow: '0 0 0 1px var(--main-color)!important',
    },
  }),
  valueContainer: (styles) => ({
    ...styles,
    padding: '8px',
  }),
  menuList: (styles) => ({
    ...styles,
    width: '100%',
    borderBottom: '1px solid #c2c2c2',
    borderRadius: '4px',
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? 'var(--main-color)' : '#ffffff',
    zIndex: 999,
    '&:hover': {
      backgroundColor: 'var(--purple-100)',
      color: '#000000',
    },
  }),
  singleValue: (styles) => ({
    ...styles,
    lineHeight: 'normal',
  }),
  indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
};

const SelectComponent: FC<ISelectProps> = (props: React.PropsWithChildren<any>) => {
  const { field } = useController(props);
  const defaultValue: IOptions = props.options && props.options.length ? props.options[0] : null;

  return (
    <>
      <div className={cx('label-wrap')}>
        {props.label && (
          <div className={props.errors ? cx('label', 'error') : cx('label')}>
            {props.label}
            {props.rules && props.rules.required ? <span className={cx('required')}>*</span> : ''}
          </div>
        )}
        <div className={cx('icon-container')}>
          {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
        </div>
      </div>
      <div className={props.errors ? cx('select-wrap', 'error') : cx('select-wrap')}>
        <ReactSelect
          {...field}
          className={props.errors ? cx('select', 'error') : cx('select')}
          classNamePrefix={'default-select'}
          options={props.options}
          isMulti={props.isMulti}
          isSearchable={props.isSearchable}
          value={field.value}
          // defaultValue={defaultValue}
          placeholder={props.placeholder}
          getOptionValue={props.getOptionValue}
          getOptionLabel={props.getOptionLabel}
          isClearable={props.isClearable}
          styles={customStyles}
          isDisabled={props.disabled}
          onMenuOpen={props.onMenuOpen}
          maxMenuHeight={props.maxMenuHeight}
          onChange={(e): void => {
            field.onChange(e);
            if (props.handleChange) {
              props.handleChange(e);
            }
          }}
          key={props.key}
        />

        {props.errors && <p className={cx('errorName')}>{props.errors.message}</p>}
      </div>
    </>
  );
};

export default SelectComponent;
