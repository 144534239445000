import { usePermission } from '../../../../../utils/usePermission.tsx';
import { Col, Row } from 'react-bootstrap';
import Button from '../../../../../components/ui/Button/index.js';
import React, { useState } from 'react';
import classNames from 'classnames/bind';
import styles from './add-branch.module.scss';
import { getDictionaryMethod } from '../../../../../api/managements/operators/index';
import AddBranchForm from './AddBranchForm';
import BranchItem from './BranchItem';
import { deleteBranchMethod } from '../../../../../api/operator/index';
import { notify } from '../../../../../utils/notify.tsx';
import ChangeBranchForm from './ChangeBranchForm';
import { ManagementOperator } from 'models/Management/Operators/Operator.js';
import { HierarchyBranch } from 'models/Hierarchy/HierarchyData.js';
import { ListValue } from 'models/ListValue.js';

type Props = {
  operatorData: ManagementOperator;
  list: HierarchyBranch[];
  role: string[];
  updateComponent: () => void;
}

const BranchesBlock = (props: Props) => {
  const { operatorData, list, role, updateComponent } = props;

  const { permissionGiven: PermissionCreateBranch } = usePermission('admin.branch.create');

  const cx = classNames.bind(styles);
  const [branchTypeList, setBranchTypeList] = useState<ListValue[]>([]);
  const [openForm, setOpenForm] = useState(false);
  const [openChangeForm, setOpenChangeForm] = useState(false);
  const [oldBranchId, setOldBranchId] = useState<number>();
  const onDeleteBranch = (item) => {
    deleteBranchMethod(item.id)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };
  const fetchBranchTypeList = () => {
    getDictionaryMethod().then((res) => {
      if (res.status === 200) {
        const list = res.data.branch_types.map((opt) => ({
          value: opt,
          label: opt,
        }));
        setBranchTypeList([...list]);
      }
    });
  };
  const openDepartmentForm = () => {
    setOpenForm(true);
    fetchBranchTypeList();
  };
  return (
    <div className={cx('listBlock')}>
      <Row className={cx('form')}>
        <Col md={12}>
          <div className={cx('title')}>Branches</div>
        </Col>
      </Row>
      <div className={cx('item_BranchList')}>
        {list?.map((item, index) => {
          return (
            <div key={item.id}>
              <BranchItem
                setChangeOpenForm={setOpenChangeForm}
                fetchBranchTypeList={fetchBranchTypeList}
                item={item}
                onDeleteBranch={onDeleteBranch}
                list={list}
                openChangeForm={openChangeForm}
                setOldBranchId={setOldBranchId}
              />
            </div>
          );
        })}
      </div>
      {openChangeForm ?
        <ChangeBranchForm
          oldBranchId={oldBranchId}
          operatorData={operatorData}
          updateComponent={updateComponent}
          setOpenForm={setOpenChangeForm}
        /> : null
      }
      {
        PermissionCreateBranch ?
          <>
            {openForm ? (
              <AddBranchForm
                operatorData={operatorData}
                branchTypeList={branchTypeList}
                updateComponent={updateComponent}
                setOpenForm={setOpenForm}
              />
            ) : (
              <Button
                buttonStyle={{ marginLeft: 0 }}
                buttonType='outline'
                buttonText='+ Add Branch'
                onClick={() => {
                  openDepartmentForm();
                }}
              />
            )}
          </> : null
      }
    </div>
  );
};
export default BranchesBlock;
