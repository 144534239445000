import { uploadDocument } from 'api/documents';
import classNames from 'classnames/bind';
import React, { useState } from 'react';
import { ProgressBar } from 'react-bootstrap';
import { FileUploader } from 'react-drag-drop-files';
import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { getUploadLog, uploadFile } from '../../../api/leads';

import { notify } from '../../../utils/notify';
import Button from '../Button/Button';
import DocumentTypePopup from '../DocumentTypePopup';
import Preloader from '../Preloader';
import styles from './fle-uploader.module.scss';

const cx = classNames.bind(styles);

const FileUploadComponent = (props) => {
    const {label, isMultiple} = props;
    const [files, setFile] = useState(null);
    const [progress, setProgress] = useState<number>(0);
    const [isUploading, setIsUploading] = useState<boolean>(false);

    const helpText: string = `Supported files are ${props.types.map(item => '.' + item).join(', ') || '.DOC, .DOCX, .PDF, .JPG, .PNG, .ODT'}. The maximum size of a single file is ${props.maxSize || 16} Mb.`;

    const handleChange = (fileOrFileList): void => {
        if (!fileOrFileList) {
            return;
        }
        const fileArr = [];
        if (isMultiple) {
            for (const key of Object.keys(fileOrFileList)) {
                fileArr.push(fileOrFileList[key]);
            }
        } else {
            fileArr.push(fileOrFileList);
        }
        setProgress(0);
        setFile(fileArr);
    };

    const deleteFile = (pos): void => {
        if (!files || !files.length) {
            return setFile(null);
        }
        const arr = files.filter((item, index): boolean => index !== pos);
        if (!arr.length) {
            return clearData();
        }
        setFile(arr);
    };

    const renderFiles = () => {
        const renderedFiles = [];
        if (files && files.length) {
            for (let i = 0; i < files.length; i++) {
                renderedFiles.push(
                    <div key={i} className={cx('file')}>
                        <i className={cx('file__delete')} onClick={deleteFile.bind(undefined, i)}></i>
                        <span>{files[i].name}</span>
                    </div>,
                );
            }
        }
        return renderedFiles;
    };

    const requestConfig = {
        onUploadProgress: (progressEvent): void => {
            const total = progressEvent.total;
            const persent: number = total / 100;
            const uploaded: number = progressEvent.loaded / persent;
            setProgress(Number(uploaded.toFixed()));
        },
    };

    const clearData = (): void => {
        const input: HTMLInputElement = document.querySelector('input[type="file"]');
        const form: HTMLFormElement = document.querySelector('form');
        form.value = '';
        input.value = '';
        setFile(null);
    };

    const sendFile = (data): void => {
        if (!data) {
            return;
        }

        setIsUploading(true);
        const formData: FormData = new FormData();
        const formDataFileKey: 'file[]' | 'file' = isMultiple ? 'file[]' : 'file';

        switch (props.type) {
            case 'kyc_document':
                for (const item of files) {
                    formData.append(formDataFileKey, item, item.name.split('.')[0]);
                    formData.append('user_id', props.userId);
                    formData.append('type_key', data.type);
                }

                uploadDocument(formData, requestConfig)
                    .then((res): void => {
                        setProgress(0);
                        clearData();
                        setIsUploading(false);
                        props.getDocumentId(res.data.data.id);
                        NotificationManager.success(`File upload succesfull`, 'Finish upload', 3000);
                        props.onSuccess?.();
                    })
                    .catch((err): void => {
                        setProgress(0);
                        setIsUploading(false);

                        notify({
                            type: 'error',
                            message: err.response,
                            timeOut: 3000,
                        });
                        props.onFail?.();
                    });
                break;

            case 'lead_document':
                for (const item of files) {
                    formData.append(formDataFileKey, item, item.name.split('.')[0]);
                }
                uploadFile(formData, requestConfig)
                    .then((res): void => {
                        setProgress(0);
                        clearData();
                        setIsUploading(false);

                        NotificationManager.success(`File upload succesfull`, 'Finish upload', 3000);
                        props.onSuccess?.();
                        setTimeout(() => props.updateComponent?.(), 2000)
                    })
                    .then((): void => {
                        getUploadLog().then((res): void => {
                            if (res && res.status === 200 && res?.data?.data?.length > 0) {
                                notify({
                                    type: 'error',
                                    message: 'Error! Details in the tab "View errors log"',
                                    timeOut: 3000,
                                });
                            }
                        });
                    })
                    .catch((err): void => {
                        setProgress(0);
                        setIsUploading(false);

                        notify({
                            type: 'error',
                            message: err.response,
                            timeOut: 3000,
                        });
                        props.onFail?.();
                    });

                break;
            default:
        }
    };

    const onSizeError = (): void => {
        NotificationManager.error(
            `Invalid file size. Max size: ${props.maxSize}MB`,
            'File size error',
            3000,
        );
    };

    const onTypeError = (): void => {
        NotificationManager.error(
            `File type must be: ${props.types?.join(', ')}.`,
            'Invalid file type',
            3000,
        );
    };

    const filesBlock: React.JSX.Element = (
        <div className={cx('file-uploader__files')}>
            {progress !== 0 ? (
                <div className={cx('progress-bar')}>
                    <span className={cx('progress-bar__status')}>{progress}%</span>
                    <ProgressBar now={progress}/>
                </div>
            ) : (
                ''
            )}

            <div className={cx('files__container')}>{renderFiles()}</div>
        </div>
    );

    return (
        <>
            {isUploading ?
                <Preloader/>
                : null
            }
            <div className={isUploading ? cx('file-uploader', 'blur') : cx('file-uploader')}>
                <form
                    onSubmit={(e: React.FormEvent<HTMLFormElement>): void => {
                        e.preventDefault();
                    }}
                >
                    <div className={cx('file-uploader__item')}>
                        <FileUploader
                            handleChange={handleChange}
                            name='file'
                            multiple={isMultiple}
                            hoverTitle={' '}
                            fileOrFiles={files}
                            onSizeError={onSizeError}
                            onTypeError={onTypeError}
                            classes='drop_area drop_zone'
                            maxSize={props.maxSize}
                            types={props.types}
                        >
                            <div className={cx('file-uploader__area')}>
                                <i className={cx('file-uploader__icon')}></i>
                                <span className={cx('file-uploader__title')}>Drag & Drop to Upload file</span>
                                {!files ? <span className={cx('span_item')}>OR</span> : ''}
                                <div className={cx('file-uploader__bottom')}>
                                    <span className={cx('file-uploader__button')}>Browse files</span>
                                </div>
                            </div>
                        </FileUploader>
                        <div className={cx('controls__buttons')}>
                            <Button
                                buttonText='Cancel'
                                buttonType='outline'
                                size='big'
                                type='button'
                                onClick={(): void => {
                                    props.onSuccess()
                                }}
                            />
                            {props.type !== 'lead_document' ? (
                                    <DocumentTypePopup
                                        trigger={<Button type='button' buttonText='Send' size='big' disabled={!files}/>}
                                        onClose={(data) => sendFile(data)}
                                        docName={files?.[0].name}
                                    />
                                ) :
                                <Button
                                    type='button'
                                    buttonText='Send'
                                    size='big'
                                    onClick={sendFile}
                                    disabled={!files}
                                />
                            }
                        </div>
                    </div>
                    {files && files.length ? filesBlock : ''}
                </form>
                {files ? '' : <span className={cx('file-uploader__types')}>{helpText}</span>}
            </div>
        </>
    );
};

export default FileUploadComponent;
