import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './styles-popup.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import OperatorIcon from '../../assets/Svg/OperatorIcon';
import { HierarchyBrand, HierarchyDesk, HierarchyOffice, HierarchyTeam } from 'models/Hierarchy/HierarchyData';
import { AxiosResponse } from 'axios';
import { APIHierarchyGetOperatorsResponse } from 'models/API/Hierarchy/Hierarchy';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  namePopup: string;
  data: HierarchyDesk | HierarchyTeam | HierarchyBrand | HierarchyOffice;
  fetchMethod: (id: string | number) => Promise<AxiosResponse<APIHierarchyGetOperatorsResponse, any>>;
}

const UserPopup = (props: Props) => {
  const { triggerBtn, namePopup, data, fetchMethod } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const closeModal = (closeModal) => {
    closeModal();
  };
  const onOpen = () => {
    setIsLoading(true);
    fetchMethod(data.id)
      .then((res) => {
        if (res.status === 200) {
          setUsersList(res.data.data.operators);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={onOpen}
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={`Users List for ${namePopup}`}
            rightContent={
              <div className={cx('content-form', 'popupForm')}>
                <div className={cx('content-controls')}>
                  <Container className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
                    <Row>
                      <Col md={12}>
                        <div className={styles.wrapperList}>
                          {usersList?.map((operator) => {
                            return (
                              <div key={operator.id} className={styles.itemList}>
                                <div className={styles.lastLevelBefore}></div>
                                <div className={cx('wrapperContent', 'greenBorder')}>
                                  <div className={styles.btnIcon}>
                                    <OperatorIcon />
                                  </div>
                                  <div className={styles.wrapperInfo}>
                                      <div className={styles.topBlock}>
                                        <div className={styles.bolderText}>
                                          {operator?.first_name} {operator?.last_name}
                                        </div>
                                        <div className={styles.greyText}>Operator</div>
                                      </div>
                                      <div className={styles.bottomBlock}>
                                        {operator.role.map((item, ind) => {
                                          return (
                                            <span key={ind} className={styles.greyText}>
                                              {item}
                                              {operator.role.length - 1 !== ind && <span>,</span>}
                                            </span>
                                          );
                                        })}
                                      </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default UserPopup;
