import classNames from 'classnames/bind';

import Button from 'components/ui/Button';
import TablePagination from 'components/ui/TablePagination';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import React, { useEffect, useRef, useState } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { getTemplatesListMethod } from '../../api/templateRoles/index';
import TemplateTable from '../../components/ui/Table/TemplateTable/TemplateTable';
import { usePermission } from '../../utils/usePermission.tsx';
import Page from '../Page/index.js';
import { DashboardLayout } from '../UserView/DashboardLayout';
import RolesTab from './RolesTab/RolesTab';

import styles from './templates-page.module.scss';
import CreateTemplatesPopup from './TemplatesPopups/CreateTemplatesPopup';
import { getTablePageParams } from '../../utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { TemplateListEntry } from 'models/RolesAndPermissions/Templates/Template.js';
import { getDataAutoRefresh } from "../../api/update-page";
import { Row } from "react-bootstrap";
import AutoRefresh from "./AutoRefresh.tsx";

const cx = classNames.bind(styles);

const TemplateRolePage = () => {
  const today = new Date
  const { permissionGiven: PermissionCreate } = usePermission('admin.role.create');
  const { permissionGiven: PermissionShowList } = usePermission('admin.role.index');
  const { permissionGiven: PermissionEdit } = usePermission('admin.role.edit');
  const { permissionGiven: PermissionDelete } = usePermission('admin.role.delete');

  const [templateList, setTemplateList] = useState<TemplateListEntry[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const [dataAutoRefresh, setDataAutoRefresh] = useState([])
  const [columnsName, setColumnsName] = useState([])
  const role = JSON.parse(localStorage.getItem('role'))[0]

  const componentMounted = useRef(true);
  document.title = 'Template roles';

  useEffect(() => {
    getDataAutoRefresh()
      .then(res => {
        setColumnsName(Object.keys(res.data))
        setDataAutoRefresh(res.data)
      })
      .catch(console.log)
  }, [role]);

  useEffect(() => {
    getTemplateList();
    return () => {
      componentMounted.current = false;
    };
  }, []);
  const getTemplateList = (options?, data?) => {
    setIsListLoading(true);

    getTemplatesListMethod(getTablePageParams(options, perPageCount, tableLinks))
      .then((res) => {
        if (componentMounted.current) {
          setTemplateList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container__block')}>
          <div className={cx('profile__tabs')}>
            <Tabs className={cx('tabs')}>
              <TabList>
                <Tab>Templates</Tab>
                <Tab>Roles</Tab>
                {role === 'Super Admin' ?
                  <Tab>Check Auto Refresh</Tab>
                  : null
                }
              </TabList>

              <TabPanel>
                <div className={cx('tab-panel')}>
                  <div className={cx('container')}>
                    <div className={cx('container__header')}>
                      <div className={cx('header')}>
                        <div className={cx('search-result')}>
                          <span>
                            <strong>{PermissionShowList ? tableMeta?.total || 0 : null}</strong> Templates
                          </span>
                          <span></span>
                        </div>
                        <div className={cx('header-controls')}>
                          {PermissionCreate ?
                            <CreateTemplatesPopup
                              updateComponent={() => getTemplateList()}
                              actionType={SALES_RULES_ACTION_TYPE.CREATE}
                              triggerBtn={
                                <Button
                                  buttonText={'+ Add template'}
                                  onClick={() => null}
                                  buttonType={'outline'}
                                />
                              }
                            />
                            : null
                          }
                        </div>
                      </div>
                    </div>
                    <div className={cx('container__block')}>
                      <TemplateTable
                        PermissionEdit={PermissionEdit}
                        PermissionDelete={PermissionDelete}
                        data={PermissionShowList ? templateList : []}
                        perPage={templateList?.length}
                        showLoader={isListLoading}
                        modifyDataHandler={(callback) => getTemplateList()}
                      />
                      {PermissionShowList ?
                        templateList?.length ? (
                          <TablePagination
                            goToFirstPage={() => {
                              getTemplateList({ navigate: 'first' });
                            }}
                            goToPrevPage={() => {
                              getTemplateList({ navigate: 'prev' });
                            }}
                            goToNextPage={() => {
                              getTemplateList({ navigate: 'next' });
                            }}
                            goToLastPage={() => {
                              getTemplateList({ navigate: 'last' });
                            }}
                            currentPage={tableMeta?.current_page}
                            pagesLength={tableMeta?.last_page}
                            perPageChange={(value) => {
                              setPerPageCount(value);
                              getTemplateList({ perPage: value });
                            }}
                            goToSelectedPage={(page) => {
                              getTemplateList({ page });
                            }}
                            hidePageSelect={true}
                            isFirstPageDisable={() => tableMeta?.current_page === 1}
                            isPrevPageDisable={() => !tableLinks?.prev}
                            isNextPageDisable={() => !tableLinks?.next}
                            isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
                          />
                        ) : (
                          ''
                        ) : ''}
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <RolesTab
                  PermissionShowList={PermissionShowList}
                  templateList={templateList}
                  PermissionEdit={PermissionEdit}
                  PermissionCreate={PermissionCreate}
                />
              </TabPanel>
              {role === 'Super Admin' ?
                <TabPanel>
                  <Row style={{padding:10}}>{`${today.getDate()}-${today.getMonth()}-${today.getFullYear()}`}</Row>
                  <Row className={cx('auto_refresh')}>
                    {columnsName?.map(item => {
                      return <AutoRefresh
                        data={dataAutoRefresh}
                        action={item}
                      />
                    })}
                  </Row>
                </TabPanel>
                : null
              }
            </Tabs>
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default TemplateRolePage;
