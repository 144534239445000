export const clientRelatedTypes = [
	'new-sales-client',
	'new-retention-client',
	'assign-sales-client',
	'assign-retention-client'
];

export const mapNotificationType = {
	'new-sales-client': 'New Sales Client',
	'new-retention-client': 'New Retention Client',
	'assign-sales-client': 'Assign Client Sales',
	'assign-retention-client': 'Assign Client Retention',
	'callback': "Callback",
	'withdrawal': "Withdrawal",
	'deposit': "Deposit",
	'client': "Client",
};