import { components } from "react-select"
import { colorOptions } from "./colorOptions";

export const SingleValue = (props) => {
    return <components.SingleValue {...props}>
        <div className='select-color-option select-color-option-value'>
            <span className='select-color-option-indicator' style={{ background: props.data.value }}/>
            {colorOptions[props.children] || props.children}
        </div>
    </components.SingleValue>
}