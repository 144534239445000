import React from 'react';
import classNames from 'classnames/bind';
import styles from './tableStyle.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';

import { TABLE_TRADING_ACC_COLUMNS_NAMES } from 'constants/table';
import moment from 'moment';
import { getShortUuid } from 'utils/getShortUuid';
import { copyToBuffer } from 'utils/copyToBuffer';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import Button from 'components/ui/Button/Button';

const cx = classNames.bind(styles);

const TradingAccTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  colSortCheck,
}) => {
  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_TRADING_ACC_COLUMNS_NAMES", TABLE_TRADING_ACC_COLUMNS_NAMES);

  // const columns = React.useMemo(() => TABLE_TRADING_ACC_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      ta_login,
      trading_account,
      client,
      name,
      date,
      credit,
      leverage,
      balance,
      amount_type,
      created_at,
      user,
      status,
      type,
      demo
    } = item;

    return {
      trading_account: (
        <div className={cx('tradingAcc-table_td')}>
          {
            <>
              <span className={cx('ta')}>
                {name ? (
                  <span>
                    {demo === 1 
                      ? <>
                          <span style={{ color: 'blue', fontWeight: 'bold'}}>DEMO</span>
                          {' '}
                        </>
                      : null
                    }
                    {name}<span className={cx(status)}>{status}</span>
                  </span>
                ) : (
                  '---'
                )}
              </span>
              {/*<span className="uuid" onClick={() => copyToBuffer(ta_login)}>*/}
              {/*  {ta_login ? ta_login : '---'}*/}
              {/*</span>*/}
            </>
          }
        </div>
      ),
      trading_login: (
        <div className={cx('tradingAcc-table_td')}>
          {
            <>
              <span className={cx('ta')} onClick={() => copyToBuffer(ta_login)}>
                {ta_login ? (
                  <span>
                    {ta_login}
                    {/*<span className={cx('ta_type', type.toLowerCase())}>{type}</span>*/}
                  </span>
                ) : (
                  '---'
                )}
              </span>
            </>
          }
        </div>
      ),
      user: (
        <div className={cx('tradingAcc-table_td')}>
          {
            <>
              <span style={{ display: 'block' }}>
                {user?.user_profile?.first_name} {user?.user_profile?.last_name}
              </span>
              <span className="uuid" onClick={() => copyToBuffer(user?.uuid)}>
                {getShortUuid(user?.uuid)}
              </span>
            </>
          }
        </div>
      ),
      // name: (
      //   <div className={cx('tradingAcc-table_td')}>
      //     <>
      //       <span>{name}</span>
      //     </>
      //   </div>
      // ),
      created_at: (
        <div className={cx('tradingAcc-table_td')}>
          <span style={{ display: 'block' }}>{created_at && moment(created_at * 1000).format('DD.MM.YYYY')}</span>
          <span>{created_at && moment(created_at * 1000).format('HH:mm')}</span>
        </div>
      ),
      credit: (
        <div className={cx('tradingAcc-table_td ')}>
          <span>
            {amount_type} {credit}
          </span>
        </div>
      ),
      leverage: (
        <div className={cx('tradingAcc-table_td ')}>
          <span>{leverage}</span>
        </div>
      ),
      balance: (
        <div className={cx('tradingAcc-table_td ')}>
          <span>
            {amount_type} {balance}
          </span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck}
      className={cx('tradingAcc-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
    />
    </>
  );
};

export default TradingAccTable;
