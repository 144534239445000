import React, { useEffect, useState } from 'react';
import { Col, Row }            from 'react-bootstrap';
import ItemLine                from './ItemLine';
import styles                  from './style.module.scss';
import { PermissionList, RAP_ActiveRole } from 'models/RolesAndPermissions/Permissions';

type Props = {
  listAction: PermissionList;
  activeRole: RAP_ActiveRole;
}

const RolesTable = (props: Props) => {
  const { listAction, activeRole } = props;

  const [keys, setKeys] = useState<string[]>([]);
  useEffect(() => {
    listAction && setKeys(Object.keys(listAction));
  }, [listAction]);
  return (
    <div className={styles.wrapperTable}>
      <Row className={styles.tableHeader}>
        <Col s={7} className={styles.leftBlock}>
          <span>Action</span>
        </Col>
        <Col s={5} className={styles.rightBlock}>
          <div className={styles.permBlock}>
            <div className={styles.topBlock}>
              <span>Permission</span>
            </div>
          </div>
        </Col>
      </Row>

      {keys?.map((item, ind) => {
        return (
          <Row key={ind} className={styles.tableBody}>
            <ItemLine item={item} listAction={listAction} activeRole={activeRole} />
          </Row>
        );
      })}
    </div>
  );
};
export default RolesTable;
