import classNames                             from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE }            from 'constants/sales-rules.const';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage }                   from 'react-intl';
import { findTeamsMethod }                    from '../../../api/managements/teams/index';
import Button                                 from '../../../components/ui/Button/index.js';
import TeamsTable                             from '../../../components/ui/Table/TeamsTable/index.js';
import TablePagination                        from '../../../components/ui/TablePagination/index.js';
import { usePermission }   from '../../../utils/usePermission.tsx';
import Page                                   from '../../Page/index.js';
import { DashboardLayout }                    from '../../UserView/DashboardLayout';
import TeamsFilter                            from './Filter/index.js';

import styles           from './teams-page.module.scss';
import CreateTeamsPopup from './TeamsPopups/CreateTeamsPopup';
import { getTablePageParams } from '../../../utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { HierarchyTeam } from 'models/Hierarchy/HierarchyData.js';
import { ManagementTeamFilter } from 'models/Management/Teams/Filter.js';
import { useAppDispatch } from "../../../store";
import { toggleScrollToTop } from "../../../store/slice/tableSlice.ts";

const cx = classNames.bind(styles);

const TeamsPage = () => {

  const { permissionGiven: PermissionList } = usePermission('admin.team.index');
  const { permissionGiven: PermissionCreate } = usePermission('admin.team.create');

  const [teams, setTeams] = useState<HierarchyTeam[]>([]);
  const dispatch = useAppDispatch();

  const [isListLoading, setIsListLoading] = useState(true);
  const [filters, setFilters] = useState<ManagementTeamFilter>(null);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  document.title = 'Teams';

  const componentMounted = useRef(false);

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      getTeams();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [PermissionList]);

  const getTeams = (options?, data?) => {
    setIsListLoading(true);

    let postData;

    findTeamsMethod(getTablePageParams(options, perPageCount, tableLinks), data || postData || filters)
      .then((res) => {
        if (componentMounted.current) {
          setTeams(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>
                  <strong>{tableMeta?.total || 0}</strong>{' '}
                  <FormattedMessage id='management.teams.title' defaultMessage='Teams' />
                </span>
                <span></span>
              </div>
              <div className={cx('header-controls')}>
                {
                  PermissionCreate ?
                    <CreateTeamsPopup
                      updateComponent={() => getTeams()}
                      actionType={SALES_RULES_ACTION_TYPE.CREATE}
                      triggerBtn={
                        <Button buttonText='+ Add team' onClick={() => null} buttonType={'outline'} />
                      }
                    /> : null
                }
              </div>
            </div>
          </div>
          <div className={cx('container__filters-block')}>
            <TeamsFilter
              onSubmit={(data) => {
                setFilters(data);
                getTeams({ navigate: 'first', perPage: data?.filter?.limit }, data);
              }}
              reloadFilters={isListLoading}
              queryMessage={queryResponseMessage}
                          />
          </div>
          <div className={cx('container__block')}>
            {
              PermissionList ?
                <TeamsTable
                  data={teams}
                  perPage={teams?.length}
                  showLoader={isListLoading}
                  modifyDataHandler={(callback) => getTeams()}
                /> : null
            }
            {teams?.length ? (
              <TablePagination
                goToFirstPage={() => {
                  getTeams({ navigate: 'first' });
                  dispatch(toggleScrollToTop())
                }}
                goToPrevPage={() => {
                  getTeams({ navigate: 'prev' });
                  dispatch(toggleScrollToTop())
                }}
                goToNextPage={() => {
                  getTeams({ navigate: 'next' });
                  dispatch(toggleScrollToTop())
                }}
                goToLastPage={() => {
                  getTeams({ navigate: 'last' });
                  dispatch(toggleScrollToTop())
                }}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getTeams({ perPage: value });
                }}
                goToSelectedPage={(page) => {
                  getTeams({ page });
                }}
                // hidePageSelect={true}
                isFirstPageDisable={() => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default TeamsPage;
