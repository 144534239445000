import { OptionType } from "../components/ui/ModernDateRangeInput";

export enum AlertModalMode {
    CREATE, EDIT, REPUBLISH
}

export type Alert = {
    id: string,
    color: string,
    message: string,
    scrolling_speed: number,
    start_date: number,
    end_date: number,
    status: boolean,
    created_at: string,
    updated_at: string,
};

export type SystemMessageFormValues = {
    message: string,
    color: OptionType | null,
    scrolling_speed: OptionType | null,
    date: { from: Date | null, to: Date | null }
};

export type SystemMessageFormSubmitData = Omit<SystemMessageFormValues, 'date'> & {
    start_date: string;
    end_date: string;
    status?: boolean;
};
