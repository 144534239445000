import classNames from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import { TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES } from 'constants/table';
import { NotePencil, Trash } from 'phosphor-react';
import React from 'react';
import Toggle from 'react-toggle';
import { updateScheduleEvent } from '../../../../api/partners';
import { makeTableData } from '../../../../helpers/table.helper';
import DeletePartnerSchedulesPopup
  from '../../../../pages/Management/Partners/Partner/PartnerShedules/DeletePertnerSchedulesPopup';
import EditPartnerSchedulesPopup
  from '../../../../pages/Management/Partners/Partner/PartnerShedules/EditPartnerSchedulesPopup';
import { notify } from '../../../../utils/notify';
import { usePermission } from '../../../../utils/usePermission.tsx';
import Table from '../Table';
import styles from './style.module.scss';
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';

let days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
const cx = classNames.bind(styles);

const PartnerSchedulesTable = ({
  data,
  countryList,
  langList,
  operatorsList,
  partnersList,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  onSort,
}) => {
  const { permissionGiven: PermissionUpdate } = usePermission('admin.partner-schedule.update');
  const { permissionGiven: PermissionDelete } = usePermission('admin.partner-schedule.delete');

    const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES", TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES);
  // const columns = React.useMemo(() => TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES, []);
  const tableData = React.useMemo(
    () => makeTableData(data, data.length),
    [data, countryList, langList, operatorsList, partnersList],
  );
  const updateEvent = (id, item): void => {
    const sendData = {
      ...item,
      hours_from: getTimeSplit(item.from, 'hours'),
      minutes_from: getTimeSplit(item.from, 'minutes'),
      hours_to: getTimeSplit(item.to, 'hours'),
      minutes_to: getTimeSplit(item.to, 'minutes'),
      active: item.active === 1 ? 0 : 1,
    };
    updateScheduleEvent(id, sendData)
      .then((res): void => {
        if (res.data.data) {
          notify({
            type: 'success',
            message: 'Event activation changed',
            timeOut: 3000,
          });
          modifyDataHandler();
        }
      })
      .catch((error): void => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };
  const getTime = (time) => {
    let time_from_arr = time?.split(':');
    return time_from_arr && time_from_arr[0] + ':' + time_from_arr[1];
  };
  const getTimeSplit = (time, key) => {
    let time_from_arr = time?.split(':');

    return key === 'hours' ? time_from_arr[0] : time_from_arr[1];
  };
  const prepearedTableData = tableData.map((item, ind, pos) => {
    const { active, day, from, to, total_leads_limit, countries, id } = item;
    let time_from = getTime(from);
    let time_to = getTime(to);
    return {
      active: item.id ? (
        <div className={cx('filters-toggle')}>
          <label className="search-clients__toggle">
            {PermissionUpdate ?
              <Toggle
                defaultChecked={active === 1}
                icons={false}
                onChange={() => {
                  updateEvent(id, item);
                }}
              />
              : null
            }
          </label>
        </div>
      ) : (
        <></>
      ),
      day: (
        <div className={cx('rule__country')}>
          <span>{days[ind]}</span>
        </div>
      ),
      working_hours: (
        <div className={cx('rule__priority')}>
          <b>
            {time_from} - {time_to}
          </b>
        </div>
      ),
      total_leads_limit: (
        <div className={cx('rule__language')}>
          <span>{total_leads_limit}</span>
        </div>
      ),
      total_limit: (
        <div className={cx('rule__partner')}>
          {countries?.length ? <b>{countries?.length} Countries </b> : ''}
          {countries?.length ? (
            <div>
              {countries?.map((item) => {
                return (
                  <div key={item.id}>
                    <span>
                      {item.name}-{item.limit}
                    </span>
                  </div>
                );
              })}
            </div>
          ) : (
            <span className={cx('rule__operator-empty')}>&mdash;</span>
          )}
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          <div>
            {
              PermissionDelete ?
                <>
                  {item.id && (
                    <DeletePartnerSchedulesPopup
                      rule={item}
                      name={days[day - 1]}
                      triggerBtn={<Trash size={20} />}
                      // onCloseModal={() => console.log('a')}
                      updateComponent={modifyDataHandler}
                    />
                  )}
                </> : null
            }
          </div>
          <div>
            {
              PermissionUpdate ?
                <EditPartnerSchedulesPopup
                  countryList={countryList}
                  sourceArray={[]}
                  langList={langList}
                  operatorsList={operatorsList}
                  partnersList={partnersList}
                  ruleData={item}
                  day={ind + 1}
                  actionType={SALES_RULES_ACTION_TYPE.EDIT}
                  triggerBtn={<NotePencil size={20} />}
                  // onCloseModal={() => console.log('a')}
                  updateComponent={modifyDataHandler}
                /> : null
            }
          </div>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
    saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#f9f1ff' : '#ffffff'}`,
          },
        };
      }}
    />
    </>
  );
};

export default PartnerSchedulesTable;
