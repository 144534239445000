import classNames                             from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import { useForm }   from 'react-hook-form';
import { useParams } from 'react-router-dom';
import styles        from './../../client-page.module.scss';
import FeedFilter    from './FeedFilter';
import { notify } from '../../../../../utils/notify';
import { getFeedList, getLogActionList } from '../../../../../api/feed';
import FeedTable from '../../../../../components/ui/Table/FeedTable';
import TablePagination from '../../../../../components/ui/TablePagination';
import { TableLinks, TableMeta } from 'models/Table';

const cx = classNames.bind(styles);

const Feed = () => {
  const [feedsList, setFeedsList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [filters, setFilters] = useState<any>(null);

  const [actionTypesList, setActionTypesList] = useState([]);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta | undefined>(undefined);
  const [tableLinks, setTableLinks] = useState<TableLinks | undefined>(undefined);
  const [perPageCount, setPerPageCount] = useState(10);
  const { id } = useParams();

  const componentMounted = useRef(true);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    fetchFeedList();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }
    let str = `${toPage}&${perPageParams}`;

    if (pageParams?.from) {
      str = str + `&from=${pageParams.from}`;
    }
    if (pageParams?.to) {
      str = str + `&to=${pageParams.to}`;
    }
    if (pageParams.action_type) {
      str = str + `&action_type=${pageParams.action_type}`;
    }
    if (pageParams.query) {
      str = str + `&query=${pageParams.query}`;
    }
    return str;
  };

  const fetchFeedList = (pageParams?, filtersData?) => {
    setIsListLoading(true);
    const options = {
      ...pageParams,
      ...filtersData
    }

    getFeedList(getTablePageParams(options), id)
      .then((res) => {
        if (componentMounted.current) {
          setFeedsList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const clearFiltersSortList = () => {
    setPerPageCount(10);
    fetchFeedList({ navigate: 'first', perPage: 10 });
    setFilters(null);
  };

  const fetchLogactionList = () => {
    getLogActionList().then((res) => {
      if (res && res.status === 200) {
        var result = Object.keys(res.data)
          .map((key) => res.data[key])
          .map((opt) => ({
            value: opt,
            label: opt,
          }));
        setActionTypesList(result);
      }
    });
  };

  useEffect(() => {
    fetchLogactionList();
  }, []);

  return (
    <div className={cx('tab-panel', 'callbacks')}>
      <div className={cx('tab-panel__content')}>
        <div className={cx('tab-panel__header')}>
          <div className={cx('tab-panel__title')}>Feed</div>
        </div>
        <div className={cx('container__block')}>
          <FeedFilter
            actionTypesList={actionTypesList}
            onSubmit={(filtersData) => {
              if (!filters && !filtersData) {
                return;
              }
              if (filters && !filtersData) {
                clearFiltersSortList();
              } else {
                setFilters(filtersData);
                // setPerPageCount(filtersData?.filter?.limit);
                setPerPageCount(val => val)
                fetchFeedList(
                  { navigate: 'first', perPage: filtersData?.filter?.limit || 10 },
                  filtersData
                );
              }
            }}
            userId={id}
            reloadFilters={isListLoading}
            queryMessage={queryResponseMessage}
                      />
        </div>
        <div className={cx('tab-panel-table')}>
          <FeedTable
            data={feedsList}
            perPage={perPageCount}
            showLoader={isListLoading}
            userId={id}
          />
          {feedsList?.length ? (
            <TablePagination
              hidePageSelect={true}
              goToFirstPage={() => {
                fetchFeedList({ navigate: 'first', ...filters });
              }}
              goToPrevPage={() => {
                fetchFeedList({ navigate: 'prev', ...filters });
              }}
              goToNextPage={() => {
                fetchFeedList({ navigate: 'next', ...filters });
              }}
              goToLastPage={() => {
                fetchFeedList({ navigate: 'last', ...filters });
              }}
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                fetchFeedList({ perPage: value });
              }}
              goToSelectedPage={(page) => {
                fetchFeedList({ page });
              }}
              isFirstPageDisable={() => tableMeta?.current_page === 1}
              isPrevPageDisable={() => !tableLinks?.prev}
              isNextPageDisable={() => !tableLinks?.next}
              isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Feed;
