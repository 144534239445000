import classNames                             from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
// import { useParams }                          from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { getRequestListMethod }               from '../../../api/customer-service/request/index';
import styles                from './crypto-payments-page.module.scss';
import CryptoPaymentsFilters from './CryptoPaymentsFilters/CryptoPaymentsFilters';
import { DashboardLayout } from "../../UserView/DashboardLayout";
import Page from "../../Page/index.js";
import CryptoRequestTable from "../../../components/ui/Table/CryptoRequestTable/index.js";
import TablePagination from "../../../components/ui/TablePagination/index.js";
import { CryptoWithdrawFilter } from 'models/CustomerService/CryptoWithdrawFilter';
import { TableLinks, TableMeta } from 'models/Table';
import { CryptoWithdrawRequest } from 'models/CustomerService/CryptoWithdraw';
import { toggleScrollToTop } from "../../../store/slice/tableSlice.ts";
import { useAppDispatch } from "../../../store";
import { useCheckAutoRefresh } from "../../../utils/useCheckAutoRefresh.tsx";

const cx = classNames.bind(styles);

const CryptoOutputPage = (props) => {

  // const params = useParams();

  const dispatch = useAppDispatch();

  const [requestList, setRequestList] = useState<CryptoWithdrawRequest[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState<CryptoWithdrawFilter>();

  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(15);
  const checkAutoRefresh = useCheckAutoRefresh('Page Crypto Withdraw')

  // const [clientsList, setClientsList] = useState([]);

  const title = 'Crypto Withdraw';

  const componentMounted = useRef(true);

  useEffect(() => {
    getRequests();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const getRequests = (options?, filter?) => {
    setIsLoading(true);

    getRequestListMethod(getTablePageParams(options, filter))
      .then((res) => {
        if (componentMounted.current) {
          setRequestList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
        console.log(err);
      });
  };

  const getTablePageParams = (pageParams, filter) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    const params = `${toPage}&${perPageParams}`;

    if (filter) {
      let withFilters = `?${params}`;
      if (filter['status']) {
        withFilters = `${withFilters}&status=${filter['status']}`;
      }
      return withFilters;
    }

    return `?${params}`;
  };

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>
                  <strong>{tableMeta?.total || 0}</strong> Crypto Withdraw
                </span>
                <span></span>
              </div>
            </div>
          </div>

          <div className={cx('container__block')}>
            <CryptoPaymentsFilters
              onSubmit={(data) => {
                if (!filters && !data) {
                  return;
                }

                if (filters && !data) {
                  getRequests({ navigate: 'first' });
                  setFilters(null);
                } else {
                  setFilters(data);
                  getRequests({ navigate: 'first' }, data);
                }
              }}
              reloadFilters={isLoading}
            />
          </div>
          <div className={cx('container__block')}>
            <CryptoRequestTable
              data={requestList}
              perPage={perPageCount}
              showLoader={isLoading}
              clientsList={[]}
              modifyDataHandler={(callback) => getRequests()}
            />
            {requestList?.length ? (
              <TablePagination
                goToFirstPage={() => {
                  getRequests({ navigate: 'first' }, filters);
                  dispatch(toggleScrollToTop())
                }}
                goToPrevPage={() => {
                  getRequests({ navigate: 'prev' }, filters);
                  dispatch(toggleScrollToTop())
                }}
                goToNextPage={() => {
                  getRequests({ navigate: 'next' }, filters);
                  dispatch(toggleScrollToTop())
                }}
                goToLastPage={() => {
                  getRequests({ navigate: 'last' }, filters);
                  dispatch(toggleScrollToTop())
                }}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getRequests({ perPage: value }, filters);
                }}
                goToSelectedPage={(page) => {
                  getRequests({ page }, filters);
                }}
                hidePageSelect={false}
                isFirstPageDisable={() => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default CryptoOutputPage;
