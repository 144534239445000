import React, { useEffect, useRef, useState } from 'react';
import Popup from 'reactjs-popup';
import { useFieldArray, useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './styles-popup.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
import { notify } from 'utils/notify';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import { editTemplateRoleMethod, getCurrentRoleMethod } from '../../../api/templateRoles';
import PaginateSelect from '../../../components/ui/PaginateSelect';
import { fetchTemplates } from '../../../utils/managment/fetchData';
import { TemplateRole } from 'models/RolesAndPermissions/Templates/Role';
import { TemplateListEntry } from 'models/RolesAndPermissions/Templates/Template';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  actionType: string;
  dataItem: TemplateRole;
  templateList: TemplateListEntry[];
}

const EditTemplateRolePopup = ({
  triggerBtn,
  updateComponent,
  actionType,
  dataItem,
  templateList,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const contentToScrol = useRef(null);
  const [ruleData, setRuleData] = useState<TemplateRole>();
  
  const [listTemplate, setListTemplate] = useState([]);
  useEffect(() => {
    const opt = templateList.map((opt) => ({
      value: opt.id,
      label: opt.name,
    }));
    setListTemplate([...opt]);
  }, [templateList]);
  const setDefaultValues = () => {
    if (ruleData?.template) {
      return {
        template_ids: {
          value: ruleData?.template.id,
          label: ruleData?.template.name,
        },
      };
    }
    return {
      template_ids: null,
    };
  };
  const {
    handleSubmit,
    getValues,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: setDefaultValues(),
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };
  const handleOpenModal = () => {
    getCurrentRoleMethod(dataItem.id).then((res) => {
      if (res.status === 200) {
        setRuleData(res.data.data);
      }
    });
  };
  useEffect(() => {
    ruleData && reset(setDefaultValues());
  }, [ruleData]);

  const onSubmit = (close, data) => {
    const sendData = {
      role_template_id: getValue(data['template_ids']),
    };

    if (actionType === SALES_RULES_ACTION_TYPE.EDIT) {
      editRuleHandler(sendData, close);
    }
  };

  const editRuleHandler = (sendData, close) => {
    setIsLoading(true);

    editTemplateRoleMethod(ruleData.id, sendData)
      .then((res) => {
        if (res.data.data) {
          notify({
            type: 'success',
            message: "Role's template edited successfully",
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
          closeModal(close);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };
  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
        onOpen={(op) => {
          handleOpenModal();
        }}
      >
        {(close) => (
          <PopupTemplate
            isBigPopup={false}
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={"Change role's template"}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')} ref={contentToScrol}>
                  <h3 className={cx('attention_text')}>
                    Attention!
                    <br /> Changing the template will change the permissions of all users of that
                    role.
                  </h3>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Tabs className={cx('tabs')}>
                      <TabPanel>
                        <Container>
                          <Row>
                            <Col md={12}>
                              <PaginateSelect
                                isRequired={true}
                                isSearchable={false}
                                isMulti={false}
                                label="Template"
                                control={control}
                                id="template"
                                name="template_ids"
                                onChange={(page) => fetchTemplates(page)}
                                errors={errors.template_ids}
                                rules={{
                                  required: 'Template field is required',
                                }}
                              />
                            </Col>
                          </Row>
                        </Container>
                        <div className={cx('content-controls')}>
                          <Container>
                            <Row>
                              <Col md={12}>
                                <div className={cx('controls__buttons')}>
                                  <Button
                                    buttonText="Cancel"
                                    buttonType="outline"
                                    size="big"
                                    type="button"
                                    onClick={closeModal.bind(undefined, close)}
                                  />
                                  <LoaderButton
                                    buttonType="primary"
                                    size="big"
                                    showSpinner={isLoading}
                                    // disabled={isLoading || !isDirty}
                                    buttonText={'Change'}
                                    type="submit"
                                    onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </Container>
                        </div>
                      </TabPanel>
                      <TabPanel></TabPanel>
                    </Tabs>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default EditTemplateRolePopup;
