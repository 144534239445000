import API from '../api';

export const uploadDocument = (data, req?) => (
  API.post(`/api/v1/document`, data)
);

export const documentTypes = () => API.get('/api/v1/document/types');

export const getDocumentByUser = (params, userId) => API.get(`/api/v1/document/${userId}/list?${params}`);

export const downloadDocument = (docId, userId) => API.get(`/api/v1/download/${docId}?user_id=${userId}`, {
  headers: {
    Accept: '*/*',
  },
  responseType: 'blob',
});

export const removeDocumentRequest = (docId, userId) => API.delete(`/api/v1/document/${docId}?user_id=${userId}`);

export const fetchDownloadFileRequest = (docId, userId, fileName): void => {
  downloadDocument(docId, userId)
    .then(resp => resp.data)
    .then(blob => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // для IE
        window.navigator.msSaveOrOpenBlob(blob, 'download.xlsx');
      } else {
        const url: string = window.URL.createObjectURL(blob);
        const a: HTMLAnchorElement = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = fileName;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      }
    });
};

export const updateStatusFile = (fileId, fileData) => API.put(`/api/v1/document/${fileId}`, fileData);

export const findDocumentMethod = (params, data) => (
  API.post(`/api/v1/search/documents?${params}`, data)
);
