import React, { memo } from 'react';
import moment from 'moment/moment';
import classNames from 'classnames/bind';
import styles from '../client-page.module.scss';
import { Client } from 'models/Clients/Clients';

const cx = classNames.bind(styles);

type Props = {
  clientData: Client;
}

const LastLogin = memo<Props>(({ clientData }) => {
  return (
    <>
      <div className={cx('client-info__col-title')}>Last login</div>
      <div className={cx('client-info__col-content')}>
        <div className={cx('strong')}>
          {clientData?.visits?.length && clientData?.visits[0]?.last_visit
            ? moment(clientData?.visits[0]?.last_visit * 1000).fromNow()
            : '---'}
        </div>
        <div>
          {clientData?.visits?.length && clientData?.visits[0]?.last_visit
            ? moment(clientData?.visits[0]?.last_visit * 1000).format('DD.MM.YYYY')
            : '---'}
        </div>
        <div>
          from{' '}
          {clientData?.visits?.length && clientData?.visits[0]?.country
            ? clientData?.visits[0]?.country.name
            : '---'}
        </div>
      </div>
    </>
  );
});

export default LastLogin;
