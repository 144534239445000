const OperatorIcon = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6.25C15.1675 6.25 11.25 10.1675 11.25 15C11.25 19.8325 15.1675 23.75 20 23.75C24.8325 23.75 28.75 19.8325 28.75 15C28.75 10.1675 24.8325 6.25 20 6.25ZM8.75 15C8.75 8.7868 13.7868 3.75 20 3.75C26.2132 3.75 31.25 8.7868 31.25 15C31.25 21.2132 26.2132 26.25 20 26.25C13.7868 26.25 8.75 21.2132 8.75 15Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 26.2487C17.1473 26.2487 14.3448 26.9996 11.8744 28.4261C9.40391 29.8526 7.3525 31.9043 5.92637 34.375C5.58124 34.9729 4.81677 35.1778 4.21888 34.8326C3.62098 34.4875 3.41606 33.7231 3.76119 33.1252C5.40673 30.2744 7.77374 27.907 10.6243 26.2611C13.4748 24.6152 16.7084 23.7487 20 23.7487C23.2916 23.7487 26.5252 24.6152 29.3758 26.2611C32.2263 27.907 34.5933 30.2744 36.2389 33.1252C36.584 33.7231 36.3791 34.4875 35.7812 34.8326C35.1833 35.1778 34.4188 34.9729 34.0737 34.375C32.6476 31.9043 30.5961 29.8526 28.1257 28.4261C25.6552 26.9996 22.8528 26.2487 20 26.2487Z"
        fill="#722ED1"
      />
    </svg>
  );
};

export default OperatorIcon;
