import classNames                                from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE }               from 'constants/sales-rules.const';
import { TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES } from 'constants/table';
import { NotePencil, Trash }                     from 'phosphor-react';
import React                                     from 'react';
import { copyToBuffer }                          from 'utils/copyToBuffer';
import { makeTableData }                         from '../../../../helpers/table.helper';
import CreateTeamsPopup                          from '../../../../pages/Management/Teams/TeamsPopups/CreateTeamsPopup';
import DeleteTeamsPopup                          from '../../../../pages/Management/Teams/TeamsPopups/DeleteTeamsPopup';
import { getShortUuid }                          from '../../../../utils/getShortUuid';
import { usePermission }                         from '../../../../utils/usePermission.tsx';
import Table                                     from '../Table';
import styles                                    from './offices-table.module.scss';
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';
import { ManagementTeamPopup } from './Popup.tsx';

const cx = classNames.bind(styles);

const TeamsTable = ({
  data,
  officeList,
  deskList,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  onSort,
}) => {

  const { permissionGiven: PermissionDelete } = usePermission('admin.team.delete');
  const { permissionGiven: PermissionUpdate } = usePermission('admin.team.update');

  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES", TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES, true);

  // const columns = React.useMemo(() => TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES, []);
  const tableData = React.useMemo(
    () => makeTableData(data, data.length),
    [data, deskList, officeList],
  );
  const prepearedTableData = tableData.map((item, pos) => {
    const { id, name, uuid, desk } = item;
    return {
      team: (
        <div className={cx('rule__name')}>
          <ManagementTeamPopup 
            triggerElement={<span style={{ cursor: 'pointer '}}>{name}</span>} 
            teamID={id}
            teamName={name}
          />
          <span
            className={cx('rule__id')}
            onClick={(): void => {
              copyToBuffer(uuid);
            }}
          >
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      office: (
        <div className={cx('rule__name')}>
          {desk ? (
            <>
              <span>{desk?.office?.name}</span>
              <span
                className={cx('rule__id')}
                onClick={(): void => {
                  copyToBuffer(desk?.office?.uuid);
                }}
              >
                {getShortUuid(desk?.office?.uuid)}
              </span>
            </>
          ) : (
            <span>&mdash;</span>
          )}
        </div>
      ),
      desk: (
        <div className={cx('rule__name')}>
          {desk ? (
            <>
              <span>{desk?.name}</span>
              <span
                className={cx('rule__id')}
                onClick={(): void => {
                  copyToBuffer(desk?.uuid);
                }}
              >
                {getShortUuid(desk?.uuid)}
              </span>
            </>
          ) : (
            <span>&mdash;</span>
          )}
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          <div>
            {
              PermissionDelete ?
                <DeleteTeamsPopup
                  rule={item}
                  triggerBtn={<Trash size={20} />}
                  updateComponent={modifyDataHandler}
                /> : null
            }
          </div>
          <div>
            {
              PermissionUpdate ?
                <CreateTeamsPopup
                  deskList={deskList}
                  officeList={officeList}
                  ruleData={item}
                  actionType={SALES_RULES_ACTION_TYPE.EDIT}
                  triggerBtn={<NotePencil size={20} />}
                  // onCloseModal={() => console.log('a')}
                  updateComponent={modifyDataHandler}
                /> : null
            }
          </div>
        </div>
      ),
    };
  });
  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#f9f1ff' : '#ffffff'}`,
          },
        };
      }}
    />
    </>
  );
};

export default TeamsTable;
