import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getUserRemindersList } from "../../../api/callbaks";
import { CallbackEditData } from "../../../models/Clients/ClientCallback";

export interface RemindersSliceState {
  callbacks: Pick<CallbackEditData, 'id' | 'reminders' | 'status' | 'client'>[];
  isLoading: boolean;
}

const initialState: RemindersSliceState = {
  callbacks: [],
  isLoading: false,
}

export const fetchCallbacksToPopulateReminders = createAsyncThunk(
  'fetchCallbacksToPopulateReminders',
  async (data, thunkAPI) => {
    const userId = localStorage.getItem('user_id');
    const response = await getUserRemindersList(userId);

    return response.data.data.map((callback: CallbackEditData) => ({
      id: callback.id,
      time: callback.time,
      reminders: callback.reminders,
      status: callback.status,
      client: callback.client,
    }));
  },
);

export const remindersSlice = createSlice({
  name: 'reminders',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchCallbacksToPopulateReminders.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchCallbacksToPopulateReminders.fulfilled, (state, action) => {
        state.isLoading = false;
        state.callbacks = action.payload;
      })
      .addCase(fetchCallbacksToPopulateReminders.rejected, (state, action) => {
        state.isLoading = false;
      })
  }
});

export const {reducer} = remindersSlice;
