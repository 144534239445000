import { Bell, BellSlash } from "phosphor-react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { MenuOption } from "./MenuOption";
import { muteThread, selectMutedThreads, unmuteThread } from "../../../../store/slice/chat/chatSlice";

type MuteNotificationsOptionProps = {
    threadId: string;
};

export const MuteNotificationsOption = ({ threadId }: MuteNotificationsOptionProps) => {
    const dispatch = useAppDispatch();
    const mutedThreads = useAppSelector(selectMutedThreads);
    const isMuted = mutedThreads[threadId];
    const Icon = isMuted ? BellSlash : Bell;

    const handleMuteNotifications = () => {
        const action = isMuted ? unmuteThread : muteThread;
        dispatch(action(threadId));
        localStorage.setItem('chat-muted-threads', JSON.stringify({ ...mutedThreads, [threadId]: !isMuted }));
    };

    return (
        <MenuOption title={`${isMuted ? 'Unmute' : 'Mute'} notifications`} onClick={handleMuteNotifications}>
            <Icon size={32} color="#722ED1"/>
        </MenuOption>
    )
}