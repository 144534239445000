import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import styles from './style.module.scss';
import { notify } from 'utils/notify';
import {
  approveRequestMethod,
  getCryptoExchangeRate,
  rejectRequestMethod,
} from '../../../../api/customer-service/request';
import ApproveRequestPopup from './ApproveRequestPopup';
import { usePermission } from "../../../../utils/usePermission";
import { CryptoWithdrawWalletData } from 'models/CustomerService/CryptoWithdraw';

const cx = classNames.bind(styles);

type Props = {
  formData: CryptoWithdrawWalletData;
  updateComponent: () => void;
}

const CryptoRequestForm = (props: Props) => {
  const { formData, updateComponent } = props

  const { permissionGiven: PermissionApproveWithdrawalRequest } = usePermission('admin.withdrawal.approve');
  const { permissionGiven: PermissionRejectWithdrawalRequest } = usePermission('admin.withdrawal.reject');

  const [isLoading, setIsLoading] = useState(false);
  const {
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const onApprove = () => {
    const sendData = {
      accept_exchange_rate: getValues('accept_exchange_rate'),
      accept_amount: getValues('accept_amount'),
    };
    approveRequestMethod(formData.id, sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Request approved successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };
  const onSubmit = (data) => {
    setIsLoading(true);
    onApprove();
  };
  const countAcceptAmount = () => {
    let val = getValues('accept_exchange_rate');
    let count = formData.amount_usd / val;
    reset({ accept_amount: count, accept_exchange_rate: val });
  };

  // const countRate = () => {
  //   let val = getValues('accept_amount');
  //   let count = formData.amount_usd / val;
  //   reset({ accept_exchange_rate: count, accept_amount: val });
  // };
  const onReject = () => {
    rejectRequestMethod(formData.id)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Request rejected successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let crypto_type = formData.crypto_type_full.toLowerCase();
    getCryptoExchangeRate(crypto_type)
      .then((res) => {
        if (res.status === 200) {
          reset({
            accept_exchange_rate: res.data.data.rateUsd,
          });
          countAcceptAmount();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData]);
  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)} noValidate={true}>
      <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        {/*<Row className={cx('form__line ')}>*/}
        {/*  <Col md={6} lg={3} xs={12}>*/}
        {/*    <FormInput*/}
        {/*      name="accept_amount"*/}
        {/*      type="number"*/}
        {/*      label="Accept amount"*/}
        {/*      control={control}*/}
        {/*      errors={errors.accept_amount}*/}
        {/*      onSelectChange={() => {*/}
        {/*        countRate();*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*  <Col md={6} lg={3} xs={12}>*/}
        {/*    <FormInput*/}
        {/*      name="accept_exchange_rate"*/}
        {/*      type="number"*/}
        {/*      label="Accept exchange rate"*/}
        {/*      control={control}*/}
        {/*      errors={errors.accept_exchange_rate}*/}
        {/*      onSelectChange={() => {*/}
        {/*        countAcceptAmount();*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </Col>*/}
        {/*</Row>*/}
      </div>
      <div className={cx('button_wrapper')}>
        {PermissionRejectWithdrawalRequest ?
          <ApproveRequestPopup
            currentMethod={() => onReject()}
            data={formData}
            headerTitle={'Reject request'}
            textQuery={`Are you sure, you want to reject request ?`}
            btnText={'Reject'}
            triggerBtn={
              <Button
                type={'button'}
                buttonText="Reject request"
                buttonType="outline"
                onClick={() => {
                  // onReject();
                }}
              />
            }
          />
          : null
        }
        {PermissionApproveWithdrawalRequest ?
          <ApproveRequestPopup
            currentMethod={() => onApprove()}
            data={formData}
            headerTitle={'Approve request'}
            textQuery={`Are you sure, you want to approve request ?`}
            btnText={'Approve request'}
            triggerBtn={<Button type={'button'} buttonText="Approve request" buttonType="button" />}
          />
          : null
        }
      </div>
    </form>
  );
};

export default CryptoRequestForm;
