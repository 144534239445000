import * as React from 'react';

const QuestionSvg = (props) => (
  <svg
    width={25}
    height={25}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M9.575 9.38a1 1 0 1 0 1.886.664L9.575 9.38Zm5.312 1.081-1-.001v.001h1Zm-2.564 1.3a1 1 0 1 0 .632 1.897l-.632-1.897Zm.344 2.949a1 1 0 1 0 0 2v-2Zm.01 2a1 1 0 1 0 0-2v2Zm-8.032-.5.896-.445-.896.445Zm-.935 5-.923-.385a1 1 0 0 0 .923 1.384v-1Zm7.751-11.166c.098-.279.291-.513.546-.662l-1.014-1.725A3.248 3.248 0 0 0 9.575 9.38l1.886.664Zm.546-.662c.254-.15.553-.204.843-.155l.338-1.97a3.248 3.248 0 0 0-2.195.4l1.014 1.725Zm.843-.155c.29.05.554.201.744.427l1.53-1.288a3.248 3.248 0 0 0-1.936-1.11l-.338 1.971Zm.744.427c.19.225.294.51.293.806l2 .003a3.249 3.249 0 0 0-.763-2.097l-1.53 1.288Zm.293.807c0 .219-.177.52-.678.854a4.268 4.268 0 0 1-.88.444l-.009.003h.001l.001-.001.317.949.317.948h.002l.003-.002a.498.498 0 0 1 .01-.003l.028-.01.093-.034c.076-.03.18-.071.304-.126.246-.11.58-.276.922-.504.622-.414 1.57-1.237 1.57-2.518h-2Zm-1.22 6.248h.01v-2h-.01v2Zm8.042-4.5a8 8 0 0 1-8 8v2c5.523 0 10-4.477 10-10h-2Zm-16 0a8 8 0 0 1 8-8v-2c-5.522 0-10 4.478-10 10h2Zm8-8a8 8 0 0 1 8 8h2c0-5.522-4.477-10-10-10v2ZM5.542 15.765a7.963 7.963 0 0 1-.831-3.556h-2c0 1.596.374 3.106 1.04 4.446l1.79-.89Zm7.168 4.444H3.712v2H12.71v-2Zm-8.999 1 .924.385v-.002l.001-.003.004-.01.015-.035a15.403 15.403 0 0 0 .229-.594c.142-.385.326-.917.49-1.495a11.48 11.48 0 0 0 .383-1.824c.062-.559.077-1.278-.215-1.866l-1.791.89c-.001-.002.006.012.015.049.008.037.017.088.022.158.011.142.006.325-.018.549-.05.449-.17.974-.319 1.495a19.448 19.448 0 0 1-.66 1.912l-.003.006v.001l.923.384Z'
      fill='#131A29'
    />
  </svg>
);

export default QuestionSvg;
