import React, { FC, memo } from 'react'
import classNames from 'classnames/bind'
import styles from '../clients-table.module.scss'
import { copyToBuffer } from '../../../../../utils/copyToBuffer'

const cx = classNames.bind(styles)

interface IAffiliateProps {
  affiliate?: {
    first_name: string
    last_name: string
  }
  referral?: string
  source?: string
}

const Affiliate: FC<IAffiliateProps> = ({
  affiliate,
  referral,
  source
}) => {
  return (
    <div className={cx('client__affiler')}>
      {affiliate && (
        <span>
          {affiliate.first_name}{' '}
          {affiliate?.last_name ? affiliate.last_name : ''}
        </span>
      )}
      {affiliate && (
        <p
          className={cx('client__affiler-referrer')}
          onClick={(): void => {
            copyToBuffer(referral, true)
          }}
        >
          Referral: {referral || '---'}
        </p>
      )}
      {source && (
        <p
          className={cx('client__affiler-source')}
          onClick={(): void => {
            copyToBuffer(source, true)
          }}
        >
          Source: {source}
        </p>
      )}
      {!affiliate && !source && (
        <span className={cx('client__affiler-empty')}>None</span>
      )}
    </div>
  )
}

export default memo(Affiliate)
