import React, { useState, useEffect, useRef, useMemo } from 'react';
import classNames from 'classnames/bind';
import styles from './../../client-page.module.scss';
import { useParams } from 'react-router-dom';
import PaymentsFilter from './PaymentsFilter';
import AddTransactionPopup from './AddTransactionPopup';
import { usePermission } from '../../../../../utils/usePermission';
import PaymentsTable from '../../../../../components/ui/Table/PaymentsTable';
import { useSelector } from 'react-redux';
import { getEnableCCWithdraw, getEnableMT, getEnablePayments } from '../../../../../constants/selectors';
import Button from '../../../../../components/ui/Button';
import TablePagination from '../../../../../components/ui/TablePagination';
import { findPayments, getTransactionsTypesList } from '../../../../../api/payments';
import { TableLinks, TableMeta } from 'models/Table';
import { Sort } from 'models/Sort';
import { getClientPaymentsColumns } from '../../../../../constants/table.js';
import { useAppDispatch } from '../../../../../store';
import { toggleScrollToTop } from '../../../../../store/slice/tableSlice.ts';

const cx = classNames.bind(styles);

type Props = {
  updateComponent: () => void;
};

const Payments = (props: Props) => {
  const { updateComponent } = props;

  const { permissionGiven: PermissionCreateDepositTransaction } = usePermission('admin.deposit.transaction.create');
  
  const { permissionGiven: PermissionDepositWire } = usePermission('admin.deposit.transaction.create-deposit-wire');
  const { permissionGiven: PermissionDepositInt } = usePermission('admin.deposit.transaction.create-deposit-internal-transfer');
  const { permissionGiven: PermissionDepositCrypto } = usePermission('admin.deposit.transaction.create-deposit-crypto');
  const { permissionGiven: PermissionDepositCard } = usePermission('admin.deposit.transaction.create-deposit-credit-card');
  const { permissionGiven: PermissionDepositCredit } = usePermission('admin.deposit.transaction.create-deposit-credit');
  const { permissionGiven: PermissionDepositBonus } = usePermission('admin.deposit.transaction.create-deposit-bonus');
  
  const { permissionGiven: PermissionWithdrawWire } = usePermission('admin.deposit.transaction.create-withdraw-wire')
  const { permissionGiven: PermissionWithdrawInt } = usePermission('admin.deposit.transaction.create-withdraw-internal-transfer')
  const { permissionGiven: PermissionWithdrawCrypto } = usePermission('admin.deposit.transaction.create-withdraw-crypto')
  const { permissionGiven: PermissionWithdrawCard } = usePermission('admin.deposit.transaction.create-withdraw-credit-card')
  const { permissionGiven: PermissionWithdrawCredit } = usePermission('admin.deposit.transaction.create-withdraw-credit')
  const { permissionGiven: PermissionWithdrawBonus } = usePermission('admin.deposit.transaction.create-withdraw-bonus')
  
  const { permissionGiven: PermissionTransfer } = usePermission('admin.deposit.transfer-funds')
  
  const { permissionGiven: PermissionShowTransactionByAccount } = usePermission('admin.deposit.transaction.show-by-account');
  const { permissionGiven: PermissionListWithdrawalRequest } = usePermission('admin.withdrawal.index');

  const dispatch = useAppDispatch();

  const [paymentsList, setPaymentsList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [paymentsType, setPaymentsType] = useState([]);
  const [filters, setFilters] = useState(null);
  const [tableMeta, setTableMeta] = useState<TableMeta | undefined>(undefined);
  const [tableLinks, setTableLinks] = useState<TableLinks | undefined>(undefined);
  const [perPageCount, setPerPageCount] = useState(10);
  const [sort, setSort] = useState<Sort | undefined>(undefined);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const { id } = useParams();
  const ENABLE_MT = useSelector(getEnableMT);
  const ENABLE_PAYMENT = useSelector(getEnablePayments);
  const ENABLE_CC_WITHDRAW = useSelector(getEnableCCWithdraw);

  const tableHeader = useMemo(() => getClientPaymentsColumns(
    ENABLE_MT, 
    ENABLE_PAYMENT,
    ENABLE_CC_WITHDRAW
  ), [ENABLE_MT, ENABLE_PAYMENT, ENABLE_CC_WITHDRAW])

  const componentMounted = useRef(true);

  useEffect(() => {
    getPaymentsList();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getPaymentsList = (pageParams?, filtersData?) => {
    setIsListLoading(true);

    findPayments(
      getTablePageParams(pageParams),
      filtersData ? { filter: { user_id: [+id] }, ...filtersData } : { filter: { user_id: [+id] } },
    )
      .then((res) => {
        if (componentMounted.current) {
          setPaymentsList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    return `${toPage}&${perPageParams}`;
  };

  const getSortFiltersForPageNavigation = () => {
    if (sort && filters) {
      return {
        sort,
        ...filters,
      };
    }

    if (sort && !filters) {
      return {
        sort,
      };
    }

    if (!sort && filters) {
      return filters;
    }
  };

  const clearFiltersSortList = () => {
    setPerPageCount(10);
    getPaymentsList({ navigate: 'first', perPage: 10 });
    setFilters(null);
    // setSort(null);
  };

  const SORTED_FIELD_NAME = {
    amount: 'amount',
    trading_acc: 'trading_account',
    date: 'created_at',
  };

  const fetchPaymentsTypesList = () => {
    getTransactionsTypesList().then((res) => {
      if (res && res.status === 200) {
        const opt = res.data.map((opt) => ({
          value: opt,
          label: opt,
        }));
        setPaymentsType(opt);
      }
    });
  };

  const handleFilter = (filtersData) => {
    if (!filters && !filtersData) {
      return;
    }
    if (filters && !filtersData) {
      clearFiltersSortList();
    } else {
      setFilters(filtersData);
      setPerPageCount(filtersData?.filter?.limit);
      getPaymentsList(
        { navigate: 'first', perPage: filtersData?.filter?.limit || 10 },
        filtersData,
      );
    }
  };

  const handleSort = (col) => {
    const sorted = col.isSorted;
    const desc = col.isSortedDesc;

    if (!sorted) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: 'desc',
      };
      setSort(sort);
      getPaymentsList(
        { navigate: 'first' },
        {
          ...filters,
          sort,
        },
      );
      col.toggleSortBy(true);
    }

    if (sorted && desc) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: 'asc',
      };
      setSort(sort);
      getPaymentsList(
        { navigate: 'first' },
        {
          ...filters,
          sort,
        },
      );
      col.toggleSortBy(false);
    }

    if (sorted && !desc) {
      const data = {
        ...filters,
      };
      setSort(null);
      getPaymentsList({ navigate: 'first' }, data);
      col.clearSortBy();
    }
  };

  useEffect(() => {
    fetchPaymentsTypesList();
  }, []);

  return (
    <div className={cx('tab-panel', 'payments')}>
      <div className={cx('tab-panel__content')}>
        <div className={cx('tab-panel__header')}>
          <div className={cx('tab-panel__title')}>Payments</div>
          <div className={cx('tab-panel__action')}>
            {PermissionCreateDepositTransaction
              || PermissionDepositWire
              || PermissionDepositInt
              || PermissionDepositCrypto
              || PermissionDepositCard
              || PermissionDepositCredit
              || PermissionDepositBonus
              || PermissionWithdrawWire
              || PermissionWithdrawInt
              || PermissionWithdrawCrypto
              || PermissionWithdrawCard
              || PermissionWithdrawCredit
              || PermissionWithdrawBonus
              || PermissionTransfer
                ? (
                <AddTransactionPopup
                  paymentsTypeOpt={paymentsType}
                  updateComponent={() => {
                    updateComponent();
                    getPaymentsList();
                  }}
                  userId={id}
                  triggerBtn={
                    <Button buttonText="+ Add transaction" buttonType="outline" onClick={null} />
                  }
                />
            ) : null}
          </div>
        </div>
        <div className={cx('tab-panel__filter-box', 'block')}>
          <PaymentsFilter
            onSubmit={handleFilter}
            userId={id}
            reloadFilters={isListLoading}
            transactionTypeOpt={paymentsType}
            queryMessage={queryResponseMessage}
                      />
        </div>
        {PermissionShowTransactionByAccount || PermissionListWithdrawalRequest ? (
          <div className={cx('tab-panel-table')}>
            {tableHeader && (
              <PaymentsTable
                tableHeader={tableHeader}
                data={paymentsList}
                perPage={perPageCount}
                showLoader={isListLoading}
                isChangeOperator={true}
                // onRowSelect={getClientToBulkActions}
                manualSortHandler={handleSort}
                modifyDataHandler={(callback) => console.log('a')}
                updateComponent={() => getPaymentsList()}
              />
            )}

            {paymentsList?.length ? (
              <TablePagination
                goToFirstPage={() => {
                  getPaymentsList({ navigate: 'first' }, getSortFiltersForPageNavigation());
                  dispatch(toggleScrollToTop());
                }}
                goToPrevPage={() => {
                  getPaymentsList({ navigate: 'prev' }, getSortFiltersForPageNavigation());
                  dispatch(toggleScrollToTop());
                }}
                goToNextPage={() => {
                  getPaymentsList({ navigate: 'next' }, getSortFiltersForPageNavigation());
                  dispatch(toggleScrollToTop());
                }}
                goToLastPage={() => {
                  getPaymentsList({ navigate: 'last' }, getSortFiltersForPageNavigation());
                  dispatch(toggleScrollToTop());
                }}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getPaymentsList({ perPage: value }, getSortFiltersForPageNavigation());
                }}
                goToSelectedPage={(page) => {
                  getPaymentsList({ page }, getSortFiltersForPageNavigation());
                }}
                hidePageSelect={true}
                isFirstPageDisable={() => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : (
              ''
            )}
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Payments;
