import React from 'react';
import classNames from 'classnames/bind';
import styles from './tableStyle.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';

import { TABLE_KYC_DOCUMENTS_COLUMNS_NAMES } from 'constants/table';
import moment from 'moment';
import { copyToBuffer } from '../../../../utils/copyToBuffer';
import { getShortUuid } from '../../../../utils/getShortUuid';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import Button from 'components/ui/Button/Button';

const cx = classNames.bind(styles);

const KycDocumentsTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  colSortCheck,
}) => {
  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_KYC_DOCUMENTS_COLUMNS_NAMES", TABLE_KYC_DOCUMENTS_COLUMNS_NAMES);
  // const columns = React.useMemo(() => TABLE_KYC_DOCUMENTS_COLUMNS_NAMES, []);
  
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      id,
      user_name,
      file_name,
      created_at,
      expiration_at,
      kyc_category,
      kyc_type,
      user_uuid,
    } = item;

    return {
      user_name: (
        <div className={cx('rule__name')}>
          <span>{user_name}</span>
          <span
            className={cx('rule__id')}
            onClick={() => {
              copyToBuffer(user_uuid);
            }}
          >
            {getShortUuid(user_uuid)}
          </span>
        </div>
      ),
      file_name: (
        <div className={cx('kycDocuments-table_td')}>
          <span>{file_name}</span>
        </div>
      ),
      created_at: (
        <div className={cx('kycDocuments-table_td')}>
          <div className={cx('rule__name')}>
            <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
            <span>{moment.unix(created_at).format('HH:mm')}</span>
          </div>
        </div>
      ),
      kyc_category: (
        <div className={cx('kycDocuments-table_td')}>
          <span>{kyc_category?.translate}</span>
        </div>
      ),
      kyc_type: (
        <div className={cx('kycDocuments-table_td')}>
          <span>{kyc_type?.translate}</span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck}
      className={cx('kycDocuments-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
    />
    </>
  );
};

export default KycDocumentsTable;
