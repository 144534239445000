import moment from 'moment'

const getUTCtime = (typeDay, date) => {

  let utcDate = new Date(date)

  let yyyy = utcDate.getFullYear()
  let mm = ("0" + (utcDate.getUTCMonth() + 1)).slice(-2)
  let dd = ("0" + utcDate.getUTCDate()).slice(-2)
  let h = ("0" + utcDate.getUTCHours()).slice(-2)
  let m = ("0" + utcDate.getUTCMinutes()).slice(-2)
  let fullUTCDate

  //З часом
  if (typeDay === null) {
    fullUTCDate = `${yyyy}-${mm}-${dd}T${h}:${m}:00Z`
  }
  //Початок дня
  if (typeDay === 'startDay') {
    fullUTCDate = yyyy + '-' + mm + '-' + dd + 'T00:00:00Z'
  }
  //Кінець дня
  if (typeDay === 'endDay') {
    fullUTCDate = yyyy + '-' + mm + '-' + dd + 'T23:59:59Z'
  }
  return moment(fullUTCDate).valueOf()
}

export default getUTCtime