import { findPayments } from 'api/payments';
import classNames from 'classnames/bind';

import TablePagination from 'components/ui/TablePagination';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import { CaretDoubleDown, CaretDoubleUp } from 'phosphor-react';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { usePermission } from '../../utils/usePermission.tsx';
import styles from './page.module.scss';
import PaymentsFilter from './PaymentsFilter/PaymentsFilter';
import PaymentsTable from '../../components/ui/Table/PaymentsTable/index.js';
import { getPaymentsColumns } from '../../constants/table.js';
import { useSelector } from 'react-redux';
import { getEnableCCWithdraw, getEnableMT, getEnablePayments } from '../../constants/selectors';
import { TableLinks, TableMeta } from 'models/Table.js';
import { Sort } from 'models/Sort.js';
import { Payment } from 'models/Payments/Payment.js';
import { PaymentFilter } from 'models/Payments/Filter.js';
import { useAppDispatch } from '../../store';
import { toggleScrollToTop } from '../../store/slice/tableSlice.ts';
import { useCheckAutoRefresh } from '../../utils/useCheckAutoRefresh.tsx';

const cx = classNames.bind(styles);

const PaymentsPage = () => {

  const { permissionGiven: PermissionShowListTransaction } = usePermission('admin.deposit.transaction.index');

  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState(true);
  const [paymentsList, setPaymentsList] = useState<Payment[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<PaymentFilter>(null);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const [sort, setSort] = useState<Sort>();
  const ENABLE_MT = useSelector(getEnableMT);
  const ENABLE_PAYMENT = useSelector(getEnablePayments);
  const ENABLE_CC_WITHDRAW = useSelector(getEnableCCWithdraw);
  const checkAutoRefresh = useCheckAutoRefresh('Page Payments');

  const tableHeader = useMemo(() => getPaymentsColumns(
    ENABLE_MT, 
    ENABLE_PAYMENT,
    ENABLE_CC_WITHDRAW
  ), [ENABLE_MT, ENABLE_PAYMENT, ENABLE_CC_WITHDRAW]);

  const componentMounted = useRef(true);
  document.title = 'Payments';

  useEffect(() => {
    getPaymentsList();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getPaymentsList = (pageParams?, filtersData?) => {
    setIsListLoading(true);

    findPayments(getTablePageParams(pageParams), filtersData)
      .then((res) => {
        if (componentMounted.current) {
          setPaymentsList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  const getClientToBulkActions = (rows) => {
    const clients = [];
    rows.forEach((item) => {
      clients.push(paymentsList[item['index']]['id']);
    });
    setSelectedRows(clients);
  };

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    return `${toPage}&${perPageParams}`;
  };

  const getSortFiltersForPageNavigation = () => {
    if (sort && filters) {
      return {
        sort,
        ...filters,
      };
    }

    if (sort && !filters) {
      return {
        sort,
      };
    }

    if (!sort && filters) {
      return filters;
    }
  };

  const clearFiltersSortList = () => {
    setPerPageCount(10);
    getPaymentsList({ navigate: 'first', perPage: 10 });
    setFilters(null);
    // setSort(null);
  };

  const SORTED_FIELD_NAME = {
    original_agent: 'operator_full_name',
    country: 'country',
    amount: 'amount',
    trading_acc: 'trading_account',
    date: 'created_at',
  };

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header', selectedRows?.length ? 'padding' : '')}>
              <div className={cx('search-result')}>
                <span>
                  <strong>{PermissionShowListTransaction ? tableMeta?.total : null}</strong>{' '}
                  Payments found
                </span>
                <span></span>
              </div>
              <div className={cx('header-controls')}>
                <button className={cx('hide-btn')} onClick={() => setExpanded(!expanded)}>
                  {expanded && <CaretDoubleUp size={22} color="rgba(0, 0, 0, 0.1)" />}
                  {!expanded && <CaretDoubleDown size={22} color="rgba(0, 0, 0, 0.1)" />}
                </button>
              </div>
            </div>
          </div>
          <div className={cx('container__filters-block', !expanded && 'hide')}>
            <PaymentsFilter
              onSubmit={(filtersData) => {
                if (!filters && !filtersData) {
                  return;
                }
                if (filters && !filtersData) {
                  clearFiltersSortList();
                } else {
                  setFilters(filtersData);
                  getPaymentsList({ navigate: 'first', perPage: perPageCount }, filtersData);
                }
              }}
              reloadFilters={isListLoading}
              queryMessage={queryResponseMessage}
                          />
          </div>
          {PermissionShowListTransaction ? (
            <div className={cx('container__block')}>
              {tableHeader && (
                <PaymentsTable
                  tableHeader={tableHeader}
                  data={paymentsList}
                  perPage={perPageCount}
                  showLoader={isListLoading}
                  onRowSelect={getClientToBulkActions}
                  updateComponent={getPaymentsList}
                  manualSortHandler={(col) => {
                    const sorted = col.isSorted;
                    const desc = col.isSortedDesc;

                    if (!sorted) {
                      const sort = {
                        field: SORTED_FIELD_NAME[col.id],
                        direct: 'desc',
                      };
                      setSort(sort);
                      getPaymentsList(
                        { navigate: 'first' },
                        {
                          ...filters,
                          sort,
                        },
                      );
                      col.toggleSortBy(true);
                    }

                    if (sorted && desc) {
                      const sort = {
                        field: SORTED_FIELD_NAME[col.id],
                        direct: 'asc',
                      };
                      setSort(sort);
                      getPaymentsList(
                        { navigate: 'first' },
                        {
                          ...filters,
                          sort,
                        },
                      );
                      col.toggleSortBy(false);
                    }

                    if (sorted && !desc) {
                      const data = {
                        ...filters,
                      };
                      setSort(null);
                      getPaymentsList({ navigate: 'first' }, data);
                      col.clearSortBy();
                    }
                  }}
                  modifyDataHandler={(callback) => console.log('a')}
                />
              )}

              {paymentsList?.length ? (
                <TablePagination
                  goToFirstPage={() => {
                    getPaymentsList({ navigate: 'first' }, getSortFiltersForPageNavigation());
                    dispatch(toggleScrollToTop());
                  }}
                  goToPrevPage={() => {
                    getPaymentsList({ navigate: 'prev' }, getSortFiltersForPageNavigation());
                    dispatch(toggleScrollToTop());
                  }}
                  goToNextPage={() => {
                    getPaymentsList({ navigate: 'next' }, getSortFiltersForPageNavigation());
                    dispatch(toggleScrollToTop());
                  }}
                  goToLastPage={() => {
                    getPaymentsList({ navigate: 'last' }, getSortFiltersForPageNavigation());
                    dispatch(toggleScrollToTop());
                  }}
                  currentPage={tableMeta?.current_page}
                  pagesLength={tableMeta?.last_page}
                  perPageChange={(value) => {
                    setPerPageCount(value);
                    getPaymentsList({ perPage: value }, getSortFiltersForPageNavigation());
                  }}
                  goToSelectedPage={(page) => {
                    getPaymentsList({ page }, getSortFiltersForPageNavigation());
                  }}
                  // hidePageSelect={true}
                  isFirstPageDisable={() => tableMeta?.current_page === 1}
                  isPrevPageDisable={() => !tableLinks?.prev}
                  isNextPageDisable={() => !tableLinks?.next}
                  isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
                />
              ) : (
                ''
              )}
            </div>
          ) : null}
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default PaymentsPage;
