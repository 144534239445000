import { deskList } from 'api/desk';
import { findNotificationsMethod, multyMarkAsRead } from 'api/notifications';
import { getSystemData } from 'api/system';
import { teamList } from 'api/team';
import classNames from 'classnames/bind';
import NotificationsTable from 'components/ui/Table/NotificationsTable';
import TablePagination from 'components/ui/TablePagination';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import { useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { notify } from 'utils/notify';
import { operatorsList } from '../../api/operators/index';
import { usePermission } from '../../utils/usePermission.tsx';
import NotificationsFilter from './NotificationsFilter/NotificationsFilter';
import styles from './page-style.module.scss';
import { getTablePageParams } from '../../utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { Notification } from 'models/Notifications/Notification.js';
import { NotificationsFilter as FilterType } from 'models/Notifications/NotificationsFilter.js';
import { ListValue } from 'models/ListValue';
import { useAppDispatch } from '../../store';
import { toggleScrollToTop } from '../../store/slice/tableSlice.ts';
import { useCheckAutoRefresh } from '../../utils/useCheckAutoRefresh.tsx';
import { mapNotificationType } from "../../constants/notifications";

const cx = classNames.bind(styles);

const NotificationsPage = () => {

  const { permissionGiven: PermissionList } = usePermission('admin.notifications.view');

  const dispatch = useAppDispatch();

  const [expanded, setExpanded] = useState(true);
  const [notificationsList, setNotificationsList] = useState<Notification[]>([]);

  const [isListLoading, setIsListLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<FilterType>(null);

  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [desksOpt, setDesksOpt] = useState<ListValue[]>([]);
  const [teamsOpt, setTeamsOpt] = useState<ListValue[]>([]);
  const [agentsOpt, setAgentsOpt] = useState<ListValue[]>([]);
  const [notificationTypesOpt, setNotificationTypesOpt] = useState<ListValue[]>([]);
  const [notificationTypesDetailsOpt, setNotificationTypesDetailsOpt] = useState<ListValue[]>([]);

  const { id } = useParams();
  const checkAutoRefresh = useCheckAutoRefresh('Page Notifications');
  document.title = 'Notifications';

  const componentMounted = useRef(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const markAsReadHandle = () => {
    const ids = selectedRows.map((row) => notificationsList[row.index].id);
    multyMarkAsRead(ids);
    //@ts-ignore
    fetchNotificationsList({ navigate: 'first', perPage: filters?.filter?.limit }, filters);
  };

  const selectRowHandle = (row) => {
    setSelectedRows(row);
  };

  const fetchDesksOpt = () => {
    deskList().then((res) => {
      if (res.status === 200) {
        setDesksOpt(
          res.data.data.map((item) => ({
            value: item.id,
            label: item.name,
          })),
        );
      }
    });
    // .catch((error) => {
    //   if (error && error.response.data !== null) {
    //     notify({
    //       type: 'error',
    //       message: error.response,
    //       timeOut: 3000,
    //     });
    //   }
    // });
  };
  const fetchTeamsOpt = () => {
    teamList()
      .then((res) => {
        if (res.status === 200) {
          setTeamsOpt(
            res.data.data.map((item) => ({
              value: item.id,
              label: item.name,
            })),
          );
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };
  const fetchAgentsOpt = () => {
    operatorsList('per_page=1000')
      .then((res) => {
        if (res.status === 200) {
          setAgentsOpt(
            res.data.data.map((item) => ({
              value: item.id,
              label: `${item.first_name} ${item && item.last_name !== null ? item.last_name : ''}`,
            })),
          );
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };
  const fetchNotyficationsTypesOpt = () => {
    getSystemData()
      .then((res) => {
        if (res.status === 200) {
          setNotificationTypesOpt(
            res.data['notification_types'].map((item) => ({
              value: item,
              label: mapNotificationType[item] || item,
            })),
          );
          setNotificationTypesDetailsOpt(
            res.data['notification_details'].map((item) => ({
              value: item,
              label: item,
            })),
          );
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  const fetchNotificationsList = (options?, filtersData?) => {
    setIsListLoading(true);
    findNotificationsMethod(getTablePageParams(options, perPageCount, tableLinks), filtersData)
      .then((res) => {
        if (res.status === 200) {
          setNotificationsList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setIsListLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsListLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      fetchNotificationsList();
    }

    return () => {
      componentMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PermissionList]);

  const getNotificationsList = (options, data) => {
    setIsListLoading(true);

    findNotificationsMethod(getTablePageParams(options, perPageCount, tableLinks), data)
      .then((res) => {
        if (componentMounted.current) {
          setNotificationsList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  useEffect(() => {
    if (PermissionList) {
      fetchDesksOpt();
      fetchTeamsOpt();
      fetchAgentsOpt();
      fetchNotyficationsTypesOpt();
    }
  }, [PermissionList]);

  return (
    <>
      <DashboardLayout>
        <Page>
          <div className={cx('container')}>
            <div className={cx('container__header')}>
              <Row>
                <div className={cx('header', selectedRows?.length ? 'padding' : '')}>
                  <div className={cx('search-result')}>
                    <span>
                      <strong>{tableMeta?.total}</strong> Notifications found
                    </span>
                    <span></span>
                  </div>
                </div>
              </Row>
            </div>
            <div className={cx('container__filters-block', !expanded && 'hide')}>
              <NotificationsFilter
                desksOptions={desksOpt}
                teamsOptions={teamsOpt}
                agentsOptions={agentsOpt}
                notificationsTypesOptions={notificationTypesOpt}
                notificationsDetailsTypesOptions={notificationTypesDetailsOpt}
                onSubmit={(filtersData) => {
                  if (!filters && !filtersData) {
                    return;
                  } else {
                    setFilters(filtersData);
                    setPerPageCount(filtersData?.filter?.limit);
                    getNotificationsList(
                      { navigate: 'first', perPage: filtersData?.filter?.limit || 10 },
                      filtersData,
                    );
                  }
                }}
                reloadFilters={isListLoading}
                queryMessage={queryResponseMessage}
              />
            </div>
            <div className={cx('container__block')}>
              {PermissionList ? (
                <NotificationsTable
                  data={notificationsList}
                  perPage={perPageCount}
                  showLoader={isListLoading}
                  onRowSelect={selectRowHandle}
                  markAsReadHandle={markAsReadHandle}
                  markAsReadDisableBtn={!selectedRows.length}
                  userId={id}
                />
              ) : null}
              {notificationsList?.length ? (
                <TablePagination
                  goToFirstPage={() => {
                    fetchNotificationsList({ navigate: 'first' }, filters);
                    dispatch(toggleScrollToTop());
                  }}
                  goToPrevPage={() => {
                    fetchNotificationsList({ navigate: 'prev' }, filters);
                    dispatch(toggleScrollToTop());
                  }}
                  goToNextPage={() => {
                    fetchNotificationsList({ navigate: 'next' }, filters);
                    dispatch(toggleScrollToTop());
                  }}
                  goToLastPage={() => {
                    fetchNotificationsList({ navigate: 'last' }, filters);
                    dispatch(toggleScrollToTop());
                  }}
                  currentPage={tableMeta?.current_page}
                  pagesLength={tableMeta?.last_page}
                  perPageChange={(value) => {
                    setPerPageCount(value);
                    fetchNotificationsList({ perPage: value }, filters);
                  }}
                  goToSelectedPage={(page) => {
                    fetchNotificationsList({ page }, filters);
                  }}
                  isFirstPageDisable={() => tableMeta?.current_page === 1}
                  isPrevPageDisable={() => !tableLinks?.prev}
                  isNextPageDisable={() => !tableLinks?.next}
                  isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </Page>
      </DashboardLayout>
    </>
  );
};

export default NotificationsPage;
