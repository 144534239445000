import React, { FC, memo } from 'react';
import classNames from 'classnames/bind';
import styles from '../clients-table.module.scss';
import { IRetentionProps } from '../../../../../models/components/Table/ClientsTable/Retention.ts';
import { PURPLE } from '../../../../../constants/colors.js';

const cx = classNames.bind(styles);

const Retention: FC<IRetentionProps> = ({ retention, status, color_status, type = '' }) => {
  const color = color_status === null ? PURPLE : color_status;

  return (
    <div className={cx('client__retention')}>
      {retention && type === 'retention' ? (
        // <div className={cx('client__sales-wrap', retention ? `sales-${status}` : '')}>
        <div style={{ border: `1px solid ${color}` }} className={cx('client__sales-wrap')}>
          <span
            // className={cx('client__sales-status', status)}
            style={{ color: color }}
            className={cx('client__sales-status')}
          >
            {status}
          </span>
          <span>{retention.operator?.full_name || ''}</span>
          <span>
            <strong>Desk:</strong> {retention.team?.desk?.name}
          </span>
          <span>
            <strong>Team:</strong> {retention.team?.name}
          </span>
        </div>
      ) : (
        <span className={cx('empty')}>None</span>
      )}
    </div>
  );
};

export default memo(Retention);
