import React, { FC, memo, useCallback } from 'react'
import classNames from 'classnames/bind'
import styles from '../clients-table.module.scss'
import moment from 'moment/moment'

const cx = classNames.bind(styles)

interface ILastActivity {
  activity: string;
  last_activity: number | null;
}

const LastActivity: FC<ILastActivity> = ({ activity, last_activity }) => {

  const checkActivity = useCallback((date): undefined | string => {
    if (!date) {
      return
    }

    const now: moment.Moment = moment()
    const days: number = now.diff(moment.unix(date), 'days')

    if (days) {
      return `${days} days ago`
    }

    const hours: number = now.diff(moment.unix(date), 'hours')

    if (hours) {
      return `${hours} hours ago`
    }

    const minutes: number = now.diff(moment.unix(date), 'minutes')

    if (minutes) {
      return `${minutes} minutes ago`
    }

    const sec: number = now.diff(moment.unix(date), 'seconds')
    return `${sec} seconds ago`

    // return `today at ${moment.unix(date).format('HH:mm')}`;
  }, [last_activity])

  return (
    <div className={cx('client__status')}>
      <span>{activity}</span>
      <span>{checkActivity(last_activity)}</span>
    </div>
  )
}

export default memo(LastActivity)
