import React, { useEffect, useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from '../crypto-transactions-page.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
// import FormInput from 'components/ui/FormInput';
import { notify } from 'utils/notify';
import Select from 'components/ui/Select';
import moment from 'moment';
import {
  changeCryptoTransactionStatus,
  changeWebHookStatus,
} from 'api/customer-service/crypto-payments';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import { getCryptoExchangeRate } from 'api/customer-service/request';
import { CryptoTransaction, NestedCryptoTransaction } from 'models/CustomerService/CryptoTransaction';
// import FormPriceInput from 'components/ui/FormPriceInput';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: (params?: any) => void;

  webHookId: number;

  info: NestedCryptoTransaction;
  transactionData: CryptoTransaction;

  cryptoFullType: string;
}

const CryptoTransactionsPopups = (props: Props) => {
  const {
    triggerBtn,
    updateComponent,
    info,
    webHookId,
    cryptoFullType,
    transactionData,
  } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [webHookStatus, setWebHookStatus] = useState(null);
  const [cryptoRate, setCryptoRate] = useState(null);
  const [usdAmount, setUsdAmount] = useState(null);

  const acceptedAmount = transactionData && transactionData.amount ? transactionData.amount : '0';

  const {
    handleSubmit,
    control,
    reset,
    // watch,
    getValues,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      deposit_transaction_id: undefined,
      web_hook_status: undefined,
      usd_accepted_amount: undefined,
      accept_crypto_rate: undefined,
    },
  });

  // const transactionStatus = watch('web_hook_status');

  const closeModal = (close) => {
    reset({
      deposit_transaction_id: undefined,
      web_hook_status: undefined,
      usd_accepted_amount: null,
      accept_crypto_rate: null,
    });
    close();
  };

  const status = [
    {
      value: 'accepted',
      label: 'Accepted',
    },
    {
      value: 'rejected',
      label: 'Rejected',
    },
    {
      value: 'new',
      label: 'New',
    },
    {
      value: 'internal',
      label: 'Internal',
    },
  ];

  let data;
  if (info) {
    data = {
      id: info?.deposit_transaction_id,
      user: {
        mail: info?.transaction?.user?.email,
        name: info?.transaction?.user?.user_profile?.first_name,
        lname: info?.transaction?.user?.user_profile?.last_name,
      },
      account: info?.transaction?.trading_account_id,
      amount: info?.transaction?.amount,
      amount_type: info?.transaction?.amount_type,
      amount_usd: info?.transaction?.amount_usd,
      type: info?.transaction?.type,
      crypto_amount: info?.crypto_amount,
      crypto_type: info?.crypto_type,
      created_at: info?.transaction?.created_at,
    };
  }

  const onSubmit = (close, formData) => {
    if (info) {
      const postData = {
        deposit_transaction_id: data.id,
        web_hook_status: formData['web_hook_status'].value,
        usd_accepted_amount: formData['usd_accepted_amount'],
        accept_crypto_rate: formData['accept_crypto_rate'],
      };
      changeWebHookAndInternalTransactionStatus(postData, close);
    } else {
      const postData = {
        web_hook_status: formData['web_hook_status'].value,
        usd_accepted_amount: formData['usd_accepted_amount'],
        accept_crypto_rate: formData['accept_crypto_rate'],
      };
      changeOnlyWebHookStatus(postData, close);
    }
  };

  const changeOnlyWebHookStatus = (postData, close) => {
    setIsLoading(true);

    changeWebHookStatus(webHookId, postData)
      .then(() => {
        updateComponent();
        setIsLoading(false);
        notify({
          type: 'success',
          message: 'Status changed success',
          timeOut: 3000,
        });
        closeModal(close);
      })
      .catch((err) => {
        setIsLoading(false);
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      });
  };

  const changeWebHookAndInternalTransactionStatus = (postData, close) => {
    setIsLoading(true);

    changeCryptoTransactionStatus(webHookId, postData)
      .then(() => {
        setIsLoading(false);
        updateComponent();
        notify({
          type: 'success',
          message: 'Status changed success',
          timeOut: 3000,
        });
        closeModal(close);
      })
      .catch((err) => {
        setIsLoading(false);
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      });
  };

  const fetchCryptoRate = () => {
    if (cryptoFullType) {
      getCryptoExchangeRate(cryptoFullType).then((res) => {
        if (res && res.status === 200) {
          setCryptoRate(res.data.data.rateUsd);
          setUsdAmount(parseFloat(res.data.data.rateUsd) * parseFloat(acceptedAmount));
        }
      });
    }
  };

  const matchCount = (type, value) => {
    if (type === 'rate') {
      setCryptoRate(value);
      setUsdAmount(value * parseFloat(acceptedAmount));
    }
    if (type === 'amount_usd') {
      setUsdAmount(value);
      setCryptoRate(value / parseFloat(acceptedAmount));
    }
  };

  useEffect(() => {
    if (webHookStatus?.value === 'accepted') {
      fetchCryptoRate();
    }
  }, [webHookStatus]);

  useEffect(() => {
    reset({
      ...getValues(),
      usd_accepted_amount: usdAmount,
      accept_crypto_rate: cryptoRate,
    });
  }, [cryptoRate, usdAmount]);

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            size="md"
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Change transaction status'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm', 'crypto-transaction')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12}>
                          {data ? (
                            <span className={cx('content-form__title')}>Internal transaction</span>
                          ) : (
                            <span className={cx('content-form__title')}>
                              Change crypto transaction status
                            </span>
                          )}
                        </Col>
                        {data && (
                          <Col md={12}>
                            <div className={cx('transaction__info', 'popup')}>
                              <span>
                                User:&nbsp;
                                <span>
                                  {data.user.name} {data.user.lname}
                                </span>
                              </span>
                              <span>
                                Account:&nbsp;{' '}
                                <span
                                  className={cx('copy_id')}
                                  onClick={() => {
                                    copyToBuffer(data.account);
                                  }}
                                >
                                  {data.account}
                                </span>
                              </span>

                              <span>
                                Transaction:&nbsp;{' '}
                                <span
                                  className={cx('copy_id')}
                                  onClick={() => {
                                    copyToBuffer(data.id);
                                  }}
                                >
                                  {getShortUuid(data.id)}
                                </span>
                              </span>
                              <span>
                                Type:&nbsp; <span className={cx(data.type)}>{data.type}</span>
                              </span>

                              <span>
                                Amount:&nbsp;
                                <span>
                                  {data.amount} {data.amount_type} {`=>`} {data.crypto_amount}{' '}
                                  {transactionData.crypto_type}
                                </span>
                              </span>
                              <span>
                                Amount USD:&nbsp;
                                <span>{`${data.amount_usd} USD`}</span>
                              </span>
                              <span>
                                Date:&nbsp;
                                <span>
                                  {moment.unix(data.created_at).format('DD.MM.YYYY HH:mm')}
                                </span>
                              </span>
                            </div>
                          </Col>
                        )}

                        <Col md={12}>
                          <Select
                            name="web_hook_status"
                            label="Status"
                            control={control}
                            isSearchable={false}
                            options={status}
                            errors={errors.web_hook_status}
                            handleChange={(value) => {
                              setWebHookStatus(value);
                            }}
                            rules={{
                              required: 'Field is required',
                            }}
                          />
                        </Col>
                        {/*{transactionStatus?.value === 'accepted' && info !== null ? (*/}
                        {/*  <>*/}
                        {/*    <Col md={12}>*/}
                        {/*      <FormPriceInput*/}
                        {/*        control={control}*/}
                        {/*        value={*/}
                        {/*          transactionData && transactionData.amount*/}
                        {/*            ? transactionData.amount*/}
                        {/*            : '0'*/}
                        {/*        }*/}
                        {/*        type="text"*/}
                        {/*        name="accepted_amount"*/}
                        {/*        label="Accepted amount"*/}
                        {/*        currencyType={cryptoType}*/}
                        {/*        disabled*/}
                        {/*        onChange={(val) => matchCount('amount_usd', val)}*/}
                        {/*      />*/}
                        {/*    </Col>*/}
                        {/*    <Col md={6}>*/}
                        {/*      <FormInput*/}
                        {/*        type="number"*/}
                        {/*        control={control}*/}
                        {/*        name="accept_crypto_rate"*/}
                        {/*        label="Rate"*/}
                        {/*        onChange={(val) => matchCount('rate', val)}*/}
                        {/*        value={cryptoRate}*/}
                        {/*        rules={{*/}
                        {/*          required: 'Field is required',*/}
                        {/*        }}*/}
                        {/*        errors={errors.accept_crypto_rate}*/}
                        {/*      />*/}
                        {/*    </Col>*/}
                        {/*    <Col md={6}>*/}
                        {/*      <FormPriceInput*/}
                        {/*        control={control}*/}
                        {/*        value={usdAmount}*/}
                        {/*        type="number"*/}
                        {/*        name="usd_accepted_amount"*/}
                        {/*        label="USD amount"*/}
                        {/*        currencyType="USD"*/}
                        {/*        onChange={(val) => matchCount('amount_usd', val)}*/}
                        {/*        rules={{*/}
                        {/*          required: 'Field is required',*/}
                        {/*        }}*/}
                        {/*        errors={errors.usd_accepted_amount}*/}
                        {/*      />*/}
                        {/*    </Col>*/}
                        {/*  </>*/}
                        {/*) : null}*/}
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText="Cancel"
                          buttonType="outline"
                          size="big"
                          type="button"
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <LoaderButton
                          buttonType="primary"
                          size="big"
                          showSpinner={isLoading}
                          // disabled={isLoading || !nameField}
                          buttonText={'Apply change'}
                          type="submit"
                          onClick={(data) => handleSubmit(onSubmit.bind(undefined, close))}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default CryptoTransactionsPopups;
