import React, { useEffect, useState, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './style.scss';
import NotificationsPopupFilter from './NotificationsPopupFilter';
import NotificationsPopupTable from '../Table/NotificationsPopupTable';
import { getSystemData } from 'api/system';
import { notify } from 'utils/notify';
import { findMyNotificationsMethod, markAsReadMethod, multyMarkAsRead } from 'api/notifications';
import TablePagination from 'components/ui/TablePagination/TablePagination';
import { getTablePageParams } from '../../../utils/getTablePageParams';
import { TableLinks, TableMeta } from '../../../models/Table';
import { IOptions } from '../../../models/components/Select/Select';
import { INotificationFilters } from '../../../models/components/NotificationPopup/NotificationPopup';
import { usePermission } from '../../../utils/usePermission';
import { useAppDispatch, useAppSelector } from '../../../store';
import { toggleScrollToTop } from '../../../store/slice/tableSlice';
import { mapNotificationType } from "../../../constants/notifications";
import {
  decreaseNotificationCount,
  getNotifications,
  getNotificationsPage, readNotifications, setCurrentPage,
  setNotifications
} from "../../../store/slice/notifySlice";

const cx = classNames.bind(styles);

const NotificationPopup = () => {
  const dispatch = useAppDispatch();

  const { permissionGiven: PermissionNotification } = usePermission('admin.notifications.view');

  const notificationsList = useAppSelector(getNotifications);
  const currentPage = useAppSelector(getNotificationsPage);

  const [filters, setFilters] = useState<INotificationFilters | null>(null);
  const [isListLoading, setIsListLoading] = useState<boolean>(true);
  const [tableMeta, setTableMeta] = useState<TableMeta>(undefined);
  const [tableLinks, setTableLinks] = useState<TableLinks>(undefined);
  const [perPageCount, setPerPageCount] = useState(10);
  const [notificationTypesOpt, setNotificationTypesOpt] = useState<IOptions[]>([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const prevCurrentPageRef = useRef<number>();

  const markAsRead = (id): void => {
    markAsReadMethod(id).then((res): void => {
      if (res && res.status === 200) {
        notify({ type: 'success', message: 'Marked as read' });
        dispatch(decreaseNotificationCount(1));
        dispatch(readNotifications([id]));
      }
    });
  };

  const markAsReadHandle = () => {
    setIsListLoading(true);
    const ids = selectedRows.reduce((result, row) => {
      if (!notificationsList[row.index].is_read) {
        result.push(notificationsList[row.index].id);
      }
      return result;
    }, []);
    multyMarkAsRead(ids);
    setTimeout(() => {
      dispatch(decreaseNotificationCount(ids.length));
      dispatch(readNotifications(ids));
      setIsListLoading(false);
    }, 3000);
  };

  const selectRowHandle = (row) => {
    setSelectedRows(row);
  };

  const fetchNotyficationsTypesOpt = (): void => {
    getSystemData()
      .then((res): void => {
        if (res.status === 200) {
          setNotificationTypesOpt(
            res.data['notification_types'].map((item: string): IOptions => ({
              value: item,
              label: mapNotificationType[item] || item,
            })),
          );
        }
      })
      .catch((error): void => {
        if (error && error?.response?.data !== null) {
          notify({
            type: 'error',
            message: error?.response,
            timeOut: 3000,
          });
        }
      });
  };

  const fetchNotificationsList = (options?, filtersData?): void => {
    setIsListLoading(true);
    findMyNotificationsMethod(getTablePageParams(options, perPageCount, tableLinks), filtersData)
      .then((res): void => {
        if (res.status === 200) {
          dispatch(setNotifications(res.data.data));
          dispatch(setCurrentPage(res.data.meta.current_page));
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
        }
      })
      .catch((error): void => {
        if (error && error?.response?.data !== null) {
          if (PermissionNotification) {
            notify({
              type: 'error',
              message: error?.response,
              timeOut: 3000,
            });
          }
        }
      })
      .finally(() => setIsListLoading(false));
  };

  useEffect((): void => {
    fetchNotyficationsTypesOpt();
  }, []);

  useEffect((): void => {
    fetchNotificationsList({ navigate: 'first', perPage: perPageCount }, filters);
  }, []);

  useEffect((): void => {
    prevCurrentPageRef.current = currentPage;
  }, [currentPage]);

  return (
    <div className={cx('notificationPopup')}>
      <div className={cx('notificationPopup__header')}>
        <strong>{tableMeta?.total}</strong> Notifications found
      </div>
      <div className={cx('notificationPopup__content')}>
        <NotificationsPopupFilter
          notificationsTypesOptions={notificationTypesOpt}
          onSubmit={(data): void => {
            setFilters(data);
            fetchNotificationsList({ navigate: 'first', perPage: data?.filter?.limit }, data);
          }}
          reloadFilters={isListLoading}
        />
        <NotificationsPopupTable
          markAsReadDisableBtn={!selectedRows.length}
          markAsReadHandle={markAsReadHandle}
          onRowSelect={selectRowHandle}
          data={notificationsList}
          perPage={perPageCount}
          showLoader={isListLoading}
          className={cx('notificationPopupTable')}
          markAsRead={(id) => markAsRead(id)}
        />
        {notificationsList?.length ? (
          <TablePagination
            goToFirstPage={(): void => {
              fetchNotificationsList({ navigate: 'first' }, filters);
              dispatch(toggleScrollToTop());
            }}
            goToPrevPage={(): void => {
              fetchNotificationsList({ navigate: 'prev' }, filters);
              dispatch(toggleScrollToTop());
            }}
            goToNextPage={(): void => {
              fetchNotificationsList({ navigate: 'next' }, filters);
              dispatch(toggleScrollToTop());
            }}
            goToLastPage={(): void => {
              fetchNotificationsList({ navigate: 'last' }, filters);
              dispatch(toggleScrollToTop());
            }}
            currentPage={tableMeta?.current_page}
            pagesLength={tableMeta?.last_page}
            perPageChange={(value): void => {
              setPerPageCount(value);
              fetchNotificationsList({ perPage: value }, filters);
            }}
            goToSelectedPage={(page): void => {
              fetchNotificationsList({ page });
            }}
            isFirstPageDisable={(): boolean => tableMeta?.current_page === 1}
            isPrevPageDisable={() => !tableLinks?.prev}
            isNextPageDisable={() => !tableLinks?.next}
            isLastPageDisable={(): boolean => tableMeta?.current_page === tableMeta?.last_page}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default NotificationPopup;
