import { getCryptoTransaction }               from 'api/customer-service/crypto-payments';
import { getCryptoExchangeRate }              from 'api/customer-service/request';
import classNames                             from 'classnames/bind';
import Button                                 from 'components/ui/Button';
import Preloader                              from 'components/ui/Preloader/Preloader';
import CryptoInnerTransactionsTable
                                              from 'components/ui/Table/CryptoInnerTransactionsTable/CryptoInnerTransactionsTable';
import moment                                 from 'moment';
import { ArrowFatLineRight }                 from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams }                    from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { copyToBuffer }                       from 'utils/copyToBuffer';
import { usePermission }                      from '../../../utils/usePermission';
import Page                                   from '../../Page/Page';
import { DashboardLayout }                    from '../../UserView/DashboardLayout';
// import CryptoTransactionsPopups            from '../CryptoTransactions/CryptoTransactionsPopups/CryptoTransactionsPopups';

import styles from './transaction-page.module.scss';
import { CryptoTransaction } from 'models/CustomerService/CryptoTransaction';

const cx = classNames.bind(styles);

const TransactionPage = () => {
  const { permissionGiven: PermissionModerateReceivedCryptoPayments } = usePermission('admin.crypto_transaction.edit');

  const params = useParams();

  const [hash, setHash] = useState('');
  const [currencyType, setCurrencyType] = useState('');
  const [cryptoFullType, setCryptoFullType] = useState('');
  const [transactionData, setWalletData] = useState<CryptoTransaction>();

  const [isLoading, setIsLoading] = useState(true);
  const [cryptoRate, setCryptoRate] = useState('');

  let title = currencyType + ' Transaction - ' + hash;

  const componentMounted = useRef(false);

  useEffect(() => {
    title = 'Transaction...';
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const fetchCryptoTransaction = (params) => {
    setIsLoading(true);

    getCryptoTransaction(params.id)
      .then((res) => {
        if (componentMounted.current) {
          setWalletData(res.data.data);
          setHash(res.data.data.crypto_hash);
          setCurrencyType(res.data.data.crypto_type);
          setCryptoFullType(res.data.data.crypto_type_full);
          setIsLoading(false);
          fetchCryptoRate();
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  const fetchCryptoRate = () => {
    getCryptoExchangeRate(transactionData.crypto_type.toLowerCase()).then((res) => {
      setCryptoRate(res.data.data?.rateUsd);
    });
  };

  useEffect(() => {
    fetchCryptoTransaction(params);
  }, [params]);

  return (
    <DashboardLayout>
      <Page>
        {!isLoading ? (
          <div className={cx('container')}>
            <div className={cx('container__header')}>
              <div className={cx('transaction')}>
                <div className={cx('transaction__icon')}>
                  <i className={cx(`${transactionData?.crypto_type.toLowerCase()}`)}></i>
                </div>
                <div className={cx('transaction__desc')}>
                  <span>{currencyType} Transaction</span>
                  <span className={cx('status', transactionData.status)}>
                    {transactionData.status}
                  </span>
                </div>
              </div>
              <div className={cx('action')}>
                {/*{PermissionModerateReceivedCryptoPayments ?*/}
                {/*  <CryptoTransactionsPopups*/}
                {/*    triggerBtn={<Button buttonType='primary' buttonText='Change status' />}*/}
                {/*    onCloseModal={() => console.log('a')}*/}
                {/*    webHookId={transactionData.id}*/}
                {/*    info={null}*/}
                {/*    updateComponent={() => fetchCryptoTransaction(params)}*/}
                {/*  />*/}
                {/*  : null*/}
                {/*}*/}
                <Link to={`/customer-transactions`} className={cx('logo')}>
                  <Button
                    buttonType='outline'
                    buttonText='To transaction list'
                    onClick={() => null}
                  />
                </Link>
              </div>
            </div>

            <div className={cx('container__block')}>
              <div className={cx('transactionInfo')}>
                <div className={cx('transactionInfo__col', 'transaction-info')}>
                  <div className={cx('transactionInfo__col-title')}>Transaction information</div>
                  <div className={cx('transactionInfo__col-content')}>
                    <div>
                      <span className={cx('strong')}>Wallet:&nbsp;</span>
                      <span
                        className={cx('wallet__identificator')}
                        onClick={() => {
                          copyToBuffer(transactionData.wallet_number);
                        }}
                      >
                        {transactionData.wallet_number}
                      </span>
                    </div>
                    <div>
                      <span className={cx('strong')}>Hash:&nbsp;</span>
                      <span
                        className={cx('wallet__identificator')}
                        onClick={() => {
                          copyToBuffer(transactionData.crypto_hash);
                        }}
                      >
                        {/* {getShortTransactionId(transactionData.crypto_hash)} */}
                        {transactionData.crypto_hash}
                      </span>
                    </div>
                    <div>
                      <span className={cx('strong')}>Amount:&nbsp;</span>
                      {transactionData.amount}&nbsp;{transactionData.crypto_type}
                    </div>
                    <div>
                      <span className={cx('strong')}>Created at:&nbsp;</span>
                      {moment.unix(transactionData.created_at).format('DD.MM.YYYY HH:mm')}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {transactionData && transactionData.accept_crypto_transaction ? (
              <div className={cx('container__block')}>
                <div className={cx('transactionInfo', 'accepted-transaction')}>
                  <div className={cx('transactionInfo__col')}>
                    <div className={cx('transactionInfo__col-title')}>
                      Accepted crypto transaction
                    </div>
                    <div className={cx('transactionInfo__col-content')}>
                      <div>
                        <span>
                          <span className={cx('strong')}>Transaction ID:&nbsp;</span>
                        </span>{' '}
                        <span className={cx('strong')}>
                          <span
                            className={cx('wallet__identificator')}
                            onClick={() => {
                              copyToBuffer(
                                transactionData.accept_crypto_transaction.transaction.id,
                              );
                            }}
                          >
                            {transactionData.accept_crypto_transaction.transaction.id}
                          </span>
                        </span>
                      </div>

                      <div className={cx('crypto-amount')}>
                        <span className={cx('strong')}>Amount:&nbsp;</span>
                        <span>
                          {transactionData.accept_crypto_transaction.transaction.amount}{' '}
                          {transactionData.accept_crypto_transaction.transaction.amount_type}
                          &nbsp;&nbsp;
                          <ArrowFatLineRight size={14} />
                          &nbsp;&nbsp;
                          {transactionData.accept_crypto_transaction.crypto_amount}&nbsp;
                          {currencyType}
                        </span>
                      </div>
                      <div>
                        <span className={cx('strong')}>Exchange rate: </span>
                        <span>{transactionData.accept_crypto_transaction.exchange_rate}</span>
                      </div>
                      <div>
                        <span className={cx('strong')}>Type: </span>
                        <span
                          className={cx(
                            'strong',
                            transactionData.accept_crypto_transaction.transaction.type,
                          )}
                        >
                          {transactionData.accept_crypto_transaction.transaction.type}
                        </span>
                      </div>
                      <div>
                        <span className={cx('strong')}>Method: </span>
                        <span>{transactionData.accept_crypto_transaction.transaction.method}</span>
                      </div>
                      <div>
                        <span className={cx('strong')}>Status: </span>
                        <span
                          className={cx(
                            transactionData.accept_crypto_transaction.transaction.status,
                          )}
                        >
                          {transactionData.accept_crypto_transaction.transaction.status} &nbsp;
                        </span>
                        <span>
                          at &nbsp;
                          {moment
                            .unix(
                              transactionData.accept_crypto_transaction.transaction
                                .status_created_date,
                            )
                            .format('DD.MM.YYYY HH:mm')}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className={cx('transactionInfo__col')}>
                    <div className={cx('transactionInfo__col-title')}>Client</div>
                    <div className={cx('transactionInfo__col-content')}>
                      <div>
                        <span className={cx('strong')}>Name: </span>
                        <span>
                          {
                            transactionData.accept_crypto_transaction.transaction.user.user_profile
                              .first_name
                          }{' '}
                          {
                            transactionData.accept_crypto_transaction.transaction.user.user_profile
                              .last_name
                          }
                        </span>
                      </div>
                      <div>
                        <span>
                          <span className={cx('strong')}>Trading account ID:&nbsp;</span>
                        </span>{' '}
                        <span className={cx('strong')}>
                          <span
                            className={cx('wallet__identificator')}
                            onClick={() => {
                              copyToBuffer(
                                transactionData.accept_crypto_transaction.transaction
                                  .trading_account_id,
                              );
                            }}
                          >
                            {
                              transactionData.accept_crypto_transaction.transaction
                                .trading_account_id
                            }
                          </span>
                        </span>
                      </div>
                      <div>
                        <span className={cx('strong')}>Balance: </span>
                        <span>
                          {transactionData.accept_crypto_transaction.transaction.user.balance}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}

            <div className={cx('container__block')}>
              <div className={cx('transactions__table')}>
                <span className={cx('uppercase')}>New transactions</span>
              </div>
              <div className={cx('transactions__table')}>
                {transactionData.new_crypto_transaction &&
                transactionData.new_crypto_transaction.length ? (
                  <CryptoInnerTransactionsTable
                    data={transactionData.new_crypto_transaction}
                    perPage={transactionData.new_crypto_transaction.length}
                    cryptoType={currencyType}
                    mainWebHookId={transactionData.id}
                    transactionData={transactionData}
                    showLoader={false}
                    onRowSelect={null}
                    modifyDataHandler={(callback) => fetchCryptoTransaction(params)}
                    cryptoRate={cryptoRate}
                    cryptoFullType={cryptoFullType}
                    permission={PermissionModerateReceivedCryptoPayments}
                  />
                ) : (
                  <div className={cx('empty-transactions')}>No new transactions</div>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className={cx('preloader')}>
            <div className={cx('preloader__inner')}>
              <Preloader />
            </div>
          </div>
        )}
      </Page>
    </DashboardLayout>
  );
};

export default TransactionPage;
