import React, { useState } from 'react';
import classNames from 'classnames/bind';

import styles from './formInput.module.scss';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';
import ShuffleIcon from '../../../assets/Svg/ShuffleIcon';
const randomize = require('randomatic');
const cx = classNames.bind(styles);

const FormInputAutoExternalId = (props) => {
  function makeRandomArr(a, b) {
    return Math.random() - 0.5;
  }

  const generate = (): void => {
    let num = randomize('0', 2);
    let upper = randomize('A', 3);
    let lower = randomize('a', 3);
    let str = num + upper + lower;
    let arr = str.split('');
    let code_arr = arr.sort(makeRandomArr).toString();
    let code = code_arr.replace(/[\s.,%]/g, '');
    props.generatePassword(code);
    field.onChange(code);
  };
  const [focus, setFocus] = useState<boolean>(false);

  const { field } = useController(props);
  return (
    <>
      <div className={cx('label-wrap')} style={props.labelStyle}>
        {props.label && (
          <label htmlFor={props.id} className={props.errors ? cx('label', 'error') : cx('label')}>
            {props.label}
            {props.rules && props.rules.required ? <span className={cx('required')}>*</span> : ''}
          </label>
        )}
        <div className={cx('icon-container')}>
          {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
        </div>
      </div>
      <div
        style={focus ? { borderColor: 'var(--main-color)' } : { backgroundColor: 'transperent' }}
        className={props.errors ? cx('input-wrap', 'error') : cx('input-wrap')}
      >
        <button onClick={generate} type={'button'} className={cx('generate-btn')}>
          <ShuffleIcon />
        </button>
        <input
          {...field}
          {...props}
          className={props.errors ? cx('input', 'error') : cx('input')}
          type={props.type}
          placeholder={props.placeholder}
          disabled={props.disabled}
          onFocus={() => setFocus(true)}
          onBlur={() => setFocus(false)}
          value={props.value || (field.value === null ? '' : field.value)}
        />
      </div>
      {props.errors && <p className={cx('errorName')}>{props.errors.message}</p>}
    </>
  );
};

export default FormInputAutoExternalId;
