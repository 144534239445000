import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';
import { Container, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import styles from './../client-page.module.scss';
import { usePermission } from '../../../../utils/usePermission';
import CalendarPicker from '../../../../components/ui/CalendarPicker/CalendarPicker';
import { updatePersonalClientInfo } from '../../../../api/profile';
import { notify } from '../../../../utils/notify';
import Button from '../../../../components/ui/Button';
import FormInput from '../../../../components/ui/FormInput';
import Select from '../../../../components/ui/Select';
import JokerSelectWithSearch from '../../../../components/ui/JokerSelectWithSearch';
import { Language } from 'models/Languages';
import { Client } from 'models/Clients/Clients';
import { ICountry } from 'models/Countries';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

const genderListArr = [
  { id: 'female', name: 'Female' },
  { id: 'male', name: 'Male' },
];

type Props = {
  formData: Client;
  countryList: ICountry[];
  timeZone: ListValue[];
  langList: Language[];
  updateComponent: () => void;
}

const PersonalInfoForm = (props: Props) => {
  const { formData, countryList, timeZone, langList, updateComponent } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [languages, setLanguages] = useState<ListValue[]>([])
  const { permissionGiven: PermissionEditPersonalInfo } = usePermission('admin.client.edit-personal-info');

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const passportIdIssueDate = watch('passport_id_issue_date');

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      ...data,
      tz_utc: data && data.tz_utc ? data.tz_utc.value : null,
      gender: data && data.gender ? data.gender.id : null,
      passport_id_country: data && data.passport_id_country ? data.passport_id_country.id : null,
      language_id: data && data.language_id ? data.language_id.value : null,
      birthday: data && data.birthday ? data.birthday / 1000 : null,
      passport_id_expiration:
        data && data.passport_id_expiration ? data.passport_id_expiration / 1000 : null,
      passport_id_issue_date:
        data && data.passport_id_issue_date ? data.passport_id_issue_date / 1000 : null,
    };
    updatePersonalClientInfo(formData.user_profile.id, sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Personal info updated successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    const opt = langList?.map(item => ({
      value: item.id,
      label: item.name
    }))
    setLanguages(opt)
  }, [])

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      const country =
        countryList &&
        countryList.find((item) => item.country_code === formData.country.country_code);
      const tz = timeZone.find((item) => item.value === formData.user_profile.tz_utc);
      const gender = genderListArr.find((item) => item.id === formData.user_profile.gender);

      const language =
        formData.language && langList.find((item) => item.id === formData.language.id);

      setIsLoading(false);
      reset({
        first_name: formData.user_profile.first_name,
        last_name: formData.user_profile.last_name,
        gender: gender,
        birthday: formData.user_profile.birthday * 1000,
        language_id: language ? { value: language.id, label: language.name } : null,
        ident_number: formData.user_profile.ident_number,
        tz_utc: tz,
        passport_id_number: formData.user_profile.passport_id_number,
        passport_id_country: country,
        passport_id_expiration: formData.user_profile.passport_id_expiration * 1000,
        passport_id_issue_date: formData.user_profile.passport_id_issue_date * 1000,
        country_specific_identifier: formData.user_profile.country_specific_identifier,
      });
    }
  }, [countryList, formData, timeZone, langList]);

  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      <Container className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <Row className={cx('form__line')}>
          <Col md={12}>
            <div className={cx('tab-panel__content-box-title')}>
              Personal information
              {
                PermissionEditPersonalInfo ?
                  isDirty && <Button buttonText='Save changes' buttonType='outline' />
                  : null
              }
            </div>
          </Col>
        </Row>
        <Row className={cx('form__line')}>
          <Col md={6} lg={4} xs={12}>
            <FormInput
              name='first_name'
              type='text'
              label='First name'
              control={control}
              errors={errors.first_name}
            />
          </Col>
          <Col md={6} lg={4} xs={12}>
            <FormInput
              name='last_name'
              type='text'
              label='Last name'
              control={control}
              errors={errors.last_name}
            />
          </Col>
          <Col md={6} lg={4} xs={12}>
            <Select
              name='gender'
              label='Gender'
              control={control}
              getOptionValue={(opt) => opt.id}
              getOptionLabel={(opt) => opt.name}
              options={genderListArr}
            />
          </Col>
          <Col md={6} lg={4} xs={12}>
          {
            <CalendarPicker
              /* @ts-ignore */
              name='birthday'
              control={control}
              label='Date of birth'
              placeholder='Date of birth'
            />
          }
          </Col>
          <Col md={6} lg={4} xs={12}>
            <JokerSelectWithSearch
              label='Language'
              control={control}
              id='language_id'
              name='language_id'
              rightaligned={true}
              options={languages}
            />
          </Col>
          {/*<Col md={6} lg={4} xs={12}>*/}
          {/*  <PaginateSelect*/}
          {/*    isMulti={false}*/}
          {/*    label='Language'*/}
          {/*    control={control}*/}
          {/*    id='language_id'*/}
          {/*    name='language_id'*/}
          {/*    onChange={(page, search) => fetchLanguage(page, search)}*/}
          {/*  />*/}
          {/*</Col>*/}
          <Col md={6} lg={4} xs={12}>
            <Select
              name='tz_utc'
              label='Time zone'
              control={control}
              options={timeZone}
              getOptionValue={(opt) => opt.value}
              getOptionLabel={(opt) => opt.label}
              maxMenuHeight={200}
            />
          </Col>
          <Col md={6} lg={4} xs={12}>
            <FormInput
              name='additional_info'
              type='text'
              label='Additional info'
              control={control}
              errors={errors.additional_info}
              maxLength={20}
            />
          </Col>
          {/* <Col md={6} lg={4} xs={12}>
            <FormInput
              name="ident_number"
              type="text"
              label="Identification Number"
              control={control}
            />
          </Col>
          <Col md={6} lg={4} xs={12}>
            <FormInput
              name="passport_id_number"
              type="text"
              label="Passport / ID number"
              control={control}
            />
          </Col>
          <Col md={6} lg={4} xs={12}>
            <CalendarPicker
              name="passport_id_issue_date"
              control={control}
              label="Passport / ID issue date"
            />
          </Col>
          <Col md={6} lg={4} xs={12}>
            <Select
              name="passport_id_country"
              label="Passport / ID country of issue"
              control={control}
              options={countryList}
              menuIsOpen={true}
              getOptionValue={(opt) => opt.id}
              getOptionLabel={(opt) => opt.name}
            />
          </Col>
          <Col md={6} lg={4} xs={12}>
            <CalendarPicker
              name="passport_id_expiration"
              control={control}
              label="Passport / ID expiration date"
              minDate={new Date(passportIdIssueDate)}
            />
          </Col> */}
          {/* <Col md={6} lg={4} xs={12}>
            <FormInput
              name="country_specific_identifier"
              type="text"
              label="Country specific identifier"
              control={control}
            />
          </Col> */}
        </Row>
      </Container>
    </form>
  );
};

export default PersonalInfoForm;
