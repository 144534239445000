import api from "../api";
import {
    AddParticipantsPayload,
    ChatMessageType,
    ChatUserInfo, CreateChatGroupPayload, CreateChatThreadPayload, GetChatRecipientResponse,
    GetMessagesResponse,
    GetNextMessagesResponse,
    GetThreadsResponse,
    GetThreadsUnreadResponse,
    MessageTypesVerbose, Participant,
    SendDocumentMessagePayload,
    SendImageMessagePayload,
    SendTextMessagePayload, Thread,
    UpdateChatUserInfoPayload
} from "../../models/Chat";
import axios, { AxiosInstance } from "axios";
import { store } from "../../store";
import { setChatToken, setMessagesError, setThreadId } from "../../store/slice/chat/chatSlice";
import API from "../api";

export const chatUrl = process.env.REACT_APP_API_CHAT_URL;

export const chatApi: AxiosInstance = axios.create({
    baseURL: chatUrl,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json;charset=UTF-8',
    },
});

chatApi.interceptors.request.use(
    (config) => {
        const token: string = localStorage.getItem('chat-token');
        config.headers['Authorization'] = 'Bearer ' + token;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    },
);

chatApi.interceptors.response.use(
    (response) => response,
    async (error) => {
        const { status } = error.response;
        const socketsAuthError = status === 403 && error?.config?.url?.includes('/api/broadcasting/auth');
        if (status === 401 || socketsAuthError) {
            store.dispatch(setChatToken(''));
            if (socketsAuthError) {
              store.dispatch(setMessagesError(true));
              store.dispatch(setThreadId(null));
            }
            localStorage.removeItem('chat-token');
        }
        return Promise.reject(error);
    },
);

export const chatGetCrmUserToken = (id: string) => api.get(`chat/token/user/${id}`);

export const chatGetUser = (id: string, data: { token: string }) => chatApi.post(`/api/user/id`, data);

export const chatGetToken = () => api.get('chat/token');

export const chatLogin = (token: string) => chatApi.post('/api/login/token', { token });

export const updateChatUserInfo = (data: UpdateChatUserInfoPayload) => chatApi.post<ChatUserInfo>('/api/messenger/heartbeat', data);

export const getChatRecipient = (id: string) => chatApi.get<GetChatRecipientResponse>(`/api/messenger/privates/recipient/user/${id}`);

export const getChatThreads = () => chatApi.get<GetThreadsResponse>('/api/messenger/threads');

export const getChatThread = (threadId: string) => chatApi.get<Thread>(`/api/messenger/threads/${threadId}`);

export const pinChatThread = (threadId: string, pin: boolean) => chatApi.put(`/api/messenger/threads/${threadId}/participants/${pin ? 'pin' : 'unpin'}`);

export const readThread = (threadId: string) => chatApi.get<Thread>(`/api/messenger/threads/${threadId}/mark-read`);

export const deleteChatThread = (threadId: string) => chatApi.delete(`/api/messenger/threads/${threadId}`);

export const leaveChatGroup = (threadId: string) => chatApi.post(`/api/messenger/threads/${threadId}/leave`);

export const createChatGroup = (data: CreateChatGroupPayload) => chatApi.post<Thread>(`/api/messenger/groups`, data);

export const createChatThread = (data: CreateChatThreadPayload) => chatApi.post(`/api/messenger/privates`, data);

export const muteChatThread = (threadId: string) => chatApi.post(`/api/messenger/threads/${threadId}/mute`);

export const unmuteChatThread = (threadId: string) => chatApi.post(`/api/messenger/threads/${threadId}/unmute`);

export const searchChatThreads = (query: string) => chatApi.get(`/api/messenger/search/${query}`);

export const searchChatClients = (data: { query: string }) => API.post(`/api/v1/search/users?page=1&per_page=5`, data);

export const searchChatMessages = (query: string, thread_id?: string) => chatApi.get(`api/search/text?q=${query}${thread_id ? '&thread_id='+thread_id : ''}`);

export const searchChatMessagePage = (message_id: string, thread_id?: string) => chatApi.get(`api/search/text/page?thread_id=${thread_id}&message_id=${message_id}`);

export const getChatMessages = (threadId: string) => chatApi.get<GetMessagesResponse>(`/api/messenger/threads/${threadId}/load`);

export const getChatMessagesPage = (threadId: string, page: string) => chatApi.get<GetNextMessagesResponse>(`/api/messenger/threads/${threadId}/messages/page/${page}`);

export const getThreadsUnreadCount = () => chatApi.get<GetThreadsUnreadResponse>(`/api/messenger/unread-threads-count`);

export const sendTextMessage = (threadId: string, data: SendTextMessagePayload) => chatApi.post<ChatMessageType>(`/api/messenger/threads/${threadId}/messages`, data);

export const updateTextMessage = (id: string, threadId, data: { message: string }) => chatApi.put<ChatMessageType>(`/api/messenger/threads/${threadId}/messages/${id}`, data);

export const deleteChatMessage = (id: string, threadId: string) => chatApi.delete(`/api/messenger/threads/${threadId}/messages/${id}`);

export const sendImageMessage = (threadId: string, data: SendImageMessagePayload) => chatApi.post<ChatMessageType>(`/api/messenger/threads/${threadId}/images`, data, {
    headers: {
        Accept: 'application/json, text/plain, */*',
    }
});

export const sendDocumentMessage = (threadId: string, data: SendDocumentMessagePayload) => chatApi.post<ChatMessageType>(`/api/messenger/threads/${threadId}/documents`, data,{
    headers: {
        Accept: 'application/json, text/plain, */*',
    }
});

export const deleteGroupParticipant = (threadId: string, id: string) => chatApi.delete(`/api/messenger/threads/${threadId}/participants/${id}`);

export const groupParticipantPromote = (threadId: string, id: string) => chatApi.post<Participant>(`/api/messenger/threads/${threadId}/participants/${id}/promote`);

export const addGroupParticipants = (threadId: string, data: AddParticipantsPayload) => chatApi.post(`/api/messenger/threads/${threadId}/participants`, data);

export const getGroupParticipants = (threadId: string) => chatApi.get(`/api/messenger/threads/${threadId}/participants`);

export const updateThreadSettings = (threadId: string, data) => chatApi.put(`api/messenger/threads/${threadId}/settings`, data);

export const sendChatMessageApi = {
    [MessageTypesVerbose.MESSAGE]: sendTextMessage,
    [MessageTypesVerbose.IMAGE_MESSAGE]: sendImageMessage,
    [MessageTypesVerbose.DOCUMENT_MESSAGE]: sendDocumentMessage
};