import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { Link, useParams } from 'react-router-dom';

import styles from './templates-page.module.scss';
import './ql-editor.scss';
import Button from 'components/ui/Button';
import 'react-tabs/style/react-tabs.css';
import moment from 'moment';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import { fetchTemplate } from 'api/templates';
import { fetchShortCodes } from 'api/templates';
import { createTemplate } from 'api/templates';
import { editTemplate } from 'api/templates';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import FormInput from 'components/ui/FormInput/FormInput';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { notify } from 'utils/notify';
import { firstLetterUppercase } from 'utils/firstLetterUppercase';
import Preloader from 'components/ui/Preloader/Preloader';
import { copyToBuffer } from 'utils/copyToBuffer';
import Select from 'components/ui/Select';
import { getLangList } from 'api/lang';

const cx = classNames.bind(styles);
const EMPTY_EDIT_AREA_VALUE = '<p><br></p>';

const TemplatePage = (props) => {
  const params = useParams();
  const [templateName, setTemplateName] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);

  const [template, setTemplate] = useState();
  const [shortCode, setShortCode] = useState([]);
  const [isPostLoading, setIsPostLoading] = useState(false);
  const [isTemplateLoading, setIsTemplateLoading] = useState(false);
  const [isCodeLoading, setIsCodeLoading] = useState(false);
  const [value, setValue] = useState('');
  const [langListArr, setlangListArr] = useState([]);

  const componentMounted = useRef(true);
  const editor = useRef();

  const { handleSubmit, reset, watch, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      subject: '',
      language: undefined,
    },
  });

  const { isDirty, isValid, dirtyFields, isSubmitSuccessful, errors } = formState;

  useEffect(() => {
    getShortCodes();
    getLangList()
      .then((res) => setlangListArr(res.data.data))
      .catch(console.log);

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getShortCodes = () => {
    setIsCodeLoading(true);

    fetchShortCodes()
      .then((res: any) => {
        if (componentMounted.current && !res.errors) {
          setShortCode(mapTemplates(res.data));
          setIsCodeLoading(false);
        }
      })

      .catch((err) => {
        setIsCodeLoading(false);
      });
  };

  const mapTemplates = (data) => {
    return data.map((item) => {
      const reg = /([{{]+)\s([_])\s([}}]+)/g;
      return {
        value: item,
        label: item.replace('{{', '').replace('}}', '').replace('_', ' '),
      };
    });
  };

  const getTemplate = (params): void => {
    setIsTemplateLoading(true);

    fetchTemplate(params.id)
      .then((res: any): void => {
        if (componentMounted.current && !res.errors) {
          setTemplate(res.data.data);
          setIsTemplateLoading(false);
          setTemplateName(res.data.data.name);
        }
      })
      .catch((err): void => {
        setIsTemplateLoading(false);
      });
  };

  const submit = (data): void => {
    if (!isEditMode) {
      createTemplateRequest(data);
    } else {
      editTemplateRequest(data);
    }
  };

  const createTemplateRequest = (data): void => {
    setIsPostLoading(true);

    createTemplate({
      ...data,
      language: data['language'].code,
      template: value,
    })
      .then((res: any): void => {
        if (componentMounted.current && !res.errors) {
          setTemplate(res.data.data);
          setIsPostLoading(false);
          setTemplateName(res.data.data.name);
          notify({
            type: 'success',
            message: 'Template created successful',
            timeOut: 3000,
          });
        }
      })
      .catch((err): void => {
        setIsPostLoading(false);
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      });
  };

  const editTemplateRequest = (data): void => {
    setIsPostLoading(true);

    editTemplate(params['id'], {
      ...data,
      language: data['language'].code,
      template: value,
    })
      .then((res: any): void => {
        if (componentMounted.current && !res.errors) {
          setTemplate(res.data.data);
          setIsPostLoading(false);
          setTemplateName(res.data.data.name);
          notify({
            type: 'success',
            message: 'Template edited successful',
            timeOut: 3000,
          });
        }
      })
      .catch((err) => {
        setIsPostLoading(false);
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      });
  };

  useEffect((): void => {
    if (templateName) {
      document.title = `Template - ${templateName}`;
    }
  }, [templateName]);

  useEffect((): void => {
    if (params['id']) {
      document.title = `Template`;
      setIsEditMode(true);
      getTemplate(params);
    } else {
      document.title = 'New Template';
      setIsEditMode(false);
    }
  }, [params]);

  useEffect(() => {

  }, [value]);

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <Container>
              <Row>
                <div className={cx('header')}>
                  <div className={cx('search-result')}>
                    <span>{params['id'] ? `Edit ${templateName}` : 'Create'} email template</span>
                  </div>
                  <div className={cx('header-controls')}>
                    <Link to={`/settings-templates`}>
                      <Button
                        buttonText="Go to Email Templates"
                        onClick={() => null}
                        buttonType={'outline'}
                      />
                    </Link>
                  </div>
                </div>
              </Row>
            </Container>
          </div>
          <div className={cx('container__block')}>
            <Container>
              {isPostLoading || isTemplateLoading ? <Preloader /> : null}
              <Row className={isPostLoading || isTemplateLoading ? cx('p-0', 'blur') : cx('p-0')}>
                <Col md={7}>
                  <div className={cx('container__edit-area')}>
                    <div className={cx('editor-variables', 'template-editor')}>
                      <span>Template Variables (Copy & Paste): </span>

                      <div>
                        {isCodeLoading ? (
                          <span
                            className={"spinner-grow text-secondary"}
                            role="status"
                            aria-hidden="true"
                          ></span>
                        ) : null}

                        {shortCode && shortCode.length ? (
                          shortCode?.map((item) => (
                            <span
                              className={cx('editor-variables__item')}
                              key={item.value}
                              onClick={(): void => {
                                copyToBuffer(item.value);
                              }}
                            >
                              {firstLetterUppercase(item.label, 'en')}
                            </span>
                          ))
                        ) : (
                          <span>No variables...</span>
                        )}
                      </div>
                    </div>

                    <ReactQuill
                      ref={editor}
                      className="template-editor"
                      theme="snow"
                      value={value}
                      modules={{
                        toolbar: [
                          [{ size: [] }],
                          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
                          [
                            { list: 'ordered' },
                            { list: 'bullet' },
                            { indent: '-1' },
                            { indent: '+1' },
                          ],
                          ['link', 'image', 'video'],
                          ['clean'],
                        ],
                      }}
                      formats={[
                        'header',
                        'font',
                        'size',
                        'bold',
                        'italic',
                        'underline',
                        'strike',
                        'blockquote',
                        'list',
                        'bullet',
                        'indent',
                        'link',
                        'image',
                        'video',
                      ]}
                      onChange={setValue}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className={cx('container__form')}>
                    <form onSubmit={handleSubmit(submit)}>
                      <FormInput
                        name="name"
                        type="text"
                        label="Email template Name"
                        control={control}
                        rules={{ required: ' Field is required' }}
                        errors={errors.name}
                      />
                      <FormInput
                        name="subject"
                        type="text"
                        label="Subject"
                        control={control}
                        rules={{ required: ' Field is required' }}
                        errors={errors.subject}
                      />
                      <Select
                        name="language"
                        label="Language"
                        control={control}
                        getOptionValue={(opt) => opt.code}
                        getOptionLabel={(opt) => opt.name}
                        options={langListArr}
                        rules={{ required: ' Field is required' }}
                        errors={errors.language}
                      />
                      <Button
                        buttonText="Create"
                        buttonType={'primary'}
                        disabled={!value || value === EMPTY_EDIT_AREA_VALUE}
                      />
                    </form>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default TemplatePage;
