import { useMemo } from 'react';
import { mapOptionsToPlaceholder } from "../constants/dynamic-placeholder";

const useDynamicSearchPlaceholder = (watch, getValues, searchOptions) => {
  return useMemo(() => {
    if (!searchOptions?.length) {
      return 'Search';
    }

    return mapOptionsToPlaceholder(searchOptions, getValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watch('query_category'), searchOptions, getValues]);
};

export default useDynamicSearchPlaceholder;
