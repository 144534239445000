export const MOBILE_SIZE_WIDTH = 900;
export const MOBILE_SMALL_SIZE_WIDTH = 425.98

export const MOBILE_BURGER_MENU_STYLES = {
  bmBurgerButton: {
    position: 'relative',
    width: '30px',
    height: '21px',
  },
  bmBurgerBars: {
    background: '#373a47',
    height: '10%',
  },
  bmBurgerBarsHover: {
    background: '#a90000',
  },
  bmCrossButton: {
    height: '24px',
    width: '24px',
  },
  bmCross: {
    background: '#bdc3c7',
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    left: 0,
    top: 0,
  },
  bmMenu: {
    background: '#f6f6f6',
    boxShadow: '1px 0px 0px var(--outline-text-color)',
    padding: '0',
  },
  bmMorphShape: {
    fill: '#373a47',
  },
  bmItemList: {
    color: '#b8b7ad',
    padding: '0',
  },
  bmItem: {
    display: 'inline-block',
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)',
    left: 0,
    top: 0,
  },
};
