import { FormattedMessage } from 'react-intl';

export const IPS_VALIDATION = {
    isIPs: (ipaddress) =>
        /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/.test(
            ipaddress,
        ) || 'err',
};

export const PHONE_INPUT_VALIDATION = {
    isPhone: (value) =>
        /^[\+]?([0-9][\s]?|[0-9]?)([(][0-9]{3}[)][\s]?|[0-9]{3}[-\s\.]?)[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(value) ||
        <FormattedMessage
            id='form.error.phoneMessage_1'
            defaultMessage='Enter a different phone format.'
        />,
};

export const PASSWORD_CONFIRM_INPUT_VALIDATION = (intl, new_password) => ({
    compare: (value) =>
        value === new_password || intl.formatMessage({
            id: 'form.error.passwordMessage_8',
            defaultMessage: 'The password confirmation does not match.',
        }),
});

export const PASSWORD_INPUT_VALIDATION_WITH_INTL = (intl) => ({
    isNumber: (value) => /^(?=.*[0-9])/.test(value) || intl.formatMessage({
        id: 'form.error.passwordMessage_5',
        defaultMessage: 'Password should contain at least one digit',
    }),
    isUpper: (value) =>
        /^(?=.*[a-zа-я])/.test(value) || intl.formatMessage({
            id: 'form.error.passwordMessage_2',
            defaultMessage: 'Password should contain at least one lowercase letter',
        }),
    isLower: (value) =>
        /^(?=.*[A-ZА-Я])/.test(value) || intl.formatMessage({
            id: 'form.error.passwordMessage_4',
            defaultMessage: 'Password should contain at least one uppercase letter',
        }),
    isSimbol: (value) =>
        /^(?=.*[!@#$%^&*\.,?;:"'№_=\/<>\|{}`~()\[\]\\])/.test(value) || intl.formatMessage({
            id: 'form.error.passwordMessage_1',
            defaultMessage: 'Password should contain at least one special symbol',
        }),
    minLength: (value) =>
        /^.{8,}$/.test(value) || intl.formatMessage({
            id: 'form.error.passwordMessage_6',
            defaultMessage: 'Password must contain at least 8 characters',
        }),
});

export const PASSWORD_INPUT_VALIDATION = {
    isNumber: (value) =>
        /^(?=.*[0-9])/.test(value) || (
            <FormattedMessage
                id='form.error.passwordMessage_5'
                defaultMessage='Password should contain at least one digit'
            />
        ),
    isUpper: (value) =>
        /^(?=.*[a-zа-я])/.test(value) || (
            <FormattedMessage
                id='form.error.passwordMessage_2'
                defaultMessage='Password should contain at least one lowercase letter'
            />
        ),
    isLower: (value) =>
        /^(?=.*[A-ZА-Я])/.test(value) || (
            <FormattedMessage
                id='form.error.passwordMessage_4'
                defaultMessage='Password should contain at least one uppercase letter'
            />
        ),
    isSimbol: (value) =>
        /^(?=.*[!@#$%^&*\.,?;:"'№_=\/<>\|{}`~()\[\]\\])/.test(value) || (
            <FormattedMessage
                id='form.error.passwordMessage_1'
                defaultMessage='Password should contain at least one special symbol'
            />
        ),
    minLength: (value) =>
        /^.{8,}$/.test(value) || (
            <FormattedMessage
                id='form.error.passwordMessage_6'
                defaultMessage='Password must contain at least 8 characters'
            />
        ),
};

export const EXTERNAL_ID_VALIDATION = {
    isNumber: (value) =>
        /^(?=.*[0-9])/.test(value) || (
            'ID should contain at least one digit'
        ),
    isUpper: (value) =>
        /^(?=.*[a-zа-я])/.test(value) || (
            'ID should contain at least one lowercase letter'
        ),
    isLower: (value) =>
        /^(?=.*[A-ZА-Я])/.test(value) || (
            'ID should contain at least one uppercase letter'
        ),
    minLength: (value) =>
        /^.{8,}$/.test(value) || (
            'ID must contain at least 8 characters'
        ),
};

export const VALID_EMAIL = {
    correctEmail: (value) =>
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            value) ||
        'Incorrect E-mail address',
};
