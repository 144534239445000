import React, { useEffect } from "react";
import Button from "../../../components/ui/Button";
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { usePageTitle } from "../../../utils/usePageTitle";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getAlerts, selectTickerMessages } from "../../../store/slice/tickerSlice";
import { SystemMessagesTable } from "./components/SystemMessagesTable/SystemMessagesTable";
import { SYSTEM_MESSAGE_MODAL_KEY, SystemMessageModal } from "./components/SystemMessageModal/SystemMessageModal";
import { openJokerModal } from "../../../store/slice/modalSlice";
import { DeleteMessageModal } from "./components/DeleteMessageModal/DeleteMessageModal";
import { AlertModalMode } from "../../../models/Ticker";
import { usePermission } from "../../../utils/usePermission";
import { DashboardLayout } from "../../UserView/DashboardLayout";
import "./SystemMessages.scss";


const SystemMessagesPage = () => {
    const dispatch = useAppDispatch();
    const { active, scheduled, past } = useAppSelector(selectTickerMessages);

    usePermission('admin.menu-system-messages.view', true);

    usePageTitle('System Messages');

    useEffect(() => {
        dispatch(getAlerts());
    },[]);

    const addMessageHandler = () => {
        dispatch(openJokerModal({
            key: SYSTEM_MESSAGE_MODAL_KEY,
            data: { title: 'New message', submitTitle: 'Create', mode: AlertModalMode.CREATE }
        }));
    };

    return (
        <DashboardLayout>
            <div className="system-messages-page">
                <div className="system-messages-page-header">
                    <div className="system-messages-page-title">System Messages</div>
                    <Button
                        buttonText='+ Add message'
                        buttonType='outline'
                        onClick={addMessageHandler}
                    />
                </div>
                <div className="system-messages-page-content">
                    <Tabs className='system-messages-page-tabs'>
                        <TabList>
                            <Tab>Active</Tab>
                            <Tab>Scheduled</Tab>
                            <Tab>Past</Tab>
                        </TabList>
                        <TabPanel><SystemMessagesTable data={active} type='active'/></TabPanel>
                        <TabPanel><SystemMessagesTable data={scheduled} type='scheduled'/></TabPanel>
                        <TabPanel><SystemMessagesTable data={past} type='past'/></TabPanel>
                    </Tabs>
                </div>
                <SystemMessageModal />
                <DeleteMessageModal />
            </div>
        </DashboardLayout>
    )
};

export default SystemMessagesPage;