import classNames from 'classnames/bind';
import React, { FC } from 'react';

import styles from './button.module.scss';
import { IButton } from "../../../models/components/Button/Button";

const cx = classNames.bind(styles);

const Button: FC<IButton> = React.forwardRef((props, ref): React.JSX.Element => {
  const {
    buttonText,
    size,
    buttonType,
    icon,
    iconPrefix,
    classes,
    buttonStyle,
    onClick,
    className,
    disabled,
    type = 'submit',
    ...other
  } = props;

  return (
    <button
      style={buttonStyle}
      className={cx('btn', buttonType, size, buttonStyle, className)}
      onClick={onClick}
      disabled={disabled}
      type={type}
      {...other}
    >
      {icon && iconPrefix ? <span className={cx('btn__icon')}>{icon}</span> : ''}
      {buttonText && <span className={cx('btn__text')}>{buttonText}</span>}
      {icon && !iconPrefix ? <span className={cx('btn__icon')}>{icon}</span> : ''}
    </button>
  );
});

export default Button;
