import { SELECT_DEFAULT_STYLES } from '../../../constants/select';

type Props = {
  isDisabled: boolean;
  errors: any;
};

export const getJokerSelectStyles = ({ isDisabled, errors }: Props) => ({
  ...SELECT_DEFAULT_STYLES,
  control: (provided, state) => ({
    ...provided,
    padding: '0 13px',
    boxShadow: '1px solid #262626',
    marginBottom: '15px',
    '&:hover': {
      border: state.isFocused ? '1px solid var(--main-color) !important' : '1px solid #c2c2c2',
    },
    backgroundColor: isDisabled ? 'var(--light-grey-disabled)' : '#fff',
    border: errors ? ' 1px solid red' : ' 1px solid #c2c2c2',
    position: 'relative',
    minHeight: '43px',
    overflowY: 'auto',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'var(--font-size-14)',
    color: 'var(--primary-text-color)',
    lineHeight: 'initial',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: '150px',
    width: '100%',
    borderTop: '1px solid #EBEBEB',
    overflowY: 'auto',
    wordBreak: 'break-word',
  }),
  container: (provided) => ({
    ...provided,
  }),
  menu: (provided) => ({
    ...provided,
    zIndex: 13,
    padding: 0,
    margin: 0,
    marginTop: 0,
    boxShadow: 'unset',
    border: '1px solid var(--light-grey)',
    overflow: 'hidden',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '10px 12px',
    fontSize: '13px',
    lineHeight: '20px',
    fontWeight: 400,
    fontFamily: 'Inter',
    backgroundColor: state.isDisabled
      ? undefined
      : state.isSelected
      ? 'var(--list-selected-option-color)'
      : state.isFocused
      ? 'var(--list-hover-option-color)'
      : undefined,
    ':hover': {
      backgroundColor: 'var(--list-hover-option-color)',
    },
    ':active': {
      backgroundColor: !state.isDisabled ? (state.isSelected ? '#F9F1FF' : '#F9F1FF') : undefined,
    },
    color: '#262626',
  }),
  placeholder: (defaultStyles) => ({
    ...defaultStyles,
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 'var(--font-size-14)',

    lineHeight: 'initial',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    alignItems: 'flex-start',
    paddingTop: '3px',
  }),
});
