import React, { useState } from "react";
import cn from "classnames";
import FormInput from "../../../../../components/ui/FormInput";
import Button from "../../../../../components/ui/Button";
import LoaderButton from "../../../../../components/ui/LoaderButton";
import { ModalTemplate } from "../../../../../components/ui/Modal/components/JokerModalTemplate/JokerModalTemplate";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { closeJokerModal, selectModalProps } from "../../../../../store/slice/modalSlice";
import { CheckboxInputDecor } from "../../CheckboxDecor/CheckboxInputDecor";
import { ChatSelectSearch } from "../../ChatSelectSearch/ChatSelectSearch";
import { useNavigate } from "react-router-dom";
import { selectChatUser } from "../../../../../store/slice/chat/chatSlice";
import { useForm, useWatch } from "react-hook-form";
import { createContact, searchChatClientUsers, searchChatUsers } from "../../../../../store/slice/chat/thunks";
import { ThreadTypes, UserTypes } from "../../../../../models/Chat";
import "./CreateChatModal.scss";

export const CREATE_CHAT_MODAL_KEY = 'CREATE_CHAT_MODAL_KEY';

export const CreateChatModal = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const chatUser = useAppSelector(selectChatUser);
    const modalProps = useAppSelector(selectModalProps);
    const isSupport = modalProps?.type == ThreadTypes.support;

    const [isLoading, setIsLoading] = useState(false);
    const [isGroup, setIsGroup] = useState(false);
    const [foundUsers, setFoundUsers] = useState([]);
    const { handleSubmit, control, reset, setValue, formState } = useForm({reValidateMode: 'onChange'});

    const { users, subject } = useWatch({ control });
    const groupChatDisabled = isGroup && ((subject?.trim() || '').length < 3 || !users?.length);

    const closeModal = () => dispatch(closeJokerModal());

    const handleSwitchFormType = () => {
        setIsGroup(v => !v);
        reset({ users: null });
    };

    const onCloseModal = () => {
        setIsLoading(false);
        setIsGroup(false);
        setFoundUsers([]);
        reset({ users: null });
        closeModal()
    };

    const onSearchUsers = async (search) => {
        if (isSupport) {
            const chatUsers = await searchChatClientUsers(search);
            return chatUsers.map(user => ({
                label: (user.user_profile?.first_name || 'user') + ' ' + (user.user_profile?.last_name || ''),
                value: user.id
            }));
        } else {
            const chatUsers = await searchChatUsers(search);
            setFoundUsers(chatUsers);

            return chatUsers
                .filter(user => (user.provider_id !== chatUser.provider.provider_id) && user.base.type === UserTypes.MANAGERS)
                .map(user => ({label: user.name, value: user.provider_id}));
        }
    };

    const onSubmit = (data) => {
        setIsLoading(true);
        dispatch(createContact({ data, isGroup, isSupport, usersData: foundUsers })).then(redirect => {
            navigate(redirect.payload);
        }).finally(() => {
            onCloseModal();
        });
    };

    return (
        <ModalTemplate id={CREATE_CHAT_MODAL_KEY} title={isSupport ? 'New support chat' : 'New chat'} onClose={onCloseModal} size='sm'>
            <div className='content-form new-chat-form'>
                <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                    {!isSupport ? (
                        <div className={cn('chat-type', {checked: isGroup})}>
                            <CheckboxInputDecor checked={isGroup} onClick={handleSwitchFormType}/>
                            <span>Group chat</span>
                        </div>
                    ) : null}
                    <ChatSelectSearch
                        id='search_chat_users'
                        name="users"
                        label={isSupport ? 'Client' : 'User(s)'}
                        placeholder={isSupport ? 'Choose a client' : 'Choose user(s)'}
                        control={control}
                        isMulti={(isGroup && !isSupport)}
                        onChange={onSearchUsers}
                        setValue={setValue}
                        disabled={isLoading}
                    />
                    {(isGroup && !isSupport) ? (
                        <FormInput
                            label='Chat name'
                            id='chat_name'
                            control={control}
                            name='subject'
                            placeholder='Write name'
                            maxLength={32}
                            disabled={isLoading}
                            rules={{ maxLength: { value: 32, message: 'Group name is too long' }}}
                            errors={formState.errors.subject}
                        />
                    ): null}
                    <div className='content-controls'>
                        <div className='controls__buttons'>
                            <Button
                                buttonText="Cancel"
                                buttonType="outline"
                                size="big"
                                type="button"
                                onClick={onCloseModal}
                                disabled={isLoading}
                            />
                            <LoaderButton
                                buttonType="primary"
                                size="big"
                                buttonText={'Create chat'}
                                showSpinner={isLoading}
                                disabled={!users || groupChatDisabled || isLoading}
                                type="submit"
                                onClick={handleSubmit((data) => onSubmit(data))}
                            />
                        </div>
                    </div>
                </form>
            </div>
        </ModalTemplate>
    )
}