import cn from "classnames";
import { X } from "phosphor-react";
import { ChatMessageType, ProcessedMessageMode } from "../../../../models/Chat";
import { setProcessedMessage } from "../../../../store/slice/chat/chatSlice";
import { renderDangerHtml } from "../../helpers/renderDangerHtml";
import { useAppDispatch } from "../../../../store";
import './ChatFormProcessedMessage.scss';

type ChatFormProcessedMessageProps = {
    message: ChatMessageType;
    mode: ProcessedMessageMode;
    resetForm: () => void;
};

export const ChatFormProcessedMessage = ({ message, mode, resetForm }: ChatFormProcessedMessageProps) => {
    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(setProcessedMessage({ mode: null, message: null }));
        resetForm();
    };

    const isEditing = mode === ProcessedMessageMode.IS_EDITING;
    const isReplying = mode === ProcessedMessageMode.IS_REPLYING;

    return (
        <div className={cn("chat-processed", {isEditing, isReplying})}>
            <div className="chat-processed-title">
                {isEditing ? 'Edit message' : null}
                {isReplying ? (
                    <>
                        Reply
                        <span>{message?.owner?.name}</span>
                    </>
                ) : null}
            </div>
            {renderDangerHtml(message?.body, 'chat-processed-text')}
            <div className="chat-processed-close" onClick={handleClose}><X size={32} color={'#722ed1'}/></div>
        </div>
    )
}