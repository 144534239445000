import { IOptions } from "../models/components/JokerSelect/JokerSelect";

export const mapOptionsToPlaceholder = (options: IOptions[], { query_category }: {
  query_category: { value: string }
}) => {
  const selectedCategory = query_category?.value;

  if (!selectedCategory || selectedCategory === 'all') {
    const validOptions = options.filter(item => item.value && item.value !== 'all');
    const labels = validOptions.map(item => item.label);

    return `Search by ${labels.join(', ')}`;
  }

  return 'Search';
};