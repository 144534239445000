import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './crypto-transactions-table.module.scss';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import { copyToBuffer } from 'utils/copyToBuffer';
import { Pencil } from 'phosphor-react';
import moment from 'moment';
import { TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES } from 'constants/table';
import CryptoTransactionsPopups from 'pages/CustomerService/CryptoTransactions/CryptoTransactionsPopups/CryptoTransactionsPopups';
import { getShortUuid } from 'utils/getShortUuid';
import {
  ICryptoInnerTransactionsTableProps
} from "../../../../models/components/Table/CryptoInnerTransactionsTable/CryptoInnerTransactionsTable.ts";
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';

const cx = classNames.bind(styles);

const CryptoInnerTransactionsTable: FC<ICryptoInnerTransactionsTableProps> = ({
  data,
  cryptoType,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  mainWebHookId,
  cryptoFullType,
  transactionData,
  permission
}) => {
  
  // const columns = React.useMemo(() => TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES, []);
  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES", TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, crypto_amount, created_at, exchange_rate, status, transaction } = item;

    const user = transaction?.user;
    const userProfile = user?.user_profile;

    return {
      transaction_id: (
        <div className={cx('transaction__id')}>
          <span
            className={cx('copy_id')}
            onClick={(): void => {
              copyToBuffer(transaction?.id);
            }}
          >
            {getShortUuid(transaction?.id)}
          </span>
        </div>
      ),
      crypto_transaction: (
        <div className={cx('transaction__desc')}>
          <div className={cx('transaction__desc-amount')}>
            <span>Crypto amount:</span>
            <span>
              {crypto_amount} {cryptoType}
            </span>
          </div>
          <div className={cx('transaction__desc-amount')}>
            <span>Exchange rate:</span>
            <span>{exchange_rate}</span>
          </div>
        </div>
      ),
      inner_transaction: (
        <div className={cx('transaction__inner')}>
          <div className={cx('transaction__inner-amount')}>
            <span>Trading account:</span>
            <span
              className={cx('transaction__user-id', 'copy_id')}
              onClick={(): void => {
                copyToBuffer(user.uuid);
              }}
            >
              {transaction?.trading_account_id}
            </span>
          </div>
          <div className={cx('transaction__inner-type')}>
            <span>Type:</span>
            <span className={cx(transaction?.type)}>{transaction?.type}</span>
          </div>
          <div className={cx('transaction__inner-method')}>
            <span>Method:</span>
            <span className={cx(transaction?.method)}>{transaction?.method}</span>
          </div>
          <div className={cx('transaction__inner-amount')}>
            <span>Amount:</span>
            <span>
              {transaction?.amount} {transaction?.amount_type}
            </span>
          </div>
          <div className={cx('transaction__inner-status')}>
            <span>Status:</span>
            <span className={cx(transaction?.status)}>{transaction?.status}&nbsp;</span>
            <span>
              at &nbsp;
              {moment.unix(transaction?.status_created_date).format('DD.MM.YYYY HH:mm')}
            </span>
          </div>
        </div>
      ),
      user: (
        <div className={cx('transaction__user')}>
          <span className={cx('transaction__user-name')}>
            {userProfile?.first_name} {userProfile?.last_name}
          </span>

          <span
            className={cx('transaction__user-id', 'copy_id')}
            onClick={(): void => {
              copyToBuffer(user?.uuid);
            }}
          >
            {getShortUuid(user?.uuid)}
          </span>
          <span>Balance: {user?.balance}</span>
        </div>
      ),
      created: (
        <div className={cx('transaction__created')}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      status: (
        <div className={cx('transaction__pinned')}>
          <span className={cx(status)}>{status}</span>
          <span className={cx('edit-status')}>
            {item && permission && !item?.transaction?.fintegrity ? (
              <CryptoTransactionsPopups
                triggerBtn={<Pencil size={18} />}
                info={item}
                webHookId={mainWebHookId}
                updateComponent={(params) => modifyDataHandler?.(params)}
                // cryptoType={cryptoType}
                cryptoFullType={cryptoFullType}
                transactionData={transactionData}
              />
            ) : (
              ''
            )}
          </span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('transaction-table')}
      isSelected={false}
      saveColumnOrder={saveColumnOrder}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
    />
    </>
  );
};

export default CryptoInnerTransactionsTable;
