const DeskIcon = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.48223 6.98223C4.95107 6.51339 5.58696 6.25 6.25 6.25H33.75C34.413 6.25 35.0489 6.51339 35.5178 6.98223C35.9866 7.45108 36.25 8.08696 36.25 8.75V31.25C36.25 31.9404 35.6904 32.5 35 32.5C34.3096 32.5 33.75 31.9404 33.75 31.25V8.75L6.25 8.75L6.25 31.25C6.25 31.9404 5.69036 32.5 5 32.5C4.30964 32.5 3.75 31.9404 3.75 31.25V8.75C3.75 8.08696 4.01339 7.45107 4.48223 6.98223Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.25 31.25C1.25 30.5596 1.80964 30 2.5 30H37.5C38.1904 30 38.75 30.5596 38.75 31.25C38.75 31.9404 38.1904 32.5 37.5 32.5H2.5C1.80964 32.5 1.25 31.9404 1.25 31.25Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 26.25C17.5 25.5596 18.0596 25 18.75 25H30C30.6904 25 31.25 25.5596 31.25 26.25V31.25C31.25 31.9404 30.6904 32.5 30 32.5C29.3096 32.5 28.75 31.9404 28.75 31.25V27.5H20V31.25C20 31.9404 19.4404 32.5 18.75 32.5C18.0596 32.5 17.5 31.9404 17.5 31.25V26.25Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.75 12.5C8.75 11.8096 9.30964 11.25 10 11.25H30C30.6904 11.25 31.25 11.8096 31.25 12.5V21.25C31.25 21.9404 30.6904 22.5 30 22.5C29.3096 22.5 28.75 21.9404 28.75 21.25V13.75H11.25V31.25C11.25 31.9404 10.6904 32.5 10 32.5C9.30964 32.5 8.75 31.9404 8.75 31.25V12.5Z"
        fill="#722ED1"
      />
    </svg>
  );
};

export default DeskIcon;
