import { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import { format } from 'date-fns';

import { DateRangeValue, dateRangeFormat, ModernDateInputMonthsYearsHeader  } from './';

type Props = ReactDatePickerCustomHeaderProps &
  Partial<DateRangeValue> & {
    showRange?: boolean;
  };

export const ModernDateInputHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  from,
  to,
  showRange,
}: Props) => {
  const endRangeValue = to ? to : from;

  return (
    <div className="date-input-header">
      {showRange ? (
        <div className="date-range-values _flex _p-10">
          <div className="date-range-value">{from ? format(from, dateRangeFormat) : '-'}</div>
          <div className="date-range-value">{endRangeValue ? format(endRangeValue, dateRangeFormat) : '-'}</div>
        </div>
      ) : null}
      <ModernDateInputMonthsYearsHeader
        date={date}
        changeYear={changeYear}
        changeMonth={changeMonth}
        decreaseMonth={decreaseMonth}
        increaseMonth={increaseMonth}
        prevMonthButtonDisabled={prevMonthButtonDisabled}
        nextMonthButtonDisabled={nextMonthButtonDisabled}
      />
    </div>
  );
};
