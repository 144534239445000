import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './files.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
import { notify } from 'utils/notify';
import Select from 'components/ui/Select';
import FormInput from 'components/ui/FormInput';
import { updateStatusFile } from 'api/documents';

const cx = classNames.bind(styles);

const EditFilesPopup = ({ triggerBtn, updateComponent, editData, typesOpt }) => {
  const [categoryOptions, setCategoryOptions] = useState();
  const [typesOptions, setTypesOptions] = useState();

  const [categoryValue, setCategoryValue] = useState(null);

  const [isLoading, setIsLoading] = useState(false);
  const [fileStatusOpt, setFileStatusOpt] = useState([
    {
      id: 1,
      name: 'new',
    },
    {
      id: 2,
      name: 'approved',
    },
    {
      id: 3,
      name: 'rejected',
    },
  ]);
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
    updateComponent()
  };

  const onSubmit = (close, data) => {
    setIsLoading(true);
    const sendData = {
      original_name: data.original_name,
      type_key: data.type_key.key,
      category_key: data.category_key.key,
      user_id: editData.user_id,
      status: data.status?.name,
    };

    updateStatusFile(editData.id, sendData)
      .then((res) => {
        if (res.status === 200) {
          updateComponent();
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          closeModal(close);
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsLoading(false));
  };

  const filterCategoryOpt = () => {
    const opt = typesOpt.map((item) => ({
      key: item.key,
      translate: item.translate,
    }));
    const filterPayment = opt.filter(item => item.key !== 'proof_of_payment' || item.translate !== 'proof_of_payment');
    setCategoryOptions(filterPayment);
  };

  useEffect(() => {
    filterCategoryOpt();
  }, []);

  useEffect(() => {
    if (editData !== undefined && typesOpt.length > 0) {
      const val = typesOpt.filter((opt) => opt.key === editData.kyc_category.key);

      setCategoryValue(val[0].key);
      changeTypesDefaultValue(val[0].key);

      const filterId = val[0].activeStatuses.filter(item => item.key !== 'id_card' || item.translate !== 'id_card');
      setTypesOptions(filterId);

      reset({
        original_name: editData.original_name,
        status: { id: editData.status, name: editData.status },
        category_key: {
          key: editData.kyc_category.key,
          translate: editData.kyc_category.translate,
        },
        type_key: { key: editData.kyc_type.key, translate: editData.kyc_type.translate },
      });
    }
  }, [editData]);

  const changeTypesDefaultValue = (value) => {
    if (value) {
      const typeOpt = typesOpt.filter((el) => el.key === value?.key)[0]?.activeStatuses;
      if (typeOpt) {
        const filterId = typeOpt.filter(item => item.key !== 'id_card' || item.translate !== 'id_card');
        setTypesOptions(filterId);

        reset({
          ...getValues(),
          category_key: {
            key: value.key,
            translate: value.translate,
          },
          type_key: { key: filterId[0].key, translate: filterId[0].translate }})

        return
      }
      setTypesOptions(typeOpt);
      reset({ ...formValues, type_key: null });
    } else {
      const typeOpt = typesOpt.filter((el) => el.key === editData?.kyc_category?.key)[0]
        ?.activeStatuses;

      setTypesOptions(typeOpt);
    }
  };

  useEffect(() => {
    filterCategoryOpt();
  }, [categoryValue]);

  const formValues = getValues();

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            size='xs'
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Edit file'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'editFilesForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row className={cx('form-line')}></Row>
                      <Row className={cx('form-line')}>
                        <Col xs={12} className={cx('input-wrap')}>
                          <FormInput control={control} name='original_name' label='Name' />
                        </Col>
                        <Col xs={12} className={cx('input-wrap')}>
                          <Select
                            name='status'
                            label='Change status'
                            control={control}
                            options={fileStatusOpt}
                            menuIsOpen={true}
                            getOptionValue={(opt) => opt.id}
                            getOptionLabel={(opt) => opt.name}
                          />
                        </Col>
                        <Col xs={12} className={cx('input-wrap')}>
                          <Select
                            name='category_key'
                            label='Change category'
                            control={control}
                            options={categoryOptions}
                            menuIsOpen={true}
                            getOptionValue={(opt) => opt.key}
                            getOptionLabel={(opt) => opt.translate}
                            handleChange={(e) => {
                              changeTypesDefaultValue(e);
                            }}
                          />
                        </Col>
                        <Col xs={12} className={cx('input-wrap')}>
                          <Select
                            name='type_key'
                            label='Change type'
                            control={control}
                            options={typesOptions}
                            menuIsOpen={true}
                            getOptionValue={(opt) => opt.key}
                            getOptionLabel={(opt) => opt.translate}
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md={12}>
                          <div className={cx('content-controls')}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText='Cancel'
                                buttonType='outline'
                                size='big'
                                type='button'
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <LoaderButton
                                buttonType='primary'
                                size='big'
                                showSpinner={isLoading}
                                disabled={isLoading}
                                buttonText={'Change'}
                                type='submit'
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default EditFilesPopup;
