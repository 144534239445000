import classNames                                from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE }               from 'constants/sales-rules.const';
import { TABLE_MANAGEMENTS_DESKS_COLUMNS_NAMES } from 'constants/table';
import { NotePencil, Trash }                     from 'phosphor-react';
import React, { FC } from 'react';
import { copyToBuffer }                          from 'utils/copyToBuffer';
import { makeTableData }                         from '../../../../helpers/table.helper';
import CreateOrEditDesksPopup                          from '../../../../pages/Management/Desks/DesksPopups/CreateOrEditDesksPopup';
import DeleteDesksPopup                          from '../../../../pages/Management/Desks/DesksPopups/DeleteDesksPopup';
import { firstLetterUppercase }                  from '../../../../utils/firstLetterUppercase';
import { getShortUuid }                          from '../../../../utils/getShortUuid';
import { usePermission }                         from '../../../../utils/usePermission.tsx';
import Table                                     from '../Table';
import styles                                    from './offices-table.module.scss';
import { IDesksTableProps } from "../../../../models/components/Table/DesksTable/DesksTable.ts";
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';
import { ManagementDeskPopup } from './Popup.tsx';

const cx = classNames.bind(styles);

const DesksTable: FC<IDesksTableProps> = ({
  data,
  langList,
  officeList,
  deskTypeList,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
}) => {
  const { permissionGiven: PermissionDelete } = usePermission('admin.desk.delete');
  const { permissionGiven: PermissionUpdate } = usePermission('admin.desk.update');

  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_MANAGEMENTS_DESKS_COLUMNS_NAMES", TABLE_MANAGEMENTS_DESKS_COLUMNS_NAMES, true);

  // const columns = React.useMemo(() => TABLE_MANAGEMENTS_DESKS_COLUMNS_NAMES, []);
  const tableData = React.useMemo(
    () => makeTableData(data, data.length),
    [data, langList, deskTypeList, officeList],
  );
  const prepearedTableData = tableData.map((item, pos) => {
    const { id, name, uuid, office, type, language } = item;
    return {
      desk: (
        <div className={cx('rule__name')}>
          <ManagementDeskPopup 
            triggerElement={
              <span style={{ cursor: 'pointer '}}>{name}</span>
            } 
            deskID={id}
            deskName={name}
          />
          <span
            className={cx('rule__id')}
            onClick={(): void => {
              copyToBuffer(uuid);
            }}
          >
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      office: (
        <div className={cx('rule__name')}>
          <span>{office?.name}</span>
          <span
            className={cx('rule__id')}
            onClick={(): void => {
              copyToBuffer(office?.uuid);
            }}
          >
            {getShortUuid(office?.uuid)}
          </span>
        </div>
      ),
      deskType: (
        <div className={cx('rule__name')}>
          <span>{firstLetterUppercase(type)}</span>
        </div>
      ),
      language: (
        <div className={cx('rule__name')}>
          <span>{firstLetterUppercase(language?.name)}</span>
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          <div>
            {
              PermissionDelete ?
                <DeleteDesksPopup
                  rule={item}
                  triggerBtn={<Trash size={20} />}
                  updateComponent={modifyDataHandler}
                /> : null
            }
          </div>
          <div>
            {
              PermissionUpdate ?
                <CreateOrEditDesksPopup
                  deskTypeList={deskTypeList}
                  officeList={officeList}
                  langList={langList}
                  ruleData={item}
                  actionType={SALES_RULES_ACTION_TYPE.EDIT}
                  triggerBtn={<NotePencil size={20} />}
                  onCloseModal={() => console.log('a')}
                  updateComponent={modifyDataHandler}
                /> : null
            }
          </div>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#f9f1ff' : '#ffffff'}`,
          },
        };
      }}
    />
    </>
  );
};

export default DesksTable;
