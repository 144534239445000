/**
 * Generates a validation error message based on the given name and errors object.
 *
 * @param {string} name - The name of the field or object being validated.
 * @param {any} errors - The errors object containing validation error messages.
 * @param {boolean} [replace=false] - Whether to replace the name in the error message.
 *
 * @returns {string} - The generated validation error message.
 */
export const generateValidationErrorMessage = (name: string, errors: any, replace: boolean = false): string => {
  const pathArray = name.split('.');

  if (!errors || !Object.keys(errors)?.length) return '';

  for (const key of pathArray) {
    errors = Object.prototype.hasOwnProperty.call(errors, key) ? errors[key] : '';
  }

  if (errors?.message) {
    return Array.isArray(errors.message) ? errors.message[0] : errors.message;
  }

  if (replace && errors && errors[0]) {
    return errors[0].replace(name, '');
  }

  return '';
};
