import { createListenerMiddleware } from "@reduxjs/toolkit";
import { CallbackEditData } from "../../../models/Clients/ClientCallback";
import { fetchCallbacksToPopulateReminders } from "./";
import { notify } from "../../../utils/notify";
import { format, isFuture } from "date-fns";

type Reminder = {
    delay: number,
    time: number,
    clientName: string,
};

export const RemindersListenerMiddleware = createListenerMiddleware();

RemindersListenerMiddleware.startListening({
  actionCreator: fetchCallbacksToPopulateReminders.fulfilled,
    effect: async (action, { getState, cancelActiveListeners, delay }) => {
        cancelActiveListeners();
        const reminders = selectReminders(getState());

        await Promise.all(reminders.map(async (item) => {
            await delay(item.delay);
            notify({
                type: 'error',
                message: `Scheduled callback today at ${item.time}, to ${item.clientName}.`,
                timeOut: 5000
            });
        }));
    },
});

const selectReminders = (state): Reminder[] => state.reminders.callbacks.reduce((result, callback: CallbackEditData) => {
    callback.status === 'pending' && (callback?.reminders || []).forEach(item => {
        if (isFuture(item * 1000)) {
            result.push({
                delay: item * 1000 - Date.now(),
                time: format(callback.time * 1000, "HH:mm"),
                clientName: callback.client.user_profile
                    ? `${callback.client.user_profile.first_name} ${callback.client.user_profile.last_name}`
                    : callback.client.email
            })
        }
    });
    return result;
}, []);
