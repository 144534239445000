import classNames from 'classnames/bind';

import Button                                 from 'components/ui/Button';
import RoleTable                              from 'components/ui/Table/TemplateTable';
import TablePagination                        from 'components/ui/TablePagination';
import { SALES_RULES_ACTION_TYPE }            from 'constants/sales-rules.const';
import React, { useEffect, useRef, useState } from 'react';
import { Container, Row }                     from 'react-bootstrap';
import { getRolesListMethod }                 from '../../../api/templateRoles';
import CreateRolePopup                        from './CreateRolePopup';

import styles from './style.module.scss';
import { getTablePageParams } from '../../../utils/getTablePageParams';
import { TemplateRole } from 'models/RolesAndPermissions/Templates/Role';
import { TableLinks, TableMeta } from 'models/Table';
import { TemplateListEntry } from 'models/RolesAndPermissions/Templates/Template';

const cx = classNames.bind(styles);

type Props = {
  PermissionShowList: boolean,
  templateList: TemplateListEntry[],
  PermissionEdit: boolean,
  PermissionCreate: boolean,
}

const RolesTab = (props: Props) => {
  useEffect(() => {
    console.log(props)
  }, [props])

  const [roleList, setRoleList] = useState<TemplateRole[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const componentMounted = useRef(true);
  document.title = 'Template roles';

  useEffect(() => {
    getRoleList();
    return () => {
      componentMounted.current = false;
    };
  }, []);
  const getRoleList = (options?, data?) => {
    setIsListLoading(true);

    getRolesListMethod(getTablePageParams(options, perPageCount, tableLinks))
      .then((res) => {
        if (componentMounted.current) {
          setRoleList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  return (
    <div className={cx('container')}>
      <div className={cx('container__header')}>
        <Container>
          <Row>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>
                  <strong>{tableMeta?.total || 0}</strong> Roles
                </span>
                <span></span>
              </div>
              <div className={cx('header-controls')}>
                {props.PermissionCreate ?
                  <CreateRolePopup
                    updateComponent={() => getRoleList()}
                    actionType={SALES_RULES_ACTION_TYPE.CREATE}
                    triggerBtn={
                      <Button buttonText={'+ Add role'} onClick={() => null} buttonType={'outline'} />
                    }
                  />
                  : null
                }
              </div>
            </div>
          </Row>
        </Container>
      </div>
      <div className={cx('container__block')}>
        {
          props.PermissionShowList ?
            <RoleTable
              PermissionEdit={props.PermissionEdit}
              templateList={props.templateList}
              data={roleList}
              perPage={roleList?.length}
              showLoader={isListLoading}
              modifyDataHandler={(callback) => getRoleList()}
            /> : null
        }
        {roleList?.length ? (
          <TablePagination
            goToFirstPage={() => {
              getRoleList({ navigate: 'first' });
            }}
            goToPrevPage={() => {
              getRoleList({ navigate: 'prev' });
            }}
            goToNextPage={() => {
              getRoleList({ navigate: 'next' });
            }}
            goToLastPage={() => {
              getRoleList({ navigate: 'last' });
            }}
            currentPage={tableMeta?.current_page}
            pagesLength={tableMeta?.last_page}
            perPageChange={(value) => {
              setPerPageCount(value);
              getRoleList({ perPage: value });
            }}
            goToSelectedPage={(page) => {
              getRoleList({ page });
            }}
            hidePageSelect={true}
            isFirstPageDisable={() => tableMeta?.current_page === 1}
            isPrevPageDisable={() => !tableLinks?.prev}
            isNextPageDisable={() => !tableLinks?.next}
            isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
};

export default RolesTab;
