import classNames                          from 'classnames/bind';
import { NotePencil, Trash }               from 'phosphor-react';
import React, { useCallback, useState } from 'react';
import { makeTableData }                   from '../../../../helpers/table.helper';
import Table                               from '../Table';
import styles                              from './sales-rules-table.module.scss';
import { usePermission } from '../../../../utils/usePermission';
import EditSalesRulesPopup from '../../../../pages/Management/SalesRules/SalesPopups/EditSalesRulesPopup';
import { TABLE_SALES_RULES_COLUMNS_NAMES } from '../../../../constants/table';
import { copyToBuffer } from '../../../../utils/copyToBuffer';
import { getShortUuid } from '../../../../utils/getShortUuid';
import DeleteSalesRulesPopup from '../../../../pages/Management/SalesRules/SalesPopups/DeleteSalesRulesPopup';
import { SALES_RULES_ACTION_TYPE } from '../../../../constants/sales-rules.const';
import Button from '../../Button';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import Toggle from 'react-toggle';
import { toggleRule } from 'api/rules';

const cx = classNames.bind(styles);

const SalesRulesTable = ({
  data,
  countryList,
  langList,
  operatorsList,
  partnersList,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  onSort,
}) => {
  const { permissionGiven: PermissionEditRule } = usePermission('admin.rule.update');
  const { permissionGiven: PermissionDeleteRule } = usePermission('admin.rule.delete');

  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_SALES_RULES_COLUMNS_NAMES", TABLE_SALES_RULES_COLUMNS_NAMES);

  // const columns = React.useMemo(() => TABLE_SALES_RULES_COLUMNS_NAMES, []);
  const tableData = React.useMemo(
    () => makeTableData(data, data.length),
    [data, countryList, langList, operatorsList, partnersList],
  );
  const rationResult = useCallback((ratio, summ) => {
    if (parseFloat(((ratio/ summ) * 100).toFixed(2)) === 0) {
      return '0.01%'
    }
    if (parseFloat(((ratio/ summ) * 100).toFixed(2)) === 100) {
      const difference = summ - ratio
      if (difference > 1) {
        return 100 - difference * 0.01 + '%'
      } else if (difference === 0) {
        return 100 + '%'
      }
      return '99.99%'
    } else return `${((ratio/ summ) * 100).toFixed(2)}%`
  }, []);

  const [countriesExpanded, setCountriesExpanded] = useState(false);
  const [langsExpanded, setLangsExpanded] = useState(false);
  const [partnersExpanded, setPartnersExpanded] = useState(false);

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      id,
      name,
      uuid,
      operator_uuid,
      countries,
      priority,
      languages,
      partners,
      operators,
      ratio,
      source,
      active,
    } = item;

    const getSourceArray = () => {
      const parsed = JSON.parse(source);
      const keys: string[] = Object.keys(parsed);
      const array = [];

      for (const key of keys) {
        array.push(parsed[key]);
      }

      return array;
    };

    const sourceArray = source ? getSourceArray() : [];
    const sourceStr = sourceArray.length > 1 ? sourceArray.join(', ') : sourceArray;
    const summ = operators.reduce((acc, prev) => acc + prev.ration, 0);

    return {
      rule: (
        <div className={cx('rule__name')}>
          {/* <Link to={`/lead/${id}`} className={cx('logo')}> */}
          <span>{name}</span>
          {/* </Link> */}
          <span
            className={cx('rule__id')}
            onClick={(): void => {
              copyToBuffer(uuid);
            }}
          >
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      country: (
        <div className={cx('rule__country')}>
          {countries.length === countryList.length ? (
            <b>All countries</b>
          ) : (
            <>
              {countries.length ? <b>{countries.length} Countries </b> : ''}
              {countries.length ? (
                <>
                  <span>
                    {countries
                      .slice(0, countriesExpanded ? countries.length : 10)
                      .map((item) => item.iso_3166_2)
                      .join(', ')}
                  </span>
                  {countries.length > 10 && (
                    <Button
                      onClick={() => setCountriesExpanded(!countriesExpanded)}
                      buttonText={`${countriesExpanded ? '-' : `+ ${countries.length - 10}`}`}
                      size="small"
                      type="button"
                      style={{ width: 'min-content', marginLeft: 0, marginTop: 12 }}
                    />
                  )}
                </>
              ) : (
                <span className={cx('rule__partner-empty')}>&mdash;</span>
              )}
            </>
          )}
        </div>
      ),
      priority: (
        <div className={cx('rule__priority')}>
          <b>{priority}</b>
        </div>
      ),
      language: (
        <div className={cx('rule__language')}>
          {languages.length === langList.length ? (
            <b>All languages</b>
          ) : (
            <>
              {languages.length ? <b>{languages.length} Languages </b> : ''}
              {languages.length ? (
                <>
                  <span>
                    {languages
                      .slice(0, langsExpanded ? languages.length : 6)
                      .map((item) => item.code?.toUpperCase())
                      .join(', ')}
                  </span>
                  {languages.length > 6 && (
                    <Button
                      onClick={() => setLangsExpanded(!langsExpanded)}
                      buttonText={`${langsExpanded ? '-' : `+ ${languages.length - 6}`}`}
                      size="small"
                      type="button"
                      style={{ width: 'min-content', marginLeft: 0, marginTop: 12 }}
                    />
                  )}
                </>
              ) : (
                <span className={cx('rule__partner-empty')}>&mdash;</span>
              )}
            </>
          )}
        </div>
      ),
      affiliate: (
        <div className={cx('rule__partner')}>
          {partners.length === partnersList.length ? (
            <b>All partners</b>
          ) : (
            <>
              {partners.length ? <b>{partners.length} Partners </b> : ''}
              {partners.length ? (
                <>
                  <span>
                    {partners
                      .slice(0, partnersExpanded ? partners.length : 3)
                      .map((item) => (
                        <div key={item.id}>
                          {item.first_name} {item.last_name !== null ? item.last_name : ''}
                        </div>
                      ))}
                  </span>
                  {partners.length > 3 && (
                    <Button
                      onClick={() => setPartnersExpanded(!partnersExpanded)}
                      buttonText={`${partnersExpanded ? '-' : `+ ${partners.length - 3}`}`}
                      size="small"
                      type="button"
                      style={{ width: 'min-content', marginLeft: 0, marginTop: 12 }}
                    />
                  )}
                </>
              ) : (
                <span className={cx('rule__partner-empty')}>&mdash;</span>
              )}
            </>
          )}
        </div>
      ),
      operator: (
        <div className={cx('rule__operator')}>
          {operators.length ? <b>{operators.length} Operators </b> : ''}
          {operators.length ? (
            <span>{operators.map((item) => item.full_name).join(', ')}</span>
          ) : (
            <span className={cx('rule__operator-empty')}>&mdash;</span>
          )}
        </div>
      ),
      ratio: (
        <div className={cx('rule__ratio')}>
          {operators?.length ? (
            operators.map((item, index) => {
              return (
                <b key={index}>
                  {index > 0 ? ', ' : ''}
                  {item.ration && rationResult(item.ration, summ)}
                </b>
              );
            })
          ) : (
            <span className={cx('rule__ratio-empty')}>&mdash;</span>
          )}
        </div>
      ),
      source: (
        <div className={cx('rule__source')}>
          {sourceArray.length ? <b>{sourceArray.length} Sources </b> : ''}
          {sourceArray.length ? (
            <span>{sourceStr}</span>
          ) : (
            <span className={cx('rule__source-empty')}>&mdash;</span>
          )}
        </div>
      ),
      status: (
        <div key={`toggle-${id}`}>
          <Toggle
            defaultChecked={active}
            icons={false}
            onChange={() => {
              toggleRule(id, !active);
            }}
          />
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          <div>
            {PermissionDeleteRule ?
              <DeleteSalesRulesPopup
                rule={item}
                triggerBtn={<Trash size={20} />}
                // onCloseModal={() => console.log('a')}
                updateComponent={modifyDataHandler}
              />
              : null
            }
          </div>
          <div>
            {PermissionEditRule ?
              <EditSalesRulesPopup
                countryList={countryList}
                sourceArray={sourceArray}
                langList={langList}
                // operatorsList={operatorsList}
                // partnersList={partnersList}
                ruleData={item}
                // actionType={SALES_RULES_ACTION_TYPE.EDIT}
                triggerBtn={
                  <Button
                    buttonType='outline'
                    buttonText={<NotePencil color='#000' size={20} />}
                    style={{padding: 0, backgroundColor: 'inherit', borderRadius: 0, marginLeft: '4px', border: 0}}
                  />
                }
                // onCloseModal={() => console.log('a')}
                updateComponent={modifyDataHandler}
              />
              : null
            }

          </div>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData, countriesExpanded, langsExpanded, partnersExpanded]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#f9f1ff' : '#ffffff'}`,
          },
        };
      }}
    />
    </>
  );
};

export default SalesRulesTable;
