import React from "react";
import FormTextarea from "../../../../../components/ui/FormTextarea";
import PaginateSelect from "../../../../../components/ui/PaginateSelect";
import Button from "../../../../../components/ui/Button";
import { useForm } from "react-hook-form";
import { fetchSpeed } from "../../helpers/fetchOptions";
import {
    defaultDateTimeFormat,
    ModernDateRangeInput,
    ModernDateTimeInputHeader
} from "../../../../../components/ui/ModernDateRangeInput";
import { systemMessageFormSubmitAdapter, systemMessageFormValuesAdapter } from "../../helpers/adapter";
import {
    SystemMessageFormSubmitData,
    SystemMessageFormValues
} from "../../../../../models/Ticker";
import { isFormFilled } from "../../helpers/isFormFilled";
import { SelectMessageColor } from "../SelectMessageColor/SelectMessageColor";
import './SystemMessageForm.scss';

type SystemMessageFormProps = {
    onSubmit: (data: SystemMessageFormSubmitData) => Promise<void>,
    onCancel: () => void,
    formValues?: SystemMessageFormValues | null,
    edit: boolean,
    loading: boolean,
    submitTitle?: string,
};

const defaultValues = {
    message: '',
    color: null,
    scrolling_speed: null,
    date: { from: null, to: null }
};

export const SystemMessageForm = ({ onSubmit, onCancel, formValues, edit, loading, submitTitle = 'Create'}: SystemMessageFormProps) => {
    const {
        handleSubmit,
        control,
        reset,
        watch,
        formState: { isDirty },
    } = useForm({
        reValidateMode: 'onChange',
        defaultValues: formValues ? systemMessageFormValuesAdapter(formValues) : defaultValues
    });

    const onSubmitHandler = (data) => onSubmit(systemMessageFormSubmitAdapter(data));

    const onCancelHandler = () => {
        reset();
        onCancel();
    };

    const values = watch();

    return (
        <form onSubmit={handleSubmit(onSubmitHandler)} className='system-message-form'>
            <FormTextarea
                name='message'
                label='Message text'
                control={control}
            />
            <SelectMessageColor
                label='Message color'
                placeholder='Select message color'
                control={control}
                id='message_color'
                name='color'
            />
            <ModernDateRangeInput
                name='date'
                control={control}
                label='Date & time'
                placeholder='From - To'
                dateFormat={defaultDateTimeFormat}
                allowToChangeViaInput={true}
                renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                    <ModernDateTimeInputHeader
                        {...props}
                        from={props.from}
                        to={props.to}
                        onTimeChangeHandler={onTimeChangeHandler}
                        showRange
                    />
                )}
            />
            <PaginateSelect
                isSearchable={false}
                isMulti={false}
                menuPosition='fixed'
                label='Scrolling speed'
                placeholder='Select scrolling speed'
                control={control}
                id='scrolling_speed'
                name='scrolling_speed'
                onChange={() => fetchSpeed()}
            />
            <div className="form-actions">
                <Button
                    buttonText='Cancel'
                    buttonType='outline'
                    type='reset'
                    onClick={onCancelHandler}
                />
                <Button
                    buttonText={submitTitle}
                    buttonType='primary'
                    size='big'
                    type='submit'
                    disabled={loading || !isFormFilled(values) || (edit && !isDirty)}
                />
            </div>
        </form>
    )
}
