import React, { FC } from 'react';
import { Wallet } from "phosphor-react";
import Table from "../Table.tsx";
import classNames from "classnames/bind";
import styles from "./crypto-wallets-user-by-id-table.module.scss";
import { copyToBuffer } from "../../../../utils/copyToBuffer.js";
import {
  TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES
} from "../../../../constants/table.js";
import { CRYPTO_WALLETS_TYPES } from "../../../../constants/payments.const.ts";
import { makeTableData } from "../../../../helpers/table.helper.ts";
import {
  ICryptoWalletsUserByIdTable
} from "../../../../models/components/Table/CryptoWalletsUserBiIdTable/CryptoWalletsUserBiIdTable.ts";
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';

const cx = classNames.bind(styles);

const CryptoWalletsUserByIdTable: FC<ICryptoWalletsUserByIdTable> = ({
                                      data,
                                      showLoader,
                                    }) => {
  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES", TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES);

  // const columns = React.useMemo(() => TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item) => {
    const { wallet, crypto_type, crypto_network, description, general, fintegrity } = item;

    return {
      crypto_type: (
        <div className={cx('wallet__type')}>
          <div className={cx('wallet__item')}>
            <div className={cx('wallet__icon')}>
              <i className={cx(`${crypto_type?.toLowerCase()}`)}></i>
            </div>
            <div className={cx('wallet__text')}>
              <span>{CRYPTO_WALLETS_TYPES[crypto_type?.toLowerCase()]} {crypto_type === 'USDT' && crypto_network && `(${crypto_network})`}</span>
              {fintegrity === 1 && <span>Fintegrity</span>}
            </div>
          </div>
        </div>
      ),

      wallet: (
        <div className={cx('wallet__desc')}>
          <span
            className={cx('wallet__name')}
            onClick={(): void => {
              copyToBuffer(wallet);
            }}
          >
            <Wallet size={20} /> {wallet}
          </span>
          <span>{description}</span>
        </div>
      ),

      shared_wallet: (
        <div>
          <span>{general === 1 ? 'Yes' : 'No'}</span>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginTop: "12px",
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      showLoader={showLoader}
      perPage={100}
      className={cx('wallet-table-user-by-id')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
    />
    </>
  );
};

export default CryptoWalletsUserByIdTable;
