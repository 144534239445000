import React from 'react';

const getColorfulLabel = (opt) => {
  let list = opt.branch.split('->');
  let labelList = list.map((item, ind) => {
    if (ind !== list.length - 1) {
      return <span>{item}-></span>;
    } else return <span style={{ color: '#1890FF', fontWeight: '500' }}>{item}</span>;
  });
  return labelList;
};
export { getColorfulLabel };
