import React, { memo } from 'react';
import { Col, Row } from "react-bootstrap";

const AutoRefresh = ({data, action}) => {
  return (
    <Row>
      <Col xs={6}>
        {action}
      </Col>
      <Col xs={6}>
        {data[action]?.map((item, index) => {
          return (
            <React.Fragment key={index}>
              <div className={'mb-1'}>
                {item?.operator_name} Count updates: {item?.count_updates}
              </div>
              <div className={'mb-1'}>

              </div>
              {/* <div className={'mb-2'}>
                {item?.date}
              </div> */}
            </React.Fragment>
          )
        })}
      </Col>
      <hr/>
    </Row>
  )
};

export default memo(AutoRefresh);
