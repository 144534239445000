import { useAppDispatch, useAppSelector } from "../../../store";
import { getActiveAlert, selectTickerActive } from "../../../store/slice/tickerSlice";
import { useEffect, useState } from "react";
import { Ticker } from "./Tiker";
import { X } from "phosphor-react";
import usePolling from "../../../utils/usePolling";

export const TickerContainer = () => {
    const dispatch = useAppDispatch();
    const alert = useAppSelector(selectTickerActive);
    const [show, setShow] = useState(false);

    usePolling(() => dispatch(getActiveAlert()), 300000);

    useEffect(() => {
        if (alert) {
            const lastRead = localStorage.getItem('ticker-read');
            setShow(lastRead !== (alert.id + ''));
        }
    }, [alert]);

    if (!alert || !show) {
        return null;
    }

    const handleCloseTicker = () => {
        setShow(false);
        localStorage.setItem('ticker-read', alert.id);
    };

    return (
        <div className='ticker-container'>
            <Ticker alerts={[alert]} speed={alert.scrolling_speed} color={alert.color}/>
            <div className="ticker-close-button" onClick={handleCloseTicker}><X size={20}/></div>
        </div>
    )
}