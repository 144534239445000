import cn from 'classnames';
import { format } from 'date-fns';
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps } from 'react-datepicker';

import { dateRangeFormat, DateRangeValue, defaultTimeFormat, InputField, ModernDateInputMonthsYearsHeader } from './';

type Props = ReactDatePickerCustomHeaderProps &
  Partial<DateRangeValue> & {
  showRange?: boolean;
  onTimeChangeHandler: any;
};

export const ModernDateTimeInputHeader = (
  {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
    from,
    to,
    showRange,
    onTimeChangeHandler,
  }: Props
) => {
  const defaultFromDateTime = new Date(new Date().setHours(0, 0, 0, 0));
  const defaultEndDateTime = new Date(new Date().setHours(23, 59, 0, 0));
  const [fromTime, setFromTime] = useState<Date | null>(from ?? defaultFromDateTime);
  const [toTime, setToTime] = useState<Date | null>(to ?? defaultEndDateTime);
  const endRangeValue = to ? to : from;

  /**
   * Set the value to from date after changing the date in the input field manually
   */
  useEffect(() => {
    if (from) {
      setFromTime(from);
    }
  }, [from]);

  /**
   * Set the value to from date after changing the date in the input field manually
   */
  useEffect(() => {
    if (to) {
      setToTime(to);
    }
  }, [to]);

  /**
   * Set default time values if date values are not provided and when the filters are reset
   */
  useEffect(() => {
    if (from || to) return;

    timePickerChangeHandler('fromTime', defaultFromDateTime, setFromTime);
    timePickerChangeHandler('toTime', defaultEndDateTime, setToTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to]);

  const timePickerChangeHandler = (
    name: string,
    date: Date | null,
    setActionCb: Dispatch<SetStateAction<Date | null>>,
  ): void => {
    setActionCb(date);

    if (date) {
      onTimeChangeHandler({target: {name, value: format(date, defaultTimeFormat)}});
    }
  };

  return (
    <div className="date-input-header">
      {showRange ? (
        <div className="date-time-range-values _flex">
          <InputField label="From" className={cn('text-input', '_text-l', 'required')}>
            <div className="date-time">
              <div className="date-range-value">{from ? format(from, dateRangeFormat) : '-'}</div>
              <DatePicker
                selected={fromTime}
                onChange={(date: Date | null) => timePickerChangeHandler('fromTime', date, setFromTime)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeFormat={defaultTimeFormat}
                dateFormat={defaultTimeFormat}
                timeClassName={() => 'datepicker-time-item-container'}
                popperClassName={'datepicker-time-popper-container'}
              />
            </div>
          </InputField>
          <InputField label="To" className={cn('text-input', '_text-l', 'required')}>
            <div className="date-time">
              <div className="date-range-value">{endRangeValue ? format(endRangeValue, dateRangeFormat) : '-'}</div>
              <DatePicker
                selected={toTime}
                onChange={(date: Date | null) => timePickerChangeHandler('toTime', date, setToTime)}
                showTimeSelect
                showTimeSelectOnly
                timeIntervals={15}
                timeFormat={defaultTimeFormat}
                dateFormat={defaultTimeFormat}
                timeClassName={() => 'datepicker-time-item-container'}
                popperClassName={'datepicker-time-popper-container'}
              />
            </div>
          </InputField>
        </div>
      ) : null}
      <ModernDateInputMonthsYearsHeader
        date={date}
        changeYear={changeYear}
        changeMonth={changeMonth}
        decreaseMonth={decreaseMonth}
        increaseMonth={increaseMonth}
        prevMonthButtonDisabled={prevMonthButtonDisabled}
        nextMonthButtonDisabled={nextMonthButtonDisabled}
      />
    </div>
  );
};
