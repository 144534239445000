import { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './../../client-page.module.scss';
import { useParams } from 'react-router-dom';
import DealsFilter from './DealsFilter';
import DealsTable from '../../../../../components/ui/Table/DealsTable';
import { findDeals } from '../../../../../api/deals';

const cx = classNames.bind(styles);

const Deals = () => {
  const [deals, setDeals] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [tickerOpt, setTickerOpt] = useState([]);

  const { id } = useParams();

  const componentMounted = useRef(false);

  useEffect(() => {
    getDeals();
    componentMounted.current = true;

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getDeals = (pageParams?, filtersData?) => {
    setIsListLoading(true);

    findDeals(null, filtersData, id)
      .then((res) => {
        if (res && res.status === 200) {
          if (componentMounted.current) {
            setDeals(res.data.data);
            const optTickerOpt = res.data.ticker.map((opt) => ({
              value: opt,
              label: opt,
            }));
            setTickerOpt(optTickerOpt);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  const clearFiltersSortList = () => {
    getDeals({ navigate: 'first', perPage: 10 });
    setFilters(null);
  };

  return (
    <div className={cx('tab-panel', 'payments')}>
      <div className={cx('tab-panel__content')}>
        <div className={cx('tab-panel__header')}>
          <div className={cx('tab-panel__title')}>Deals</div>
        </div>
        <div className={cx('tab-panel__filter-box', 'block')}>
          <DealsFilter
            onSubmit={(filtersData) => {
              if (!filters && !filtersData) {
                return;
              }
              if (filters && !filtersData) {
                clearFiltersSortList();
              } else {
                setFilters(filtersData);
                getDeals(
                  { navigate: 'first', perPage: filtersData?.filter?.limit || 10 },
                  filtersData,
                );
              }
            }}
            userId={id}
            reloadFilters={isListLoading}
            tickerOpt={tickerOpt}
          />
        </div>
        <div className={cx('tab-panel-table')}>
          <DealsTable
            data={deals}
            perPage={200}
            showLoader={isListLoading}
            // modifyDataHandler={(callback) => console.log('a')}
          />
        </div>
      </div>
    </div>
  );
};

export default Deals;
