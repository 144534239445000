import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../components/ui/CrmSearch/CrmSearch";
import {
  CrmSearchCategorySelect,
  defaultQueryCategoryOption
} from "../../../../components/ui/CrmSearchCategorySelect/CrmSearchCategorySelect";
import useDynamicSearchPlaceholder from "../../../../utils/useDynamicSearchPlaceholder";
import { useSearchCategoriesOptions } from "../../../../utils/useGetSearchCategoriesOptions";
import styles from './wallet-filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import { ArrowsClockwise, Info } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';
// import { getLangList } from 'api/lang';
// import { getCountryList } from 'api/country';
// import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
// import FormInput from 'components/ui/FormInput/FormInput';
import Button from 'components/ui/Button/Button';
// import { ACCOUNT_STATUS_SELECT_OPTIONS } from 'constants/clients/filters.const';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import DatePicker from 'components/ui/DatePicker/DatePicker';
import getUTCtime from 'utils/getUTCTime';
import { isFormChange } from '../../../../utils/isFormChange';
import { CryptoWalletsFilter } from 'models/CustomerService/CryptoWalletsFilter';

const cx = classNames.bind(styles);

const walletStatus = [
  { value: 'any', label: 'Any' },
  { value: 1, label: 'Available' },
  { value: 0, label: 'Not available' },
];

const walletOpt = [
  { value: 'any', label: 'Any' },
  { value: 'BTC', label: 'Bitcoin' },
  { value: 'LTC', label: 'Litecoin' },
  { value: 'ETH', label: 'Ethereum' },
  { value: 'DASH', label: 'Dash' },
  { value: 'DOGE', label: 'Doge' },
];

type Props = {
  onSubmit: (data: CryptoWalletsFilter) => void;
  reloadFilters: boolean;
  queryMessage: string,
}

const CryptoPaymentsFilters = (props: Props) => {
  const { onSubmit, reloadFilters, queryMessage} = props;

  const componentMounted = useRef(true);
  const [showRange, setShowRange] = useState(false);
  const cryptoWalletsSearchOptions = useSearchCategoriesOptions('cryptoWallet');

  const getDefaultValue = () => {
    return {
      query:'',
      query_category: defaultQueryCategoryOption,
      type: null,
      available: null,
      time_from: undefined,
      time_to: undefined,
    };
  };
  const { handleSubmit, reset, watch, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields, errors } = formState;

  const dynamicSearchPlaceholder = useDynamicSearchPlaceholder(watch, getValues, cryptoWalletsSearchOptions);

  const availableWatch = watch('available');

  const submit = (data) => {
    const type =
      data['type'] && data['type'].value !== 'any' ? data['type'].value.toUpperCase() : null;
    const available =
      data['available'] && data['available'].value !== 'any' ? data['available'].value : null;

    if (available !== null) {
      const from = getUTCtime(null, data.time_from);
      const to = getUTCtime(null, data.time_to);

      onSubmit({
        query: data['query'],
        searchCategory: data['query_category']?.value,
        type,
        available,
        time_from: Math.round(from / 1000),
        time_to: Math.round(to / 1000),
      });
    } else {
      onSubmit({
        query: data['query'],
        searchCategory: data['query_category']?.value,
        type,
        available: null,
      });
    }
  };

  const resetForm = () => {
    onSubmit(null);
    reset(getDefaultValue());
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (availableWatch && availableWatch.value !== 'any') {
      setShowRange(true);
    } else {
      setShowRange(false);
    }
  }, [availableWatch]);

  return (
    <>
      <form onSubmit={handleSubmit(submit)} className={cx('filters')}>
        <Row>
          <Col md={12}>
            <ComplexSearchBar message={queryMessage}>
              <>
                <CrmSearchCategorySelect
                  id='query_category'
                  name='query_category'
                  control={control}
                  options={cryptoWalletsSearchOptions}
                  onSelectChange={() => {
                      const { query } = getValues();
                    if (query) submit(getValues());
                  }}
                />
                <CrmSearch
                  id='query'
                  name='query'
                  control={control}
                  placeholder={dynamicSearchPlaceholder}
                  onSearch={() => submit(getValues())}
                  handleChange={(value: string) => {
                      if (value?.length === 0) submit(getValues());
                  }}
                />
              </>
            </ComplexSearchBar>
          </Col>
        </Row>
        <Row>
          <Col xl={3} lg={4} md={6}>
            <JokerSelect
              label="Currency Type"
              control={control}
              id="type"
              name="type"
              options={walletOpt}
            />
          </Col>
          <Col xl={3} lg={4} md={6}>
            <JokerSelect
              label="Wallet Status"
              control={control}
              id="available"
              name="available"
              options={walletStatus}
            />
          </Col>
          {showRange ? (
            <>
              <Col xl={3} lg={4} md={6}>
                <div className={cx('datapicker')}>
                  <DatePicker
                    name="time_from"
                    control={control}
                    label="Available date from"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    showTimeInput
                    placeholderText={'Date from'}
                    rules={{ required: 'Field is required' }}
                    errors={errors.time_from}
                  />
                </div>
              </Col>
              <Col xl={3} lg={4} md={6}>
                <div className={cx('datapicker')}>
                  <DatePicker
                    name="time_to"
                    control={control}
                    label="Available date to"
                    dateFormat="MMMM d, yyyy h:mm aa"
                    showTimeInput
                    placeholderText={'Date to'}
                    rules={{ required: 'Field is required' }}
                    errors={errors.time_to}
                  />
                </div>
              </Col>
            </>
          ) : (
            ''
          )}
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => resetForm()}
              disabled={!isFormChange(dirtyFields)}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default CryptoPaymentsFilters;
