import cn from "classnames";
import { ChatUserStatus } from "../ChatUserStatus/ChatUserStatus";
import { chatUrl } from "../../../../api/chat";
import './ChatAvatar.scss';

type ChatAvatarProps = {
    name: string;
    status: boolean;
    className?: string;
    src?: string;
    size?: 'sm'| 'md';
};

export const ChatAvatar = ({ name, status, className, src, size = 'md' }: ChatAvatarProps) => {
    const [firstName, lastName] = name.split(' ');
    const initials = firstName[0] + (lastName?.[0] || '');

    return (
        <div className={cn('chat-avatar', className, size)}>
            <div className="chat-avatar-wrap">
                <div className="chat-avatar-initials">{initials}</div>
                {src ? <img src={chatUrl + src} alt=""/> : null}
            </div>
            {status ? <ChatUserStatus /> : null}
        </div>
    )
};
