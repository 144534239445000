import { createSlice } from "@reduxjs/toolkit";

interface ITableState {
  scrollToTop: boolean,
}

const initialState: ITableState = {
  scrollToTop: false,
}

const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    toggleScrollToTop: (state) => {
      console.log(1)
      state.scrollToTop = !state.scrollToTop
    }
  }
})

export const { toggleScrollToTop } = tableSlice.actions
export const tableSliceReducer = tableSlice.reducer
