import classNames                     from 'classnames/bind';
import { CaretDown, CaretUp }         from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { Col, Row }                   from 'react-bootstrap';
import Toggle                         from 'react-toggle';
import { changePermissionsMethod }    from '../../../api/rolesAndPermissions';
import styles                         from './style.module.scss';
import { PermissionList, RAP_ActiveRole } from 'models/RolesAndPermissions/Permissions';
import { usePermission } from 'utils/usePermission';

const cx = classNames.bind(styles);

type Props = {
  item: string;
  listAction: PermissionList;
  activeRole: RAP_ActiveRole;
}

const ItemLine = (props: Props) => {
  const { item, listAction, activeRole } = props;

  const { permissionGiven: PermissionSupervisor } = usePermission('admin.permission.supervisor');

  const [show, setShow] = useState(false);
  const [currItemList, setCurrItemList] = useState([]);
  useEffect(() => {
    let newList =
      listAction &&
      listAction[item]?.map((perm) => {
        return { ...perm };
      });
    setCurrItemList(newList);
  }, [listAction]);
  const changePermission = (list, curr) => {
    let changeCurr = { ...curr, show: !curr.show };
    let newList = list?.map((item) => {
      if (item === curr) {
        return changeCurr;
      } else return item;
    });
    setCurrItemList(newList);
    submitChanges(changeCurr);
  };
  const submitChanges = (data) => {
    let formData = {
      id_role: activeRole.id,
      selected_permission: data.id,
      status: data.show ? 1 : 0,
    };
    changePermissionsMethod(activeRole.id, formData).then((res) => {});
  };
  return (
    <>
      <Col s={12} className={styles.leftBlock}>
        <div className={styles.wrapperItem}>
          <button
            className={styles.btnAction}
            onClick={() => {
              setShow(!show);
            }}
          >
            {show ? (
              <CaretUp size={24} color={'#c2c2c2'} />
            ) : (
              <CaretDown size={24} color={'#c2c2c2'} />
            )}
            <span className={styles.textBold}>{item}</span>
          </button>
        </div>
      </Col>
      {show &&
        currItemList?.map((item) => {
          return (
            <Row key={item.id} className={styles.subItemWrapper}>
              <Col s={7} className={styles.leftBlock}>
                &#8226; {item.description}
              </Col>
              <Col s={7} className={styles.rightBlock}>
                <div className={styles.permBlock}>
                  <div className={styles.bottomBlock}>
                    <div className={styles.itemBlock}>
                      <div className={cx('filters-toggle')}>
                        <label className='search-clients__toggle'>
                          <Toggle
                            defaultChecked={item.show}
                            icons={false}
                            // disabled={PermissionSupervisor}
                            onChange={() => {
                              changePermission(currItemList, item);
                            }}
                          />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          );
        })}
    </>
  );
};
export default ItemLine;
