import React from 'react';
import classNames from 'classnames/bind';
import styles from './operators-table.module.scss';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import { copyToBuffer } from 'utils/copyToBuffer';
import {
  TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES
} from 'constants/table';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { getShortUuid } from '../../../../utils/getShortUuid';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import Button from 'components/ui/Button/Button';
import PopupYesOrNo from 'components/ui/PopupYesOrNo/PopupYesOrNo';
import { Trash } from 'phosphor-react';
import { usePermission } from 'utils/usePermission';
import { deleteOperator } from 'api/operator';

const cx = classNames.bind(styles);

const OperatorsTable = ({ data, perPage, showLoader, onRowSelect, manualSortHandler, onSort, modifyDataHandler }) => {
  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES", TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES, true);
  // const columns = React.useMemo(() => TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);
  const { permissionGiven: PermissionDeleteOperator } = usePermission('admin.admin-user.delete'); 

  const checkSortEnable = (colId) => {
    switch (colId) {
      case 'operator':
      case 'country':
      case 'status':
      case 'created_at':
        return true;
      default:
        return false;
    }
  };
  const prepearedTableData = tableData.map((item, pos) => {
    const { id, first_name, uuid, country, status, created_at, status_updated_at, last_name } =
      item;
    return {
      operator: (
        <div className={cx('rule__name')}>
          <Link target="_blank" to={`/operator/${id}`} className={cx('logo')}>
            <span>{first_name + ' ' + last_name}</span>
          </Link>
          <span
            className={cx('rule__id')}
            onClick={(): void => {
              copyToBuffer(uuid);
            }}
          >
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      country: country ? (
        <div className={cx('rule__country')}>
          {/*<b>{country?.length} Countries</b>*/}
          <div className={cx('client__country-flag')}>
            <i className={cx('icon')} style={{ backgroundImage: `url(${country.flag})` }} />
          </div>
          <span>{country.iso_3166_2}</span>
        </div>
      ) : (
        <div className={cx('rule__name')}>
          <span>&mdash;</span>
        </div>
      ),
      created_at: (
        <div className={cx('rule__name')}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      status: (
        <div className={cx('rule__name')}>
          <span
            className={cx('status_text')}
            style={status === 'active' ? { color: '#53c41a' } : { color: '#f6222e' }}
          >
            {status}
          </span>
          <span>
            <FormattedMessage id="management.operators.HeaderColumn6" defaultMessage="since " />
            {moment.unix(status_updated_at).format('DD.MM.YYYY')}
          </span>
        </div>
      ),
      action: PermissionDeleteOperator && id ? (
        <div className={cx('rule__country')}>
          <PopupYesOrNo
            onClick={() => { deleteOperator(id).then(() => modifyDataHandler()) }}
            headerTitle={'Delete operator'}
            bodyText={`Are you sure that you want to delete operator ${first_name} ${last_name}?`}
            triggerBtn={
              <Trash
                size={20}
                color="#ff0000"
                style={{ cursor: 'pointer' }}
              />
            }
          />
        </div>
      ) : (
        <div className={cx('rule__country')}>
          <span>&mdash;</span>
        </div>
      )
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      saveColumnOrder={saveColumnOrder}
      manualSortHandler={manualSortHandler}
      colSortCheck={checkSortEnable}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
    />
    </>
  );
};

export default OperatorsTable;
