import React, { FC, useEffect, useRef, useState } from 'react';
import { CryptoWallet } from "../../../../../models/CustomerService/CryptoWallets.ts";
import { getWalletsById } from "../../../../../api/clients/CryptoWalletsTab";
import { Params, useParams } from "react-router-dom";
import CryptoWalletsUserByIdTable
  from "../../../../../components/ui/Table/CryptoWalletsUserByIdTable/CryptoWalletsUserByIdTable.tsx";

const CryptoWalletsTab: FC = () => {

  const [walletList, setWalletList] = useState<CryptoWallet[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const componentMounted = useRef<boolean>(false)
  const params: Readonly<Params> = useParams();

  useEffect(() => {
    componentMounted.current = true;
    getWallets(params.id);
    return (): void => {
      componentMounted.current = false;
    };
  }, []);

  const getWallets = (userId: string): void => {
    setIsLoading(true);

    getWalletsById(userId)
      .then((res): void => {
        if (componentMounted.current) {
          setWalletList(res?.data?.data?.wallet.sort((a, b) => a.general
            - b.general));
        }
      })
      .catch((err): void => {
        console.log(err)
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <CryptoWalletsUserByIdTable
        data={walletList}
        showLoader={isLoading}
      />
    </div>
  );
};

export default CryptoWalletsTab;
