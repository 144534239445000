import { useState } from 'react';
import { useWindowWidth } from '@react-hook/window-size';
import { MOBILE_SIZE_WIDTH } from 'constants/mobile';
import Menu from './ui/Menu/Menu';
import Header from './ui/Header/Header';

export const AppComponents = () => {
  const [menuIsOpen, setMenuIsOpen] = useState(false);

  const width = useWindowWidth({ initialWidth: document.documentElement.clientWidth });
  const isMobile = width < MOBILE_SIZE_WIDTH;

  return (
    <>
      <Menu isOpen={menuIsOpen} isMobile={isMobile} onClose={() => setMenuIsOpen(false)} />
      <Header isMobile={isMobile} onOpenMenu={() => setMenuIsOpen(true)} />
    </>
  );
};
