import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './crypto-wallet-table.module.scss';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import { Eye, Wallet } from 'phosphor-react';

import { Link } from 'react-router-dom';
import moment from 'moment';
import { getStatusForCrypt } from '../../../../utils/getStatusForCrypt';
import { usePermission } from "../../../../utils/usePermission";
import { copyToBuffer } from "../../../../utils/copyToBuffer";
import { CRYPTO_WALLETS_TYPES } from "../../../../constants/payments.const";
import { TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES } from "../../../../constants/table";
import { ICryptoRequestTable } from "../../../../models/components/Table/CryptoRequestTable/CryptoRequestTable.ts";
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';

const cx = classNames.bind(styles);

const CryptoRequestTable: FC<ICryptoRequestTable> = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
}) => {
  // const columns = React.useMemo(() => TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES, []);
  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES", TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);
  const { permissionGiven: PermissionShowWithdrawalRequest } = usePermission('admin.trading-account.show');

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, wallet_address, crypto_type, amount_usd, description, created_at, deleted_at } =
      item;
    const status = getStatusForCrypt(item);
    return {
      wallet_address: (
        <div className={cx('wallet__desc')}>
          <span
            className={cx('wallet__name')}
            onClick={() => {
              copyToBuffer(wallet_address);
            }}
          >
            <Wallet size={20} /> {wallet_address}
          </span>
          <span>{description}</span>
        </div>
      ),
      crypto_type: (
        <div className={cx('wallet__type')}>
          <div className={cx('logo', 'link_type')}>
            <div className={cx('wallet__item')}>
              <div className={cx('wallet__icon')}>
                <i className={cx(`${crypto_type?.toLowerCase()}`)}></i>
              </div>
              <div className={cx('wallet__text')}>
                <span>{CRYPTO_WALLETS_TYPES[crypto_type?.toLowerCase()]}</span>
              </div>
            </div>
          </div>
        </div>
      ),
      amount_usd: (
        <div>
          <span>{amount_usd}</span>
        </div>
      ),
      created_at: (
        <div className={cx('transaction__created')}>
          <span>{moment.unix(created_at).format('DD.MM.YYYY')}</span>
          <span>{moment.unix(created_at).format('HH:mm')}</span>
        </div>
      ),
      status: (
        <div className={cx('transaction__pinned')}>
          <span className={cx(status)}>{status.toUpperCase()}</span>
        </div>
      ),
      action: (
        <div className={cx('wallet__actions')}>
          {PermissionShowWithdrawalRequest ?
            <Link to={`/crypto-request/${id}`} className={cx('view_link')}>
              <Eye size={24} />
            </Link>
            : null
          }
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('wallet-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
      saveColumnOrder={saveColumnOrder}
    />
    </>
  );
};

export default CryptoRequestTable;
