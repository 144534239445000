import API from '../api';

export const getTemplatesListMethod = (params) => API.get(`api/v1/role/templates?${params}`);
export const getPermissionsListMethod = () => API.get(`api/v1/permissions`);
export const getTotalDepartmentsListMethod = () =>
  API.get(`/api/v1/departments?per_page=100&page=1`);
export const getDepartmentsListMethod = (params) => API.get(`/api/v1/departments?${params}`);
export const createTemplateMethod = (data) => API.post(`/api/v1/role/templates`, data);
export const deleteTemplateMethod = (id) => API.delete(`/api/v1/role/templates/${id}`);
export const updateTemplateMethod = (id, data) => API.put(`/api/v1/role/templates/${id}`, data);
export const getCurrentTemplateMethod = (id) => API.get(`/api/v1/role/templates/${id}`);
export const createRoleMethod = (data) => API.post(`/api/v1/roles`, data);
export const getCurrentRoleMethod = (id) => API.get(`/api/v1/roles/${id}`);
export const editNameRoleMethod = (id, data) => API.put(`/api/v1/roles/${id}`, data);
export const editTemplateRoleMethod = (id, data) => API.put(`/api/v1/roles/${id}/template`, data);

export const getTotalTemplatesListMethod = () =>
  API.get(`/api/v1/role/templates?per_page=100&page=1`);
export const getRolesListMethod = (params) => API.get(`api/v1/roles?${params}`);
