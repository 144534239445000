import { getWallet }                          from 'api/customer-service/crypto-payments';
import classNames                             from 'classnames/bind';
import Button                                 from 'components/ui/Button';
import { CRYPTO_WALLETS_TYPES }               from 'constants/payments.const';
import moment                                 from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Link, useParams }                    from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { copyToBuffer }                       from 'utils/copyToBuffer';
import { usePermission }                      from '../../../utils/usePermission.tsx';
import Page                                   from '../../Page/Page';
import { DashboardLayout }                    from '../../UserView/DashboardLayout';
import PinToUserPopup                         from '../CryptoPayments/CryptoPaymentsPopups/PinToUserPopup';

import styles from './wallet-page.module.scss';
import CryptoWalletBindingsTable from "../../../components/ui/Table/CryptoWalletBindingsTable";
import { CryptoWallet } from 'models/CustomerService/CryptoWallets.js';

const cx = classNames.bind(styles);

const WalletPage = () => {
  const { permissionGiven: PermissionSetOrEditWalletToClient } = usePermission('admin.crypto_wallets.set-wallet');

  const params = useParams();

  const [walletNumber, setWalletNumber] = useState('');
  const [walletData, setWalletData] = useState<CryptoWallet>();

  // const [showNotes, setShowNotes] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const title = 'Wallet - ' + walletNumber;

  const [clientsList, setClientsList] = useState([]);

  const componentMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const fetchWalletInfo = (params) => {
    setIsLoading(true);

    getWallet(params.id)
      .then((res) => {
        setWalletData(res.data.data);
        setWalletNumber(res.data.data.wallet);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    fetchWalletInfo(params);
  }, [params]);

  return (
    <DashboardLayout>
      {!isLoading && (
        <Page>
          <div className={cx('container')}>
            <div className={cx('container__header')}>
              <div className={cx('wallet')}>
                <div className={cx('wallet__type')}>
                  <div className={cx('wallet__item')}>
                    <div className={cx('wallet__icon')}>
                      <i className={cx(`${walletData?.crypto_type.toLowerCase()}`)}></i>
                    </div>
                    <div className={cx('wallet__desc')}>
                      <span>
                        {CRYPTO_WALLETS_TYPES[walletData?.crypto_type.toLowerCase()]} {walletData?.crypto_type === 'USDT' && walletData.crypto_network && `(${walletData.crypto_network})`} Wallet
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className={cx('action')}>
                {PermissionSetOrEditWalletToClient && walletData.general === 0 ?
                  <PinToUserPopup
                    wallet={walletData}
                    clientsList={clientsList}
                    updateComponent={() => fetchWalletInfo(params)}
                    triggerBtn={<Button buttonType='outline' buttonText='Bind to user' />}
                  />
                  : null
                }
                <Link to={`/customer-crypto`} className={cx('logo')}>
                  <Button
                    buttonType='outline'
                    buttonText='Go to wallet list'
                    onClick={() => null}
                  />
                </Link>
              </div>
            </div>

            {/*{walletData && walletData.wallet_to_user ? (*/}
            {/*  <div className={cx('container__block')}>*/}
            {/*    <div className={cx('wallet-info')}>*/}
            {/*      <div className={cx('wallet-info__col')}>*/}
            {/*        <div className={cx('wallet-info__col-title')}>Current user</div>*/}
            {/*        <div className={cx('wallet-info__col-content')}>*/}
            {/*          <span>*/}
            {/*            <span className={cx('strong')}>Name:&nbsp;</span>*/}
            {/*            {walletData.user?.user_profile*/}
            {/*              ? walletData.user?.user_profile.first_name +*/}
            {/*                ' ' +*/}
            {/*                walletData.user?.user_profile.last_name*/}
            {/*              : '---'}{' '}*/}
            {/*          </span>{' '}*/}
            {/*          <span className={cx('strong')}>*/}
            {/*            ID:&nbsp;*/}
            {/*            <div*/}
            {/*              className={cx('wallet__identificator')}*/}
            {/*              onClick={() => {*/}
            {/*                copyToBuffer(walletData.user.uuid);*/}
            {/*              }}*/}
            {/*            >*/}
            {/*              {getShortUuid(walletData.user.uuid)}*/}
            {/*            </div>*/}
            {/*          </span>*/}
            {/*          <span>*/}
            {/*            <span className={cx('strong')}>Email:&nbsp;</span>*/}
            {/*            {walletData.user.email || '---'}{' '}*/}
            {/*          </span>{' '}*/}
            {/*          <span>*/}
            {/*            <span className={cx('strong')}>Phone:&nbsp;</span>*/}
            {/*            {walletData.user.phone || '---'}{' '}*/}
            {/*          </span>{' '}*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*      <div className={cx('wallet-info__col')}>*/}
            {/*        <div className={cx('wallet-info__col-title')}>Current bind</div>*/}
            {/*        <div className={cx('wallet-info__col-content')}>*/}
            {/*          {walletData.wallet_to_user.pinned ? (*/}
            {/*            <span className={cx('pinned', 'strong')}>Pinned</span>*/}
            {/*          ) : (*/}
            {/*            <>*/}
            {/*              <div className={cx('available', 'strong')}>AVAILABLE</div>*/}
            {/*              <div>*/}
            {/*                from:{' '}*/}
            {/*                {moment*/}
            {/*                  .unix(walletData.wallet_to_user.available_from)*/}
            {/*                  .format('DD.MM.YYYY HH:mm')}*/}
            {/*              </div>*/}
            {/*              <div>*/}
            {/*                to:{' '}*/}
            {/*                {moment*/}
            {/*                  .unix(walletData.wallet_to_user.available_to)*/}
            {/*                  .format('DD.MM.YYYY HH:mm')}*/}
            {/*              </div>*/}
            {/*            </>*/}
            {/*          )}*/}
            {/*        </div>*/}
            {/*      </div>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*) : (*/}
            {/*  ''*/}
            {/*)}*/}

            <div className={cx('container__block')}>
              <div className={cx('personalInfo')}>
                <div className={cx('personalInfo__col')}>
                  <div className={cx('personalInfo__col-title')}>Wallet information</div>
                  <div className={cx('personalInfo__col-content')}>
                    <div>
                      <span className={cx('strong')}>Wallet:&nbsp;</span>
                      <span
                        className={cx('wallet__identificator')}
                        onClick={() => {
                          copyToBuffer(walletData.wallet);
                        }}
                      >
                        {walletData.wallet}
                      </span>
                    </div>
                    <div>
                      <span className={cx('strong')}>Description:&nbsp;</span>
                      {walletData.description || '---'}{' '}
                    </div>

                    <div>
                      <span className={cx('strong')}>Created at:&nbsp;</span>
                      {moment.unix(walletData.created_at).format('DD.MM.YYYY HH:mm')}
                    </div>
                    <div>
                      <span className={cx('strong')}>Shared wallet:&nbsp;</span>
                      {walletData.general === 1 ? 'Yes' : 'No'}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={cx('container__block')}>
              <div className={cx('wallet__bindings')}>
                <span>Wallet bindings</span>
              </div>
              <div className={cx('wallet__table')}>
                {walletData.general === 0 ?
                  <CryptoWalletBindingsTable
                    data={walletData.binded_wallet_to_users}
                    perPage={walletData.binded_wallet_to_users.length}
                    showLoader={false}
                    onRowSelect={null}
                    modifyDataHandler={(callback) => fetchWalletInfo(params)}
                    permission={PermissionSetOrEditWalletToClient}
                  />
                  : null
                }
              </div>
            </div>
          </div>
        </Page>
      )}
    </DashboardLayout>
  );
};

export default WalletPage;
