export const CRYPTO_WALLETS_TYPES = {
  btc: 'Bitcoin',
  ltc: 'Litecoin',
  eth: 'Ethereum',
  dash: 'Dash',
  doge: 'Doge',
  bcy: 'Bcy',
  usdt: 'Tether',
  usdc: 'USDC',
  trx: 'Tron',
};

export const WALLET_ACTION_TYPE = {
  CREATE: 'create',
  DELETE: 'delete',
  EDIT: 'edit',
  RESTORE: 'restore',
};
