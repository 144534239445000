import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './crypto-wallet-table.module.scss';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import { ClockCounterClockwise, Trash, Wallet } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { usePermission } from '../../../../utils/usePermission';
import { copyToBuffer } from '../../../../utils/copyToBuffer';
import { TABLE_CRYPTO_WALLETS_COLUMNS_NAMES } from '../../../../constants/table';
import DeleteRestorePopup from '../../../../pages/CustomerService/CryptoPayments/CryptoPaymentsPopups/DeleteRestorePopup';
import { CRYPTO_WALLETS_TYPES, WALLET_ACTION_TYPE } from '../../../../constants/payments.const';
import { ICryptoWalletTableProps } from '../../../../models/components/Table/CryptoWalletTable/CryptoWalletTable.ts';
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';
import { getShortUuid } from '../../../../utils/getShortUuid';

const cx = classNames.bind(styles);

const CryptoWalletTable: FC<ICryptoWalletTableProps> = ({
  data,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
}) => {
  const { permissionGiven: PermissionDeleteCryptoWallet } = usePermission(
    'admin.crypto_wallets.delete',
  );
  const { permissionGiven: PermissionRestoreDeletedCryptoWallet } = usePermission(
    'admin.crypto_wallets.restore',
  );

  const { columns, saveColumnOrder, setDefaultColumnsOrder } = useTableColumnOrder(
    'TABLE_CRYPTO_WALLETS_COLUMNS_NAMES',
    TABLE_CRYPTO_WALLETS_COLUMNS_NAMES,
  );
  // const columns = React.useMemo(() => TABLE_CRYPTO_WALLETS_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const {
      id,
      wallet,
      crypto_type,
      crypto_network,
      user,
      description,
      created_at,
      deleted_at,
      general,
      fintegrity,
    } = item;

    return {
      crypto_type: (
        <div className={cx('wallet__type')}>
          {!deleted_at ? (
            <Link to={`/customer-crypto/${id}`} className={cx('logo')}>
              <div className={cx('wallet__item')}>
                <div className={cx('wallet__icon')}>
                  <i className={cx(`${crypto_type?.toLowerCase()}`)}></i>
                </div>
                <div className={cx('wallet__text')}>
                  <span>{CRYPTO_WALLETS_TYPES[crypto_type?.toLowerCase()]}</span>
                  {fintegrity === 1 && <span>Fintegrity</span>}
                </div>
              </div>
            </Link>
          ) : (
            <div className={cx('wallet__item')}>
              <div className={cx('wallet__icon')}>
                <i className={cx(`${crypto_type?.toLowerCase()}`)}></i>
              </div>
              <div className={cx('wallet__text')}>
                <span>
                  {CRYPTO_WALLETS_TYPES[crypto_type?.toLowerCase()]}{' '}
                  {crypto_type === 'USDT' && crypto_network && `(${crypto_network})`}
                </span>
                {fintegrity === 1 && <span>Fintegrity</span>}
              </div>
            </div>
          )}
        </div>
      ),

      wallet: (
        <div className={cx('wallet__desc')}>
          <span
            className={cx('wallet__name')}
            onClick={(): void => {
              copyToBuffer(wallet);
            }}
          >
            <Wallet size={20} /> {wallet}
          </span>
          <span>{description}</span>
        </div>
      ),

      shared_wallet: (
        <div>
          <span>{general === 1 ? 'Yes' : 'No'}</span>
        </div>
      ),
      user_profile: (
        <div className={cx('wallet_table_td')}>
          <Link
            target={'_blank'}
            to={`/client/${item?.user_profile?.user_id ?? 0}`}
            className={cx('wallet_table_td-link')}
            style={{ display: 'block' }}
          >
            <span>
              {item && item.user_profile && item.user_profile.first_name
                ? item.user_profile.first_name + ' '
                : ''}
              {item && item.user_profile && item.user_profile.last_name
                ? item.user_profile.last_name
                : ''}
            </span>
          </Link>
          {item.user_profile !== null && item?.user_profile?.user_uuid && (
            <span
              className="uuid"
              onClick={() => copyToBuffer(item?.user_profile?.user_uuid, true)}
            >
              {getShortUuid(item?.user_profile?.user_uuid)}
            </span>
          )}
        </div>
      ),
      action: (
        <div className={cx('wallet__actions')}>
          {!deleted_at ? (
            <>
              <div className={cx('wallet__actions-delete')}>
                {PermissionDeleteCryptoWallet ? (
                  <DeleteRestorePopup
                    wallet={item}
                    actionType={WALLET_ACTION_TYPE.DELETE}
                    triggerBtn={<Trash size={20} />}
                    onCloseModal={() => console.log('a')}
                    updateComponent={modifyDataHandler}
                  />
                ) : null}
              </div>
            </>
          ) : (
            <div className={cx('wallet__actions-restore')}>
              {PermissionRestoreDeletedCryptoWallet ? (
                <DeleteRestorePopup
                  wallet={item}
                  actionType={WALLET_ACTION_TYPE.RESTORE}
                  triggerBtn={<ClockCounterClockwise size={20} />}
                  onCloseModal={() => console.log('a')}
                  updateComponent={modifyDataHandler}
                />
              ) : null}
            </div>
          )}
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
      <Button
        buttonStyle={{
          marginTop: '12px',
          marginBottom: '12px',
          marginLeft: 0,
        }}
        buttonText="Reset column order"
        onClick={setDefaultColumnsOrder}
      />
      <Table
        saveColumnOrder={saveColumnOrder}
        columns={columns}
        data={tableDataMeMo}
        perPage={perPage}
        showLoader={showLoader}
        onRowSelect={onRowSelect}
        className={cx('wallet-table')}
        isSelected={false}
        getCellProps={(cellInfo, cx) => {
          const { row, value, column } = cellInfo;
          return {
            style: {
              backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
            },
          };
        }}
      />
    </>
  );
};

export default CryptoWalletTable;
