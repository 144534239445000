import classNames from 'classnames/bind';
import { CheckCircle, Key } from 'phosphor-react';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { resetPassword } from '../../../api/auth';
import Button from '../../../components/ui/Button';
import FormInput from '../../../components/ui/FormInput';
import Preloader from '../../../components/ui/Preloader/Preloader';
import { notify } from '../../../utils/notify';
import styles from './forgotPassword.module.scss';
import { useAppSelector } from 'store';
import { getLogo } from 'constants/selectors';

import * as STATIC_LOGOS from '../../../assets/logos';

const cx = classNames.bind(styles);

const ForgotPassword = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(false);
  const [requestStatus, setRequestStatus] = useState(false);
  const logo = useAppSelector(getLogo)

  const onSubmit = (data) => {
    setIsLoading(true);
    resetPassword(data)
      .then((response) => {
        setIsLoading(false);
        setRequestStatus(true);
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect(() => {
    document.title = 'Reset Password';
  }, []);

  const renderSuccessfullyRequest = () => (
    <div className={cx('page')}>
      <div className={cx('page__box')}>
        <div className={cx('page__box-header')}>
          <div className={cx('page__box-header-icon')}>
            <CheckCircle size={132} />
          </div>
          <div className={cx('page__box-lang-switcher')}></div>
        </div>
        <div className={cx('page__box-content', 'text-page')}>
          <p className={cx('title')}>The letter was successfully sent.</p>
          <p>Please check.</p>
        </div>
      </div>
    </div>
  );

  return (
    <>
      {!requestStatus ? (
        <div className={cx('page')}>
          <div className={cx('login', 'auth')}>
            <div className={cx('login__col', 'auth__descr')}>
              <div className={cx('auth__descr-img')}>
                <Key size={170} />
              </div>
              <div className={cx('auth__descr-text')}>
                <p>Already have an account?</p>
                <Link to='/' className={cx('link-text')}>
                  Sign in
                </Link>
              </div>
            </div>
            <div className={cx('login__col')}>
              <div className={cx('login__header')}>
                <div className={cx('login__title')}>
                  <FormattedMessage id='auth.resetPassword.title' defaultMessage='Reset password' />
                </div>
              </div>
              <div className={cx('login__content')}>
                <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
                  {isLoading ? <Preloader /> : null}
                  <Container className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
                    <Row className={cx('form__line')}>
                      <Col md={12}>
                        <FormInput
                          name='email'
                          type='email'
                          label='E-mail'
                          control={control}
                          rules={{
                            required: (
                              <FormattedMessage
                                id='form.error.requiredField'
                                defaultMessage='Field is required'
                                values={{
                                  fieldName: 'E-mail',
                                  code: (fieldName) => <span>{fieldName}</span>,
                                }}
                              />
                            ),
                          }}
                          errors={errors.email}
                        />
                      </Col>
                    </Row>
                    <div className={cx('button-wrap')}>
                      <Button
                        type='submit'
                        buttonText={
                          <FormattedMessage
                            id='auth.resetPassword.btnText'
                            defaultMessage='Reset password'
                          />
                        }
                        size='big'
                        loading={isLoading}
                        disabled={isLoading}
                      />
                    </div>
                  </Container>
                </form>
              </div>
            </div>
            <div className={cx('logo')}>
              <img src={logo} alt='logo' />
            </div>
          </div>
        </div>
      ) : (
        renderSuccessfullyRequest()
      )}
      <div className={cx('copyright')}>
        © {new Date().getFullYear()} {process.env.REACT_APP_COMPANY_NAME}, Inc. All rights Reserved
      </div>
    </>
  );
};

export default ForgotPassword;
