import { useController } from 'react-hook-form';
import Select, { GroupBase } from 'react-select';
import { DefaultInputField } from "../DefaultInputField/DefaultInputField";
import { SelectInputProps } from "../DefaultInputField/type";
import { DropdownIndicator } from "./DropdownIndicator";

import './DefaultSelectInput.scss';

export const DefaultSelectInput = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
    id,
    name,
    label,
    styles,
    options,
    isDisabled,
    isLoading,
    tooltip,
    rules,
    placeholder,
    control,
    readonly,
    isClearable,
    isSearchable,
    onSelectChange,
    classNamePrefix,
  }: SelectInputProps<Option, IsMulti, Group>) => {
  const { field, formState } = useController({ name, control, disabled: !!isDisabled, rules });
  const { onChange, ...fieldProps } = field;
  const fieldError = `${formState.errors?.[name]?.message || ''}`;

  return (
    <DefaultInputField
      id={id}
      label={label}
      error={fieldError}
      className={`select-input ${rules?.required ? 'required' : ''}`}
      tooltip={tooltip}
    >
      <Select
        {...fieldProps}
        inputId={id}
        styles={styles}
        options={options}
        classNamePrefix={`select-input ${classNamePrefix ? classNamePrefix : ''}`}
        className={isLoading ? 'is-loading' : ''}
        isLoading={isLoading}
        components={{ DropdownIndicator }}
        isDisabled={isDisabled || readonly}
        placeholder={placeholder}
        onChange={(value: any) => {
          onChange(value);
          onSelectChange?.(value);
        }}
        isClearable={isClearable}
        isSearchable={isSearchable}
      />
    </DefaultInputField>
  );
};
