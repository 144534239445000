import API from '../api' 

export const getDepartamentList = () => (
    API.get(`/api/v1/departments`)
)

export const createDepartaments = (data) => (
    API.post('/api/v1/departments', data)
)
export const updateDepartaments = (id, data) => (
    API.post(`/api/v1/departments/${id}`, data)
)

export const deleteDepartaments = (id) => (
    API.delete(`/api/v1/departments/${id}`)
)