import classNames                             from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import 'react-tabs/style/react-tabs.css';
import { WALLET_ACTION_TYPE }                 from '../../../constants/payments.const';
import { usePermission }                      from '../../../utils/usePermission';

import styles                from './crypto-payments-page.module.scss';
import CryptoPaymentsFilters from './CryptoPaymentsFilters/CryptoPaymentsFilters';
import AddCryptoWalletPopup  from './CryptoPaymentsPopups/AddCryptoWalletPopup';
import Page from '../../Page';
import Button from '../../../components/ui/Button';
import CryptoWalletTable from '../../../components/ui/Table/CryptoWalletTable';
import TablePagination from '../../../components/ui/TablePagination';
import { getCryptoWalletsAll } from '../../../api/customer-service/crypto-payments';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import { ShortCryptoWallet } from 'models/CustomerService/CryptoWallets';
import { CryptoWalletsFilter } from 'models/CustomerService/CryptoWalletsFilter';
import { TableLinks, TableMeta } from 'models/Table';
import { useAppDispatch } from "../../../store";
import { toggleScrollToTop } from "../../../store/slice/tableSlice.ts";
import { useCheckAutoRefresh } from "../../../utils/useCheckAutoRefresh.tsx";

const cx = classNames.bind(styles);

const CryptoPaymentsPage = () => {
  const { permissionGiven: PermissionViewCryptoWallets } = usePermission('admin.crypto_wallets.index');
  const { permissionGiven: PermissionCreateNewCryptoWallet } = usePermission('admin.crypto_wallets.create');

  const dispatch = useAppDispatch();

  const [walletList, setWalletList] = useState<ShortCryptoWallet[]>([]);

  const [isLoading, setIsLoading] = useState(true);

  const [filters, setFilters] = useState<CryptoWalletsFilter>();
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(15);

  const title = 'Crypto Wallets';
  const checkAutoRefresh = useCheckAutoRefresh('Page Crypto Wallets')

  const componentMounted = useRef(false);

  useEffect(() => {
    if (PermissionViewCryptoWallets) {
      componentMounted.current = true;
      getWallets();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [PermissionViewCryptoWallets]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  const getWallets = (options?, filter?) => {
    setIsLoading(true);

    getCryptoWalletsAll(getTablePageParams(options, filter))
      .then((res) => {
        if (componentMounted.current) {
          setWalletList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
          setIsLoading(false);
        }
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getTablePageParams = (pageParams, filter) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    const params = `${toPage}&${perPageParams}`;

    if (filter) {
      let withFilters = `?${params}`;
      if (filter['type']) {
        withFilters = `${withFilters}&type=${filter['type']}`;
      }
      if (filter['available'] !== null) {
        withFilters = `${withFilters}&available=${filter['available']}&time_from=${filter['time_from']}&time_to=${filter['time_to']}`;
      }
      if (filter['query'] !== ''){
        withFilters = `${withFilters}&query=${filter['query']}`;
      }
      if (filter['searchCategory'] && filter['searchCategory'] !== ''){
        withFilters = `${withFilters}&searchCategory=${filter['searchCategory']}`;
      }
      return withFilters;
    }

    return `?${params}`;
  };

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>
                  <strong>{tableMeta?.total || 0}</strong> Crypto Wallets
                </span>
                <span></span>
              </div>
              <div className={cx('header-controls')}>
                {PermissionCreateNewCryptoWallet ?
                  <AddCryptoWalletPopup
                    updateComponent={() => getWallets()}
                    actionType={WALLET_ACTION_TYPE.CREATE}
                    triggerBtn={
                      <Button buttonText='+ Add Wallet' onClick={() => null} buttonType={'outline'} />
                    }
                  />
                  : null
                }
              </div>
            </div>
          </div>

          <div className={cx('container__block')}>
            <CryptoPaymentsFilters
              onSubmit={(data) => {
                if (!filters && !data) {
                  return;
                }

                if (filters && !data) {
                  getWallets({ navigate: 'first' });
                  setFilters(null);
                } else {
                  setFilters(data);
                  getWallets({ navigate: 'first' }, data);
                }
              }}
              reloadFilters={isLoading}
              queryMessage={queryResponseMessage}
                          />
          </div>
          <div className={cx('container__block')}>
            {
              PermissionViewCryptoWallets ?
                <CryptoWalletTable
                  data={walletList}
                  perPage={perPageCount}
                  showLoader={isLoading}
                  clientsList={[]}
                  modifyDataHandler={(callback) => getWallets()}
                /> : null
            }
            {walletList?.length ? (
              <TablePagination
                goToFirstPage={() => {
                  getWallets({ navigate: 'first' }, filters);
                  dispatch(toggleScrollToTop())
                }}
                goToPrevPage={() => {
                  getWallets({ navigate: 'prev' }, filters);
                  dispatch(toggleScrollToTop())
                }}
                goToNextPage={() => {
                  getWallets({ navigate: 'next' }, filters);
                  dispatch(toggleScrollToTop())
                }}
                goToLastPage={() => {
                  getWallets({ navigate: 'last' }, filters);
                  dispatch(toggleScrollToTop())
                }}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getWallets({ perPage: value }, filters);
                }}
                goToSelectedPage={(page) => {
                  getWallets({ page }, filters);
                }}
                hidePageSelect={false}
                isFirstPageDisable={() => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default CryptoPaymentsPage;
