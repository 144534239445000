import { Tooltip } from 'bootstrap';
import classNames from 'classnames/bind';
import { CaretDown, CaretUp } from 'phosphor-react';
import { FC, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import Select, { components, OptionProps } from 'react-select';

import styles from './joker-select.module.scss';
import { IJokerSelect, IOptions } from '../../../models/components/JokerSelect/JokerSelect.ts';
import { getJokerSelectStyles } from './jokerSelectStyles.ts';

const cx = classNames.bind(styles);

const JokerSelect: FC<IJokerSelect> = (props) => {
  const {
    onChange,
    label,
    options,
    defaultValues,
    placeholder,
    errors,
    isDisabled = false,
    isRequired = false,
    isClearable = false,
    isMulti = false,
    rules,
    ...other
  } = props;
  const defaultValue: IOptions | null = options && options.length ? options[0] : null;

  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState<IOptions>(defaultValue);

  const { field } = useController(props);

  const onSelectChange = (value): void => {
    setIsOpen(false);
    field.onChange(value);
    setValue(value);
    props.onSelectChange?.();
  };

  useEffect(() => setValue(field.value), [field.value]);

  return (
    <div>
      <div className={cx('label-wrap')}>
        {props.label && (
          <label htmlFor={props.id} className={cx('label')}>
            {props.label}
            {props.isRequired && <span className={cx('required')}>*</span>}
          </label>
        )}
        {props.tooltip && props.tooltip.show && (
          <div className={cx('icon-container')}>
            <Tooltip params={props.tooltip} />
          </div>
        )}
      </div>
      <Select
        {...field}
        styles={getJokerSelectStyles({ isDisabled, errors })}
        autoFocus
        // controlShouldRenderValue={false}
        isMulti={isMulti}
        isDisabled={isDisabled}
        backspaceRemovesValue={false}
        components={{
          DropdownIndicator: () => <DropdownIndicator isOpen={isOpen} />,
          Option,
          IndicatorSeparator: null,
        }}
        hideSelectedOptions={false}
        isClearable={isClearable}
        onMenuClose={() => setIsOpen(false)}
        onMenuOpen={() => setIsOpen(true)}
        onChange={onSelectChange}
        options={options}
        placeholder={placeholder}
        tabSelectsValue={false}
        value={value || placeholder || 'Any'}
      />
      {errors && <p className={cx('errorName')}>{errors.message}</p>}
    </div>
  );
};

const DropdownIndicator = (props) => (
  <div className={cx('custom-empty')}>
    <div className={cx('custome-control', props.isOpen ? 'list-opened' : '')}>
      {props.isOpen ? <CaretUp size={18} /> : <CaretDown size={18} />}
    </div>
  </div>
);

const Option = (props: OptionProps) => {
  return (
    <div
      className={cx(
        'option',
        props.isSelected ? 'selected' : props.isFocused ? 'focused' : undefined,
      )}
    >
      <components.Option {...props}>
        <div>
          <span className={cx('account__label')} ref={props.innerRef} {...props.innerProps}>
            {props.children}
          </span>
        </div>
      </components.Option>
    </div>
  );
};

export default JokerSelect;
