import API from '../api';

export const fetchTemplates = (params) => API.get(`/api/v1/email-template${params}`);

export const fetchTemplate = (id) => API.get(`/api/v1/email-template/${id}`);

export const deleteTemplate = (id) => API.delete(`/api/v1/email-template/${id}`);

export const fetchShortCodes = () => API.get(`/api/v1/email-template/short-code`);

export const createTemplate = (data) => API.post(`/api/v1/email-template`, data);

export const editTemplate = (id, data) => API.put(`/api/v1/email-template/${id}`, data);
