import React, { FC, memo } from 'react'
import classNames from 'classnames/bind'
import styles from '../clients-table.module.scss'

const cx = classNames.bind(styles)

interface ITypeProps {
  type: string;
}

const Type: FC<ITypeProps> = ({ type }) => {
  return (
    <div className={cx('client__type-sales')}>
      {type || 'none type'}
    </div>
  )
}

export default memo(Type)
