import React, { useEffect, useState } from 'react';
import styles from './style.module.scss';
import { getDepartmentRolesMethod } from '../../../api/rolesAndPermissions';
import { CaretDown, CaretRight } from 'phosphor-react';
import { IItemList, IListRoles, SubItem } from "../../../models/components/DepartmentsMenu/DepartmentsMenu";

const DepartmentsMenu = ({ list, setActiveRole, activeRole }) => {
  const [itemList, setItemList] = useState<IItemList[]>(list);

  useEffect((): void => {
    setItemList(list);
  }, [list]);
  const getRoles = (item) => {
    return getDepartmentRolesMethod(item.id)
      .then((res) => res.data.data)
      .catch(console.log)
  };

  const updateSubItems = async (arr: IItemList[], curr): Promise<void> => {
    const listRoles: IListRoles = await getRoles(curr);

    const newList: IItemList[] = arr.map((item: IItemList): IItemList => {
      if (item === curr) {
        return {
          ...item,
          subItems: [...listRoles.roles],
          show: true,
        };
      }
      return {
        ...item,
        show: false,
      };
    });

    setItemList(newList);
  };
  const closeSubItems = (arr: IItemList[], curr): void => {
    const newList: IItemList[] = arr.map((item: IItemList): IItemList => {
      if (item === curr) {
        return {
          ...item,
          show: false,
        };
      }
      return item;
    });

    setItemList(newList);
  };

  return (
    <div className={styles.wrapperMenu}>
      {itemList?.map((item: IItemList) => {
        return (
          <div key={item.id} className={styles.wrapperCollapse}>
            <button
              className={styles.btnItem}
              key={item.id}
              onClick={(): void => {
                item.show ? closeSubItems(itemList, item) : updateSubItems(itemList, item);
              }}
            >
              <p className={styles.title}>{item.name}</p>
              {item.show ? (
                <CaretDown size={24} color={'#c2c2c2'} />
              ) : (
                <CaretRight size={24} color={'#c2c2c2'} />
              )}
            </button>
            {item.show && (
              <div>
                {item?.subItems.map((sub: SubItem) => {
                  return (
                    <button
                      key={sub.id}
                      className={
                        activeRole === sub
                          ? styles.btnSubItem + ' ' + styles.active
                          : styles.btnSubItem
                      }
                      onClick={(): void => {
                        setActiveRole(sub);
                      }}
                    >
                      &#8226; {sub.name}
                    </button>
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};
export default DepartmentsMenu;
