import React, { FC } from 'react';
import classNames from 'classnames/bind';

import styles from './private-error-page.module.scss';
import { DashboardLayout } from '../UserView/DashboardLayout';
import Page from '../Page/Page';
import ErrorPage from '../ErrorPage/ErrorPage';

const cx = classNames.bind(styles);

const PrivateErrorPage: FC = () => {
  return (
    <DashboardLayout>
      <Page>
        <ErrorPage isPrivateRoute={true} />
      </Page>
    </DashboardLayout>
  );
};

export default PrivateErrorPage;
