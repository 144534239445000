import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import { ComplexSearchBar } from "../../../../components/ui/ComplexSearchBar/ComplexSearchBar";
import { CrmSearch } from "../../../../components/ui/CrmSearch/CrmSearch";
import styles from './offices-filter.module.scss';
import { Col, Container, Row } from 'react-bootstrap';
import { ArrowsClockwise, Info } from 'phosphor-react';
import { useForm, useWatch } from 'react-hook-form';
import 'react-toggle/style.css';
import './reload-button.scss';

import JokerMultiSelect from 'components/ui/JokerMultiSelect/JokerMultiSelect';
import Button from 'components/ui/Button/Button';
import { isFormChange } from '../../../../utils/isFormChange';
import { ManagementOfficesFilter } from 'models/Management/Offices/Filter';
import { CountryListValue } from 'models/Countries';

const cx = classNames.bind(styles);

type Props = {
  onSubmit: (data: ManagementOfficesFilter) => void;
  reloadFilters: boolean;
  countryList: CountryListValue[];
  queryMessage: string,
}

const OfficesFilter = (props: Props) => {
  const { onSubmit, reloadFilters, countryList, queryMessage, setQueryMessage} = props;

  // const [isDataFetchedAfterChange, setIsDataFetchedAfterChange] = useState(false);

  const componentMounted = useRef(true);

  const getDefaultValue = () => {
    return {
      country_ids: [],
      query: '',
    };
  };
  const { handleSubmit, reset, watch, setValue, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { isDirty, isValid, dirtyFields } = formState;

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        country_ids: getValue(data['country_ids']),
      },
    };

    if (data['query']) {
      postData['query'] = data['query'];
    }

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };
    onSubmit(postData);
  };

  const resetForm = () => {
    reset(getDefaultValue());
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };

  const clearEmptyFilter = (data) => {
    const filtered = {};

    for (const key of Object.keys(data)) {
      if (data[key]) {
        filtered[key] = data[key];
      }
    }

    return filtered;
  };

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={cx('filters')}
      >
        <Row>
          <Col md={12}>
            <ComplexSearchBar searchOnly={true} message={queryMessage}>
              <CrmSearch
                id='query'
                name='query'
                control={control}
                placeholder='Search by Office Name'
                onSearch={() => submit(getValues())}
                handleChange={(value: string) => {
                  if (value?.length === 0) submit(getValues());
                }}
              />
            </ComplexSearchBar>
          </Col>
        </Row>
        <Row>
          {countryList && (
            <Col xl={3} lg={4} md={6}>
              <JokerMultiSelect
                label="Country"
                control={control}
                id="country_ids"
                name="country_ids"
                options={countryList}
              />
            </Col>
          )}
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => resetForm()}
              disabled={!isFormChange(dirtyFields)}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OfficesFilter;
