import { RootState } from "../store";

export const getProgress = (state: RootState) => state.userInfo.progressBar.progress
export const getStatusProgress = (state: RootState) => state.userInfo.progressBar.status
export const getTotalProgress = (state: RootState) => state.userInfo.progressBar.total
export const getEnableMT = (state: RootState) => state.userInfo.ENABLE_MT
export const getEnablePayments = (state: RootState) => state.userInfo.ENABLE_PAYMENT
export const getEnableCCWithdraw = (state: RootState) => state.userInfo.ENABLE_CREDIT_CARD_WITHDRAW
export const getAuthorized = (state: RootState) => state.auth.isAuthorized
export const getScrollToTop = (state: RootState) => state.table.scrollToTop
export const getEnableWebTrader = (state: RootState) => state.userInfo.ENABLE_WEB_TRADER
export const getDemoAccountsAllowed = (state: RootState) => state.userInfo.ALLOW_DEMO_ACCOUNTS;
export const getLogo = (state: RootState) => state.visual.properLogo;
export const getEnableLK = (state: RootState) => state.userInfo.ENABLE_LK;
export const getIsSuperAdmin = (state: RootState) => state.userInfo.IS_SUPER_ADMIN;
export const getVoipPrefixes = (state: RootState) => state.userInfo.VOIP_PREFIXES;
export const getVoipStatus = (state: RootState) => state.userInfo.VOIP_STATUS;
