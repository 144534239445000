import { getCountryList } from 'api/country';
import classNames from 'classnames/bind';
import Button from 'components/ui/Button';
import moment from 'moment';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import { CaretDown, Trash } from 'phosphor-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getDictionaryMethod } from '../../../../api/managements/operators/index';
import {
  activationOperatorMethod,
  deleteOperator,
  getOperatorMethod,
  resetOperatorPassword,
} from '../../../../api/operator/index';
import LoaderButton from '../../../../components/ui/LoaderButton/index.js';
import { getShortUuid } from '../../../../utils/getShortUuid.js';
import { notify } from '../../../../utils/notify.tsx';
import { usePermission } from '../../../../utils/usePermission.tsx';
import AddDepartmentList from './AddDepartmentList/index';
import BranchesBlock from './BranchesBlock/BranchesBlock';
import styles from './operator-page.module.scss';
import HierarchyForm from './OperatorForms/HierarchyForm';
import OperatorPersonalInfoForm from './OperatorForms/OperatorPersonalInfoForm';
import OperatorChangePasswordPopup from './OperatorPopups/OperatorChangePasswordPopup';
import Feed from './OperatorTabs/Feed/Feed';
import SalesRules from './OperatorTabs/SalesRules/index.js';
import Registered from './OperatorSection/Registered';
import { CountryListValue } from 'models/Countries.js';
import { SalesRuleShort } from 'models/Management/SalesRules/Rule.js';
import { ManagementOperator } from 'models/Management/Operators/Operator.js';
import PopupYesOrNo from 'components/ui/PopupYesOrNo/PopupYesOrNo.tsx';

const cx = classNames.bind(styles);

const OperatorPage = () => {
  const navigate = useNavigate();

  const { permissionGiven: PermissionActivationProfileOperator } = usePermission(
    'admin.admin-user.activation',
  );
  const { permissionGiven: PermissionShowOperator } = usePermission('admin.admin-user.show', true);
  const { permissionGiven: PermissionChangePassword } = usePermission(
    'admin.admin-user.change-password-for-other',
  );
  const { permissionGiven: PermissionViewOperatorLogs } = usePermission('admin.logs.admin-user');
  const { permissionGiven: PermissionViewListBranch } = usePermission('admin.branch.index');
  const { permissionGiven: PermissionViewDepartment } = usePermission('admin.department.view');
  const { permissionGiven: PermissionDeleteOperator } = usePermission('admin.admin-user.delete');

  const params = useParams();

  const [nameItem, setItemName] = useState('');
  const [operatorData, setOperatorData] = useState<ManagementOperator>();
  
  const [openAccStatus, setOpenAccStatus] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingResetPass, setIsLoadingResetPass] = useState(false);
  const [countryListArr, setCountryListArr] = useState<CountryListValue[]>([]);
  const [statusList, setStatusList] = useState<string[]>([]);

  const [salesRulesList, setSalesRulesList] = useState<SalesRuleShort[]>([]);

  const title = 'Operator - ' + nameItem;
  const dropMenu = useRef(null);
  const componentMounted = useRef(true);

  const fetchStatusList = () => {
    getDictionaryMethod().then((res) => {
      if (res.status === 200) {
        let arrFilter = res.data.operator_statuses.filter((item) => item !== operatorData.status);
        setStatusList(arrFilter);
      }
    });
  };

  const changedStatus = (status) => {
    setOpenAccStatus(!openAccStatus);
    let data = {
      activation: status === 'active' ? 1 : 0,
    };
    activationOperatorMethod(operatorData.id, data)
      .then((res) => {
        if (res.status === 200) {
          getOperator(params);
          notify({
            type: 'success',
            message: 'status changed',
            timeOut: 3000,
          });
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response.data.message,
          timeOut: 3000,
        });
      });
  };

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getCountryList().then((res) => {
      if (res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.id,
          label: opt.name,
          iso_3166_2: opt.iso_3166_2,
        }));
        setCountryListArr([...opt]);
      }
    });
  }, []);

  const onResetPassword = () => {
    let data = {
      email: operatorData.email,
    };
    setIsLoadingResetPass(true);
    resetOperatorPassword(data)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response.data.message,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoadingResetPass(false);
      });
  };

  const getOperator = (params?) => {
    getOperatorMethod(params.id)
      .then((res) => {
        if (res.status === 200) {
          setOperatorData(res.data.data);
          setSalesRulesList(res.data.data.sale_rules);
          setIsLoading(false);
          setItemName(res.data.data.first_name + ' ' + res.data.data.last_name);
        }
      })
      .catch((err) => {
        console.log (err)
      });
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    getOperator(params);
  }, [params]);

  useEffect(() => {
    operatorData && fetchStatusList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [operatorData, PermissionShowOperator]);

  const accountStatus = () => (
    <>
      {!isLoading && (
        <>
          <div className={cx('client-info__col-title')}>Account status</div>
          <div className={cx('client-info__col-content')}>
            <div className={cx('info-block')}>
              <div className={cx('info-block__value')} ref={dropMenu}>
                <div className={cx('statusValue')}>
                  <div className={cx('statusValue__value', operatorData.status)}>
                    <span
                      className={cx('status_span')}
                      onClick={() =>
                        PermissionActivationProfileOperator
                          ? setOpenAccStatus(!openAccStatus)
                          : null
                      }
                    >
                      {operatorData && operatorData.status ? operatorData.status : '---'}
                    </span>
                    <div
                      className={cx('value-icon', openAccStatus ? 'active' : '')}
                      onClick={() =>
                        PermissionActivationProfileOperator
                          ? setOpenAccStatus(!openAccStatus)
                          : null
                      }
                    >
                      <CaretDown size={16} />
                    </div>
                  </div>
                  <div className={cx('statusValue__descr')}>
                    {operatorData.status_updated_at && (
                      <span>
                        since{' '}
                        {moment(operatorData.status_updated_at * 1000).format(
                          'DD.MM.YYYY HH:mm:ss',
                        )}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className={cx('info-block__action-container', openAccStatus ? 'open' : '')}>
                <ul className={cx('accActionsList')}>
                  {statusList.map((item, ind) => {
                    return (
                      <li
                        className={item === 'active' ? 'unblock' : 'blocked'}
                        key={ind}
                        onClick={() => changedStatus(item)}
                      >
                        {item}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

  const personalInfo = () => (
    <div className={cx('personalInfo')}>
      <div className={cx('personalInfo__col')}>
        <div className={cx('personalInfo__col-title')}>Personal information</div>
        <div className={cx('personalInfo__col-content')}>
          <div>
            <b>First name: </b>
            {operatorData && operatorData.first_name}
          </div>
          <div>
            <b>Last name: </b>
            {operatorData && operatorData.last_name}
          </div>
          <div>
            <b>Email: </b>
            {operatorData && operatorData.email ? operatorData.email : '---'}
          </div>
          <div>
            <b>Phone number: </b>
            {operatorData && operatorData.phone ? operatorData.phone : '---'}
          </div>
          {operatorData && operatorData.email_verified_at ? (
            <div>
              <b>Registration date: </b>
              <span>{moment.unix(operatorData.created_at).format('DD.MM.YYYY')}</span>{' '}
              <span>{moment.unix(operatorData.created_at).format('HH:mm')}</span>
            </div>
          ) : (
            ''
          )}
          <div>
            <b>SIP account: </b>
            {operatorData && operatorData.sip_account ? operatorData.sip_account : '---'}
          </div>
        </div>
      </div>

      <div className={cx('personalInfo__col', 'acquisitionStatus')}>
        <div className={cx('personalInfo__col-title')}>Additional information</div>
        <div className={cx('personalInfo__col-content')}>
          <div className={cx('personalInfo__col-title')}>Departments</div>
          {operatorData.departments.length > 0 &&
            operatorData.roles_with_department.map((item) => {
              return (
                <div key={item.id}>
                  <div className={cx('acquisitionStatus__block', 'sales')}>
                    <div className={cx('acquisitionStatus__block-col')}>
                      <div className={cx('acquisitionStatus__info')}>
                        <div className={cx('acquisitionStatus__info-row')}>
                          <b>{item.department?.name}</b>
                        </div>
                        <div className={cx('acquisitionStatus__info-row')}>
                          <span>{item.name}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );

  return (
    <DashboardLayout>
      {!isLoading && (
        <Page>
          <div className={cx('container')}>
            <div className={cx('container__header')}>
              <div className={cx('client')}>
                <div className={cx('client__name')}>{nameItem}</div>
                <div
                  className={cx('client__identificator')}
                  onClick={() => {
                    copyToBuffer(operatorData.uuid);
                  }}
                >
                  {getShortUuid(operatorData.uuid)}
                </div>
              </div>
              <div className={cx('action')}>
                <LoaderButton
                  buttonType="outline"
                  showSpinner={isLoadingResetPass}
                  spinnerStyle={{
                    color: 'var(--main-color)',
                    left: '50%',
                    transformX: ' translate(-50%)',
                  }}
                  buttonText="Reset password"
                  type="submit"
                  onClick={() => onResetPassword()}
                />
                {PermissionChangePassword ? (
                  <OperatorChangePasswordPopup
                    updateComponent={() => getOperator()}
                    userId={params.id}
                    triggerBtn={
                      <Button
                        buttonType="outline"
                        buttonText="Change password"
                        onClick={() => setShowNotes(!showNotes)}
                      />
                    }
                  />
                ) : null}
                {PermissionDeleteOperator ? (
                  <div
                    style={{
                      display: 'flex',
                      marginLeft: 12,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <PopupYesOrNo
                      onClick={() => {
                        deleteOperator(operatorData.id).then(() =>
                          navigate('/management-operators'),
                        );
                      }}
                      headerTitle={'Delete operator'}
                      bodyText={`Are you sure that you want to delete operator ${operatorData.first_name} ${operatorData.last_name}?`}
                      triggerBtn={<Trash size={20} color="#ff0000" style={{ cursor: 'pointer' }} />}
                    />
                  </div>
                ) : null}
              </div>
            </div>
            <div className={cx('container__block')}>
              <div className={cx('client-info')}>
                <div className={cx('client-info__col')}>{accountStatus()}</div>
                <div className={cx('client-info__col')}>
                  {<Registered operatorData={operatorData} />}
                </div>
              </div>
            </div>
            <div className={cx('container__block')}>{personalInfo()}</div>
            <div className={cx('container__block')}>
              <div className={cx('profile__tabs')}>
                <Tabs className={cx('tabs')}>
                  <TabList>
                    <Tab>Profile</Tab>
                    <Tab>Sales Rules</Tab>
                    {PermissionViewOperatorLogs ? <Tab>Feed</Tab> : null}
                  </TabList>

                  <TabPanel>
                    <div className={cx('tab-panel')}>
                      <div className={cx('tab-panel__content')}>
                        <div className={cx('tab-panel__content-col', 'w100')}>
                          <div className={cx('tab-panel__content-box')}>
                            <div className="form-wrap">
                              <OperatorPersonalInfoForm
                                formData={operatorData}
                                countryList={countryListArr}
                                updateComponent={() => getOperator(params)}
                              />
                            </div>
                            <div className="form-wrap">
                              {PermissionViewDepartment ? (
                                <AddDepartmentList
                                  operatorData={operatorData}
                                  list={operatorData.roles_with_department}
                                  updateComponent={() => getOperator(params)}
                                />
                              ) : null}
                            </div>
                            <div className="form-wrap">
                              <HierarchyForm
                                formData={operatorData}
                                updateComponent={() => getOperator(params)}
                              />
                            </div>
                            <div className="form-wrap">
                              {PermissionViewListBranch ? (
                                <BranchesBlock
                                  operatorData={operatorData}
                                  list={operatorData.branches}
                                  role={operatorData.role}
                                  updateComponent={() => getOperator(params)}
                                />
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <SalesRules salesRulesList={salesRulesList} />
                  </TabPanel>
                  {PermissionViewOperatorLogs ? (
                    <TabPanel>
                      <Feed />
                    </TabPanel>
                  ) : null}
                </Tabs>
              </div>
            </div>
          </div>
        </Page>
      )}
    </DashboardLayout>
  );
};

export default OperatorPage;
