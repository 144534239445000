import { getCountryList } from 'api/country';
import classNames from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import React, { useEffect, useRef, useState } from 'react';
import { findOfficesMethod } from '../../../api/managements/offices/index';
import Button from '../../../components/ui/Button/index.js';
import OfficesTable from '../../../components/ui/Table/OfficesTable/index.js';
import TablePagination from '../../../components/ui/TablePagination/index.js';
import { usePermission } from '../../../utils/usePermission.tsx';
import Page from '../../Page/index.js';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import OfficesFilter from './Filter/index.js';

import styles from './offices-page.module.scss';
import CreateOfficesPopup from './OfficesPopups/CreateOfficesPopup';
import { notify } from '../../../utils/notify.tsx';
import { getTablePageParams } from '../../../utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { CountryListValue } from 'models/Countries.js';
import { HierarchyOffice } from 'models/Hierarchy/HierarchyData.js';
import { ManagementOfficesFilter } from 'models/Management/Offices/Filter.js';
import { useAppDispatch } from "../../../store";
import { toggleScrollToTop } from "../../../store/slice/tableSlice.ts";

const cx = classNames.bind(styles);

const OfficesPage = () => {

  const { permissionGiven: PermissionList } = usePermission('admin.office.index');
  const { permissionGiven: PermissionCreate } = usePermission('admin.office.create');

  const dispatch = useAppDispatch();
  const [rules, setRules] = useState<HierarchyOffice[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [countryOpt, setCountryOpt] = useState<CountryListValue[]>([]);
  const [filters, setFilters] = useState<ManagementOfficesFilter>(null);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const componentMounted = useRef(false);

  document.title = 'Offices';

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      getOffices();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [PermissionList]);

  useEffect(() => {
    fetchCountryList();
  }, [])

  const fetchCountryList = () => {
    getCountryList().then((res) => {
      if (res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.id,
          label: opt.name,
          iso_3166_2: opt.iso_3166_2,
        }));
        setCountryOpt([...opt]);
      }
    })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      });
  };

  const getOffices = (options?, data?) => {
    setIsListLoading(true);

    let postData;

    findOfficesMethod(getTablePageParams(options, perPageCount, tableLinks), data || postData || filters)
      .then((res) => {
        if (componentMounted.current) {
          setRules(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
          setIsListLoading(false);
        }
      })
      .catch((err) => {
        setIsListLoading(false);
        console.log(err);
      });
  };

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>
                  <strong>{tableMeta?.total || 0}</strong> Offices
                </span>
                <span></span>
              </div>
              <div className={cx('header-controls')}>
                {
                  PermissionCreate ?
                    <CreateOfficesPopup
                      updateComponent={() => getOffices()}
                      countryList={countryOpt}
                      actionType={SALES_RULES_ACTION_TYPE.CREATE}
                      triggerBtn={
                        <Button
                          buttonText={'+ Add office'}
                          onClick={() => null}
                          buttonType={'outline'}
                        />
                      }
                    /> : null
                }
              </div>
            </div>
          </div>
          <div className={cx('container__filters-block')}>
            <OfficesFilter
              countryList={countryOpt}
              onSubmit={(data) => {
                setFilters(data);
                getOffices({ navigate: 'first', perPage: data?.filter?.limit }, data);
              }}
              reloadFilters={isListLoading}
              queryMessage={queryResponseMessage}
                          />
          </div>
          <div className={cx('container__block')}>
            {
              PermissionList ?
                <OfficesTable
                  data={rules}
                  countryList={countryOpt}
                  perPage={rules?.length}
                  showLoader={isListLoading}
                  modifyDataHandler={(callback) => getOffices()}
                /> : null
            }
            {rules?.length ? (
              <TablePagination
                goToFirstPage={() => {
                  getOffices({ navigate: 'first' });
                  dispatch(toggleScrollToTop())
                }}
                goToPrevPage={() => {
                  getOffices({ navigate: 'prev' });
                  dispatch(toggleScrollToTop())
                }}
                goToNextPage={() => {
                  getOffices({ navigate: 'next' });
                  dispatch(toggleScrollToTop())
                }}
                goToLastPage={() => {
                  getOffices({ navigate: 'last' });
                  dispatch(toggleScrollToTop())
                }}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getOffices({ perPage: value });
                }}
                goToSelectedPage={(page) => {
                  getOffices({ page });
                }}
                // hidePageSelect={true}
                isFirstPageDisable={() => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default OfficesPage;
