import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './crypto-wallet-table.module.scss';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import { copyToBuffer } from 'utils/copyToBuffer';
import { TABLE_WALLET_BINDINGS } from 'constants/table';
import { Pencil } from 'phosphor-react';
import { Link } from 'react-router-dom';
import { getShortUuid } from 'utils/getShortUuid';
import moment from 'moment';
import EditPinToUserPopup
  from "../../../../pages/CustomerService/CryptoPayments/CryptoPaymentsPopups/EditPinToUserPopup";
import {
  ICryptoWalletBindingsTableProps
} from "../../../../models/components/Table/CryptoWalletBindingsTable/CryptoWalletBindingsTable.ts";
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';

const cx = classNames.bind(styles);

const CryptoWalletBindingsTable: FC<ICryptoWalletBindingsTableProps> = ({
  data,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
  permission
}) => {
  // const columns = React.useMemo(() => TABLE_WALLET_BINDINGS, []);
  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_WALLET_BINDINGS", TABLE_WALLET_BINDINGS);

  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, user, pinned, available_from, available_to, created_at } = item;
    return {
      // id: <span>{user.id}</span>,
      user: (
        <div className={cx('wallet__user')}>
          <Link to={`/client/${user?.id}`} className={cx('logo')}>
            <span>{user?.user_profile?.first_name + ' ' + user?.user_profile?.last_name}</span>
          </Link>
          <div className={cx('wallet__user-id')}>
            <span
              onClick={(): void => {
                copyToBuffer(user?.uuid);
              }}
            >
              {getShortUuid(user?.uuid)}
            </span>
          </div>
          <span>{user?.email}</span>
        </div>
      ),
      bind: (
        <div className={cx('wallet__desc')}>
          <div className={cx('wallet__desc__col-content')}>
            {pinned ? (
              <span className={cx('pinned', 'strong')}>Pinned</span>
            ) : (
              <>
                <div className={cx('available', 'strong')}>AVAILABLE</div>
                <div>from: {moment.unix(available_from).format('DD.MM.YYYY HH:mm')}</div>
                <div>to: {moment.unix(available_to).format('DD.MM.YYYY HH:mm')}</div>
              </>
            )}
          </div>
        </div>
      ),
      action: (
        <div className={cx('wallet__actions')}>
          {item && permission ? (
            <EditPinToUserPopup
              bindInfo={item}
              updateComponent={() => modifyDataHandler?.()}
              triggerBtn={<Pencil size={20} />}
              onCloseModal={() => console.log('a')}
            />
          ) : (
            ''
          )}
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginTop: "12px",
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('wallet-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
      saveColumnOrder={saveColumnOrder}
    />
    </>
  );
};

export default CryptoWalletBindingsTable;
