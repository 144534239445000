import React, { FC, useState } from 'react';
import classNames from 'classnames/bind';

import styles from './passwordInput.module.scss';
import { useController } from 'react-hook-form';
import Tooltip from '../Tooltip/Tooltip';
import { Eye, EyeSlash } from 'phosphor-react';
import { IPasswordInputProps } from "../../../models/components/PasswordInput/PasswordInput.ts";

const cx = classNames.bind(styles);

const PasswordInput: FC<IPasswordInputProps> = (props: React.PropsWithChildren<any>) => {
  const { field } = useController(props);

  const [showPassword, setShowPassword] = useState<boolean>(false);

  const handleShowPassword = (): void => {
    setShowPassword(!showPassword);
  };

  return (
    <>
      <div className={cx('label-wrap')}>
        {props.label && (
          <label htmlFor={props.id} className={props.errors ? cx('label', 'error') : cx('label')}>
            {props.label}
            {props.rules && props.rules.required ? <span className={cx('required')}>*</span> : ''}
          </label>
        )}
        {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
      </div>
      <div className={props.errors ? cx('input-wrap', 'error') : cx('input-wrap')}>
        <input
          {...field}
          className={props.errors ? cx('input', 'error') : cx('input')}
          type={showPassword ? 'text' : 'password'}
          placeholder={props.placeholder}
          value={field.value}
        />
        <span className={cx('input-icon')}>
          {!showPassword ? (
            <Eye size={24} onClick={handleShowPassword} />
          ) : (
            <EyeSlash size={24} onClick={handleShowPassword} />
          )}
        </span>
        {props.errors && <p className={cx('errorName')}>{props.errors.message}</p>}
      </div>
    </>
  );
};

export default PasswordInput;
