import { getSettings } from 'api/auth';
import { useEffect } from 'react';
import { useAppDispatch } from 'store';
import { setInstanceSettings } from 'store/slice/userInfoSlice';

export const useInstanceSettings = (auth: boolean) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (auth) {
      getSettings()
        .then((res) => {
          if (res.status === 200) {
            dispatch(setInstanceSettings(res.data));
          }
        })
        .catch(console.log);
    }
  }, [auth]);
};
