import { getCountryList } from 'api/country';
import { getLeadInfo } from 'api/leads';
import { fetchPinnedNotesList } from 'api/notes';
import classNames from 'classnames/bind';
import Button from 'components/ui/Button';
import Notes from 'components/ui/Notes/Notes';
import Preloader from 'components/ui/Preloader/Preloader';
import moment from 'moment';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import { Eye, EyeSlash, Trash } from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate, useParams } from 'react-router-dom';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import { getTimeAgo } from 'utils/getTimeAgo';
import CheckRole from '../../../utils/RoleComponent.tsx';
import { usePermission } from '../../../utils/usePermission.tsx';
import styles from './lead-page.module.scss';
import LeadAdressForm from './LeadForms/LeadAdressForm';
import LeadContactForm from './LeadForms/LeadContactForm';
import LeadPersonalInfoForm from './LeadForms/LeadPersonalInfoForm';
import ChangeStatusPopup from './LeadPopups/ChangeStatusPopup';
import PromotePopup from './LeadPopups/PromotePopup';
import Feed from './LeadTabs/Feed/Feed';
import NotesTabs from './LeadTabs/NotesTabs/NotesTabs';
import { TableLinks, TableMeta } from 'models/Table';
import { Lead } from 'models/Leads/Lead';
import { ICountry } from 'models/Countries';
import { LeadNote } from 'models/Leads/LeadNotes';
import { deleteLead } from '../../../api/leads';
import { notify } from "../../../utils/notify";
import PopupYesOrNo from "../../../components/ui/PopupYesOrNo/PopupYesOrNo";

const cx = classNames.bind(styles);

const LeadPage = () => {
  const { permissionGiven: PermissionShowLead } = usePermission('admin.lead.show', true);
  const { permissionGiven: PermissionViewDocumentLogs } = usePermission('admin.logs.document');
  const { permissionGiven: PermissionPromote } = usePermission('admin.lead.promote');
  const { permissionGiven: PermissionShowPersonalInfo } = usePermission('admin.lead.show-personal-info');
  const { permissionGiven: PermissionShowAddress } = usePermission('admin.lead.show-address');
  const { permissionGiven: PermissionShowContacts } = usePermission('admin.lead.show-contacts');
  const { permissionGiven: PermissionPhone } = usePermission('admin.lead.show-phone');
  const { permissionGiven: PermissionEmail } = usePermission('admin.lead.show-email');
  const { permissionGiven: PermissionMobile } = usePermission('admin.lead.show-mobile');
  const { permissionGiven: PermissionNotesCreate } = usePermission('admin.notes.create');
  const { permissionGiven: PermissionDelete } = usePermission('admin.lead.delete')

  const params = useParams();
  const navigate = useNavigate();

  const [clientName, setClientName] = useState('');
  const [clientData, setClientData] = useState<Lead>();
  const [isListLoading, setIsListLoading] = useState(true);
  const [showNotes, setShowNotes] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [countryListArr, setCountryListArr] = useState<ICountry[]>([]);

  const [showPhone, setShowPhone] = useState(false);
  const [showMobile, setShowMobile] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [pinnedNotesList, setPinnedNotesList] = useState<LeadNote[]>([]);
  const [notesLoading, setNotesLoading] = useState(true);

  const [meta, setMeta] = useState<TableMeta>();
  const [links, setLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const title = 'Lead - ' + clientName;

  const componentMounted = useRef(true);

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    if (PermissionShowLead) {
      getCountryList().then((res) => setCountryListArr(res.data.data));
    }
  }, [PermissionShowLead]);

  const getClient = (params) => {
    setIsListLoading(true);

    getLeadInfo(params.id)
      .then((res) => {
        setClientData(res.data.data);
        setIsLoading(false);
        setClientName(res.data.data.name + ' ' + res.data.data.surname);
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  const getPinnedNotes = (params?, isNextCall?) => {
    setNotesLoading(true);

    let searchParams: string | number = '';
    if (isNextCall) {
      let arr = links.next.split('=');
      searchParams = links && links.next ? links.next.split('=')[arr.length - 1] : 1;
    }
    fetchPinnedNotesList(params.id, {
      page: links?.next ? searchParams : 1,
      is_pinned: 1,
      per_page: 10,
    })
      .then((res) => {
        if (isNextCall) {
          setPinnedNotesList([...pinnedNotesList, ...res.data.data]);
        } else {
          setPinnedNotesList(res.data.data);
        }
        setMeta(res.data.meta);
        setLinks(res.data.links);
        setPerPageCount(res.data.meta.per_page);
        setNotesLoading(false);
      })
      .catch((err) => {
        setNotesLoading(false);
      });
  };

  const onClickDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    deleteLead(params.id)
      .then(() => {
        navigate('/search-leads');
      })
      .catch((error) => {
        console.log(error)
        notify({
          type: 'error',
          message: 'Lead not deleted',
          timeOut: 3000,
        });
      })
  }

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    getClient(params);
    getPinnedNotes(params);
  }, [params]);

  const accountStatus = () => (
    <>
      {!isLoading && (
        <>
          <div className={cx('client-info__col-title')}>Account status</div>
          <div className={cx('client-info__col-content')}>
            <div className={cx('info-block')}>
              <div className={cx('info-block__value')}>
                <div className={cx('statusValue')}>
                  <div className={cx('statusValue__value', clientData.status)}>
                    {clientData && clientData.status ? clientData.status : '---'}
                  </div>
                  <div className={cx('statusValue__descr')}>
                    since{' '}
                    {clientData && clientData.change_status
                      ? moment(clientData.change_status.converted_at * 1000).format(
                        'DD.MM.YYYY HH:mm:ss',
                      )
                      : moment(clientData.created_at * 1000).format('DD.MM.YYYY HH:mm:ss')}
                    {clientData && clientData.client?.uuid ? (
                      <div
                        className={cx('client__identificator')}
                        onClick={() => {
                          copyToBuffer(clientData.client?.uuid);
                        }}
                      >
                        by {getShortUuid(clientData.client?.uuid)}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );

  const registered = () => (
    <>
      <div className={cx('client-info__col-title')}>Registered</div>
      <div className={cx('client-info__col-content')}>
        <div className={cx('strong')}>
          {clientData && clientData.created_at ? getTimeAgo(clientData.created_at) : '---'}
        </div>
        <div>
          on{' '}
          {clientData && clientData.created_at
            ? moment(clientData.created_at * 1000).format('DD.MM.YYYY HH:mm')
            : '---'}
        </div>
      </div>
    </>
  );

  const personalInfo = () => (
    <div className={cx('personalInfo')}>
      <div className={cx('personalInfo__col')}>
        <div className={cx('personalInfo__col-title')}>Personal information</div>
        <div className={cx('personalInfo__col-content')}>
          {
            PermissionShowPersonalInfo ?
              <>
                <div>
                  <b>Date of birth: </b>
                  {clientData && clientData.birthDate
                    ? moment(clientData.birthDate * 1000).format('DD.MM.YYYY')
                    : '---'}
                </div>
                <div>
                  <b>Gender: </b>
                  {clientData && clientData.gender ? clientData.gender : '---'}
                </div>
              </> : null
          }
          {
            PermissionShowContacts ?
              <>
                {
                  PermissionPhone ?
                    <div>
                      <b>Phone: </b>
                      {showPhone
                        ? clientData && clientData.phone
                          ? clientData.phone
                          : '---'
                        : '***********'}
                      <CheckRole permissionType='admin.client.view-phone' roleType={['Super Admin']}>
              <span className={cx('showContent')} onClick={() => setShowPhone(!showPhone)}>
                {!showPhone ? <Eye size={17} /> : <EyeSlash size={17} />}
              </span>
                      </CheckRole>
                    </div> : null
                }
                {
                  PermissionMobile ?
                    <div>
                      <b>Mobile: </b>
                      {showMobile
                        ? clientData && clientData.mobile
                          ? clientData.mobile
                          : '---'
                        : '***********'}
                      <CheckRole permissionType='admin.client.view-phone' roleType={['Super Admin']}>
              <span className={cx('showContent')} onClick={() => setShowMobile(!showMobile)}>
                {!showMobile ? <Eye size={17} /> : <EyeSlash size={17} />}
              </span>
                      </CheckRole>
                    </div> : null
                }
                {
                  PermissionEmail ?
                    <div>
                      <b>E-mail: </b>
                      {showEmail
                        ? clientData && clientData.email
                          ? clientData.email
                          : '---'
                        : '***********'}
                      <CheckRole permissionType='admin.client.view-email' roleType={['Super Admin']}>
              <span className={cx('showContent')} onClick={() => setShowEmail(!showEmail)}>
                {!showEmail ? <Eye size={17} /> : <EyeSlash size={17} />}
              </span>
                      </CheckRole>
                    </div> : null
                }
              </> : null
          }
          {
            PermissionShowAddress ?
              <>
                <div className={cx('country')}>
                  <b>Country: </b>
                  {clientData.country ? (
                    <i
                      className={cx('icon')}
                      style={{ backgroundImage: `url(${clientData.country?.flag})` }}
                    />
                  ) : (
                    ''
                  )}
                  {clientData && clientData.country?.name ? clientData.country?.name : ' ---'}
                </div>
                {clientData && clientData.city ? (
                  <div>
                    <b>City: </b>
                    {clientData.city}
                  </div>
                ) : (
                  ''
                )}
              </> : null
          }
          <div>
            <b>Language: </b>
            {clientData && clientData.language ? clientData.language.name : '---'}
          </div>
          {clientData && clientData.source ? (
            <div>
              <b>Source: </b>
              {clientData.source}
            </div>
          ) : (
            ''
          )}
          {clientData && clientData.affiliate ? (
            <div>
              <b>Affiliate: </b>
              {getShortUuid(clientData.affiliate)}
            </div>
          ) : (
            ''
          )}
        </div>
      </div>

      <div className={cx('personalInfo__col', 'pinnedNotes')}>
        <div className={cx('personalInfo__col-title')}>Pinned notes</div>
        <div
          className={cx('personalInfo__col-content')}
          id='scrollableDiv'
          style={{ height: '400px', overflow: 'hidden' }}
        >
          {pinnedNotesList.length > 0 && links && meta ? (
            <InfiniteScroll
              dataLength={pinnedNotesList.length}
              next={() => getPinnedNotes(params, true)}
              hasMore={!!links.next}
              loader={
                <div style={{ position: 'relative', height: '50px' }}>
                  <Preloader />
                </div>
              }
              height={290}
              scrollableTarget='scrollableDiv'
            >
              {pinnedNotesList.map((note) => (
                <div className={cx('pinnedNote')} key={note.id}>
                  <div className={cx('pinnedNote__title')}>{note.subject}</div>
                  <div className={cx('pinnedNote__operator')}>
                    {`by ${note && note.operator ? note.operator.first_name : ''} ${
                      note && note.operator && note.operator.last_name
                        ? note.operator.last_name
                        : ''
                    }`}
                  </div>
                  <div className={cx('pinnedNote__create')}>
                    {moment.unix(note.create_at).format('DD.MM.YYYY HH:mm')} to{' '}
                    <span
                      className='copuToBuffer'
                      onClick={() => {
                        copyToBuffer(note.to);
                      }}
                    >
                      {getShortUuid(note.to)}
                    </span>
                  </div>
                  <div className={cx('pinnedNote__body')}>{note.body}</div>
                </div>
              ))}
            </InfiniteScroll>
          ) : null}
        </div>
      </div>
    </div>
  );

  return (
    <DashboardLayout>
      {!isLoading && (
        <Page>
          <div className={cx('container')}>
            <div className={cx('container__header')}>
              <div className={cx('client')}>
                <div className={cx('client__name')}>
                  {clientName}
                  {/* TODO: USELESS FUNCTIONAL; */}
                  {/* {clientData && clientData.birthDate !== null
                    ? `(${Math.floor(
                        moment(new Date()).diff(
                          moment(clientData.birthDate * 1000).format('MM/DD/YYYY'),
                          'years',
                          true,
                        ),
                      )})`
                    : '(?)'} */}
                </div>
                <div
                  className={cx('client__identificator')}
                  onClick={() => {
                    copyToBuffer(clientData.uuid);
                  }}
                >
                  {getShortUuid(clientData.uuid)}
                </div>
              </div>
              <div className={cx('action')}>
                <ChangeStatusPopup
                  updateComponent={() => getClient(params)}
                  user={clientData}
                  triggerBtn={<Button buttonType='outline' buttonText='Change status' />}
                />
                {PermissionNotesCreate ?
                  <Button
                    buttonType='outline'
                    buttonText='Add note'
                    onClick={() => setShowNotes(!showNotes)}
                  />
                  : null
                }
                {/*<Button*/}
                {/*  buttonType="outline"*/}
                {/*  icon={<ArrowsCounterClockwise size={32} />}*/}
                {/*  onClick={() => {*/}
                {/*    getClient(params);*/}
                {/*    getPinnedNotes(params);*/}
                {/*  }}*/}
                {/*/>*/}
                {/*{clientData && clientData.status === 'new' ? (*/}
                {/*  <PromotePopup*/}
                {/*    updateComponent={() => getClient(params)}*/}
                {/*    user={clientData}*/}
                {/*    countryList={countryListArr}*/}
                {/*    triggerBtn={<Button buttonType='outline' buttonText='Promote to client' />}*/}
                {/*    onCloseModal={() => console.log('a')}*/}
                {/*  />*/}
                {/*) : (*/}
                {/*  ''*/}
                {/*)}*/}
                {
                  PermissionPromote ?
                    <PromotePopup
                      updateComponent={() => getClient(params)}
                      user={clientData}
                      countryList={countryListArr}
                      langList={[]}
                      triggerBtn={<Button buttonType='outline' buttonText='Promote to client' />}
                    /> : null
                }
                {showNotes && (
                  <Notes
                    userId={clientData.id}
                    handleClose={(show) => {
                      setShowNotes(show);
                    }}
                    type='lead'
                    updateComponent={() => {
                      getPinnedNotes(params);
                    }}
                  />
                )}
                {
                  PermissionDelete && (
                    <PopupYesOrNo
                      onClick={onClickDelete}
                      headerTitle={'Delete lead'}
                      bodyText={'Are you sure you want to delete this lead?'}
                      triggerBtn={
                        <Button
                          buttonType="delete"
                          icon={<Trash />}
                        />
                      }
                    />
                  )
                }
              </div>
            </div>
            <div className={cx('container__block')}>
              <div className={cx('client-info')}>
                <div className={cx('client-info__col')}>{accountStatus()}</div>
                <div className={cx('client-info__col')}>{registered()}</div>
              </div>
            </div>
            {
              PermissionShowPersonalInfo ?
                <div className={cx('container__block')}>{personalInfo()}</div> : null
            }
            <div className={cx('container__block')}>
              <div className={cx('profile__tabs')}>
                <Tabs className={cx('tabs')}>
                  <TabList>
                    <Tab>Profile</Tab>
                    <Tab>Notes</Tab>
                    {PermissionViewDocumentLogs ?
                      <Tab>Feed</Tab>
                      : null
                    }
                  </TabList>

                  <TabPanel>
                    <div className={cx('tab-panel')}>
                      <div className={cx('tab-panel__content')}>
                        <div className={cx('tab-panel__content-col', 'w100')}>
                          <div className={cx('tab-panel__content-box')}>
                            <div className='form-wrap'>
                              {
                                PermissionShowPersonalInfo ?
                                  <LeadPersonalInfoForm
                                    formData={clientData}
                                    updateComponent={() => getClient(params)}
                                  /> : null
                              }
                            </div>
                          </div>
                          <div className={cx('tab-panel__content-box')}>
                            <div className='form-wrap'>
                              {
                                PermissionShowAddress ?
                                  <LeadAdressForm
                                    formData={clientData}
                                    countryList={countryListArr}
                                    updateComponent={() => getClient(params)}
                                  /> : null
                              }
                            </div>
                          </div>
                          <div className={cx('tab-panel__content-box')}>
                            <div className='form-wrap'>
                              {
                                PermissionShowContacts ?
                                  <LeadContactForm
                                    formData={clientData}
                                    updateComponent={() => getClient(params)}
                                  /> : null
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <NotesTabs />
                  </TabPanel>
                  {PermissionViewDocumentLogs ?
                    <TabPanel>
                      <Feed />
                    </TabPanel>
                    : null
                  }
                </Tabs>
              </div>
            </div>
          </div>
        </Page>
      )}
    </DashboardLayout>
  );
};

export default LeadPage;
