import { APIHierarchyGetBrandResponse, APIHierarchyGetDesksResponse, APIHierarchyGetOfficesResponse, APIHierarchyGetOperatorsResponse, APIHierarchyGetTeamResponse } from 'models/API/Hierarchy/Hierarchy';
import API from '../api';

export const getBrandMethod = () => API.get<APIHierarchyGetBrandResponse>(`api/v1/brands?per_page=10&page=1`);
export const getOfficesMethod = () => API.get<APIHierarchyGetOfficesResponse>(`api/v1/offices?per_page=100&page=1`);
export const getDeskForOfficeMethod = (params) =>
  API.get<APIHierarchyGetDesksResponse>(`api/v1/desks?per_page=100&page=1&office_id=${params}`);
export const getTeamForDeskMethod = (params) =>
  API.get<APIHierarchyGetTeamResponse>(`api/v1/teams?per_page=100&page=1&desk_id=${params}`);
export const getOperatorsMethod = (id) => API.get<APIHierarchyGetOperatorsResponse>(`api/v1/teams/${id}`);
export const getOperatorsForOfficeMethod = (id) => API.get<APIHierarchyGetOperatorsResponse>(`api/v1/offices/${id}`);
export const getOperatorsForDeskMethod = (id: string | number) => API.get<APIHierarchyGetOperatorsResponse>(`api/v1/desks/${id}`);
export const getOperatorsForTeamMethod = (id) => API.get<APIHierarchyGetOperatorsResponse>(`api/v1/teams/${id}`);
export const getOperatorsForBrandMethod = (id) => API.get<APIHierarchyGetOperatorsResponse>(`api/v1/brands/${id}`);
