import { ChatAvatar } from "../ChatAvatar/ChatAvatar";
import { UserTypeLabels, UserTypes } from "../../../../models/Chat";
import { unEscapeHtml } from "../../helpers/unscapeHtml";
import { ChatUserStatus } from "../ChatUserStatus/ChatUserStatus";
import './ChatDialogUser.scss'

type ChatDialogUserProps = {
	name: string,
	type: UserTypes,
	isOnline: boolean,
	isGroup: boolean
}

export const ChatDialogUser = ({ name, type, isOnline, isGroup }: ChatDialogUserProps) => {
	return (
		<div className="chat-dialog-user">
			<ChatAvatar name={name} status={false} className={isGroup ? 'group' : UserTypeLabels[type]}/>
			<div className="user-info">
				<div className="chat-dialog-user-name">{unEscapeHtml(name)}</div>
				{isOnline && !isGroup ? (
					<div className="chat-dialog-user-status">
						<ChatUserStatus/> <span>Active</span>
					</div>
				) : null}
			</div>
		</div>
	)
}