import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './../../client-page.module.scss';
import { ArrowsCounterClockwise, ArrowsClockwise } from 'phosphor-react';

import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import Select from 'components/ui/Select';
import { useParams } from 'react-router-dom';
import { notify } from 'utils/notify';
import DatePickerRange from 'components/ui/DatePickerRange';
import { getDepartamentList } from 'api/departament';
import { Col, Row } from 'react-bootstrap';
import { getUserNotesList, searchNotesListAll } from 'api/notes';
import moment from 'moment';
import NotesItem from './NotesItem';
import EditNotePopup from './EditNotePopup';
import NotesFilter from './NotesFilter';
import Preloader from 'components/ui/Preloader/Preloader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { usePermission } from '../../../../../utils/usePermission';
import { isFormChange } from '../../../../../utils/isFormChange';
import { TableLinks, TableMeta } from 'models/Table';
import { Sort } from 'models/Sort';

const cx = classNames.bind(styles);

type Props = {
  updateHeadNotes: () => unknown;
  permission: boolean;
}

const NotesTabs = (props: Props) => {
  const { updateHeadNotes, permission } = props;

  const { permissionGiven: PermissionList } = usePermission('admin.notes.list');

  const [departamentList, setDepartamentList] = useState([]);
  const [notesList, setNotesList] = useState([]);

  const [meta, setMeta] = useState<TableMeta | undefined>(undefined);
  const [links, setLinks] = useState<TableLinks | undefined>(undefined);
  const [perPageCount, setPerPageCount] = useState(10);
  const [savedFilters, setSavedFilters] = useState(null);

  const [isListLoading, setIsListLoading] = useState(false);

  const { id } = useParams();

  const componentMounted = useRef(false);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    setSavedFilters(data);
    setIsListLoading(true);
    fetchNotesList(data);
  };

  const getNotesByUser = () => {
    getUserNotesList(savedFilters, id)
      .then((res) => {
        if (res.status === 200) {
          setNotesList(res.data.data);
          setMeta(res.data.meta);
          setLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setIsListLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsListLoading(false);

          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  const fetchNotesList = (data?, isNextCall?) => {
    updateHeadNotes();
    setIsListLoading(true);

    const postData = {
      filter: {
        id: +id,
        type: 'user',
      },
    };

    if (data && data['department_id']) {
      postData.filter['department_ids'] = [data['department_id'].id];
    }

    if (data && data['created_at']) {
      postData.filter['created_at'] = {
        from: moment(data.created_at.from).format('YYYY-MM-DD'),
        to: moment(data.created_at.to).format('YYYY-MM-DD'),
      };
    }

    let searchParams = '';
    if (isNextCall) {
      searchParams = links && links.next ? `?${links.next.split('?').reverse()[0]}` : '';
    }

    searchNotesListAll(searchParams, postData)
      .then((res) => {
        if (res.status === 200) {
          if (isNextCall) {
            setNotesList([...notesList, ...res.data.data]);
          } else {
            setNotesList(res.data.data);
          }
          setMeta(res.data.meta);
          setLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setIsListLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsListLoading(false);

          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  const fetchDepartamentList = () => {
    setIsListLoading(true);
    getDepartamentList()
      .then((res) => {
        if (res.status === 200) {
          setDepartamentList(
            res.data.data.map((el) => ({
              id: el.id,
              name: el.name,
            })),
          );
          setIsListLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsListLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      fetchDepartamentList();
      fetchNotesList();
    }

    return () => {
      componentMounted.current = false;
    };
  }, [PermissionList]);

  return (
    <div className={cx('tab-panel', 'notes')}>
      <div className={cx('tab-panel__content')}>
        <div className={cx('tab-panel__header')}>
          <div className={cx('tab-panel__title')}>Notes</div>
          <div className={cx('tab-panel__action')}>
            {permission ?
              <EditNotePopup
                type='create'
                updateComponent={() => getNotesByUser()}
                userId={id}
                triggerBtn={<Button buttonText='+ Add note' buttonType='outline' onClick={null} />}
              />
              : null
            }
          </div>
        </div>
        <div className={cx('tab-panel__filter-box')}>
          <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col md={12} lg={6} xl={4}>
                <div className={cx('select-wrap')}>
                  <Select
                    name='department_id'
                    label='Department'
                    control={control}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    options={departamentList}
                    onChange={(data) => onSubmit(data)}
                  />
                </div>
              </Col>
              <Col md={12} lg={6} xl={4}>
                <DatePickerRange
                  name='created_at'
                  control={control}
                  label='Creation date range'
                  placeholder='Start date - End date'
                />
              </Col>
              <Col md={12} lg={12} xl={4} className={cx('actin-btn')}>
                <Button
                  buttonType='outline'
                  className={isListLoading ? 'reload-btn reload' : 'reload-btn'}
                  icon={<ArrowsClockwise size={18} />}
                  onClick={(e) => {
                    e.preventDefault();
                    onSubmit(getValues());
                  }}
                />
                <Button
                  buttonType='primary'
                  type='button'
                  buttonText='Reset'
                  disabled={!isFormChange(dirtyFields)}
                  onClick={() => {
                    reset({
                      department_id: null,
                      created_at: null,
                    });
                    fetchNotesList();
                  }}
                />
                <Button
                  buttonType='primary'
                  buttonText='Apply'
                  onClick={handleSubmit(onSubmit)}
                  disabled={!isFormChange(dirtyFields)}
                />
              </Col>
            </Row>
          </form>
        </div>
        {PermissionList ?
          <div className={cx('tab-panel-table')}>
            {isListLoading ? <Preloader /> : null}
            <div className={cx('notes-list', isListLoading ? 'p-0 blur' : '')}>
              {notesList.length > 0 && meta && links  ? (
                <InfiniteScroll
                  dataLength={notesList.length}
                  next={() => fetchNotesList(savedFilters, true)}
                  hasMore={!!links.next}
                  height={600}
                  scrollableTarget='scrollableDiv'
                  loader={<></>}
                >
                  {notesList.map((el) => (
                    <NotesItem
                      key={el.id}
                      noteItem={el}
                      updateNotesList={() => {
                        getNotesByUser();
                      }}
                    />
                  ))}
                </InfiniteScroll>
              ) : (
                <span className={cx('noItems')}>No items</span>
              )}
            </div>
          </div>
          : null
        }
      </div>
    </div>
  );
};

export default NotesTabs;
