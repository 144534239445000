import { useEffect, useRef, useState } from "react";
import { PaperPlaneRight } from "phosphor-react";
import { useAppDispatch, useAppSelector } from "../../../../store";
import Button from "../../../../components/ui/Button";
import { ChatInput } from "./ChatInput";
import {
    selectProcessedMessage,
    selectProcessedMessageMode,
    selectThread,
} from "../../../../store/slice/chat/chatSlice";
import { ChatFormProcessedMessage } from "./ChatFormProcessedMessage";
import { useEvent } from "../../helpers/useEvent";
import { ChatUploadInput } from "./ChatUploadInput";
import { sendFile, sendText, updateText } from "../../../../store/slice/chat/thunks";
import { ProcessedMessageMode } from "../../../../models/Chat";
import { handleReadThread } from "../../helpers/readThread";
import './ChatMessageForm.scss';

export const ChatMessageForm = () => {
    const dispatch = useAppDispatch();
    const thread = useAppSelector(selectThread);
    const processedMessageMode = useAppSelector(selectProcessedMessageMode);
    const processedMessage = useAppSelector(selectProcessedMessage);
    const threadId = thread?.id;

    const textAreaRef = useRef<HTMLTextAreaElement>(null);
    const [text, setText] = useState('');

    const fileMessageSubmitHandler = (file: File) => {
        dispatch(sendFile({ threadId, file }));
    };

    const textMessageSubmitHandler = (event) => {
        event.stopPropagation();
        handleReadThread(thread);

        if (!text.trim().length) return;

        setText('');
        textAreaRef.current?.focus();

        if (processedMessageMode === ProcessedMessageMode.IS_EDITING) {
            dispatch(updateText({ id: processedMessage.id, threadId, body: text }));
        } else {
            dispatch(sendText({ threadId, body: text }));
        }
    };

    const keyDownHandler = event => {
        if (event.key === 'Enter' && document.activeElement === textAreaRef.current) {
            event.preventDefault();
            textMessageSubmitHandler(event);
        }
    };

    useEvent('keydown', keyDownHandler);

    useEffect(() => {
        setText('');
        textAreaRef.current?.focus();
    }, [threadId]);

    useEffect(() => {
        if (processedMessageMode) {
            textAreaRef.current?.focus();

            if (processedMessageMode === ProcessedMessageMode.IS_EDITING) {
                setText(processedMessage.body)
            }
        }
    }, [processedMessageMode]);

    return (
        <div className={'chat-message-form'}>
            <ChatUploadInput onChangeHandler={fileMessageSubmitHandler}/>
            <ChatInput
                text={text}
                placeholder='Write a message'
                onChange={e => setText(e.target.value)}
                textAreaRef={textAreaRef}
            />
            <Button
                onClick={textMessageSubmitHandler}
                className='message-sent-button'
                icon={<PaperPlaneRight size={16} color="#fafafa"/>}
            />
            {processedMessageMode ? (
                <ChatFormProcessedMessage
                    mode={processedMessageMode}
                    message={processedMessage}
                    resetForm={() => setText('')}
                />
            ) : null}
        </div>
    )
}
