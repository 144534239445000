export const range = (len) => {
  const arr = [];
  for (let i = 0; i < len; i++) {
    arr.push(i);
  }
  return arr;
};

export const newItem = (pos = 0, data) => {
  return data[pos];
};

export const makeTableData = (info, ...lens) => {
  const makeDataLevel = (depth = 0) => {
    const len = lens[depth];

    return range(len).map((d, pos) => {
      return {
        ...newItem(pos, info),
        subRows: lens[depth + 1] ? makeDataLevel(depth + 1) : undefined,
      };
    });
  };

  return makeDataLevel();
};
