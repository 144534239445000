 export const isNotEmptyFilterOption = (dataValues) => {
  const countryList = dataValues?.dynamic_statuses?.find(item => item.label.includes('Country'));
  const salesStatusList = dataValues?.dynamic_statuses?.find(item => item.label.includes('sales status'));
  const retentionStatusList = dataValues?.dynamic_statuses?.find(item => item.label.includes('retention status'));
  const kycStatusList = dataValues?.dynamic_statuses?.find(item => item.label.includes('kyc status'));
  const affiliateList = dataValues?.dynamic_statuses?.find(item => item.value.includes('affiliateIds'));

  if (dataValues?.languageIds?.length > 0) {
    return true;
  } else if (dataValues?.query?.length > 0) {
    return true;
  } else if (dataValues?.activity?.value !== 'any' && dataValues?.activity?.value) {
    return true;
  } else if (countryList?.countryIds?.length > 0) {
    return true;
  } else if (affiliateList?.affiliateIds?.length > 0) {
    return true;
  } else if (dataValues?.deskIds?.length > 0) {
    return true;
  } else if (dataValues?.teamIds?.length > 0) {
    return true;
  } else if (dataValues?.operatorIds?.length > 0) {
    return true;
  } else if (dataValues?.affiliateIds?.length > 0) {
    return true;
  } else if (dataValues?.assign_status?.length > 0) {
    return true;
  } else if (dataValues?.first_time_deposit?.value !== 'any' && dataValues?.first_time_deposit?.value) {
    return true;
  } else if (dataValues?.profile_status?.value !== 'any' && dataValues?.profile_status?.value) {
    return true;
  } else if (dataValues?.desk_type?.value !== 'any' && dataValues?.desk_type?.value) {
    return true;
  } else if (dataValues?.created_at) {
    return true;
  } else if (dataValues?.ftd_range) {
    return true;
  } else if (dataValues?.note_first) {
    return true;
  } else if (dataValues?.last_trade) {
    return true;
  } else if (dataValues?.note_last) {
    return true;
  } else if (dataValues?.last_login) {
    return true;
  } else if (dataValues?.updated_at) {
    return true;
  } else if (dataValues?.limit) {
    return true;
  } else if (dataValues?.balance && dataValues?.balance.from !== '' && dataValues?.balance.to !== '') {
    return true;
  } else if (dataValues?.number_deposits && dataValues?.number_deposits.from !== '' && dataValues?.number_deposits.to !== '') {
    return true;
  } else if (salesStatusList?.sales?.length > 0) {
    return true;
  } else if (retentionStatusList?.retention?.length > 0) {
    return true;
  } else if (kycStatusList?.kyc?.length > 0) {
    return true;
  }

  return false;
};
