import React from "react";
import { FileCsv, FileDoc, FilePdf, FileText, FileXls, UserMinus, UserPlus, Users } from "phosphor-react";
import { MessageTypesVerbose } from "../../../models/Chat";

export const mapMessageFiles = {
    pdf: {
        icon: <FilePdf size={18}/>,
        type: MessageTypesVerbose.DOCUMENT_MESSAGE,
    },
    txt: {
        icon: <FileText size={18}/>,
        type: MessageTypesVerbose.DOCUMENT_MESSAGE
    },
    doc: {
        icon: <FileDoc size={18}/>,
        type: MessageTypesVerbose.DOCUMENT_MESSAGE
    },
    docx: {
        icon: <FileDoc size={18}/>,
        type: MessageTypesVerbose.DOCUMENT_MESSAGE
    },
    csv: {
        icon: <FileCsv size={18} />,
        type: MessageTypesVerbose.DOCUMENT_MESSAGE
    },
    xls: {
        icon: <FileXls size={18} />,
        type: MessageTypesVerbose.DOCUMENT_MESSAGE
    },
    xlsx: {
        icon: <FileXls size={18} />,
        type: MessageTypesVerbose.DOCUMENT_MESSAGE
    },
    jpg: {
        icon: '',
        type: MessageTypesVerbose.IMAGE_MESSAGE
    },
    jpeg: {
        icon: '',
        type: MessageTypesVerbose.IMAGE_MESSAGE
    },
    png: {
        icon: '',
        type: MessageTypesVerbose.IMAGE_MESSAGE
    },
    gif: {
        icon: '',
        type: MessageTypesVerbose.IMAGE_MESSAGE
    },
};

export const mapSystemMessageIcons = {
    [MessageTypesVerbose.GROUP_CREATED]: <Users size={24} />,
    [MessageTypesVerbose.PARTICIPANTS_ADDED]: <UserPlus size={24} />,
    [MessageTypesVerbose.PARTICIPANT_REMOVED]: <UserMinus size={24} />,
    [MessageTypesVerbose.PARTICIPANT_LEFT_GROUP]: <UserMinus size={24} />
}

export const mapMessageApiField = {
    [MessageTypesVerbose.MESSAGE]: 'message',
    [MessageTypesVerbose.DOCUMENT_MESSAGE]: 'document',
    [MessageTypesVerbose.IMAGE_MESSAGE]: 'image',
};

export const chatDocumentTypes = ["TXT", "DOC", "DOCX", "PDF", "CSV", "XLSX", "XLS"];
export const chatImageTypes = ["JPG", "JPEG", "PNG", "GIF"];
export const chatFileTypes = [...chatDocumentTypes, ...chatImageTypes];

export const allowedMessageTypes = [
    MessageTypesVerbose.IMAGE_MESSAGE,
    MessageTypesVerbose.DOCUMENT_MESSAGE,
    MessageTypesVerbose.MESSAGE,
    MessageTypesVerbose.GROUP_CREATED,
    MessageTypesVerbose.PARTICIPANTS_ADDED,
    MessageTypesVerbose.PARTICIPANT_REMOVED,
    MessageTypesVerbose.PARTICIPANT_LEFT_GROUP
];

export const systemMessageTypes = [
    MessageTypesVerbose.GROUP_CREATED,
    MessageTypesVerbose.PARTICIPANTS_ADDED,
    MessageTypesVerbose.PARTICIPANT_REMOVED,
    MessageTypesVerbose.PARTICIPANT_LEFT_GROUP
];

export const awayTimeout = 3 * 60 * 1000;
export const loadThreadsInterval = 5 * 60 * 1000;
export const refreshTokenInterval = 12 * 60 * 1000;
