import { ModalTemplate } from "../../../../../components/ui/Modal/components/JokerModalTemplate/JokerModalTemplate";
import { useAppDispatch, useAppSelector } from "../../../../../store";
import { closeJokerModal, selectModalProps } from "../../../../../store/slice/modalSlice";
import { SystemMessageForm } from "../SystemMessageForm/SystemMessageForm";
import { AlertModalMode, SystemMessageFormSubmitData } from "../../../../../models/Ticker";
import { createAlert, updateAlert } from "../../../../../store/slice/tickerSlice";
import { useState } from "react";

export const SYSTEM_MESSAGE_MODAL_KEY = 'SYSTEM_MESSAGE_MODAL_KEY';

export const SystemMessageModal = () => {
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useAppDispatch();
    const modalProps = useAppSelector(selectModalProps);
    const isEdit = modalProps?.mode === AlertModalMode.EDIT;
    const isRepublish = modalProps?.mode === AlertModalMode.REPUBLISH;

    const handleCloseModal = () => dispatch(closeJokerModal());

    const onSubmitHandler = async (data: SystemMessageFormSubmitData) => {
        setIsLoading(true);
        if (isEdit || isRepublish) {
            await dispatch(updateAlert({message: data, id: modalProps?.message?.id, isRepublish}))
                .then((result) => {
                    if (result.type === 'ticker/update/fulfilled') {
                        handleCloseModal();
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                });
        } else {
            await dispatch(createAlert(data))
                .then((result) => {
                    if (result.type === 'ticker/create/fulfilled') {
                        handleCloseModal();
                    }
                })
                .finally(() => {
                    setIsLoading(false)
                });
        }
    };

    return (
        <ModalTemplate id={SYSTEM_MESSAGE_MODAL_KEY} title={modalProps?.title} onClose={handleCloseModal} size='sm'>
            <div className='system-messages-modal'>
                <SystemMessageForm
                    onSubmit={onSubmitHandler}
                    onCancel={handleCloseModal}
                    formValues={modalProps?.message}
                    edit={isEdit}
                    loading={isLoading}
                    submitTitle={modalProps?.submitTitle}
                />
            </div>
        </ModalTemplate>
    )
};