interface IBulkActions {
  SALES: string,
  RETENTION: string,
  MOVE: string,
}

export const BULK_ACTIONS_TYPE: IBulkActions = {
  SALES: 'sales',
  RETENTION: 'retention',
  MOVE: 'move',
};
