import { getCountryList } from 'api/country';
import classNames from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import React, { useEffect, useRef, useState } from 'react';

import { findOperatorsMethod, getDictionaryMethod } from '../../../api/managements/operators';
import Button from '../../../components/ui/Button/index.js';
import OperatorsTable from '../../../components/ui/Table/OperatorsTable/index.js';
import TablePagination from '../../../components/ui/TablePagination/index.js';
import { firstLetterUppercase } from '../../../utils/firstLetterUppercase.js';
import { usePermission } from '../../../utils/usePermission.tsx';
import Page from '../../Page/index.js';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import OperatorsFilter from './Filter/index.js';

import styles from './operators-page.module.scss';
import CreateOperatorsPopup from './OperatorsPopups/CreateOperatorsPopup';
import { getTablePageParams } from '../../../utils/getTablePageParams.js';
import { CountryListValue } from 'models/Countries.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { ManagementOperator } from 'models/Management/Operators/Operator.js';
import { ManagementOperatorsFilter } from 'models/Management/Operators/Filter.js';
import { ListValue } from 'models/ListValue.js';
import { useAppDispatch } from "../../../store";
import { toggleScrollToTop } from "../../../store/slice/tableSlice.ts";

const cx = classNames.bind(styles);

const OperatorsPage = (props) => {

  const { permissionGiven: PermissionShowListOperators } = usePermission('admin.admin-user.index');
  const { permissionGiven: PermissionCreateOperator } = usePermission('admin.admin-user.create');

  const dispatch = useAppDispatch();

  const [operators, setOperators] = useState<ManagementOperator[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [statusList, setStatusList] = useState<ListValue[]>([]);
  const [countryOpt, setCountryOpt] = useState<CountryListValue[]>([]);
  const [branchTypeList, setBranchTypeList] = useState<ListValue[]>([]);
  const [filters, setFilters] = useState<ManagementOperatorsFilter>(null);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');

  const componentMounted = useRef(false);
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  // const [sort, setSort] = useState();
  document.title = 'Operators';
  const SORTED_FIELD_NAME = {
    operator: 'first_name',
    country: 'country_name',
    status: 'status',
    created_at: 'created_at',
  };
  useEffect(() => {
    if (PermissionShowListOperators) {
      componentMounted.current = true;
      getOperators();
    }

    return () => {
      componentMounted.current = false;
    };
  }, [PermissionShowListOperators]);

  useEffect(() => {
    fetchCountryList();
    fetchDictionaryList();
  }, [])

  const fetchCountryList = () => {
    getCountryList().then((res) => {
      const opt = res.data.data.map((opt) => ({
        value: opt.id,
        label: opt.name,
        iso_3166_2: opt.iso_3166_2,
      }));
      setCountryOpt([{ value: 0, label: 'Other' }, ...opt]);
    });
  };

  const fetchDictionaryList = () => {
    getDictionaryMethod().then((res) => {
      const list = res.data.branch_types.map((opt) => ({
        value: opt,
        label: opt,
      }));
      const list1 = res.data.operator_statuses.map((opt) => ({
        value: opt,
        label: firstLetterUppercase(opt),
      }));
      setBranchTypeList([...list]);
      setStatusList([...list1]);
    });
  };

  const getOperators = (options?, data?) => {
    setIsListLoading(true);

    let postData;

    findOperatorsMethod(getTablePageParams(options, perPageCount, tableLinks), data || postData || filters || { filter: { statuses: ['active'] }})
      .then((res) => {
        setOperators(res.data.data);
        setTableMeta(res.data.meta);
        setTableLinks(res.data.links);
        setPerPageCount(res.data.meta.per_page);
        setQueryResponseMessage(res?.data?.message || '');
        setIsListLoading(false);
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  const sortColumn = (col) => {
    const sorted = col.isSorted;
    const desc = col.isSortedDesc;
    const sortedOperator = SORTED_FIELD_NAME[col.id] === SORTED_FIELD_NAME.operator;

    if (!sorted) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: sortedOperator ? 'asc' : 'desc',
      };
      // setSort(sort);
      getOperators(null, {
        ...filters,
        sort,
      });
      col.toggleSortBy(true);
    }

    if (sorted && desc) {
      const sort = {
        field: SORTED_FIELD_NAME[col.id],
        direct: sortedOperator ? 'desc' : 'asc',
      };
      // setSort(sort);
      getOperators(null, {
        ...filters,
        sort,
      });
      col.toggleSortBy(false);
    }

    if (sorted && !desc) {
      const data = {
        ...filters,
      };
      // setSort(null);
      getOperators(null, data);
      col.clearSortBy();
    }
  };

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>
                  <strong>{tableMeta?.total || 0}</strong> Operators found
                </span>
                <span></span>
              </div>
              <div className={cx('header-controls')}>
                {
                  PermissionCreateOperator ?
                    <CreateOperatorsPopup
                      branchTypeList={branchTypeList}
                      updateComponent={() => getOperators()}
                      actionType={SALES_RULES_ACTION_TYPE.CREATE}
                      triggerBtn={
                        <Button
                          buttonText={'+ New operator'}
                          onClick={() => null}
                          buttonType={'outline'}
                        />
                      }
                    /> : null
                }
              </div>
            </div>
          </div>
          <div className={cx('container__filters-block')}>
            <OperatorsFilter
              statusList={[...statusList, { value: 'any', label: 'Any' }]}
              countryList={countryOpt}
              onSubmit={(data) => {
                setFilters(data);
                getOperators({ navigate: 'first', perPage: data?.filter?.limit }, data);
              }}
              reloadFilters={isListLoading}
              queryMessage={queryResponseMessage}
                          />
          </div>
          <div className={cx('container__block')}>
            {
              PermissionShowListOperators ?
                <OperatorsTable
                  manualSortHandler={sortColumn}
                  data={operators}
                  branchTypeList={branchTypeList}
                  perPage={operators?.length}
                  showLoader={isListLoading}
                  modifyDataHandler={(callback) => getOperators()}
                /> : null
            }
            {operators?.length ? (
              <TablePagination
                goToFirstPage={() => {
                  getOperators({ navigate: 'first' });
                  dispatch(toggleScrollToTop())
                }}
                goToPrevPage={() => {
                  getOperators({ navigate: 'prev' });
                  dispatch(toggleScrollToTop())
                }}
                goToNextPage={() => {
                  getOperators({ navigate: 'next' });
                  dispatch(toggleScrollToTop())
                }}
                goToLastPage={() => {
                  getOperators({ navigate: 'last' });
                  dispatch(toggleScrollToTop())
                }}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getOperators({ perPage: value });
                }}
                goToSelectedPage={(page) => {
                  getOperators({ page });
                }}
                // hidePageSelect={true}
                isFirstPageDisable={() => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default OperatorsPage;
