import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fetchStyles } from 'api/system';

import * as STATIC_LOGOS from '../../assets/logos';

interface IVisualInfo {
  logoURL: string;
  properLogo: any;
}

const initialState: IVisualInfo = {
  logoURL: '',
  properLogo: null,
};

export const getStyles = createAsyncThunk('visualSlice/getStyles', async (_, { rejectWithValue }) => {
  try {
    const response = await fetchStyles();
    
    return response;
  } catch (error) {
    return rejectWithValue(error.response.data.message);
  }
});

const getProperLogo = (logoURL: string) => {
  if (logoURL) {
    return `${logoURL}?time=${new Date().getTime()}`;
  }

  const staticLogo = STATIC_LOGOS[process.env.REACT_APP_DEFAULT_ICON];

  if (typeof staticLogo === 'string') {
    return staticLogo;
  }

  return staticLogo.default;
}

export const visualSlice = createSlice({
  name: 'visualSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getStyles.fulfilled, (state, action) => {
      return {
        ...state,
        logoURL: action.payload.data.data.logo_src,
        properLogo: getProperLogo(action.payload.data.data.logo_src_crm)
      }
    })
  }
});

const { reducer } = visualSlice;
export default reducer;
