import React, { useState } from 'react';
import {
  getDeskForOfficeMethod,
  // getOfficesMethod,
  getOperatorsForOfficeMethod,
} from '../../api/hierarchy';
import HierarchyComponentTeams from './HierarchyComponentTeams';
import styles from './hierarchy-page.module.scss';
import PlusIcon from '../../assets/Svg/PlusIcon';
import MinusIcon from '../../assets/Svg/MinusIcon';
import OfficeIcon from '../../assets/Svg/OfficeIcon';
import { checkDevise } from '../../utils/checkDevise';
import Button from '../../components/ui/Button';
import OperatorIcon from '../../assets/Svg/OperatorIcon';
// import CreateTemplatesPopup from '../Templates/TemplatesPopups/CreateTemplatesPopup';
// import { SALES_RULES_ACTION_TYPE } from '../../constants/sales-rules.const';
import UserPopup from './UsersPopup';
import { HierarchyOffice } from 'models/Hierarchy/HierarchyData';

type Props = {
  data: HierarchyOffice;
}

const HierarchyComponentDesks = (props: Props) => {
  const { data } = props;

  let [item, setItem] = useState(data);
  let [open, setOpen] = useState(false);
  let [hover, setHover] = useState(false);
  const device = checkDevise();
  return (
    <div className={styles.wrapperItem}>
      <div
        className={styles.wrapperContent}
        onMouseEnter={() => {
          device === 'desktop' && setHover(true);
        }}
        onMouseLeave={() => {
          device === 'desktop' && setHover(false);
        }}
      >
        {device === 'mobile' && item?.branches_count > 0 ? (
          <>
            {open ? (
              <button
                className={styles.btnIcon}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <MinusIcon />
              </button>
            ) : (
              <button
                className={styles.btnIcon}
                onClick={() => {
                  setOpen(true);
                  getDeskForOfficeMethod(item.id).then((res) => {
                    if (res.status === 200) {
                      setItem({
                        ...item,
                        desks: [...res.data.data],
                      });
                    }
                  });
                }}
              >
                <PlusIcon />
              </button>
            )}
          </>
        ) : (
          <>
            {item?.branches_count > 0 && hover ? (
              <>
                {open ? (
                  <button
                    className={styles.btnIcon}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <MinusIcon />
                  </button>
                ) : (
                  <button
                    className={styles.btnIcon}
                    onClick={() => {
                      setOpen(true);
                      getDeskForOfficeMethod(item.id).then((res) => {
                        if (res.status === 200) {
                          setItem({
                            ...item,
                            desks: [...res.data.data],
                          });
                        }
                      });
                    }}
                  >
                    <PlusIcon />
                  </button>
                )}
              </>
            ) : (
              <div className={styles.btnIcon}>
                <OfficeIcon />
              </div>
            )}
          </>
        )}

        <div className={styles.wrapperInfo}>
          <div>
            <div className={styles.topBlock}>
              <p className={styles.bolderText}>{item?.name}</p>
              <p className={styles.greyText}>Office</p>
            </div>
            <div className={styles.bottomBlock}>
              <p className={styles.greyText}>
                {item?.branches_count} branches / {item?.operators_count}{' '}
                {item?.operators_count === 1 ? 'user' : 'users'}
              </p>
            </div>
          </div>
          {item?.operators_count > 0 && (
            <UserPopup
              fetchMethod={getOperatorsForOfficeMethod}
              data={item}
              namePopup={`office ${item.name}`}
              triggerBtn={
                <Button icon={<OperatorIcon />} onClick={() => null} buttonType={'outline'} />
              }
            />
          )}
        </div>
      </div>

      {open && item?.desks?.length > 0 && (
        <div className={styles.wrapperList}>
          {item.desks?.map((desk, ind) => {
            return (
              <div key={desk.id} className={styles.itemList}>
                <div className={styles.beforeParent}></div>
                <HierarchyComponentTeams data={desk} />
                {item?.desks?.length - 1 === ind && <p className={styles.hideLine}>0</p>}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default HierarchyComponentDesks;
