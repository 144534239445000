import Preloader from "../../../../components/ui/Preloader";
import './ChatMessagesEmpty.scss';

export const ChatMessagesEmpty = ({ isLoading }: { isLoading: boolean }) => {
    return (
        <div className="chat-messages-empty">
            {isLoading ?  <Preloader /> : (
                <>
                    <div className='chat-empty-title'>There are no messages yet.</div>
                    <div className='chat-empty-title'>Be the first to say hello.</div>
                </>
            )}
        </div>
    )
}