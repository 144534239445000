import * as React from "react"

const UsersSvg = (props) => (
  <svg
    width={25}
    height={25}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M13.706 20.21v-2a5 5 0 1 0-10 0v2h10Zm0 0h8v-1c0-2.946-2.239-5-5-5-1.414 0-2.69.625-3.6 1.63m-1.4-8.63a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm7 2a2 2 0 1 1-4 0 2 2 0 0 1 4 0Z"
      stroke="#000000"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default UsersSvg
