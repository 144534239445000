import React, { useState } from 'react';
import {
  // getDeskForOfficeMethod,
  // getOfficesMethod,
  getOperatorsForDeskMethod,
  // getOperatorsForOfficeMethod,
  getTeamForDeskMethod,
} from '../../api/hierarchy';
import HierarchyComponentOperators from './HierarchyComponentOperators';
import styles from './hierarchy-page.module.scss';
import classNames from 'classnames/bind';
import PlusIcon from '../../assets/Svg/PlusIcon';
import MinusIcon from '../../assets/Svg/MinusIcon';
import DeskIcon from '../../assets/Svg/DeskIcon';
import { checkDevise } from '../../utils/checkDevise';
import UserPopup from './UsersPopup';
import Button from '../../components/ui/Button';
import OperatorIcon from '../../assets/Svg/OperatorIcon';
import { HierarchyDesk } from 'models/Hierarchy/HierarchyData';

const cx = classNames.bind(styles);

type Props = {
  data: HierarchyDesk;
}

const HierarchyComponentTeams = (props: Props) => {
  const { data } = props;

  let [item, setItem] = useState(data);
  let [open, setOpen] = useState(false);
  let [hover, setHover] = useState(false);
  const device = checkDevise();
  return (
    <div className={styles.wrapperItem}>
      <div
        className={cx('wrapperContent', 'twoLevel')}
        onMouseEnter={() => {
          device && setHover(true);
        }}
        onMouseLeave={() => {
          device && setHover(false);
        }}
      >
        <div className={styles.beforeEl}></div>
        {device === 'mobile' && item?.branches_count > 0 ? (
          <>
            {open ? (
              <button
                className={styles.btnIcon}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <MinusIcon />
              </button>
            ) : (
              <button
                className={styles.btnIcon}
                onClick={() => {
                  setOpen(true);
                  getTeamForDeskMethod(item.id).then((res) => {
                    if (res.status === 200) {
                      setItem({
                        ...item,
                        teams: [...res.data.data],
                      });
                    }
                  });
                }}
              >
                <PlusIcon />
              </button>
            )}
          </>
        ) : (
          <>
            {item?.branches_count > 0 && hover ? (
              <>
                {open ? (
                  <button
                    className={styles.btnIcon}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <MinusIcon />
                  </button>
                ) : (
                  <button
                    className={styles.btnIcon}
                    onClick={() => {
                      setOpen(true);
                      getTeamForDeskMethod(item.id).then((res) => {
                        if (res.status === 200) {
                          setItem({
                            ...item,
                            teams: [...res.data.data],
                          });
                        }
                      });
                    }}
                  >
                    <PlusIcon />
                  </button>
                )}
              </>
            ) : (
              <div className={styles.btnIcon}>
                <DeskIcon />
              </div>
            )}
          </>
        )}

        <div className={styles.wrapperInfo}>
          <div>
            <div className={styles.topBlock}>
              <p className={styles.bolderText}>{item?.name}</p>
              <p className={styles.greyText}>Desk</p>
            </div>
            <div className={styles.bottomBlock}>
              <p className={styles.greyText}>
                {item?.branches_count} branches / {item?.operators_count}{' '}
                {item?.operators_count === 1 ? 'user' : 'users'}
              </p>
            </div>
          </div>
          {item?.operators_count > 0 && (
            <UserPopup
              fetchMethod={getOperatorsForDeskMethod}
              data={item}
              namePopup={`desk ${item.name}`}
              triggerBtn={
                <Button icon={<OperatorIcon />} onClick={() => null} buttonType={'outline'} />
              }
            />
          )}
        </div>
      </div>

      {open && item?.teams?.length > 0 && (
        <div className={styles.wrapperList}>
          {item.teams?.map((team, ind) => {
            return (
              <div key={team.id} className={styles.itemList}>
                <div className={styles.beforeParent}></div>
                <HierarchyComponentOperators data={team} />
                {item?.teams?.length - 1 === ind && <p className={styles.hideLine}>0</p>}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default HierarchyComponentTeams;
