import classNames from 'classnames/bind';
import moment from 'moment';
import { ArrowsClockwise, ArrowsCounterClockwise, CaretDown, Trash } from 'phosphor-react';
import React, { useEffect, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { deleteClient, getClientAutologinLink, getClientInfo, resetPasswordForClient } from '../../../api/clients';
import timezone from '../../../constants/timezone.json'
import { usePermission } from '../../../utils/usePermission.tsx';
import Page from '../../Page/Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';

import styles from './client-page.module.scss';
import AdressForm from './ClientForms/AdressForm';
import ContactForm from './ClientForms/ContactForm';
import KycForm from './ClientForms/KycForm';
import PersonalInfoForm from './ClientForms/PersonalInfoForm';
import ClientChangePasswordPopup from './ClientPopups/ClientChangePasswordPopup';
import Callbacks from './ClientTabs/Callbacks';
import Feed from './ClientTabs/Feed';
import Files from './ClientTabs/Files';
import NotesTabs from './ClientTabs/NotesTabs';
import Payments from './ClientTabs/Payments';
import TradingActivity from './ClientTabs/TradingActivity';
import ChangeAcquisitionStatus from './ClientPopups/ChangeAcquisitionStatus';
import Affiliate from './ClientSections/Affiliate';
import AccountStatus from './ClientSections/AccountStatus';
import ResetPassword from '../../../components/ui/Icons/ResetPassword';
import Registered from './ClientSections/Registered';
import LastActivity from './ClientSections/LastActivity';
import LastLogin from './ClientSections/LastLogin';
import PersonalInfo from './ClientSections/PersonalInfo';
import OpenedPositions from './ClientTabs/OpenedPositions';
import Deals from './ClientTabs/Deals';
import { useSelector } from 'react-redux';
import { getEnableMT } from '../../../constants/selectors';
import TradingAccounts from './ClientTabs/TradingAccounts/TradingAccounts';
import Button from '../../../components/ui/Button';
import Notes from '../../../components/ui/Notes/Notes';
import { getCountryList } from '../../../api/country';
import { getLangList } from '../../../api/lang';
import { getBalanceInfo, updateProfitInfo } from '../../../api/deposit';
import { notify } from '../../../utils/notify';
import { copyToBuffer } from '../../../utils/copyToBuffer';
import { getShortUuid } from '../../../utils/getShortUuid';

import { BalancesData } from 'models/Balances';
import { ICountry } from 'models/Countries';
import { Client as ClientType } from 'models/Clients/Clients';
import { Language } from 'models/Languages';
import PopupYesOrNo from "../../../components/ui/PopupYesOrNo/PopupYesOrNo.tsx";
import CryptoWalletsTab from "./ClientTabs/CryptoWalletsTab/CryptoWalletsTab.tsx";
import { isChatEnabled } from "../../Chat/helpers/isChatEnabled";
import { ChatButton } from "../../Chat/components/ChatButton/ChatButton";
import ErrorPage from "../../ErrorPage";

const cx = classNames.bind(styles);

const Client = () => {
  const chatEnabled = isChatEnabled();
  const { permissionGiven: PermissionChangePassword } = usePermission('admin.client.change-password');
  const { permissionGiven: PermissionAddressInfo } = usePermission('admin.client.view-address-info');
  const { permissionGiven: PermissionViewUserLogs } = usePermission('admin.logs.user');
  const { permissionGiven: PermissionListCallback } = usePermission('admin.callback.index');
  const { permissionGiven: PermissionNotesCreate } = usePermission('admin.notes.create');
  const { permissionGiven: PermissionPersonalInfo } = usePermission('admin.client.view-personal-info');
  const { permissionGiven: PermissionDelete } = usePermission('admin.client.delete')
  const { permissionGiven: PermissionShowTableCryptoWalletsUserById } = usePermission('admin.client.crypto_wallets.index');
  const { permissionGiven: PermissionAssociateClientToOperator } = usePermission('admin.client.team');
  const { permissionGiven: PermissionAutologin } = usePermission('admin.client.get-autologin');

  const params = useParams();
  const navigate = useNavigate();
  const [isLoadingResetPass, setIsLoadingResetPass] = useState(false);
  const [clientName, setClientName] = useState('');
  const [clientData, setClientData] = useState<ClientType | undefined>();
  const [isListLoading, setIsListLoading] = useState(false);
  const [openBalance, setOpenBalance] = useState(false);
  const [showNotes, setShowNotes] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [countryListArr, setCountryListArr] = useState<ICountry[]>([]);
  const [langListArr, setlangListArr] = useState<Language[]>([]);
  const [hasError, setError] = useState(false);
  const [passMenu, setPassMenu] = useState(false);
  const [balanceInfo, setBalanceInfo] = useState<BalancesData | undefined>();
  const [autologinURL, setAutologinURL] = useState('');
  const [balancesLoading, setBalancesLoading] = useState(false);
  const [lastSelectedPeriod, setLastSelectedPeriod] = useState<string | undefined>(undefined);
  const ENABLE_MT = useSelector(getEnableMT);

  const title = 'Client - ' + clientName;

  const passwordMenu = useRef(null);

  const componentMounted = useRef(false);

  useEffect(() => {
    componentMounted.current = true;
    return () => {
      componentMounted.current = false;
    };
  }, []);

  useEffect(() => {
    getCountries();
    getLanguages();
  }, []);

  const getCountries = () => {
    getCountryList()
      .then((res) => {
        if (res.status === 200) {
          setCountryListArr(res.data.data)
        }
      })
      .catch((error) => console.log(error))
  }

  const getLanguages = () => {
    getLangList()
      .then((res) => {
        if (res.status === 200) {
          setlangListArr(res.data.data)
        }
      })
      .catch((error) => console.log(error))
  }

  const getClient = (params?: any) => {
    setIsListLoading(true);
    fetchBalanceInfo(params);

    getClientInfo(params.id).then((res) => {
      if (res.status === 200) {
        setClientData(res.data.data);
        setClientName(
          res.data.data.user_profile.first_name + ' ' + res.data.data.user_profile.last_name,
        );
        setIsLoading(false);
        setError(false);
      }
    }).catch((err) => {
      if (err.response.status === 403) {
        setError(true);
      }
    }).finally(() => {
      setIsListLoading(false);
    });
  };

  const fetchBalanceInfo = (params, period?: string, refreshProfit?: boolean) => {
    setBalancesLoading(true);

    let func: () => Promise<any> = () => Promise.resolve();

    if (refreshProfit) {
      func = () => updateProfitInfo(params.id);
    }

    func()
      .then(() => 
        getBalanceInfo(params.id, period)
      )
      .then((res) => {
        if (res.status === 200) {
          setBalanceInfo(res.data.data);
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setBalancesLoading(false);
      });
  };

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    getClient(params);
  }, [params]);

  const getAutologinLink = async () => {
    const response = await getClientAutologinLink(clientData.id);
    if (response.status === 200) {
      setAutologinURL(response.data.url);
    }
  }

  useEffect(() => {
    if (clientData && clientData.id !== undefined && PermissionAutologin) {
      getAutologinLink()
    }
  }, [clientData, PermissionAutologin])

  const onResetPassword = () => {
    setIsLoadingResetPass(true);
    resetPasswordForClient(clientData.id).then((res) => {
      if (res.status === 200) {
        notify({
          type: 'success',
          message: res.data.message,
          timeOut: 3000,
        });
        setPassMenu(false);
      }
    })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response.data.message,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoadingResetPass(false);
      });
  };
  const onClickOutside = (event) => {
    if (passwordMenu.current && !passwordMenu.current.contains(event.target)) {
      setPassMenu(false);
    }
  };

  const onClickDelete = () => {
    // eslint-disable-next-line no-restricted-globals
    deleteClient(params.id)
      .then(() => {
        navigate('/search-clients')
      })
      .catch((error) => {
        console.log(error)
        notify({
          type: 'error',
          message: 'Сlient not deleted',
          timeOut: 3000,
        });
      })
  }

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true);
  }, []);

  const balanceStatus = () => (
    <>
      <div className={cx('client-info__col-title')}>Total balance</div>
      <div className={cx('client-info__col-content')}>
        <div className={cx('info-block')}>
          <div className={cx('balance-wrap')}>
            <div className={cx('balance')}>
              <div className={cx('balance__value-wrap')}>
                <div className={cx('balance__value')}>
                  USD {balanceInfo?.total?.net_balance ? balanceInfo?.total?.net_balance : '0.00'}
                </div>
                <div className={cx('balance__descr')}>
                  Credit: USD{' '}
                  {balanceInfo?.total?.credit ? balanceInfo?.total?.credit : '0.00'}
                </div>
              </div>
              <div
                className={cx('value-icon', openBalance ? 'active' : '')}
                onClick={() => setOpenBalance(!openBalance)}
              >
                <CaretDown size={16} />
              </div>
            </div>
            <div className={cx('balance-dropdown', openBalance ? 'open' : '')}>
              <div className={cx('balance-dropdown__row')}>
                {ENABLE_MT ?
                  <div className={cx('balance-dropdown__col')}>
                    <div className={cx('balance-dropdown__col-title')}>EUR BALANCE</div>
                    <div className={cx('balance-dropdown__col-value')}>
                      EUR {balanceInfo?.EUR ? balanceInfo?.EUR?.net_balance : '0.00'}
                    </div>
                    <div className={cx('balance-dropdown__col-descr')}>
                      Credit: EUR{' '}
                      {balanceInfo?.EUR?.credit ? balanceInfo?.EUR?.credit : '0.00'}
                    </div>
                  </div>
                  : null
                }
                <div className={cx('balance-dropdown__col')}>
                  <div className={cx('balance-dropdown__col-title')}>USD BALANCE</div>
                  <div className={cx('balance-dropdown__col-value')}>
                    USD {balanceInfo?.USD?.net_balance ? balanceInfo?.USD?.net_balance : '0.00'}
                  </div>
                  <div className={cx('balance-dropdown__col-descr')}>
                    Credit: USD {balanceInfo?.USD?.credit ? balanceInfo?.USD?.credit : '0.00'}
                  </div>
                </div>
              </div>
              <div className={cx('balance-dropdown__row')}>
                <ReactSelect
                  styles={{
                    container: (styles) => ({
                      ...styles,
                      width: '100%',
                      marginBottom: '10px',
                      border: '1px solid #c2c2c2',
                      borderRadius: '4px',
                      outline: 'none',
                      boxShadow: 'none!important',
                      '&:hover': {
                        borderColor: 'var(--main-color)!important',
                      },
                      '&:active': {
                        borderColor: 'var(--main-color)!important',
                      },
                      '&:focus': {
                        borderColor: 'var(--main-color)!important',
                      },
                    }),
                    control: (styles) => ({
                      ...styles,
                      border: 'none',
                      minHeight: 'unset',
                      '&:hover': {
                        borderColor: 'var(--main-color)!important',
                        boxShadow: 'none!important',
                      },
                      '&:active': {
                        borderColor: 'var(--main-color)!important',
                        boxShadow: 'none!important',
                      },
                      '&:focus': {
                        borderColor: 'var(--main-color)!important',
                        boxShadow: 'none!important',
                      },
                    }),
                    valueContainer: (styles) => ({
                      ...styles,
                      padding: '8px',
                    }),
                    menuList: (styles) => ({
                      ...styles,
                      width: '100%',
                    }),
                    option: (styles, { isSelected }) => ({
                      ...styles,
                      backgroundColor: isSelected ? 'var(--main-color)' : '#ffffff',
                      '&:hover': {
                        backgroundColor: 'var(--purple-100)',
                        color: '#000000',
                      },
                    }),
                    singleValue: (styles) => ({
                      ...styles,
                      lineHeight: 'normal',
                    }),
                    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
                  }}
                  options={[
                    { value: '', label: 'All time' },
                    { value: '1', label: 'One day' },
                    { value: '7', label: 'One week' },
                    { value: '31', label: 'One month' },
                    { value: '366', label: 'One year' },
                  ]}
                  onChange={(value) => {
                    setLastSelectedPeriod(value.value);
                    fetchBalanceInfo(params, value.value)
                  }}
                />
              </div>
              <div className={cx('balance-dropdown__row')}>
                <div className={cx('balance-dropdown__col')}>
                  <div className={cx('balance-dropdown__col-title')}>Deposits</div>
                  <div className={cx('balance-dropdown__col-value')}>
                    {balanceInfo?.total?.deposit_count ? balanceInfo?.total?.deposit_count : '0'}
                  </div>
                </div>
                <div className={cx('balance-dropdown__col')}>
                  <div className={cx('balance-dropdown__col-title')}>Withdrawals</div>
                  <div className={cx('balance-dropdown__col-value')}>
                    {balanceInfo?.total?.withdraw_count ? balanceInfo?.total?.withdraw_count : '0'}
                  </div>
                </div>
              </div>
              <div className={cx('balance-dropdown__row')}>
                <div className={cx('balance-dropdown__col')}>
                  <div className={cx('balance-dropdown__col-title')}>Deposited</div>
                  <div className={cx('balance-dropdown__col-value')}>
                    USD: {balanceInfo?.total?.deposit ? balanceInfo?.total?.deposit : '0.00'}
                  </div>
                </div>
                <div className={cx('balance-dropdown__col')}>
                  <div className={cx('balance-dropdown__col-title')}>Withdrawn</div>
                  <div className={cx('balance-dropdown__col-value')}>
                    USD: {balanceInfo?.total?.withdraw ? balanceInfo?.total?.withdraw : '0.00'}
                  </div>
                </div>
                <div className={cx('balance-dropdown__col')}>
                  <div className={cx('balance-dropdown__col-title')}>Profit</div>
                  <div className={cx('balance-dropdown__col-value')}>
                    USD: {balanceInfo?.total?.profit ? balanceInfo?.total?.profit : '0.00'}
                  </div>
                </div>
              </div>
              {ENABLE_MT ?
                <>
                  <div className={cx('balance-dropdown__row')}>
                    <div className={cx('balance-dropdown__col')}>
                      <div className={cx('balance-dropdown__col-title')}>Trade balance</div>
                      <div className={cx('balance-dropdown__col-value')}>
                        USD: {balanceInfo?.total?.trade_balance ? balanceInfo?.total?.trade_balance : '0.00'}
                      </div>
                    </div>
                    <div className={cx('balance-dropdown__col')}>
                      <div className={cx('balance-dropdown__col-title')}>Trade count</div>
                      <div className={cx('balance-dropdown__col-value')}>
                        USD:{' '}
                        {balanceInfo?.total?.trade_balance_count ? balanceInfo?.total?.trade_balance_count : '0.00'}
                      </div>
                    </div>
                    <div className={cx('balance-dropdown__col')}>
                      <div className={cx('balance-dropdown__col-title')}>net deposit</div>
                      <div className={cx('balance-dropdown__col-value')}>
                        USD: {balanceInfo?.total?.net_deposit ? balanceInfo?.total?.net_deposit : '0.00'}
                      </div>
                    </div>
                  </div>
                </> : null
              }
              <div className={cx('balance-dropdown__col')}>
                <Button 
                  className={balancesLoading ? 'reload-btn-clients reload' : 'reload-btn-clients'}
                  onClick={() => fetchBalanceInfo(params, lastSelectedPeriod, true)} 
                  icon={<ArrowsClockwise color="white" />} 
                  style={{ marginLeft: 0 }} 
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );

  const referrals = () => (
    <>
      <div className={cx('client-info__col-title')}>Referrals</div>
      <div className={cx('client-info__col-content')}>
        {/* <div className={cx('strong')}>
         {clientData.referrals && clientData.referrals.count ? clientData.referrals.count : '0'}
         </div> */}
        <div>
          FTD: {clientData?.referrals?.ftd ? clientData?.referrals?.ftd : '0'}
        </div>
        <div>
          Remuneration: USD{' '}
          {clientData?.referrals?.remuneration
            ? clientData?.referrals?.remuneration
            : '0.00'}
        </div>
      </div>
    </>
  );

  if (hasError) {
    return <ErrorPage />
  }

  return (
    <DashboardLayout>
      {!isLoading && (
        <Page>
          <div className={cx('container')}>
            <div className={cx('container__header')}>
              <div className={cx('client')}>
                <div className={cx('client__name')}>
                  {clientData?.user_profile?.first_name} {clientData?.user_profile?.last_name}{' '}
                  {clientData?.user_profile?.birthday !== null
                    ? `(${Math.floor(
                      moment(new Date()).diff(
                        moment(clientData?.user_profile?.birthday * 1000).format('MM/DD/YYYY'),
                        'years',
                        true,
                      ),
                    )})`
                    : '(?)'}
                </div>
                <div
                  className={cx('client__identificator', 'uuid')}
                  onClick={() => {
                    copyToBuffer(clientData?.uuid, true);
                  }}
                >
                  {getShortUuid(clientData?.uuid)}
                </div>
              </div>
              <div className={cx('action')}>
                {chatEnabled ? <ChatButton id={clientData?.id}/> : null}
                {
                  autologinURL && PermissionAutologin && (
                    <Button 
                      buttonText='Autologin'
                      onClick={() => window.open(autologinURL)}
                    />
                  )
                }
                {
                  clientData?.hasOwnProperty('sales') && PermissionAssociateClientToOperator ?
                    <ChangeAcquisitionStatus
                      actionType='sales'
                      updateComponent={() => getClient(params)}
                      userId={params.id}
                      triggerBtn={
                        <Button
                          buttonType='outline'
                          buttonText='Change acquisition status'
                        />
                      }
                    /> : null
                }

                {
                  clientData?.hasOwnProperty('retention') && PermissionAssociateClientToOperator ?
                    <ChangeAcquisitionStatus
                      actionType='retention'
                      updateComponent={() => getClient(params)}
                      userId={params.id}
                      triggerBtn={
                        <Button
                          buttonType='outline'
                          buttonText='Change acquisition status'
                        />
                      }
                    /> : null
                }
                {PermissionNotesCreate ?
                  <Button
                    buttonType='outline'
                    buttonText='Add note'
                    onClick={() => setShowNotes(!showNotes)}
                  />
                  : null
                }
                <Button
                  className={isListLoading ? 'reload-btn-small reload' : 'reload-btn-small'}
                  buttonType='outline'
                  icon={<ArrowsCounterClockwise size={32} />}
                  onClick={() => getClient(params)}
                  buttonStyle={{ marginLeft: '15px' }}
                />
                <div className={cx('customDropdown')}>
                  {
                    PermissionChangePassword ?
                      <Button
                        ref={passwordMenu}
                        buttonType='outline'
                        icon={<ResetPassword />}
                        onClick={() => setPassMenu(!passMenu)}
                      /> : null
                  }
                  <div
                    className={cx('customDropdown__list', passMenu ? 'open' : '')}
                    ref={passwordMenu}
                  >
                    <ul className={cx('passMenu')}>
                      <ClientChangePasswordPopup
                        updateComponent={() => getClient()}
                        userId={params.id}
                        triggerBtn={<li className={cx('passMenu__item')}>Change password</li>}
                      />

                      <li
                        onClick={() => onResetPassword()}
                        className={cx('passMenu__item', 'withLoader')}
                      >
                        Reset password
                        {isLoadingResetPass ? (
                          <div
                            style={{
                              position: 'initial',
                              color: 'var(--main-color)',
                              marginLeft: '10px',
                            }}
                            className='spinner-wrapp'
                          >
                            <span
                              className='spinner-border spinner-border-sm'
                              role='status'
                              aria-hidden='true'
                            ></span>
                          </div>
                        ) : (
                          ''
                        )}
                      </li>
                    </ul>
                  </div>
                </div>
                {showNotes && (
                  <Notes
                    userId={clientData.id}
                    handleClose={(show) => setShowNotes(show)}
                    type='client'
                    updateComponent={() => getClient(params)}
                  />
                )}
                {
                  PermissionDelete && (
                    <PopupYesOrNo
                      onClick={onClickDelete}
                      headerTitle={'Delete client'}
                      bodyText={'Are you sure you want to delete this client?'}
                      triggerBtn={
                        <Button
                          buttonType="delete"
                          icon={<Trash />}
                        />
                      }
                    />
                  )
                }
              </div>
            </div>
            <div className={cx('container__block')}>
              <div className={cx('client-info')}>
                <div className={cx('client-info__col')}>{
                  <AccountStatus
                    clientData={clientData}
                    isLoading={isLoading}
                    updateComponent={() => getClient(params)}
                  />
                }</div>
                <div className={cx('client-info__col')}>{balanceStatus()}</div>
                <div className={cx('client-info__col')}>{<Affiliate clientData={clientData} />}</div>
                <div className={cx('client-info__col')}>{<LastActivity clientData={clientData} />}</div>
                <div className={cx('client-info__col')}>{<Registered clientData={clientData} />}</div>
                <div className={cx('client-info__col')}>{<LastLogin clientData={clientData} />}</div>
                {/*<div className={cx('client-info__col')}>{referrals()}</div>*/}
              </div>
            </div>
            <div className={cx('container__block')}>
              {<PersonalInfo
                clientData={clientData}
                updateComponent={() => getClient(params)}
              />}
            </div>
            <div className={cx('container__block')}>
              <div className={cx('profile__tabs')}>
                <Tabs className={cx('tabs')}>
                  <div className={cx('tabs__list-wrap')}>
                    <TabList>
                      <Tab>Profile</Tab>
                      <Tab>Payments</Tab>
                      {ENABLE_MT ?
                        <>
                          <Tab>Trading Activity</Tab>
                          <Tab>Opened positions</Tab>
                          <Tab>Deals</Tab>
                          <Tab>Accounts</Tab>
                        </> : null
                      }
                      {
                        PermissionListCallback ?
                          <Tab>Callbacks</Tab>
                          : null
                      }
                      <Tab>Files</Tab>
                      <Tab>Notes</Tab>
                      {
                        PermissionViewUserLogs ?
                          <Tab>Feed</Tab>
                          : null
                      }
                      {
                        PermissionShowTableCryptoWalletsUserById ?
                          <Tab>Crypto Wallets</Tab>
                          : null
                      }

                    </TabList>
                  </div>

                  <TabPanel>
                    <div className={cx('tab-panel')}>
                      <div className={cx('tab-panel__content')}>
                        <div className={cx('tab-panel__content-col', 'w70')}>
                          {PermissionPersonalInfo ?
                            <div className={cx('tab-panel__content-box')}>
                              <div className='form-wrap'>
                                <PersonalInfoForm
                                  formData={clientData}
                                  countryList={countryListArr}
                                  timeZone={timezone}
                                  langList={langListArr}
                                  updateComponent={() => getClient(params)}
                                />
                              </div>
                            </div>
                            : null}
                          {PermissionAddressInfo ?
                            <div className={cx('tab-panel__content-box')}>
                              <div className='form-wrap'>
                                <AdressForm
                                  formData={clientData}
                                  countryList={countryListArr}
                                  timeZone={timezone}
                                  langList={langListArr}
                                  updateComponent={() => getClient(params)}
                                />
                              </div>
                            </div>
                            : null}
                        </div>
                        <div className={cx('tab-panel__content-col', 'w30')}>
                          <div className={cx('tab-panel__content-box')}>
                            <div className='form-wrap'>
                              <KycForm
                                formData={clientData}
                                updateComponent={() => getClient(params)}
                              />
                            </div>
                          </div>
                          {/* <div className={cx('tab-panel__content-box')}>
                           <div className="form-wrap">
                           <TransferAvailabilityForm
                           formData={clientData}
                           updateComponent={() => getClient(params)}
                           />
                           </div>
                           </div> */}
                          <div className={cx('tab-panel__content-box')}>
                            <div className='form-wrap'>
                              <ContactForm
                                formData={clientData}
                                updateComponent={() => getClient(params)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <Payments updateComponent={() => getClient(params)} />
                  </TabPanel>
                  {
                    ENABLE_MT ?
                      <>
                        <TabPanel>
                          <TradingActivity />
                        </TabPanel>
                        <TabPanel>
                          <OpenedPositions />
                        </TabPanel>
                        <TabPanel>
                          <Deals />
                        </TabPanel>
                        <TabPanel>
                          <TradingAccounts
                            status={clientData.user_profile.status}
                            initialAllowDemo={clientData.allow_demo_account}
                          />
                        </TabPanel>
                      </> : null
                  }
                  {
                    PermissionListCallback ?
                      <TabPanel>
                        <Callbacks />
                      </TabPanel>
                      : null
                  }
                  <TabPanel>
                    <Files uuid={clientData.uuid} />
                  </TabPanel>
                  <TabPanel>
                    <NotesTabs
                      permission={PermissionNotesCreate}
                      updateHeadNotes={() => getClient(params)}
                    />
                  </TabPanel>
                  {
                    PermissionViewUserLogs ?
                      <TabPanel>
                        <Feed />
                      </TabPanel>
                      : null
                  }
                  {
                    PermissionShowTableCryptoWalletsUserById ?
                      <TabPanel>
                        <CryptoWalletsTab />
                      </TabPanel>
                      : null
                  }
                </Tabs>
              </div>
            </div>
          </div>
        </Page>
      )}
    </DashboardLayout>
  );
};

export default Client;
