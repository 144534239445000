import { CaretRight } from 'phosphor-react';
import React from 'react';
import {
  FormattedMessage,
} from 'react-intl';
import UsersSvg from '../components/ui/Icons/UsersSvg.tsx';
import BellSvg from '../components/ui/Icons/BellSvg.tsx';
import BreifCase from '../components/ui/Icons/BreifCase.tsx';
import CreditCardSvg from '../components/ui/Icons/CreditCardSvg.tsx';
import DiagramSvg from '../components/ui/Icons/DiagramSvg.tsx';
import KeyHoleSvg from '../components/ui/Icons/KeyHoleSvg.tsx';
import PhoneSvg from '../components/ui/Icons/PhoneSvg.tsx';
import QuestionSvg from '../components/ui/Icons/QuestionSvg.tsx';
import UserCheck from '../components/ui/Icons/UserCheck.tsx';
import Settings from '../assets/img/settings.png';


export const topMenuItems = [
  {
    title: <FormattedMessage id='menu.clients' defaultMessage='Clients' />,
    key: 'clients',
    itemId: '#1',
    elemBefore: () => <UsersSvg />,
    subNav: [
      {
        title: <FormattedMessage id='menu.searchClients' defaultMessage='Search clients' />,
        key: 'search_clients',
        itemId: '/search-clients',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id='menu.kycDocuments' defaultMessage='KYC documents' />,
        key: 'kyc_documents',
        itemId: '/documents',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: <FormattedMessage id='menu.tradingAccount' defaultMessage='Trading Accounts' />,
        key: 'trading_accounts',
        itemId: '/trading-accounts',
        elemBefore: () => <CaretRight size={15} />,
      },
    ],
  },
  {
    title: <FormattedMessage id='menu.leads' defaultMessage='Leads' />,
    key: 'leads',
    itemId: '/search-leads',
    elemBefore: () => <UserCheck />,
  },
  {
    title: 'Management',
    itemId: '#2',
    key: 'management',
    elemBefore: () => <BreifCase />,
    subNav: [
      {
        title: 'Offices',
        key: 'offices',
        itemId: '/management-offices',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: 'Desks',
        key: 'desks',
        itemId: '/management-desks',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: 'Teams',
        key: 'teams',
        itemId: '/management-teams',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: 'Operators',
        key: 'operators',
        itemId: '/management-operators',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: 'Affiliates',
        key: 'partners',
        itemId: '/management-partners',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: 'Sales rules',
        key: 'sales_rules',
        itemId: '/sales-rules',
        elemBefore: () => <CaretRight size={15} />,
      },
    ],
  },
  {
    title: 'Notifications',
    key: 'notifications',
    itemId: '/notifications',
    elemBefore: () => <BellSvg />,
  },
  {
    title: 'Payments',
    key: 'payments',
    itemId: '/payments',
    elemBefore: () => <CreditCardSvg />,
  },
  {
    title: 'Callbacks',
    key: 'callbacks',
    itemId: '/callbacks/list',
    elemBefore: () => <PhoneSvg />,
  },
  {
    title: 'Customer Service',
    key: 'customer_service',
    itemId: '#3',
    elemBefore: () => <QuestionSvg />,
    subNav: [
      {
        title: 'Crypto Wallets',
        key: 'crypto_wallets',
        itemId: '/customer-crypto',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: 'Crypto Transactions',
        key: 'crypto_transactions',
        itemId: '/customer-transactions',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: 'Crypto Withdraw',
        key: 'crypto_requests',
        itemId: '/crypto-output',
        elemBefore: () => <CaretRight size={15} />,
      },
    ],
  },
  {
    title: 'Hierarchy',
    key: 'hierarchy',
    itemId: '/hierarchy',
    elemBefore: () => <DiagramSvg />,
  },
  {
    title: 'Roles and Permissions',
    key: 'roles_and_permissions_section',
    itemId: '#5',
    elemBefore: () => <KeyHoleSvg />,
    subNav: [
      {
        title: 'Roles and Permissions',
        key: 'roles_and_permissions',
        itemId: '/roles-end-permissions',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: 'Templates',
        key: 'templates',
        itemId: '/template-roles',
        elemBefore: () => <CaretRight size={15} />,
      },
    ],
  },
  {
    title: 'Settings PC',
    key: 'settings',
    itemId: '#4',
    elemBefore: () => <img src={Settings} alt='menu' />,
    subNav: [
      {
        title: 'Styles',
        key: 'style_pc',
        itemId: '/settings-pc-style',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: 'Dynamic statuses',
        key: 'dynamic_statuses',
        itemId: '/settings-pc-dynamic-statuses',
        elemBefore: () => <CaretRight size={15} />,
      },
      {
        title: 'System messages',
        key: 'system_messages',
        itemId: '/settings-pc-system-messages',
        elemBefore: () => <CaretRight size={15} />,
      },
    ],
  },
];

export const bottomMenuitems = [];

export const MAIN_MENU_TYPE = {
  SIDE_MENU: 'side_menu',
  SIDE_MENU_SEMI_BIG: 'side_menu_semi_big',
  BURGER: 'burger_menu',
};
