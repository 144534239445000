import { Trash } from 'phosphor-react';
import React from 'react';
import styles from './add-departent.module.scss';
import classNames from 'classnames/bind';
import { usePermission } from '../../../../../utils/usePermission';
import { RoleWithDepartment } from 'models/RolesAndPermissions/Department';

type Props = {
  item: RoleWithDepartment;
  onDeleteDepartment: (role: RoleWithDepartment) => void;
}

const DepartmentItem = (props: Props) => {
  const { item, onDeleteDepartment } = props;

  const { permissionGiven: PermissionDeleteRoleOperator } = usePermission('admin.admin-user.role-delete');

  const cx = classNames.bind(styles);
  return (
    <div className={cx('departmentItem')}>
      <span className={cx('title_item')}>{item.department?.name} </span>
      <span className={cx('title_item', 'arrow')}>-</span>
      <span className={cx('title_item', 'for-mobile')}>↓</span>
      <span className={cx('title_item')}>{item.name}</span>
      {PermissionDeleteRoleOperator ?
        <button
          className={cx('trashBtn')}
          onClick={() => {
            onDeleteDepartment(item);
          }}
        >
          <Trash size={20} />
        </button> : null}
    </div>
  );
};
export default DepartmentItem;
