import * as React from 'react';

const KeyHoleSvg = (props) => (
  <svg
    width={25}
    height={25}
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M12.706 12.71a2 2 0 1 0 0-4 2 2 0 0 0 0 4Zm0 0v3m8-3.5c0 4.46-5.46 7.693-7.359 8.682-.205.107-.308.16-.45.189a1.133 1.133 0 0 1-.382 0c-.143-.028-.245-.082-.45-.189-1.9-.989-7.36-4.221-7.36-8.682V8.427c0-.8 0-1.2.131-1.543a2 2 0 0 1 .547-.789c.276-.243.65-.383 1.399-.664l5.362-2.01c.208-.079.312-.118.419-.133a1 1 0 0 1 .286 0c.107.015.21.054.418.132l5.362 2.01c.749.282 1.123.422 1.399.665a2 2 0 0 1 .547.79c.13.343.13.743.13 1.542v3.783Z'
      stroke='#131A29'
      strokeWidth={2}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default KeyHoleSvg;
