import classNames from 'classnames/bind';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  actionTA,
  blockTradingAccountTrading,
  changeTaPassword,
  deleteTA,
  findTradingAccByUserMethod,
  getTradingAccBalance,
  getTradingAccStatuses,
  getTradingAccType,
  refreshApiKey,
} from '../../../../../api/tradingAcc';
import Button from '../../../../../components/ui/Button';
import TradingAccountsTable from '../../../../../components/ui/Table/TradingAccountsTable/TradingAccountsTable';
import TablePagination from '../../../../../components/ui/TablePagination';
import { checkMtStatus } from "../../../../../utils/checkMtStatusInfo";
import { getTablePageParams } from '../../../../../utils/getTablePageParams';
import { notify } from '../../../../../utils/notify';
import { usePermission } from '../../../../../utils/usePermission';
import styles from './../../client-page.module.scss';
import AddTradingAccountPopup from './AddTradingAccountPopup';
import TradingAccFilter from './TradingAccFilter/TradingAccFilter';
import Toggle from 'react-toggle'
import { disableClientDemoAccounts, enableClientDemoAccounts } from 'api/clients';
import { useAppSelector } from 'store';
import { getEnableWebTrader } from 'constants/selectors';

const cx = classNames.bind(styles);

interface ITableMeta {
  per_page: number,
  current_page: number,
  last_page: number,
}

interface ITableLinks {
  prev: null | string,
  next: null | string,
}

const TradingAccounts = ({ status, initialAllowDemo }) => {
  const { permissionGiven: PermissionCreateTradingAccount } = usePermission('admin.trading-account.create');
  const { permissionGiven: PermissionShowTradingAccount } = usePermission('admin.trading-account.show');
  const { permissionGiven: PermissionToggleDemoAccount } = usePermission('admin.trading-account.edit-demo');

  const [demoAllowed, setDemoAllowed] = useState(initialAllowDemo);
  const wtEnabled = useAppSelector(getEnableWebTrader);
  
  useEffect(() => {
    setDemoAllowed(initialAllowDemo)
  }, [initialAllowDemo])

  const [tradingAccList, setTradingAccList] = useState([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [accountTypeOpt, setAccountTypeOpt] = useState([]);
  const [taStatusesOpt, setTaStatusesOpt] = useState([]);
  const [filters, setFilters] = useState(null);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<ITableMeta>();
  const [tableLinks, setTableLinks] = useState<ITableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const { id } = useParams();

  const componentMounted = useRef(true);

  const fetchTradingAccList = useCallback((options, data): void => {
    setIsListLoading(true);

    findTradingAccByUserMethod(getTablePageParams(options, perPageCount, tableLinks), data, id)
        .then((res): void => {
          if (res.status === 200) {
            setTradingAccList(res.data.data);
            setTableMeta(res.data.meta);
            setTableLinks(res.data.links);
            setQueryResponseMessage(res?.data?.message || '');
          }
        })
        .catch((error): void => {
          if (error && error.response.data !== null) {
            notify({
              type: 'error',
              message: error.response,
              timeOut: 3000,
            });
          }
        })
        .finally(() => {
          setIsListLoading(false)
        });
  }, [id, perPageCount]);

  const deleteTradingAccount = (ta_id): void => {
    setIsListLoading(true);

    deleteTA(ta_id)
      .then((res): void => {
        if (res.status === 202) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          fetchTradingAccList(null, null);
        }
      })
      .catch((error): void => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally((): void => {
        setIsListLoading(false);
      });
  };

  const actionTaMethod = (data): void => {
    setIsListLoading(true);
    const postData = {
      user_id: id,
      ta_id: data.taId,
      blocked: data.blocked,
    };

    actionTA(postData)
      .then((res): void => {
        if (res.status === 202) {
          fetchTradingAccList(null, null);
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
        }
      })
      .catch((error): void => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally((): void => {
        setIsListLoading(false);
      });
  };

  const blockTaTradingMethod = (id: string, value: boolean) => {
    setIsListLoading(true);
    
    blockTradingAccountTrading(id, value)
      .then((res): void => {
        if (res.status === 202) {
          fetchTradingAccList(null, null);
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
        }
      })
      .catch((error): void => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally((): void => {
        setIsListLoading(false);
      });
  }

  const changeTaPasswordMethod = (taId: string, data: any): void => {
    setIsListLoading(true);

    const postData = {
      password: data.password,
      password_confirmation: data.password_confirmation,
    };

    changeTaPassword(taId, postData)
        .then((res) => {
          if (res.status === 200) {
            fetchTradingAccList(null, null);
            notify({
              type: 'success',
              message: res.data.message,
              timeOut: 3000,
            });
          }
        })
        .catch((error) => {
          if (error && error.response.data !== null) {
            notify({
              type: 'error',
              message: error.response,
              timeOut: 3000,
            });
          }
        })
        .finally(() => {
          setIsListLoading(false);
        })
  };

  const fetchTradingAccTypeList = async (): Promise<void> => {
    setIsListLoading(true);

    return getTradingAccType()
      .then((res): void => {
        if (res.status === 200) {
          setAccountTypeOpt(
            res.data.data.map((el) => ({
              value: el,
              label: el,
            })),
          );
        }
      })
      .catch((error): void => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally((): void => {
        setIsListLoading(false);
      });
  };

  const fetchTAStatusesOpt = (): void => {
    getTradingAccStatuses()
      .then((res): void => {
        if (res.status === 200) {
          setTaStatusesOpt(
            res.data.data.map((item) => ({
              value: item,
              label: item,
            })),
          );
        }
      })
      .catch((error): void => {
        if (error && error.response.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect(() => {
    const fetchTradingData = async () => {
      await fetchTradingAccTypeList();
      fetchTradingAccList(null, null);
      fetchTAStatusesOpt();
    };

    void (fetchTradingData());

    return (): void => {
      componentMounted.current = false;
    };
  }, [fetchTradingAccList, status]);

  const refreshApiToken = (taId): void => {
    setIsListLoading(true);
    refreshApiKey(id, taId)
      .then((res): void => {
        if (res.status === 202) {
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
          fetchTradingAccList(null, null);
        }
      })
      .catch((error): void => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsListLoading(false));
  };

  const getBalance = (tradAcc, id): void => {
    getTradingAccBalance(tradAcc)
      .then(data => {
        setTradingAccList(tradingAccList.map(item => {
          if (item.trading_account === id) {
            return item.balance = data.data.balance;
          } else return item;
        }));
        fetchTradingAccList(null, null);
      })
      .catch((err): void => {
        checkMtStatus()
        notify({
          type: 'error',
          message: err.message,
          timeOut: 2000,
        });
      });
  };

  const onSubmit = (data): void => {
    setFilters(data);
    fetchTradingAccList({ navigate: 'first', perPage: data?.filter?.limit }, data);
  };

  return (
    <div className={cx('tab-panel')}>
      <div className={cx('tab-panel__content')}>
        <div className={cx('tab-panel__header')}>
          <div className={cx('tab-panel__title')}>Trading Accounts</div>
          <div className={cx('tab-panel__action')}>
            {PermissionCreateTradingAccount ?
              <AddTradingAccountPopup
                updateComponent={() => fetchTradingAccList(null, null)}
                userId={id}
                triggerBtn={
                  <Button buttonText='+ Add trading account' buttonType='outline' onClick={null} />
                }
              />
              : null}
          </div>
        </div>
        {PermissionToggleDemoAccount && wtEnabled && !isListLoading && (
          <div style={{ display: 'flex', flexDirection: 'row', marginBottom: '12px', gap: 8 }}>
            <p>Allow this client to create demo accounts</p>
            <Toggle 
              icons={false} 
              checked={Boolean(Number(demoAllowed))} 
              onChange={(e) => {
                if (e.target.checked) {
                  enableClientDemoAccounts(id);
                } else {
                  disableClientDemoAccounts(id);
                }
                setDemoAllowed(e.target.checked)
              }}
            />
          </div>
        )}
        <div className={cx('container__block')}>
          <TradingAccFilter
            taStatusesOpt={taStatusesOpt}
            taTypesOpt={accountTypeOpt}
            onSubmit={onSubmit}
            reloadFilters={isListLoading}
            queryMessage={queryResponseMessage}
                      />
        </div>
        {PermissionShowTradingAccount ?
          <div className={cx('tab-panel-table')}>
            <TradingAccountsTable
              data={tradingAccList}
              perPage={tableMeta?.per_page}
              showLoader={isListLoading}
              userId={id}
              getBalance={(tradAcc, id) => getBalance(tradAcc, id)}
              // refreshApiToken={(taId) => refreshApiToken(taId)}
              actionTaMethod={(type) => actionTaMethod(type)}
              blockTaTradingMethod={(id, value) => blockTaTradingMethod(id, value)}
              deleteTradingAccount={(ta_id) => deleteTradingAccount(ta_id)}
              updateComponent={() => fetchTradingAccList(null, null)}
              changeTaPasswordMethod={changeTaPasswordMethod}
            />
            {tradingAccList?.length ? (
              <TablePagination
                hidePageSelect={true}
                goToFirstPage={(): void => {
                  fetchTradingAccList({ navigate: 'first' }, null);
                }}
                goToPrevPage={(): void => {
                  fetchTradingAccList({ navigate: 'prev' }, null);
                }}
                goToNextPage={(): void => {
                  fetchTradingAccList({ navigate: 'next' }, null);
                }}
                goToLastPage={(): void => {
                  fetchTradingAccList({ navigate: 'last' }, null);
                }}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value): void => {
                  setPerPageCount(value);
                  fetchTradingAccList({ perPage: value }, null);
                }}
                goToSelectedPage={(page): void => {
                  fetchTradingAccList({ page }, null);
                }}
                isFirstPageDisable={(): boolean => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={(): boolean => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : (
              ''
            )}
          </div>
          : null
        }
      </div>
    </div>
  );
};

export default TradingAccounts;
