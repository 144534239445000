import { getCountryList } from 'api/country'
import classNames from 'classnames/bind'
import Button from 'components/ui/Button'
import moment from 'moment'
import Page from 'pages/Page/Page'
import { DashboardLayout } from 'pages/UserView/DashboardLayout'

import { CaretDown } from 'phosphor-react'
import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'
import { copyToBuffer } from 'utils/copyToBuffer'
import { getDictionaryMethod } from '../../../../api/managements/operators/index'
import { activationPartnerMethod, getPartnerMethod } from '../../../../api/partners/index'
import { getShortUuid } from '../../../../utils/getShortUuid.js'
import { getTimeAgo } from '../../../../utils/getTimeAgo.js'

import { notify } from '../../../../utils/notify.tsx'
import { usePermission } from '../../../../utils/usePermission.tsx'

import styles from './partner-page.module.scss'
import PartnerPersonalInfoForm from './PartnerForms/PartnerPersonalInfoForm'
import PartnerChangePasswordPopup from './PartnerPopups/PartnerChangePasswordPopup'
import PartnerSchedules from './PartnerShedules/index.js'
import Feed from './PartnerTabs/Feed/index.js'
import SalesRules from './PartnerTabs/SalesRules/index.js'
import { ManagementPartner } from 'models/Management/Partners/Partner.js'
import { CountryListValue } from 'models/Countries.js'
import { ManagementPartnerSchedule } from 'models/Management/Partners/Schedule.js'
import { ListValue } from 'models/ListValue'

const cx = classNames.bind(styles)

const PartnerPage = () => {

  const { permissionGiven: PermissionShow } = usePermission('admin.partner.show', true)
  const { permissionGiven: PermissionChangePass } = usePermission('admin.partner.change-password')
  const { permissionGiven: PermissionActivate } = usePermission('admin.partner.active')
  const { permissionGiven: PermissionShowSchedule } = usePermission('admin.partner-schedule.show')
  const { permissionGiven: PermissionViewPartnerLogs } = usePermission('admin.logs.partner')

  const params = useParams()

  const [nameItem, setItemName] = useState('')
  const [partnerData, setPartnerData] = useState<ManagementPartner>()
  const [isListLoading, setIsListLoading] = useState(true)
  const [openAccStatus, setOpenAccStatus] = useState(false)
  const [showNotes, setShowNotes] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [countryListArr, setCountryListArr] = useState<CountryListValue[]>([])
  const [statusList, setStatusList] = useState<string[]>([])
  const [scheduleList, setScheduleList] = useState<ManagementPartnerSchedule>()

  const title = 'Affiliate - ' + nameItem
  const dropMenu = useRef(null)
  const componentMounted = useRef(true)
  const changedStatus = (status) => {
    setOpenAccStatus(!openAccStatus)
    let data = {
      active: status === 'active' ? 1 : 0,
    }
    activationPartnerMethod(partnerData.id, data)
      .then((res) => {
        if (res.status === 200) {
          getPartner(params)
          notify({
            type: 'success',
            message: 'status changed',
            timeOut: 3000,
          })
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response.data.message,
          timeOut: 3000,
        })
      })
  }
  const fetchStatusList = () => {
    getDictionaryMethod().then((res) => {
      if (res.status === 200) {
        let arrFilter = res.data.operator_statuses.filter((item) => item != partnerData?.status)
        setStatusList(arrFilter)
      }
    })
  }
  useEffect(() => {
    fetchStatusList()
    return () => {
      componentMounted.current = false
    }
  }, [])

  useEffect(() => {
    getCountryList().then((res) => {
      if (res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.id,
          label: opt.name,
          iso_3166_2: opt.iso_3166_2,
        }))
        setCountryListArr([...opt])
      }
    })
  }, [PermissionShow])
  const getPartner = (params) => {
    setIsListLoading(true)

    getPartnerMethod(params.id)
      .then((res) => {
        if (res.status === 200) {
          setPartnerData(res.data.data)
          setScheduleList(res.data.data.schedules)
          setIsLoading(false)
          setItemName(res.data.data.first_name)
        }
      })
      .catch((err) => {
        setIsListLoading(false)
      })
  }

  useEffect(() => {
    document.title = title
  }, [title])

  useEffect(() => {
    getPartner(params)
  }, [params])
  useEffect(() => {
    partnerData && fetchStatusList()
  }, [partnerData])
  const accountStatus = () => (
    <>
      {!isLoading && (
        <>
          <div className={cx('client-info__col-title')}>Account status</div>
          <div className={cx('client-info__col-content')}>
            <div className={cx('info-block')}>
              <div className={cx('info-block__value')} ref={dropMenu}>
                <div className={cx('statusValue')}>
                  <div className={cx('statusValue__value', partnerData.status)}>
                    <span
                      className={cx('status_span')}
                      onClick={() => setOpenAccStatus(!openAccStatus)}
                    >
                      {partnerData && partnerData.status ? partnerData.status : '---'}
                    </span>
                    <div
                      className={cx('value-icon', openAccStatus ? 'active' : '')}
                      onClick={() => {
                        if (PermissionActivate) {
                          setOpenAccStatus(!openAccStatus)
                        }
                      }}
                    >
                      <CaretDown size={16}/>
                    </div>
                  </div>
                  <div className={cx('statusValue__descr')}>
                    {partnerData.status_updated_at && (
                      <span>
                        since{' '}
                        {moment(partnerData.status_updated_at * 1000).format('DD.MM.YYYY HH:mm:ss')}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className={cx('info-block__action-container', openAccStatus ? 'open' : '')}>
                <ul className={cx('accActionsList')}>
                  {statusList.map((item, ind) => {
                    return (
                      <li
                        className={item === 'active' ? 'unblock' : 'blocked'}
                        key={ind}
                        onClick={() => changedStatus(item)}
                      >
                        {item}
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  )

  const registered = () => (
    <>
      <div className={cx('client-info__col-title')}>Registered</div>
      <div className={cx('client-info__col-content')}>
        <div className={cx('strong')}>
          {partnerData && partnerData.created_at ? getTimeAgo(partnerData.created_at) : '-------'}
        </div>
        <div>
          on{' '}
          {partnerData && partnerData.created_at
            ? moment(partnerData.created_at * 1000).format('DD.MM.YYYY HH:mm')
            : '---'}
        </div>
      </div>
    </>
  )

  const personalInfo = () => (
    <div className={cx('personalInfo')}>
      <div className={cx('personalInfo__col')}>
        <div className={cx('personalInfo__col-title')}>Personal information</div>
        <div className={cx('personalInfo__col-content')}>
          <div>
            <b>Name: </b>
            {partnerData && partnerData.first_name}
          </div>
          <div>
            <b>Email: </b>
            {partnerData && partnerData.email ? partnerData.email : '---'}
          </div>
          <div>
            <b>Phone number: </b>
            {partnerData && partnerData.phone ? partnerData.phone : '---'}
          </div>
          {partnerData && partnerData.created_at ? (
            <div>
              <b>Registration date: </b>
              <span>{moment.unix(partnerData.created_at).format('DD.MM.YYYY')}</span>{' '}
              <span>{moment.unix(partnerData.created_at).format('HH:mm')}</span>
            </div>
          ) : (
            ''
          )}
        </div>
      </div>
      {/*<div className={cx('personalInfo__col')}>*/}
      {/*  <div className={cx('personalInfo__col-title')}>Affiliation information</div>*/}
      {/*  <div className={cx('personalInfo__col-content')}>*/}
      {/*    <p>{'POST /api/v1/affiliate/clients'}</p>*/}
      {/*    <p>{'Host: {crm_domain}'}</p>*/}
      {/*    <p>{'login: {partner_login}'}</p>*/}
      {/*    <p>{'password: {partner_password}'}</p>*/}
      {/*    <p>{'Content-Type: text/plain'}</p>*/}
      {/*    <p>{'Content-Length: 218'}</p>*/}
      {/*    <p className={cx('wrapp_text')}>*/}
      {/*      {'{"firstname":"affilate_name","lastname":"affilate_lastname","country":"ua","email":"affilate@mail.com",' +*/}
      {/*        '"password":"password","phone":"447999153509","source":"lblv.com","language":"en","ip":"127.0.0.1"}'}*/}
      {/*    </p>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  )

  return (
    <DashboardLayout>
      {!isLoading && (
        <Page>
          <div className={cx('container')}>
            <div className={cx('container__header')}>
              <div className={cx('client')}>
                <div className={cx('client__name')}>{nameItem}</div>
                <div
                  className={cx('client__identificator')}
                  onClick={() => {
                    copyToBuffer(partnerData.uuid)
                  }}
                >
                  {getShortUuid(partnerData.uuid)}
                </div>
              </div>
              <div className={cx('action')}>
                {
                  PermissionChangePass ?
                    <PartnerChangePasswordPopup
                      updateComponent={() => getPartner(params)}
                      userId={params.id}
                      triggerBtn={
                        <Button
                          buttonType='outline'
                          buttonText='Change password'
                          onClick={() => setShowNotes(!showNotes)}
                        />
                      }
                    /> : null
                }
              </div>
            </div>
            <div className={cx('container__block')}>
              <div className={cx('client-info')}>
                <div className={cx('client-info__col')}>{accountStatus()}</div>
                <div className={cx('client-info__col')}>{registered()}</div>
              </div>
            </div>
            <div className={cx('container__block')}>{personalInfo()}</div>

            <div className={cx('container__block')}>
              <div className={cx('profile__tabs')}>
                <Tabs className={cx('tabs')}>
                  <TabList>
                    <Tab>Profile</Tab>
                    <Tab>Sales Rules</Tab>
                    {PermissionViewPartnerLogs ?
                      <Tab>Feed</Tab>
                      : null
                    }
                  </TabList>
                  <TabPanel>
                    <div className={cx('tab-panel')}>
                      <div className={cx('tab-panel__content')}>
                        <div className={cx('tab-panel__content-col', 'w100')}>
                          <div className={cx('tab-panel__content-box')}>
                            <div className='form-wrap'>
                              <PartnerPersonalInfoForm
                                formData={partnerData}
                                countryList={countryListArr}
                                updateComponent={() => getPartner(params)}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </TabPanel>
                  <TabPanel>
                    <SalesRules salesRulesList={partnerData?.sale_rules}/>
                  </TabPanel>
                  {PermissionViewPartnerLogs ?
                    <TabPanel>
                      <Feed/>
                    </TabPanel>
                    : null
                  }
                </Tabs>
              </div>
            </div>
            <div className={cx('container__block')}>
              <div className={cx('tab-panel')}>
                <div className={cx('tab-panel__content')}>
                  <div className={cx('tab-panel__content-col', 'w100')}>
                    <div className={cx('tab-panel__content-box')}>
                      <div className='form-wrap'>
                        {
                          PermissionShowSchedule ?
                            <PartnerSchedules
                              countryOpt={countryListArr}
                              list={scheduleList}
                              modifyDataHandler={() => getPartner(params)}
                            /> : null
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Page>
      )}
    </DashboardLayout>
  )
}

export default PartnerPage
