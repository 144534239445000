import InfiniteScroll from "react-infinite-scroll-component";
import Preloader from "../../../../components/ui/Preloader";
import { useEffect, useRef, useState } from "react";
import { ChatMessage } from "../ChatMessage/ChatMessage";
import { ChatMessageType } from "../../../../models/Chat";
import {
    selectFoundMessageId,
    selectScrollToTheLast,
    selectThread,
    selectUserStatuses, setFoundMessageId, setScrollToTheLast
} from "../../../../store/slice/chat/chatSlice";
import { useAppDispatch, useAppSelector } from "../../../../store";
import { ChatFoundMessageAnimationDuration, ChatInfiniteScrollStyle, ChatScrollToBottomOffset } from "./constants";
import { ArrowCircleDown } from "phosphor-react";
import './ChatMessagesList.scss';

type ChatMessagesListProps = {
    messages: ChatMessageType[],
    selectedMessage: ChatMessageType,
    handleContextMenu: (event: any, entity: ChatMessageType) => void,
    loadMore: () => Promise<void>,
    hasMore: boolean
};

export const ChatMessagesList = ({ messages, selectedMessage, handleContextMenu, loadMore, hasMore }: ChatMessagesListProps) => {
    const userStatuses = useAppSelector(selectUserStatuses);
    const scrollToTheLast = useAppSelector(selectScrollToTheLast);
    const thread = useAppSelector(selectThread);
    const foundMessageId = useAppSelector(selectFoundMessageId);

    const [showScrollButton, setShowScrollButton] = useState(false);

    const lastMessageRef = useRef(null);
    const foundMessageRef = useRef(null);
    const scrollPosition = useRef(0);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (scrollToTheLast) {
            lastMessageRef?.current?.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, scrollToTheLast]);

    useEffect(() => {
        let timeout;
        if (foundMessageId && foundMessageRef.current) {
            foundMessageRef.current.scrollIntoView({ block: 'center', behavior: 'smooth' });
            foundMessageRef.current.style.animationDuration = ChatFoundMessageAnimationDuration + 'ms';
            foundMessageRef.current.style.animationFillMode = "forwards";
            timeout = setTimeout(() => {
                foundMessageRef.current.style = '';
                dispatch(setFoundMessageId(''));
            }, ChatFoundMessageAnimationDuration);
        }

        return () => {
            clearTimeout(timeout)
        }
    }, [foundMessageId]);

    const handleScrollBottom = () => {
        dispatch(setScrollToTheLast(true));
        lastMessageRef?.current?.scrollIntoView({ behavior: 'smooth' });
        setShowScrollButton(false);
    };

    const handleScrollMessagesList = (e: MouseEvent) => {
        const newScrollPosition = (e.target as HTMLElement)?.scrollTop || 0;
        // scroll down
        if (newScrollPosition > scrollPosition.current) {
            showScrollButton && newScrollPosition > -ChatScrollToBottomOffset && setShowScrollButton(false);

            if (newScrollPosition === 0) {
                !scrollToTheLast && dispatch(setScrollToTheLast(true));
            }
        // scroll up
        } else {
            if (!showScrollButton && newScrollPosition < -ChatScrollToBottomOffset) {
                setShowScrollButton(true);
                scrollToTheLast && dispatch(setScrollToTheLast(false));
            }
        }
        scrollPosition.current = newScrollPosition;
    };

    return (
        <div className="chat-messages-overflow" id="messagesOverflow">
            {showScrollButton ?
              <div className='scroll-bottom' onClick={handleScrollBottom}>
                  <ArrowCircleDown size={22} />
              </div> : null}
            {messages.length ? (
              <InfiniteScroll
                scrollableTarget='messagesOverflow'
                dataLength={messages.length}
                next={loadMore}
                inverse={true}
                hasMore={hasMore}
                style={ChatInfiniteScrollStyle}
                height='100%'
                loader={<div className='scroll-loader'><Preloader/></div>}
                onScroll={handleScrollMessagesList}
              >
                <span ref={lastMessageRef}/>
                {messages.map(message => (
                    <ChatMessage
                        message={message}
                        userStatus={userStatuses[message.owner_id]}
                        activeViaContextMenu={selectedMessage?.id === message.id}
                        onContextMenu={handleContextMenu}
                        isSupportChat={!!thread?.is_support}
                        foundMessageRef={message.id === foundMessageId ? foundMessageRef : null}
                        key={message.id}
                    />
                    )
                )}
              </InfiniteScroll>
            ): null }
        </div>
    )
}