import cn from "classnames";
import { BellSlash, PushPin  } from "phosphor-react";
import { ChatAvatar } from "../ChatAvatar/ChatAvatar";
import { ChatDate } from "../ChatDate/ChatDate";
import { OnlineStatusesVerbose, Thread, UserTypeLabels } from "../../../../models/Chat";
import { unEscapeHtml } from "../../helpers/unscapeHtml";
import { ThreadMessageBody } from "./ThreadMessageBody";
import './ChatThread.scss';

type ChatThreadProps = {
    thread: Thread;
    userId: string;
    status?: OnlineStatusesVerbose;
    active: boolean;
    isMuted: boolean;
    handleContextMenu: (event: any, entity: any) => void;
    onClick: () => void;
};

export const ChatThread = ({ userId, thread, status, handleContextMenu, active, isMuted, onClick }: ChatThreadProps) => {
    const { name, resources, unread_count, group} = thread;
    const { latest_message, recipient } = resources;
    const isOwnMessage = userId === latest_message?.owner_id;
    const isOnline = status === OnlineStatusesVerbose.ONLINE;

    return (
        <div
            className={cn("chat-card", { hasUnread: !!unread_count }, { active })}
            onClick={onClick}
            onContextMenu={event => handleContextMenu(event, thread)}
        >
            <ChatAvatar name={name} status={isOnline} className={group ? 'group' : UserTypeLabels[recipient.base.type]}/>
            <div className="chat-card-content">
                <div className="message-heading">
                    <div className="chat-card-name">{unEscapeHtml(name)}</div>
                    <div className="dialog-statuses">
                        {thread.pinned ? <PushPin color="#722ED1FF" size={12}/> : null}
                        {isMuted ? <BellSlash color="#722ED1FF" size={12}/> : null}
                    </div>
                    <ChatDate date={latest_message?.updated_at} dateFormat='time' className='time-format'/>
                    <ChatDate date={latest_message?.updated_at} dateFormat='date' className='date-format'/>
                </div>
                {latest_message ? <ThreadMessageBody
                    isOwnMessage={isOwnMessage}
                    isGroupChat={group}
                    message={latest_message}
                    unreadCount={unread_count}
                /> : null}
            </div>
        </div>
    )
};
