import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
import { ArrowsClockwise } from 'phosphor-react';
import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import DatePickerRange from 'components/ui/DatePickerRange';
import TablePagination from 'components/ui/TablePagination';

import { getOperatorFeedList, getOperatorsActionList } from 'api/feed';
import { getPartnerFeedList } from '../../../../../../api/partners';
import { isFormChange } from '../../../../../../utils/isFormChange';
import FeedTable from '../../../../../../components/ui/Table/FeedTable';
import { notify } from '../../../../../../utils/notify';
import Select from '../../../../../../components/ui/Select';
import Button from '../../../../../../components/ui/Button';
import { TableLinks, TableMeta } from 'models/Table';
import { ManagementFeedTradingAccount } from 'models/Management/Partners/Feed/TradingAccount';
import { ManagementPartnerFeedAction } from 'models/Management/Partners/Feed/Action';
import { ManagementPartnerFeedFilter } from 'models/Management/Partners/Feed/Filter';

const cx = classNames.bind(styles);

const Feed = () => {
  const [tradingAccList, setTradingAccList] = useState<ManagementFeedTradingAccount[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [selectedRows, setSelectedRows] = useState<number[]>(null);

  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const { id } = useParams();
  const [filterData, setFilterData] = useState<ManagementPartnerFeedFilter>(null);
  const [actionList, setActionList] = useState<ManagementPartnerFeedAction[]>([]);

  const componentMounted = useRef(true);

  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const [isLoading, setIsLoading] = useState(false);
  //
  const onSubmit = (data) => {
    setIsLoading(true);
    let created_at_from = new Date(data.created_at?.from).getTime();
    let created_at_to = new Date(data.created_at?.to).getTime();
    const sendData = {
      ...data,
      from: created_at_from / 1000,
      to: created_at_to / 1000,
    };
    setFilterData(sendData);
  };
  useEffect(() => {
    getOperatorsActionList().then((res) => {
      if (res.status === 200) {
        let arr = Object.keys(res.data);
        const opt = arr.map((opt) => ({
          id: opt,
          name: opt,
        }));
        setActionList([...opt]);
      }
    });
  }, []);
  useEffect(() => {
    filterData && fetchFeedList(filterData);
  }, [filterData]);

  const fetchFeedList = (options?) => {
    setIsListLoading(true);

    getPartnerFeedList(getTablePageParams(options), id)
      .then((res) => {
        if (res.status === 200) {
          setTradingAccList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);

          setIsListLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect(() => {
    fetchFeedList();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }
    let str = `${toPage}&${perPageParams}`;
    if (filterData?.from) {
      str = str + `&from=${filterData.from}`;
    }
    if (filterData?.to) {
      str = str + `&to=${filterData.to}`;
    }
    if (filterData?.action_type) {
      str = str + `&action_type=${filterData.action_type.id}`;
    }
    return str;
  };

  return (
    <div className={cx('tab-panel', 'callbacks', 'feed')}>
      <div className={cx('tab-panel__content')}>
        <div className={cx('tab-panel__header')}>
          <div className={cx('tab-panel__title')}>Feed</div>
        </div>
        <div className={cx('tab-panel__filter-box')}>
          <div className={cx('tab-panel__filter-box-col')}>
            <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
              <div className={cx('form__line', 'filter-row')}>
                <div className={cx('select-wrap')}>
                  <Select
                    name="action_type"
                    label="Action type"
                    control={control}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    options={actionList}
                  />
                </div>
                <DatePickerRange
                  name="created_at"
                  control={control}
                  label="Date & time range"
                  placeholder="From - To"
                />
              </div>
            </form>
          </div>
          <div className={cx('tab-panel__filter-box-col', 'actin-btn')}>
            <Button
              buttonType="outline"
              className={isListLoading ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise size={18} />}
              onClick={(e) => {
                e.preventDefault();
                onSubmit(getValues());
              }}
            />
            <Button
              buttonType="primary"
              type="button"
              buttonText="Reset"
              disabled={!isFormChange(dirtyFields)}
              onClick={() => {
                reset({
                  action_type: null,
                  created_at: null,
                });
              }}
            />
            <Button
              buttonType="outline"
              buttonText="Apply"
              onClick={handleSubmit(onSubmit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </div>
        <div className={cx('tab-panel-table')}>
          <FeedTable
            data={tradingAccList}
            perPage={perPageCount}
            showLoader={isListLoading}
            onRowSelect={setSelectedRows}
            userId={id}
          />
          {tradingAccList?.length ? (
            <TablePagination
              goToFirstPage={() => {
                fetchFeedList({ navigate: 'first' });
              }}
              goToPrevPage={() => {
                fetchFeedList({ navigate: 'prev' });
              }}
              goToNextPage={() => {
                fetchFeedList({ navigate: 'next' });
              }}
              goToLastPage={() => {
                fetchFeedList({ navigate: 'last' });
              }}
              currentPage={tableMeta?.current_page}
              pagesLength={tableMeta?.last_page}
              perPageChange={(value) => {
                setPerPageCount(value);
                fetchFeedList({ perPage: value });
              }}
              goToSelectedPage={(page) => {
                fetchFeedList({ page });
              }}
              isFirstPageDisable={() => tableMeta?.current_page === 1}
              isPrevPageDisable={() => !tableLinks?.prev}
              isNextPageDisable={() => !tableLinks?.next}
              isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
            />
          ) : (
            ''
          )}
        </div>
      </div>
    </div>
  );
};

export default Feed;
