import { ITable } from "models/components/Table/Table";
import { useEffect, useState } from "react";

interface UseDragAndDropI {
    columns: ITable["columns"],
    saveColumnOrder?: (columns: any) => void;
}   

function moveItemInList<T>(list: T[], fromIndex: number, toIndex: number): T[] {
  if (fromIndex < 0 || fromIndex >= list.length || toIndex < 0 || toIndex >= list.length) {
      throw new Error("Invalid index");
  }

  const itemToMove = list.splice(fromIndex, 1)[0];
  list.splice(toIndex, 0, itemToMove);

  return list;
}

export default function useDragAndDrop({columns, saveColumnOrder}: UseDragAndDropI)  {
    const [dragOver, setDragOver] = useState("");
    const [draggableId, setDraggableId] = useState("");
    const [cols, setCols] = useState([...columns]);
    const [relativeDragOverPosition, setRelativeDragOverPosition] = useState<"prev" | "next">("prev");

    useEffect(()=> {
        setCols(columns);
    }, [columns]);

  const handleDragStart = e => {
    const { id } = e.target;
    setDraggableId(id);
  };

  const handleDragOver = e => {
    const {id} = e.target;
    if (id !== "" && dragOver !== id) {
        setDragOver(id)

    }
    e.preventDefault();
  }
  
  const handleDragEnter = e => {
    const { id } = e.target;
    const dragOverColIdx = cols.findIndex(c => c.id === id);
    const draggableColIdx = cols.findIndex(c => c.id === draggableId);
    if (dragOverColIdx > draggableColIdx) 
    {
      setRelativeDragOverPosition("prev")
    }
    else {
      setRelativeDragOverPosition("next")
    }
    
    setDragOver(id);
  };

  const handleOnDrop = e => {
        const droppedColIdx = cols.findIndex(c => c.id === dragOver);
        const draggableColIdx = cols.findIndex(c => c.id === draggableId);
        if (droppedColIdx === -1 || draggableColIdx === -1) {
          setDragOver("");    
          setDraggableId("");
          return;
        }
        const tempCols = [...cols];
        // tempCols[droppedColIdx] = cols[draggableColIdx];
        // tempCols[draggableColIdx] = cols[droppedColIdx];
        const newOrder = moveItemInList(tempCols, draggableColIdx, droppedColIdx)
        setCols(newOrder);
        if (saveColumnOrder) {
          saveColumnOrder(newOrder);
        }

  };

  const onDragEnd = e => {
    setDragOver("");    
    setDraggableId("");
  }



  if (!saveColumnOrder) {
    return {
      draggableElementProps: {
          draggable: false,
      },
      draggableColumns: cols,
      dragOver,
      dragOn: draggableId,
      relativeDragOverPosition,
  }
  }

    return {
        draggableElementProps: {
            draggable: true,
            onDragStart: handleDragStart,
            onDragOver: handleDragOver,
            onDrop: handleOnDrop,
            onDragEnter: handleDragEnter,
            onDragEnd
        },
        draggableColumns: cols,
        dragOver,
        dragOn: draggableId,
        relativeDragOverPosition
    }
} 

