import { Tooltip } from 'bootstrap';
import classNames from 'classnames/bind';
import { CaretDown, CaretUp } from 'phosphor-react';
import React, { FC, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import Select, { components } from 'react-select';
import { SELECT_DEFAULT_STYLES } from '../../../constants/select';

import styles from './filter-selectSearch.module.scss';
import {
  IJokerSelectWithSearch,
  IOptions,
} from '../../../models/components/JokerSelectWithSearch/JokerSelectWithSearch.ts';

const cx = classNames.bind(styles);

const customStyles = {
  ...SELECT_DEFAULT_STYLES,
  control: (provided, state) => ({
    ...provided,
    padding: '0px 13px',
    boxShadow: '1px solid #262626',
    '&:hover': {
      border: state.isFocused ? '1px solid #262626 !important' : '1px solid #c2c2c2',
    },
    backgroundColor: '#ffffff',
    margin: '10px',
    border: ' 1px solid var(--primary-text-color)',
    position: 'relative',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
    maxHeight: '150px',
    width: '100%',
    borderTop: '1px solid #EBEBEB',
    overflowY: 'auto',
  }),
  container: (provided) => ({
    ...provided,
    border: '1px solid #EBEBEB',
    backgroundColor: '#f5f5f5',
    // borderTopLeftRadius: '5px',
    // borderTopRightRadius: '5px',
  }),
  menu: (provided) => ({
    ...provided,
    padding: 0,
    margin: 0,
    marginTop: 0,
    boxShadow: 'unset',
    border: '1px solid var(--light-grey)',
    overflow: 'hidden',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
  }),
  option: (provided, state) => {
    return {
      ...provided,
      padding: '10px 12px',
      fontSize: '13px',
      lineHeight: '20px',
      fontWeight: 400,
      fontFamily: 'Inter',
      backgroundColor: state.isDisabled
        ? undefined
        : state.isSelected
        ? 'var(--list-selected-option-color)'
        : state.isFocused
        ? 'var(--list-hover-option-color)'
        : undefined,
      ':hover': {
        backgroundColor: 'var(--list-hover-option-color)',
      },
      ':active': {
        // ...styles[':active'],
        backgroundColor: !state.isDisabled ? (state.isSelected ? '#f9f1ff' : '#f9f1ff') : undefined,
      },
      color: '#262626',
    };
  },
  groupHeading: (provided, state) => {
    return {
      ...provided,
      display: 'flex',
      justifyContent: 'space-between',
      paddingTop: '8px',
      color: 'var(--purple)',
      fontFamily: 'Inter',
      fontSize: '12px',
      paddingRight: '10px',
    };
  },
  group: (provided, state) => {
    return {
      ...provided,
    };
  },
};

const Svg = (p) => (
  <svg width="18" height="18" viewBox="0 0 24 24" focusable="false" role="presentation" {...p} />
);

/** лучше исп. JokerSelect */
const JokerSelectWithSearch: FC<IJokerSelectWithSearch> = (props) => {
  const {
    onChange,
    label,
    options,
    defaultValues,
    placeholder,
    isAbsolute = false,
    isRequired = false,
    isDisabled = false,
    errors,
    rules,
    isMulti = false,
    ...other
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const defaultValue: IOptions | null = options && options.length ? options[0] : null;
  const [value, setValue] = useState<IOptions>(defaultValue);
  const { field } = useController(props);

  const toggleOpen = (): void => {
    !isDisabled && setIsOpen(!isOpen);
  };

  const onSelectChange = (value): void => {
    toggleOpen();
    field.onChange(value);
    setValue(value);
    props.onSelectChange?.();
  };

  useEffect(() => setValue(field.value), [field.value]);

  const Blanket = (props) => (
    <div
      style={{
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
        position: 'fixed',
        zIndex: 1,
      }}
      {...props}
    />
  );

  const Menu = (props) => {
    return (
      <div
        style={{
          backgroundColor: 'white',
          borderRadius: 4,
          marginTop: 0,
          position: 'relative',
          zIndex: 999,
          width: '100%',
        }}
      >
        <div
          style={{
            position: 'absolute',
            zIndex: 999,
            width: '100%',
          }}
          {...props}
        />
      </div>
    );
  };

  const Dropdown = ({ children, isOpen, target, onClose }) => (
    <>
      <div style={{ position: 'relative' }}>
        {target}
        {isOpen ? <Menu>{children}</Menu> : null}
      </div>
      {isOpen ? <Blanket onClick={onClose} /> : null}
    </>
  );

  const DropdownIndicator = () => (
    <div
      style={{
        color: 'var(--primary-text-color)',
        height: '100%',
        width: '22px',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Svg>
        <path
          d="M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z"
          fill="currentColor"
          fillRule="evenodd"
        />
      </Svg>
    </div>
  );

  const SelectedValue = ({ data }) => (
    <div className={cx('selected-value')}>
      <span className={cx('selected-value__label')}>{data.label} </span>
    </div>
  );

  const Control = (props): JSX.Element => {
    return options.length > 10 ? (
      <components.Control {...props}>{props.children}</components.Control>
    ) : null;
  };

  const Option = (props) => {
    return (
      <div
        className={cx(
          'option',
          props.isSelected ? 'selected' : props.isFocused ? 'focused' : undefined,
        )}
      >
        <components.Option {...props}>
          <div>
            <span className={cx('account__label')} ref={props.innerRef} {...props.innerProps}>
              {props.children}
            </span>
          </div>
        </components.Option>
      </div>
    );
  };

  return (
    <Dropdown
      isOpen={isDisabled ? !isDisabled : isOpen}
      onClose={toggleOpen}
      target={
        <div onClick={toggleOpen}>
          <div className={cx('label-wrap')}>
            {label && (
              <div className={errors ? cx('label', 'error') : cx('label')}>
                {label}
                {rules && rules.required ? <span className={cx('required')}>*</span> : ''}
              </div>
            )}
            <div className={cx('icon-container')}>
              {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
            </div>
          </div>
          {/* <span className={cx('custome-control__label')}>{label}</span> */}
          <div
            style={
              isDisabled
                ? { backgroundColor: 'var(--light-grey-disabled)' }
                : { backgroundColor: 'transparent' }
            }
            className={
              errors
                ? cx('custome-control', 'error')
                : cx('custome-control', isOpen ? 'list-opened' : '')
            }
          >
            {value ? (
              <SelectedValue data={value} />
            ) : (
              <span className={cx('placeholder')}>{'--Select--'}</span>
            )}
            {!isDisabled && isOpen ? <CaretUp size={18} /> : <CaretDown size={18} />}
          </div>
          {errors && <p className={cx('errorName')}>{errors.message}</p>}
        </div>
      }
    >
      <Select
        {...field}
        styles={customStyles}
        autoFocus
        backspaceRemovesValue={false}
        components={{ DropdownIndicator, Option, Control, IndicatorSeparator: null }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable
        menuIsOpen
        isMulti={isMulti}
        onChange={onSelectChange}
        options={options}
        placeholder={placeholder}
        tabSelectsValue={false}
        value={value}
      />
    </Dropdown>
  );
};

export default JokerSelectWithSearch;
