import React from 'react';
import classNames from 'classnames/bind';
import styles from './tableStyle.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import ReactTooltip from 'react-tooltip';

import { TABLE_NOTIFICATIONS_POPUP_COLUMNS_NAMES } from 'constants/table';
import moment from 'moment';
import { EnvelopeOpen } from 'phosphor-react';
import { Link } from 'react-router-dom';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import Button from 'components/ui/Button/Button';
import { mapNotificationType } from "../../../../constants/notifications";

const cx = classNames.bind(styles);

const NotificationsPopupTable = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  markAsReadDisableBtn,
  markAsReadHandle,
  colSortCheck,
  markAsRead,
}) => {
  const { columns, saveColumnOrder, setDefaultColumnsOrder } = useTableColumnOrder(
    'TABLE_NOTIFICATIONS_POPUP_COLUMNS_NAMES',
    TABLE_NOTIFICATIONS_POPUP_COLUMNS_NAMES,
  );

  const tableData = React.useMemo(() => makeTableData(data, data.length).map((item, pos) => {
    const { id, type, details, client, created_at, priority, is_read } = item;

    return {
      type: (
        <div className={cx('notifications-table_td')}>
          {
            <>
              <span className={cx(!is_read ? 'unread' : '')}>{mapNotificationType[type] || type}</span>
            </>
          }
        </div>
      ),
      details: (
        <div className={cx('notifications-table_td')}>
          {<span className={cx(!is_read ? 'unread' : '', 'notification-details')} title={details}>{details}</span>}
        </div>
      ),
      client: (
        <div className={cx('notifications-table_td')}>
          <span className={cx(!is_read ? 'unread' : '')}>
            <Link
              target={'_blank'}
              to={`/client/${client?.id ?? 0}`}
              className={cx('notifications-table_td-link')}
            >
              {client?.user_profile?.first_name + ' ' + client?.user_profile?.last_name}
            </Link>
          </span>
        </div>
      ),
      created_at: (
        <div className={cx('notifications-table_td')}>
          <span className={cx(!is_read ? 'unread' : '')}>
            {created_at && moment(created_at * 1000).format('DD.MM.YYYY HH:mm:ss')}
          </span>
        </div>
      ),
      priority: (
        <div className={cx('notifications-table_td ', priority, !is_read ? 'unread' : '')}>
          <span className={cx(priority)}>{priority}&nbsp;</span>
        </div>
      ),
      action: (
        <div className={cx('notifications-table_td')}>
          {!is_read && (
            <div className={cx('icon', !is_read ? 'unread' : '')}>
              <EnvelopeOpen
                size={20}
                onClick={() => markAsRead(id)}
                data-tip="Mark as read"
                data-for="markAsRead"
              />
            </div>
          )}
          <ReactTooltip id="markAsRead" />
        </div>
      ),
    };
  }), [data]);

  return (
    <div>
      <div
        style={{
          display: 'flex',
          gap: 20,
        }}
      >
        <Button
          buttonStyle={{
            marginBottom: '12px',
            marginLeft: 0,
          }}
          buttonText="Reset column order"
          onClick={setDefaultColumnsOrder}
        />
        <Button
          buttonStyle={{
            marginBottom: '12px',
            marginLeft: 0,
          }}
          buttonText="Мark as read"
          disabled={markAsReadDisableBtn}
          onClick={markAsReadHandle}
        />
      </div>
      <Table
        saveColumnOrder={saveColumnOrder}
        columns={columns}
        data={tableData}
        perPage={perPage}
        showLoader={showLoader}
        onRowSelect={onRowSelect}
        colSortCheck={colSortCheck}
        className={cx('notifications-table', 'fixedHeader')}
        isSelected={true}
        getCellProps={(cellInfo, cx) => {
          const { row, value, column } = cellInfo;
          return {
            style: {
              backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
            },
          };
        }}
      />
    </div>
  );
};

export default NotificationsPopupTable;
