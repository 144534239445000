import React, { FC, useEffect } from 'react';
import ImageUploading, { ImageType } from 'react-images-uploading';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
import { CloudArrowUp, Trash } from 'phosphor-react';
import { IUploadImageLK } from "../../../models/components/UploadImageLK/UploadImageLK.ts";
import { deleteImage } from "../../../api/settingsLK";
import { notify } from "../../../utils/notify.tsx";
import { removeImage } from "../../../helpers/deleteImageForLK.ts";

const cx = classNames.bind(styles);

const UploadImageLK: FC<IUploadImageLK> = ({ changeImage, image, type }) => {
  const [images, setImages] = React.useState([]);
  const maxNumber: number = 69;
  const maxFileSize: number = 1050000;

  useEffect((): void => {
    image && setImages([image]);
  }, [image]);

  const onChange = (imageList): void => {
    setImages(imageList);
    changeImage(imageList[0]);
  };

  return (
    <div>
      <ImageUploading
        multiple={false}
        value={images}
        acceptType={['png']}
        onChange={onChange}
        maxNumber={maxNumber}
        maxFileSize={maxFileSize}
        dataURLKey="data_url"
      >
        {({
            imageList,
            onImageUpload,
            onImageRemoveAll,
            onImageUpdate,
            onImageRemove,
            isDragging,
            dragProps,
            errors,
          }) => (
          <div className={cx('wrapper-uploaderImage')}>
            <div className={cx('file-uploader')}>
              <div className={cx('file-uploader__files')}>
                <div className={cx('file-uploader__area')}>
                  {images.length === 0 ? (
                    <button
                      className={cx('file-uploader__title')}
                      style={isDragging ? { color: 'red' } : undefined}
                      onClick={onImageUpload}
                      {...dragProps}
                    >
                      <i className={cx('file-uploader__icon')}></i>
                      Drag & Drop to Upload Image
                    </button>
                  ) : (
                    imageList.map((img: ImageType, index: number) => (
                      <div key={index} className="image-item">
                        <img src={img?.data_url ? img.data_url : img} alt="" width="100" />
                        <div className={cx('image-item__btn-wrapper')}>
                          <button
                            className={cx('actionBtn')}
                            type="button"
                            onClick={(): void => {
                              onImageUpload();
                            }}
                          >
                            <CloudArrowUp size={32} />
                          </button>
                          <button
                            className={cx('actionBtn')}
                            type="button"
                            onClick={(): void => {
                              onImageRemove(index);
                              removeImage(type);
                            }}
                          >
                            <Trash size={32} color={'red'} />
                          </button>
                        </div>
                      </div>
                    ))
                  )}
                </div>
              </div>
            </div>
            {errors?.maxFileSize &&
              <span className={cx('error__maxFileSize')}>
                The selected file is larger than 1 MB.
              </span>}
          </div>
        )}
      </ImageUploading>
    </div>
  );
}

export default UploadImageLK;
