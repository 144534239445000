import React, { FC, memo } from 'react'
import classNames from 'classnames/bind'
import styles from '../clients-table.module.scss'
import { ISalesProps } from "../../../../../models/components/Table/ClientsTable/Sales.ts";
import { PURPLE } from "../../../../../constants/colors.js";

const cx = classNames.bind(styles)

const Sales: FC<ISalesProps> = ({ sales, status, color_status, type = '' }) => {

  const color = color_status === null ? PURPLE : color_status;

  return (
    <div className={cx('client__sales')}>
      {sales && type === 'sales' ? (
        <div
          style={{ border: `1px solid ${color}` }}
          className={cx('client__sales-wrap')}
        >
          <span
            style={{ color: color }}
            className={cx('client__sales-status')}
          >
            {status}
          </span>
          {!!sales?.operator?.full_name &&<span>{sales?.operator?.full_name}</span>}
          <span>
            <strong>Desk:</strong> {sales?.team?.desk?.name}
          </span>
          <span>
            <strong>Team:</strong> {sales?.team?.name}
          </span>
        </div>
      ) : (
        <span className={cx('empty')}>None</span>
      )}
    </div>
  )
}

export default memo(Sales)
