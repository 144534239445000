import Table from "../../../../../components/ui/Table";
import React, { useMemo } from "react";
import { TABLE_SYSTEM_MESSAGES_COLUMNS_NAMES } from "./columns";
import { makeTableData } from "../../../../../helpers/table.helper";
import { SystemMessageDate } from "./SystemMessageDate";
import { SystemMessageActions } from "./SystemMessageActions";
import { SystemMessageText } from "./SystemMessageText";
import { useAppSelector } from "../../../../../store";
import { selectTickerMessagesPending } from "../../../../../store/slice/tickerSlice";
import './SystemMessagesTable.scss';

export const SystemMessagesTable = ({ data, type }) => {
    const messagesPending = useAppSelector(selectTickerMessagesPending);

    const tableData = useMemo(() => {
        return makeTableData(data, data.length).map((item) => ({
            message: <SystemMessageText text={item.message} color={item.color}/>,
            start_date: <SystemMessageDate date={item.start_date}/>,
            end_date: <SystemMessageDate date={item.end_date}/>,
            action: <SystemMessageActions message={item} type={type}/>
        }));
    }, [data]);

    return <Table
        columns={TABLE_SYSTEM_MESSAGES_COLUMNS_NAMES}
        data={tableData}
        className='system-messages-table'
        showLoader={messagesPending}
        isSelected={false}
        getCellProps={() => ({ style: {} })}
        perPage={1000}
    />
}