import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import { findLeads, getUploadLog, promoteLeadsToClients } from '../../../api/leads/index';
import Button from '../../../components/ui/Button/index.js';
import LeadsTable from '../../../components/ui/Table/LeadsTable/index.js';
import TablePagination from '../../../components/ui/TablePagination/index.js';
import { usePermission } from '../../../utils/usePermission.tsx';
import Page from '../../Page/Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import LeadFilter from './LeadFilter/LeadFilter';

import styles from './search-leads-page.module.scss';
import LeadUploadLogPopup from './SearchLeadsPopup/LeadUploadLogPopup/LeadUploadLogPopup';
import LeadUploadPopup from './SearchLeadsPopup/LeadUploadPopup/LeadUploadPopup';
import { notify } from "../../../utils/notify.tsx";
import LoaderButton from "../../../components/ui/LoaderButton/index.js";
import { TableMeta, TableLinks } from 'models/Table';
import { Sort } from 'models/Sort';
import { Lead } from 'models/Leads/Lead';
import { LeadsFilter } from 'models/Leads/LeadsFilter';
import { LeadErrorLogItem } from 'models/Leads/LeadLog.js';
import { useAppDispatch } from "../../../store";
import { toggleScrollToTop } from "../../../store/slice/tableSlice.ts";
import { useCheckAutoRefresh } from "../../../utils/useCheckAutoRefresh.tsx";

const cx = classNames.bind(styles);

const SearchLeadsPage = () => {

  const { permissionGiven: PermissionList } = usePermission('admin.lead.index');
  const { permissionGiven: PermissionUpload } = usePermission('admin.leads.upload');
  const { permissionGiven: PermissionPromote } = usePermission('admin.lead.promote');

  const [leads, setLeads] = useState<Lead[]>([]);
  const dispatch = useAppDispatch();

  const [isListLoading, setIsListLoading] = useState(false);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [logsData, setLogsData] = useState<LeadErrorLogItem[]>([]);

  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<LeadsFilter>(null);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const [sort, setSort] = useState<Sort>();

  const componentMounted = useRef(false);
  const checkAutoRefresh = useCheckAutoRefresh('Page Search Leads')
  document.title = 'Search Leads';

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      getLeads();
    }
    return () => {
      componentMounted.current = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PermissionList]);

  useEffect(() => {
    if (PermissionUpload) {
      getUploadErrors();
    }
  }, [PermissionUpload])

  // const changeLimits = (val) => {
  //   setPerPageCount(val);
  // };

  const getUploadErrors = () => {
    getUploadLog().then((res) => {
      if (res && res.status === 200) {
        setLogsData(res.data.data);
      }
    });
  };

  const getLeads = (pageParams?, filtersData?, hasReindex = false) => {
    setIsListLoading(true);

    const params = `${getTablePageParams(pageParams)}${hasReindex ? '&reindex=1' : ''}`

    findLeads(params, filtersData)
      .then((res) => {
        if (componentMounted.current) {
          setLeads(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
          setIsListLoading(false);
        }
      })
      .catch(console.log)
      .finally(() => setIsListLoading(false));
  };

  const getClientToBulkActions = (rows) => {
    const clients = [];
    rows.forEach((item) => {
      clients.push(leads[item['index']]['id']);
    });
    setSelectedRows(clients);
  };

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    return `${toPage}&${perPageParams}`;
  };

  const getSortFiltersForPageNavigation = () => {
    if (sort && filters) {
      return {
        sort,
        ...filters,
      };
    }

    if (sort && !filters) {
      return {
        sort,
      };
    }

    if (!sort && filters) {
      return filters;
    }
  };

  const promoteLeads = () => {
    setIsLoadingButton(true);

    const postData = {
      leads: selectedRows,
    }

    promoteLeadsToClients(postData)
      .then(res => {
        notify({
          type: 'success',
          message: res.data.message,
          timeOut: 3000,
        });
        setTimeout(() => getLeads(), 2000)
      })
      .catch(err => {
        notify({
          type: 'error',
          message: 'Leads not converted to clients',
          timeOut: 3000,
        });
        console.log(err)
      })
      .finally(() => setIsLoadingButton(false))
  }

  const clearFiltersSortList = () => {
    setPerPageCount(10);
    getLeads({ navigate: 'first', perPage: 10 });
    setFilters(null);
    setSort(null);
  };

  const SORTED_FIELD_NAME = {
    country: 'country',
    registration: 'created_at',
    last_note: 'last_note',
  };

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>
                  <strong>{tableMeta?.total}</strong> Leads found
                </span>
                <span>{selectedRows?.length} selected</span>
              </div>
              <div className={cx('header-controls')}>
                {
                  PermissionUpload ?
                    <>
                      <LeadUploadLogPopup
                        triggerBtn={<Button buttonText='View errors log' buttonType={'outline'} />}
                        logsData={logsData}
                      />
                      <LeadUploadPopup
                        triggerBtn={
                          <Button buttonText='Upload' onClick={() => null} buttonType={'outline'} />
                        }
                        onCloseModal={() => getUploadErrors()}
                        updateComponent={() => getLeads()}
                      />
                    </> : null
                }
              </div>
            </div>
          </div>
          <div className={cx('container__filters-block')}>
            <LeadFilter
              onSubmit={(filtersData, hasReindex) => {
                if (!filters && !filtersData) {
                  return;
                }
                if (filters && !filtersData) {
                  clearFiltersSortList();
                } else {
                  setFilters(filtersData);
                  getLeads({ navigate: 'first', perPage: filtersData?.filter?.limit || 10 }, filtersData, hasReindex);
                }
              }}
              // changeLimits={(val) => changeLimits(val)}
              reloadFilters={isListLoading}
              queryMessage={queryResponseMessage}
                          />
          </div>
          {PermissionPromote ?
            <div className={cx('controls__loader')}>
              <LoaderButton
                buttonType='primary'
                size='big'
                spinnerStyle={{ justifyContent: 'center' }}
                disabled={selectedRows?.length === 0}
                showSpinner={isLoadingButton}
                buttonText={'Promote to clients'}
                type='submit'
                onClick={promoteLeads}
              />
            </div>
            : null
          }

          <div className={cx('container__block')}>
            {
              PermissionList ?
                <LeadsTable
                  perPage={perPageCount}
                  data={leads}
                  showLoader={isListLoading}
                  onRowSelect={getClientToBulkActions}
                  manualSortHandler={(col) => {
                    const sorted = col.isSorted;
                    const desc = col.isSortedDesc;

                    if (!sorted) {
                      const sort = {
                        field: SORTED_FIELD_NAME[col.id],
                        direct: 'desc',
                      };
                      setSort(sort);
                      getLeads(
                        { navigate: 'first' },
                        {
                          ...filters,
                          sort,
                        },
                      );
                      col.toggleSortBy(true);
                    }

                    if (sorted && desc) {
                      const sort = {
                        field: SORTED_FIELD_NAME[col.id],
                        direct: 'asc',
                      };
                      setSort(sort);
                      getLeads(
                        { navigate: 'first' },
                        {
                          ...filters,
                          sort,
                        },
                      );
                      col.toggleSortBy(false);
                    }

                    if (sorted && !desc) {
                      const data = {
                        ...filters,
                      };
                      setSort(null);
                      getLeads({ navigate: 'first' }, data);
                      col.clearSortBy();
                    }
                  }}
                  modifyDataHandler={(callback) => console.log('a')}
                /> : null
            }
            {leads?.length ? (
              <TablePagination
                goToFirstPage={() => {
                  getLeads({ navigate: 'first' }, getSortFiltersForPageNavigation());
                  dispatch(toggleScrollToTop())
                }}
                goToPrevPage={() => {
                  getLeads({ navigate: 'prev' }, getSortFiltersForPageNavigation());
                  dispatch(toggleScrollToTop())
                }}
                goToNextPage={() => {
                  getLeads({ navigate: 'next' }, getSortFiltersForPageNavigation());
                  dispatch(toggleScrollToTop())
                }}
                goToLastPage={() => {
                  getLeads({ navigate: 'last' }, getSortFiltersForPageNavigation());
                  dispatch(toggleScrollToTop())
                }}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getLeads({ perPage: value }, getSortFiltersForPageNavigation());
                }}
                goToSelectedPage={(page) => {
                  getLeads({ page }, getSortFiltersForPageNavigation());
                }}
                isRareCase={true}
                // hidePageSelect={true}
                isFirstPageDisable={() => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default SearchLeadsPage;
