export enum ChatTypes { PRIVATE = 'PRIVATE', GROUP = 'GROUP' }
export type ChatType = `${ChatTypes}`;

export enum OwnerTypes { USERS = 'users', ADMINS = 'admins' }
export enum UserTypes { CLIENTS, MANAGERS }
export enum UserTypeLabels { 'client', 'manager' }
export enum UnreadTypeRoutes { 'client', 'operator' }
export enum ThreadTypes { 'internal', 'support' }

export enum MessageTypesVerbose {
    DOCUMENT_MESSAGE = 'DOCUMENT_MESSAGE' ,
    IMAGE_MESSAGE = 'IMAGE_MESSAGE' ,
    MESSAGE = 'MESSAGE',
    GROUP_CREATED = 'GROUP_CREATED',
    PARTICIPANTS_ADDED = 'PARTICIPANTS_ADDED',
    PARTICIPANT_REMOVED = 'PARTICIPANT_REMOVED',
    PARTICIPANT_LEFT_GROUP = 'PARTICIPANT_LEFT_GROUP'
}
export enum OnlineStatusesVerbose { 'OFFLINE' = 0 , 'ONLINE', 'AWAY'  }

export enum ProcessedMessageMode { IS_EDITING = 'IS_EDITING', IS_REPLYING = 'IS_REPLYING' }

export type ChatResponseImage = {
    sm: string;
    md: string;
    lg: string
};

export type ChatUserBase = {
    id: string;
    type: UserTypes;
    name: string;
    avatar: ChatResponseImage | null;
    admin: number;
    demo: number;
    out_user_id: string;
    created_at: string;
    updated_at: string
};

export type ChatUser = {
    name: string;
    route: string | null;
    provider_id: string;
    provider_alias: string;
    base: ChatUserBase;
    avatar: ChatResponseImage | null;
    roles: ParticipantRole[];
};

export type ChatUserInfo = {
    provider: ChatUser;
    active_calls_count: number;
    online_status: 0 | 1 | 2;
    online_status_verbose: OnlineStatusesVerbose;
    unread_threads_count: number;
    pending_friends_count: number;
    settings: {
        id: string;
        owner_type: OwnerTypes;
        owner_id: string;
        message_popups: boolean;
        message_sound: boolean;
        call_ringtone_sound: boolean;
        notify_sound: boolean;
        dark_mode: boolean;
        online_status: 0 | 1 | 2;
        created_at: string;
        updated_at: string
    }
};

export type LastRead = {
    time: string;
    message_id: string | null;
};

export type Participant = LastRead & {
    id: string,
    thread_id: string,
    admin: boolean,
    pending: boolean,
    send_knocks: boolean,
    send_messages: boolean,
    add_participants: boolean,
    manage_invites: boolean,
    manage_bots: boolean,
    start_calls: boolean,
    owner_id: string,
    owner_type: OwnerTypes,
    owner: ChatRecipientType,
    created_at: string;
    updated_at: string;
};

export type ChatMessageType = {
    id: string;
    thread_id: string;
    owner_id: string;
    owner_type: OwnerTypes;
    owner: ChatUser;
    type: number;
    type_verbose: MessageTypesVerbose;
    system_message: boolean;
    from_bot: boolean;
    body: string;
    edited: boolean;
    reacted: boolean;
    embeds: boolean;
    extra: any;
    created_at: string;
    updated_at: string;
    roles: ParticipantRole[];
    meta: {
        thread_id: string;
        thread_type: number;
        thread_type_verbose: string;
        thread_name?: string;
        thread_avatar?: ChatResponseImage | null
    };
    reply_to_id?: string;
    reply_to?: ChatMessageType;
    temporary_id?: string;
    image?: ChatResponseImage;
    document?: string;
    is_removed?: boolean;

};

export type ChatRecipientType = {
    name: string;
    route: null;
    provider_id: string;
    provider_alias: string;
    base: ChatUserBase;
    options: {
        can_message_first: boolean;
        friendable: boolean;
        can_friend: boolean;
        searchable: boolean;
        can_search: boolean;
        online_status: 0 | 1 | 2;
        online_status_verbose: OnlineStatusesVerbose;
        friend_status: 0 | 1;
        friend_status_verbose: string;
        last_active: string;
        friend_id: string
    };
    roles: ParticipantRole[];
    avatar: ChatResponseImage;
    thread_id?: string;
};

export type ParticipantRole = {
    id: number;
    name: string;
    created_at: string;
    updated_at: string;
    pivot: {
        user_id: string;
        role_id: number;
    }
};

export type ChatEntity = {
    id: string;
    type: number;
    type_verbose: ChatType;
    has_call: boolean;
    locked: boolean;
    pending: boolean;
    name: string;
    avatar: ChatResponseImage;
    group: boolean;
    unread: boolean;
    unread_count: number;
    created_at: string;
    updated_at: string;
    options: {
        admin: boolean;
        manage_bots: boolean;
        chat_bots: boolean;
        muted: boolean;
        add_participants: boolean;
        invitations: boolean;
        call: boolean;
        message: boolean;
        knock: boolean
    }
};

export type ChatThreadResources = {
    latest_message: ChatMessageType | null;
    recipient?: ChatRecipientType
};

export type Thread = ChatEntity & {
    resources: ChatThreadResources;
    is_support: 0 | 1;
    pinned: boolean;
};

export type ChatResponseMeta = {
    index: boolean;
    page_id: string | null;
    next_page_id: string | null;
    next_page_route: string | null;
    final_page: boolean;
    per_page: number;
    results: number;
    total: number;
}

export type SystemFeatures = {
    bots: boolean;
    calling: boolean;
    invitations: boolean;
    invitations_max: number;
    knocks: boolean;
    audio_messages: boolean;
    document_messages: boolean;
    image_messages: boolean;
    message_edits: boolean;
    message_edits_view: boolean;
    message_reactions: boolean;
    message_reactions_max: number;
    provider_avatars: boolean;
    thread_avatars: boolean;
    bot_avatars: boolean;
}

export type GetThreadsResponse = {
    data: Thread[];
    meta: ChatResponseMeta;
    system_features: SystemFeatures
};

export type GetChatRecipientResponse = {
    recipient: ChatRecipientType,
    thread_id?: string
};

export type ChatMessagesResources = {
    latest_message: ChatMessageType | null;
    recipient?: ChatRecipientType;
    participants?: {
        data: Participant[];
        meta: ChatResponseMeta
    };
    messages: {
        data: ChatMessageType[];
        meta: ChatResponseMeta
    };
};

export type GetMessagesResponse = ChatEntity & {
    meta: ChatResponseMeta;
    system_features: SystemFeatures;
    resources: ChatMessagesResources
}

export type GetNextMessagesResponse = {
    data: ChatMessageType[],
    meta: ChatResponseMeta
};

export type GetThreadsUnreadResponse = {
    unread_threads_count: number;
}

export type SendMessagePayloadMeta = {
    temporary_id: string;
    reply_to_id?: string;
};

export type SendTextMessagePayload = SendMessagePayloadMeta & {
    message: string;
};

export type SendImageMessagePayload = SendMessagePayloadMeta & {
    image: File;
};

export type SendDocumentMessagePayload = SendMessagePayloadMeta & {
    document: File;
};

export type UpdateChatUserInfoPayload = {
    away: boolean
};

export type AddParticipantsPayload = {
    providers: {
        alias: string;
        id: string;
    }[];
};

export type CreateChatGroupPayload = AddParticipantsPayload & {
    subject: string;
};


export type CreateChatThreadPayload = {
    message: string;
    recipient_id: string;
    recipient_alias: 'user'
};