import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './../../lead-page.module.scss';
import { ArrowsClockwise, Pencil, Trash } from 'phosphor-react';

import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import Select from 'components/ui/Select';
import { useParams } from 'react-router-dom';
import { notify } from 'utils/notify';
import DatePickerRange from 'components/ui/DatePickerRange';
import { getDepartamentList } from 'api/departament';
import { Col, Row } from 'react-bootstrap';
import { searchNotesListAll } from 'api/notes';
import moment from 'moment';
import NotesItem from './NotesItem';
import './reload-button.scss';
import Preloader from 'components/ui/Preloader/Preloader';
import InfiniteScroll from 'react-infinite-scroll-component';
import { isFormChange } from '../../../../../utils/isFormChange';
import { TableLinks, TableMeta } from 'models/Table';
import { LeadNote, LeadNotesFilter } from 'models/Leads/LeadNotes';

const cx = classNames.bind(styles);

type Department = { id: number; name: string; }

const NotesTabs = () => {
  const [departamentList, setDepartamentList] = useState<Department[]>([]);
  const [notesList, setNotesList] = useState<LeadNote[]>([]);

  const [isListLoading, setIsListLoading] = useState(true);
  const [savedFilters, setSavedFilters] = useState<LeadNotesFilter>();

  const [meta, setMeta] = useState<TableMeta>();
  const [links, setLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const { id } = useParams();

  const componentMounted = useRef(true);

  const {
    handleSubmit,
    control,
    watch,
    reset,
    getValues,
    formState: { errors, isDirty, dirtyFields },
  } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      department_id: null,
      created_at: null,
    },
  });
  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (data) => {
    setSavedFilters(data);
    setIsLoading(true);
    fetchNotesList(data);
  };

  const fetchNotesList = (data?, isNextCall?) => {
    // updateHeadNotes();
    setIsListLoading(true);

    const postData = {
      filter: {
        id,
        type: 'lead',
      },
    };

    if (data && data['department_id']) {
      postData.filter['department_ids'] = [data['department_id'].id];
    }

    if (data && data['created_at']) {
      postData.filter['created_at'] = {
        from: moment(data.created_at.from).format('YYYY-MM-DD'),
        to: moment(data.created_at.to).format('YYYY-MM-DD'),
      };
    }

    let searchParams = '';
    if (isNextCall) {
      searchParams = links && links.next ? `?${links.next.split('?').reverse()[0]}` : '';
    }

    searchNotesListAll(searchParams, postData)
      .then((res) => {
        if (res.status === 200) {
          if (isNextCall) {
            setNotesList([...notesList, ...res.data.data]);
          } else {
            setNotesList(res.data.data);
          }
          setMeta(res.data.meta);
          setLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setIsListLoading(false);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsLoading(false);
          setIsListLoading(false);

          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  const fetchDepartamentList = () => {
    setIsListLoading(true);
    getDepartamentList()
      .then((res) => {
        if (res.status === 200) {
          setDepartamentList(
            res.data.data.map((el) => ({
              id: el.id,
              name: el.name,
            })),
          );
          setIsListLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect(() => {
    fetchDepartamentList();
    fetchNotesList();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <div className={cx('tab-panel', 'notes')}>
      <div className={cx('tab-panel__content')}>
        <div className={cx('tab-panel__header')}>
          <div className={cx('tab-panel__title')}>Notes</div>
          <div className={cx('tab-panel__action')}></div>
        </div>
        <div className={cx('tab-panel__filter-box')}>
          <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
            <Row className={cx('form__line', 'filter-row')}>
              <Col md={4} lg={4}>
                <div className={cx('select-wrap')}>
                  <Select
                    name="department_id"
                    label="Department"
                    control={control}
                    getOptionValue={(opt) => opt.id}
                    getOptionLabel={(opt) => opt.name}
                    options={departamentList}
                    onChange={(data) => onSubmit(data)}
                  />
                </div>
              </Col>
              <Col md={4} lg={4}>
                <DatePickerRange
                  name="created_at"
                  control={control}
                  label="Creation date range"
                  placeholder="Start date - End date"
                />
              </Col>
            </Row>
          </form>
          <div className={cx('tab-panel__filter-box-col', 'actin-btn')}>
            <Button
              buttonType="outline"
              className={isListLoading ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise size={18} />}
              onClick={(e) => {
                e.preventDefault();
                onSubmit(getValues());
              }}
            />
            <Button
              buttonType="primary"
              type="button"
              buttonText="Reset"
              disabled={!isFormChange(dirtyFields)}
              onClick={() => {
                reset({
                  department_id: null,
                  created_at: null,
                });
                fetchNotesList();
              }}
            />
            <Button
              buttonType="primary"
              buttonText="Apply"
              onClick={handleSubmit(onSubmit)}
              disabled={!isFormChange(dirtyFields)}
            />
          </div>
        </div>
        <div className={cx('tab-panel-table')}>
          {isListLoading ? <Preloader /> : null}
          <div className={cx('notes-list', isListLoading ? 'p-0 blur' : '')}>
            {notesList.length > 0 && meta && links ? (
              <InfiniteScroll
                dataLength={notesList.length}
                next={() => fetchNotesList(savedFilters, true)}
                hasMore={!!links.next}
                height={600}
                scrollableTarget="scrollableDiv"
                loader={<></>}
              >
                {notesList.map((el) => (
                  <NotesItem
                    key={el.id}
                    noteItem={el}
                    updateNotesList={() => {
                      fetchNotesList(savedFilters);
                    }}
                  />
                ))}
              </InfiniteScroll>
            ) : (
              <span className={cx('noItems')}>No items</span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotesTabs;
