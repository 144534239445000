import moment from 'moment/moment';
import { CaretDown } from 'phosphor-react';
import UnblockedClientPopup from '../ClientPopups/UnblockedClientPopup';
import ClientChangeAccountStatusPopup from '../ClientPopups/ClientChangeAccountStatusPopup';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from '../client-page.module.scss';
import { usePermission } from '../../../../utils/usePermission';
import { useParams } from 'react-router-dom';
import VerifyClientPopup from '../ClientPopups/VerifyClientPopup';
import { Client } from 'models/Clients/Clients';

const cx = classNames.bind(styles);

type Props = {
  isLoading: boolean;
  clientData: Client;
  updateComponent: () => void;
}

const AccountStatus = memo<Props>(({
                                     isLoading,
                                     clientData,
                                     updateComponent,
                                   }) => {
  const { permissionGiven: PermissionEditStatus } = usePermission('admin.client.status');
  const { permissionGiven: PermissionEditUnblockedAccount } = usePermission('admin.client.unblocked');
  const { permissionGiven: PermissionEditBlockedAccount } = usePermission('admin.client.blocked');
  const { permissionGiven: PermissionVerifyAccount } = usePermission('admin.client.verified');

  const [openAccStatus, setOpenAccStatus] = useState(false);

  const dropMenu = useRef(null);
  const params = useParams();

  const onClickOutside = useCallback((event) => {
    if (dropMenu.current && !dropMenu.current.contains(event.target)) {
      setOpenAccStatus(false);
    }
  }, [setOpenAccStatus]);

  useEffect(() => {
    document.addEventListener('click', onClickOutside, true);

    return () => document.removeEventListener('click', onClickOutside, true);
  }, [onClickOutside]);

  return (
    <>
      {!isLoading && (
        <>
          <div className={cx('client-info__col-title')}>Account status</div>
          <div className={cx('client-info__col-content')}>
            <div className={cx('info-block')}>
              <div
                className={cx('info-block__value')}
                ref={dropMenu}>
                <div className={cx('statusValue')}>
                  <div
                    className={cx('statusValue__value', clientData?.user_profile?.status)}
                    onClick={() => PermissionEditStatus ? setOpenAccStatus(!openAccStatus) : null}
                  >
                    {clientData?.user_profile?.status || '---'}
                  </div>
                  <div className={cx('statusValue__descr')}>
                    since{' '}
                    {clientData && clientData.updated_at
                      ? moment(clientData.updated_at * 1000).format('DD.MM.YYYY')
                      : '---'}
                  </div>
                </div>
                <div
                  className={cx('value-icon', openAccStatus ? 'active' : '')}
                  onClick={() => PermissionEditStatus ? setOpenAccStatus(!openAccStatus) : null}
                >
                  <CaretDown size={16} />
                </div>
              </div>
              <div className={cx('info-block__action-container', openAccStatus ? 'open' : '')}>
                <ul className={cx('accActionsList')}>
                  {clientData?.user_profile?.status === 'blocked' ? (
                      PermissionEditUnblockedAccount ?
                        <UnblockedClientPopup
                          updateComponent={updateComponent}
                          userId={params.id}
                          triggerBtn={
                            <li
                              className={'unblock'}
                              onClick={() => setOpenAccStatus(!openAccStatus)}
                            >
                              Unblocked
                            </li>
                          }
                          // onCloseModal={() => console.log('a')}
                        /> : null
                    ) :
                    <>
                      {clientData?.user_profile?.status !== 'verified' && PermissionVerifyAccount ?
                        <VerifyClientPopup
                          updateComponent={updateComponent}
                          userId={params.id}
                          triggerBtn={
                            <li
                              className={'unblock'}
                              onClick={() => setOpenAccStatus(!openAccStatus)}
                            >
                              Verify
                            </li>
                          }
                          // onCloseModal={() => console.log('a')}
                        /> : null
                      }
                      {PermissionEditBlockedAccount ?
                        <ClientChangeAccountStatusPopup
                          updateComponent={updateComponent}
                          userId={params.id}
                          triggerBtn={
                            <li
                              className={'blocked'}
                              onClick={() => setOpenAccStatus(!openAccStatus)}
                            >
                              Blocked
                            </li>
                          }
                          // onCloseModal={() => console.log('a')}
                        /> : null}
                    </>
                  }
                </ul>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
});

export default AccountStatus;
