import React from 'react';
import { FormattedMessage } from 'react-intl';

// not use
export const TABLE_HEADER_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="table.th.transaction" defaultMessage="Transaction" />,
    accessor: 'transaction',
  },
  {
    Header: <FormattedMessage id="table.th.type" defaultMessage="Type" />,
    accessor: 'type',
  },
  {
    Header: <FormattedMessage id="table.th.amount" defaultMessage="Amount" />,
    accessor: 'amount',
  },
  {
    Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading Account" />,
    accessor: 'tradingAcc',
  },
  {
    Header: <FormattedMessage id="table.th.dateTime" defaultMessage="DATE & TIME" />,
    accessor: 'dateTime',
  },
  {
    Header: <FormattedMessage id="table.th.status" defaultMessage="Status" />,
    accessor: 'status',
  },
];

// not use
export const TABLE_ACCOUNTS_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="table.th.tradingAcc" defaultMessage="Trading account" />,
    accessor: 'tradingAcc',
  },
  {
    Header: <FormattedMessage id="table.th.login" defaultMessage="Login" />,
    accessor: 'login',
  },
  {
    Header: <FormattedMessage id="table.th.balance" defaultMessage="Balance" />,
    accessor: 'balance',
  },
  {
    Header: <FormattedMessage id="table.th.credit" defaultMessage="Credit" />,
    accessor: 'credit',
  },
  {
    Header: <FormattedMessage id="table.th.liverage" defaultMessage="Leverage" />,
    accessor: 'liverage',
  },
  {
    Header: <FormattedMessage id="table.th.server" defaultMessage="Server" />,
    accessor: 'server',
  },
  {
    Header: '',
    accessor: 'controls',
  },
];

// not use
export const TABLE_TRADING_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="table.th.trade" defaultMessage="Trade" />,
    accessor: 'trade',
  },
  {
    Header: <FormattedMessage id="table.th.type" defaultMessage="Type" />,
    accessor: 'type',
  },
  {
    Header: <FormattedMessage id="table.th.openTime" defaultMessage="Open Time" />,
    accessor: 'openTime',
  },
  {
    Header: <FormattedMessage id="table.th.closeTime" defaultMessage="Close Time" />,
    accessor: 'closeTime',
  },
  {
    Header: <FormattedMessage id="table.th.symbol" defaultMessage="Symbol" />,
    accessor: 'symbol',
  },
  {
    Header: <FormattedMessage id="table.th.volume" defaultMessage="volume" />,
    accessor: 'volume',
  },
  {
    Header: <FormattedMessage id="table.th.openPrice" defaultMessage="Open Price" />,
    accessor: 'openPrice',
  },
  {
    Header: <FormattedMessage id="table.th.closePrice" defaultMessage="Close Price" />,
    accessor: 'closePrice',
  },
  {
    Header: <FormattedMessage id="table.th.commission" defaultMessage="Commission" />,
    accessor: 'commission',
  },
  {
    Header: <FormattedMessage id="table.th.swap" defaultMessage="Swap" />,
    accessor: 'swap',
  },
  {
    Header: <FormattedMessage id="table.th.profit" defaultMessage="Profit" />,
    accessor: 'profit',
  },
];

export const TABLE_CLIENTS_COLUMNS_NAMES = [
  {
    Header: 'client',
    accessor: 'client',
  },
  {
    Header: 'Last activity',
    accessor: 'last_activity',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Deposits',
    accessor: 'deposit',
  },
  {
    Header: 'Affiliate',
    accessor: 'affiliate_refferer',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Sales',
    accessor: 'sales',
  },
  {
    Header: 'Retention',
    accessor: 'retention',
  },
  {
    Header: 'Registration',
    accessor: 'registration',
  },
  {
    Header: 'Last note',
    accessor: 'last_note',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];
export const TABLE_TRADING_ACCOUNT_NAMES = [
  {
    Header: 'Account name',
    accessor: 'trading_account',
  },
  {
    Header: 'Trading Acc',
    accessor: 'login',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Leverage',
    accessor: 'leverage',
  },
  {
    Header: 'Trading Status',
    accessor: 'status',
  },
  {
    Header: ' ',
    accessor: 'action',
  },
];

export const TABLE_TRADING_ACCOUNT_NAMES_WITH_WT = [
  {
    Header: 'Account name',
    accessor: 'trading_account',
  },
  {
    Header: 'Trading Acc',
    accessor: 'login',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
  {
    Header: 'Demo account',
    accessor: 'demo',
  },
  {
    Header: 'Leverage',
    accessor: 'leverage',
  },
  {
    Header: 'Trading Status',
    accessor: 'status',
  },
  {
    Header: ' ',
    accessor: 'action',
  },
];

export const TABLE_LEADS_COLUMNS_NAMES = [
  {
    Header: 'Lead',
    accessor: 'lead',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Sales Agent',
    accessor: 'sales',
  },
  {
    Header: 'Registration',
    accessor: 'registration',
  },
  {
    Header: 'Last note',
    accessor: 'last_note',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const TABLE_SALES_RULES_COLUMNS_NAMES = [
  {
    Header: 'Rule',
    accessor: 'rule',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Priority',
    accessor: 'priority',
  },
  {
    Header: 'Language',
    accessor: 'language',
  },
  {
    Header: 'Affiliate',
    accessor: 'affiliate',
  },
  {
    Header: 'Operator',
    accessor: 'operator',
  },
  {
    Header: 'Ratio',
    accessor: 'ratio',
  },
  {
    Header: 'Source',
    accessor: 'source',
  },
  {
    Header: 'Active',
    accessor: 'status',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];

export const TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="management.offices.HeaderColumn1" defaultMessage="Offices" />,
    accessor: 'office',
  },
  {
    Header: <FormattedMessage id="management.offices.HeaderColumn2" defaultMessage="Offices" />,
    accessor: 'country',
  },
  {
    Header: <FormattedMessage id="management.offices.HeaderColumn3" defaultMessage="Offices" />,
    accessor: 'action',
  },
];

export const TABLE_MANAGEMENTS_DESKS_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="management.desks.HeaderColumn1" defaultMessage="Desk" />,
    accessor: 'desk',
  },
  {
    Header: <FormattedMessage id="management.desks.HeaderColumn2" defaultMessage="Offices" />,
    accessor: 'office',
  },
  {
    Header: <FormattedMessage id="management.desks.HeaderColumn3" defaultMessage="Desk Type" />,
    accessor: 'deskType',
  },
  {
    Header: <FormattedMessage id="management.desks.HeaderColumn5" defaultMessage="Language" />,
    accessor: 'language',
  },
  {
    Header: <FormattedMessage id="management.desks.HeaderColumn4" defaultMessage="Actions" />,
    accessor: 'action',
  },
];
export const TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="management.teams.HeaderColumn1" defaultMessage="Team" />,
    accessor: 'team',
  },
  {
    Header: <FormattedMessage id="management.teams.HeaderColumn2" defaultMessage="Office" />,
    accessor: 'office',
  },
  {
    Header: <FormattedMessage id="management.teams.HeaderColumn3" defaultMessage="Desk" />,
    accessor: 'desk',
  },
  {
    Header: <FormattedMessage id="management.teams.HeaderColumn4" defaultMessage="Actions" />,
    accessor: 'action',
  },
];
export const TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES = [
  {
    Header: <FormattedMessage id="management.operators.HeaderColumn1" defaultMessage="Operator" />,
    accessor: 'operator',
  },
  {
    Header: <FormattedMessage id="management.operator.HeaderColumn2" defaultMessage="Country" />,
    accessor: 'country',
  },
  {
    Header: <FormattedMessage id="management.operator.HeaderColumn3" defaultMessage="Registered" />,
    accessor: 'created_at',
  },
  {
    Header: <FormattedMessage id="management.operator.HeaderColumn4" defaultMessage="Status" />,
    accessor: 'status',
  },
  {
    Header: '',
    accessor: 'action',
  },
];
export const TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES = [
  {
    Header: 'Affiliate',
    accessor: 'partner',
  },
  {
    Header: 'External id',
    accessor: 'external_id',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Registered',
    accessor: 'created_at',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES_NOT_MT = [
  {
    Header: 'Affiliate',
    accessor: 'partner',
  },
  {
    Header: 'Country',
    accessor: 'country',
  },
  {
    Header: 'Registered',
    accessor: 'created_at',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const TABLE_CALLBACKS_COLUMNS_NAMES = [
  {
    Header: 'Callback ID',
    accessor: 'id',
  },
  {
    Header: 'OPERATOR',
    accessor: 'operator',
  },
  {
    Header: 'CALLBACK TIME',
    accessor: 'time',
  },
  {
    Header: 'CREATE',
    accessor: 'created_at',
  },
  {
    Header: 'MODIFIED',
    accessor: 'updated_at',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'REMINDERS',
    accessor: 'reminders',
  },
  {
    Header: ' ',
    accessor: 'action',
  },
];
export const TABLE_ALL_CALLBACKS_COLUMNS_NAMES = [
  {
    Header: 'OPERATOR',
    accessor: 'operator',
  },
  {
    Header: 'CLIENT',
    accessor: 'client',
  },
  {
    Header: 'CALLBACK TIME',
    accessor: 'time',
  },
  {
    Header: 'CREATE',
    accessor: 'created_at',
  },
  {
    Header: 'MODIFIED',
    accessor: 'updated_at',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'REMINDERS',
    accessor: 'reminders',
  },
];

export const TABLE_CRYPTO_WALLETS_COLUMNS_NAMES = [
  {
    Header: 'Type',
    accessor: 'crypto_type',
  },
  {
    Header: 'Description',
    accessor: 'wallet',
  },
  {
    Header: 'Shared Wallet',
    accessor: 'shared_wallet',
  },
  {
    Header: 'User',
    accessor: 'user_profile',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];

export const TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES = [
  {
    Header: 'Type',
    accessor: 'crypto_type',
  },
  {
    Header: 'Description',
    accessor: 'wallet',
  },
  {
    Header: 'Shared Wallet',
    accessor: 'shared_wallet',
  },
];
export const TABLE_FEED_COLUMNS_NAMES = [
  // {
  //   Header: '№',
  //   accessor: 'number',
  // },

  {
    Header: 'Feed',
    accessor: 'created_at',
  },
  // {
  //   id: 'expanded',
  //   Cell: ({ row }) => (
  //     <span {...row.getToggleRowExpandedProps()} className="link">
  //       {row.isExpanded ? 'Hide details' : 'Show details'}
  //     </span>
  //   ),
  // },
];

export const TABLE_CRYPTO_TRANSACTIONS_COLUMNS_NAMES = [
  {
    Header: 'Type',
    accessor: 'crypto_type',
  },
  {
    Header: 'Wallet / Transaction Info',
    accessor: 'wallet',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'User',
    accessor: 'user_profile',
  },
  {
    Header: 'Created at',
    accessor: 'created',
  },
  
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES = [
  {
    Header: 'Transaction ID',
    accessor: 'transaction_id',
  },
  {
    Header: 'Amount',
    accessor: 'crypto_transaction',
  },
  {
    Header: 'Inner transaction',
    accessor: 'inner_transaction',
  },
  {
    Header: 'User',
    accessor: 'user',
  },
  {
    Header: 'Created at',
    accessor: 'created',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const TABLE_KYC_DOCUMENTS_COLUMNS_NAMES = [
  {
    Header: 'Client',
    accessor: 'user_name',
  },
  {
    Header: 'File',
    accessor: 'file_name',
  },
  {
    Header: 'DATE & TIME',
    accessor: 'created_at',
  },
  {
    Header: 'category',
    accessor: 'kyc_category',
  },
  {
    Header: 'document type',
    accessor: 'kyc_type',
  },
];

export const TABLE_NOTIFICATIONS_COLUMNS_NAMES = [
  {
    Header: 'Notification type',
    accessor: 'type',
  },
  {
    Header: 'Notification details',
    accessor: 'details',
  },
  {
    Header: 'Agent',
    accessor: 'agent',
  },
  {
    Header: 'Client',
    accessor: 'client',
  },
  {
    Header: 'Notification date',
    accessor: 'created_at',
  },
  {
    Header: 'priority',
    accessor: 'priority',
  },
];

export const TABLE_NOTIFICATIONS_POPUP_COLUMNS_NAMES = [
  {
    Header: 'Notification type',
    accessor: 'type',
  },
  {
    Header: 'Notification details',
    accessor: 'details',
  },
  {
    Header: 'Client',
    accessor: 'client',
  },
  {
    Header: 'Date',
    accessor: 'created_at',
  },
  {
    Header: 'priority',
    accessor: 'priority',
  },
  {
    Header: ' ',
    accessor: 'action',
  },
];

export const TABLE_TEMPLATES_COLUMNS_NAMES = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Template',
    accessor: 'template',
  },
  {
    Header: 'Subject',
    accessor: 'subject',
  },
  {
    Header: 'Languages',
    accessor: 'lang',
  },
  {
    Header: '',
    accessor: 'action',
  },
];

export const TABLE_WALLET_BINDINGS = [
  // {
  //   Header: 'User ID',
  //   accessor: 'id',
  // },
  {
    Header: 'User',
    accessor: 'user',
  },
  {
    Header: 'Bind',
    accessor: 'bind',
  },
  {
    Header: '',
    accessor: 'action',
  },
];
export const TABLE_ROLES_AND_PERMISSIONS = [
  {
    Header: 'Action',
    accessor: 'action',
  },
  {
    Header: 'Permission View',
    accessor: 'permission_view',
  },
  {
    Header: 'Permission Edit',
    accessor: 'permission_edit',
  },
  {
    Header: 'Preview',
    accessor: 'preview',
  },
];
export const TABLE_TRADING_ACC_COLUMNS_NAMES = [
  // {
  //   Header: 'MT4 Login',
  //   accessor: 'ta_login',
  // },
  {
    Header: 'Trading ACC',
    accessor: 'trading_account',
  },
  {
    Header: 'Login',
    accessor: 'trading_login',
  },
  {
    Header: 'Client',
    accessor: 'user',
  },
  // {
  //   Header: 'Source Name',
  //   accessor: 'name',
  // },
  {
    Header: 'Date',
    accessor: 'created_at',
  },
  {
    Header: 'Credit',
    accessor: 'credit',
  },
  {
    Header: 'Leverage',
    accessor: 'leverage',
  },
  {
    Header: 'Balance',
    accessor: 'balance',
  },
];

export const TABLE_PAYMENTS_COLUMNS = [
  {
    Header: 'Transactions',
    accessor: 'transactions',
  },
  {
    Header: 'Client',
    accessor: 'client',
  },
  {
    Header: 'Affiliate',
    accessor: 'affiliate',
  },
  {
    Header: 'Operator',
    accessor: 'original_agent',
  },
  {
    Header: 'Internal id',
    accessor: 'internal_id',
  },
  {
    Header: 'Payment type',
    accessor: 'payment_type',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Trading ACC',
    accessor: 'trading_acc',
    dependencies: ['MT']
  },
  {
    Header: 'Payment Method',
    accessor: 'payment_method',
  },
  {
    Header: 'Gateway',
    accessor: 'gateway',
    dependencies: ['PAYMENTS'],
  },
  {
    Header: 'Data & Time',
    accessor: 'date',
  },
  {
    Header: 'Crypto Wallets',
    accessor: 'cripto_walets',
  },
  {
    Header: 'Credit Card',
    accessor: 'credit_card',
    dependencies: ['CCW'],
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
];

export const getPaymentsColumns = (withMT, withPayments, withCCWithdraw) => {
  return TABLE_PAYMENTS_COLUMNS.filter(col => (
    !(col.dependencies?.includes('MT') && !withMT) && 
    !(col.dependencies?.includes('PAYMENTS') && !withPayments) &&
    !(col.dependencies?.includes('CCW') && !withCCWithdraw)
  ))
}

const TABLE_CLIENT_PAYMENTS_COLUMNS = [
  {
    Header: 'Transactions',
    accessor: 'transactions',
  },
  {
    Header: 'Operator',
    accessor: 'original_agent',
  },
  {
    Header: 'Internal id',
    accessor: 'internal_id',
  },
  {
    Header: 'External Reference ID',
    accessor: 'reference_id',
    dependencies: ['MT'],
  },
  {
    Header: 'Payment type',
    accessor: 'payment_type',
  },
  {
    Header: 'Amount',
    accessor: 'amount',
  },
  {
    Header: 'Trading ACC',
    accessor: 'trading_acc',
    dependencies: ['MT']
  },
  {
    Header: 'Payment Method',
    accessor: 'payment_method',
  },
  {
    Header: 'Gateway',
    accessor: 'gateway',
    dependencies: ['PAYMENTS']
  },
  {
    Header: 'Data & Time',
    accessor: 'date',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Crypto Wallets',
    accessor: 'cripto_walets',
  },
  {
    Header: 'Credit Card',
    accessor: 'credit_card',
    dependencies: ['CCW']
  },
  {
    Header: '',
    accessor: 'action',
  },
]

export const getClientPaymentsColumns = (withMT, withPayments, withCCWithdraw) => {
  return TABLE_CLIENT_PAYMENTS_COLUMNS.filter(col => (
    !(col.dependencies?.includes('MT') && !withMT) && 
    !(col.dependencies?.includes('PAYMENTS') && !withPayments) &&
    !(col.dependencies?.includes('CCW') && !withCCWithdraw)
  ))
}

export const TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES = [
  // {
  //   Header: 'ID',
  //   accessor: 'id',
  // },
  {
    Header: 'NAME',
    accessor: 'name',
  },
  {
    Header: 'PRIORITY',
    accessor: 'priority',
  },
  {
    Header: 'SOURCE',
    accessor: 'source',
  },
];
export const TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES = [
  {
    Header: 'ACTIVE',
    accessor: 'active',
  },
  {
    Header: 'DAY',
    accessor: 'day',
  },
  {
    Header: 'Working hours',
    accessor: 'working_hours',
  },
  {
    Header: 'Total leads limit',
    accessor: 'total_leads_limit',
  },
  {
    Header: 'Total limits',
    accessor: 'total_limit',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];
export const TABLE_TEMPLATE_ROLES = [
  {
    Header: 'Name template',
    accessor: 'name',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];

export const TABLE_TRADING_ACTIVITY_COLUMNS_NAMES = [
  {
    Header: 'Trade',
    accessor: 'trade',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Trading Acc',
    accessor: 'trading_account',
  },
  // {
  //   Header: 'Original agent',
  //   accessor: 'original_agent',
  // },
  {
    Header: 'Transaction price',
    accessor: 'open_price',
  },
  // {
  //   Header: 'Close price',
  //   accessor: 'close_price',
  // },
  {
    Header: 'Volume',
    accessor: 'volume',
  },
  // {
  //   Header: 'Profit',
  //   accessor: 'profit',
  // },
  // {
  //   Header: 'Swap',
  //   accessor: 'swap',
  // },
  {
    Header: 'Transaction time',
    accessor: 'open_time',
  },
  // {
  //   Header: 'Close time',
  //   accessor: 'close_time',
  // },
];
export const TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES = [
  {
    Header: 'Wallet address',
    accessor: 'wallet_address',
  },
  {
    Header: 'Type',
    accessor: 'crypto_type',
  },
  {
    Header: 'Amount usd',
    accessor: 'amount_usd',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Created',
    accessor: 'created_at',
  },
  {
    Header: 'View detail',
    accessor: 'action',
  },
];
export const TABLE_DYNAMIC_STATUS_TYPES = [
  {
    Header: 'Key',
    accessor: 'key',
  },
  {
    Header: 'Translate',
    accessor: 'translate',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Active',
    accessor: 'active',
  },
  {
    Header: 'Created',
    accessor: 'created_at',
  },
  {
    Header: 'Updated',
    accessor: 'updated_at',
  },
  {
    Header: 'Color',
    accessor: 'change_color',
  },
  {
    Header: 'Action',
    accessor: 'action',
  },
];
export const TABLE_FILES_COLUMNS_NAMES = [
  {
    Header: 'Name',
    accessor: 'name',
  },
  {
    Header: 'Category',
    accessor: 'category',
  },
  {
    Header: 'Type',
    accessor: 'type',
  },
  {
    Header: 'Status',
    accessor: 'status',
  },
  {
    Header: 'Publish date',
    accessor: 'created_at',
  },
  {
    Header: ' ',
    accessor: 'action',
  },
];

export const TABLE_OPENED_POSITIONS_COLUMNS_NAMES = [
  {
    Header: 'Account',
    accessor: 'account',
  },
  {
    Header: 'Side',
    accessor: 'side',
  },
  {
    Header: 'Position',
    accessor: 'position',
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
  },
  {
    Header: 'Open price',
    accessor: 'open_price',
  },
  {
    Header: 'Current price',
    accessor: 'current_price',
  },
  {
    Header: 'Volume',
    accessor: 'volume',
  },
  {
    Header: 'Profit',
    accessor: 'profit',
  },
  {
    Header: 'Open time',
    accessor: 'open_time',
  },
];
export const TABLE_DEALS_COLUMNS_NAMES = [
  {
    Header: 'Account',
    accessor: 'account',
  },
  {
    Header: 'Side',
    accessor: 'side',
  },
  {
    Header: 'Deal',
    accessor: 'deal',
  },
  {
    Header: 'Symbol',
    accessor: 'symbol',
  },
  {
    Header: 'Open price',
    accessor: 'open_price',
  },
  {
    Header: 'Close price',
    accessor: 'close_price',
  },
  {
    Header: 'Volume',
    accessor: 'volume',
  },
  {
    Header: 'Profit',
    accessor: 'profit',
  },
  {
    Header: 'Close time',
    accessor: 'close_time',
  },
];

export const tablesColumnData = {
  TABLE_CLIENTS_COLUMNS_NAMES: TABLE_CLIENTS_COLUMNS_NAMES,
  TABLE_TRADING_ACCOUNT_NAMES: TABLE_TRADING_ACCOUNT_NAMES,
  TABLE_TRADING_ACCOUNT_NAMES_WITH_WT: TABLE_TRADING_ACCOUNT_NAMES_WITH_WT,
  TABLE_LEADS_COLUMNS_NAMES: TABLE_LEADS_COLUMNS_NAMES,
  TABLE_SALES_RULES_COLUMNS_NAMES: TABLE_SALES_RULES_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES: TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_DESKS_COLUMNS_NAMES: TABLE_MANAGEMENTS_DESKS_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES: TABLE_MANAGEMENTS_TEAMS_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES: TABLE_MANAGEMENTS_OPERATORS_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES: TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES,
  TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES_NOT_MT: TABLE_MANAGEMENTS_PARTNERS_COLUMNS_NAMES_NOT_MT,
  TABLE_CALLBACKS_COLUMNS_NAMES: TABLE_CALLBACKS_COLUMNS_NAMES,
  TABLE_ALL_CALLBACKS_COLUMNS_NAMES: TABLE_ALL_CALLBACKS_COLUMNS_NAMES,
  TABLE_CRYPTO_WALLETS_COLUMNS_NAMES: TABLE_CRYPTO_WALLETS_COLUMNS_NAMES,
  TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES: TABLE_CRYPTO_WALLETS_USER_BY_ID_COLUMNS_NAMES,
  TABLE_FEED_COLUMNS_NAMES: TABLE_FEED_COLUMNS_NAMES,
  TABLE_CRYPTO_TRANSACTIONS_COLUMNS_NAMES: TABLE_CRYPTO_TRANSACTIONS_COLUMNS_NAMES,
  TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES: TABLE_CRYPTO_INNER_TRANSACTIONS_COLUMNS_NAMES,
  TABLE_KYC_DOCUMENTS_COLUMNS_NAMES: TABLE_KYC_DOCUMENTS_COLUMNS_NAMES,
  TABLE_NOTIFICATIONS_COLUMNS_NAMES: TABLE_NOTIFICATIONS_COLUMNS_NAMES,
  TABLE_NOTIFICATIONS_POPUP_COLUMNS_NAMES: TABLE_NOTIFICATIONS_POPUP_COLUMNS_NAMES,
  TABLE_TEMPLATES_COLUMNS_NAMES: TABLE_TEMPLATES_COLUMNS_NAMES,
  TABLE_WALLET_BINDINGS: TABLE_WALLET_BINDINGS,
  TABLE_ROLES_AND_PERMISSIONS: TABLE_ROLES_AND_PERMISSIONS,
  TABLE_TRADING_ACC_COLUMNS_NAMES: TABLE_TRADING_ACC_COLUMNS_NAMES,
  TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES: TABLE_SALES_RULES_OPERATOR_COLUMNS_NAMES,
  TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES: TABLE_PARTNER_SCHEDULES_COLUMNS_NAMES,
  TABLE_TEMPLATE_ROLES: TABLE_TEMPLATE_ROLES,
  TABLE_TRADING_ACTIVITY_COLUMNS_NAMES: TABLE_TRADING_ACTIVITY_COLUMNS_NAMES,
  TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES: TABLE_CRYPTO_REQUESTS_COLUMNS_NAMES,
  TABLE_DYNAMIC_STATUS_TYPES: TABLE_DYNAMIC_STATUS_TYPES,
  TABLE_FILES_COLUMNS_NAMES: TABLE_FILES_COLUMNS_NAMES,
  TABLE_OPENED_POSITIONS_COLUMNS_NAMES: TABLE_OPENED_POSITIONS_COLUMNS_NAMES,
  TABLE_DEALS_COLUMNS_NAMES: TABLE_DEALS_COLUMNS_NAMES,
};

export const tablesColumnDataKeys = Object.keys(tablesColumnData);

const targetIndex = 3;
const regex = new RegExp(`th:nth-child\\(${targetIndex}\\)\\s*{[\\s\\S]*?}`, 'g');
