import classNames from 'classnames/bind';
import React, { FC } from 'react';

import styles from './loader-button.module.scss';
import './loader-btn.scss';
import { ILoaderButton } from "../../../models/components/LoaderButton/LoaderButton.ts";

const cx = classNames.bind(styles);

const LoaderButton: FC<ILoaderButton> = (props) => {

  const {
    buttonText,
    size,
    buttonType,
    buttonStyle,
    onClick,
    showSpinner,
    disabled,
    spinnerStyle,
    type,
    ...other
  } = props;

  return (
    <button
      className={cx('btn', 'loader-btn', buttonType, size, buttonStyle)}
      onClick={onClick}
      disabled={disabled}
      {...other}
    >
      <span className={cx('btn__text', showSpinner ? 'hidden' : '')}>{buttonText}</span>
      {showSpinner ? (
        <div style={spinnerStyle} className="spinner-wrapp">
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      ) : (
        ''
      )}
    </button>
  );
};

export default LoaderButton;
