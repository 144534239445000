import { Col, Row } from 'react-bootstrap';
import FormInput from 'components/ui/FormInput';
import classNames from 'classnames/bind';
import {
  defaultDateTimeFormat,
  ModernDateRangeInput,
} from '../../../../../components/ui/ModernDateRangeInput';
import styles from '../client-filter.module.scss';
import ButtonReset from '../../../../../components/ui/Button/ButtonReset';
import JokerSelect from '../../../../../components/ui/JokerSelect';
import {
  ACQUISITION_SELECT_OPTIONS,
  ACTIVITY_SELECT_OPTIONS,
  FIRST_TIME_DEPOS_SELECT_OPTIONS,
  NOTES_SELECT_OPTIONS,
  PROFILE_STATUS_SELECT_OPTIONS,
} from '../../../../../constants/clients/filters.const';
import JokerMultiSelect from '../../../../../components/ui/JokerMultiSelect';
import DatePickerRange from '../../../../../components/ui/DatePickerRange';
import { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getEnableMT } from '../../../../../constants/selectors';
import { CountryOpt, DynamicFilterRequiredType } from 'models/Clients/ClientFilter';
import { ListValue } from 'models/ListValue';
import { Control, UseFormHandleSubmit } from 'react-hook-form';
import { ModernDateTimeInputHeader } from 'components/ui/ModernDateRangeInput/ModernDateTimeInputHeader';
import PaginateSelect from 'components/ui/PaginateSelect';

const cx = classNames.bind(styles);

type Props = {
  control: Control<any>;
  handleSubmit: UseFormHandleSubmit<any>;

  submit: (data?: any) => void;

  getDirtyFieldsLengthBalance: (arr: any[]) => boolean;
  getDirtyFieldsLength: () => number | any;
  resetBalance: () => void;
  resetActivity: () => void;
  resetTeam: () => void;
  resetStatuses: () => void;
  resetDates: () => any;
  resetForm: () => void;

  countryOpt: CountryOpt[];
  languageOpt: ListValue[];
  // sources: ListValue[];
  // referrals: ListValue[] | any;
  affiliate: ListValue[];
  assignStatusList: ListValue[] | any;
  dynamicFilterList: DynamicFilterRequiredType[];

  setSelectedDynamicFilter: (val: any[]) => void;
  desks: ListValue[];
  teams: ListValue[];
  operators: ListValue[];

  selectedDesks: any;
  setSelectedDesks: any;
  selectedTeams: any;
  setSelectedTeams: any;
  fetchTeamsList?: () => void;
  fetchReferralsList?: (
    page?: number,
    query?: string,
  ) => Promise<void | {
    data: any;
    total: number;
  }>;
  fetchSourceList: (
    page?: number,
    query?: string,
  ) => Promise<void | {
    data: any;
    total: number;
  }>;
};

const SearchSectionAllFilters = (props: Props) => {
  const {
    control,
    handleSubmit,
    submit,
    getDirtyFieldsLengthBalance,
    resetBalance,
    countryOpt,
    resetActivity,
    // sources,
    // referrals,
    resetTeam,
    affiliate,
    assignStatusList,
    dynamicFilterList,
    resetStatuses,
    resetDates,
    resetForm,
    setSelectedDynamicFilter,
    getDirtyFieldsLength,
    languageOpt,
    desks,
    teams,
    operators,
    // selectedDesks,
    setSelectedDesks,
    // selectedTeams,
    setSelectedTeams,
    fetchReferralsList,
    fetchSourceList,
  } = props;

  const ENABLE_MT = useSelector(getEnableMT);

  return (
    <>
      <Row style={{ gap: 34 }}>
        <Col lg={12} style={{ flex: 3 }}>
          <Row className={cx('range-wrapper')}>
            <Col md={2}>
              <FormInput
                name="balance.from"
                type="text"
                inputmode="numeric"
                placeholder="0"
                min={0}
                label="Balance"
                pattern="[0-9]*"
                control={control}
              />

              <span className={cx('range-divider')}>-</span>
            </Col>
            <Col md={2}>
              <FormInput
                name="balance.to"
                min={0}
                type="text"
                inputmode="numeric"
                label="&nbsp;"
                placeholder="0"
                pattern="[0-9]*"
                control={control}
              />
            </Col>
            <Col md={2}>
              <FormInput
                name="number_deposits.from"
                type="text"
                inputmode="numeric"
                //labelStyle={{ width: isMobile() ? '200%' : '300%' }}
                placeholder="0"
                min={0}
                label="Number of deposits"
                control={control}
              />
              <span className={cx('range-divider')}>-</span>
            </Col>
            <Col md={2}>
              <FormInput
                name="number_deposits.to"
                min={0}
                type="text"
                inputmode="numeric"
                label="&nbsp;"
                placeholder="0"
                control={control}
              />
            </Col>
            <Col>
              <div style={{ display: 'flex', paddingTop: 27, gap: 22 }}>
                <ButtonReset
                  buttonText="Apply filters"
                  type="submit"
                  onClick={handleSubmit(submit)}
                  disabled={!getDirtyFieldsLengthBalance(['number_deposits', 'balance'])}
                />
                <ButtonReset
                  buttonText="Reset"
                  type="submit"
                  variant="link"
                  onClick={resetBalance}
                  //disabled={!getDirtyFieldsLength()}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ gap: 34 }}>
            <Col className={cx('range-wrapper', 'activity')}>
              <div className={cx('filter-title')}>General information</div>
              <JokerSelect
                label="Activity"
                control={control}
                id="Activity"
                name="activity"
                options={ACTIVITY_SELECT_OPTIONS}
              />
              <JokerMultiSelect
                label="Languages"
                control={control}
                id="languages"
                name="languageIds"
                options={languageOpt}
                ungroup
              />
              <JokerMultiSelect
                label="Country"
                control={control}
                id="country"
                name="countryIds"
                options={countryOpt}
                ungroup
              />
              <Col>
                <div style={{ display: 'flex', paddingTop: 20, paddingBottom: 20, gap: 22 }}>
                  <ButtonReset
                    buttonText="Apply filters"
                    type="submit"
                    onClick={handleSubmit(submit)}
                    disabled={
                      !getDirtyFieldsLengthBalance(['countryIds', 'languageIds', 'activity'])
                    }
                  />
                  <ButtonReset
                    buttonText="Reset all"
                    type="submit"
                    variant="link"
                    onClick={resetActivity}
                    //disabled={!getDirtyFieldsLength()}
                  />
                </div>
              </Col>
            </Col>
            <Col className={cx('range-wrapper', 'activity')}>
              <div className={cx('filter-title')}>Belonging</div>
              <JokerMultiSelect
                label="Desks"
                control={control}
                id="desks"
                name="deskIds"
                options={desks}
                ungroup
                onChange={(value) => {
                  setSelectedDesks(value);
                }}
              />

              <JokerMultiSelect
                label="Teams"
                control={control}
                id="teams"
                name="teamIds"
                options={teams}
                ungroup
                onChange={(value) => {
                  setSelectedTeams(value);
                }}
              />

              <JokerMultiSelect
                label="Operators"
                control={control}
                id="operators"
                name="operatorIds"
                options={operators}
                ungroup
              />

              <JokerMultiSelect
                label="Affiliates"
                control={control}
                id="affiliate"
                name="affiliateIds"
                options={affiliate}
                ungroup
              />

              <JokerSelect
                label="First time deposit"
                control={control}
                id="first-time-deposit"
                name="first_time_deposit"
                options={FIRST_TIME_DEPOS_SELECT_OPTIONS}
              />
              <JokerSelect
                label="Notes"
                control={control}
                id="notes"
                name="notes"
                options={NOTES_SELECT_OPTIONS}
              />
              <PaginateSelect
                isMulti
                label="Source"
                control={control}
                id="sources"
                name="sources"
                truncateLabelSize={50}
                onChange={(page, search) => fetchSourceList(page, search)}
              />
              <PaginateSelect
                isMulti
                label="Referral"
                control={control}
                id="referrals"
                name="referrals"
                truncateLabelSize={50}
                onChange={(page, search) => fetchReferralsList(page, search)}
              />
              <Col>
                <div style={{ display: 'flex', paddingTop: 20, paddingBottom: 20, gap: 22 }}>
                  <ButtonReset
                    buttonText="Apply filters"
                    type="submit"
                    onClick={handleSubmit(submit)}
                    disabled={
                      !getDirtyFieldsLengthBalance([
                        'first_time_deposit',
                        'affiliateIds',
                        'operatorIds',
                        'teamIds',
                        'deskIds',
                        'notes',
                        'sources',
                        'referrals',
                      ])
                    }
                  />
                  <ButtonReset
                    buttonText="Reset all"
                    type="submit"
                    variant="link"
                    onClick={() => {
                      resetTeam();
                      setSelectedDesks([]);
                      setSelectedTeams([]);
                    }}
                    //disabled={!getDirtyFieldsLength()}
                  />
                </div>
              </Col>
            </Col>
          </Row>
        </Col>
        <Col lg={4} className={cx('range-wrapper')}>
          <div className={cx('filter-title')}>Statuses</div>
          <JokerSelect
            label="Profile status"
            control={control}
            id="profile_status"
            name="profile_status"
            options={PROFILE_STATUS_SELECT_OPTIONS}
          />
          <JokerSelect
            label="Acquisition status"
            control={control}
            id="desk_type"
            name="desk_type"
            options={ACQUISITION_SELECT_OPTIONS}
          />
          <JokerSelect
            label="Assign status"
            control={control}
            id="assign_status"
            name="assign_status"
            options={assignStatusList}
          />
          {dynamicFilterList.map((item, index) => {
            return (
              <JokerMultiSelect
                key={index}
                label={item.label}
                control={control}
                id={item.value}
                name={`${item.value}`}
                options={item.options}
                ungroup
              />
            );
          })}
          <Col>
            <div style={{ display: 'flex', paddingTop: 20, paddingBottom: 20, gap: 22 }}>
              <ButtonReset
                buttonText="Apply filters"
                type="submit"
                onClick={handleSubmit(submit)}
                disabled={
                  !getDirtyFieldsLengthBalance([
                    'assign_status',
                    'desk_type',
                    'profile_status',
                    'kyc',
                    'retention',
                    'sales',
                  ])
                }
              />
              <ButtonReset
                buttonText="Reset all"
                type="submit"
                variant="link"
                onClick={resetStatuses}
                //disabled={!getDirtyFieldsLength()}
              />
            </div>
          </Col>
        </Col>
      </Row>
      <Row className={cx('range-wrapper')}>
        <div className={cx('filter-title')}>Filters by date</div>
        <Row>
          <Col xl={4} lg={6} md={6}>
            <ModernDateRangeInput
              name="created_at"
              control={control}
              label="Registration date range"
              placeholder="From - To"
              dateFormat={defaultDateTimeFormat}
              allowToChangeViaInput={true}
              renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                <ModernDateTimeInputHeader
                  {...props}
                  from={props.from}
                  to={props.to}
                  onTimeChangeHandler={onTimeChangeHandler}
                  showRange
                />
              )}
            />
          </Col>
          <Col xl={4} lg={6} md={6}>
            <ModernDateRangeInput
              name="ftd_range"
              control={control}
              label="FTD date range"
              placeholder="From - To"
              dateFormat={defaultDateTimeFormat}
              allowToChangeViaInput={true}
              renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                <ModernDateTimeInputHeader
                  {...props}
                  from={props.from}
                  to={props.to}
                  onTimeChangeHandler={onTimeChangeHandler}
                  showRange
                />
              )}
            />
          </Col>
          <Col xl={4} lg={6} md={6}>
            <ModernDateRangeInput
              name="note_first"
              control={control}
              label="First note date"
              placeholder="From - To"
              dateFormat={defaultDateTimeFormat}
              allowToChangeViaInput={true}
              renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                <ModernDateTimeInputHeader
                  {...props}
                  from={props.from}
                  to={props.to}
                  onTimeChangeHandler={onTimeChangeHandler}
                  showRange
                />
              )}
            />
          </Col>
          {ENABLE_MT ? (
            <Col xl={4} lg={6} md={6}>
              <ModernDateRangeInput
                name="last_trade"
                control={control}
                label="Last trade date"
                placeholder="From - To"
                dateFormat={defaultDateTimeFormat}
                allowToChangeViaInput={true}
                renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                  <ModernDateTimeInputHeader
                    {...props}
                    from={props.from}
                    to={props.to}
                    onTimeChangeHandler={onTimeChangeHandler}
                    showRange
                  />
                )}
              />
            </Col>
          ) : null}
          <Col xl={4} lg={6} md={6}>
            <ModernDateRangeInput
              name="note_last"
              control={control}
              label="Last note date"
              placeholder="From - To"
              dateFormat={defaultDateTimeFormat}
              allowToChangeViaInput={true}
              renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                <ModernDateTimeInputHeader
                  {...props}
                  from={props.from}
                  to={props.to}
                  onTimeChangeHandler={onTimeChangeHandler}
                  showRange
                />
              )}
            />
          </Col>
          <Col xl={4} lg={6} md={6}>
            <ModernDateRangeInput
              name="last_login"
              control={control}
              label="Last login date"
              placeholder="From - To"
              dateFormat={defaultDateTimeFormat}
              allowToChangeViaInput={true}
              renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                <ModernDateTimeInputHeader
                  {...props}
                  from={props.from}
                  to={props.to}
                  onTimeChangeHandler={onTimeChangeHandler}
                  showRange
                />
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={4} lg={6} md={6}>
            <ModernDateRangeInput
              name="updated_at"
              control={control}
              label="Last modification"
              placeholder="From - To"
              dateFormat={defaultDateTimeFormat}
              allowToChangeViaInput={true}
              renderCustomHeader={(props: any, onTimeChangeHandler: any) => (
                <ModernDateTimeInputHeader
                  {...props}
                  from={props.from}
                  to={props.to}
                  onTimeChangeHandler={onTimeChangeHandler}
                  showRange
                />
              )}
            />
          </Col>
        </Row>
        <Col>
          <div style={{ display: 'flex', paddingTop: 20, paddingBottom: 20, gap: 22 }}>
            <ButtonReset
              buttonText="Apply filters"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={
                !getDirtyFieldsLengthBalance([
                  'created_at',
                  'ftd_range',
                  'note_first',
                  'last_trade',
                  'note_last',
                  'last_login',
                  'updated_at',
                  // 'limit',
                ])
              }
            />
            <ButtonReset
              buttonText="Reset all"
              type="submit"
              variant="link"
              onClick={resetDates}
              //disabled={!getDirtyFieldsLength()}
            />
          </div>
        </Col>
      </Row>
      <Row style={{ paddingBottom: 34, gap: 20 }}>
        <Col style={{ display: 'flex', gap: 22 }}>
          <ButtonReset
            buttonText="Apply all filters"
            type="submit"
            onClick={handleSubmit(submit)}
            disabled={!getDirtyFieldsLength()}
          />
          <ButtonReset
            style={{ maxWidth: '170px', display: 'inline-flex', justifyContent: 'center' }}
            buttonText="Reset all filters"
            type="reset"
            onClick={() => {
              resetForm();
              setSelectedDesks([]);
              setSelectedTeams([]);
              setSelectedDynamicFilter([]);
            }}
            disabled={!getDirtyFieldsLength()}
          />
        </Col>
      </Row>
    </>
  );
};

export default memo(SearchSectionAllFilters);
