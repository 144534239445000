import { endOfDay, format, parseISO, startOfDay } from "date-fns";
export const getTimesTamp = (data): undefined | object => {
  if (!data) {
    return;
  }

  if (!data.from && !data.to) {
    return;
  }

  const res: object = {};

  if (data.from) {
    res['from'] = format(startOfDay(parseISO(data['from'])), 't');
  }

  if (data.to) {
    res['to'] = format(endOfDay(parseISO(data['to'])), 't');
  }

  return res;
}
