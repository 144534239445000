import cn from "classnames";
import { X } from "phosphor-react";
import { ReactNode } from "react";
import { JokerModal } from "../../JokerModal";
import './JokerModalTemplate.scss'

export type ModalSizes = 'sm' | 'md' | 'lg' | 'xs';
type ModalTemplateProps = {
    id: string;
    title: string | ReactNode;
    size?: ModalSizes;
    onClose: () => void;
    closeOnOverlayClick?: boolean;
    className?: string;
    children: ReactNode;
}

export const ModalTemplate = ({ id, title, children, onClose, closeOnOverlayClick, size = 'md', className = '' }: ModalTemplateProps) => {
    return (
        <JokerModal modal={id} onOverlayClick={closeOnOverlayClick && onClose}>
            <div className={cn("modal-template", size, className)}>
                <div className="modal-template-header">
                    <div className="modal-template-title"><span>{title}</span></div>
                    <div className="modal-template-close" onClick={onClose}><X size={20} color="#fff"/></div>
                </div>
                <div className="modal-template-content">{children}</div>
            </div>
        </JokerModal>
    )
};
