import cn from "classnames";
import { ThreadTypes } from "../../../../models/Chat";
import { ChatBadge } from "../ChatBadge/ChatBadge";
import { Dispatch, SetStateAction } from "react";
import { useAppSelector } from "../../../../store";
import { selectChatUnreadSupport, selectChatUnreadInternal } from "../../../../store/slice/chat/chatSlice";
import './ChatTypeSwitcher.scss';

type ChatRoleFilterProps = {
    type: ThreadTypes,
    setType: Dispatch<SetStateAction<ThreadTypes>>;
}

export const ChatTypeSwitcher = ({ type, setType}: ChatRoleFilterProps) => {
    const unreadSupport = useAppSelector(selectChatUnreadSupport);
    const unreadInternal = useAppSelector(selectChatUnreadInternal);

    return (
        <div className="chat-type-switch">
            <div
                className={cn("chat-type-tab", {active: type === ThreadTypes.internal})}
                onClick={() => setType(ThreadTypes.internal)}
            >
                <span>
                    Internals
                    {unreadInternal ? <ChatBadge count={unreadInternal} /> : null}
                </span>
            </div>
            <div
                className={cn("chat-type-tab", {active: type === ThreadTypes.support})}
                onClick={() => setType(ThreadTypes.support)}>
                <span>
                    Support
                    {unreadSupport ? <ChatBadge count={unreadSupport} /> : null}
                </span>
            </div>
        </div>
    )
}