import { Tooltip } from 'bootstrap';
import classNames from 'classnames/bind';
import { CaretDown, CaretUp, X, XCircle } from 'phosphor-react';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useController } from 'react-hook-form';
import ReactMultiSelectCheckboxes from '../multiselect-checkboxes/ReactMultiselectCheckboxes.jsx';
import { CheckboxGroupHeading } from '../multiselect-checkboxes/CheckboxGroup.jsx';
import { components } from 'react-select';
import { SELECT_DEFAULT_STYLES } from '../../../constants/select';
import { actionFilters } from '../../../pages/Clients/Client/ClientTabs/Feed/FeedFilter/helper';
import './joker-ms.scss';

import styles from './joker-multiselect.module.scss';
import {
  IJokerMultiSelect,
  IOptionsMultiSelect,
} from '../../../models/components/JokerMultiSelect/JokerMultiSelect.ts';

const cx = classNames.bind(styles);

const JokerMultiSelect: FC<IJokerMultiSelect> = (props) => {
  const {
    onChange,
    options,
    label,
    onMenuOpen,
    forSmallPopup = false,
    isRequired = false,
    maxSelected,
    errors,
    ungroup,
    hideSearch,
    ...other
  } = props;
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { field } = useController(props);
  const { ref, ...fieldProps } = field;
  const selectedValueDefaultLabel: string = props.defaultLabel || props.placeholder || 'Any';

  const [matches, setMatches] = useState<boolean>(
    window.matchMedia('(min-height: 1200px)').matches,
  );
  const setHeightMenu = useCallback((e) => setMatches(e.matches), []);

  useEffect(() => {
    window.matchMedia('(min-height: 1200px)').addEventListener('change', setHeightMenu);

    return () => {
      return window.removeEventListener('change', setHeightMenu);
    };
  }, []);

  const getOptions = (data): IOptionsMultiSelect[] | undefined => {
    if (!data) {
      return;
    }

    return [
      {
        label: 'Available options',
        options: data,
      },
    ];
  };

  const Control = ({ children, ...props }: any) => {
    return (
      <div className={cx('search-header')}>
        <components.Control {...props}>{children}</components.Control>
      </div>
    );
  };

  const GroupHeading = ({ children, ...props }): React.JSX.Element => {
    if (children === 'Selected') {
      return (
        <div className={cx('group-heading')}>
          <span>{children}</span>
          <span className={cx('clear-btn')} {...props} onClick={null}>
            <XCircle size={13} />
            {'Clear'}
          </span>
        </div>
      );
    }

    return (
      <div className={cx('group-heading')}>
        <span>{children}</span>
        <CheckboxGroupHeading className={cx('all-btn')} {...props}>
          {'All'}
        </CheckboxGroupHeading>
      </div>
    );
  };

  const shortenTextByScript = (text: string, max: number): string => {
    let result = text;

    if (result.length > max) {
      result = result.substring(0, max) + '...';
    }

    return result;
  };

  const DropdownButton = ({ children, onPress, isSelected, ...props }) => {
    useEffect((): void => {
      setIsOpen(isSelected);
    }, [isSelected]);

    const renderEntries = (entries?: { label: string; value: string }[]): JSX.Element => {
      if (!entries) {
        return <span>{selectedValueDefaultLabel}</span>;
      }

      const MAX_ENTRIES_AS_BLOCKS = 15;

      return (
        <div className={cx('jokermultiselect-grid')}>
          {entries.slice(0, MAX_ENTRIES_AS_BLOCKS).map((entry, key) => (
            <div
              key={key}
              style={{
                backgroundColor: 'rgb(230, 230, 230)',
                display: 'flex',
                flexDirection: 'row',
                borderRadius: 2,
                alignItems: 'center',
                justifyContent: 'space-between',
              }}
            >
              <p
                style={{
                  fontSize: '85%',
                  lineHeight: '150%',
                  textAlign: 'center',
                  verticalAlign: 'center',
                  padding: '4px',
                  wordBreak: 'break-word',
                  width: '100%',
                }}
              >
                {shortenTextByScript(entry.label, 25)}
              </p>
              <X
                style={{ cursor: 'pointer', marginRight: 4 }}
                onClick={(event) => {
                  event.stopPropagation();
                  const filtered = entries.filter((e) => e.value !== entry.value);
                  field.onChange(filtered);
                  onChange?.(filtered);
                }}
              />
            </div>
          ))}
          {entries.length > MAX_ENTRIES_AS_BLOCKS && `+${entries.length - MAX_ENTRIES_AS_BLOCKS}`}
          {entries.length === 0 && '--Select--'}
        </div>
      );
    };

    const chooseDisplayMethod = (): JSX.Element => {
      if (ungroup) {
        return renderEntries(field.value);
      }

      if (field?.value?.length) {
        return <span>{actionFilters(field.value, children)}</span>;
      }

      return <span>{selectedValueDefaultLabel}</span>;
    };

    return (
      <div
        className={forSmallPopup ? 'select-valueForSmallPopup' : 'select-value'}
        onClick={(e: React.MouseEvent<HTMLDivElement>): void => {
          e.preventDefault();
          onPress();

          if (onMenuOpen) {
            onMenuOpen();
          }
        }}
      >
        <div className={cx('label-wrap')}>
          {label && (
            <label htmlFor={props.id} className={cx('label', 'big_label')}>
              {label}
              {isRequired && <span className={cx('required')}>*</span>}
            </label>
          )}
          <div className={cx('icon-container')}>
            {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
          </div>
        </div>
        <div className={cx('custome-control', isOpen ? 'list-opened' : '', errors ? 'error' : '')}>
          {/*<span>{field.value?.length ? children : selectedValueDefaultLabel}</span>*/}
          {chooseDisplayMethod()}
          {isOpen ? <CaretUp size={18} /> : <CaretDown size={18} />}
        </div>
      </div>
    );
  };

  const customStyles = (hideHeading) => {
    return {
      ...SELECT_DEFAULT_STYLES,
      control: (provided, state) => {
        const optionsLength = state?.options[0]?.options?.length;
        return {
          ...provided,
          zIndex: '9999',
          padding: '0px 13px',
          boxShadow: '1px solid #262626',
          display: optionsLength > 1 ? 'flex' : 'none',
          '&:hover': {
            border: state.isFocused ? '1px solid #262626 !important' : '1px solid #c2c2c2',
          },
          backgroundColor: '#ffffff',
          margin: '10px',
          border: ' 1px solid var(--primary-text-color)',
          position: 'relative',
          minWidth: '50px',
        };
      },
      menuList: (provided) => ({
        ...provided,
        padding: 0,
        maxHeight: matches ? '435px' : '150px',
        width: '100%',
        borderTop: '1px solid #EBEBEB',
      }),
      container: (provided) => ({
        ...provided,
        border: ' 1px solid #EBEBEB',
        boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.4);',
        backgroundColor: '#ffffff',
      }),
      menu: (provided) => ({
        ...provided,
        padding: 0,
        boxShadow: 'unset',
        border: 'none',
        // overflow: 'hidden',
      }),
      option: (provided, state) => {
        return {
          ...provided,
          padding: '10px 12px',
          fontSize: '13px',
          lineHeight: '20px',
          fontWeight: 400,
          fontFamily: 'Inter',
          backgroundColor: state.isSelected && undefined,
          ':hover': {
            backgroundColor: '#f9f1ff',
          },
          ':active': {
            // ...styles[':active'],
            backgroundColor: !state.isDisabled
              ? state.isSelected
                ? '#f9f1ff'
                : '#f9f1ff'
              : undefined,
          },
          color: '#262626',
          minWidth: '50px',
        };
      },
      groupHeading: (provided, state) => {
        return !hideHeading
          ? {
              ...provided,
              color: 'var(--purple)',
              fontFamily: 'Inter',
              fontSize: '12px',
            }
          : {
              display: 'none',
            };
      },
    };
  };

  return (
    <>
      <div>
        <ReactMultiSelectCheckboxes
          {...(props?.label === 'Sales representative' ? { ...props } : { ...fieldProps })}
          components={{ DropdownButton, GroupHeading }}
          styles={customStyles(props.hideHeading)}
          onChange={(data): void => {
            if (!maxSelected || data.length <= maxSelected) {
              field.onChange(data);
              onChange?.(data);
            }
          }}
          rightaligned={props.rightaligned}
          options={getOptions(options)}
          className={'multi-select'}
          value={field.value || []}
          hideSearch={hideSearch}
        />
        {errors && <p className={cx('errorName')}>{errors.message}</p>}
      </div>
    </>
  );
};

export default JokerMultiSelect;
