import { User } from "phosphor-react";
import { openInNewTab } from "../../helpers/openInNewTab";
import { MenuOption } from "./MenuOption";

type ViewProfileOptionProps = {
    url: string;
};

export const ViewProfileOption = ({ url }: ViewProfileOptionProps) => {
    const handleViewProfile = () => openInNewTab(url);

    return (
        <MenuOption title='View profile' onClick={handleViewProfile}>
            <User size={32} color="#722ED1"/>
        </MenuOption>
    )
}