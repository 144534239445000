import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.scss';

import { useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import SalesRulesOperatorTable from '../../../../../../components/ui/Table/SalesRulesOperatorTable';
import { SalesRuleShort } from 'models/Management/SalesRules/Rule';

const cx = classNames.bind(styles);

type Props = { salesRulesList: SalesRuleShort[] }

const SalesRules = (props: Props) => {
  const { salesRulesList } = props;

  const { id } = useParams();
  const componentMounted = useRef(true);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <div className={cx('tab-panel', 'callbacks')}>
      <div className={cx('tab-panel__content')}>
        <div className={cx('tab-panel__header')}>
          <div className={cx('tab-panel__title')}>Sales Rules</div>
        </div>
        <div className={cx('tab-panel-table')}>
          <SalesRulesOperatorTable data={salesRulesList} showLoader={false} userId={id} />
        </div>
      </div>
    </div>
  );
};

export default SalesRules;
