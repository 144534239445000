import axios, { AxiosInstance } from 'axios';
import { notify } from '../utils/notify';
import { timeout } from '../utils/timeout.js';
import { DisconnectWSS } from "../utils/ConnectWSS";

interface INotification {
  check: boolean,
}

const notification: INotification = {
  check: false,
};

const api: AxiosInstance = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json;charset=UTF-8',
  },
  timeout: 30000,
});

api.interceptors.request.use(
  (config) => {
    const token: string = localStorage.getItem('token');
    config.headers['Authorization'] = 'Bearer ' + token;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    const { status } = response;
    if (status === 200 || status === 201) {
      return response;
    }
    if (status === 202) {
      notify({
        type: 'success',
        message: `Please wait, the data is being processed. This may take some time.`,
        timeOut: 3000,
      });
      return response;
    }
  },
  async (error) => {
    const { status, statusText } = error.response;
    if (status === 500) {
      notify({
        type: 'error',
        message: `${error?.response?.data?.message}` || `${status} ${statusText}`,
        timeOut: 3000,
      });
    }
    if (status === 401) {
      if (notification.check && error.config.url !== '/api/v1/login') return;
      notification.check = true;
      DisconnectWSS();
      localStorage.clear();
      notify({
        type: 'warning',
        message: `Your session was expiring`,
        timeOut: 3000,
      });
      
      if (error.config.url !== '/api/v1/login') {
        await timeout(2000);
        window.location.reload();
      }
    }
    return Promise.reject(error);
  },
);

export default api;
