import classNames                     from 'classnames/bind';
import React, { useEffect, useState } from 'react';
import { Col, Row }                   from 'react-bootstrap';
import {
  getDepartmentsListMethod,
  getPermissionsTempleRole,
  resetToDefaultPermissions,
}                                     from '../../api/rolesAndPermissions/index';
import Button                         from '../../components/ui/Button/index.js';
import DepartmentsMenu                from '../../components/ui/DepartmentsMenu/index.js';
import {
  usePermission,
} from '../../utils/usePermission.tsx';
import Page                           from '../Page/index.js';
import {
  DashboardLayout,
}                                     from '../UserView/DashboardLayout';

import styles     from './rolesPermissions-page.module.scss';
import RolesTable from './RolesTable/index.js';
import { Department } from 'models/RolesAndPermissions/Department.js';
import { PermissionList, RAP_ActiveRole as ActiveRole } from 'models/RolesAndPermissions/Permissions.ts';

const cx = classNames.bind(styles);
const RolesPermissionsPage = () => {

  const { permissionGiven: PermissionList } = usePermission('admin.permission.list');
  const { permissionGiven: PermissionShow } = usePermission('admin.role.show');

  const [departmentsList, setDepartmentsList] = useState<Department[]>([]);

  const [activeRole, setActiveRole] = useState<ActiveRole>(null);

  const [listAction, setListAction] = useState<PermissionList>();
  const title = 'Roles and Permissions';

  useEffect(() => {
    document.title = title;
  }, [title]);

  const role = JSON.parse(localStorage.getItem('role'))[0]

  const parseList = (list) => {
    let newList = list.map((item, ind) => {
      return { ...item, subItems: [], show: false };
    });
    return newList;
  };
  useEffect(() => {
    getDepartmentsListMethod().then((res) => {
      if (res.status === 200) {
        setDepartmentsList(parseList(res.data.data));
      }
    });
  }, []);
  useEffect(() => {
    getGroupAction([]);
    activeRole?.id &&
    getPermissionsTempleRole(activeRole.id)
      .then((res) => {
        if (res.status === 200) {
          getGroupAction(res.data.data);
        }
      })
      .catch((error) => {
        getGroupAction([]);
      });
  }, [activeRole]);

  const getGroupAction = (list) => {
    let newList = list.reduce((acc, curr) => {
      let name = curr.action;
      if (acc[name]) {
        return { ...acc, [name]: [...acc[name], curr] };
      }
      return { ...acc, [name]: [curr] };
    }, {});
    setListAction(newList);
  };

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>Roles and Permissions</span>
                <span></span>
              </div>
            </div>
          </div>
          <div className={cx('container__block')}>
            <Row>
              <Col xl={3}>
                {PermissionShow ?
                  <DepartmentsMenu
                    list={departmentsList}
                    setActiveRole={setActiveRole}
                    activeRole={activeRole}
                  />
                  : null
                }
              </Col>
              {PermissionList ?
                <Col xl={9}>
                  <div
                    style={activeRole?.name && { justifyContent: 'space-between' }}
                    className={styles.wrapperHeaderTable}
                  >
                    {activeRole?.name && <p className={styles.bolderText}>{activeRole.name}</p>}
                    {role === 'Super Admin' ?
                      <Button
                        buttonText='Reset to Default'
                        type='reset'
                        onClick={() => {
                          resetToDefaultPermissions(activeRole.id)
                            .then((r) => {
                            })
                            .finally(() => {
                              getGroupAction([]);
                              getPermissionsTempleRole(activeRole.id).then((res) => {
                                if (res.status === 200) {
                                  getGroupAction(res.data.data);
                                }
                              });
                            });
                        }}
                        disabled={activeRole ? false : true}
                      />
                      : null
                    }
                  </div>
                  <RolesTable listAction={listAction} activeRole={activeRole} />
                  {!activeRole && (
                    <p className={styles.worryingGray}>
                      Please choose an authority to the show permissions...
                    </p>
                  )}
                </Col>
                : null
              }
            </Row>
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default RolesPermissionsPage;
