import React, { memo } from 'react';
import moment from 'moment/moment';
import classNames from 'classnames/bind';
import styles from '../client-page.module.scss';
import { Client } from 'models/Clients/Clients';
const cx = classNames.bind(styles);

type Props = {
  clientData: Client;
}

const Registered = memo<Props>(({ clientData }) => {
  return (
    <>
      <div className={cx('client-info__col-title')}>Registered</div>
      <div className={cx('client-info__col-content')}>
        <div className={cx('strong')}>
          {clientData?.registration
            ? moment(clientData?.registration * 1000).fromNow()
            : '---'}
        </div>
        <div>
          on{' '}
          {clientData?.registration
            ? moment(clientData?.registration * 1000).format('DD.MM.YYYY')
            : '---'}
        </div>
      </div>
    </>
  );
});

export default Registered;
