import { range } from 'lodash';
import styles from './burger-button.module.scss';

type Props = {
  onClick: () => void;
};

export const BurgerButton = ({ onClick }: Props) => {
  return (
    <div role="button" className={styles.button} onClick={onClick}>
      {range(3).map((n: number) => (
        <div key={n} className={styles.line} />
      ))}
    </div>
  );
};
