import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { Container, Row, Col } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import Button from 'components/ui/Button';
import styles from '../operator-page.module.scss';
import { notify } from 'utils/notify';
import JokerSelectWithSearch from '../../../../../components/ui/JokerSelectWithSearch';
import { updateOperatorProfileMethod } from '../../../../../api/operators';
import PaginateSelect from '../../../../../components/ui/PaginateSelect/PaginateSelect';
import { fetchUserTypes } from '../../../../../utils/managment/fetchData';
import { ManagementOperator } from 'models/Management/Operators/Operator';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  formData: ManagementOperator;
  updateComponent: () => void;
}

const HierarchyForm = (props: Props) => {
  const { formData, updateComponent } = props;

  const [isLoading, setIsLoading] = useState(true);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      type_user_id: data.type_user_id.value,
    };
    updateOperatorProfileMethod(formData.id, sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'User Type changed success',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };
  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      setIsLoading(false);
      reset({
        type_user_id: { value: formData?.type_user?.id, label: formData?.type_user?.name },
      });
    }
  }, [formData]);
  return (
    <form className={cx('form', 'form_userType')} onSubmit={handleSubmit(onSubmit)}>
      <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <Row className={cx('form__line')}>
          <Col md={12}>
            <div className={cx('tab-panel__content-box-title')}>
              Hierarchy
              {isDirty && <Button buttonText="Save changes" buttonType="outline" />}
            </div>
          </Col>
        </Row>
        <Row className={cx('form__line')}>
          <Col md={4}>
            <div className={cx('form__line')}>
              <PaginateSelect
                isRequired={false}
                isMulti={false}
                isSearchable={false}
                label={'User Type'}
                control={control}
                id="type_user_id"
                name="type_user_id"
                onChange={(page) => fetchUserTypes(page)}
                errors={errors.type_user_id}
                rules={{
                  required: 'User Type field is required',
                }}
              />
              {/* <Button buttonText="Verify" /> */}
            </div>
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default HierarchyForm;
