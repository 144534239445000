import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';
import styles from './sales-rules-popup.mudule.scss';
import { deleteRule } from '../../../../api/rules';
import { notify } from '../../../../utils/notify';
import PopupTemplate from '../../../../components/ui/PopupTemplate';
import Button from '../../../../components/ui/Button';
import LoaderButton from '../../../../components/ui/LoaderButton';
import { SalesRule } from 'models/Management/SalesRules/Rule';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  rule: SalesRule;
}

const DeleteSalesRulesPopup = (props: Props) => {
  const { triggerBtn, updateComponent, rule } = props;

  const [isLoading, setIsLoading] = useState(false);

  const closeModal = (closeModal) => {
    closeModal();
  };

  const onSubmit = (close) => {
    setIsLoading(true);

    deleteRule(rule.id)
      .then(() => {
        updateComponent();
        closeModal(close);
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
      })
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Delete rule'}
            rightContent={
              <div className={cx('content-form', 'popupForm')}>
                <div className={cx('content-controls')}>
                  <Container>
                    <Row>
                      <Col md={12}>
                        <span>
                          Are you sure, you want to delete <b>{rule.name}</b>?
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className={cx('controls__buttons')}>
                          <Button
                            buttonText="Cancel"
                            buttonType="outline"
                            size="big"
                            type="button"
                            onClick={closeModal.bind(undefined, close)}
                          />
                          <LoaderButton
                            buttonType="primary"
                            size="big"
                            showSpinner={isLoading}
                            disabled={isLoading}
                            buttonText={'Delete'}
                            onClick={(data) => onSubmit(close)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default DeleteSalesRulesPopup;
