import classNames                             from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';

import { useForm }           from 'react-hook-form';
import { useParams }         from 'react-router-dom';
import PartnerSchedulesTable from '../../../../../components/ui/Table/PartnerSchedulesTable';
import styles                from './style.module.scss';
import { ManagementPartnerSchedule } from 'models/Management/Partners/Schedule';
import { CountryListValue } from 'models/Countries';

const cx = classNames.bind(styles);

type Props = {
  list: ManagementPartnerSchedule;
  modifyDataHandler: () => void;
  countryOpt: CountryListValue[];
}

const PartnerSchedules = (props: Props) => {
  const { list, modifyDataHandler, countryOpt } = props;

  const { id } = useParams();
  const componentMounted = useRef(true);
  const [currList, setCurrList] = useState([]);
  useEffect(() => {
    const parseData = (list) => {
      let val = Object.values(list);
      setCurrList(val);
    };

    parseData(list);
  }, [list]);
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });

  useEffect(() => {
    return () => {
      componentMounted.current = false;
    };
  }, []);

  return (
    <div className={cx('tab-panel', 'callbacks')}>
      <div className={cx('tab-panel__content')}>
        <div className={cx('tab-panel__header')}>
          <div className={cx('tab-panel__title')}>Api sending Schedule</div>
        </div>
        <div className={cx('tab-panel-table')}>
          <PartnerSchedulesTable
            modifyDataHandler={modifyDataHandler}
            data={currList}
            showLoader={false}
            userId={id}
            countryList={countryOpt}
          />
        </div>
      </div>
    </div>
  );
};

export default PartnerSchedules;
