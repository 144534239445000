import { useState } from "react";

export const useParticipantsDropdown = () => {
    const [selected, setSelected] = useState(null);
    const [points, setPoints] = useState(null);

    const handleSelect = (e, owner_id) => {
        const buttonWidth = 20;
        setPoints({x: e.clientX - buttonWidth, y: e.clientY})
        setSelected(owner_id);
    };

    const handleClickOutside = () => {
        setPoints(null);
        setSelected(null);
    };

    return {
        selected,
        points,
        handleSelect,
        handleClickOutside
    }
}