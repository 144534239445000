import React, { FC, useState } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './PopupYesOrNo.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';

const cx = classNames.bind(styles);

interface IPopupYesOrNoProps {
  triggerBtn: React.JSX.Element | ((isOpen: boolean) => React.JSX.Element);
  onClick: () => void;
  headerTitle: string;
  bodyText: string;
}

const PopupYesOrNo: FC<IPopupYesOrNoProps> = ({
                                                triggerBtn,
                                                onClick,
                                                headerTitle,
                                                bodyText,
                                              }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const {
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const closeModal = (closeModal): void => {
    reset();
    closeModal();
  };

  const onSubmit = async (close, data): Promise<void> => {
    setIsLoading(true);
    try {
      await onClick()
      closeModal(close)
    } catch (error) {
      console.log(error)
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={headerTitle}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popup__yes-or-no')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12}>
                          {bodyText}
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div className={cx('content-controls')}>
                            <div className={cx('controls__buttons')}>
                              <Button
                                buttonText="No"
                                buttonType="outline"
                                size="big"
                                type="button"
                                onClick={closeModal.bind(undefined, close)}
                              />
                              <LoaderButton
                                buttonType="primary"
                                size="big"
                                showSpinner={isLoading}
                                buttonText={'Yes'}
                                type="submit"
                                onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default PopupYesOrNo;
