import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames/bind';
import styles from './filter.module.scss';
import { Col, Row } from 'react-bootstrap';
import DatePickerRange from 'components/ui/DatePickerRange/DatePickerRange';
import FormInput from 'components/ui/FormInput';
import Button from 'components/ui/Button';
import Search from 'components/ui/Search';
import { ArrowsClockwise, Info } from 'phosphor-react';

import 'react-toggle/style.css';
import './reload-button.scss';
import JokerMultiSelect from 'components/ui/JokerMultiSelect/JokerMultiSelect';
import { useWindowWidth } from '@react-hook/window-size';
import { MOBILE_SIZE_WIDTH } from 'constants/mobile';
import { getTradingAccList } from 'api/tradingAcc';
import { getFilterData } from 'api/tradingHistory';
import timeToInteger from 'utils/timeToInteger';
import { useForm } from 'react-hook-form';
import JokerSelect from 'components/ui/JokerSelect/JokerSelect';
import { ListValue } from 'models/ListValue';
import { clearEmptyFilter } from "../../../../../../utils/clearEmptyFilter.ts";

const cx = classNames.bind(styles);

type Props = {
  onSubmit: (data: any) => void;
  reloadFilters: boolean;
  userId: string | number;
  tickerOpt: ListValue[]
}

const OpenedPositionsFilter = (props: Props) => {
  const { onSubmit, reloadFilters, userId } = props;

  const [accountsOpt, setAccountsOpt] = useState<ListValue[]>([]);
  const {tickerOpt} = props;

  const componentMounted = useRef(true);

  const getDefaultValue = () => {
    return {
      trading_account: [],
      ticker: [],
      open_at: '',
    };
  };

  const { handleSubmit, reset, watch, setValue, getValues, control, formState } = useForm({
    reValidateMode: 'onChange',
    defaultValues: getDefaultValue(),
  });
  const { dirtyFields } = formState;

  const prepareFiltersData = (data) => {
    const postData = {
      filter: {
        trading_account: getValue(data['trading_account']),
        symbol: getValue(data['ticker']),
        open_at: getDateRange(data['open_at']),
      },
    };

    return postData;
  };

  const submit = (data) => {
    const prepeared = prepareFiltersData(data);
    const postData = {
      ...prepeared,
      filter: clearEmptyFilter(prepeared.filter),
    };

    onSubmit(postData);
  };

  const resetForm = () => {
    reset(getDefaultValue());
    onSubmit(null);
  };

  useEffect(() => {
    getTradingAccOpt();

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getTradingAccOpt = () => {
    getTradingAccList(null, userId).then((res) => {
      if (res && res.status === 200) {
        const opt = res.data.data.map((opt) => ({
          value: opt.trading_account,
          label: opt.name,
        }));
        setAccountsOpt(opt);
      }
    });
  };

  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item || typeof item === 'number');
    }

    return data.value !== 'any' ? data.value : null;
  };

  const getDateRange = (data) => {
    if (!data) {
      return;
    }

    if (!data.from && !data.to) {
      return;
    }

    const res = {};

    if (data.from) {
      res['from'] = timeToInteger(data['from']) / 1000;
    }

    if (data.to) {
      res['to'] = timeToInteger(data['to']) / 1000;
    }

    return res;
  };

  const getDirtyFieldsLength = () => Object.keys(dirtyFields).length;

  return (
    <>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit(submit);
        }}
        className={cx('filters')}
      >
        <Row>
          <Col xl={3} md={4} sm={6}>
            <JokerMultiSelect
              label="Accounts"
              control={control}
              id="trading_account"
              name="trading_account"
              options={accountsOpt}
            />
          </Col>
          <Col xxl={2} xl={3} md={3} sm={6}>
            <JokerMultiSelect
              hideSearch={true}
              label="Symbol"
              control={control}
              id="ticker"
              name="ticker"
              options={tickerOpt}
            />
          </Col>
          <Col xl={3} md={4} sm={12}>
            <DatePickerRange
              name="open_at"
              control={control}
              label="Open time range"
              placeholder="From - To"
            />
          </Col>
        </Row>
      </form>

      <Row>
        <Col md={12}>
          <div className={cx('filters__btns')}>
            <Button
              className={reloadFilters ? 'reload-btn reload' : 'reload-btn'}
              icon={<ArrowsClockwise color="var(--white)" size={18} />}
              onClick={(e) => {
                e.preventDefault();
                submit(getValues());
              }}
            />
            <Button
              buttonText="Reset"
              type="reset"
              onClick={() => resetForm()}
              disabled={!getDirtyFieldsLength()}
            />
            <Button
              buttonText="Apply"
              type="submit"
              onClick={handleSubmit(submit)}
              disabled={!getDirtyFieldsLength()}
            />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default OpenedPositionsFilter;
