import { getTickerMessageColors, getTickerMessageSpeed } from "../../../../api/tiker";

export const fetchColors = async () => {
    const colorsData = await getTickerMessageColors();

    return {
        options: Object.keys(colorsData.data).map(key => ({ value: key, label: colorsData.data[key]})),
        hasMore: false,
        additional: {},
    }
}

export const fetchSpeed = async () => {
    const speedData = await getTickerMessageSpeed();
    return { data: Object.keys(speedData.data).map(key => ({ value: speedData.data[key], label: key})) };
}