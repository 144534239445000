import { APIGetLeadInfoResponse, APIGetLeadStatusesResponse, APIGetLeadsResponse, APIPromoteLeadResponse } from 'models/API/Leads/Leads';
import API from '../api';
import { APIMessageResponse } from 'models/API/Common';
import { APIGetLeadErrorLogResponse } from 'models/API/Leads/Log';
import { LeadFeedActionList } from 'models/Leads/LeadFeed';

// unused
export const getLeadsList = (params) => API.get(`api/v1/leads?${params}`);

export const findLeads = (params, data) => API.post<APIGetLeadsResponse>(`api/v1/search/leads?${params}`, data);

export const promoteLeadsToClients = (data) => API.post<APIMessageResponse>(`api/v1/leads/clients/promote`, data);

export const promoteLead = (id, data) => API.put<APIPromoteLeadResponse>(`/api/v1/leads/${id}/promote`, data);

export const changeStatusLead = (id, data) => API.put<APIMessageResponse>(`/api/v1/leads/${id}/status`, data);

export const getLeadStatuses = () => API.get<APIGetLeadStatusesResponse>(`/api/v1/dictionary/lead-statuses`);

export const getLeadInfo = (id) => API.get<APIGetLeadInfoResponse>(`/api/v1/leads/${id}`);

// response unused
export const uploadFile = (data, req?) => API.post(`api/v1/upload-leads`, data);

// response unused
export const updateLeadPersonalInfo = (id, data) =>
  API.put(`/api/v1/leads/${id}/update/personal-info`, data);

// response unused
export const updateLeadAddress = (id, data) => API.put(`/api/v1/leads/${id}/update/address`, data);

export const updateLeadContacts = (id, data) =>
  API.put<APIMessageResponse>(`/api/v1/leads/${id}/update/contacts`, data);
export const getLeadActionTypeFeed = () => API.get<LeadFeedActionList>(`/api/v1/logs/action-types-lead`);

export const getUploadLog = () => API.get<APIGetLeadErrorLogResponse>(`/api/v1/leads/upload-errors`);

// response unused
export const deleteLead = (leadId) => API.delete(`/api/v1/leads/${leadId}`);
