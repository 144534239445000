import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './style-popup.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
import { notify } from 'utils/notify';
import { deleteTemplateMethod } from 'api/templateRoles';
import { deleteDynamicStatus } from '../../../../api/settingsLK';
import { DynamicStatus } from 'models/SettingsLK/DynamicStatuses/DynamicStatusesPage';

const cx = classNames.bind(styles);

type Props = {
  rule: DynamicStatus;
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
}

const DeleteDynamicStatusesPopup = (props: Props) => {
  const { triggerBtn, updateComponent, rule } = props;

  const [isLoading, setIsLoading] = useState(false);

  const closeModal = (closeModal) => {
    closeModal();
  };

  const onSubmit = (close) => {
    setIsLoading(true);

    deleteDynamicStatus(rule.id)
      .then((res) => {
        setIsLoading(false);
        updateComponent();
        closeModal(close);
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Delete status'}
            rightContent={
              <div className={cx('content-form', 'popupForm')}>
                <div className={cx('content-controls')}>
                  <Container>
                    <Row>
                      <Col md={12}>
                        <span>
                          Are you sure, you want to delete status <b>{rule.key}</b>?
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <div className={cx('controls__buttons')}>
                          <Button
                            buttonText="Cancel"
                            buttonType="outline"
                            size="big"
                            type="button"
                            onClick={closeModal.bind(undefined, close)}
                          />
                          <LoaderButton
                            buttonType="primary"
                            size="big"
                            showSpinner={isLoading}
                            disabled={isLoading}
                            buttonText={'Delete'}
                            onClick={(data) => onSubmit(close)}
                          />
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};

export default DeleteDynamicStatusesPopup;
