import classNames from 'classnames/bind';
import { FC, useEffect } from 'react';
import { TickerContainer } from '../../components/ui/Ticker/TickerContainer';

import styles from './dashboarLayout.module.scss';
import UserDashboard from './UserDashboard/UserDashboard';
import { chatInit } from '../../store/slice/chat/chatSlice';
import { useHeartBeat } from '../Chat/helpers/useHeartBeat';
import { useAppDispatch } from '../../store';
import { useWindowWidth } from '@react-hook/window-size';
import { MOBILE_SIZE_WIDTH } from 'constants/mobile';
import { fetchCallbacksToPopulateReminders } from '../../store/slice/reminders';

const cx = classNames.bind(styles);

export const DashboardLayout: FC = ({ children }) => {
  const dispatch = useAppDispatch();

  const width = useWindowWidth();

  useEffect(() => {
    dispatch(chatInit());
    dispatch(fetchCallbacksToPopulateReminders());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useHeartBeat();

  return (
    <div className={cx('dashboard', { mobile: width < MOBILE_SIZE_WIDTH })}>
      <UserDashboard>
        <div className={cx('dashboard__content')}>
          <div className={cx('dashboard__content-wrap')}>{children}</div>
        </div>
      </UserDashboard>
      <TickerContainer />
    </div>
  );
};
