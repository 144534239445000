import React from 'react';
import classNames from 'classnames/bind';

import styles from './datePicker.module.scss';
import { useController } from 'react-hook-form';
import { CalendarBlank } from 'phosphor-react';
import Tooltip from '../Tooltip/Tooltip';
import DatePickerComponent from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const cx = classNames.bind(styles);

const DatePicker = (props) => {
  const { field } = useController(props);
  const { ref, ...fieldProps } = field;
  return (
    <>
      <div className={cx('label-wrap')}>
        {props.label && (
          <div className={props.errors ? cx('label', 'error') : cx('label')}>
            {props.label}
            {props.rules && props.rules.required ? <span className={cx('required')}>*</span> : ''}
          </div>
        )}
        {props.tooltip && props.tooltip.show ? <Tooltip params={props.tooltip} /> : ''}
      </div>
      <div className={props.errors ? cx('datePicker-wrap', 'error') : cx('datePicker-wrap')}>
        <div>
          <DatePickerComponent
            {...fieldProps}
            {...props}
            className={props.errors ? cx('datePicker', 'error') : cx('datePicker')}
            onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
              field.onChange(e);
              props?.onChange && props?.onChange();
            }}
            selected={field.value}
            disabled={props.disabled}
            dateFormat={props.dateFormat}
            showTimeInput={props.showTimeInput}
            placeholderText={props.placeholderText}
          />
          <span className={cx('icon')}>
            <CalendarBlank size={24} />
          </span>
        </div>

        {props.errors && <p className={cx('errorName')}>{props.errors.message}</p>}
      </div>
    </>
  );
};

export default DatePicker;
