import React, { useState, useEffect, useRef } from 'react';
import classNames from 'classnames/bind';
import styles from './../../client-page.module.scss';

import TablePagination from 'components/ui/TablePagination';
import { useParams } from 'react-router-dom';
import TradingActivityTable from 'components/ui/Table/TradingActivityTable';
import OpenedPositionsFilter from './OpenedPositionsFilter';
import { findTradingHistory, findTradingHistoryPositions } from 'api/tradingHistory';
import { findOpenedPositions } from '../../../../../api/openedPositions';
import OpenedPositionsTable from '../../../../../components/ui/Table/OpenedPositionsTable';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

const OpenedPositions = () => {
  const [openPositions, setOpenPositions] = useState([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [filters, setFilters] = useState(null);
  const [tickerOpt, setTickerOpt] = useState<ListValue[]>([]);
  const { id } = useParams();

  const componentMounted = useRef(false);

  useEffect(() => {
    getOpenedPositions();
    componentMounted.current = true;

    return () => {
      componentMounted.current = false;
    };
  }, []);

  const getOpenedPositions = (pageParams?, filtersData?) => {
    setIsListLoading(true);

    findOpenedPositions(null, filtersData, id)
      .then((res) => {
        if (res && res.status === 200) {
        if (componentMounted.current) {
          setOpenPositions(res.data.data);
          
        }}
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setIsListLoading(false);
      });

    findTradingHistoryPositions(null, filtersData, id)
      .then((res)=>{
        const optTickerOpt = res.data.ticker.map((opt) => ({        
          value: opt,
          label: opt,
        }));
        setTickerOpt(optTickerOpt);
      })
  };

  const clearFiltersSortList = () => {
    getOpenedPositions({ navigate: 'first' });
    setFilters(null);
  };

  return (
    <div className={cx('tab-panel', 'payments')}>
      <div className={cx('tab-panel__content')}>
        <div className={cx('tab-panel__header')}>
          <div className={cx('tab-panel__title')}>Opened positions</div>
        </div>
        <div className={cx('tab-panel__filter-box', 'block')}>
          <OpenedPositionsFilter
            onSubmit={(filtersData) => {
              if (!filters && !filtersData) {
                return;
              }
              if (filters && !filtersData) {
                clearFiltersSortList();
              } else {
                setFilters(filtersData);
                // setPerPageCount(filtersData?.filter?.limit);
                getOpenedPositions(
                  { navigate: 'first' },
                  filtersData,
                );
              }
            }}
            userId={id}
            reloadFilters={isListLoading}
            tickerOpt={tickerOpt}
          />
        </div>
        <div className={cx('tab-panel-table')}>
          <OpenedPositionsTable
            data={openPositions}
            perPage={200}
            showLoader={isListLoading}
            // modifyDataHandler={(callback) => console.log('a')}
          />
        </div>
      </div>
    </div>
  );
};

export default OpenedPositions;
