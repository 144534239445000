import React from 'react';
import classNames from 'classnames/bind';
import styles from './style.module.scss';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import { TABLE_TEMPLATE_ROLES } from 'constants/table';
import { NotePencil, Trash } from 'phosphor-react';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';
import EditTemplatesPopup from 'pages/Templates/TemplatesPopups/EditTemplatesPopup';
import DeleteTemplatesPopup from 'pages/Templates/TemplatesPopups/DeleteTemplatesPopup';
import useTableColumnOrder from 'utils/useTableColumnOrder';
import Button from 'components/ui/Button/Button';

const cx = classNames.bind(styles);

const TemplateTable = ({
                         data,
                         perPage,
                         showLoader,
                         modifyDataHandler,
                         // onRowSelect,
                         PermissionDelete,
                         PermissionEdit,
                       }) => {

  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_TEMPLATE_ROLES", TABLE_TEMPLATE_ROLES);

  // const columns = React.useMemo(() => TABLE_TEMPLATE_ROLES, []);
  const tableData = React.useMemo(
    () => makeTableData(data, data.length),
    [data],
  );
  const prepearedTableData = tableData.map((item, pos) => {
    const { id, name } = item;
    return {
      name: (
        <div className={cx('rule__name')}>
          <span>{name}</span>
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          <div>
            {PermissionDelete ?
              <DeleteTemplatesPopup
                rule={item}
                triggerBtn={<Trash size={20} />}
                updateComponent={modifyDataHandler}
              /> : null}
          </div>
          <div>
            {PermissionEdit ?
              <EditTemplatesPopup
                // departmentsList={departmentsList}
                // permissionsList={permissionsList}
                dataItem={item}
                actionType={SALES_RULES_ACTION_TYPE.EDIT}
                triggerBtn={<NotePencil size={20} />}
                updateComponent={modifyDataHandler}
              /> : null}
          </div>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      // onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
    />
    </>
  );
};

export default TemplateTable;
