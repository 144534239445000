import classNames from 'classnames/bind';
import React, { useEffect, useRef, useState } from 'react';
import { usePermission } from '../../../utils/usePermission.tsx';
import Page from '../../Page/Page';
import { DashboardLayout } from '../../UserView/DashboardLayout';
import styles from './sales-rules-page.module.scss';
import SalesFilter from './SalesFilter/SalesFilter';
import CreateSalesRulesPopup from './SalesPopups/CreateSalesRulesPopup';
import { notify } from '../../../utils/notify.tsx';
import { getLangList } from '../../../api/lang/index';
import { getCountryList } from '../../../api/country/index';
import { findRules } from '../../../api/rules/index';
import { SALES_RULES_ACTION_TYPE } from '../../../constants/sales-rules.const.js';
import Button from '../../../components/ui/Button/index.js';
import SalesRulesTable from '../../../components/ui/Table/SalesRulesTable/index.js';
import TablePagination from '../../../components/ui/TablePagination/index.js';
import { TableLinks, TableMeta } from 'models/Table';
import { ListValue } from 'models/ListValue';
import { SalesRule } from 'models/Management/SalesRules/Rule';
import { SalesRulesFilter } from 'models/Management/SalesRules/Filter';
import { getPartnersList } from 'api/partners/index.ts';
const cx = classNames.bind(styles);

const SalesRules = () => {

  const { permissionGiven: PermissionCreateRule } = usePermission('admin.rule.create');

  const [rules, setRules] = useState<SalesRule[]>([]);
  const [isListLoading, setIsListLoading] = useState(true);
  const [countryOpt, setCountryOpt] = useState<ListValue[]>([]);
  const [operator, setOperator] = useState([]);
  const [partners, setPartnersList] = useState([]);
  const [langListArr, setLangListArr] = useState<ListValue[]>([]);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [filters, setFilters] = useState<SalesRulesFilter>(null);

  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);

  const componentMounted = useRef(false);
  document.title = 'Sales Rules';

  useEffect(() => {
    componentMounted.current = true;

    getRules();
    fetchCountryList();
    fetchPartners();
    getLangList()
      .then((res) => {
      const mapped = res.data.data.map((item) => ({
        value: item.id,
        label: item.name,
      }));

      setLangListArr(mapped);
    });

    return () => {
      componentMounted.current = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchPartners = () => {
    getPartnersList('per_page=500').then(res => {
      setPartnersList(res.data.data)
    })
  }
 
  const fetchCountryList = () => {
    getCountryList().then((res) => {
      if (componentMounted.current) {
        const opt = res.data.data.map((opt) => ({
          value: opt.id,
          label: opt.name,
          iso_3166_2: opt.iso_3166_2,
        }));
        setCountryOpt(opt);
      }
    })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      });
  };

  const getRules = (pageParams?, filtersData?) => {
    setIsListLoading(true);

    findRules(getTablePageParams(pageParams), filtersData)
      .then((res) => {
        if (componentMounted.current) {
          setRules(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setPerPageCount(res.data.meta.per_page);
          setQueryResponseMessage(res?.data?.message || '');
        }
      })
      .catch((err) => {
        notify({
          type: 'error',
          message: err.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsListLoading(false);
      })
  };

  const getTablePageParams = (pageParams) => {
    if (!pageParams) {
      return '';
    }

    const { page, navigate, perPage } = pageParams;

    let perPageParams = `per_page=${perPage || perPageCount}`;
    let toPage;

    if (page) {
      toPage = `page=${page}`;
    } else if (navigate && tableLinks) {
      const splittedLink = tableLinks[navigate].split('?').reverse()[0];
      const splitedParams = splittedLink.split('&');
      toPage = splitedParams.filter((item) => item.includes('page='))?.[0];
    }

    return `${toPage}&${perPageParams}`;
  };

  const getSortFiltersForPageNavigation = () => {
    if (filters) {
      return filters;
    }
  };

  const clearFiltersSortList = () => {
    setPerPageCount(10);
    getRules({ navigate: 'first', perPage: 10 });
    setFilters(null);
  };

  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>
                  <strong>{tableMeta?.total || 0}</strong> Sales rules
                </span>
                <span></span>
              </div>
              <div className={cx('header-controls')}>
                {PermissionCreateRule ?
                  <CreateSalesRulesPopup
                    updateComponent={() => getRules()}
                    countryList={countryOpt}
                    langList={langListArr}
                    operatorsList={operator}
                    partnersList={partners}
                    actionType={SALES_RULES_ACTION_TYPE.CREATE}
                    triggerBtn={
                      <Button buttonText='+ Add Rule' onClick={() => null} buttonType={'outline'} />
                    }
                  />
                  : null
                }
              </div>
            </div>
          </div>
          <div className={cx('container__filters-block')}>
            <SalesFilter
              countryList={countryOpt}
              langList={langListArr}
              onSubmit={(filtersData) => {
                if (!filters && !filtersData) {
                  return;
                }
                if (filters && !filtersData) {
                  clearFiltersSortList();
                } else {
                  setFilters(filtersData);
                  getRules({ navigate: 'first', perPage: filtersData?.filter?.limit || 10 }, filtersData);
                }
              }}
              reloadFilters={isListLoading}
              queryMessage={queryResponseMessage}
                          />
          </div>
          <div className={cx('container__block')}>
            <SalesRulesTable
              data={rules}
              countryList={countryOpt}
              langList={langListArr}
              operatorsList={operator}
              partnersList={partners}
              perPage={perPageCount}
              showLoader={isListLoading}
              modifyDataHandler={() => getRules()}
            />
            {rules?.length ? (
              <TablePagination
                goToFirstPage={() => {
                  getRules({ navigate: 'first' }, getSortFiltersForPageNavigation());
                }}
                goToPrevPage={() => {
                  getRules({ navigate: 'prev' }, getSortFiltersForPageNavigation());
                }}
                goToNextPage={() => {
                  getRules({ navigate: 'next' }, getSortFiltersForPageNavigation());
                }}
                goToLastPage={() => {
                  getRules({ navigate: 'last' }, getSortFiltersForPageNavigation());
                }}
                currentPage={tableMeta?.current_page}
                pagesLength={tableMeta?.last_page}
                perPageChange={(value) => {
                  setPerPageCount(value);
                  getRules({ perPage: value }, getSortFiltersForPageNavigation());
                }}
                goToSelectedPage={(page) => {
                  getRules({ page }, getSortFiltersForPageNavigation());
                }}
                // hidePageSelect={true}
                isFirstPageDisable={() => tableMeta?.current_page === 1}
                isPrevPageDisable={() => !tableLinks?.prev}
                isNextPageDisable={() => !tableLinks?.next}
                isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
              />
            ) : (
              ''
            )}
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default SalesRules;
