import React, { useState } from 'react';
import {
  // getDeskForOfficeMethod,
  // getOfficesMethod,
  // getOperatorsForDeskMethod,
  getOperatorsForTeamMethod,
  getOperatorsMethod,
} from '../../api/hierarchy';
// import HierarchyComponentTeams from './HierarchyComponentTeams';
import styles from './hierarchy-page.module.scss';
import classNames from 'classnames/bind';
import PlusIcon from '../../assets/Svg/PlusIcon';
import MinusIcon from '../../assets/Svg/MinusIcon';
// import DeskIcon from '../../assets/Svg/DeskIcon';
import TeamIcon from '../../assets/Svg/TeamIcon';
import OperatorIcon from '../../assets/Svg/OperatorIcon';
import { checkDevise } from '../../utils/checkDevise';
import UserPopup from './UsersPopup';
import Button from '../../components/ui/Button';
import { HierarchyTeam } from 'models/Hierarchy/HierarchyData';

type Props = {
  data: HierarchyTeam;
}

const HierarchyComponentOperators = (props: Props) => {
  const { data } = props;

  let [item, setItem] = useState(data);
  let [open, setOpen] = useState(false);
  let [hover, setHover] = useState(false);
  const device = checkDevise();
  const cx = classNames.bind(styles);
  return (
    <div className={styles.wrapperItem}>
      <div
        className={cx('wrapperContent', 'twoLevel')}
        onMouseEnter={() => {
          device === 'desktop' && setHover(true);
        }}
        onMouseLeave={() => {
          device === 'desktop' && setHover(false);
        }}
      >
        {(device === 'mobile' && item?.operators_count > 0) ||
        (item?.branches_count > 0 && hover) ? (
          <>
            {open ? (
              <button
                className={styles.btnIcon}
                onClick={() => {
                  setOpen(false);
                }}
              >
                <MinusIcon />
              </button>
            ) : (
              <button
                className={styles.btnIcon}
                onClick={() => {
                  setOpen(true);
                  getOperatorsMethod(item.id).then((res) => {
                    if (res.status === 200) {
                      setItem({
                        ...item,
                        operators: [...res.data.data.operators],
                      });
                    }
                  });
                }}
              >
                <PlusIcon />
              </button>
            )}
          </>
        ) : (
          <>
            {(item?.operators_count > 0 && hover) || (item?.branches_count > 0 && hover) ? (
              <>
                {open ? (
                  <button
                    className={styles.btnIcon}
                    onClick={() => {
                      setOpen(false);
                    }}
                  >
                    <MinusIcon />
                  </button>
                ) : (
                  <button
                    className={styles.btnIcon}
                    onClick={() => {
                      setOpen(true);
                      getOperatorsMethod(item.id).then((res) => {
                        if (res.status === 200) {
                          setItem({
                            ...item,
                            operators: [...res.data.data.operators],
                          });
                        }
                      });
                    }}
                  >
                    <PlusIcon />
                  </button>
                )}
              </>
            ) : (
              <div className={styles.btnIcon}>
                <TeamIcon />
              </div>
            )}
          </>
        )}

        <div className={styles.wrapperInfo}>
          <div>
            <div className={styles.topBlock}>
              <p className={styles.bolderText}>{item?.name}</p>
              <p className={styles.greyText}>Team</p>
            </div>
            <div className={styles.bottomBlock}>
              <p className={styles.greyText}>
                {item?.branches_count} branches / {item?.operators_count}{' '}
                {item?.operators_count === 1 ? 'user' : 'users'}
              </p>
            </div>
          </div>
          {item?.operators_count > 0 && (
            <UserPopup
              fetchMethod={getOperatorsForTeamMethod}
              data={item}
              namePopup={`team ${item.name}`}
              triggerBtn={
                <Button icon={<OperatorIcon />} onClick={() => null} buttonType={'outline'} />
              }
            />
          )}
        </div>
      </div>
      {open && item?.operators?.length > 0 && (
        <div className={styles.wrapperList}>
          {item.operators?.map((operator) => {
            return (
              <div key={operator.id} className={styles.itemList}>
                <div className={styles.lastLevelBefore}></div>
                <div className={cx('wrapperContent', 'greenBorder')}>
                  <div className={styles.btnIcon}>
                    <OperatorIcon />
                  </div>
                  <div className={styles.wrapperInfo}>
                    <div>
                      <div className={styles.topBlock}>
                        <p className={styles.bolderText}>{operator?.first_name}</p>
                        <p className={styles.greyText}>Operator</p>
                      </div>
                      <div className={styles.bottomBlock}>
                        {operator.role.map((item, ind) => {
                          return (
                            <span key={ind} className={styles.greyText}>
                              {item}
                              {operator.role.length - 1 !== ind && <span>,</span>}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};
export default HierarchyComponentOperators;
