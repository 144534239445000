import classNames                                  from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE }                 from 'constants/sales-rules.const';
import { TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES } from 'constants/table';
import { NotePencil, Trash }                       from 'phosphor-react';
import React                                       from 'react';
import { copyToBuffer }                            from 'utils/copyToBuffer';
import { makeTableData }                           from '../../../../helpers/table.helper';
import CreateOfficesPopup
                                                   from '../../../../pages/Management/Offices/OfficesPopups/CreateOfficesPopup';
import DeleteOfficesPopup
                                                   from '../../../../pages/Management/Offices/OfficesPopups/DeleteOfficesPopup';
import { getShortUuid }                            from '../../../../utils/getShortUuid';
import { usePermission }                           from '../../../../utils/usePermission.tsx';
import Table                                       from '../Table';
import styles                                      from './offices-table.module.scss';
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';

const cx = classNames.bind(styles);

const OfficesTable = ({
  data,
  countryList,
  perPage,
  showLoader,
  modifyDataHandler,
  onRowSelect,
}) => {

  const { permissionGiven: PermissionDelete } = usePermission('admin.office.delete');
  const { permissionGiven: PermissionUpdate } = usePermission('admin.office.update');

  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES", TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES, true);

  // const columns = React.useMemo(() => TABLE_MANAGEMENTS_OFFICES_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data, countryList]);
  const prepearedTableData = tableData.map((item, pos) => {
    const { id, name, uuid, operator_uuid, country } = item;
    return {
      office: (
        <div className={cx('rule__name')}>
          {/* <Link to={`/lead/${id}`} className={cx('logo')}> */}
          <span>{name}</span>
          {/* </Link> */}
          <span
            className={cx('rule__id')}
            onClick={(): void => {
              copyToBuffer(uuid);
            }}
          >
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      country: (
        <div className={cx('rule__country')}>
          {/*<b>{country?.length} Countries</b>*/}
          <div className={cx('client__country-flag')}>
            <i className={cx('icon')} style={{ backgroundImage: `url(${country?.flag})` }} />
          </div>
          <span>{country?.iso_3166_2}</span>
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          <div>
            {
              PermissionDelete ?
                <DeleteOfficesPopup
                  rule={item}
                  triggerBtn={<Trash size={20} />}
                  // onCloseModal={() => console.log('a')}
                  updateComponent={modifyDataHandler}
                /> : null
            }
          </div>
          <div>
            {
              PermissionUpdate ?
                <CreateOfficesPopup
                  countryList={countryList}
                  ruleData={item}
                  actionType={SALES_RULES_ACTION_TYPE.EDIT}
                  triggerBtn={<NotePencil size={20} />}
                  // onCloseModal={() => console.log('a')}
                  updateComponent={modifyDataHandler}
                /> : null
            }
          </div>
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
      saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      className={cx('rules-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#f9f1ff' : '#ffffff'}`,
          },
        };
      }}
    />
    </>
  );
};

export default OfficesTable;
