import { Users } from "phosphor-react";
import { useAppDispatch } from "../../../../store";
import { openJokerModal } from "../../../../store/slice/modalSlice";
import { PARTICIPANTS_MODAL_KEY } from "../modals/GroupParticipantsModal/GroupParticipantsModal";
import { getParticipants } from "../../../../store/slice/chat/thunks";
import { MenuOption } from "./MenuOption";

type GroupMembersOptionProps = { threadId: string, title?: string };

export const GroupMembersOption = ({ threadId, title = 'Group members' }: GroupMembersOptionProps) => {
    const dispatch = useAppDispatch();

    const openParticipantsModal = () => {
        dispatch(openJokerModal({ key: PARTICIPANTS_MODAL_KEY }));
        dispatch(getParticipants({ threadId }));
    };

    return (
        <MenuOption title={title} onClick={openParticipantsModal}>
            <Users size={32} color="#722ED1"/>
        </MenuOption>
    )
}