import React, { FC } from 'react';
import classNames from 'classnames/bind';
import styles from './сallbacksTable.module.scss';
import { makeTableData } from 'helpers/table.helper';
import Table from '../Table';
import ReactTooltip from 'react-tooltip';

import { TABLE_CALLBACKS_COLUMNS_NAMES } from 'constants/table';
import moment from 'moment';
import { CheckCircle, Pencil, Power, Trash } from 'phosphor-react';
import EditCallbackPopup from 'pages/Clients/Client/ClientTabs/Callbacks/EditCallbackPopup';
import { copyToBuffer } from '../../../../utils/copyToBuffer';
import { getShortUuid } from '../../../../utils/getShortUuid';
import { usePermission } from '../../../../utils/usePermission';
import { ICallbacksTable } from "../../../../models/components/Table/CallbacksTable/CallbacksTable.ts";
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';

const cx = classNames.bind(styles);

const CallbacksTable: FC<ICallbacksTable> = ({
  data,
  perPage,
  showLoader,
  onRowSelect,
  colSortCheck,
  userId,
  deleteAction,
  changeStatusAction,
  updateComponent,
}) => {
  const { permissionGiven: PermissionDeleteCallback } = usePermission('admin.callback.delete');
  const { permissionGiven: PermissionEditCallback } = usePermission('admin.callback.update');

  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder("TABLE_CALLBACKS_COLUMNS_NAMES", TABLE_CALLBACKS_COLUMNS_NAMES);

  // const columns = React.useMemo(() => TABLE_CALLBACKS_COLUMNS_NAMES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { id, operator, time, created_at, updated_at, status, reminders, uuid } = item;

    return {
      id: (
        <div className={cx('callback-table_th')}>
          <span
            className={cx('rule__id')}
            onClick={(): void => {
              copyToBuffer(uuid);
            }}
          >
            {getShortUuid(uuid)}
          </span>
        </div>
      ),
      operator: (
        <div className={cx('callback-table_th')}>
          <span className={cx('bolder')}>{operator.full_name}</span>
          <p
            className={cx('rule__id')}
            onClick={(): void => {
              copyToBuffer(operator.uuid);
            }}
          >
            {getShortUuid(operator.uuid)}
          </p>
        </div>
      ),
      time: (
        <div className={cx('client__registration')}>
          <span>{moment(time * 1000).format('DD.MM.YYYY')}</span>
          <span>{moment(time * 1000).format('HH:mm')}</span>
        </div>
      ),
      created_at: (
        <div className={cx('callback-table_th')}>
          <div className={cx('client__registration')}>
            <span>{moment(created_at * 1000).format('DD.MM.YYYY')}</span>
            <span>{moment(created_at * 1000).format('HH:mm')}</span>
          </div>
        </div>
      ),
      updated_at: (
        <div className={cx('callback-table_th')}>
          <div className={cx('client__registration')}>
            <span>{moment(updated_at * 1000).format('DD.MM.YYYY')}</span>
            <span>{moment(updated_at * 1000).format('HH:mm')}</span>
          </div>
        </div>
      ),
      status: (
        <div className={cx('callback-table_th')}>
          <span className={cx(status)}>{status}</span>
        </div>
      ),
      reminders: (
          <div className={cx('callback-table_th')}>
              <div className={cx('client__registration')}>
                  {reminders.length ? (
                      <>
                          <span>{moment(reminders[0] * 1000).format('DD.MM.YYYY')}</span>
                          {reminders.map(item => <span>{moment(item * 1000).format('HH:mm')}</span>)}
                      </>
                  ) : null}
              </div>
          </div>
      ),
      action: (
        <div className={cx('action')}>
          {PermissionEditCallback ?
            <EditCallbackPopup
              updateComponent={(): void => {
                updateComponent();
              }}
              editData={item}
              userId={userId}
              triggerBtn={<Pencil size={20} />}
              onCloseModal={() => console.log('a')}
            />
            : null
          }
          {PermissionDeleteCallback ?
            <Trash size={20} onClick={() => deleteAction(id)} />
            : null
          }
          {status !== 'pending' ? null : (
            <>
              <Power
                size={20}
                onClick={() => changeStatusAction(id, 'disabled')}
                data-tip="Disabled callback"
                data-for="disabled"
              />
              <CheckCircle
                size={20}
                onClick={() => changeStatusAction(id, 'success')}
                data-tip="Success callback"
                data-for="success"
              />
            </>
          )}
          <ReactTooltip id="disabled" />
          <ReactTooltip id="success" />
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
        <Button 
    buttonStyle={{
      marginBottom: "12px",
      marginLeft: 0,
    }}
    buttonText='Reset column order'
    onClick={setDefaultColumnsOrder}
     />
      <Table
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck}
      className={cx('callback-table')}
      isSelected={false}
      saveColumnOrder={saveColumnOrder}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#F9F1FF' : '#FFFFFF'}`,
          },
        };
      }}
    />
    </>
   
  );
};

export default CallbacksTable;
