import { createPortal } from "react-dom";
import { useAppSelector } from "../../../store";
import { selectModalOpened } from "../../../store/slice/modalSlice";
import { ReactNode } from "react";
import { ModalContainer } from "./components/ModalContainer";

type JokerModalProps = {
    children: ReactNode;
    modal: string;
    disableBodyScroll?: boolean;
    onOverlayClick: () => void;
};

export const JokerModal = ({ children, modal, onOverlayClick, disableBodyScroll = true }: JokerModalProps) => {
    const modalKey = useAppSelector(selectModalOpened);

    if (modalKey !== modal) {
        return null
    }

    return createPortal(
        <ModalContainer disableBodyScroll={disableBodyScroll} onOverlayClick={onOverlayClick}>
            {children}
        </ModalContainer>,
        document.body);
};