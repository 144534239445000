const PlusIcon = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 6.25C12.4061 6.25 6.25 12.4061 6.25 20C6.25 27.5939 12.4061 33.75 20 33.75C27.5939 33.75 33.75 27.5939 33.75 20C33.75 12.4061 27.5939 6.25 20 6.25ZM3.75 20C3.75 11.0254 11.0254 3.75 20 3.75C28.9746 3.75 36.25 11.0254 36.25 20C36.25 28.9746 28.9746 36.25 20 36.25C11.0254 36.25 3.75 28.9746 3.75 20Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 20C12.5 19.3096 13.0596 18.75 13.75 18.75H26.25C26.9404 18.75 27.5 19.3096 27.5 20C27.5 20.6904 26.9404 21.25 26.25 21.25H13.75C13.0596 21.25 12.5 20.6904 12.5 20Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 12.5C20.6904 12.5 21.25 13.0596 21.25 13.75V26.25C21.25 26.9404 20.6904 27.5 20 27.5C19.3096 27.5 18.75 26.9404 18.75 26.25V13.75C18.75 13.0596 19.3096 12.5 20 12.5Z"
        fill="#722ED1"
      />
    </svg>
  );
};

export default PlusIcon;
