import { createSelector, createSlice } from '@reduxjs/toolkit';
import { RootState } from "../";

const initialState = {
  unreadCount: 0,
  current_page: 1,
  notifications: []
};

const notifySlice = createSlice({
  name: 'notify',
  initialState,
  reducers: {
    setNotificationCount(state, action) {
      state.unreadCount = action.payload;
    },
    increaseNotificationCount(state, action) {
      state.unreadCount += action.payload;
    },
    decreaseNotificationCount(state, action) {
      state.unreadCount -= action.payload;
    },
    setNotifications(state, action) {
      state.notifications = action.payload;
    },
    addNotification(state, action) {
      if (state.current_page === 1) {
        state.notifications.unshift(action.payload);
      }
    },
    readNotifications(state, action: { type: string; payload: number[] }) {
      state.notifications.forEach(item => {
        if (action.payload.includes(item.id)) {
          item.is_read = true;
        }
      });
    },
    setCurrentPage(state, action) {
      state.current_page = action.payload;
    },
  },
});

export const notifyReducer = notifySlice.reducer;
export const {
  setNotificationCount,
  increaseNotificationCount,
  decreaseNotificationCount,
  setNotifications,
  addNotification,
  readNotifications,
  setCurrentPage
} = notifySlice.actions;

const selectSelf = (state: RootState) => state.notify;
export const getNotify = createSelector(selectSelf, (state) => state.unreadCount)
export const getNotifications = createSelector(selectSelf, (state) => state.notifications);
export const getNotificationsPage = createSelector(selectSelf, (state) => state.current_page);
