import { getFileExtension } from "../../helpers/getFileExtension";
import { getMessageFileConfig } from "../../helpers/getMessageFileConfig";
import { prettifyFileName } from "../../helpers/prettifyFileName";

export const ThreadMessageDocument = ({ filename }) => {
	const documentType = getFileExtension(filename);
	return (
		<div className='message-document-preview message-text'>
			{getMessageFileConfig(documentType).icon} {prettifyFileName(filename) + '.' + documentType}
		</div>
	)
}