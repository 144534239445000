const ArrowBigIcon = (props) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 9C3 8.44772 3.44772 8 4 8H6.88873C8.31941 8.00175 9.72906 8.34456 11.0008 8.99998C12.2724 9.65533 13.3694 10.6044 14.2009 11.7683C14.201 11.7685 14.2008 11.7682 14.2009 11.7683L19.4257 19.068C20.0725 19.9734 20.9263 20.7125 21.9155 21.2222C22.9044 21.7319 24.0005 21.9985 25.113 22C25.1132 22 25.1127 22 25.113 22H29C29.5523 22 30 22.4477 30 23C30 23.5523 29.5523 24 29 24L25.1113 24C23.6806 23.9982 22.2709 23.6554 20.9992 23C19.7277 22.3447 18.6307 21.3957 17.7992 20.2318C17.799 20.2316 17.7993 20.232 17.7992 20.2318L12.5743 12.932C11.9275 12.0266 11.0737 11.2875 10.0845 10.7778C9.0956 10.2681 7.99943 10.0015 6.88688 10H4C3.44772 10 3 9.55228 3 9Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2929 5.29289C25.6834 4.90237 26.3166 4.90237 26.7071 5.29289L29.7071 8.29289C30.0976 8.68342 30.0976 9.31658 29.7071 9.70711L26.7071 12.7071C26.3166 13.0976 25.6834 13.0976 25.2929 12.7071C24.9024 12.3166 24.9024 11.6834 25.2929 11.2929L27.5858 9L25.2929 6.70711C24.9024 6.31658 24.9024 5.68342 25.2929 5.29289Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.2929 19.2929C25.6834 18.9024 26.3166 18.9024 26.7071 19.2929L29.7071 22.2929C30.0976 22.6834 30.0976 23.3166 29.7071 23.7071L26.7071 26.7071C26.3166 27.0976 25.6834 27.0976 25.2929 26.7071C24.9024 26.3166 24.9024 25.6834 25.2929 25.2929L27.5858 23L25.2929 20.7071C24.9024 20.3166 24.9024 19.6834 25.2929 19.2929Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M25.1113 8L25.1125 8L29 8C29.5523 8 30 8.44772 30 9C30 9.55229 29.5523 10 29 10H25.1131C24.0006 10.0015 22.9044 10.2681 21.9154 10.7778C20.9271 11.2871 20.0744 12.0246 19.4278 12.9291L19.2795 13.1392C18.961 13.5904 18.337 13.698 17.8858 13.3795C17.4346 13.061 17.327 12.437 17.6455 11.9858L17.7987 11.7687C18.6303 10.6046 19.7275 9.65541 20.9992 8.99998C22.2709 8.34456 23.6806 8.00175 25.1113 8Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1142 18.6206C14.5654 18.939 14.673 19.563 14.3545 20.0142L14.2045 20.2267L14.2012 20.2313C13.3696 21.3955 12.2725 22.3446 11.0008 23C9.72906 23.6555 8.31941 23.9983 6.88873 24L4 24C3.44772 24 3 23.5523 3 23C3 22.4477 3.44772 22 4 22H6.88684C7.9994 21.9986 9.09559 21.7319 10.0845 21.2223C11.0729 20.7129 11.9256 19.9754 12.5722 19.071L12.7205 18.8608C13.039 18.4096 13.663 18.3021 14.1142 18.6206Z"
      />
    </svg>
  );
};

export default ArrowBigIcon;
