import React, { useState } from 'react';
import classNames from 'classnames/bind';
import { Pencil, Trash } from 'phosphor-react';

import styles from './../../client-page.module.scss';
import moment from 'moment';
import EditNotePopup from './EditNotePopup';
import { deleteNote } from 'api/notes';
import { notify } from 'utils/notify';
import { copyToBuffer } from 'utils/copyToBuffer';
import { getShortUuid } from 'utils/getShortUuid';
import { usePermission } from '../../../../../utils/usePermission';

const cx = classNames.bind(styles);

const NotesItem = ({ noteItem, updateNotesList }) => {
  const { permissionGiven: PermissionNotesDelete } = usePermission('admin.notes.delete');
  const { permissionGiven: PermissionNotesUpdate } = usePermission('admin.notes.update');

  const [isLoading, setIsloading] = useState(false);

  const removeNote = (id) => {
    setIsloading(true);
    deleteNote(noteItem?.id)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: 'Notes deleted successfully',
            timeOut: 3000,
          });
          updateNotesList();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => setIsloading(false));
  };

  return (
    <div className={cx('notes-list__item')}>
      <div className={cx('notes-list__header')}>
        <div className={cx('notes-list__item-info')}>
          <div className={cx('notes-list__item-author')}>
            {`${noteItem?.operator?.first_name || ''} ${
              noteItem?.operator?.last_name || ''}`}{' '}
            {noteItem?.is_pinned ? <span className={cx('pinned')}>Pinned</span> : null}
          </div>
          <div className={cx('notes-list__item-ident')}>
            {noteItem?.operator?.uuid ? getShortUuid(noteItem?.operator?.uuid) : '---'}
          </div>
          <div className={cx('notes-list__item-date')}>
            {moment(noteItem?.create_at * 1000).format('DD.MM.YYYY HH:mm:ss')} to{' '}
            <span
              className="copuToBuffer"
              onClick={() => {
                copyToBuffer(noteItem?.to);
              }}
            >
              {getShortUuid(noteItem?.to)}
            </span>
          </div>
        </div>
        {noteItem?.operator?.edit === true ? (
          <div className={cx('notes-list__item-actinBtn')}>
            {PermissionNotesUpdate ?
              <EditNotePopup
                updateComponent={() => updateNotesList()}
                noteData={noteItem}
                triggerBtn={<Pencil size={20} onClick={null} />}
                type="edit"
              />
              : null
            }
            {PermissionNotesDelete ?
              <Trash size={20} onClick={() => removeNote(noteItem?.id)} />
              : null
            }
          </div>
        ) : null}
      </div>
      <div className={cx('notes-list__item-note')}>
        <div className={cx('subject')}>{noteItem?.subject}</div>
        <div className={cx('body')}>{noteItem?.body}</div>
      </div>
    </div>
  );
};
export default NotesItem;
