import React, { useState } from "react";
import Button from "../../../Button";
import LoaderButton from "../../../LoaderButton";
import { ModalSizes, ModalTemplate } from "../JokerModalTemplate/JokerModalTemplate";
import { closeJokerModal } from "../../../../../store/slice/modalSlice";
import { useAppDispatch } from "../../../../../store";
import './ConfirmationModal.scss';

type ConfirmationModalProps = {
    id: string;
    title: string;
    onConfirm: () => Promise<void>;
    className?: string;
    size?: ModalSizes;
    confirmTitle?: string;
    cancelTitle?: string;
};

export const ConfirmationModal = ({ id, title, onConfirm, className, size, confirmTitle = 'Yes', cancelTitle = 'No'}: ConfirmationModalProps) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const handleCloseModal = () => dispatch(closeJokerModal());

    const handleConfirm = async () => {
        setIsLoading(true);

        try {
            await onConfirm();
            dispatch(closeJokerModal());
        } catch (error) {
            //TODO: add error handler
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <ModalTemplate id={id} title={title} onClose={handleCloseModal} className={className} size={size}>
            <div className='confirmation-modal'>
                <Button
                    buttonText={cancelTitle}
                    buttonType="outline"
                    size="big"
                    type="button"
                    onClick={handleCloseModal}
                />
                <LoaderButton
                    buttonType="primary"
                    size="big"
                    showSpinner={isLoading}
                    disabled={isLoading}
                    buttonText={confirmTitle}
                    onClick={handleConfirm}
                />
            </div>
        </ModalTemplate>
    )
};
