import { MagnifyingGlass } from "phosphor-react";
import React, { FC } from 'react';
import { useController } from "react-hook-form";
import { ISearchProps } from "../../../models/components/Search/Search";
import { generateValidationErrorMessage } from "../../../utils/error-message-from-api";
import { DefaultInputField } from "../DefaultInputField/DefaultInputField";

import './CrmSearch.scss';

export const CrmSearch: FC<ISearchProps> = (props: ISearchProps) => {
  const { placeholder, control, name, id, disabled, ...inputAttrs } = props;
  const { field, formState } = useController({ name, control, disabled: !!disabled });

  const { onChange: onChangeField, ...fieldProps } = field;
  const fieldError = generateValidationErrorMessage(name, formState.errors);

  return (
    <div className='crm-search-input'>
      <DefaultInputField
        id={id}
        error={fieldError}
      >
        <input
          {...fieldProps}
          {...inputAttrs}
          className="input"
          type='text'
          placeholder={placeholder}
          value={field.value === null ? '' : field.value}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>): void => {
            if (e.key === 'Enter') {
              props?.onSearch();
            }
          }}
          onChange={(e: React.ChangeEvent<HTMLInputElement>): void => {
            field.onChange(e);
            if (props.handleChange) {
              props.handleChange(e.target.value);
            }
          }}
        />
        <MagnifyingGlass className='search-icon' size={20} weight="bold" onClick={() => {
          props?.onSearch();
        }}/>
      </DefaultInputField>
    </div>
  );
};

