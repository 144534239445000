import classNames from 'classnames/bind';
import { ArrowsClockwise, Eye, LockLaminated, Password, Trash } from 'phosphor-react';
import React, { FC } from 'react';
import ReactTooltip from 'react-tooltip';
import { TABLE_TRADING_ACCOUNT_NAMES } from '../../../../constants/table';
import { makeTableData } from '../../../../helpers/table.helper';
import Table from '../Table';
import styles from './tradingAccountsTable.module.scss';
import { usePermission } from '../../../../utils/usePermission';
import ShowPassword from '../../../../pages/Clients/Client/ClientTabs/TradingAccounts/TradingAccFilter/ShowPassword';
import PopupYesOrNo from "../../PopupYesOrNo/PopupYesOrNo";
import { TABLE_TRADING_ACCOUNT_NAMES_WITH_WT } from "../../../../constants/table.js";
import { useAppSelector } from "../../../../store";
import { getEnableWebTrader } from "../../../../constants/selectors.ts";
import useTableColumnOrder from 'utils/useTableColumnOrder.ts';
import Button from 'components/ui/Button/Button.tsx';
import ChangeTAPassword from 'pages/Clients/Client/ClientTabs/TradingAccounts/TradingAccFilter/ChangeTAPassword.tsx';
import ResetPassword from 'components/ui/Icons/ResetPassword.tsx';

const cx = classNames.bind(styles);

interface ITradingAccountsTable {
  data: any;
  perPage: number;
  showLoader: boolean;
  modifyDataHandler?: (data: any) => void;
  onRowSelect?: (data: any) => void;
  colSortCheck?: (colId: any) => any;
  userId: string;
  refreshApiToken?: () => void;
  actionTaMethod?: (data: any) => void;
  blockTaTradingMethod?: (id: string, value: boolean) => void;
  changeTaPasswordMethod?: (taId: string, data: any) => void;
  getBalance?: (trading_account: string, id: string) => void;
  updateComponent?: () => void;
  deleteTradingAccount?: (id: string) => void;
}

const TradingAccountsTable: FC<ITradingAccountsTable> = ({
                                data,
                                perPage,
                                showLoader,
                                modifyDataHandler,
                                onRowSelect,
                                colSortCheck,
                                userId,
                                refreshApiToken,
                                actionTaMethod,
                                blockTaTradingMethod,
                                changeTaPasswordMethod,
                                getBalance,
                                updateComponent,
                                deleteTradingAccount,
                              }) => {
  const { permissionGiven: PermissionChangePasswordTradingAccount } = usePermission('admin.trading-account.change-password');
  const { permissionGiven: PermissionShowBlockAccount } = usePermission('admin.trading-account.block-account');

  const ENABLE_WT = useAppSelector(getEnableWebTrader);

  const CURRENT_TABLE = ENABLE_WT ? TABLE_TRADING_ACCOUNT_NAMES_WITH_WT : TABLE_TRADING_ACCOUNT_NAMES;

  const {columns, saveColumnOrder, setDefaultColumnsOrder} = useTableColumnOrder(ENABLE_WT ? "TABLE_TRADING_ACCOUNT_NAMES_WITH_WT" : "TABLE_TRADING_ACCOUNT_NAMES" , CURRENT_TABLE);

  // const columns = React.useMemo(() => CURRENT_TABLE, [ENABLE_WT]);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data]);

  const prepearedTableData = tableData.map((item, pos) => {
    const { amount_type, balance, credit, id, demo, name, status, trading_account, leverage, type } =
      item;

    return {
      trading_account: (
        <div className={cx('login')}>
          <span>
            {name}
            {/*<span className={cx('ta_type', type.toLowerCase())}>{type}</span>*/}
          </span>
        </div>
      ),
      login: (
        <div>
          {demo === 1 && <span style={{ color: 'blue', fontWeight: 'bold' }}>DEMO </span>}<span>{trading_account}</span>
        </div>
      ),
      demo: (
        <div>
          <span>{demo === 1 ? 'Yes' : 'No'}</span>
        </div>
      ),
      balance: (
        <div>
          <span className={'d-flex justify-content-between'}>
            {amount_type}&nbsp;{balance === null ? '0.00' : balance}
            <ArrowsClockwise
              size={20}
              style={{ cursor: 'pointer' }}
              onClick={() => getBalance(trading_account, id)}
              data-tip='Refresh Balance'
              data-for='refresh'
            />
            <ReactTooltip id='refresh' />
          </span>
        </div>
      ),
      // credit: (
      //   <div>
      //     <span>
      //       {amount_type} {credit}
      //     </span>
      //   </div>
      // ),
      leverage: (
        <div>
          <span>{leverage}</span>
        </div>
      ),
      status: (
        <div>
          <span className={cx('status', status)}>{status}</span>
        </div>
      ),
      action: (
        <div className={cx('action')}>
          <ShowPassword
            tradingAccount={item}
            triggerBtn={<Eye size={20} data-tip='Show password' data-for='showPass' />}
          />
          <ReactTooltip id='showPass' />
          
          {PermissionShowBlockAccount ?
            <LockLaminated
              size={20}
              onClick={() =>
                actionTaMethod({
                  taId: id,
                  blocked: status === 'active' || status === 'blocked-trading',
                })
              }
              data-tip={status === 'active' || status === 'blocked-trading' ? 'Block account' : 'Activate account'}
              data-for='blocked'
            />
            : null}

          {PermissionShowBlockAccount ?
            <LockLaminated
              size={20}
              onClick={() =>
                blockTaTradingMethod(id, status === 'active' || status === 'blocked')
              }
              data-tip={status === 'active' || status === 'blocked' ? 'Block trading' : 'Allow trading'}
              data-for='blocked-trading'
              color='blue'
            />
            : null}
          <ReactTooltip id='blocked' />
          <ReactTooltip id='blocked-trading' />

          {/*<Trash*/}
          {/*  size={20}*/}
          {/*  onClick={() => deleteTradingAccount(id)}*/}
          {/*  color="#ff0000"*/}
          {/*  data-tip='Delete account'*/}
          {/*  data-for='delete'*/}
          {/*/>*/}
          {/*<ReactTooltip id='delete' type={'error'}/>*/}

          <PopupYesOrNo
            onClick={() => deleteTradingAccount(id)}
            headerTitle={'Delete trading account'}
            bodyText={'Are you sure you want to delete trading account?'}
            triggerBtn={
              <Trash
                size={20}
                color="#ff0000"
                data-tip='Delete account'
                data-for='delete'
              />
            }
          />
          <ReactTooltip id='delete' type={'error'}/>

            {
                PermissionChangePasswordTradingAccount
                    ? <ChangeTAPassword
                        updateComponent={() => updateComponent()}
                        tradingAccount={item}
                        triggerBtn={<ResetPassword fillColor='#262626' style={{ width: '22px' }} />}
                    />
                    : null
            }
          <ReactTooltip id='editPass' />
        </div>
      ),
    };
  });

  const checkSortEnable = (colId) => {
    // switch (colId) {
    //   case 'trading_account':
    //   case 'login':
    //   case 'balance':
    //   case 'credit':
    //   // case 'leverage':
    //   case 'status':
    //     // case 'server':
    //     return true;
    //   default:
    //     return false;
    // }
  };

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
    <Button 
buttonStyle={{
  marginBottom: "12px",
  marginLeft: 0,
}}
buttonText='Reset column order'
onClick={setDefaultColumnsOrder}
 />
    <Table
    saveColumnOrder={saveColumnOrder}
      columns={columns}
      data={tableDataMeMo}
      perPage={perPage}
      showLoader={showLoader}
      onRowSelect={onRowSelect}
      colSortCheck={colSortCheck || checkSortEnable}
      className={cx('clients-table')}
      isSelected={false}
      getCellProps={(cellInfo, cx) => {
        const { row, value, column } = cellInfo;
        return {
          style: {
            backgroundColor: `${row.isSelected ? '#f9f1ff' : '#ffffff'}`,
          },
        };
      }}
    />
    </>
  );
};

export default TradingAccountsTable;
