import classNames                                                  from 'classnames/bind';
import React, { useEffect, useState }                              from 'react';
import { Col, Row }                                                from 'react-bootstrap';
import { getDynamicStatusesList, getDynamicStatusesTypeModelList } from '../../../api/settingsLK/index';
import Button                                                      from '../../../components/ui/Button/index.js';
import DynamicStatusMenu                                           from '../../../components/ui/DynamicStatusMenu/index.js';
import DynamicStatusesTable
                                                                   from '../../../components/ui/Table/DynamicStatuseTable/DynamicStatusesTable';
import { SALES_RULES_ACTION_TYPE }                                 from '../../../constants/sales-rules.const.js';
import { usePermission }                                           from '../../../utils/usePermission.tsx';
import Page                                                        from '../../Page/index.js';
import { DashboardLayout }                                         from '../../UserView/DashboardLayout';
import CreateStatusPopup                                           from './popups/CreateStatusPopup';

import styles from './rolesPermissions-page.module.scss';
import {
  DynamicStatusesActiveRole,
  DynamicStatusesType
} from "../../../models/SettingsLK/DynamicStatuses/DynamicStatusesPage.ts";


const cx = classNames.bind(styles);
const DynamicStatusesPage = () => {

  const { permissionGiven: PermissionMenu } = usePermission('admin.menu-dynamic-statuses.view', true);
  const { permissionGiven: PermissionCreateAndEditDynamicStatuses } = usePermission('admin.statuses.create');

  const { permissionGiven: PermissionChangeColor } = usePermission('admin.statuses.color.update');

  const [typeList, setTypeList] = useState<DynamicStatusesType[]>([]);
  const [activeRole, setActiveRole] = useState<DynamicStatusesActiveRole>(null);

  const title = 'Dynamic statuses';

  const fetchDynamicStatusesType = () => {
    getDynamicStatusesTypeModelList().then((res) => {
      setTypeList(parseList(res.data));
    }).catch(() => console.log(PermissionMenu));
  };
  const getStatusList = () => {
    let type = activeRole.type.toLowerCase();
    return (
      activeRole &&
      getDynamicStatusesList(type).then((res) => {
        return res.data.data;
      })
    );
  };
  useEffect(() => {
    fetchDynamicStatusesType();
  }, []);
  useEffect(() => {
    document.title = title;
  }, [title]);
  const parseList = (list) => {
    return list.map((item, key) => {
      return { id: key, name: item, show: false };
    });
  };
  const clearTable = (list) => {
    setActiveRole(null);
  };

  const [itemList, setItemList] = useState(typeList);
  useEffect(() => {
    setItemList(typeList);
  }, [typeList]);
  const updateSubItems = async (arr, curr) => {
    let listRoles = await getStatusList();
    let type = curr.type.toLowerCase();
    let newList = arr.map((item, ind) => {
      if (item.name === type) {
        return {
          ...item,
          subItems: [...listRoles],
          show: true,
        };
      }
      return {
        ...item,
        show: false,
      };
    });
    listRoles.map((item) => {
      if (item.key === curr.key) {
        setActiveRole(item);
      }
    });
    setItemList(newList);
  };
  return (
    <DashboardLayout>
      <Page>
        <div className={cx('container')}>
          <div className={cx('container__header')}>
            <div className={cx('header')}>
              <div className={cx('search-result')}>
                <span>Dynamic statuses</span>
                <span></span>
              </div>
              <div className={cx('header-controls')}>
                {PermissionCreateAndEditDynamicStatuses ?
                  <CreateStatusPopup
                    updateComponent={() => updateSubItems(itemList, activeRole)}
                    actionType={SALES_RULES_ACTION_TYPE.CREATE}
                    triggerBtn={
                      <Button
                        buttonText={'+ Add status'}
                        onClick={() => null}
                        buttonType={'outline'}
                      />
                    }
                  />
                  : null
                }
              </div>
            </div>
          </div>
          <div className={cx('container__block')}>
            <Row>
              <Col xl={3}>
                <DynamicStatusMenu
                  setItemList={setItemList}
                  clearTable={clearTable}
                  itemList={itemList}
                  setActiveRole={setActiveRole}
                  activeRole={activeRole}
                />
              </Col>
              <Col xl={9}>
                <div
                  style={activeRole?.name && { justifyContent: 'space-between' }}
                  className={styles.wrapperHeaderTable}
                >
                  {activeRole?.name && <p className={styles.bolderText}>{activeRole.name}</p>}
                </div>
                <DynamicStatusesTable
                  permissionChangeColor={PermissionChangeColor}
                  modifyDataHandler={() => updateSubItems(itemList, activeRole)}
                  data={activeRole?.statuses ? activeRole.statuses : []}
                  type={activeRole?.type}
                  permission={PermissionCreateAndEditDynamicStatuses}
                />
                {!activeRole && (
                  <p className={styles.worryingGray}>
                    Select a dynamic status type to create or edit an existing dynamic status
                  </p>
                )}
              </Col>
            </Row>
          </div>
        </div>
      </Page>
    </DashboardLayout>
  );
};

export default DynamicStatusesPage;
