import React from "react";
import { Trash } from "phosphor-react";
import { useAppDispatch } from "../../../../store";
import { deleteParticipant } from "../../../../store/slice/chat/thunks";
import { MenuOption } from "./MenuOption";

type DeleteParticipantOptionProps = {
    id: string;
    threadId: string;
    ownerId;
};

export const DeleteParticipantOption = ({ id, threadId, ownerId }: DeleteParticipantOptionProps) => {
    const dispatch = useAppDispatch();

    const handleDeleteParticipant = () => {
        dispatch(deleteParticipant({ threadId, ownerId, id }));
    };

    return (
        <MenuOption title='Remove' onClick={handleDeleteParticipant}>
            <Trash size={32} color="#c71d27"/>
        </MenuOption>
    )
}