import debounce from "lodash.debounce";
import { useEffect, useMemo, useState } from "react";
import { searchChatMessages } from "../../../../api/chat";

export const useDialogSearch = (threadId: string) => {
	const [search, setSearch] = useState('');
	const [results, setResults] = useState([]);
	const [loading, setLoading] = useState(false);

	useEffect(() => {
		const fetchOptions = async () => {
			try {
				setLoading(true);
				const response = await searchChatMessages(encodeURIComponent(search), threadId);

				setResults(response.data);
				setLoading(false);
			} catch (e) {
				setLoading(false);
				console.log('Failed to search chat messages!');
			}
		};

		if (search.trim().length > 2) {
			fetchOptions();
		} else {
			setResults([]);
		}
	}, [search]);

	const debouncedChangeHandler = useMemo(() => debounce(e => {
		setSearch(e.target.value)
	}, 300), []);

	useEffect(() => {
		return () => {
			debouncedChangeHandler.cancel();
		}
	}, []);

	const resetSearch = () => {
		setResults([]);
		setSearch('');
		setLoading(false);
	};

	return {
		search,
		loading,
		results,
		onSearch: debouncedChangeHandler,
		resetSearch
	}
}