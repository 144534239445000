import React from "react";
import { Control } from "react-hook-form";
import { DefaultSelectInput } from "../DefaultSelectInput/DefaultSelectInput";

import './CrmSearchCategorySelect.scss';

interface ICrmSearchCategorySelect {
  name: string;
  id: string;
  options?: IOption[];
  onChange?: () => void;
  placeholder?: string;
  onSelectChange?: () => void;
  control: Control<any>;
}

export interface IOption {
  label: string;
  value: string | number;
}

export const defaultQueryCategoryOption: IOption = { label: "All", value: "all" };

export const CrmSearchCategorySelect = ({ control, id, name, options, onSelectChange, }: ICrmSearchCategorySelect) => {
  return (
    <div className="crm-search-category-select-container">
      <DefaultSelectInput
        id={id}
        control={control}
        name={name}
        options={options}
        placeholder='All'
        onSelectChange={onSelectChange}
        classNamePrefix='crm-search-category-select'
      />
    </div>
  );
};