import classNames from 'classnames/bind';
import Button from 'components/ui/Button';
import FormInput from 'components/ui/FormInput';
import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { notify } from 'utils/notify';
import { updateOperatorProfileMethod } from '../../../../../api/operators/index';
import JokerSelectWithSearch from '../../../../../components/ui/JokerSelectWithSearch/index.js';
import { VALID_EMAIL } from '../../../../../constants/validators.js';
import { usePermission } from '../../../../../utils/usePermission.tsx';
import styles from '../operator-page.module.scss';
import { CountryListValue } from 'models/Countries.js';
import { ManagementOperator } from 'models/Management/Operators/Operator.js';

const cx = classNames.bind(styles);

type Props = {
  formData: ManagementOperator;
  updateComponent: () => void;
  countryList: CountryListValue[];
};

const OperatorPersonalInfoForm = (props: Props) => {
  const { formData, updateComponent, countryList } = props;

  const { permissionGiven: PermissionEditOperator } = usePermission('admin.admin-user.edit');

  const [isLoading, setIsLoading] = useState(true);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const getValue = (data) => {
    if (!data || (Array.isArray(data) && !data.length)) {
      return;
    }

    if (Array.isArray(data) && data.length) {
      const temp = data.map((item) => (item.value !== 'any' ? item.value : null));
      return temp.filter((item) => item);
    }

    return data.value !== 'any' ? data.value : null;
  };

  const onSubmit = (data) => {
    setIsLoading(true);
    const sendData = {
      ...data,
      country_id: getValue(data['country_id']),
    };
    updateOperatorProfileMethod(formData.id, sendData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Personal info updated successfully',
            timeOut: 3000,
          });
          setIsLoading(false);
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (Object.keys(formData).length !== 0) {
      setIsLoading(false);
      reset({
        first_name: formData.first_name,
        last_name: formData.last_name,
        email: formData.email,
        country_id: formData?.country
          ? { value: formData?.country?.id, label: formData?.country?.name }
          : '',
        phone: formData.phone,
        sip_account: formData.sip_account,
      });
    }
  }, [countryList, formData]);
  return (
    <form className={cx('form')} onSubmit={handleSubmit(onSubmit)}>
      <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
        <Row className={cx('form__line')}>
          <Col md={12}>
            <div className={cx('tab-panel__content-box-title')}>
              Personal information
              {PermissionEditOperator
                ? isDirty && <Button buttonText="Save changes" buttonType="outline" />
                : null}
            </div>
          </Col>
        </Row>
        <Row className={cx('form__line ')}>
          <Col md={6} lg={4} xs={12}>
            <FormInput
              rules={{
                required: 'First name field is required',
                validate: {
                  correctFirstName: (value) => value.length < 50 || 'First name too long',
                },
              }}
              name="first_name"
              type="text"
              label="First name"
              control={control}
              errors={errors.first_name}
            />
          </Col>
          <Col md={6} lg={4} xs={12}>
            <FormInput
              rules={{
                required: 'Last name field is required',
                validate: {
                  correctLastName: (value) => value.length < 50 || 'Last name too long',
                },
              }}
              name="last_name"
              type="text"
              label="Last name"
              control={control}
              errors={errors.last_name}
            />
          </Col>
          <Col md={6} lg={4} xs={12}>
            <FormInput
              disabled={true}
              name="email"
              type="email"
              label="Email"
              control={control}
              rules={{
                validate: VALID_EMAIL,
              }}
              errors={errors.email}
            />
          </Col>
        </Row>
        <hr />
        <Row className={cx('form__line')}>
          <Col md={12}>
            <div className={cx('tab-panel__content-box-title')}>Contacts</div>
          </Col>
        </Row>
        <Row className={cx('form__line ')}>
          <Col md={4}>
            <div className={cx('form__line', 'd-flex')}>
              <div className={cx('form__line-input-wrap')}>
                <FormInput name="phone" type="text" label="Phone" control={control} />
              </div>
              {/* <Button buttonText="Verify" /> */}
            </div>
          </Col>
          <Col md={4}>
            <div className={cx('form__line')}>
              <JokerSelectWithSearch
                label="Country"
                control={control}
                id="country_id"
                name="country_id"
                placeholder={'Search'}
                rightaligned={true}
                options={countryList}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <div className={cx('form__line', 'd-flex')}>
              <div className={cx('form__line-input-wrap')}>
                <FormInput name="sip_account" type="text" label="SIP account" control={control} />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </form>
  );
};

export default OperatorPersonalInfoForm;
