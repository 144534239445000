import React from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Row } from 'react-bootstrap';
import PopupTemplate from '../../../../../components/ui/PopupTemplate';
import Button from '../../../../../components/ui/Button';
import classNames from 'classnames/bind';
import moment from 'moment/moment';
import styles from './ShowLastNote.scss'
const cx = classNames.bind(styles);

const ShowLastNote = ({
                        triggerBtn,
                        note_last,
                        user,
                      }) => {
  const {
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });

  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={`Last note by ${note_last?.operator_name || ''}`}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form>
                    <Row>
                      <Col md={12}>
                        <ul className={'last-note__body'}>
                          <li>{user.first_name} {user.last_name}</li>
                          <li><b>{moment.unix(note_last.create_at).format('DD.MM.YYYY')}</b></li>
                          <li>{moment.unix(note_last.create_at).format('HH:mm:ss')}</li>
                          <li>{<b>Subject: </b>}{note_last.subject}</li>
                          <li>{<b>Body: </b>}{note_last.body}</li>
                        </ul>
                      </Col>
                    </Row>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText='OK'
                          buttonType='outline'
                          size='big'
                          type='button'
                          onClick={closeModal.bind(undefined, close)}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default ShowLastNote;
