import { documentTypes, findDocumentMethod }  from 'api/documents';
import classNames                             from 'classnames/bind';
import KycDocumentsTable                      from 'components/ui/Table/KycDocumentsTable';
import TablePagination                        from 'components/ui/TablePagination';
import Page                                   from 'pages/Page/Page';
import { DashboardLayout }                    from 'pages/UserView/DashboardLayout';
import React, { useEffect, useRef, useState } from 'react';

import { useForm }                          from 'react-hook-form';
import { useParams }                        from 'react-router-dom';
import { notify }                           from 'utils/notify';
import { usePermission } from '../../utils/usePermission.tsx';
import KycDocumentsFilter                   from './KycDocumentsFilter/KycDocumentsFilter';
import styles                               from './page-style.module.scss';
import { getTablePageParams } from '../../utils/getTablePageParams.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { KYCDocumentsTypesData } from 'models/KYCDocuments/KYCDocsPage/Types.js';
import { KYCDocumentsFilter } from 'models/KYCDocuments/KYCDocsPage/Filter.js';
import { KYCDocument } from 'models/KYCDocuments/Document.js';
import { ListValue } from 'models/ListValue.js';
import { toggleScrollToTop } from "../../store/slice/tableSlice.ts";
import { useAppDispatch } from "../../store";
import { useCheckAutoRefresh } from "../../utils/useCheckAutoRefresh.tsx";

const cx = classNames.bind(styles);

const KycDocumentsPage = () => {

  const { permissionGiven: PermissionList } = usePermission('admin.document.index');

  const dispatch = useAppDispatch();
  const [expanded, setExpanded] = useState(true);
  const [documentsList, setDocumentsList] = useState<KYCDocument[]>([]);

  const [isListLoading, setIsListLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [filters, setFilters] = useState<KYCDocumentsFilter>(null);

  const [typesData, setTypesData] = useState<KYCDocumentsTypesData[]>([]);
  const [categoryOptions, setCategoryOptions] = useState<ListValue[]>();
  const [typesOptions, setTypesOptions] = useState<ListValue[]>();
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [selectedCategory, setSelectedCategory] = useState<(string | number)[]>([]);

  const [selectedType, setSelectedType] = useState(null);

  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const { id } = useParams();
  const checkAutoRefresh = useCheckAutoRefresh('Page KYC documents')
  const title = 'KYC documents';

  const componentMounted = useRef(false);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isDirty },
  } = useForm({
    reValidateMode: 'onChange',
  });
  useEffect(() => {
    document.title = title;
  }, [title]);
  const fetchDocumentsList = (options?, data?) => {
    setIsListLoading(true);
    findDocumentMethod(getTablePageParams(options), data)
      .then((res) => {
        if (res.status === 200) {
          setDocumentsList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setQueryResponseMessage(res?.data?.message || '');
          setIsListLoading(false);
        }
      })
      .catch((error) => {
        if (error && error.response.data !== null) {
          setIsListLoading(false);
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      });
  };

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      fetchDocumentsList();
    }
    return () => {
      componentMounted.current = false;
    };
  }, [PermissionList]);

  const getDocumentsList = (options, data?) => {
    setIsListLoading(true);

    let postData;

    findDocumentMethod(getTablePageParams(options, perPageCount, tableLinks), data || postData || filters)
      .then((res) => {
        setDocumentsList(res.data.data);
        setTableMeta(res.data.meta);
        setTableLinks(res.data.links);
        setPerPageCount(res.data.meta.per_page);
        setQueryResponseMessage(res?.data?.message || '');
        setIsListLoading(false);
      })
      .catch((err) => {
        setIsListLoading(false);
      });
  };

  const changeTypesDefaultValue = (selectedCategory) => {
    let result = [];
    let docTypeOpt = [];

    typesData?.filter(function(arr_item) {
      return (
        selectedCategory.filter(function(idArr_item) {
          if (idArr_item == arr_item.key) {
            return (result = [...result, ...arr_item.activeStatuses]);
          }
        }).length !== 0
      );
    });
    if (selectedCategory.length) {
      setTypesOptions(
        result.map((el) => ({
          value: el.key,
          label: el.translate,
        })),
      );
    } else {
      typesData?.forEach((item) => {
        const arr = item.activeStatuses.map((item) => ({
          value: item.key,
          label: item.translate,
        }));

        if (arr.length) {
          docTypeOpt = docTypeOpt.concat(arr);
          setTypesOptions(docTypeOpt);
        }
      });
    }

    setSelectedType(null);
  };

  useEffect(() => {
    documentTypes()
      .then((res) => {
        setTypesData(res.data.data);
        const categoryOptions = res.data.data.map((item) => ({
          value: item.key,
          label: item.translate,
        }));

        let docTypeOpt = [];

        res.data.data.forEach((item) => {
          const arr = item.activeStatuses.map((item) => ({
            value: item.key,
            label: item.translate,
          }));

          if (arr.length) {
            docTypeOpt = docTypeOpt.concat(arr);
          }
        });

        setTypesOptions(docTypeOpt);

        setCategoryOptions(categoryOptions);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    selectedCategory && changeTypesDefaultValue(selectedCategory);
  }, [selectedCategory]);

  return (
    <>
      <DashboardLayout>
        <Page>
          <div className={cx('container')}>
            <div className={cx('container__header')}>
              <div className={cx('header', selectedRows?.length ? 'padding' : '')}>
                <div className={cx('search-result')}>
                  <span>
                    <strong>{tableMeta?.total}</strong> KYC documents
                  </span>
                  <span></span>
                </div>
              </div>
            </div>
            <div className={cx('container__filters-block', !expanded && 'hide')}>
              <KycDocumentsFilter
                categoryOptions={categoryOptions}
                typesOptions={typesOptions}
                changeDocumentCategory={(val) => setSelectedCategory(val.map((el) => el.value))}
                onSubmit={(data) => {
                  setFilters(data);
                  getDocumentsList({ navigate: 'first', perPage: data?.filter?.limit }, data);
                }}
                reloadFilters={isListLoading}
                queryMessage={queryResponseMessage}
              />
            </div>
            <div className={cx('container__block')}>
              {
                PermissionList ?
                  <KycDocumentsTable
                    data={documentsList}
                    perPage={perPageCount}
                    showLoader={isListLoading}
                    onRowSelect={setSelectedRows}
                    userId={id}
                  /> : null
              }
              {documentsList?.length ? (
                <TablePagination
                  goToFirstPage={() => {
                    getDocumentsList({ navigate: 'first' });
                    dispatch(toggleScrollToTop())
                  }}
                  goToPrevPage={() => {
                    getDocumentsList({ navigate: 'prev' });
                    dispatch(toggleScrollToTop())
                  }}
                  goToNextPage={() => {
                    getDocumentsList({ navigate: 'next' });
                    dispatch(toggleScrollToTop())
                  }}
                  goToLastPage={() => {
                    getDocumentsList({ navigate: 'last' });
                    dispatch(toggleScrollToTop())
                  }}
                  currentPage={tableMeta?.current_page}
                  pagesLength={tableMeta?.last_page}
                  perPageChange={(value) => {
                    setPerPageCount(value);
                    getDocumentsList({ perPage: value });
                  }}
                  goToSelectedPage={(page) => {
                    getDocumentsList({ page });
                  }}
                  // hidePageSelect={true}
                  isFirstPageDisable={() => tableMeta?.current_page === 1}
                  isPrevPageDisable={() => !tableLinks?.prev}
                  isNextPageDisable={() => !tableLinks?.next}
                  isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
                />
              ) : (
                ''
              )}
            </div>
          </div>
        </Page>
      </DashboardLayout>
    </>
  );
};

export default KycDocumentsPage;
