import * as React from "react"

const ResetPassword = (props) => {

    const {fillColor} = props;
    const fillColorValue = fillColor || '#722ED1';

    return (
        <svg
            viewBox="0 0 32 32"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.975 5.463a1 1 0 0 1 1 1v5h5a1 1 0 1 1 0 2h-6a1 1 0 0 1-1-1v-6a1 1 0 0 1 1-1Z"
                fill={fillColorValue}
            />
            <path
                d="M13.662 4.231a12 12 0 1 1-6.144 20.25 1 1 0 1 1 1.414-1.413 10.001 10.001 0 1 0 0-14.136l-.001.001-4.25 4.238a1 1 0 1 1-1.412-1.417l4.249-4.236a12 12 0 0 1 6.144-3.287Z"
                fill={fillColorValue}
            />
            <path
                d="M10.975 15.25a1 1 0 0 1 1-1h9a1 1 0 0 1 1 1v5.5a1 1 0 0 1-1 1h-9a1 1 0 0 1-1-1v-5.5Zm2 1v3.5h7v-3.5h-7Z"
                fill={fillColorValue}
            />
            <path
                d="M16.475 12.25a1.5 1.5 0 0 0-1.5 1.5v1.5a1 1 0 1 1-2 0v-1.5a3.5 3.5 0 1 1 7 0v1.5a1 1 0 1 1-2 0v-1.5a1.5 1.5 0 0 0-1.5-1.5Z"
                fill={fillColorValue}
            />
        </svg>
    );
};

export default ResetPassword;
