import cn from 'classnames';
import { useController } from 'react-hook-form';
import { GroupBase } from 'react-select';
import { InputField, SelectInputProps } from "../../../../../components/ui/ModernDateRangeInput";
import { DropdownIndicator } from "./DropdownIndicator";
import { AsyncPaginate } from "react-select-async-paginate";
import { fetchColors } from "../../helpers/fetchOptions";
import { Option } from "./Option";
import { SingleValue } from "./SingleValue";
import './SelectMessageColor.scss';


export const SelectMessageColor = <
  IOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<IOption> = GroupBase<IOption>,
>({
  id,
  name,
  label,
  isDisabled,
  isLoading,
  tooltip,
  rules,
  placeholder,
  control,
}: SelectInputProps<IOption, IsMulti, Group>) => {
  const { field, formState } = useController({ name, control, disabled: !!isDisabled, rules });
  const { onChange, ...fieldProps } = field;
  const fieldError = `${formState.errors?.[name]?.message || ''}`;

  const onSelectChange = (value) => {
    field.onChange(value);
  };

  return (
    <InputField
      id={id}
      label={label}
      error={fieldError}
      className={cn('select-input', { required: rules?.required })}
      tooltip={tooltip}
    >
      <AsyncPaginate
        {...fieldProps}
        inputId={id}
        className={cn({ 'is-loading': isLoading })}
        classNamePrefix="select-color-input"
        menuPosition='fixed'
        placeholder={placeholder}
        loadOptions={fetchColors}
        components={{ DropdownIndicator, Option, SingleValue }}
        onChange={onSelectChange}
        isLoading={isLoading}
        isDisabled={isDisabled}
        isSearchable={false}
        isMulti={false}
      />
    </InputField>
  );
};
