import React, { useState, useEffect } from 'react';
import Popup from 'reactjs-popup';
import { useForm } from 'react-hook-form';
import { Col, Container, Row } from 'react-bootstrap';
import classNames from 'classnames/bind';

import styles from './clientsPopups.mudule.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
import FormTextarea from 'components/ui/FormTextarea';
import { notify } from 'utils/notify';
import SelectComponent from 'components/ui/Select/Select';
import { createTradingAcc } from 'api/tradingAcc';
import { blockedAcc, getBlockedStatus } from 'api/clients';
import { usePermission } from '../../../../utils/usePermission';
import { ListValue } from 'models/ListValue';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  userId: string | number;
}

const ClientChangeAccountStatusPopup = (props: Props) => {
  const { triggerBtn, updateComponent, userId } = props;

  const { permissionGiven: PermissionEditBlockedAccount } = usePermission('admin.client.blocked');

  const [isLoading, setIsLoading] = useState(false);
  const [reasonOpt, setReasonOpt] = useState<ListValue[]>([]);

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    reValidateMode: 'onChange',
  });
  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };

  const onSubmit = (close, data) => {
    setIsLoading(true);
    const sendData = {
      status_id: data && data.reason ? data.reason.id : null,
      comment: data && data.comment ? data.comment : null,
    };

    blockedAcc(userId, sendData)
      .then((res) => {
        if (res.status === 200) {
          updateComponent();
          closeModal(close);
          setIsLoading(false);
          notify({
            type: 'success',
            message: res.data.message,
            timeOut: 3000,
          });
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };
  const getReasonList = () => {
    getBlockedStatus()
      .then((res) => {
        if (res) {
          setReasonOpt(res.data.data);
          setIsLoading(false);
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (PermissionEditBlockedAccount) {
      getReasonList();
    }
  }, [PermissionEditBlockedAccount]);

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={'Change account status'}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'popupForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <Container>
                      <Row>
                        <Col md={12}>
                          <SelectComponent
                            name='reason'
                            label='Reason'
                            control={control}
                            options={reasonOpt}
                            rules={{ required: ' Field is required' }}
                            errors={errors.reason}
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            isSearchable={false}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <FormTextarea
                            name='comment'
                            label='Comment'
                            control={control}
                            rules={{ required: ' Field is required' }}
                            errors={errors.comment}
                          />
                        </Col>
                      </Row>
                    </Container>
                    <div className={cx('content-controls')}>
                      <div className={cx('controls__buttons')}>
                        <Button
                          buttonText='Cancel'
                          buttonType='outline'
                          size='big'
                          type='button'
                          onClick={closeModal.bind(undefined, close)}
                        />
                        <LoaderButton
                          buttonType='primary'
                          size='big'
                          showSpinner={isLoading}
                          disabled={isLoading}
                          buttonText='Change status'
                          type='submit'
                          onClick={(data) => handleSubmit(onSubmit.bind(data, close))}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default ClientChangeAccountStatusPopup;
