const TeamIcon = (props) => {
  return (
    <svg width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 16.875C17.2386 16.875 15 19.1136 15 21.875C15 24.6364 17.2386 26.875 20 26.875C22.7614 26.875 25 24.6364 25 21.875C25 19.1136 22.7614 16.875 20 16.875ZM12.5 21.875C12.5 17.7329 15.8579 14.375 20 14.375C24.1421 14.375 27.5 17.7329 27.5 21.875C27.5 26.0171 24.1421 29.375 20 29.375C15.8579 29.375 12.5 26.0171 12.5 21.875Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.2603 20.2295C33.1323 19.6655 31.8882 19.3729 30.6271 19.375C29.9367 19.3762 29.3762 18.8175 29.375 18.1271C29.3738 17.4367 29.9326 16.8762 30.6229 16.875C32.2735 16.8723 33.9019 17.2552 35.3783 17.9934C36.8547 18.7316 38.1381 19.8045 39.1263 21.1267C39.5395 21.6797 39.4263 22.463 38.8733 22.8763C38.3203 23.2896 37.537 23.1763 37.1237 22.6233C36.3688 21.6132 35.3882 20.7935 34.2603 20.2295Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.37292 19.375C8.11183 19.3729 6.8677 19.6655 5.73975 20.2295C4.61179 20.7935 3.63123 21.6132 2.87627 22.6233C2.46298 23.1763 1.67967 23.2896 1.12669 22.8763C0.573711 22.463 0.460472 21.6797 0.873761 21.1267C1.86192 19.8045 3.14536 18.7316 4.62171 17.9934C6.09807 17.2552 7.72649 16.8723 9.3771 16.875C10.0675 16.8762 10.6262 17.4367 10.625 18.1271C10.6239 18.8175 10.0633 19.3762 9.37292 19.375Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 29.379C18.3581 29.379 16.7492 29.8402 15.3566 30.7099C13.964 31.5797 12.8436 32.8231 12.1232 34.2985C11.8203 34.9188 11.0719 35.1762 10.4515 34.8733C9.83116 34.5704 9.57382 33.8219 9.87673 33.2016C10.8026 31.3054 12.2425 29.7073 14.0323 28.5895C15.822 27.4717 17.8898 26.879 20 26.879C22.1102 26.879 24.1779 27.4717 25.9677 28.5895C27.7575 29.7073 29.1973 31.3054 30.1232 33.2016C30.4261 33.8219 30.1688 34.5704 29.5484 34.8733C28.9281 35.1762 28.1796 34.9188 27.8767 34.2985C27.1563 32.8231 26.036 31.5797 24.6434 30.7099C23.2508 29.8402 21.6419 29.379 20 29.379Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3013 9.49249C9.61183 9.31573 8.88631 9.33882 8.2095 9.55907C7.53268 9.77932 6.93253 10.1876 6.47916 10.7363C6.02578 11.285 5.73791 11.9513 5.64919 12.6575C5.56046 13.3637 5.67454 14.0806 5.9781 14.7244C6.28166 15.3681 6.76215 15.9122 7.36345 16.293C7.96474 16.6739 8.66199 16.8757 9.37374 16.875C10.0641 16.8743 10.6243 17.4334 10.625 18.1237C10.6257 18.8141 10.0666 19.3743 9.37628 19.375C8.19003 19.3762 7.02794 19.0398 6.02579 18.4051C5.02363 17.7704 4.2228 16.8635 3.71688 15.7906C3.21095 14.7176 3.02081 13.5229 3.16869 12.3459C3.31657 11.1689 3.79635 10.0583 4.55197 9.14384C5.30759 8.22939 6.30785 7.54886 7.43587 7.18178C8.56389 6.81469 9.77308 6.77621 10.9222 7.07082C12.0712 7.36543 13.1128 7.98097 13.925 8.84552C14.7372 9.71007 15.2867 10.7879 15.5091 11.9531C15.6385 12.6312 15.1937 13.2859 14.5156 13.4153C13.8375 13.5448 13.1829 13.1 13.0534 12.4219C12.92 11.7227 12.5903 11.076 12.103 10.5573C11.6156 10.0386 10.9907 9.66926 10.3013 9.49249Z"
        fill="#722ED1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.7905 9.55907C31.1137 9.33882 30.3882 9.31573 29.6987 9.49249C29.0093 9.66926 28.3844 10.0386 27.897 10.5573C27.4097 11.076 27.08 11.7227 26.9466 12.4219C26.8171 13.1 26.1625 13.5448 25.4844 13.4153C24.8062 13.2859 24.3615 12.6312 24.4909 11.9531C24.7133 10.7879 25.2628 9.71007 26.075 8.84552C26.8872 7.98097 27.9288 7.36543 29.0778 7.07082C30.2269 6.77621 31.4361 6.81469 32.5641 7.18178C33.6921 7.54886 34.6924 8.22939 35.448 9.14384C36.2037 10.0583 36.6834 11.1689 36.8313 12.3459C36.9792 13.5229 36.789 14.7176 36.2831 15.7906C35.7772 16.8635 34.9764 17.7704 33.9742 18.4051C32.9721 19.0398 31.81 19.3762 30.6237 19.375C29.9334 19.3743 29.3743 18.8141 29.375 18.1237C29.3757 17.4334 29.9359 16.8743 30.6263 16.875C31.338 16.8757 32.0353 16.6739 32.6365 16.293C33.2378 15.9122 33.7183 15.3681 34.0219 14.7244C34.3255 14.0806 34.4395 13.3637 34.3508 12.6575C34.2621 11.9513 33.9742 11.285 33.5208 10.7363C33.0675 10.1876 32.4673 9.77932 31.7905 9.55907Z"
        fill="#722ED1"
      />
    </svg>
  );
};

export default TeamIcon;
