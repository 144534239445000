import cn from 'classnames';
import { getMonth, getYear } from 'date-fns';
import { CaretLeft, CaretRight } from 'phosphor-react';

import { DateRangeValue, monthsList, yearsList } from './';

type Props = Partial<DateRangeValue> & {
  date: Date;
  changeYear(year: number): void;
  changeMonth(month: number): void;
  decreaseMonth(): void;
  increaseMonth(): void;
  prevMonthButtonDisabled: boolean;
  nextMonthButtonDisabled: boolean;
};

export const ModernDateInputMonthsYearsHeader = (
  {
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
    prevMonthButtonDisabled,
    nextMonthButtonDisabled,
  }: Props
) => {
  return (
    <div className={cn('months-year-selection _flex _flex-y-c _flex-x-b')}>
      <button className={cn('nav-month-button')} onClick={decreaseMonth} disabled={prevMonthButtonDisabled} type='button'>
        <CaretLeft size={12} weight="bold" color="#595959"/>
      </button>
      <div className="_flex">
        <select
          value={monthsList[getMonth(date)]}
          onChange={({target: {value}}) => changeMonth(monthsList.indexOf(value))}
        >
          {monthsList.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
        <select value={getYear(date)} onChange={({target: {value}}) => changeYear(Number(value))}>
          {yearsList.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </select>
      </div>
      <button className={cn('nav-month-button')} onClick={increaseMonth} disabled={nextMonthButtonDisabled} type='button'>
        <CaretRight size={12} weight="bold" color="#595959"/>
      </button>
    </div>
  );
};
