import { APIManagementAddOperatorResponse, APIManagementGetDepartmentsResponse, APIManagementGetOperatorsResponse, APIManagementGetSingleDepartmentResponse, APIManagementGetUserTypesResponse } from 'models/API/Management/Operators';
import API from '../../api';
import { APIManagementDictionaryResponse } from 'models/API/Management/Desks';
export const findOperatorsMethod = (params, data) =>
  API.post<APIManagementGetOperatorsResponse>(`/api/v1/search/admin-users?${params}`, data);
export const addOperatorsMethod = (data) => API.post<APIManagementAddOperatorResponse>(`/api/v1/admin-users`, data);
// method unused
export const updateOperatorsMethod = (id, data) => API.put(`/api/v1/admin-users/${id}`, data);
// method unused
export const deleteOperatorsMethod = (id) => API.delete(`/api/v1/admin-users/${id}`);
// method unused
export const getOperatorsListMethod = (params) => API.get(`/api/v1/admin-users?${params}`);
// method unused
export const getTotalUserTypesListMethod = () => API.get(`/api/v1/type-users?per_page=10&page=1`);
export const getUserTypesListMethod = (params) => API.get<APIManagementGetUserTypesResponse>(`/api/v1/type-users?${params}`);

export const getTotalDepartmentsTypesListMethod = () =>
  API.get<APIManagementGetDepartmentsResponse>(`/api/v1/departments?per_page=10&page=1`);
export const getDepartmentsListMethod = (params) => API.get<APIManagementGetDepartmentsResponse>(`/api/v1/departments?${params}`);
export const getDepartmentRolesMethod = (id) => API.get<APIManagementGetSingleDepartmentResponse>(`/api/v1/departments/${id}`);

// method unused
export const getTotalBranchListMethod = () => API.get(`/api/v1/branches?per_page=10&page=1`);
// method unused
export const getBranchListMethod = (params) => API.get(`/api/v1/branches?${params}`);
export const getDictionaryMethod = () => API.get<APIManagementDictionaryResponse>(`/api/v1/dictionary/system`);
