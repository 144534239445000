import { findAllCallbacksMethod } from 'api/callbaks';
import classNames from 'classnames/bind';
import CallbacksTableAdmin from 'components/ui/Table/CallbacksTableAdmin';

import TablePagination from 'components/ui/TablePagination';
import Page from 'pages/Page/Page';
import { DashboardLayout } from 'pages/UserView/DashboardLayout';
import React, { useEffect, useRef, useState } from 'react';
import { Row } from 'react-bootstrap';

import { useParams } from 'react-router-dom';
import { notify } from 'utils/notify';
import { usePermission } from '../../utils/usePermission.tsx'
import CallbacksFilter from './CallbacksFilter/CallbacksFilter';
import styles from './page-style.module.scss';
import { getTablePageParams } from '../../utils/getTablePageParams.js';
import { Callback } from 'models/Callbacks/Callback.js';
import { CallbackFilter } from 'models/Callbacks/CallbackFilter.js';
import { TableLinks, TableMeta } from 'models/Table.js';
import { useAppDispatch } from "../../store";
import { toggleScrollToTop } from "../../store/slice/tableSlice.ts";
import { useCheckAutoRefresh } from "../../utils/useCheckAutoRefresh.tsx";

const cx = classNames.bind(styles);

const CallbacksPage = () => {

  const { permissionGiven: PermissionList } = usePermission('admin.callback.index');

  const dispatch = useAppDispatch();

  const [callbacksList, setCallbacksList] = useState<Callback[]>([]);
  const [isListLoading, setIsListLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState(null);
  const [filters, setFilters] = useState<CallbackFilter>(null);
  const [queryResponseMessage, setQueryResponseMessage] = useState('');
  const [tableMeta, setTableMeta] = useState<TableMeta>();
  const [tableLinks, setTableLinks] = useState<TableLinks>();
  const [perPageCount, setPerPageCount] = useState(10);
  const { id } = useParams();
  const checkAutoRefresh = useCheckAutoRefresh('Page Callbacks')
  document.title = 'Callbacks';

  const componentMounted = useRef(false);

  const fetchCallbaksList = (options?, data?) => {
    setIsListLoading(true);

    findAllCallbacksMethod(getTablePageParams(options, perPageCount, tableLinks), data)
      .then((res) => {
        if (res.status === 200) {
          setCallbacksList(res.data.data);
          setTableMeta(res.data.meta);
          setTableLinks(res.data.links);
          setQueryResponseMessage(res?.data?.message || '');
        }
      })
      .catch((error) => {
        if (error && error?.response?.data !== null) {
          notify({
            type: 'error',
            message: error.response,
            timeOut: 3000,
          });
        }
      })
      .finally(() => {
        setIsListLoading(false);
      });
  };

  useEffect(() => {
    if (PermissionList) {
      componentMounted.current = true;
      fetchCallbaksList();
    }

    return () => {
      componentMounted.current = false;
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PermissionList]);

  const clearFiltersSortList = () => {
    setPerPageCount(10);
    fetchCallbaksList({ navigate: 'first', perPage: 10 });
    setFilters(null);
    // setSort(null);
  };

  return (
    <>
      <DashboardLayout>
        <Page>
          <div className={cx('container')}>
            <div className={cx('container__header')}>
              <Row>
                <div className={cx('header', selectedRows?.length ? 'padding' : '')}>
                  <div className={cx('search-result')}>
                    <span>
                      <strong>{tableMeta?.total}</strong> Callbacks
                    </span>
                    <span></span>
                  </div>
                </div>
              </Row>
            </div>
            <div className={cx('container__filters-block')}>
              <CallbacksFilter
                onSubmit={(filtersData: CallbackFilter) => {
                  if (!filters && !filtersData) {
                    return;
                  }
                  if (filters && !filtersData) {
                    clearFiltersSortList();
                  } else {
                    setFilters(filtersData);
                    setPerPageCount(filtersData?.filter?.limit || 10);
                    fetchCallbaksList({ navigate: 'first', perPage: perPageCount }, filtersData);
                  }
                }}
                reloadFilters={isListLoading}
                queryMessage={queryResponseMessage}
              />
            </div>
            {
              PermissionList ?
                <div className={cx('container__block')}>
                  <CallbacksTableAdmin
                    data={callbacksList}
                    perPage={tableMeta?.per_page}
                    showLoader={isListLoading}
                    onRowSelect={setSelectedRows}
                    userId={id}
                  />
                  {callbacksList?.length ? (
                    <TablePagination
                      goToFirstPage={() => {
                        fetchCallbaksList({ navigate: 'first', perPage: perPageCount }, filters);
                        dispatch(toggleScrollToTop())
                      }}
                      goToPrevPage={() => {
                        fetchCallbaksList({ navigate: 'prev', perPage: perPageCount }, filters);
                        dispatch(toggleScrollToTop())
                      }}
                      goToNextPage={() => {
                        fetchCallbaksList({ navigate: 'next', perPage: perPageCount }, filters);
                        dispatch(toggleScrollToTop())
                      }}
                      goToLastPage={() => {
                        fetchCallbaksList({ navigate: 'last', perPage: perPageCount }, filters);
                        dispatch(toggleScrollToTop())
                      }}
                      currentPage={tableMeta?.current_page}
                      pagesLength={tableMeta?.last_page}
                      perPageChange={(value) => {
                        setPerPageCount(value);
                        fetchCallbaksList({ perPage: value }, filters);
                      }}
                      goToSelectedPage={(page) => {
                        fetchCallbaksList({ page, perPage: perPageCount }, filters);
                      }}
                      isFirstPageDisable={() => tableMeta?.current_page === 1}
                      isPrevPageDisable={() => !tableLinks?.prev}
                      isNextPageDisable={() => !tableLinks?.next}
                      isLastPageDisable={() => tableMeta?.current_page === tableMeta?.last_page}
                    />
                  ) : (
                    ''
                  )}
                </div> : null
            }
          </div>
        </Page>
      </DashboardLayout>
    </>
  );
};

export default CallbacksPage;
