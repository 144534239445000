import classNames from 'classnames/bind';
import { SALES_RULES_ACTION_TYPE } from 'constants/sales-rules.const';

import { TABLE_TEMPLATE_ROLES } from 'constants/table';
import { makeTableData } from 'helpers/table.helper';
import EditNameRolePopup from 'pages/Templates/RolesTab/EditNameRolePopup';
import EditTemplateRolePopup from 'pages/Templates/RolesTab/EditTemplateRolePopup';
import React from 'react';
import Button from '../../../Button';
import Table from '../../Table';
import styles from './style.module.scss';
import useTableColumnOrder from 'utils/useTableColumnOrder';

const cx = classNames.bind(styles);

const RoleTable = ({
                     data,
                     perPage,
                     showLoader,
                     modifyDataHandler,
                     onRowSelect,
                     manualSortHandler,
                     colSortCheck,
                     templateList,
                     onSort,
                     PermissionEdit,
                   }) => {
  const { columns, saveColumnOrder, setDefaultColumnsOrder } = useTableColumnOrder('TABLE_TEMPLATE_ROLES', TABLE_TEMPLATE_ROLES);
  // const columns = React.useMemo(() => TABLE_TEMPLATE_ROLES, []);
  const tableData = React.useMemo(() => makeTableData(data, data.length), [data, templateList]);
  const prepearedTableData = tableData.map((item, pos) => {
    const { id, name } = item;
    return {
      name: (
        <div className={cx('rule__name')}>
          <span>{name}</span>
        </div>
      ),
      action: (
        <div className={cx('rule__actions')}>
          {
            PermissionEdit ?
              <>
                <EditNameRolePopup
                  dataItem={item}
                  actionType={SALES_RULES_ACTION_TYPE.EDIT}
                  updateComponent={modifyDataHandler}
                  triggerBtn={
                    <Button buttonText={'Change name'} onClick={() => null} buttonType={'outline'} />
                  }
                />
                <EditTemplateRolePopup
                  templateList={templateList}
                  dataItem={item}
                  actionType={SALES_RULES_ACTION_TYPE.EDIT}
                  updateComponent={modifyDataHandler}
                  triggerBtn={
                    <Button buttonText={'Change template'} onClick={() => null} buttonType={'outline'} />
                  }
                  // onCloseModal={() => {
                  // }}
                />
              </> : null
          }
        </div>
      ),
    };
  });

  const tableDataMeMo = React.useMemo(() => prepearedTableData, [tableData]);

  return (
    <>
      <Button 
        buttonStyle={{
          marginBottom: "12px",
          marginLeft: 0,
        }}
        buttonText='Reset column order'
        onClick={setDefaultColumnsOrder}
      />
      <Table
        columns={columns}
        data={tableDataMeMo}
        perPage={perPage}
        showLoader={showLoader}
        onRowSelect={onRowSelect}
        className={cx('rules-table')}
        isSelected={false}
        saveColumnOrder={saveColumnOrder}
        getCellProps={(cellInfo, cx) => {
          const { row, value, column } = cellInfo;
          return {
            style: {
              backgroundColor: `${row.isSelected ? '#f9f1ff' : '#ffffff'}`,
            },
          };
        }}
      />
    </>
  );
};

export default RoleTable;
