import classNames from 'classnames';
import './ChatBadge.scss';

type Props = {
    className?: string,
    count: number;
};

export const ChatBadge = ({ className, count }: Props) => {
    return <div className={classNames('chat-badge', className)}>{count}</div>
}