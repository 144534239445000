import React from 'react';
import styles from './client-acquisition-status.module.scss';
import classNames from 'classnames/bind';
import { Operator } from 'models/Clients/Clients';
import { HierarchyTeam } from 'models/Hierarchy/HierarchyData';

const cx = classNames.bind(styles);

type Props = {
  oldValues: { team: HierarchyTeam, operator: Operator };
  type: string;
  status: { status_name: string; }
}

const ClientAcquisitionStatus = (props: Props) => {
  const { oldValues, type, status } = props;

  return (
      <div
        className={cx('acquisitionStatus__block', 'old-values')}
      >
        <div className={cx('acquisitionStatus__block-col')}>
          <div className={cx('acquisitionStatus__action', 'old-values')}>{type}</div>
          <div className={cx('acquisitionStatus__action-status', 'old-values')}>
            {status?.status_name || 'NONE' }
            {/*{oldValues?.operator?.status*/}
            {/*  ? oldValues.operator.status*/}
            {/*  : 'NONE'}*/}
          </div>
        </div>
        <div className={cx('acquisitionStatus__block-col')}>
          <div className={cx('acquisitionStatus__info')}>
            <div className={cx('acquisitionStatus__info-row', 'old-values')}>
              <b>
                {oldValues?.operator?.full_name
                  ? oldValues.operator.full_name
                  : '---'}
              </b>
            </div>
            <div className={cx('acquisitionStatus__info-row')}>
              <b>Desk:</b>{' '}
              {oldValues?.team?.desk
                ? oldValues.team.desk.name
                : '---'}
            </div>
            <div className={cx('acquisitionStatus__info-row')}>
              <b>Team:</b>{' '}
              {oldValues?.team
                ? oldValues.team.name
                : '---'}
            </div>
          </div>
        </div>
      </div>
  )
};

export default ClientAcquisitionStatus;
