import { Copy } from "phosphor-react";
import { unEscapeHtml } from "../../helpers/unscapeHtml";
import { MenuOption } from "./MenuOption";
import { copyToClipboard } from "../../helpers/copyToClipboard";

type CopyTextOptionProps = { text: string };

export const CopyTextOption = ({ text }: CopyTextOptionProps) => {
    const handleCopyToClipboard = () => {
        copyToClipboard(unEscapeHtml(text));
    };

    return (
        <MenuOption title='Copy' onClick={handleCopyToClipboard}>
            <Copy size={32} color="#722ED1"/>
        </MenuOption>
    )
}