import React, { useState } from 'react';
import Popup from 'reactjs-popup';
import { Controller, useForm } from 'react-hook-form';
import classNames from 'classnames/bind';

import styles from './notesTabs.module.scss';

import PopupTemplate from 'components/ui/PopupTemplate/PopupTemplate';
import Button from 'components/ui/Button';
import LoaderButton from 'components/ui/LoaderButton/LoaderButton';
import { notify } from 'utils/notify';
import Toggle from 'react-toggle';
import { updateNote } from 'api/notes';
import { LeadNote } from 'models/Leads/LeadNotes';

const cx = classNames.bind(styles);

type Props = {
  triggerBtn: React.ReactElement;
  updateComponent: () => void;
  noteData: LeadNote;
}

const EditNotePopup = (props: Props) => {
  const { triggerBtn, updateComponent, noteData } = props;

  const [isLoading, setIsLoading] = useState(false);
  const [isPinned, setIsPinned] = useState(false);
  const { register, handleSubmit, control, reset, watch } = useForm({
    reValidateMode: 'onChange',
  });

  const watchField = watch();

  const closeModal = (closeModal) => {
    reset();
    closeModal();
  };

  const onSubmit = (close, data) => {
    setIsLoading(true);
    const sendData = {
      subject: data && data.subject ? data.subject : null,
      body: data && data.subject ? data.body : null,
      is_pinned: isPinned === true ? 1 : 0,
    };

    updateNote(sendData, noteData.id)
      .then((res) => {
        if (res.status === 200) {
          notify({
            type: 'success',
            message: 'Notes edited successfully',
            timeOut: 3000,
          });
          reset({
            subject: watchField.subject,
            body: watchField.body,
            is_pinned: isPinned,
          });
          closeModal(close);
          setIsLoading(false);
          updateComponent();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
        setIsLoading(false);
      });
  };

  return (
    <>
      <Popup
        modal
        trigger={triggerBtn}
        closeOnEscape
        repositionOnResize
        lockScroll
        closeOnDocumentClick
      >
        {(close) => (
          <PopupTemplate
            size="sm"
            trigger={<button> Trigger</button>}
            dismissModal={closeModal.bind(undefined, close)}
            headerTitle={`Edit note`}
            rightContent={
              <div className={cx('content')}>
                <div className={cx('content-form', 'editNotesForm')}>
                  <form onSubmit={handleSubmit(onSubmit.bind(undefined, close))}>
                    <div className={cx('notes')}>
                      <div className={cx('notes__container')}>
                        <Controller
                          control={control}
                          name="subject"
                          render={({ field: { onChange, value }}) => (
                            <div className={cx('notes__subject')}>
                              <div className={cx('label')}>Subject</div>
                              <div className={cx('input-wrap')}>
                                <input
                                  {...register('subject')}
                                  type="text"
                                  name="subject"
                                  defaultValue={noteData.subject}
                                  value={value}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          )}
                        />
                        <Controller
                          control={control}
                          name="body"
                          render={({ field: { onChange, value }}) => (
                            <div className={cx('notes__body')}>
                              <div className={cx('label')}>Body</div>
                              <div className={cx('input-wrap')}>
                                <textarea
                                  {...register('body')}
                                  name="body"
                                  defaultValue={noteData.body}
                                  value={value}
                                  onChange={onChange}
                                />
                              </div>
                            </div>
                          )}
                        />
                        <div className={cx('notes__footer')}>
                          <div className={cx('notes__info')}>
                            <Toggle
                              {...register('is_pined')}
                              icons={false}
                              defaultChecked={noteData.is_pinned === 1 ? true : false}
                              onChange={(e) => {
                                setIsPinned(e.target.checked);
                              }}
                            />
                            <div className={cx('toggle-label')}>Pin</div>
                          </div>
                          <div className={cx('btn-wrap')}>
                            <Button
                              buttonType="outline"
                              buttonText="Cancel"
                              type="button"
                              onClick={closeModal.bind(undefined, close)}
                            />

                            <LoaderButton
                              buttonType="primary"
                              size="big"
                              showSpinner={isLoading}
                              disabled={isLoading}
                              buttonText={'Save'}
                              type="submit"
                              onClick={(data) => handleSubmit(onSubmit.bind(close, data))}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            }
          />
        )}
      </Popup>
    </>
  );
};
export default EditNotePopup;
