import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import { Row, Col } from 'react-bootstrap';
import styles from './style.module.scss';
import { uploadLogoMethod } from '../../../../api/settingsLK';
import UploadImageComponent from '../../../../components/ui/UploadImageComponent/UploadImageComponent';
import emptyCache from "../../../../utils/emptyCache";
import { notify } from "../../../../utils/notify";
import Button from "../../../../components/ui/Button";
import { LKImage, LKStyleList } from 'models/SettingsLK/LKStyleList';

const cx = classNames.bind(styles);

type Props = {
  formData: LKStyleList;
  updateComponent: () => void;
  type: 'CRM' | 'PC';
  additionalInfo?: string;
}

const LogoForm = (props: Props) => {
  const { formData, updateComponent } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [logo, setLogo] = useState<LKImage>(null);
  const [showBtn, setShowBtn] = useState(false);
  useEffect(() => {
    formData && setIsLoading(false);
  }, [formData]);
  const onSubmit = () => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append('file', logo.file);
    formData.append('key', props.type === 'CRM' ? 'logo_crm' : 'logo_pc');

    uploadLogoMethod(formData)
      .then((res) => {
        if (res) {
          notify({
            type: 'success',
            message: 'Logo updated successfully',
            timeOut: 3000,
          });
          updateComponent();
          emptyCache();
        }
      })
      .catch((error) => {
        notify({
          type: 'error',
          message: error.response,
          timeOut: 3000,
        });
      })
      .finally(() => {
        setIsLoading(false);
        setShowBtn(false);
      });
  };
  const changeLogo = (logo) => {
    setLogo(logo);
    if (logo) {
      setShowBtn(true);
    } else setShowBtn(false);
  };
  return (
    <div className={isLoading ? cx('p-0', 'blur') : cx('p-0')}>
      <Row className={cx('form__line')}>
        <Col md={12}>
          <div className={cx('tab-panel__content-box-title')}>
            Upload logo {props.additionalInfo && `(${props.additionalInfo})`}
            {showBtn && (
              <Button buttonText='Save changes' buttonType='outline' onClick={() => onSubmit()} />
            )}
          </div>
        </Col>
      </Row>
      {formData && (
        <Col>
          <Row className={cx('form__line ')}>
            <UploadImageComponent
              formData={formData}
              changeLogo={(logo) => changeLogo(logo)}
              logo={`${formData[props.type === 'PC' ? 'logo_src' : 'logo_src_crm' ]}?time=${new Date().getTime()}`}
            />
          </Row>
        </Col>
      )}
      <Row>
        <Col className={cx('pt-2', 'pb-2', 'lh-sm')}>
          Supported files are .jpeg, .png, .jpg, .gif. The maximum size of a single file is 1 Mb.
        </Col>
      </Row>
    </div>
  );
};

export default LogoForm;
