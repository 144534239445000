import { APIGetAllWithdrawRequestsResponse, APIGetCurrentRequestResponse, APIGetExchangeRateResponse } from 'models/API/CustomerService/Request';
import API from '../../api';
import axios from 'axios';

export const getRequestListMethod = (params) => API.get<APIGetAllWithdrawRequestsResponse>(`/api/v1/withdrawal${params}`);
export const getCurrentRequestMethod = (id) => API.get<APIGetCurrentRequestResponse>(`/api/v1/withdrawal/${id}`);
export const getCryptoExchangeRate = (crypt) =>
  axios.get<APIGetExchangeRateResponse>(`https://api.coincap.io/v2/rates/${crypt}`);
  
// response unused
export const approveRequestMethod = (id, data) => API.put(`/api/v1/withdrawal/${id}`, data);
// response unused
export const rejectRequestMethod = (id) => API.delete(`/api/v1/withdrawal/${id}`);
